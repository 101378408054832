import React, { useCallback, useEffect, useState } from "react";

import Payment from "payment";
import Cards from "react-credit-cards";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import "react-credit-cards/es/styles-compiled.css";
import { createSubscription } from "../../redux/billing/actions";
import { BillingFormButton } from "../../Shared";
import { MonthlyBillDivider, MonthlyBillForm, MonthlyBillingHeader, ResultView } from "./billing.style";
import { formatCVC, formatCreditCardNumber, formatExpirationDate } from "./cardUtils";
import PopUp from "./PopUp";


const MonthlyBilling = () => {
  const dispatch = useDispatch();
  const subscription = useSelector((state) => state.billing.Subscription);
  const userData = useSelector((state) => state.userData.user);
  const [success, setSuccess] = useState();
  const [flip, setFlip] = useState(false);
  const [process, setProcess] = useState(false);
  const [endDate] = useState();
  const [cardNumber, setCardNumber] = useState("");
  const [cardExpiry, setCardExpiry] = useState("");
  const [cardCVC, setCardCVC] = useState("");
  const [cardFocus, setCardFocus] = useState("");
  const [validNumber, setValidNumber] = useState(true);
  const [validExpire, setValidExpire] = useState(true);
  const [validCVC, setValidCVC] = useState(true);
  const { t } = useTranslation();

  const callback_popup = useCallback((popup_state) => {
    setSuccess(popup_state);
  }, []);

  useEffect(() => {
    setProcess(false);
  }, [subscription]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const valid_cardNumber = Payment.fns.validateCardNumber(cardNumber);
    const valid_cardExpiry = Payment.fns.validateCardExpiry(cardExpiry);
    const valid_cardCVC = Payment.fns.validateCardCVC(cardCVC);

    if (!valid_cardNumber) {
      setValidNumber(false);
      return;
    }

    if (!valid_cardExpiry) {
      setValidExpire(false);
      return;
    }

    if (!valid_cardCVC) {
      setValidCVC(false);
      return;
    }

    setProcess(true);

    const cardInfo = {
      number: cardNumber,
      expire: cardExpiry,
      cvc: cardCVC,
    };

    dispatch(createSubscription(cardInfo));
  };

  const handleInputFocus = (e) => {
    setCardFocus(e.target.name);
  };

  const handleCardNumberChange = (e) => {
    const { value } = e.target;
    setCardNumber(formatCreditCardNumber(value));
    setValidNumber(true);
  };

  const handleExpiryChange = (e) => {
    const { value } = e.target;
    setCardExpiry(formatExpirationDate(value));
    setValidExpire(true);
  };

  const handleCVCChange = (e) => {
    const { value } = e.target;
    setCardCVC(formatCVC(value));
    setValidCVC(true);
  };

  const handleFlip = () => {
    setFlip(!flip);

    if (flip) setCardFocus("number");
    else setCardFocus("cvc");
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {success && <PopUp endDate={endDate} popupCallback={callback_popup} />}
      <MonthlyBillingHeader>
        <div>{t("billing.monthlySub")}</div>
        <div>$99.00 USD</div>
      </MonthlyBillingHeader>
      <MonthlyBillDivider />
      <MonthlyBillForm>
        <h4>{t("billing.paymentMethod")}</h4>
        <div id="PaymentForm">
          <div className="wrap-card" onClick={handleFlip}>
            <Cards cvc={cardCVC} expiry={cardExpiry} focused={cardFocus} name={userData.userName} number={cardNumber} />
          </div>
          <div className="mt-10">
            <label>{t("billing.cardNumber")}</label>
            <input
              type="text"
              name="number"
              placeholder="1234 1234 1234 1234"
              className={!validNumber && "invalid"}
              value={cardNumber}
              onChange={handleCardNumberChange}
              onFocus={handleInputFocus}
            />
            <div className="wrap-expiration">
              <label>
                {t("billing.expiration")}
                <input
                  type="text"
                  name="expiry"
                  placeholder="MM / YY"
                  className={!validExpire && "invalid"}
                  value={cardExpiry}
                  onChange={handleExpiryChange}
                  onFocus={handleInputFocus}
                />
              </label>
              <label>
                CVC/CV2
                <input
                  type="text"
                  name="cvc"
                  placeholder="CVC"
                  className={!validCVC && "invalid"}
                  value={cardCVC}
                  onChange={handleCVCChange}
                  onFocus={handleInputFocus}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="mt-25">
          <BillingFormButton onClick={handleSubmit}>
            {process ? t("billing.processing") : t("billing.pay")}
          </BillingFormButton>
          <ResultView result="#46b474">{subscription}</ResultView>
        </div>
      </MonthlyBillForm>
    </div>
  );
};

export default MonthlyBilling;
