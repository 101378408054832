import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const API = process.env.REACT_APP_PRO_API;

export const initialState = {
  strategies: [],
  isLoading: false,
  newStrategy: {
    name: "",
    description: "",
    groups: [],
    alerts: [],
    checkpoints: [],
    category: "",
    tags: [],
    startDate: new Date(),
    endDate: "",
  },
  activeStrategy: {
    _id: "",
    name: "",
    description: "",
    groups: [],
    alerts: [],
    checkpoints: [],
    category: "",
    tags: [],
    startDate: new Date(),
    endDate: "",
  },
};

const strategy = createSlice({
  name: "strategy",
  initialState,
  reducers: {
    setLoading(state, action) {
      return { ...state, isLoading: action.payload };
    },
    setStrategies(state, action) {
      return { ...state, strategies: action.payload };
    },
    setNewStrategy(state, action) {
      return { ...state, newStrategy: action.payload };
    },
    setActiveStrategy(state, action) {
      return { ...state, activeStrategy: action.payload };
    },
  },
});

export const strategyActions = strategy.actions;

const getRoot = (state) => state.strategy;

export const strategySelectors = {
  getLoading: (state) => getRoot(state).isLoading,
  getStrategies: (state) => getRoot(state).strategies,
  getNewStrategy: (state) => getRoot(state).newStrategy,
  getActiveStrategy: (state) => getRoot(state).activeStrategy,
};

export const strategyOperations = {
  getStrategies: () => async (dispatch, getState) => {
    dispatch(strategyActions.setLoading(true));
    try {
      const token = getState().userData.tokens.access.token;
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const { data } = await axios.get(`${API}/v1/strategy/list`, config);
      dispatch(strategyActions.setStrategies(data));
      dispatch(strategyActions.setLoading(false));
    } catch (err) {
      console.log(err);
      dispatch(strategyActions.setLoading(false));
    }
  },
  updateStrategy: () => async (dispatch, getState) => {
    dispatch(strategyActions.setLoading(true));
    try {
      const token = getState().userData.tokens.access.token;
      const strategy = { ...strategySelectors.getActiveStrategy(getState()) };
      delete strategy.groups;

      const config = {
        headers: {
          Authorization: token,
        },
      };
      await axios.patch(`${API}/v1/strategy/${strategy.id}`, strategy, config);
      dispatch(strategyOperations.getStrategies());
    } catch (err) {
      console.log(err);
      dispatch(strategyActions.setLoading(false));
    }
  },
  createStrategy: () => async (dispatch, getState) => {
    try {
      const token = getState().userData.tokens.access.token;
      const strategy = strategySelectors.getNewStrategy(getState());

      const config = {
        headers: {
          Authorization: token,
        },
      };

      await axios.post(`${API}/v1/strategy`, strategy, config);
      dispatch(strategyActions.setNewStrategy(initialState.newStrategy));
    } catch (err) {
      console.log(err);
    }
  },
  deleteStrategy: (id) => async (dispatch, getState) => {
    try {
      const token = getState().userData.tokens.access.token;

      const config = {
        headers: {
          Authorization: token,
        },
      };

      await axios.delete(`${API}/v1/strategy/${id}`, config);
      dispatch(strategyOperations.getStrategies());
    } catch (err) {
      console.log(err);
    }
  },
};

export const strategyReducer = strategy.reducer;
