import React from "react";

import { getWeb3 } from "@keyfi/keyfi-common/src/integrations";
import { useDispatch, useSelector } from "react-redux";

import { appActions } from "../redux/appSlice";
import { walletActions, walletOperations } from "../redux/walletSlice";

const ethereum = window.ethereum;

export default function useAccountChanged() {
  const dispatch = useDispatch();

  const [address, setAddress] = React.useState("");

  const { selectedAddress } = useSelector((state) => state.wallet);

  React.useEffect(() => {
    if (address) {
      console.log("here");
      dispatch(appActions.setLoading(true));
      dispatch(walletOperations.loadUserData());
    }
  }, [address]);

  ethereum.on("accountsChanged", async function (accounts) {
    if (selectedAddress !== accounts[0]) {
      setAddress(accounts[0]);
      dispatch(walletActions.setSelectedAddress(accounts[0]));
      const web3 = await getWeb3("Metamask");
      web3.eth.defaultAccount = accounts[0];
    }
  });
  return;
}
