import axios from "axios";

const API = process.env.REACT_APP_PRO_API;

export const getTimeFromNow = (old) => {
  const current = new Date().getTime();
  const res = current - old;

  let seconds = (res / 1000).toFixed(1);
  let minutes = (res / (1000 * 60)).toFixed(1);
  let hours = (res / (1000 * 60 * 60)).toFixed(1);
  let days = (res / (1000 * 60 * 60 * 24)).toFixed(1);
  if (seconds < 60) return 0 + "m";
  else if (minutes < 60) return Math.floor(minutes) + "m";
  else if (hours < 24) return Math.floor(hours) + "h";
  else return Math.floor(days) + "d";
};

export const getArticleComments = async (id, accessToken) => {
  try {
    const url = `${API}/v1/news/get-all-comments/${id}`;
    const config = {
      headers: {
        Authorization: accessToken,
      },
    };
    const { data } = await axios.get(url, { id: id }, config);
    return data;
  } catch (err) {
    return;
  }
};
