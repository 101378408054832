import styled from "styled-components";

import { breakPoints } from "../../Theme";

export const Wrapper = styled.div`
  flex: 3;
  margin-left: 1.5em;
  background: ${(props) => props.theme.secondaryBg};
  border-radius: 6px;
  height: 100%;
  @media (max-width: ${breakPoints.lg}px) {
    display: none;
  }
`;

export const FeedWrap = styled.div`
  margin: 0.75em 1em;

  h2 {
    display: inline;
    margin-left: 1em;
  }

  .noList {
    text-align: center;
    padding: 2em 0;
    color: #738ca6;
  }
`;

export const Wrap = styled.div`
  display: flex;
  margin-top: 1em;

  &:hover {
    cursor: pointer;
  }

  img {
    width: 50px;
    height: 50px;
  }

  .info {
    margin-left: 1em;
  }
  h5 {
    font-size: 0.75em;
    color: ${(props) => props.theme.text};
  }

  p {
    margin-top: 4px;
    font-weight: 500;
    font-size: 0.625em;
    color: ${(props) => props.theme.lightGrey};
  }

  @media (min-width: ${breakPoints.xxl}px) {
    .info > h5 {
      font-size: 1em;
    }
  }
`;
