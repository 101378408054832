import React, { useEffect, useMemo, useState } from "react";

import { getAddressSum, getContractPlatform } from "@keyfi/keyfi-common/src/integrations/common";
import Tippy from "@tippyjs/react";
import axios from "axios";
import BigNumber from "bignumber.js";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ReactComponent as ArrowIcon } from "../../assets/arrow-out.svg";
import { ReactComponent as InfoIcon } from "../../assets/infoIcon.svg";
import { ReactComponent as TXFailed } from "../../assets/transactions/txFailed.svg";
import { ReactComponent as TXSuccess } from "../../assets/transactions/txSuccess.svg";
import { beautifyTokensAmount, trimAddress } from "../../helpers";
import AssetImage from "../Borrow/AssetImage";
import Modal from "../Modal";
import { TXTooltip, directionSignCheck } from "./DesktopTransactionTable";
import { MobileTXCard } from "./MobileTransactionTable";
import { StyledTableCell, TransactionTable } from "./transaction.styles";
import { TransactionNoteWrapper } from "./TransactionNote.styles";

const PRO_API = process.env.REACT_APP_PRO_API;

const TransactionNoteTable = ({ apiURL, tx, network, t, id, tokenSymbol, tokenPrice }) => {
  return (
    <TransactionTable noteTable>
      <thead>
        <tr>
          <th>{t("common.transactionHash")}</th>
          <th>{t("common.token")}</th>
          <th>{t("common.fromTo")}</th>
          <th>{t("common.status")}</th>
          <th>{t("common.fee")}</th>
          <th>{t("common.gas")}</th>
          <th>{t("common.value")}</th>
          <th>{t("common.date")}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <StyledTableCell>
            <div>
              <a
                href={`https://${apiURL}/tx/${tx.hash}`}
                target="_blank"
                rel="noreferrer"
                className="transaction_txhash tx_blue"
              >
                {trimAddress(tx.hash)}
                <ArrowIcon />
              </a>
            </div>
          </StyledTableCell>
          <StyledTableCell txHash>
            <div>
              {tx.tokenName ? (
                <div className="tx_token">
                  <AssetImage
                    className="tx_token_image"
                    src={`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${
                      network.name === "bsc-mainnet" ? "smartchain" : "ethereum"
                    }/assets/${getAddressSum(tx.contractAddress)}/logo.png`}
                    alt={tx.tokenSymbol}
                  />
                  <div>
                    <div className="tx_token_name">{tx.tokenName}</div>
                    <div className="tx_token_symbol">{tx.tokenSymbol}</div>
                  </div>
                </div>
              ) : (
                "-"
              )}
              {directionSignCheck(tx, id, t)}
            </div>
          </StyledTableCell>
          <StyledTableCell>
            <div>
              <a
                href={`https://${apiURL}/address/${tx.from}`}
                target="_blank"
                rel="noreferrer"
                className="transaction_txhash"
              >
                {trimAddress(tx.from)}
                <ArrowIcon />
              </a>
            </div>
            <div>
              <a
                href={`https://${apiURL}/address/${tx.to}`}
                target="_blank"
                rel="noreferrer"
                className={`transaction_txhash ${getContractPlatform(tx.to) ? "tx_blue" : ""}`}
              >
                {getContractPlatform(tx.to) ?? trimAddress(tx.to)}

                <ArrowIcon />
              </a>
            </div>
          </StyledTableCell>
          <StyledTableCell>
            <div>{tx.isError === "0" || !tx.isError ? <TXSuccess /> : <TXFailed />}</div>
          </StyledTableCell>
          <StyledTableCell>
            <>
              <div>
                {BigNumber(tx.gasPrice).times(tx.gasUsed).shiftedBy(-18).toFixed(9)} {tokenSymbol}
              </div>
              <div className="tx_blue">
                {beautifyTokensAmount(
                  BigNumber(tx.gasPrice).times(tx.gasUsed).shiftedBy(-18).times(tokenPrice).toFixed(),
                  { prefix: "$", suffix: " USD" }
                )}
                <Tippy offset={[60, 5]} render={(attrs) => <TXTooltip attrs={attrs} network={network} />}>
                  <InfoIcon className="tx_info" />
                </Tippy>
              </div>
            </>
          </StyledTableCell>
          <StyledTableCell>
            <>
              <div>
                {BigNumber(tx.gasPrice).shiftedBy(-18).toFixed(9)} {tokenSymbol}
              </div>
              <div className="tx_blue">
                {beautifyTokensAmount(BigNumber(tx.gasPrice).shiftedBy(-9).toFixed(), { suffix: " Gwei" })}
              </div>
            </>
          </StyledTableCell>
          <StyledTableCell>
            <div>
              {beautifyTokensAmount(
                BigNumber(tx.value)
                  .shiftedBy(tx.tokenDecimal ? -tx.tokenDecimal : -18)
                  .toFixed(),
                { suffix: ` ${tx.tokenSymbol ?? tokenSymbol}` }
              )}
            </div>
          </StyledTableCell>
          <td>
            <div>{moment(tx.timeStamp * 1000).format("YYYY/MM/DD")}</div>
            <div>{moment(tx.timeStamp * 1000).format("HH:mm:ss")}</div>
          </td>
        </tr>
      </tbody>
    </TransactionTable>
  );
};

const TransactionNote = ({ network, handleCloseModal, apiURL, tx, tokenSymbol, tokenPrice }) => {
  const { t } = useTranslation();
  const [inputData, setInputData] = useState({
    inputChanged: false,
    notes: "",
  });

  const userData = useSelector((state) => state.userData);
  const { tokens } = userData;

  const user = useSelector((state) => state.user);
  const { id } = user;

  const config = useMemo(
    () => ({
      headers: {
        Authorization: tokens.refresh.token,
      },
    }),
    [tokens.refresh.token]
  );

  useEffect(() => {
    const handleFetching = async (hash) => {
      try {
        const { data } = await axios.get(`${PRO_API}/v1/transaction/${hash}`, config);
        setInputData((prev) => ({ ...prev, notes: data.notes }));
      } catch (err) {
        setInputData((prev) => ({ ...prev, notes: "" }));
      }
    };

    if (tx.hash) {
      handleFetching(tx.hash);
    }
  }, [tx, tokens, config]);

  const handleSaveNote = async () => {
    try {
      const body = {
        txHash: tx.hash,
        notes: inputData.notes,
      };
      await axios.post(`${PRO_API}/v1/transaction`, body, config);
      handleCloseModal();
    } catch (err) {
      console.log(err);
    }
  };

  const handleInputChange = (e) => {
    setInputData({ inputChanged: true, notes: e.target.value });
  };
  return (
    <Modal handleCloseModal={handleCloseModal} title="Transaction Note" maxwidth="916px" width="100%">
      <TransactionNoteWrapper>
        <div className="tx_note_details">
          <h4>{t("common.transactionsDetail")}</h4>
          <div className="tx_note_transaction">
            <TransactionNoteTable
              tokenSymbol={tokenSymbol}
              tokenPrice={tokenPrice}
              tx={tx}
              apiURL={apiURL}
              network={network}
              t={t}
              id={id}
            />
          </div>
          <MobileTXCard
            data={tx}
            id={id}
            t={t}
            network={network}
            tokenSymbol={tokenSymbol}
            ETHPrice={tokenPrice}
            apiURL={apiURL}
            noteTable
          />
        </div>

        <h3>{t("transactions.note")}</h3>
        <textarea
          rows="4"
          autoCorrect="off"
          placeholder={t("transactions.youCanWriteNoteHere")}
          value={inputData.notes}
          onChange={handleInputChange}
        />
        <div className="tx_note_buttons">
          <button className="tx_note_main_button" disabled={!inputData.inputChanged} onClick={() => handleSaveNote()}>
            {t("dashboard.widget.save")}
          </button>
          <button className="tx_note_secondary_button" onClick={handleCloseModal}>
            {t("dashboard.widget.cancel")}
          </button>
        </div>
      </TransactionNoteWrapper>
    </Modal>
  );
};

export default TransactionNote;
