import styled from "styled-components";

export const AddLiquidityWrapper = styled.div`
  background: ${(props) => props.theme.secondaryBg};
  border: 1px solid ${(props) => props.theme.researchBorder};
  border-radius: ${(props) => (props.dashboardContainer ? "6px" : "0 6px 6px 6px")};
  padding: 1.5rem;
  .add-liquidity-info {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    background: ${(props) => props.theme.disabled};
    padding: 1rem;
    border-radius: 6px;
    color: ${(props) => props.theme.mainDarkText};
    p {
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    svg {
      height: 18px;
    }
  }
  .add-liquidity-platform {
    margin-top: ${(props) => (props.dashboardContainer ? "0" : "1.5rem")};
    padding-bottom: 1.5rem;
    border-bottom: 1px solid ${(props) => props.theme.researchBorder};

    .add-liquidity-platform-label {
      font-size: 12px;
      color: ${(props) => props.theme.lightGrey};
      margin-bottom: 0.5rem;
      font-weight: 500;
    }
  }
`;
