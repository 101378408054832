import React, { Suspense, lazy, useEffect, useMemo, useState } from "react";

import { integrations } from "@keyfi/keyfi-common";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";

// Components
import Billing from "./Components/Billing/Billing";
import Layout from "./Components/Layout";
import ProtectedRoute from "./Components/ProtectedRoute";
import Forgot from "./Components/Signin/ForgotPassword";
import SigninRoute from "./Components/SigninRoute";
import { useWindowSize } from "./hooks/useWindowSize";
// Pages
import Access from "./Pages/Access";
import Actions from "./Pages/Actions/Actions";
import Alerts from "./Pages/Alerts/Alerts";
import Borrow from "./Pages/Borrow/Borrow";
import Dashboard from "./Pages/Dashboard/Dashboard";
import EmailVerification from "./Pages/EmailVerification";
import EmailVerified from "./Pages/EmailVerified";
import Info from "./Pages/Info/Info";
import ManageWallets from "./Pages/ManageWallets";
import News from "./Pages/News/News";
import Rebalancer from "./Pages/Rebalancer";
import Discovery from "./Pages/Research/Research";
import ResetPassword from "./Pages/ResetPassword";
import Signin from "./Pages/Signin";
import Signup from "./Pages/Signup";
import StakeError from "./Pages/StakeError";
import Strategy from "./Pages/Strategy";
import Transactions from "./Pages/Transactions";
import User from "./Pages/User";
import VerifyAuth from "./Pages/VerifyAuth";
import { showTransactionManager } from "./redux/transactionManager/actions";
import { userDataOperations } from "./redux/userDataSlice";
import { GlobalStyles } from "./Theme/GlobalStyles";

const Error404 = lazy(() => import("./Pages/Error404"));
const ResearchToken = lazy(() => import("./Pages/Research/ResearchToken"));
const Watchlist = lazy(() => import("./Pages/Watchlist/Watchlist"));
const PopularWatchlists = lazy(() => import("./Pages/Watchlist/PopularWatchlists"));
const FollowingWatchlists = lazy(() => import("./Pages/Watchlist/FollowingWatchlists"));
const CreateStrategy = lazy(() => import("./Pages/Strategy/CreateStrategy"));
const BorrowAsset = lazy(() => import("./Pages/Borrow/BorrowAsset"));
const Tokens = lazy(() => import("./Pages/Tokens"));
const Deposit = lazy(() => import("./Pages/Deposit/Deposit"));
const SwapV2 = lazy(() => import("./Pages/Swap/Swap"));
const Pools = lazy(() => import("./Pages/Pools/Pools"));
const RemoveLiquidity = lazy(() => import("./Pages/Pools/RemoveLiquidity"));
const MainWatchlist = lazy(() => import("./Pages/Watchlist/MainWatchlist"));
const Recommendation = lazy(() => import("./Pages/Recommendation/Recommendation"));
const MarketAnalysis = lazy(() => import("./Pages/MarketAnalysis/MarketAnalysis"));

window.integrations = integrations;

function App() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const [showSidebar, setShowSidebar] = useState(true);
  const [isMinimized, setIsMinimized] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [width] = useWindowSize();
  const isMobile = useMemo(() => width <= 768, [width]);
  const isTablet = useMemo(() => width <= 920, [width]);
  const isDesktop = useMemo(() => width <= 1200, [width]);

  // Initial fetch
  useEffect(() => {
    dispatch(userDataOperations.userInit());
  }, [dispatch]);

  useEffect(() => {
    if (isTablet && !isMinimized && !isDesktop) {
      setShowSidebar(true);
      setIsMinimized(true);
    } else if (isMobile) {
      setShowSidebar(false);
      setIsMinimized(false);
    } else {
      setShowSidebar(true);
    }
  }, [isMobile, isTablet]);

  const handleToggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const handleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  const handleShowModalAndSidebar = () => {
    setShowModal(true);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleChangeLang = (lang) => {
    i18n.changeLanguage(lang);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowTxm = () => {
    showTransactionManager();
  };

  const navigation = () => {
    return (
      <>
        <Switch>
          <SigninRoute exact path="/verify-email" component={EmailVerification} />
          <SigninRoute exact path="/verified" component={EmailVerified} />
          <SigninRoute exact path="/access" component={Access} />
          <SigninRoute exact path="/accesserror" component={StakeError} />
          <SigninRoute exact path="/forgot_password" component={Forgot} />
          <SigninRoute exact path="/reset_password" component={ResetPassword} />
          <SigninRoute exact path="/signup" component={Signup} />
          <SigninRoute exact path="/verify-auth" component={VerifyAuth} />
          <SigninRoute exact path="/signin" component={Signin} />
          <ProtectedRoute>
            <Layout
              showSidebar={showSidebar}
              handleToggleSidebar={handleToggleSidebar}
              isMobile={isMobile}
              sidebarMinimized={isMinimized}
              handleMinimize={handleMinimize}
              handleShowModal={isMobile ? handleShowModalAndSidebar : handleShowModal}
              setLang={handleChangeLang}
              showModal={showModal}
              handleCloseModal={handleCloseModal}
              handleShowTxm={handleShowTxm}
            >
              <Switch>
                <Route exact path="/">
                  <Redirect to="/dashboard" />
                </Route>
                <ProtectedRoute exact path="/dashboard" component={Dashboard} />
                <ProtectedRoute exact path="/borrow" component={Borrow} />
                <ProtectedRoute exact path="/transactions" component={Transactions} />
                <ProtectedRoute exact path="/strategy" component={Strategy} />
                <ProtectedRoute exact path="/billing" component={Billing} />
                <ProtectedRoute exact path="/news" component={News} />
                <ProtectedRoute exact path="/research" component={Discovery} />
                <ProtectedRoute exact path="/actions" component={Actions} />
                <ProtectedRoute exact path="/rebalancer" component={Rebalancer} />
                <ProtectedRoute exact path="/alerts" component={Alerts} />
                <ProtectedRoute exact path="/user" component={User} />
                <ProtectedRoute exact path="/info" component={Info} />
                <Suspense fallback={""}>
                  <Switch>
                    <ProtectedRoute exact path="/wallets" component={ManageWallets} />
                    <ProtectedRoute
                      exact
                      path="/pools/remove-liquidity/:platformCode?/:chainId?"
                      component={RemoveLiquidity}
                    />
                    <ProtectedRoute exact path="/market_analysis" component={MarketAnalysis} />
                    <ProtectedRoute exact path="/discovery_engine" component={Recommendation} />
                    <ProtectedRoute exact path="/pools/:tab?/:platformCode?/:chainId?" component={Pools} />
                    <ProtectedRoute exact path="/swap/:platformCode?/:chainId?" component={SwapV2} />
                    <ProtectedRoute exact path="/deposit/:platformCode?" component={Deposit} />
                    <ProtectedRoute exact path="/earn/:platformCode?" component={Tokens} />
                    <ProtectedRoute exact path="/borrow/:platform/:version/:asset" component={BorrowAsset} />
                    <ProtectedRoute exact path="/strategy/create" component={CreateStrategy} />
                    <ProtectedRoute exact path="/watchlist" component={MainWatchlist} />
                    <ProtectedRoute exact path="/watchlist/popular" component={PopularWatchlists} />
                    <ProtectedRoute exact path="/watchlist/following" component={FollowingWatchlists} />
                    <ProtectedRoute exact path="/watchlist/:watchlistID" component={Watchlist} />
                    <ProtectedRoute exact path="/research/token/:tokenID" component={ResearchToken} />
                    <ProtectedRoute exact path="/404" component={Error404} />
                    {/* <ProtectedRoute exact path="/nexo-test/signup" component={SignupforNexo} />
                  <ProtectedRoute exact path="/nexo-test/verify-email" component={verifyEmailforNexo} />
                  <ProtectedRoute exact path="/nexo-test/recovery-code" component={RecoveryCode} />
                  <ProtectedRoute exact path="/nexo-test/user-balance" component={UserBalance} />
                  <ProtectedRoute exact path="/nexo-test/request-withdraw" component={WithdrawRequest} />
                  <ProtectedRoute exact path="/nexo-test/verify-withdraw" component={VerifyWithdraw} />
                  <ProtectedRoute exact path="/nexo-test/verify-recovery-code" component={VerifyRecoveryCode} /> */}
                  </Switch>
                </Suspense>
              </Switch>
            </Layout>
          </ProtectedRoute>
        </Switch>
      </>
    );
  };
  return (
    <>
      <GlobalStyles />
      {navigation()}
    </>
  );
}

export default App;
