import styled from "styled-components";

import { breakPoints } from "../../Theme";

export const Wrapper = styled.div`
  width: 100%;
  min-height: 3rem;
  background: ${(props) => props.theme.secondaryBg};
  margin-top: 1.5em;
  border-radius: 6px;
  padding: 0 10px;

  .swiper-wrapper {
    max-height: 100%;
    display: flex;
    max-width: 0px;
    padding: 0.75rem 0px;
  }
  .swiper-container {
    max-width: 100%;
  }

  @media (max-width: ${breakPoints.sm}px) {
    width: calc(100vw - 40px);
    margin-right: 20px;
  }

  .slides {
    width: auto !important;
  }

  .token {
    display: flex;
    align-items: center;
    max-height: max-content;
    gap: 0.5rem;

    img {
      width: 24px;
      height: 24px;
    }
    .symbol {
      color: ${(props) => props.theme.lightGrey};
    }
  }

  .token_price_change {
    color: ${(props) => props.theme.greenShade2};
  }
  .token_price_negative {
    color: ${(props) => props.theme.redMinus};
  }
`;
