import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Assets
import NoStrategiesImage from "../../assets/strategy/no_strategies.svg";
import { ReactComponent as CirclePlus } from "../../assets/strategy/plus.svg";
import Loader from "../../Components/Loader";
import Meta from "../../Components/Meta";
import StrategyCard from "../../Components/Strategy/StrategyCard";
import { strategyOperations } from "../../redux/strategySlice";
import { StrategyWrapper } from "./strategy.styles";

const Strategy = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const strategy = useSelector((state) => state.strategy);
  const { strategies, isLoading } = strategy;

  useEffect(() => {
    dispatch(strategyOperations.getStrategies());
  }, [dispatch]);

  return (
    <StrategyWrapper>
      <Meta title="Strategy Manager" />
      {/* <StrategyWelcome /> */}
      <div className="strategy_header">
        <h1>My Strategies {!isLoading && `(${strategies.length})`}</h1>
        <Link to="/strategy/create" className="strategy_create">
          <CirclePlus />
        </Link>
      </div>
      {isLoading && <Loader style={{ marginTop: "3rem" }} />}
      {!isLoading &&
        (strategies.length === 0 ? (
          <div className="strategy_main">
            <div className="strategy_no_strategies">
              <img src={NoStrategiesImage} alt="You do not have any strategies" />
              <p>{t("strategy.noStrategyYet")}</p>
              <Link to="/strategy/create">{t("strategy.createStrategy")}</Link>
            </div>
          </div>
        ) : (
          strategies.map((item) => <StrategyCard key={item.id} strategy={item} />)
        ))}
    </StrategyWrapper>
  );
};

export default Strategy;
