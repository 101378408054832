import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { addressesActions } from "./addressesSlice";

const API = process.env.REACT_APP_PRO_API;

const initialState = {
  isLoading: false,
  addresses: [],
};

const addAddresses = createSlice({
  name: "addAddresses",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setAddresses(state, action) {
      state.addresses = action.payload;
    },
  },
});

export const addAddressesActions = addAddresses.actions;

const getRoot = (state) => state.addAddresses;

export const addAddressesSelectors = {
  getLoading: (state) => getRoot(state).isLoading,
  getAddresses: (state) => getRoot(state).addresses,
};

export const addAddressesOperations = {
  handleInputChange: (index, key, value) => (dispatch, getState) => {
    let addresses = [...addAddressesSelectors.getAddresses(getState())];
    addresses[index] = { ...addresses[index], [key]: value };
    dispatch(addAddressesActions.setAddresses(addresses));
  },
  createAddressField: (key, value) => (dispatch, getState) => {
    let addresses = [...addAddressesSelectors.getAddresses(getState())];
    addresses.push(key === "address" ? { address: value, title: "" } : { title: value, address: "" });
    dispatch(addAddressesActions.setAddresses(addresses));
  },
  addAdresses: () => async (dispatch, getState) => {
    try {
      dispatch(addAddressesActions.setLoading(true));
      const token = getState().userData.tokens.access.token;
      const addresses = getState().addAddresses.addresses;

      const config = {
        headers: {
          Authorization: token,
        },
      };
      const { data } = await axios.post(`${API}/v1/wallet`, addresses, config);
      dispatch(addAddressesActions.setAddresses([])); // Clear addresses array

      const currentUserAddresses = [...getState().addresses.addresses];
      dispatch(addressesActions.setAddresses([...currentUserAddresses, ...data]));
      dispatch(addAddressesActions.setLoading(false));
      return;
    } catch (err) {
      console.log(err);
      dispatch(addAddressesActions.setLoading(false));
    }
  },
  addSingleAddress: (address) => async (dispatch, getState) => {
    try {
      dispatch(addAddressesActions.setLoading(true));
      const token = getState().userData.tokens.access.token;

      const config = {
        headers: {
          Authorization: token,
        },
      };
      const { data } = await axios.post(`${API}/v1/wallet`, [{ address }], config);
      const currentUserAddresses = [...getState().addresses.addresses];
      dispatch(addressesActions.setAddresses([...currentUserAddresses, ...data]));
      dispatch(addAddressesActions.setLoading(false));
      return;
    } catch (err) {
      console.log(err);
      dispatch(addAddressesActions.setLoading(false));
    }
  },
};

export const addAddressesReducer = addAddresses.reducer;
