import React, { useEffect , useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as VerifyEmail } from "../assets/email/verify-email.svg";
import Meta from "../Components/Meta";
import { userDataOperations } from "../redux/userDataSlice";


// Assets

const Wrapper = styled.div`
  color: ${(props) => props.theme.default_color};
  .verify-email-section {
    margin-top: 6.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1.25rem;
    text-align: center;
    h1 {
      font-size: 24px;
      margin: 2rem 0 0.75rem;
    }
    h2 {
      font-size: 20px;
      font-weight: 500;
    }
    div {
      margin-top: 6rem;
      p {
        font-size: 12px;
        font-weight: 500;
      }
      button {
        margin-top: 0.75rem;
        cursor: pointer;
        font-size: 16px;
        max-width: 180px;
        width: 100%;
        font-weight: 300;
        background: ${(props) => props.theme.form_content};
        outline: none;
        border: 1px solid ${(props) => props.theme.form_text3};
        border-radius: 6px;
        color: ${(props) => props.theme.superLightBlue};
        padding: 9px 0;
        :hover {
          background: ${(props) => props.theme.input_placefolder};
        }
        :disabled {
          pointer-events: none;
          color: gray;
        }
      }
    }
  }
`;

const EmailVerification = ({ location }) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData);
  const { user, loading } = userData;
  const { t } = useTranslation();
  const [disableButton, setDisableButton] = useState(false);
  const [timer, setTimer] = useState(0);

  const token = location.search.split("t=")[1];

  useEffect(() => {
    if (token) {
      dispatch(userDataOperations.approveEmail(token));
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    }

    if (timer === 0 && disableButton) {
      setDisableButton(false);
    }
  }, [timer, disableButton]);

  const handleVerificationResend = () => {
    setDisableButton(true);
    const emailSent = dispatch(userDataOperations.resendVerificationEmail());
    if (emailSent) {
      setTimer(10);
    } else {
      console.log("error");
    }
  };

  return (
    <Wrapper>
      <Meta title="Verify email" />
      {!user.id && !loading && <Redirect to="/signin" />}
      {!loading && user.emailApproved && <Redirect to="/verified" />}
      <div className="verify-email-section">
        <VerifyEmail />
        <h1>{t("auth.oneStepAwayFromRegistration")}</h1>
        <h2>{t("auth.checkEmailToVerify")}</h2>
        <div>
          <p>{t("auth.didntVerifyEmail")}</p>
          <button onClick={handleVerificationResend} disabled={disableButton}>
            {disableButton ? timer : t("auth.resendEmail")}
          </button>
        </div>
        {disableButton && <span style={{ marginTop: "0.5rem" }}>{t("auth.emailHasBeenSent")}</span>}
      </div>
    </Wrapper>
  );
};

export default EmailVerification;
