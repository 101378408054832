import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { newsOperations } from "../../../redux/Slices/newsSlice";
import { breakPoints } from "../../../Theme";
import { getTimeFromNow } from "../../News/news.helpers";
import WidgetWrapper from "./WidgetWrapper";

const NewsHeader = styled.div`
  .total_value_toggle {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    div {
      color: ${(props) => props.theme.lightGrey};
      transition: 0.3s;
    }
    .mode_selected {
      color: ${(props) => props.theme.mainDarkText};
    }
    input[type="checkbox"] {
      display: none;
      :checked + label:after {
        left: calc(100% - 2px);
        transform: translateX(-100%);
      }
    }
    label {
      display: block;
      width: 37px;
      height: 20px;
      background: ${(props) => props.theme.toggleGreenOpacity};
      border-radius: 26px;
      border: 1px solid ${(props) => props.theme.toggleGreen};
      position: relative;
      cursor: pointer;
      transition: 0.3s;
      ::after {
        content: "";
        height: 12px;
        width: 12px;
        background: ${(props) => props.theme.toggleBall};
        position: absolute;
        border: 1px solid ${(props) => props.theme.toggleBallBorder};
        border-radius: 50%;
        top: 2px;
        left: 2px;
        transition: 0.3s;
      }
    }
  }
`;

const NewsMain = styled.div`
  flex: 1;
  background: ${(props) => props.theme.bg};
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.input_placefolder};
  overflow: auto;
  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.scroleThumb};
    border-radius: 10px;
  }
  .news_section {
    margin: 0 1rem;
    padding: 0.5rem 0;
    border-bottom: 1px solid ${(props) => props.theme.input_placefolder};
    display: flex;
    :last-of-type {
      border: 0;
    }
    .news_section_time {
      flex: 0.75;
      font-size: 10px;
      color: ${(props) => props.theme.newsTimestamp};
      margin-right: 0.5rem;
    }
    .news_section_main {
      display: flex;
      flex: 9;
      .news_section_title {
        flex: 8;
        font-size: 14px;
        color: ${(props) => props.theme.mainDarkText};
        :hover {
          text-decoration: underline;
        }
      }
      .news_section_category {
        flex: 3;
        text-align: right;
        font-size: 12px;
        color: ${(props) => props.theme.form_text};
        font-weight: 500;
        @media (max-width: ${breakPoints.lg}px) {
          margin-top: 4px;
          text-align: left;
        }
      }
    }
  }
  @media (max-width: ${breakPoints.lg}px) {
    background: 0;
    margin-top: 0.75rem !important;
    border: 0;
    .news_section {
      .news_section_time {
        flex: 1.25;
      }
      .news_section_main {
        flex-direction: column;
      }
    }
  }
`;

const LIST_TYPE = "list";
// const CARD_TYPE = "card";

const NEWS_DATA_TYPE = "news";
const READING_LIST_TYPE = "reading_list";

const NewsWidget = ({ setOpenModal, item }) => {
  const widgetType = LIST_TYPE; // For now a simple constant
  // const [widgetType, setWidgetType] = useState(LIST_TYPE);
  const [dataType, setDataType] = useState(NEWS_DATA_TYPE);
  const dispatch = useDispatch();
  const news = useSelector((state) => state.news);
  const { newsData, isLoading, readingList } = news;

  useEffect(() => {
    if (!isLoading) {
      if (newsData.length === 0 && dataType === NEWS_DATA_TYPE) {
        dispatch(newsOperations.loadAllNews());
        return;
      }
      if (readingList.length === 0 && dataType === READING_LIST_TYPE) {
        dispatch(newsOperations.getReadingList());
      }
    }
  }, [dispatch, dataType]);

  const handleChange = (e) => {
    setDataType(e.target.checked ? READING_LIST_TYPE : NEWS_DATA_TYPE);
  };

  const data = dataType === NEWS_DATA_TYPE ? newsData : readingList;

  return (
    <WidgetWrapper
      item={item}
      setOpenModal={setOpenModal}
      id="news"
      header={
        <NewsHeader className="widget_info">
          <div className="total_value_toggle">
            <div className={dataType === NEWS_DATA_TYPE ? "mode_selected" : ""}>Latest News</div>
            <div>
              <input
                type="checkbox"
                name="newsSwitch"
                id="newsSwitch"
                onChange={handleChange}
                checked={dataType === READING_LIST_TYPE}
              />
              <label htmlFor="newsSwitch"></label>
            </div>

            <div className={dataType === READING_LIST_TYPE ? "mode_selected" : ""}>Reading List</div>
          </div>
        </NewsHeader>
      }
      body={
        <>
          {widgetType === LIST_TYPE && (
            <NewsMain className="widget_main">
              {data.length !== 0 &&
                data.map((item, i) => (
                  <div className="news_section" key={`${item.index}-${i}`}>
                    <div className="news_section_time">{getTimeFromNow(new Date(item.date))}</div>
                    <div className="news_section_main">
                      <a href={item.news_url} target="_blank" rel="noreferrer" className="news_section_title">
                        {item.title}
                      </a>
                      <div className="news_section_category">
                        {item.tickers.length !== 0 && item.tickers.map((currency) => `${currency} `)}
                      </div>
                    </div>
                  </div>
                ))}
            </NewsMain>
          )}
        </>
      }
    />
  );
};

export default NewsWidget;
