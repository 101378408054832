import React, { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as ArrowRight } from "../../assets/arrow_right.svg";
import { ReactComponent as MagnifyingGlass } from "../../assets/discovery/magnifyingGlass.svg";
import { ReactComponent as CirclePlus } from "../../assets/plus_circle.svg";
import { ReactComponent as CircleArrow } from "../../assets/strategy/circle_arrow_right.svg";
import { ReactComponent as Edit } from "../../assets/strategy/edit.svg";
import { ReactComponent as Trash } from "../../assets/strategy/trash.svg";
import { poolsLogos } from "../../helpers";
import { strategyActionActions, strategyActionOperations } from "../../redux/strategyActionsSlice";
import { strategyGroupOperations } from "../../redux/strategyGroupSlice";
import Modal from "../Modal";
import { CreateGroupActions, CreateGroupModalWrapper, NewActionGroup } from "./CreateGroupModal.styles";
import EditActionModal from "./EditActionModal";

const CreateGroupModal = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");

  const [editModal, setEditModal] = useState(false);

  const strategyGroup = useSelector((state) => state.strategyGroup);
  const { group } = strategyGroup;
  const strategyAction = useSelector((state) => state.strategyAction);
  const { actionTemplates } = strategyAction;

  useEffect(() => {
    if (actionTemplates.length === 0) {
      dispatch(strategyActionOperations.getActionTemplates());
    }
  }, [actionTemplates, dispatch]);

  const handleAddToGroup = (id) => {
    const actionData = actionTemplates.find((action) => action._id === id);
    if (actionData) {
      dispatch(
        strategyGroupOperations.handleGroupInput("actions", [
          ...group.actions,
          {
            templateId: actionData._id,
            args: [],
          },
        ])
      );
    }
  };

  const handleRemoveFromGroup = (id) => {
    const filteredGroup = group.actions.filter((item) => item.templateId !== id);
    dispatch(strategyGroupOperations.handleGroupInput("actions", filteredGroup));
  };

  const handleSaveGroup = () => {
    handleClose();
    dispatch(strategyGroupOperations.createGroup());
  };

  const handleOpenEditAction = (item) => {
    dispatch(strategyActionActions.setActiveAction(item));
    setEditModal(true);
  };

  const filteredTemplates = useMemo(() => {
    return actionTemplates.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()));
  }, [actionTemplates, searchQuery]);

  return (
    <Modal
      margin="1rem auto"
      title={t("strategy.createNewGroup")}
      maxwidth="1000px"
      open={open}
      handleCloseModal={handleClose}
    >
      {editModal && <EditActionModal handleCloseModal={() => setEditModal(false)} />}
      <CreateGroupModalWrapper>
        <CreateGroupActions>
          <h3>{t("strategy.actions")}</h3>
          <div className="create_group_actions_searchbar">
            <label htmlFor="search">{t("strategy.search")}</label>
            <div className="create_group_actions_search">
              <input value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
              <MagnifyingGlass />
            </div>
          </div>
          <div className="create_group_actions">
            {filteredTemplates.map((item, i) => (
              <div key={`${item.name}-${i}`} className="create_group_action">
                {poolsLogos[item.name] ?? "?"}
                <div className="create_group_action_name">
                  <h4>{item.action}</h4>
                  <h5>{item.name}</h5>
                </div>
                <button onClick={() => handleAddToGroup(item._id)}>
                  {t("strategy.add")} <ArrowRight />
                </button>
              </div>
            ))}
          </div>
          <button className="create_group_action_custom">
            <CirclePlus /> {t("strategy.createCustomActions")}
          </button>
        </CreateGroupActions>
        <div className="create_group_arrow_field">
          <CircleArrow />
        </div>
        <NewActionGroup>
          <h3>{t("strategy.newActionGroup")}</h3>
          <label htmlFor="name">{t("strategy.groupName")}</label>
          <input
            name="name"
            placeholder={t("strategy.gourpNameDescription")}
            value={group.name}
            onChange={(e) => dispatch(strategyGroupOperations.handleGroupInput(e.target.name, e.target.value))}
          />
          <label>
            {t("strategy.actions")} ({group.actions.length})
          </label>
          <div className="new_actions_section">
            {group.actions.length !== 0
              ? group.actions.map((item) => {
                  const groupAction = actionTemplates.find((action) => action._id === item.templateId);
                  return (
                    <div key={item.templateId} className="new_action_item">
                      {poolsLogos[groupAction.name] ?? "?"}
                      <div className="new_action_item_name">
                        <h4>{groupAction.action}</h4>
                        <h5>{groupAction.name}</h5>
                      </div>
                      <div className="new_action_item_buttons">
                        <button onClick={() => handleOpenEditAction(item)}>
                          <Edit />
                        </button>
                        <button onClick={() => handleRemoveFromGroup(groupAction._id)}>
                          <Trash />
                        </button>
                      </div>
                    </div>
                  );
                })
              : t("strategy.chooseActionFromList")}
          </div>
          <div className="new_actions_buttons">
            <button className="new_actions_button_primary" onClick={handleSaveGroup} disabled={group.name.length === 0}>
              {t("strategy.saveNewGroup")}
            </button>
            <button className="new_actions_button_secondary" onClick={handleClose}>
              {t("strategy.discard")}
            </button>
          </div>
        </NewActionGroup>
      </CreateGroupModalWrapper>
    </Modal>
  );
};

export default CreateGroupModal;
