import styled, { css } from "styled-components";

import { breakPoints } from "../../Theme";

export const WalletViewModalHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  label {
    display: flex;
    align-items: center;
    position: relative;
    gap: 0.5rem;
    cursor: pointer;
    user-select: none;
    font-size: 12px;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    .checkmark {
      top: 0;
      left: 0;
      height: 18px;
      width: 18px;
      background: ${(props) => props.theme.bg};
      border-radius: 3px;
      border: 1px solid ${(props) => props.theme.researchBorder};
      :after {
        content: "";
        position: absolute;
        display: none;
        left: 6px;
        top: 8px;
        width: 4px;
        height: 8px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
    :hover input ~ .checkmark {
      /* background-color: #ccc; */
    }
    input:checked ~ .checkmark {
      background: ${(props) => props.theme.blueTransparent};
      border-color: ${(props) => props.theme.brandBlue};
      :after {
        display: block;
      }
    }
  }
`;

export const WalletViewDropdown = styled.div`
  position: relative;
  .wallet_view_dropdown {
    cursor: pointer;
    background: ${(props) => props.theme.bg};
    width: 125px;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    border-radius: 6px;
    border: 1px solid ${(props) => props.theme.researchBorder};
    font-size: 12px;
    line-height: normal;
    .wallet_view_arrow {
      width: 16px;
      height: 16px;
    }
    ${(props) =>
      props.open &&
      css`
        border-bottom: 0px;
        border-radius: 6px 6px 0 0;
      `}
  }
  .wallet_view_dropdown_list {
    overflow: hidden;
    z-index: 4;
    position: absolute;
    top: 100%;
    left: 0;
    background: ${(props) => props.theme.bg};
    width: 130%;
    border-radius: 6px;
    border-top-left-radius: 0px;
    border: 1px solid ${(props) => props.theme.researchBorder};
    > div {
      cursor: pointer;
      font-size: 12px;
      line-height: normal;
      padding: 0.5rem;
      :not(:last-of-type) {
        border-bottom: 1px solid ${(props) => props.theme.researchBorder};
      }
      :hover {
        background: ${(props) => props.theme.secondaryBg};
      }
    }
  }
`;

export const WalletViewModalWrapper = styled.div`
  padding: 1rem 0;
`;

export const AddressSectionWrapper = styled.div`
  cursor: ${(props) => (props.zeroValue ? "default" : "pointer")};
  margin-bottom: 2.5rem;
  :last-of-type {
    margin: 0;
  }
  .address_section_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .address_section_title {
      display: flex;
      align-items: flex-end;
      gap: 6px;
      .address_section_icon {
        height: 16px;
        width: 16px;
        border-radius: 50%;
      }
      .address_title {
        display: flex;
        align-items: flex-end;
        gap: 6px;
        h3 {
          font-size: 16px;
          line-height: 16px;
        }
        h5 {
          font-size: 12px;
          color: ${(props) => props.theme.lightGrey};
          line-height: 12px;
        }
        @media (max-width: ${breakPoints.lg}px) {
          flex-direction: column;
          align-items: flex-start;
        }
      }
      @media (max-width: ${breakPoints.lg}px) {
        align-items: flex-start;
      }
    }
    .address_section_header_end {
      display: flex;
      align-items: center;
      gap: 1rem;
      .address_section_total {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        span {
          color: ${(props) => props.theme.lightGrey};
        }
        h3 {
          font-size: 16px;
          color: ${(props) => props.theme.form_text};
        }
        @media (max-width: ${breakPoints.lg}px) {
          flex-direction: column;
          align-items: flex-end;
          gap: 2px;
          span {
            font-size: 12px;
          }
        }
      }
      svg {
        cursor: pointer;
        height: 18px;
        width: 18px;
      }
    }
  }
  .address_section_main {
    table {
      margin-top: 0.75rem;
      width: 100%;
      border-collapse: collapse;
      th,
      td {
        /* width: 25%; */
        text-align: left;
        :first-of-type {
          padding-left: 1rem;
        }
        :last-of-type {
          padding-right: 1rem;
        }
        :nth-last-of-type(2) {
          text-align: right;
        }
      }
      th {
        font-size: 12px;
        color: ${(props) => props.theme.lightGrey};
        padding-bottom: 6px;
        border-bottom: 1px solid ${(props) => props.theme.researchBorder};
      }
      tr {
        :nth-child(even) {
          background: ${(props) => props.theme.bg};
        }
        :hover {
          .wallet_data_table_hide {
            visibility: visible;
          }
        }
      }
      td {
        padding-top: 1rem;
        padding-bottom: 1rem;
        .address_token {
          display: flex;
          gap: 0.5rem;
          align-items: center;
          .table_image,
          img,
          svg {
            height: 32px;
            width: 32px;
            border-radius: 50%;
          }
        }
        .wallet_data_table_hide {
          display: flex;
          justify-content: flex-end;
          visibility: hidden;
          button {
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              cursor: pointer;
              height: 18px;
              width: 18px;
            }
          }
        }
      }
    }
    .mobile_address_token {
      display: none;
    }
    @media (max-width: ${breakPoints.lg}px) {
      margin-top: 1.5rem;
      table {
        display: none;
      }
      .mobile_address_token {
        display: flex;
        gap: 1.5rem;
        padding: 1rem;
        background: ${(props) => props.theme.bg};
        border-radius: 6px;
        flex-direction: column;
        margin-bottom: 1.5rem;
        > div {
          display: flex;
          width: 100%;
          > div {
            flex: 1;
            display: flex;
            align-items: center;
            :first-of-type {
              color: ${(props) => props.theme.lightGrey};
            }
            .address_token_image,
            img {
              height: 24px;
              width: 24px;
              margin-right: 0.5rem;
            }
          }
        }
      }
    }
  }
  /* Single wallet LP grid CSS */
  .wallet_data_wallet_lp {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    ::-webkit-scrollbar {
      display: none;
    }
    .wallet_data_wallet_lp_header {
      grid-column: 1/4;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      padding: 0 0.5rem 3px;
      h4 {
        font-size: 12px;
        color: ${(props) => props.theme.lightGrey};
      }
    }
    .wallet_lp_item {
      grid-column: 1/4;
      border-radius: 6px;
      :nth-of-type(odd) {
        background: ${(props) => props.theme.secondaryBg};
      }
      .wallet_lp_item_header {
        cursor: pointer;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: center;
        margin: 10px 0.5rem;

        /* Image, name and amount */
        .wallet_lp_item_info {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          .wallet_lp_item_icon {
            height: 24px;
            width: 24px;
            border-radius: 50%;
          }
          .wallet_lp_last_icon {
            margin-left: -5px;
          }
          .wallet_lp_item_title {
            h3 {
              font-size: 14px;
            }
            h5 {
              font-size: 14px;
              color: ${(props) => props.theme.form_text};
            }
          }
        }
        /* Platform */
        .wallet_lp_item_platform {
          display: flex;
          align-items: center;
          gap: 6px;
          img,
          svg {
            height: 24px;
            width: 24px;
          }
        }
        /* Value and arrow */
        .wallet_lp_item_value {
          display: flex;
          justify-content: space-between;
          align-items: center;

          path {
            fill: ${(props) => props.theme.form_text};
          }
        }
      }
      .wallet_lp_item_body {
        margin: 10px 0.5rem;
        padding-top: 10px;
        border-top: 1px solid ${(props) => props.theme.researchBorder};
        flex-direction: column;
        gap: 10px;
        .wallet_lp_body_line {
          display: flex;
          justify-content: space-between;
          > div {
            display: flex;
            align-items: center;
            gap: 6px;
          }
          img,
          svg {
            height: 24px;
            width: 24px;
            border-radius: 50%;
          }
        }
      }
    }
  }
  /* Stack LP Grid additional CSS */
  .wallet_data_stack_lp {
    background: ${(props) => props.theme.bg};
    padding: 0.5rem;
    margin-top: 1rem;
    border-radius: 6px;
  }
`;
