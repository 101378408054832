import styled, { css } from "styled-components";

import { breakPoints } from "../../Theme";

export const StrategyCardWrapper = styled.div`
  display: flex;
  margin-bottom: 2.5rem;
  padding: 1rem;
  gap: 0.75rem;
  background: ${(props) => props.theme.secondaryBg};
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.researchBorder};
  .strategy_options_section {
    max-width: 62px;
    .strategy_builder_icon {
      height: 62px;
      width: 62px;
      margin-bottom: ${(props) => props.showBody && `1rem`};
      display: flex;
      align-items: center;
      justify-content: center;
      background: repeating-linear-gradient(
        45deg,
        ${(props) => props.theme.darkGrey},
        ${(props) => props.theme.darkGrey} 16px,
        ${(props) => props.theme.bg} 16px,
        ${(props) => props.theme.bg} 32px
      );
      border-radius: 6px;
      border: 1px solid ${(props) => props.theme.researchBorder};
    }
    .strategy_options {
      width: 100%;
      background: ${(props) => props.theme.bg};
      border: 1px solid ${(props) => props.theme.researchBorder};
      border-radius: 6px;
      padding: 0.75rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .strategy_option {
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 38px;
          margin-bottom: 0.25rem;
          background: ${(props) => props.theme.secondaryBg};
          border: 1px solid ${(props) => props.theme.researchBorder};
          border-radius: 6px;
          :hover {
            background: ${(props) => props.theme.secondaryBtnHover};
          }
        }
        .strategy_selected {
          border-color: ${(props) => props.theme.brandBlue};
          background: ${(props) => props.theme.blueTransparent};
        }
        h6 {
          font-size: 8px;
          text-align: center;
        }
      }
    }
    @media (max-width: ${breakPoints.lg}px) {
      display: none;
    }
  }
`;

export const StrategyMainSection = styled.div`
  flex: 1;
  .strategy_main_header {
    border-bottom: ${(props) => props.showBody && `1px solid ${props.theme.researchBorder}`};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: ${(props) => props.showBody && `1rem`};

    h2 {
      display: flex;
      font-size: 20px;
      margin-bottom: 0.75rem;
      gap: 0.75rem;
    }
    button {
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${(props) => props.theme.disabled};
      border: 1px solid ${(props) => props.theme.researchBorder};
      border-radius: 6px;
      :hover {
        background: ${(props) => props.theme.secondaryBtnHover};
      }
      svg {
        height: 13px;
        width: 13px;
      }
    }
    .strategy_tags {
      display: flex;
      gap: 1rem;
      > div {
        border-radius: 6px;
        width: max-content;
        background: ${(props) => props.theme.disabled};
        padding: 0.25rem 0.5rem;
        font-size: 12px;
      }
    }
  }
  .strategy_main_body {
    margin-top: 1rem;
    .strategy_groups {
      display: grid;
      gap: 1rem;
      grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
      @media (max-width: ${breakPoints.lg}px) {
        grid-template-columns: 1fr;
      }
    }
  }
`;

export const StrategyGroupItem = styled.div`
  .strategy_group_title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    h4 {
      font-size: 14px;
      color: ${(props) => props.theme.lightGrey};
    }
    button {
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${(props) => props.theme.disabled};
      border: 1px solid ${(props) => props.theme.researchBorder};
      border-radius: 6px;
      :hover {
        background: ${(props) => props.theme.secondaryBtnHover};
      }
      svg {
        height: 13px;
        width: 13px;
      }
    }
  }

  .strategy_group_actions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    margin-bottom: 1rem;
    background: ${(props) => props.theme.bg};
    border-radius: 6px;
    border: 1px solid ${(props) => props.theme.researchBorder};
  }
`;

export const StrategyAction = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  gap: 10px;
  padding: 0.75rem 1rem;
  background: ${(props) => props.theme.secondaryBg};
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.researchBorder};
  cursor: pointer;
  :hover {
    background: ${(props) => props.theme.blueTransparent};
    border-color: ${(props) => props.theme.brandBlue};
    .strategy_action_buttons {
      display: flex;
    }
    .strategy_action_number {
      background: ${(props) => props.theme.blueTransparent};
      border-color: ${(props) => props.theme.brandBlue};
    }
  }
  .strategy_action_number {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    background: ${(props) => props.theme.secondaryBg};
    height: 24px;
    width: 24px;
    border-radius: 50%;
    border: 1px solid ${(props) => props.theme.researchBorder};
    font-size: 14px;
    line-height: 14px;
    transform: translate(-35%, -35%);
  }
  > img {
    height: 32px;
    width: 32px;
  }
  .strategy_action_name {
    flex: 1;
    h5 {
      font-size: 16px;
    }
    h6 {
      font-size: 14px;
      color: ${(props) => props.theme.mainDarketText};
    }
  }
  .strategy_action_buttons {
    display: none;
    align-items: center;
    gap: 0.75rem;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${(props) => props.theme.disabled};
      height: 34px;
      width: 34px;
      border-radius: 6px;
      border: 1px solid ${(props) => props.theme.researchBorder};
      :hover {
        background: ${(props) => props.theme.secondaryBtnHover};
      }
    }
  }
`;

export const CreateButton = styled.button`
  /* max-width: 306px; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 63px;
  border-radius: 6px;
  border: 1px dashed ${(props) => props.theme.researchBorder};
  background: ${(props) => props.theme.widgetCardBg};
  color: ${(props) => props.theme.mainDarkText};
  :hover {
    background: ${(props) => props.theme.secondaryBtnHover};
  }
  ${(props) =>
    props.newGroup &&
    css`
      background: 0;
      padding: 0.5rem 2rem;
      width: auto;
      height: auto;
      margin-bottom: 1rem;
      svg {
        height: 24px;
        width: 24px;
      }
    `}
`;
