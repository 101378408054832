import styled from "styled-components";

import { breakPoints } from "../../Theme";

export const ActionsWrapper = styled.div`
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
  h1 {
    font-size: 24px;
    margin-bottom: 2.5rem;
    @media (max-width: ${breakPoints.md}px) {
      display: none;
    }
  }
`;

export const ActionsContainer = styled.div`
  min-height: 450px;
  display: flex;
  /* align-items: center; */
  gap: 1rem;
`;

export const ActionsTemplateList = styled.div`
  flex: 1;
  .action_template_platform {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: 6px;
    border: 1px solid ${(props) => props.theme.researchBorder};
    margin-bottom: 1rem;
    cursor: pointer;
    img {
      height: 32px;
      width: 32px;
      margin-right: 10px;
    }
    span {
      flex: 1;
    }
    .action_arrow_down {
      path {
        fill: ${(props) => props.theme.mainDarkText};
      }
    }
  }
  .action_platform_action {
    width: 90%;
    margin-left: auto;
    background: ${(props) => props.theme.secondaryBg};
  }
  .action_open {
    background: ${(props) => props.theme.secondaryBg};
  }
`;

export const ActionsTransactionQueue = styled.div`
  flex: 1;
  border: 1px solid ${(props) => props.theme.researchBorder};
  border-radius: 6px;
  padding: 1.5rem;
  background: ${(props) => props.theme.secondaryBg};
  h3 {
    margin-bottom: 1rem;
  }
  .actions_no_tx_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .action_no_tx {
      width: 100%;
      margin: 0 auto 1rem;
    }
    p {
      color: ${(props) => props.theme.lightGrey};
      font-size: 14px;
    }
  }
`;

export const TxQueueItem = styled.div`
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.bg};
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.researchBorder};
  margin-bottom: 1rem;
  .tx_item_main {
    padding: 1rem;
    padding-right: 0;
    cursor: pointer;
    flex: 1;
    display: flex;
    align-items: center;
    img {
      height: 32px;
      width: 32px;
      margin-right: 0.5rem;
    }
    span {
      flex: 1;
    }
  }
  .tx_item_buttons {
    display: flex;
    gap: 0.75rem;
    margin-left: 0.75rem;
    margin-right: 1rem;
    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 34px;
      width: 33px;
      background: ${(props) => props.theme.disabled};
      border-radius: 6px;
      border: 1px solid ${(props) => props.theme.researchBorder};
      :hover {
        background: ${(props) => props.theme.secondaryBtnHover};
      }
    }
  }
`;
