import React from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import PWOpen from "../../assets/HidePasswordOpenEye.svg";
import { trimAddress } from "../../helpers";
import { userDataOperations } from "../../redux/userDataSlice";
import Modal from "../Modal";
import { WalletTable } from "./WalletContainer.helpers";

const Wrapper = styled.div`
  padding: 2rem 0 1rem;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    margin-left: auto;
  }
`;

const RemovedTokenModal = ({ open, handleCloseModal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector((state) => state.userData.user);
  const { tokenBlacklist } = user;

  const handleRemoveToken = (token) => dispatch(userDataOperations.removeTokenBlacklist(token));
  return (
    <Modal open={open} handleCloseModal={handleCloseModal} width="600px" maxwidth="600px" title="Removed tokens">
      <Wrapper>
        {" "}
        <WalletTable secondary>
          <thead>
            <th>{t("common.asset")}</th>
            <th>Address</th>
            <th />{" "}
          </thead>
          <tbody>
            {tokenBlacklist.map((item) => (
              <tr key={item.address + Math.random()}>
                <td>{item.symbol}</td>
                <td>{trimAddress(item.address)}</td>
                <td>
                  <button onClick={() => handleRemoveToken(item)}>
                    <img src={PWOpen} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>{" "}
        </WalletTable>
      </Wrapper>
    </Modal>
  );
};

export default RemovedTokenModal;
