import React from "react";

import moment from "moment";
import styled from "styled-components";

const Wrapper = styled.div`
  .strategy_dates {
    display: flex;
    gap: 1.5rem;
    margin-bottom: 1rem;
    h6 {
      font-size: 12px;
      color: ${(props) => props.theme.nonHighlighted};
      margin-bottom: 3px;
    }
  }
  p {
    font-size: 14px;
    color: ${(props) => props.theme.lightGrey};
  }
`;

const StrategyInfo = ({ strategy }) => {
  return (
    <Wrapper>
      <div className="strategy_dates">
        <div className="strategy_date">
          <h6>Start date:</h6>
          <h4>{moment(strategy.startDate).format("DD MMMM, YYYY")}</h4>
        </div>
        {strategy.endDate && (
          <div className="strategy_date">
            <h6>End date:</h6>
            <h4>{moment(strategy.endDate).format("DD MMMM, YYYY")}</h4>
          </div>
        )}
      </div>
      <p>{strategy.description}</p>
    </Wrapper>
  );
};

export default StrategyInfo;
