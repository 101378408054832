import React, { forwardRef } from "react";

import { Link } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.div`
  position: absolute;
  top: 120%;
  right: 0;
  width: 200px;
  display: none;
  background: ${(props) => props.theme.secondaryBg};
  border: 1px solid ${(props) => props.theme.researchBorder};
  border-radius: 6px;
  z-index: 2;
  ::after {
    z-index: 9;
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 100%;
    height: 9px;
    width: 100%;
  }
  .user_options_links {
    padding: 0.5rem 0;
    button {
      margin: 0 !important;
    }
  }
  a,
  button {
    display: block;
    padding: 0.5rem 1.5rem;
    border: 0;
    background: 0;
    width: 100%;
    text-align: left;
    font-size: 16px;
    color: ${(props) => props.theme.mainDarkText};
    :last-child {
      border-bottom: 0;
    }
    :hover {
      background: ${(props) => props.theme.bg};
    }
  }
  button {
    margin: 0.5rem 0;
  }
  .user_options_line {
    height: 1px;
    background: ${(props) => props.theme.researchBorder};
    width: 166px;
    margin: 0 auto;
  }
`;

const WalletCard = forwardRef(({ handleShowModal, handleDisconnect, ...attrs }, ref) => {
  return (
    <Wrapper ref={ref} {...attrs}>
      <div className="user_options_links">
        <button onClick={handleShowModal}>Change</button>
        <Link
          to="/wallets"
          onClick={() => {
            ref.current.style.display = "none";
          }}
        >
          Manage Wallets
        </Link>
      </div>
      <div className="user_options_line" />
      <button onClick={handleDisconnect}>Disconnect</button>
    </Wrapper>
  );
});

WalletCard.displayName = "WalletCard";

export default WalletCard;
