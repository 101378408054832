import styled, { css } from "styled-components";

import { breakPoints } from "../Theme";

export const BlueTextCard = styled.div`
  padding: 1.5em 1em;
  border-radius: 6px;
  color: ${(props) => props.theme.default_color};
  background: ${(props) => props.theme.bg};
  font-family: "Telex", sans-serif;
  font-size: 14px;
  line-height: 1.3;
  margin: 0px auto 2rem auto;
  max-width: 512px;
  @media (max-width: ${breakPoints.lg}px) {
    max-width: 100%;
  }
`;

export const PoolsCardWrapper = styled.div`
  background: ${(props) => props.theme.gradientBg};
  color: ${(props) => props.theme.default_color};
  padding: 24px;
  border: 1px solid ${(props) => props.theme.borderBg};
  border-radius: 6px;
  margin: 0 auto 4em;
  width: 560px;
  @media (max-width: ${breakPoints.lg}px) {
    margin: 0 auto 2em;
    padding: 16px;
    width: 100%;
  }
`;

export const CardWrapper = styled.div`
  background: ${(props) => props.theme.superLightBlue};
  color: ${(props) => props.theme.mainDarkText};
  border: 1px solid ${(props) => props.theme.lightBlue3};

  padding: 24px;
  border-radius: 6px;
`;
export const CardTitle = styled.div`
  color: ${(props) => props.theme.mainDarkText};
  font-weight: 500;
  font-size: 1.5em;
  margin-left: -1em;
  margin-top: -3em;
  position: absolute;
`;

export const SecondTitle = styled.div`
  color: ${(props) => props.theme.default_color};
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 7px;
`;

export const PlatformSelect = styled.div`
  margin-bottom: 24px;
`;

export const BoldTitle = styled.div`
  color: ${(props) => props.theme.default_color};
  font-weight: 500;
  font-size: 24px;
  margin: 0 auto 1.2em;
  width: 560px;

  @media (max-width: ${breakPoints.lg}px) {
    margin: 2em auto 1.2em;
    width: 100%;
  }
`;

export const InsideBoldTitle = styled.div`
  color: ${(props) => props.theme.default_color};
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 1.2em;
  width: 560px;

  @media (max-width: ${breakPoints.lg}px) {
    width: 100%;
  }
`;

export const PoolsInnerWrapper = styled.div`
  color: ${(props) => props.theme.mainDarkText};
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5rem;
  .InnerWrapper__inputsgroup {
    position: relative;

    .inputsgroup__floating-left,
    .inputsgroup__floating-right {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      position: absolute;
      top: 0.5rem;
      color: ${(props) => props.theme.default_color};
      .value {
        max-width: 62px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: clip;
        display: inline-flex;
        margin-right: 3px;
      }
    }

    .inputsgroup__floating-left {
      left: 1rem;
    }

    @media (max-width: ${breakPoints.md}px) {
      .inputsgroup__floating-left {
        left: 0.75rem;
      }
    }

    .inputsgroup__floating-right {
      right: 1rem;
    }
  }

  .InnerWrapper__infogroup {
    margin: 1.5rem 0 0.5rem;
    color: ${(props) => props.theme.default_color};
  }

  .infogroup__title {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 22px;
  }

  .error {
    margin: 5px 0 5px 1rem;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: ${(props) => props.theme.error};

    @media (max-width: ${breakPoints.md}px) {
      margin: -19px 0 5px 1rem;
    }
  }

  button {
    margin-top: 1rem;
  }

  .float {
    position: absolute;
    right: 0;
  }
  .InnerWrapper {
    display: grid;
    grid-template-columns: 3fr 156px;
    grid-column-gap: 1rem;
    margin-bottom: 1.5em;

    @media (max-width: ${breakPoints.md}px) {
      grid-template-columns: 3fr;
      margin-bottom: 2em;
    }

    button {
      align-self: end;
    }
  }

  .InnerWrapper__button {
    margin-bottom: 1rem;
  }

  .InnerWrapper__details {
    margin-bottom: 0;
    padding: 0;
  }
`;

export const PoolDataWrapper = styled.div`
  width: 100%;
  margin: 5px 0 0;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${(props) => props.theme.form_text3};
  border-radius: 6px;
  padding: 0.75rem 1rem;

  span:nth-of-type(odd) {
    font-weight: 500;
    font-size: 1rem;
    line-height: 20px;
    color: ${(props) => props.theme.default_color};
  }
`;

export const DataInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  span:first-of-type {
    font-size: 16px;
  }
  span:last-of-type {
    font-size: 12px;
  }
  /* span:nth-last-of-type(odd) {
    font-weight: 500;
    font-size: 1rem;
    line-height: 20px;
  } */
`;

export const Divider = styled.hr`
  background: ${(props) => props.theme.researchBorder};
  border: 0;
  height: 1px;
  margin: 2rem 0 0;
  width: 100%;
`;

export const IconTextWrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  color: ${(props) => props.theme.default_color};
  svg,
  img {
    margin-right: 8px;
    max-width: 32px;
    max-height: 32px;
  }
`;

export const HeadInfo = styled.div`
  background: ${(props) => props.rewards && "red"};
  color: ${(props) => props.theme.default_color};
  span {
    display: block;
    font-weight: 500;
  }

  .title {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 5px;
  }

  .value {
    font-size: 1rem;
    line-height: 20px;
    color: ${(props) => props.theme.form_text};
    @media (max-width: ${breakPoints.sm}px) {
      margin-bottom: ${(props) => (props.rewardsCard ? "0.75rem" : "1.5rem")};
    }
  }

  .rewards_value {
    margin-bottom: 0rem;
    @media (max-width: ${breakPoints.sm}px) {
      margin-bottom: 0.75rem;
    }
  }
`;

export const BlueIconTextTitle = styled(IconTextWrapper)`
  color: ${(props) => props.theme.brandBlue};
  margin-bottom: 2rem;
  font-size: 20px;
  line-height: 24px;

  img,
  svg {
    width: 32px;
    height: 32px;
  }
`;

export const PoolTokenCard = styled.div`
  cursor: pointer;
  height: 32px;
  padding: 8px;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  border: 1px solid ${(props) => props.theme.researchBorder};
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin: 0px 0.5rem 0.5rem 0px;

  .token__name {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
    img,
    svg {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }

  .token__value {
    color: ${(props) => props.theme.form_text};
  }
  :hover {
    background: ${(props) => props.theme.researchBorder};
  }
`;

export const Skeleton = styled.div`
  border-radius: 2px;
  height: ${(props) => (props.height ? `${props.height}px` : "30px")};
  width: ${(props) => (props.width ? `${props.width}px` : "70%")};
  position: relative;
  background-color: ${(props) => (props.white ? `#DFEDFF` : props.theme.skeletonBg)};
  overflow: hidden;
  margin-left: ${(props) => (props.marginLeft ? "auto" : "0")};
  border-radius: ${(props) => props.circle && "50%"};
  ::before {
    content: "";
    display: block;
    position: absolute;
    left: -100%;
    top: 0;
    height: 100%;
    width: 100%;
    background: ${(props) =>
      props.white
        ? `linear-gradient(
      to right,
      rgba(223, 237, 255, 0.4) 0%,
      #CBE2FF 100%
    )`
        : props.theme.skeletonLoader};
    animation: load 1.2s ease-in-out infinite;
  }

  @keyframes load {
    from {
      transform: translateX(0) rotate(180deg);
    }
    to {
      transform: translateX(200%) rotate(180deg);
    }
  }
`;

export const LoginForm = styled.form`
  font-family: Montserrat;
  width: 100%;
  max-width: 560px;
  margin: 48px auto;
  padding: 0 1.25rem;
  h2 {
    color: white;
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 1.5rem;
  }
  .inputContainer {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    border-radius: 6px;
    background: ${(props) => props.theme.gradientBg};
    border: 1px solid ${(props) => props.theme.researchBorder};

    h4 {
      font-size: 14px;
      color: ${(props) => props.theme.default_color};
      margin-bottom: 1.5rem;
    }
    label {
      color: ${(props) => props.theme.lightDarkGrey};
      font-weight: 400;
      margin-bottom: 0.5rem;
      font-size: 12px;
    }
    .user_input_field {
      margin-bottom: 1.5rem;
    }
    a {
      margin: 1.5rem 0;
      width: max-content;
      color: ${(props) => props.theme.form_text};
      text-decoration: underline;
    }
    .signin_forgotpassword {
      font-size: 14px;
    }
    @media (max-width: ${breakPoints.md}px) {
      padding: 0.75rem;
    }
  }
  #result {
    color: aquamarine;
  }
`;

export const InputField = styled.input`
  font-family: Montserrat;
  background: ${(props) => props.theme.bg};
  color: ${(props) => (props.error ? props.theme.error : props.theme.default_color)};
  border: 1px solid ${(props) => (props.error ? props.theme.error : `#3a4c61`)};
  border-radius: 6px;
  padding: 0.75rem;
  font-size: 16px;
  margin-bottom: 1.5rem;
  transition: all 150ms;
  :last-of-type {
    margin-bottom: 0rem;
  }
  ::placeholder {
    color: ${(props) => props.theme.input_placefolder};
    font-weight: 500;
  }
  :focus {
    outline: none;
    box-shadow: 0px 0px 10px 0px rgba(73, 132, 211, 0.25);
    border-color: #4984d3;
  }
`;

export const FormButton = styled.button`
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  color: ${(props) => (props.disabled ? props.theme.borderBg : props.theme.superLightBlue)};
  font-family: Montserrat;
  background: ${(props) => (props.disabled ? props.theme.form_text3 : props.theme.brandBlue)};
  padding: 0.75rem 2.5rem;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 500;
  border: none;
  border-radius: 6px;
  transition: all 150ms;
  text-align: center;
  width: max-content;
  ${(props) =>
    props.marginTop &&
    css`
      margin-top: 1.5rem;
    `}
  @media (max-width: ${breakPoints.md}px) {
    width: 100%;
  }
`;

export const BillingFormButton = styled.div`
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  color: ${(props) => (props.disabled ? props.theme.borderBg : props.theme.superLightBlue)};
  font-family: Montserrat;
  background: ${(props) => (props.disabled ? props.theme.form_text3 : props.theme.brandBlue)};
  padding: 0.5rem 0;
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 500;
  border: none;
  border-radius: 6px;
  transition: all 150ms;
  height: fit-content;
  text-align: center;
  ${(props) =>
    props.marginTop &&
    css`
      margin-top: 1.5rem;
    `}
`;

export const ErrorMessage = styled.div`
  margin-top: 6px;
  font-weight: 500;
  font-size: 12px;
  color: ${(props) => (props.success ? props.theme.green : props.theme.error)};
`;

export const SpinningLoader = styled.div`
  padding: 2rem 0;
  > div {
    .loader {
      position: relative;
      margin: 0px auto;
      width: 80px;
      height: 80px;
      :before {
        content: "";
        display: block;
        padding-top: 100%;
      }

      .circular-loader {
        -webkit-animation: rotate 2s linear infinite;
        animation: rotate 2s linear infinite;
        height: 100%;
        -webkit-transform-origin: center center;
        -ms-transform-origin: center center;
        transform-origin: center center;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        margin: auto;
      }

      .loader-path {
        stroke-dasharray: 150, 200;
        stroke-dashoffset: -10;
        -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
        animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
        stroke-linecap: round;
      }

      @-webkit-keyframes rotate {
        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }

      @keyframes rotate {
        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
      @-webkit-keyframes dash {
        0% {
          stroke-dasharray: 1, 200;
          stroke-dashoffset: 0;
        }
        50% {
          stroke-dasharray: 89, 200;
          stroke-dashoffset: -35;
        }
        100% {
          stroke-dasharray: 89, 200;
          stroke-dashoffset: -124;
        }
      }
      @keyframes dash {
        0% {
          stroke-dasharray: 1, 200;
          stroke-dashoffset: 0;
        }
        50% {
          stroke-dasharray: 89, 200;
          stroke-dashoffset: -35;
        }
        100% {
          stroke-dasharray: 89, 200;
          stroke-dashoffset: -124;
        }
      }
      @-webkit-keyframes color {
        0% {
          stroke: ${(props) => props.theme.brandBlue};
        }
        40% {
          stroke: ${(props) => props.theme.brandBlue};
        }
        66% {
          stroke: ${(props) => props.theme.brandBlue};
        }
        80%,
        90% {
          stroke: ${(props) => props.theme.brandBlue};
        }
      }
      @keyframes color {
        0% {
          stroke: ${(props) => props.theme.brandBlue};
        }
        40% {
          stroke: ${(props) => props.theme.brandBlue};
        }
        66% {
          stroke: ${(props) => props.theme.brandBlue};
        }
        80%,
        90% {
          stroke: ${(props) => props.theme.brandBlue};
        }
      }
    }
  }
`;

export const SpinningLoaderv2 = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    .widget_load {
      width: 80px;
      height: 80px;
      margin: 110px auto 0;
      border: solid 7px #58afff;
      border-radius: 50%;
      border-right-color: transparent;
      border-bottom-color: transparent;
      -webkit-transition: all 0.5s ease-in;
      -webkit-animation-name: rotate;
      -webkit-animation-duration: 1s;
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-timing-function: linear;

      transition: all 0.5s ease-in;
      animation-name: rotate;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
   @keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to { 
        transform: rotate(360deg);
    }
  }
`;

export const Spinner = styled.div`
  font-size: 12px;
  margin: 10px auto;
  width: 8em;
  height: 8em;
  border-radius: 50%;
  background: linear-gradient(to right, ${(props) => props.theme.brandBlue} 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  animation: load3 1.4s infinite linear;
  transform: translateZ(0);

  :before {
    width: 50%;
    height: 50%;
    background: ${(props) => (props.background ? props.theme[props.background] : "#ffffff")};
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
  }
  :after {
    background: ${(props) => (props.background ? props.theme[props.background] : "#ffffff")};
    width: 85%;
    height: 85%;
    border-radius: 50%;
    content: "";
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  @-webkit-keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
