import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { stacksOperations } from "./stacksSlice";

const API = process.env.REACT_APP_PRO_API;

const initialState = {
  isLoading: false,
  data: { title: "", addresses: [], _id: "" },
};

const editStack = createSlice({
  name: "editStack",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setData(state, action) {
      state.data = action.payload;
    },
    setTitle(state, action) {
      state.data.title = action.payload;
    },
    setAddresses(state, action) {
      state.data.addresses = action.payload;
    },
  },
});

export const editStackActions = editStack.actions;

const getRoot = (state) => state.editStack;

export const editStackSelectors = {
  getLoading: (state) => getRoot(state).isLoading,
  getData: (state) => getRoot(state).data,
  getTitle: (state) => getRoot(state).data.title,
  getAddresses: (state) => getRoot(state).data.addresses,
  getID: (state) => getRoot(state).data._id,
};

export const editStackOperations = {
  addData: (stack) => (dispatch) => {
    stack = { ...stack, addresses: stack.addresses.map((item) => item._id) };
    dispatch(editStackActions.setData(stack));
  },
  handleAddAllAddresses: (addAll) => (dispatch, getState) => {
    if (addAll) {
      const userAddresses = getState().addresses.addresses;
      dispatch(editStackActions.setAddresses(userAddresses.map((item) => item._id)));
      return;
    }
    dispatch(editStackActions.setAddresses([]));
  },
  handleAddress: (addressId, add) => (dispatch, getState) => {
    const addresses = getState().editStack.data.addresses;
    if (add) {
      dispatch(editStackActions.setAddresses([...addresses, addressId]));
      return;
    }
    dispatch(editStackActions.setAddresses([...addresses].filter((id) => id !== addressId)));
  },
  postEditStack: () => async (dispatch, getState) => {
    dispatch(editStackActions.setLoading(true));
    const title = editStackSelectors.getTitle(getState());
    const addresses = editStackSelectors.getAddresses(getState());
    const _id = editStackSelectors.getID(getState());
    const token = getState().userData.tokens.access.token;
    const config = {
      headers: {
        Authorization: token,
      },
    };
    try {
      await axios.patch(`${API}/v1/wallet/stack`, { title, addresses, _id }, config);
      dispatch(stacksOperations.fetchUserStacks());
      return;
    } catch (err) {
      console.log(err);
    }
    dispatch(editStackActions.setLoading(false));
    return;
  },
  handleDelete: () => async (dispatch, getState) => {
    dispatch(editStackActions.setLoading(true));
    const _id = editStackSelectors.getID(getState());
    const token = getState().userData.tokens.access.token;
    const config = {
      headers: {
        Authorization: token,
      },
    };
    try {
      await axios.delete(`${API}/v1/wallet/stack/${_id}`, config);
      dispatch(stacksOperations.fetchUserStacks());
      return;
    } catch (err) {
      console.log(err);
    }
    dispatch(editStackActions.setLoading(false));
    return;
  },
};

export const editStackReducer = editStack.reducer;
