import styled, { css } from "styled-components";

import { breakPoints } from "../../Theme";

export const StrategyBuilderWrapper = styled.div`
  /* max-width: 1200px; */
  width: max-content;
  margin: 0 auto;
  .strategy_builder_back {
    display: flex;
    align-items: center;
    gap: 5px;
    background: ${(props) => props.theme.secondaryBg};
    color: ${(props) => props.theme.mainDarkText};
    padding: 5px 1rem;
    border: 1px solid ${(props) => props.theme.researchBorder};
    border-radius: 6px;
    height: max-content;
    font-size: 12px;
    svg {
      width: 12px;
      path {
        fill: #738ca6;
      }
    }
    :hover {
      background: ${(props) => props.theme.secondaryBtnHover};
    }
  }
  .strategy_builder_header {
    h1 {
      margin-bottom: 1rem;
      font-size: 24px;
    }
    h3 {
      margin-bottom: 0.75rem;
      font-size: 18px;
      span {
        color: ${(props) => props.theme.form_text};
      }
    }
  }
`;

export const AddTagModal = styled.div`
  padding: 1rem 0;
  label {
    display: block;
    padding: 0;
    font-size: 12px;
    line-height: 16px;
    color: ${(props) => props.theme.lightGrey};
    margin-bottom: 0.5rem;
  }
  input,
  textarea {
    width: 100%;
    font-size: 16px;
    background: ${(props) => props.theme.bg};
    padding: 0.75rem 1rem;
    color: ${(props) => props.theme.mainDarkText};
    border-radius: 6px;
    border: 1px solid ${(props) => props.theme.researchBorder};
    margin-bottom: 2rem;
    resize: none;
    transition: all 0.2s;
    ::placeholder {
      color: ${(props) => props.theme.placeholderText};
    }
    :focus {
      box-shadow: 0px 0px 10px 0px #4984d340;
      border-color: ${(props) => props.theme.border_color};
    }
  }
  .add_tag_buttons {
    display: flex;
    gap: 1.5rem;
    margin-bottom: 0.5rem;
    button {
      padding: 0.75rem 2.5rem;
      border-radius: 6px;
      border: 1px solid transparent;
      color: ${(props) => props.theme.mainDarkText};
      transition: all 0.2s;
    }
    .add_tag_primary {
      color: white;
      background: ${(props) => props.theme.brandBlue};
      :hover {
        background: ${(props) => props.theme.darkBrandBlue};
      }
      :disabled {
        pointer-events: none;
        background: ${(props) => props.theme.disabledText};
        color: ${(props) => props.theme.disabled};
      }
    }
    .add_tag_secondary {
      background: 0;
      border-color: ${(props) => props.theme.researchBorder};
      :hover {
        background: ${(props) => props.theme.secondaryBtnHover};
      }
    }
  }
`;

export const CreateStrategyForm = styled.form`
  width: 100%;
  max-width: 475px;
  background: ${(props) => props.theme.secondaryBg};
  border: 1px solid ${(props) => props.theme.researchBorder};
  border-radius: 6px;
  padding: 1rem 1.5rem;
  @media (max-width: ${breakPoints.md}px) {
    padding: 0.5rem 0.75rem;
  }
  label {
    display: block;
    padding: 0;
    font-size: 12px;
    line-height: 16px;
    color: ${(props) => props.theme.lightGrey};
    margin-bottom: 0.5rem;
  }
  input,
  textarea {
    width: 100%;
    font-size: 16px;
    background: ${(props) => props.theme.bg};
    padding: 0.75rem 1rem;
    color: ${(props) => props.theme.mainDarkText};
    border-radius: 6px;
    border: 1px solid ${(props) => props.theme.researchBorder};
    margin-bottom: 1rem;
    resize: none;
    transition: all 0.2s;
    ::placeholder {
      color: ${(props) => props.theme.placeholderText};
    }
    :focus {
      box-shadow: 0px 0px 10px 0px #4984d340;
      border-color: ${(props) => props.theme.border_color};
    }
  }

  .strategy_select {
    font-size: 16px;
    margin-bottom: 1rem;
    .dropdown_selected,
    .dropdown_option {
      padding: 10px 1rem;
    }
  }
  .strategy_tabs {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  .strategy_dates {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  .strategy_next {
    display: flex;
    align-items: center;
    width: max-content;
    padding: 0.75rem 2.5rem;
    margin-bottom: 2rem;
    color: white;
    background: ${(props) => props.theme.brandBlue};
    border-radius: 6px;
    border: 0;
    transition: all 0.2s;

    :hover {
      background: ${(props) => props.theme.darkBrandBlue};
    }
    :active {
      background: ${(props) => props.theme.pressedBrandBlue};
    }
  }
  .strategy_next_disabled {
    pointer-events: none;
    background: ${(props) => props.theme.disabledText};
    color: ${(props) => props.theme.disabled};
    svg {
      path {
        fill: ${(props) => props.theme.disabled};
      }
    }
  }
  ${(props) =>
    props.editStrategy &&
    css`
      width: 100%;
      max-width: none;
      border: 0;
      padding: 1rem 0;
      .strategy_next {
        margin-bottom: 0;
      }
      .strategy_buttons {
        display: flex;
        gap: 1rem;
        > button {
          display: flex;
          align-items: center;
          gap: 0.5rem;

          path {
            fill: white;
          }
        }
      }
    `}
`;

export const CreateStrategyDate = styled.div`
  .react-datepicker {
    border: none;
    color: ${(props) => props.theme.mainDarkText};
    background: 0;
    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker__month-container {
      background: #232e3a;
      border: 1px solid ${(props) => props.theme.researchBorder};
      border-radius: 6px;
      .react-datepicker__header {
        background: #232e3a;
        color: ${(props) => props.theme.mainDarkText};
        border: 0px;
        .react-datepicker__day-name {
          color: ${(props) => props.theme.mainDarkText};
        }
      }

      .react-datepicker__day--weekend,
      .react-datepicker__day {
        background-color: ${(props) => props.theme.bg};
        color: ${(props) => props.theme.mainDarkText};
        border-radius: 6px;
        :hover {
          background: ${(props) => props.theme.darkBrandBlue};
        }
      }
      .react-datepicker__day--selected {
        background: #005ad3;
        color: ${(props) => props.theme.mainDarkText};
      }
      .react-datepicker__day--outside-month {
        color: ${(props) => props.theme.disabledText};
        background: transparent;
      }
      .react-datepicker__current-month {
        color: ${(props) => props.theme.mainDarkText};
      }
      .react-datepicker__day--disabled {
        background: 0;
        color: ${(props) => props.theme.disabledText};
      }
    }
  }
`;

export const CreateStrategyTab = styled.div`
  padding: 0.75rem 1rem;
  background: ${(props) => props.theme.bg};
  display: flex;
  align-items: center;
  width: max-content;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.researchBorder};
  line-height: 20px;
  gap: 0.75rem;
  cursor: pointer;
  svg {
    height: 18px;
    width: 18px;
    path {
      stroke: ${(props) => props.theme.mainDarkText};
    }
  }
`;

export const CreateStrategyButton = styled(CreateStrategyTab)`
  cursor: pointer;
  background: ${(props) => props.theme.inputPlaceholder};
  border-style: dashed;
  display: flex;
  align-items: center;
  :hover {
    background: ${(props) => props.theme.researchBorder};
    border-color: ${(props) => props.theme.lightGrey};
  }
`;
