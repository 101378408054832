import axios from "axios";

import { userDataOperations } from "../userDataSlice";

const APIUrl = process.env.REACT_APP_PRO_API;

export function createSubscription(cardInfo) {
  return function (dispatch, getState) {
    const url = `${APIUrl}/v1/billing/create-subscription`;
    const auth_token = getState().userData.tokens.access.token;

    axios
      .post(url, cardInfo, {
        headers: {
          Authorization: `${auth_token}`,
        },
      })
      .then(function (response) {
        dispatch({
          type: "FETCH_PRICE_SUCCESS",
          subscription: response.data,
        });
      })
      .catch((error) => {
        if (error.response.status === 401) dispatch(userDataOperations.logout());
      });
  };
}

export function getEndDate() {
  return function (dispatch, getState) {
    const url = `${APIUrl}/v1/billing/get-enddate`;
    const auth_token = getState().userData.tokens.access.token;
    const data = {};

    axios
      .post(url, data, {
        headers: {
          Authorization: `${auth_token}`,
        },
      })
      .then(function (response) {
        dispatch({
          type: "FETCH_ENDDATE_SUCCESS",
          subscription: response.data,
        });
      })
      .catch((error) => {
        if (error.response.status === 401) dispatch(userDataOperations.logout());
      });
  };
}
