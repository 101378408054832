import styled, { css } from "styled-components";

import { breakPoints } from "../../Theme";

export const LineBreak = styled.div`
  flex-direction: column;
  border-bottom: 1px solid ${(props) => props.theme.input_placefolder};
  margin: 0 auto;
  margin-top: 10px;
`;

export const VerticalLineBreak = styled.div`
  border-left: 2px solid ${(props) => props.theme.input_placefolder};
  margin: 0 ${(props) => props.margin};
  height: ${(props) => props.height};
`;

export const Wrapper = styled.div`
  overflow-y: scroll;
  background: ${(props) => props.theme.secondaryBg};
  margin-top: 1em;
  border-radius: 6px;
  ::-webkit-scrollbar {
    display: none;
  }

  ${(props) =>
    props.view == "card" &&
    css`
      background: ${(props) => props.theme.bg};
    `}

  @media (max-width: ${breakPoints.lg}px) {
    background: ${(props) => props.theme.bg};
    margin: 0;
  }
`;

export const FeedWrap = styled.div`
  /* margin-top: 1rem; */
  padding: 0 1rem;
  border-radius: 6px;
  ${(props) =>
    props.view == "card" &&
    css`
      background: ${(props) => props.theme.bg};
      margin: 0;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      height: 100%;
      gap: 1.5rem;
      padding: 0;
    `}

  @media (max-width: ${breakPoints.lg}px) {
    padding: 0;
  }
`;

export const Wrap = styled.div`
  display: flex;
  padding: 10px 0;

  :not(:last-of-type) {
    border-bottom: 1px solid ${(props) => props.theme.researchBorder};
  }

  img {
    width: 2.25em;
    height: 2.25em;
    margin-right: 0.625em;
  }
  h5 {
    cursor: pointer;
    font-size: 1em;
    color: ${(props) => props.theme.text};
  }
  .sub-text {
    display: flex;
    margin-top: 2px;
  }

  .decrease,
  .increase {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .decrease {
    margin-left: 0.75em;
  }

  p {
    font-weight: 500;
    font-size: 0.75em;
    color: ${(props) => props.theme.lightGrey};
  }

  .tickets {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    padding-left: 1em;

    @media (max-width: ${breakPoints.sm}px) {
      justify-content: left;
      margin-left: 0;
      padding-left: 0;
    }
  }

  .vote {
    display: flex;
    align-items: center;
  }

  .voteIcon {
    width: 10px;
    height: 10px;
    margin-right: 4px;
  }

  .symbol {
    color: ${(props) => props.theme.form_text};

    @media (max-width: ${breakPoints.sm}px) {
      margin-top: 4px;
    }
  }

  span {
    margin-left: 3px;
  }
`;

export const Popup = styled.div`
  position: relative;
  width: 1024px;
  height: 558px;
  background: ${(props) => props.theme.secondaryBg};
  border-left: 1px solid ${(props) => props.theme.input_placefolder};
`;

export const CardWrap = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.secondaryBg};
  width: 100%;
  border: 1px solid ${(props) => props.theme.input_placefolder};
  border-radius: 6px;
  padding: 0.75rem 0.75rem 1rem;
  cursor: pointer;

  img {
    width: 100%;
  }

  .cover {
    width: 100%;
    position: relative;
    margin-bottom: 8px;
  }

  .textWrap {
    width: 100%;
    height: 31px;
    position: absolute;
    bottom: 0;
  }

  .coverText {
    width: 100%;
    line-height: 31px;
    padding-left: 8px;
    position: absolute;
    vertical-align: middle;
    opacity: 0.95;
    background: ${(props) => props.theme.bg};
    color: ${(props) => props.theme.lightGrey};
    white-space: nowrap;
    overflow: hidden;
  }

  .voteIcon {
    width: 10px;
    height: 10px;
    margin-right: 4px;
  }

  .infoWrap {
    height: 36%;
  }

  .topic {
    width: 24px;
    height: 24px;
    margin-right: 6px;
  }

  .decrease {
    margin-left: 12px;
  }

  .subText,
  .vote {
    display: flex;
    align-items: center;
  }

  p {
    font-weight: 500;
    font-size: 0.75em;
    color: ${(props) => props.theme.lightGrey};
  }

  .title,
  .text {
    width: 100%;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: 500;
    font-size: 0.75em;
    color: ${(props) => props.theme.lightGrey};
  }

  .vote > p {
    font-size: 0.625em;
    display: flex;
    line-height: 100%;
  }

  .title {
    color: ${(props) => props.theme.default_color};
    margin: 6px 0;
  }

  @media (min-width: ${breakPoints.xxl}px) {
    p,
    .title,
    .text {
      font-size: 1em;
    }
    .vote > p {
      font-size: 0.8em;
    }
  }
`;
