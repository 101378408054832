import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { alertsOperations } from "./alertsSlice";

const API = process.env.REACT_APP_PRO_API;

const initialState = {
  isLoading: false,
  createIsLoading: false,
  error: null,
  startDate: new Date(),
  endDate: null,
  channels: {
    email: false,
    telegram: false,
    discord: false,
    sms: false,
  },
  interestRate: {
    platform: "",
    asset: "",
    value: "",
    conditional: ">",
  },
  gas: {
    network: "ethereum",
    gasRate: "",
    value: "",
  },
  price: {
    asset: "",
    conditional: ">",
    value: "",
  },
  volume: {
    asset: "",
    conditional: ">",
    value: "",
  },
};

const createAlert = createSlice({
  name: "createAlert",
  initialState,
  reducers: {
    setInitialState(state, action) {
      return { ...action.payload };
    },
    setIsLoading(state, action) {
      return { ...state, isLoading: action.payload };
    },
    setCreateIsLoading(state, action) {
      return { ...state, createIsLoading: action.payload };
    },
    setError(state, action) {
      return { ...state, error: action.payload };
    },
    setStartDate(state, action) {
      return { ...state, startDate: action.payload };
    },
    setEndDate(state, action) {
      return { ...state, endDate: action.payload };
    },
    setChannels(state, action) {
      return { ...state, channels: action.payload };
    },
    setInterest(state, action) {
      return { ...state, interestRate: action.payload };
    },
    setGas(state, action) {
      return { ...state, gas: action.payload };
    },
    setPrice(state, action) {
      return { ...state, price: action.payload };
    },
    setVolume(state, action) {
      return { ...state, volume: action.payload };
    },
  },
});

export const createAlertActions = createAlert.actions;

const getRoot = (state) => state.createAlert;

export const createAlertSelectors = {
  getIsLoading: (state) => getRoot(state).isLoading,
  getError: (state) => getRoot(state).error,
  getInterest: (state) => getRoot(state).interestRate,
  getGas: (state) => getRoot(state).gas,
  getEndDate: (state) => getRoot(state).endDate,
  getChannels: (state) => getRoot(state).channels,
  getPrice: (state) => getRoot(state).price,
  getVolume: (state) => getRoot(state).volume,
};

export const createAlertOperations = {
  setInterest: (name, value) => async (dispatch, getState) => {
    const interest = createAlertSelectors.getInterest(getState());
    dispatch(createAlertActions.setInterest({ ...interest, [name]: value }));
  },
  setGas: (name, value) => async (dispatch, getState) => {
    const gas = createAlertSelectors.getGas(getState());
    dispatch(createAlertActions.setGas({ ...gas, [name]: value }));
  },
  setStartDate: (date) => async (dispatch, getState) => {
    dispatch(createAlertActions.setStartDate(date));
    const endDate = createAlertSelectors.getEndDate(getState());
    if (date > endDate) {
      dispatch(createAlertActions.setEndDate(date));
    }
  },
  setChannels: (name, value) => async (dispatch, getState) => {
    const channels = createAlertSelectors.getChannels(getState());
    dispatch(createAlertActions.setChannels({ ...channels, [name]: value }));
  },
  setPrice: (name, value) => async (dispatch, getState) => {
    const price = createAlertSelectors.getPrice(getState());
    dispatch(createAlertActions.setPrice({ ...price, [name]: value }));
  },
  setVolume: (name, value) => async (dispatch, getState) => {
    const volume = createAlertSelectors.getVolume(getState());
    dispatch(createAlertActions.setVolume({ ...volume, [name]: value }));
  },

  createAlert: (type) => async (dispatch, getState) => {
    dispatch(createAlertActions.setCreateIsLoading(true));

    try {
      const token = getState().userData.tokens.access.token;
      const createAlert = getState().createAlert;
      const body = createAlert[type];
      const channels = createAlert.channels;
      const startDate = createAlert.startDate;
      const endDate = createAlert.endDate;

      const config = {
        headers: {
          Authorization: token,
        },
      };
      const { data } = await axios.post(`${API}/v1/alert`, { ...body, ...channels, type, startDate, endDate }, config);
      dispatch(createAlertActions.setInitialState(initialState));
      dispatch(createAlertActions.setCreateIsLoading(false));
      dispatch(alertsOperations.getAlerts());
      return data._id;
    } catch (err) {
      dispatch(createAlertActions.setCreateIsLoading(false));
    }
  },
};

export const createAlertReducer = createAlert.reducer;
