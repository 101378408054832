import React, { useEffect, useState } from "react";

import axios from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";

// Assets and icons
import { ReactComponent as Close } from "../assets/closeModal.svg";
import Dropdownv2 from "../Components/DropDownV2";
import Meta from "../Components/Meta";
import Pagination from "../Components/Pagination";
import DesktopTransactionTable from "../Components/Transactions/DesktopTransactionTable";
import MobileTransactionTable from "../Components/Transactions/MobileTransactionTable";
import TransactionNote from "../Components/Transactions/TransactionNote";
import { breakPoints } from "../Theme";

const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  overflow: hidden;
  h1 {
    font-size: 24px;
    margin: 0 0 2rem;
    @media (max-width: ${breakPoints.md}px) {
      display: none;
    }
  }
  .tx_pagination {
    display: flex;

    > div {
      margin-left: auto;
      margin-top: 1.5rem;
    }
  }
`;

const FilterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .transaction_filter {
    margin-bottom: 1.5rem;
    margin-right: 1.5rem;
  }
  .transaction_filter_label {
    font-size: 12px;
    color: ${(props) => props.theme.lightGrey};
    margin-bottom: 0.5rem;
  }
  .transaction_filter_clear {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: fit-content;
    margin-top: 23px;
    font-size: 12px;
    padding: 0.5rem;
    border-radius: 6px;
    border: 1px solid ${(props) => props.theme.researchBorder};
    background: ${(props) => props.theme.secondaryBg};
    color: ${(props) => props.theme.lightGrey};
    svg {
      height: 100%;
      width: 12px;
      margin-right: 0.75rem;
      path {
        stroke: ${(props) => props.theme.mainDarkText};
      }
    }
    :hover {
      background: ${(props) => props.theme.disabled};
      color: ${(props) => props.theme.mainDarkText};
    }
  }
  .tx_header_pagination {
    display: flex;
    align-self: center;
    flex: 1;
    @media (max-width: ${breakPoints.lg}px) {
      display: none;
    }
    > div {
      margin-left: auto;
    }
  }
`;

const txBaseURL = {
  mainnet: "etherscan.io",
  "bsc-mainnet": "bscscan.com",
  polygon: "polygonscan.com",
};

const Transactions = () => {
  const { t } = useTranslation();
  const [tx, setTx] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState("15");
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({
    status: "",
    direction: "",
  });

  const tableHeadItems = [
    { id: "txHash", label: t("common.transactionHash"), sortable: true },
    { id: "token", label: t("common.token"), sortable: true },
    { id: "from", label: t("common.fromTo"), sortable: true },
    { id: "status", label: t("common.status"), sortable: true },
    { id: "txFee", label: t("common.transasctionFee"), sortable: true },
    { id: "txGas", label: t("common.gas"), sortable: true },
    { id: "value", label: t("common.value"), sortable: true },
    { id: "timestamp", label: t("common.date"), sortable: true },
  ];

  const statusOptions = [
    {
      id: "success",
      text: t("common.success"),
    },
    {
      id: "failed",
      text: t("common.failed"),
    },
  ];

  const directionOptions = [
    {
      id: "in",
      text: t("common.in"),
    },
    {
      id: "out",
      text: t("common.out"),
    },
  ];

  const pageSizeOptions = [
    {
      id: "15",
      text: "15",
    },
    {
      id: "25",
      text: "25",
    },
    {
      id: "50",
      text: "50",
    },
  ];

  const [modalOptions, setModalOptions] = useState({
    open: false,
    tx: {},
  });

  const user = useSelector((state) => state.user);
  const { usdPrices, id } = user;

  const wallet = useSelector((state) => state.wallet);
  const { network, isLoading } = wallet;

  const handleSetPage = (pageNr) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setPage(pageNr);
  };

  const handleModalOptions = (txHash) => {
    const txData = tx.find((item) => item.hash === txHash);
    if (txData) {
      setModalOptions({
        open: true,
        tx: txData,
      });
    }
  };

  const handleCloseModal = () => {
    setModalOptions({
      open: false,
      tx: {},
    });
  };

  const defaultTokenPrice = () => {
    if (!isLoading && usdPrices) {
      const defaultPrices = {
        mainnet: usdPrices.ETH ?? 0,
        "bsc-mainnet": usdPrices.BNB ?? 0,
        polygon: usdPrices.MATIC ?? 0,
      };
      return defaultPrices[network.name] ?? usdPrices.ETH;
    }
    return null;
  };

  const defaultTokenSymbol = () => {
    if (!isLoading) {
      const defaultSymbol = {
        mainnet: "ETH",
        "bsc-mainnet": "BNB",
        polygon: "MATIC",
      };
      return defaultSymbol[network.name] ?? "ETH";
    }
    return "";
  };

  const apiURL = txBaseURL[network.name] ?? "etherscan.io";

  useEffect(() => {
    const fetchTransactions = async () => {
      const delay = (ms) => new Promise((res) => setTimeout(res, ms));
      try {
        const { data: ERC20Transactions } = await axios.get(
          `https://api.${apiURL}/api?module=account&action=tokentx&address=${id}&page=1&offset=125&sort=desc`
        );
        await delay(5000);
        const { data: transactions } = await axios.get(
          `https://api.${apiURL}/api?module=account&action=txlist&address=${id}&sort=desc&page=1&offset=125`
        );

        if (transactions.status === "0" || ERC20Transactions.status === "0") {
          throw new Error("API Limit");
        }

        // Two transaction arrays are getting merged together and sorted from latest to oldest
        const allTx = ERC20Transactions.result
          .concat(
            transactions.result.filter((item) => ERC20Transactions.result.map((tx) => tx.hash).indexOf(item.hash) < 0)
          )
          .sort((a, b) => parseFloat(b.timeStamp) - parseFloat(a.timeStamp));

        setTx(allTx);
        setLoading(false);
      } catch (err) {
        // If API limited, retry the API call after 3 sec
        setTimeout(() => fetchTransactions(), 7000);
      }
    };

    if (id && usdPrices && network.name) {
      fetchTransactions();
    }
  }, [id, wallet, usdPrices, network.name]);

  const filterTx = (item, filter) => {
    const { status, direction } = filter;

    const statusCheck = () => {
      if (status === "success") return item.isError === "0" || !item.isError;
      if (status === "failed") return item.isError === "1";
      return true;
    };

    const directionCheck = () => {
      if (direction === "in") return item.to === id.toLowerCase();
      if (direction === "out") return item.from === id.toLowerCase();
      return true;
    };

    return statusCheck() && directionCheck();
  };

  const filteredTXList = tx.filter((item) => filterTx(item, filter));

  return (
    <Wrapper>
      {modalOptions.open && (
        <TransactionNote
          tokenPrice={defaultTokenPrice()}
          tokenSymbol={defaultTokenSymbol()}
          network={network}
          apiURL={apiURL}
          handleCloseModal={handleCloseModal}
          tx={modalOptions.tx}
        />
      )}
      <h1>{t("transactions.transactionHistory")}</h1>
      <Meta title={t("transactions.transactionHistory")} />
      <FilterWrapper>
        <div className="transaction_filter">
          <div className="transaction_filter_label">{t("transactions.Status")}</div>
          <Dropdownv2
            options={statusOptions}
            setOption={(id) => {
              setPage(1);
              setFilter((prev) => ({ ...prev, status: id }));
            }}
            selectedOption={filter.status}
            placeholder={t("transactions.Select status")}
          />
        </div>
        <div className="transaction_filter">
          <div className="transaction_filter_label">{t("transactions.Direction")}</div>
          <Dropdownv2
            options={directionOptions}
            setOption={(id) => {
              setFilter((prev) => ({ ...prev, direction: id }));
              setPage(1);
            }}
            selectedOption={filter.direction}
            placeholder={t("transactions.Select direction")}
          />
        </div>
        <div className="transaction_filter">
          <div className="transaction_filter_label">{t("transactions.Show")}</div>
          <Dropdownv2
            options={pageSizeOptions}
            setOption={(id) => {
              setPageSize(id);
              setPage(1);
            }}
            selectedOption={pageSize}
            placeholder={t("transactions.selectPageSize")}
          />
        </div>
        {(filter.status || filter.direction) && (
          <button
            className="transaction_filter_clear"
            onClick={() => {
              setPage(1);
              setFilter({ status: "", direction: "" });
            }}
          >
            <Close /> {t("common.clearFilters")}
          </button>
        )}
        <div className="tx_header_pagination">
          <Pagination
            page={page}
            setPage={handleSetPage}
            lastPage={Math.ceil(filteredTXList.length / parseFloat(pageSize)) || 1}
          />
        </div>
      </FilterWrapper>
      <DesktopTransactionTable
        tableHeadItems={tableHeadItems}
        loading={loading}
        filter={filter}
        apiURL={apiURL}
        network={network}
        tokenPrice={defaultTokenPrice()}
        tokenSymbol={defaultTokenSymbol()}
        tx={filteredTXList.filter(
          (_, index) => index + 1 <= page * parseFloat(pageSize) && index + 1 > (page - 1) * parseFloat(pageSize)
        )}
        openModal={handleModalOptions}
      />
      <MobileTransactionTable
        loading={loading}
        filter={filter}
        apiURL={apiURL}
        network={network}
        tokenPrice={defaultTokenPrice()}
        tokenSymbol={defaultTokenSymbol()}
        tx={filteredTXList.filter(
          (_, index) => index + 1 <= page * parseFloat(pageSize) && index + 1 > (page - 1) * parseFloat(pageSize)
        )}
      />
      <div className="tx_pagination">
        <Pagination
          page={page}
          setPage={handleSetPage}
          lastPage={Math.ceil(filteredTXList.length / parseFloat(pageSize)) || 1}
        />
      </div>
    </Wrapper>
  );
};

export default Transactions;
