const useNetworkChange = () => {
  if (window.ethereum) {
    return window.ethereum.on("networkChanged", function () {
      window.location.reload();
    });
  }
  return;
};

export default useNetworkChange;
