import React from "react";

import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import Web3 from "web3";

import { addAddressesActions, addAddressesOperations } from "../../redux/wallets/addAddressesSlice";
import { breakPoints } from "../../Theme";
import Modal from "../Modal";

const Wrapper = styled.form`
  padding: 1.5rem 0 1rem;

  .add_addresses_grid {
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: flex-start;
    column-gap: 2rem;
  }
  .add_addresses_labels {
    margin-bottom: 6px;
    @media (max-width: ${breakPoints.lg}px) {
      display: none;
    }
    h5 {
      font-size: 12px;
      color: ${(props) => props.theme.lightGrey};
    }
  }
  .add_addresses_inputs {
    row-gap: 1.5rem;
    margin-bottom: 2.5rem;
    @media (max-width: ${breakPoints.lg}px) {
      display: none;
    }
  }

  input {
    padding: 0.75rem;
    background: ${(props) => props.theme.bg};
    border: 1px solid ${(props) => props.theme.researchBorder};
    border-radius: 6px;
  }
  .add_addresses_placeholder_input {
    border-style: dashed;
    opacity: 0.6;
  }
  /* Mobile layout styling */
  .add_addresses_mobile {
    display: none;
    @media (max-width: ${breakPoints.lg}px) {
      display: block;
    }
    .add_address_mobile_card {
      margin-bottom: 2rem;
      h5 {
        color: ${(props) => props.theme.lightGrey};
        font-size: 12px;
        margin-bottom: 6px;
      }
      .add_address_mobile_inputs {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        input {
          width: 100%;
        }
      }
    }
  }
  /* End of mobile layout styling */

  /* Save and cancel buttons */
  .add_addresses_buttons {
    display: flex;
    gap: 2rem;
    button {
      padding: 0.75rem 2.5rem;
      border-radius: 6px;
      border: 0;
      @media (max-width: ${breakPoints.lg}px) {
        padding: 0.75rem 0;
        flex: 1;
      }
    }
    .add_save {
      background: ${(props) => props.theme.brandBlue};
      border: 1px solid ${(props) => props.theme.brandBlue};
      color: white;
      :hover {
        background: ${(props) => props.theme.darkBrandBlue};
        border-color: ${(props) => props.theme.darkBrandBlue};
      }
      :disabled {
        pointer-events: none;
        background: ${(props) => props.theme.disabledText};
        border-color: ${(props) => props.theme.disabledText};
        color: ${(props) => props.theme.disabled};
      }
    }
    .add_cancel {
      color: ${(props) => props.theme.mainDarkText};
      background: 0;
      border: 1px solid ${(props) => props.theme.researchBorder};
      :hover {
        background: ${(props) => props.theme.bg};
      }
    }
  }
`;

const InputFieldWrapper = styled.div`
  width: 100%;
  input {
    width: 100%;
    margin-bottom: 6px;
    ${(props) =>
      props.error &&
      css`
        border-color: ${props.theme.error};
        background: ${props.theme.errorInputBg};
        color: ${props.theme.error};
      `}
    ${(props) =>
      props.warning &&
      css`
        border-color: ${props.theme.inputWarning};
        background: ${props.theme.inputWarningBg};
        color: ${props.theme.inputWarning};
      `}
  }
  .add_address_error {
    font-size: 12px;
    color: ${(props) => props.theme.error};
  }
  .add_address_warning {
    font-size: 12px;
    color: ${(props) => props.theme.inputWarning};
  }
`;
const InputField = ({ item, i, addresses }) => {
  const dispatch = useDispatch();

  const web3 = new Web3();

  const isAddressValid = item.address ? web3.utils.isAddress(item.address) : true;
  const doesAddressExist = item.address
    ? addresses.some((addr) => addr.address.toLowerCase() === item.address.toLowerCase())
    : false;

  return (
    <InputFieldWrapper error={!isAddressValid} warning={doesAddressExist}>
      <input
        name={`add_address_address_${i}`}
        value={item.address}
        onChange={(e) => dispatch(addAddressesOperations.handleInputChange(i, "address", e.target.value))}
      />
      {!isAddressValid && <div className="add_address_error">Address is not valid</div>}
      {doesAddressExist && <div className="add_address_warning">This address is already in your Tracked list</div>}
    </InputFieldWrapper>
  );
};

const AddAddressesModal = ({ handleClose, open }) => {
  const dispatch = useDispatch();
  const web3 = new Web3();

  const { addresses } = useSelector((state) => state.addAddresses);
  const { addresses: userAddresses } = useSelector((state) => state.addresses);

  const handleBlur = (name) => {
    document.getElementsByName(name)[0].blur();
  };

  const addressesValid = addresses.every((item) => web3.utils.isAddress(item.address));
  const duplicateAddresses = () => {
    const ids = new Set(addresses.map(({ address }) => address));
    return userAddresses.some(({ address }) => ids.has(address));
  };

  const handleAddAddresses = async (e) => {
    e.preventDefault();
    if (addressesValid && addresses.length !== 0) {
      await dispatch(addAddressesOperations.addAdresses());
      handleClose();
    }
  };

  return (
    <Modal open={open} handleCloseModal={handleClose} title="Add addresses" maxwidth="900px" width="100%">
      <Wrapper onSubmit={handleAddAddresses}>
        <div className="add_addresses_grid add_addresses_labels">
          <div>
            <h5>Wallet address</h5>
          </div>
          <div>
            <h5>Label (optional)</h5>
          </div>
        </div>
        <div className="add_addresses_grid add_addresses_inputs">
          {addresses.length !== 0 &&
            addresses.map((item, i) => (
              <React.Fragment key={i}>
                <InputField addresses={userAddresses} item={item} i={i} />
                <input
                  name={`add_address_title_${i}`}
                  value={item.title}
                  onChange={(e) => dispatch(addAddressesOperations.handleInputChange(i, "title", e.target.value))}
                />
              </React.Fragment>
            ))}
          <input
            name="add_addresses_ph_address"
            className="add_addresses_placeholder_input"
            placeholder="0xF11..."
            value=""
            onChange={(e) => {
              dispatch(addAddressesOperations.createAddressField("address", e.target.value));
              handleBlur(e.target.name);
            }}
          />
          <input
            name="add_addresses_ph_title"
            className="add_addresses_placeholder_input"
            placeholder="eg. Primary"
            value=""
            onChange={(e) => {
              dispatch(addAddressesOperations.createAddressField("title", e.target.value));
              handleBlur(e.target.name);
            }}
          />
        </div>
        <div className="add_addresses_mobile">
          {addresses.length !== 0 &&
            addresses.map((item, i) => (
              <div className="add_address_mobile_card" key={i}>
                <h5>Wallet address</h5>
                <div className="add_address_mobile_inputs">
                  <InputField item={item} i={i} addresses={userAddresses} />
                  <input
                    placeholder="Add label (optional)"
                    name={`add_address_title_${i}`}
                    value={item.title}
                    onChange={(e) => dispatch(addAddressesOperations.handleInputChange(i, "title", e.target.value))}
                  />
                </div>
              </div>
            ))}
          <div className="add_address_mobile_card">
            <h5>Wallet address</h5>
            <div className="add_address_mobile_inputs">
              <input
                name="add_addresses_ph_address_mobile"
                className="add_addresses_placeholder_input"
                placeholder="0xF11..."
                value=""
                onChange={(e) => {
                  dispatch(addAddressesOperations.createAddressField("address", e.target.value));
                  handleBlur(e.target.name);
                }}
              />
              <input
                name="add_addresses_ph_title_mobile"
                className="add_addresses_placeholder_input"
                placeholder="Add label (optional)"
                value=""
                onChange={(e) => {
                  dispatch(addAddressesOperations.createAddressField("title", e.target.value));
                  handleBlur(e.target.name);
                }}
              />
            </div>
          </div>
        </div>

        <div className="add_addresses_buttons">
          <button disabled={!addressesValid || addresses.length === 0 || duplicateAddresses()} className="add_save">
            Save
          </button>
          <button
            className="add_cancel"
            onClick={() => {
              dispatch(addAddressesActions.setAddresses([]));
              handleClose();
            }}
          >
            Cancel
          </button>
        </div>
      </Wrapper>
    </Modal>
  );
};

export default AddAddressesModal;
