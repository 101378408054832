import React, { useEffect, useMemo, useRef, useState } from "react";

import BigNumber from "bignumber.js";
import { useDispatch, useSelector } from "react-redux";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

import { ReactComponent as BarChartIcon } from "../../assets/dashboard/Bar_chart.svg";
import { ReactComponent as DonutChart } from "../../assets/dashboard/Donut_chart.svg";
import { ReactComponent as Refresh } from "../../assets/refresh.svg";
import { beautifyTokensAmount, trimAddress } from "../../helpers";
import { stringToColour } from "../../Pages/ManageWallets";
import { appActions } from "../../redux/appSlice";
import { userDataOperations } from "../../redux/userDataSlice";
import { stackBalanceOperations } from "../../redux/wallets/stackBalanceSlice";
import { stacksOperations } from "../../redux/wallets/stacksSlice";
import { walletOperations } from "../../redux/walletSlice";
import { Skeleton } from "../../Shared";
import { getAddressValue } from "./dashboard.helpers";
import { networks } from "./NetworkLabels";
import { CustomTooltipWrapper, LegendWrapper, WalletValueBarChart, WalletValueWrapper } from "./WalletValue.styles";

const BAR_CHART = "BAR";
const DONUT_CHART = "DONUT";

const WALLET_MODE = "WALLET";
const NETWORK_MODE = "NETWORK";

const colors = {
  mainnet: "#1ad6b4",
  "bsc-mainnet": "#F3BA2F",
  polygon: "#8247E5",
  avalanche: "#EB5757",
  moonbeam: "#E10E7A",
  arbitrum: "#BBDEFF",
  fantom: "#58AFFF",
  optimism: "#C90000",
  cronos: "#244770",
  metis: "#5DE0D8",
  moonriver: "#FFF170",
  okex: "#22221E",
  telos: "#402D83",
  aurora: "#78D64B",
};

const RenderLegend = (props) => {
  const stackWrapperRef = useRef(null);
  const shadowRef = useRef(null);
  const { payload, mode } = props;

  const onScroll = () => {
    if (stackWrapperRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = stackWrapperRef.current;
      if (scrollTop + clientHeight >= scrollHeight) {
        shadowRef.current.style.display = "none";
      } else {
        shadowRef.current.style.display = "block";
      }
    }
  };

  useEffect(() => {
    if (stackWrapperRef.current) {
      onScroll();
    }
  }, [stackWrapperRef]);

  // Observe div when resizing so shadow can be added
  useEffect(() => {
    if (stackWrapperRef.current) {
      const observer = new ResizeObserver(() => {
        onScroll();
      });
      observer.observe(stackWrapperRef.current);
      return () => stackWrapperRef.current && observer.unobserve(stackWrapperRef.current);
    }
  }, [stackWrapperRef]);

  return (
    <LegendWrapper>
      <div ref={stackWrapperRef} className="legend_wrapper_scroll" onScroll={onScroll}>
        {payload
          .filter((item) => item.payload.value > 0)
          .map((entry, index) => (
            <div className="legend_item" key={`item-${index}`}>
              <svg
                width="12"
                height="12"
                viewBox="0 0 20 20"
                version="1.1"
                style={{ display: "inline-block", verticalAlign: "middle" }}
              >
                <path
                  fill={mode === WALLET_MODE ? stringToColour(entry.payload.address) : colors[entry.payload.id]}
                  transform="translate(10, 10)"
                  type="circle"
                  d="M10,0A10,10,0,1,1,-10,0A10,10,0,1,1,10,0"
                ></path>
              </svg>
              {mode === NETWORK_MODE ? (
                <div className="legend_item_text">
                  <h5>{entry.payload.mobileName}</h5>
                  <h4>{beautifyTokensAmount(entry.payload.value, { prefix: "$" })}</h4>
                </div>
              ) : (
                <div className="legend_item_text">
                  <h5 style={{ marginBottom: "0" }}>{entry.payload.name}</h5>
                </div>
              )}
            </div>
          ))}
      </div>
      <div ref={shadowRef} className="wallet_data_shadow" />
    </LegendWrapper>
  );
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <CustomTooltipWrapper>
        <p className="tooltip_label">{payload[0].name}</p>
        <p className="tooltip_value">{beautifyTokensAmount(payload[0].value, { prefix: "$" })}</p>
      </CustomTooltipWrapper>
    );
  }

  return null;
};

const WalletValue = ({ total }) => {
  const dispatch = useDispatch();
  const [selectedMode, setSelectedMode] = useState(NETWORK_MODE);

  const handleChangeChart = (chart) => dispatch(userDataOperations.changeDashboardChart(chart));

  const app = useSelector((state) => state.app);
  const { isLoading } = app;
  const wallet = useSelector((state) => state.wallet);
  const stackBalance = useSelector((state) => state.stackBalance);
  const { showStack, balance } = stackBalance;
  const prices = useSelector((state) => state.user.usdPrices);
  const user = useSelector((state) => state.userData.user);
  const { tokenBlacklist, dashboardChart } = user;

  const activeStack = dispatch(stacksOperations.getActiveStack(stackBalance.activeStackId));

  const handleChange = (e) => {
    setSelectedMode(e.target.checked ? NETWORK_MODE : WALLET_MODE);
  };

  const data = useMemo(() => {
    if (selectedMode === NETWORK_MODE) {
      return networks
        .map((item) => ({
          ...item,
          value: showStack
            ? parseFloat(dispatch(stackBalanceOperations.getNetworkValue(item.id)).total)
            : parseFloat(dispatch(walletOperations.getNetworkValue(item.id)).total),
        }))
        .sort((a, b) => b.value - a.value)
        .filter((item) => parseFloat(item.value) > 0);
    }

    if (!activeStack.addresses) return [];

    const fullData = [...balance.tokens, ...balance.staking, ...balance.lp];

    return activeStack.addresses
      .map((item) => ({
        ...item,
        name: item.title || trimAddress(item.address),
        value: BigNumber(getAddressValue(fullData, item, prices)).toNumber(),
      }))
      .sort((a, b) => b.value - a.value)
      .filter((item) => parseFloat(item.value) > 0);
  }, [showStack, selectedMode, balance, wallet, networks, tokenBlacklist]);

  useEffect(() => {
    if (!showStack) {
      setSelectedMode(NETWORK_MODE);
    }
  }, [showStack]);

  const maxValue = useMemo(() => {
    return Math.max.apply(
      Math,
      data.map((o) => o.value)
    );
  }, [data]);

  const handleHardRefresh = () => {
    if (showStack) {
      dispatch(stackBalanceOperations.fetchBalance(true));
      return;
    }
    dispatch(appActions.setLoading(true));
    dispatch(walletOperations.loadUserData(0, true));
  };

  return (
    <WalletValueWrapper>
      <div className="total_value_outside">
        <div className="total_value_container">
          <h4>
            Total Value{" "}
            <button onClick={handleHardRefresh}>
              <Refresh />
            </button>
          </h4>

          {isLoading ? (
            <Skeleton height="41.5" width="200" style={{ marginBottom: "0.75rem" }} />
          ) : (
            <h2>{beautifyTokensAmount(total, { prefix: "$" })}</h2>
          )}

          <div className="total_value_toggle">
            {showStack && (
              <>
                <div className={selectedMode === WALLET_MODE ? "mode_selected" : ""}>Wallet</div>
                <div>
                  <input
                    type="checkbox"
                    name="switch"
                    id="switch"
                    onChange={handleChange}
                    checked={selectedMode === NETWORK_MODE}
                  />
                  <label htmlFor="switch"></label>
                </div>
              </>
            )}
            <div className={selectedMode === NETWORK_MODE ? "mode_selected" : ""}>Network</div>
          </div>

          <div className="total_value_charts">
            <button
              onClick={() => handleChangeChart(BAR_CHART)}
              className={dashboardChart === BAR_CHART ? "chart_selected" : ""}
            >
              <BarChartIcon />
            </button>
            <button
              onClick={() => handleChangeChart(DONUT_CHART)}
              className={dashboardChart === DONUT_CHART ? "chart_selected" : ""}
            >
              <DonutChart />
            </button>
          </div>
        </div>
      </div>
      <div className="wallet_value_chart_container">
        {dashboardChart === DONUT_CHART && data.length !== 0 && (
          <ResponsiveContainer height="100%" width="100%">
            <PieChart>
              <Legend
                layout="vertical"
                align="right"
                verticalAlign="middle"
                iconType="circle"
                content={<RenderLegend mode={selectedMode} />}
              />

              <Pie data={data} dataKey="value" nameKey="name" innerRadius="75%" outerRadius="90%" fill="#8884d8">
                {data.map((item) => {
                  return (
                    <Cell
                      key={`cell-${selectedMode === WALLET_MODE ? item._id : item.id}`}
                      fill={selectedMode === WALLET_MODE ? stringToColour(item.address) : colors[item.id]}
                      strokeWidth={0}
                    />
                  );
                })}
              </Pie>

              <Tooltip content={<CustomTooltip />} />
            </PieChart>
          </ResponsiveContainer>
        )}

        {dashboardChart === BAR_CHART && data.length !== 0 && (
          <WalletValueBarChart>
            {data.map((item) => (
              <div key={selectedMode === WALLET_MODE ? item._id : item.id} className="wallet_value_bar">
                <div className="wallet_value_bar_info">
                  {selectedMode === NETWORK_MODE ? (
                    <img className="wallet_value_bar_icon" src={item.img} alt={item.name} />
                  ) : (
                    <div className="wallet_value_bar_icon" style={{ background: stringToColour(item.address) }} />
                  )}
                  {selectedMode === WALLET_MODE && <h4>{item.title || trimAddress(item.address)}</h4>}
                </div>

                <div
                  className="wallet_value_rect_wrapper"
                  style={{
                    flexGrow: `${parseFloat(item.value) / maxValue}`,
                  }}
                >
                  <div
                    className="wallet_value_rect"
                    style={{
                      background: `linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, ${
                        selectedMode === NETWORK_MODE ? colors[item.id] : stringToColour(item.address)
                      } 100%)`,
                    }}
                  />
                </div>
                <p className="wallet_value_chart_total">{beautifyTokensAmount(item.value, { prefix: "$" })}</p>
              </div>
            ))}
          </WalletValueBarChart>
        )}
      </div>
    </WalletValueWrapper>
  );
};

export default WalletValue;
