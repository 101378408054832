import React, { useEffect, useState } from "react";

import qrcode from "qrcode";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

// Assets
import Apple from "../../assets/Apple_logo.svg";
import { ReactComponent as Checkmark } from "../../assets/check2.svg";
import SuccessCheck from "../../assets/email/email-verified.svg";
import GooglePlay from "../../assets/Google_Play_logo.svg";
import HideToken from "../../assets/HidePassword.svg";
import ShowToken from "../../assets/HidePasswordOpenEye.svg";
import { ReactComponent as ArrowRight } from "../../assets/popicon/Button_Arrow_Right.svg";
import { userDataOperations } from "../../redux/userDataSlice";
import { ErrorMessage } from "../../Shared";
import Button from "../Button";
import Modal from "../Modal";
import UserInputField from "./UserInputField";

const Wrapper = styled.div`
  padding-top: 2.5rem;
  width: 100%;
`;

const Stepper = styled.div`
  margin: 2.5rem 0 3rem;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 2rem;
`;

const StepperStep = styled.div`
  width: 100%;
  position: relative;
  .stepper_step_title {
    position: absolute;
    bottom: calc(100% + 10px);
    left: 0;
    white-space: nowrap;
    transform: translate(-22%, 0);
    color: ${(props) => props.theme.default_color};
    font-weight: 500;
    font-size: 14px;
  }
  .stepper_step_info {
    display: flex;
    align-items: center;
    .stepper_step_number {
      min-height: 24px;
      min-width: 24px;
      border-radius: 50%;
      color: ${(props) => (props.active ? props.theme.default_color : props.theme.input_placefolder)};
      background: ${(props) => (props.active || props.completed ? props.theme.brandBlue : props.theme.default_color)};
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      margin: 0 1rem;
      svg {
        height: 9px;
        width: 12px;
        path {
          fill: white;
        }
      }
    }
    .stepper_step_line {
      height: 1px;
      background: ${(props) => (props.completed ? props.theme.brandBlue : props.theme.researchBorder)};
      width: 100%;
    }
  }
  :last-of-type {
    display: flex;
    flex-direction: column;
    width: max-content;
    .stepper_step_line {
      display: none;
    }
  }
`;

const StepWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: ${(props) => props.theme.default_color};
  padding-bottom: 3rem;
  h2 {
    font-size: 20px;
    margin-bottom: 0.5rem;
  }
  p {
    font-size: 14px;
    margin-bottom: 2rem;
  }
`;

const Step1Wrapper = styled(StepWrapper)`
  .step1_apps {
    width: 100%;
    max-width: 430px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 3.5rem;
    .step1_single_app {
      color: ${(props) => props.theme.default_color};
      display: flex;
      padding: 0.75rem 1.5rem;
      background: ${(props) => props.theme.form_content};
      border-radius: 6px;
      border: 1px solid ${(props) => props.theme.pagination_border};
      img {
        margin-right: 1rem;
        height: 32px;
        width: 30px;
      }
      .step1_app_subtitle {
        font-size: 12px;
        line-height: 14px;
        font-weight: 500;
      }
      .step1_app_title {
        font-size: 16px;
        line-height: 19px;
        font-weight: 500;
      }
    }
  }
`;

const Step2Wrapper = styled(StepWrapper)`
  .step2_container {
    display: flex;
    max-width: 430px;
    width: 100%;
    margin-bottom: 3.5rem;
    .step2_qr {
      height: 112px;
      width: 112px;
      margin-right: 2.75rem;
    }
    div {
      p {
        font-weight: 500;
        margin: 0 0 1rem;
      }
      .step2_token {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background: ${(props) => props.theme.borderBg};
        padding: 0.75rem 1rem;
        border-radius: 6px;
        img {
          cursor: pointer;
        }
      }
    }
  }
  .step2_buttons {
    display: flex;
    width: 100%;
    justify-content: center;
  }
`;

const Step3Wrapper = styled(StepWrapper)`
  .step3_container {
    width: 100%;
    margin-bottom: 3.5rem;
    .step3_label {
      font-size: 12px;
      font-weight: 500;
      color: ${(props) => props.theme.lightDarkGrey};
      margin-bottom: 0.5rem;
    }
  }
  .step3_buttons {
    display: flex;
    width: 100%;
    justify-content: center;
  }
`;

const Step4Wrapper = styled.div`
  padding-bottom: 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    margin-bottom: 1.5rem;
  }
  h2 {
    font-size: 20px;
    color: ${(props) => props.theme.default_color};
    margin-bottom: 3.5rem;
  }
`;

const StepButton = styled(Button)`
  font-size: 16px;
  max-width: 200px;
  width: 100%;
  height: max-content;
  line-height: 19.5px;
  padding: 0.75rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-left: 0.5rem;
  }
  ${(props) =>
    props.backButton &&
    css`
      background: 0;
      color: ${(props) => props.theme.form_text3};
      margin-right: 2rem;
      svg {
        margin-left: 0;
        margin-right: 0.5rem;
        transform: rotate(180deg);
        path {
          fill: ${(props) => props.theme.form_text3};
        }
      }
    `}
`;

const GoogleAuthModal = ({ openModal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const [showToken, setShowToken] = useState(false);
  const [authData, setAuthData] = useState({
    qrcodeURL: "",
    token: "",
  });
  const [authCode, setAuthCode] = useState("");

  const userData = useSelector((state) => state.userData);
  const { user, error } = userData;

  useEffect(() => {
    const fetchTokenData = async () => {
      const { qrcode: qrcodeURL, token } = await dispatch(userDataOperations.getAuthToken());
      qrcode.toDataURL(qrcodeURL, (err, imageUrl) => {
        if (err) {
          console.log("Error with QR");
          return;
        }
        setAuthData({ token, qrcodeURL: imageUrl });
      });
    };
    fetchTokenData();
  }, [dispatch]);

  useEffect(() => {
    if (user.authentication.approved) {
      setStep(4);
    }
  }, [user]);

  const handleVerifyGoogleAuth = () => {
    dispatch(userDataOperations.verifyAuthToken(authCode));
  };

  return (
    <Modal title={t("auth.setUpGoogleAuth")} handleCloseModal={() => openModal(false)} width="100%" maxwidth="600px">
      <Wrapper>
        {step !== 4 && (
          <Stepper>
            <StepperStep active={step === 1} completed={step > 1}>
              <div className="stepper_step_title">{t("auth.downloadApp")}</div>
              <div className="stepper_step_info">
                <div className="stepper_step_number">{step > 1 ? <Checkmark /> : <span>1</span>}</div>
                <div className="stepper_step_line" />
              </div>
            </StepperStep>
            <StepperStep active={step === 2} completed={step > 2}>
              <div className="stepper_step_title">{t("auth.scanQRcode")}</div>
              <div className="stepper_step_info">
                <div className="stepper_step_number">{step > 2 ? <Checkmark /> : <span>2</span>}</div>
                <div className="stepper_step_line" />
              </div>
            </StepperStep>
            <StepperStep active={step === 3} completed={step > 3}>
              <div className="stepper_step_title">{t("auth.verifyCode")}</div>
              <div className="stepper_step_info">
                <div className="stepper_step_number">{step > 3 ? <Checkmark /> : <span>3</span>}</div>
                <div className="stepper_step_line" />
              </div>
            </StepperStep>
          </Stepper>
        )}

        {step === 1 && (
          <Step1Wrapper>
            <h2>{t("auth.step1")}</h2>
            <p>{t("auth.downloadGoogleAuth")}</p>
            <div className="step1_apps">
              <a
                className="step1_single_app"
                target="_blank"
                href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                rel="noopener noreferrer"
              >
                <img src={Apple} alt="Apple App Store" />
                <div className="step1_app_info">
                  <h6 className="step1_app_subtitle">{t("auth.downloadFrom")}</h6>
                  <h4 className="step1_app_title">{t("auth.appStore")}</h4>
                </div>
              </a>
              <a
                className="step1_single_app"
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                rel="noopener noreferrer"
              >
                <img src={GooglePlay} alt="Google Play" />
                <div className="step1_app_info">
                  <h6 className="step1_app_subtitle">{t("auth.downloadFrom")}</h6>
                  <h4 className="step1_app_title">{t("auth.googlePlay")}</h4>
                </div>
              </a>
            </div>
            <StepButton primary onClick={() => setStep(2)}>
              {t("auth.next")} <ArrowRight />
            </StepButton>
          </Step1Wrapper>
        )}
        {step === 2 && (
          <Step2Wrapper>
            <h2>{t("auth.step2")}</h2>
            <p>{t("auth.scanQRforGoogle")}</p>
            <div className="step2_container">
              <img src={authData.qrcodeURL} alt="Google Authenticator QR code" className="step2_qr" />
              <div>
                <p>{t("auth.unableToScanQR")}</p>
                <div className="step2_token">
                  {showToken ? authData.token : "***************"}
                  <img
                    src={showToken ? ShowToken : HideToken}
                    alt="Show Eye"
                    onClick={() => setShowToken(!showToken)}
                  />
                </div>
              </div>
            </div>
            <div className="step2_buttons">
              <StepButton backButton onClick={() => setStep(1)}>
                <ArrowRight />
                {t("auth.back")}
              </StepButton>
              <StepButton primary onClick={() => setStep(3)}>
                {t("auth.next")} <ArrowRight />
              </StepButton>
            </div>
          </Step2Wrapper>
        )}
        {step === 3 && (
          <Step3Wrapper>
            <h2>{t("auth.step3")}</h2>
            <p>{t("auth.enterAuthCode")}</p>
            <div className="step3_container">
              <div className="step3_label">{t("auth.googleAuthenticatorCode")}</div>
              <UserInputField placeholder="111111" value={authCode} onChange={(e) => setAuthCode(e.target.value)} />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </div>
            <div className="step3_buttons">
              <StepButton backButton onClick={() => setStep(2)}>
                <ArrowRight />
                {t("auth.back")}
              </StepButton>
              <StepButton primary onClick={handleVerifyGoogleAuth}>
                {t("auth.enable")}
              </StepButton>
            </div>
          </Step3Wrapper>
        )}
        {step === 4 && (
          <Step4Wrapper>
            <img src={SuccessCheck} alt="Google Auth Approved" />
            <h2>{t("auth.googleAuthFinish")}</h2>
            <StepButton primary onClick={() => openModal(false)}>
              {t("auth.close")}
            </StepButton>
          </Step4Wrapper>
        )}
      </Wrapper>
    </Modal>
  );
};

export default GoogleAuthModal;
