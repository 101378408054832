import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import ArrowDown from "../../assets/arrowDown.svg";
import CopyIcon from "../../assets/copyIcon.svg";
import ETH from "../../assets/eth.svg";
import Warning from "../../assets/warning.svg";
import { LowerContainer, UpperContainer, YearBillingContainer } from "./billing.style";

const YearBilling = () => {
  const { t } = useTranslation();
  const [coin] = useState({
    name: "ETH",
    image: ETH,
    address: "0x4b6000f9163de2e3f0a01ec37e06e1469dbbce9d",
  });

  return (
    <YearBillingContainer>
      <UpperContainer>
        <div className="upper_container_description">
          <h4>{t("billing.oneYearSub")}</h4>
          <p>{t("billing.acceptCoins")}</p>
        </div>
        <div className="upper_container_price">
          <h4>660.00 KEYFI </h4>
          <div className="upper_container_select">
            <img src={coin.image} className="logo" alt={coin.name} height="23px"></img>
            <span>{coin.name}</span>
            <img src={ArrowDown} alt="Arrow Down" width="11px"></img>
          </div>
        </div>
      </UpperContainer>
      <div className="year_divider"></div>
      <LowerContainer>
        <div className="lower_container_coin">
          <img src={coin.image} className="logo" alt={coin.name} height="33px"></img>
          <div>{coin.name}</div>
        </div>
        <div className="lower_container_deposit">
          <div className="lower_container_deposit_title">{t("billing.depositAddress")}</div>
          <div className="lower_container_address">
            <span className="subscription_address">{coin.address}</span>
            <span
              className="subscription_copy"
              onClick={() => {
                navigator.clipboard.writeText(coin.address);
              }}
            >
              <img src={CopyIcon} alt="Copy" height="16px"></img>
              {t("billing.copyAddress")}
            </span>
          </div>
        </div>
        <div className="lower_container_warning">
          <img src={Warning} alt="Warning"></img>
          {t("billing.sendOnly")} {coin.name} {t("billing.toThisAddress")}
        </div>
      </LowerContainer>
    </YearBillingContainer>
  );
};

export default YearBilling;
