import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import { SubscriptionContainer, ToggleButtonContainer, ToggleButtonOptions } from "./billing.style";
import WrapperStripe from "./MonthlyBilling";
import ToggleButton from "./ToggleButton";
import YearBilling from "./YearBilling";

function Billing() {
  const [billingData, setBillingData] = useState({
    cardNumber: "",
    cardExpiration: "",
    cvc: "",
    annually: false,
  });

  const { t } = useTranslation();

  const handleToggleButton = (e) => {
    setBillingData((prev) => ({ ...prev, annually: e.target.checked }));
  };

  return (
    <SubscriptionContainer>
      <h2>{t("billing.title")}</h2>
      <div className="container">
        <ToggleButtonContainer className="toggle_button_container">
          <ToggleButtonOptions checked={!billingData.annually}>{t("billing.billMonthly")}</ToggleButtonOptions>
          <ToggleButton onChange={handleToggleButton} />
          <ToggleButtonOptions checked={billingData.annually}>
            {t("billing.billAnnually")}
            <div className="small_text">{t("billing.payByCrypto")}</div>
          </ToggleButtonOptions>
        </ToggleButtonContainer>
        {billingData.annually ? <YearBilling /> : <WrapperStripe />}
      </div>
    </SubscriptionContainer>
  );
}

export default Billing;
