import React, { Suspense } from "react";

import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";

import { SigninContainer } from "../Theme";

const SigninRoute = ({ component: Component, ...rest }) => {
  const userData = useSelector((state) => state.userData);
  const { user } = userData;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (
          user.id &&
          user.access.allow &&
          user.emailApproved &&
          ((userData.user.authentication.approved && userData.user.authVerified) ||
            !userData.user.authentication.approved)
        ) {
          return <Redirect to="/dashboard" />;
        }

        return (
          <Suspense fallback={<div></div>}>
            <SigninContainer>
              <Component {...rest} {...props} />
            </SigninContainer>
          </Suspense>
        );
      }}
    />
  );
};

export default SigninRoute;
