import React from "react";

import styled from "styled-components";

import BSCIcon from "../../assets/header/BSC.svg";
import ETHIcon from "../../assets/header/ETH.svg";
import PolygonIcon from "../../assets/header/PolygonCircle.svg";
import Arbitrum from "../../assets/platforms/arbitrum.svg";
import Aurora from "../../assets/platforms/Aurora.svg";
import Avalanche from "../../assets/platforms/avalanche.svg";
import Cronos from "../../assets/platforms/cronos.svg";
import Fantom from "../../assets/platforms/fantom.svg";
import Metis from "../../assets/platforms/Metis.svg";
import Moonbeam from "../../assets/platforms/Moonbeam.png";
import Moonriver from "../../assets/platforms/moonriver.svg";
import OKEx from "../../assets/platforms/OEC.svg";
import Optimism from "../../assets/platforms/optimism.svg";
import Telos from "../../assets/platforms/telos.svg";

const Wrapper = styled.div`
  position: absolute;
  top: 120%;
  right: 0;
  width: 200px;
  background: ${(props) => props.theme.secondaryBg};
  border: 1px solid ${(props) => props.theme.researchBorder};
  border-radius: 6px;
  z-index: 2;
  /* overflow: hidden; */
  padding: 0.5rem 0;
  ::after {
    z-index: 9;
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 100%;
    height: 9px;
    width: 100%;
  }
  button {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 0.5rem 1.5rem;
    border: 0;
    background: 0;
    width: 100%;
    text-align: left;
    font-size: 16px;
    color: ${(props) => props.theme.mainDarkText};

    :hover {
      background: ${(props) => props.theme.bg};
    }
    img {
      height: 16px;
      width: 16px;
    }
  }
`;

const networks = [
  {
    name: "Ethereum",
    icon: ETHIcon,
    method: "wallet_switchEthereumChain",
    params: [{ chainId: "0x1" }],
  },
  {
    name: "BSC",
    icon: BSCIcon,
    params: [
      {
        chainId: "0x38",
        chainName: "Binance Smart Chain",
        rpcUrls: ["https://bsc-dataseed1.ninicoin.io"],
        iconUrls: ["https://s2.coinmarketcap.com/static/img/coins/200x200/1839.png"],
        nativeCurrency: {
          name: "bnb",
          symbol: "bnb",
          decimals: 18,
        },
      },
    ],
  },
  {
    name: "Polygon",
    icon: PolygonIcon,
    params: [
      {
        chainId: "0x89",
        chainName: "Polygon Mainnet",
        nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
        rpcUrls: ["https://polygon-rpc.com"],
        blockExplorerUrls: ["https://www.polygonscan.com"],
      },
    ],
  },
  {
    name: "Avalanche",
    icon: Avalanche,
    params: [
      {
        chainId: "0xa86a",
        chainName: "Avalanche",
        nativeCurrency: { name: "AVAX", symbol: "AVAX", decimals: 18 },
        rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
        blockExplorerUrls: ["https://snowtrace.io/"],
      },
    ],
  },
  {
    name: "Arbitrum",
    icon: Arbitrum,
    params: [
      {
        chainId: "0xA4B1",
        chainName: "Arbitrum",
        nativeCurrency: { name: "ETH", symbol: "ETH", decimals: 18 },
        rpcUrls: ["https://arb1.arbitrum.io/rpc"],
        blockExplorerUrls: ["https://arbiscan.io/"],
      },
    ],
  },
  {
    name: "Fantom",
    icon: Fantom,
    params: [
      {
        chainId: "0xFA",
        chainName: "Fantom",
        nativeCurrency: { name: "FTM", symbol: "FTM", decimals: 18 },
        rpcUrls: ["https://rpc.ftm.tools/"],
        blockExplorerUrls: ["https://ftmscan.com/"],
      },
    ],
  },
  {
    name: "Cronos",
    icon: Cronos,
    params: [
      {
        chainId: "0x19",
        chainName: "Cronos",
        nativeCurrency: { name: "CRO", symbol: "CRO", decimals: 18 },
        rpcUrls: ["https://evm-cronos.crypto.org"],
        blockExplorerUrls: ["https://cronoscan.com/"],
      },
    ],
  },
  {
    name: "Optimism",
    icon: Optimism,
    params: [
      {
        chainId: "0xA",
        chainName: "Optimism",
        nativeCurrency: { name: "ETH", symbol: "ETH", decimals: 18 },
        rpcUrls: ["https://mainnet.optimism.io"],
        blockExplorerUrls: ["https://optimistic.etherscan.io/"],
      },
    ],
  },
  {
    name: "Metis",
    icon: Metis,
    params: [
      {
        chainId: "0x440",
        chainName: "Metis",
        nativeCurrency: { name: "Metis", symbol: "METIS", decimals: 18 },
        rpcUrls: ["https://andromeda.metis.io/?owner=1088"],
        blockExplorerUrls: ["https://andromeda-explorer.metis.io/"],
      },
    ],
  },
  {
    name: "Moonriver",
    icon: Moonriver,
    params: [
      {
        chainId: "0x505",
        chainName: "Moonriver",
        nativeCurrency: { name: "Moonriver", symbol: "MOVR", decimals: 18 },
        rpcUrls: ["https://rpc.moonriver.moonbeam.network/"],
        blockExplorerUrls: ["https://blockscout.moonriver.moonbeam.network/"],
      },
    ],
  },
  {
    name: "Moonbeam",
    icon: Moonbeam,
    params: [
      {
        chainId: "0x504",
        chainName: "Moonbeam",
        nativeCurrency: { name: "GLMR", symbol: "GLMR", decimals: 18 },
        rpcUrls: ["https://rpc.api.moonbeam.network"],
        blockExplorerUrls: ["https://moonscan.io/"],
      },
    ],
  },
  {
    name: "Telos",
    icon: Telos,
    params: [
      {
        chainId: "0x28",
        chainName: "Telos",
        nativeCurrency: { name: "TLOS", symbol: "TLOS", decimals: 18 },
        rpcUrls: ["https://mainnet.telos.net/evm"],
        blockExplorerUrls: ["https://www.teloscan.io/"],
      },
    ],
  },
  {
    name: "OKC",
    icon: OKEx,
    params: [
      {
        chainId: "0x42",
        chainName: "OKC",
        nativeCurrency: { name: "OKT", symbol: "OKT", decimals: 18 },
        rpcUrls: ["https://exchainrpc.okex.org"],
        blockExplorerUrls: ["https://www.oklink.com/okexchain/"],
      },
    ],
  },
  {
    name: "Aurora",
    icon: Aurora,
    params: [
      {
        chainId: "0x4E454152",
        chainName: "Aurora",
        nativeCurrency: { name: "ETH", symbol: "ETH", decimals: 18 },
        rpcUrls: ["https://mainnet.aurora.dev"],
        blockExplorerUrls: ["https://explorer.mainnet.aurora.dev/"],
      },
    ],
  },
];

const ethereum = window.ethereum;

const NetworkCard = ({ ...attrs }) => {
  const switchNetwork = async (params, method) => {
    try {
      await ethereum.request({
        id: 1,
        jsonrpc: "2.0",
        method: method ?? "wallet_addEthereumChain",
        params,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Wrapper {...attrs}>
      {networks.map((network) => (
        <button key={network.name} onClick={() => switchNetwork(network.params, network.method)}>
          <img src={network.icon} alt={network.name} />
          {network.name}
        </button>
      ))}
    </Wrapper>
  );
};

export default NetworkCard;
