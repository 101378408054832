import React, { useEffect, useMemo, useRef, useState } from "react";

import { getAddressSum } from "@keyfi/keyfi-common/src/integrations";
import BigNumber from "bignumber.js";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as ArrowDown } from "../../assets/arrowWhite.svg";
import { ReactComponent as ExpandIcon } from "../../assets/dashboard/expand.svg";
import { ReactComponent as HideEyeIcon } from "../../assets/dashboard/hideEye.svg";
import { assetImageNetwork, beautifyTokensAmount, tokensLogos, trimAddress, wrapperNetworkToken } from "../../helpers";
import { stringToColour } from "../../Pages/ManageWallets";
import { userDataOperations } from "../../redux/userDataSlice";
import { stackBalanceOperations } from "../../redux/wallets/stackBalanceSlice";
import { stacksOperations } from "../../redux/wallets/stacksSlice";
import { supportedNetworks, walletOperations } from "../../redux/walletSlice";
import { Skeleton } from "../../Shared";
import AssetImage from "../Borrow/AssetImage";
import { getAddressValue, getTotalValue, totalValue } from "./dashboard.helpers";
import NetworkLabels from "./NetworkLabels";
import PlatformCard from "./PlatformCard";
import platforms from "./platforms";
import RemovedTokenModal from "./RemovedTokenModal";
import { TokenRows, WalletTable } from "./WalletContainer.helpers";
import { ViewDropdownWrapper, WalletDataWrapper } from "./WalletData.styles";
import WalletViewModal from "./WalletViewModal";

export const WALLET_VIEW = "wallets";
export const ASSETS_VIEW = "assets";
export const PLATFORMS_VIEW = "platforms";

const WALLET_TYPE = "tokens";
const STAKING_TYPE = "staking";
const LP_TYPE = "lp";

const viewOptions = [
  { id: WALLET_VIEW, title: "Wallets" },
  { id: ASSETS_VIEW, title: "Assets" },
  { id: PLATFORMS_VIEW, title: "Platforms" },
];

const ViewDropdown = ({ selectedView, setSelectedView, showStack }) => {
  const [open, setOpen] = useState(false);
  const selectedViewData = viewOptions.find((item) => item.id === selectedView);

  const handleSelect = (id) => {
    setSelectedView(id);
    setOpen(false);
  };

  const outsideClickListener = () => {
    if (open) {
      setOpen(false);
    }
    document.removeEventListener("click", outsideClickListener);
  };
  return (
    <ViewDropdownWrapper
      onMouseLeave={() => {
        document.addEventListener("click", outsideClickListener);
      }}
      isOpen={open}
    >
      <div className="view_dropdown_header" onClick={() => setOpen((prev) => !prev)}>
        {selectedViewData.title} <ArrowDown />
      </div>
      <div style={{ display: open ? "block" : "none" }} className="view_dropdown_list">
        {viewOptions
          .filter((option) => (showStack ? true : option.id !== WALLET_VIEW))
          .map((option) => (
            <div key={option.id} onClick={() => handleSelect(option.id)}>
              {option.title}
            </div>
          ))}
      </div>
    </ViewDropdownWrapper>
  );
};

const WalletDataStackItem = ({ item, prices, lp, selectedType }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);

  return (
    <div className="wallet_data_stack_item">
      <div className="wallet_data_stack_item_header" onClick={() => setOpen((prev) => !prev)}>
        <div className="wallet_data_stack_item_icon" style={{ background: stringToColour(item.address) }} />
        {item.title && <h3>{item.title}</h3>}
        <h5>{trimAddress(item.address)}</h5>
        <div className="wallet_data_stack_item_total">
          Total: <b>{beautifyTokensAmount(item.value, { prefix: "$" })}</b>{" "}
          <ArrowDown style={{ transform: open ? "rotate(180deg)" : "" }} />
        </div>
      </div>
      <div className="wallet_data_stack_item_main" style={{ display: open ? "block" : "none" }}>
        {!lp && (
          <table className="wallet_data_table">
            <thead>
              <tr>
                <th>Asset</th>
                <th>Balance</th>
                <th>Price</th>
                <th>Value</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {item.tokens.length === 0 && (
                <tr>
                  <td colSpan={4} style={{ textAlign: "left" }}>
                    No tokens
                  </td>
                </tr>
              )}
              {item.tokens.length !== 0 &&
                item.tokens.map((token) => (
                  <tr key={`${token.network}_${token.symbol}_${Math.random()}`}>
                    <td>
                      <div className="wallet_data_row_asset">
                        <AssetImage
                          fallbackLogo={tokensLogos[token.symbol]}
                          className="wallet_data_table_icon"
                          src={`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${
                            assetImageNetwork[token.network]
                          }/assets/${
                            token.address === "0x0000000000000000000000000000000000000000"
                              ? getAddressSum(wrapperNetworkToken[token.network])
                              : getAddressSum(token.address)
                          }/logo.png`}
                          alt={token.symbol}
                        />
                        {token.symbol.toUpperCase()}
                      </div>
                    </td>
                    <td>{beautifyTokensAmount(token.amount)}</td>
                    <td>
                      {beautifyTokensAmount(
                        token.type === "token"
                          ? prices[token.symbol.toUpperCase()] || 0
                          : totalValue(token, prices) / token.amount,
                        { prefix: "$" }
                      )}
                    </td>
                    <td>
                      {beautifyTokensAmount(
                        token.type === "token"
                          ? token.amount * (prices[token.symbol.toUpperCase()] || 0)
                          : totalValue(token, prices),
                        { prefix: "$" }
                      )}
                    </td>
                    <td>
                      {token.address && selectedType === WALLET_TYPE && (
                        <div className="wallet_data_table_hide">
                          <button onClick={() => dispatch(userDataOperations.addToTokenBlacklist(token))}>
                            <HideEyeIcon />
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
        {lp && (
          <div className="wallet_data_wallet_lp wallet_data_stack_lp">
            <div className="wallet_data_wallet_lp_header">
              <h4>Assets</h4>
              <h4>Platform</h4>
              <h4>Value</h4>
            </div>

            {item.tokens.length === 0 && (
              <div className="wallet_lp_item">
                <div className="wallet_lp_item_header">No tokens</div>
              </div>
            )}
            {item.tokens.length !== 0 &&
              item.tokens
                .map((token) => ({
                  ...token,
                  value: totalValue(token, prices, true),
                }))
                .map((token) => <LPItem token={token} key={token.address} />)}
          </div>
        )}
      </div>
    </div>
  );
};

const AssetImageWrapper = ({ network, className, tokenName, tokenAddress }) => (
  <AssetImage
    fallbackLogo={tokensLogos[tokenName]}
    className={className}
    src={`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${
      assetImageNetwork[network]
    }/assets/${
      tokenAddress === "0x0000000000000000000000000000000000000000"
        ? getAddressSum(wrapperNetworkToken[network])
        : getAddressSum(tokenAddress)
    }/logo.png`}
    alt={tokenName}
  />
);

export const LPItem = ({ token, onScroll }) => {
  const [open, setOpen] = useState(false);
  const platformData = platforms.find(
    (item) => item.name.toLowerCase() === token.platform.toLowerCase() && item.network === token.network
  );

  const handleOpen = () => {
    setOpen((prev) => !prev);
    if (onScroll) onScroll();
  };
  return (
    <div className="wallet_lp_item">
      <div className="wallet_lp_item_header" onClick={handleOpen}>
        <div className="wallet_lp_item_info">
          <div>
            <AssetImageWrapper
              tokenName={token.assetA}
              tokenAddress={token.assetAAddress}
              className="wallet_lp_item_icon"
              network={token.network}
            />
            <AssetImageWrapper
              tokenName={token.assetB}
              tokenAddress={token.assetBAddress}
              className="wallet_lp_item_icon wallet_lp_last_icon"
              network={token.network}
            />
          </div>
          <div className="wallet_lp_item_title">
            <h3>
              {token.assetA}:{token.assetB}
            </h3>
            <h5>{beautifyTokensAmount(token.liquidity)}</h5>
          </div>
        </div>
        <div className="wallet_lp_item_platform">
          {platformData.icon}
          <div>{platformData.name}</div>
        </div>
        <div className="wallet_lp_item_value">
          <div>{beautifyTokensAmount(token.value, { prefix: "$" })}</div>
          <ArrowDown style={{ transform: open ? "rotate(180deg)" : "" }} />
        </div>
      </div>
      <div className="wallet_lp_item_body" style={{ display: open ? "flex" : "none" }}>
        <div className="wallet_lp_body_line">
          <div>
            <AssetImageWrapper
              tokenName={token.assetA}
              tokenAddress={token.assetAAddress}
              className="wallet_lp_item_icon"
              network={token.network}
            />
            Pooled {token.assetB}
          </div>
          <div>{beautifyTokensAmount(token[token.assetA])}</div>
        </div>
        <div className="wallet_lp_body_line">
          <div>
            <AssetImageWrapper
              tokenName={token.assetB}
              tokenAddress={token.assetBAddress}
              className="wallet_lp_item_icon"
              network={token.network}
            />
            Pooled {token.assetB}
          </div>
          <div>{beautifyTokensAmount(token[token.assetB])}</div>
        </div>
        <div className="wallet_lp_body_line">
          <div>Your pool share</div>
          <div>{beautifyTokensAmount(token.liquidityPercent * 100, { suffix: "%" })}</div>
        </div>
      </div>
    </div>
  );
};

const WalletData = () => {
  const stackWrapperRef = useRef(null);
  const shadowRef = useRef(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [selectedType, setSelectedType] = useState(WALLET_TYPE);
  const [viewType, setViewType] = useState(WALLET_VIEW);
  const [network, setNetwork] = useState("mainnet");
  const [openModal, setOpenModal] = useState(false);
  const [openRemoveTokenModal, setOpenRemoveTokenModal] = useState(false);

  const app = useSelector((state) => state.app);
  const { isLoading } = app;
  const prices = useSelector((state) => state.user.usdPrices);
  const wallet = useSelector((state) => state.wallet);
  const { tokens, staking, lp, network: networkWallet } = wallet;
  const stackBalance = useSelector((state) => state.stackBalance);
  const { showStack, balance } = stackBalance;
  const user = useSelector((state) => state.userData.user);
  const { tokenBlacklist } = user;

  const activeStack = dispatch(stacksOperations.getActiveStack(stackBalance.activeStackId));

  const tokenBlacklistFilter = (item) =>
    !tokenBlacklist.some((token) => token.network === item.network && token.address === item.address);

  // Total Value for Wallet, Staking and LP
  const balances = useMemo(() => {
    const balanceObject = showStack ? { ...balance } : { tokens, staking, lp };
    if (!prices) return {};

    // Remove tokens that are blacklisted
    Object.keys(balanceObject).forEach((key) => {
      balanceObject[key] = balanceObject[key].filter(
        (item) => !tokenBlacklist.some((token) => token.network === item.network && token.address === item.address)
      );
    });

    return {
      [WALLET_TYPE]: getTotalValue(balanceObject.tokens, prices),
      [STAKING_TYPE]: getTotalValue(balanceObject.staking, prices),
      [LP_TYPE]: getTotalValue(balanceObject.lp, prices, true),
    };
  }, [showStack, prices, balance, tokens, staking, lp, tokenBlacklist]);

  // Each Network value
  const networkValues = useMemo(
    () =>
      supportedNetworks.reduce((acc, network) => {
        return {
          ...acc,
          [network]: showStack
            ? dispatch(stackBalanceOperations.getNetworkValue(network))
            : dispatch(walletOperations.getNetworkValue(network)),
        };
      }, {}),
    [tokenBlacklist, balance, showStack, tokens, staking, lp]
  );

  // Array with stack addresses info + balances
  const stackAddressBalances = useMemo(() => {
    if (!activeStack || !activeStack.addresses) return [];
    const dataArray = balance[selectedType].filter((item) => item.network === network);

    return activeStack.addresses
      .map((item) => ({
        ...item,
        value: getAddressValue(dataArray, item, prices, selectedType === LP_TYPE),
        tokens: dataArray
          .filter((token) => token.userId.toLowerCase() === item.address.toLowerCase() && tokenBlacklistFilter(token))
          .sort(
            (a, b) => totalValue(b, prices, selectedType === LP_TYPE) - totalValue(a, prices, selectedType === LP_TYPE)
          ),
      }))
      .sort((a, b) => b.value - a.value);
  }, [balance, selectedType, activeStack, network, prices, tokenBlacklist]);

  // Single wallet info + balances
  const walletTokensData = useMemo(() => {
    return wallet[selectedType]
      .filter((item) => item.network === network && tokenBlacklistFilter(item))
      .map((item) => ({ ...item, value: totalValue(item, prices, selectedType === LP_TYPE) }))
      .sort((a, b) => BigNumber(b.value) - BigNumber(a.value));
  }, [wallet, selectedType, network, prices, tokenBlacklist]);

  // Remove shadow when scrolled to bottom
  const onScroll = () => {
    if (stackWrapperRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = stackWrapperRef.current;
      if (scrollTop + clientHeight >= scrollHeight) {
        shadowRef.current.style.display = "none";
      } else {
        shadowRef.current.style.display = "block";
      }
    }
  };

  // If show stack = show wallet view, else show asset view
  useEffect(() => {
    if (showStack) {
      setViewType(WALLET_VIEW);
    } else {
      setViewType(ASSETS_VIEW);
    }
  }, [showStack]);

  useEffect(() => {
    if (viewType === PLATFORMS_VIEW) {
      setSelectedType(STAKING_TYPE);
    }
  }, [viewType]);

  // Set active network once available
  useEffect(() => {
    if (networkWallet && networkWallet.name) {
      setNetwork(networkWallet.name === "unknown" ? "mainnet" : networkWallet.name);
    }
  }, [networkWallet]);

  // If user switched type and the network value = 0 -> Switch networks
  useEffect(() => {
    if (BigNumber(networkValues[network][selectedType]).eq(0)) {
      const valuedNetworks = Object.entries(networkValues).filter((entry) => BigNumber(entry[1][selectedType]).gt(0));
      if (valuedNetworks.length > 0) {
        setNetwork(valuedNetworks[0][0]);
      }
    }
  }, [networkValues, selectedType]);

  // useEffect for shadow (make sure shadow works properly)
  useEffect(() => {
    if (stackWrapperRef.current) {
      onScroll();
    }
  }, [showStack, selectedType, network, walletTokensData, stackAddressBalances, stackWrapperRef]);

  // Observe div when resizing so shadow can be added
  useEffect(() => {
    if (stackWrapperRef.current) {
      const observer = new ResizeObserver(() => {
        onScroll();
      });
      observer.observe(stackWrapperRef.current);
      return () => stackWrapperRef.current && observer.unobserve(stackWrapperRef.current);
    }
  }, [stackWrapperRef]);

  const networkData = supportedNetworks.reduce((acc, network) => {
    const operations = showStack ? stackBalanceOperations : walletOperations;
    return {
      ...acc,
      [network]: {
        tokens: dispatch(operations.getNetworkTokens(network)),
        staking: dispatch(operations.getNetworkStakedTokens(network)),
        lp: dispatch(operations.getNetworkLP(network)),
      },
    };
  }, {});

  const assetData = useMemo(() => {
    return networkData[network][selectedType].filter(tokenBlacklistFilter);
  }, [showStack, wallet, balance, prices, selectedType, network, tokenBlacklist]);

  return (
    <WalletDataWrapper>
      <WalletViewModal
        selectedNetwork={network}
        selectedType={selectedType}
        viewType={viewType}
        setViewType={setViewType}
        open={openModal}
        handleCloseModal={() => setOpenModal(false)}
        networkValues={networkValues}
      />
      <RemovedTokenModal open={openRemoveTokenModal} handleCloseModal={() => setOpenRemoveTokenModal(false)} />
      <div className="wallet_data_header">
        <button
          disabled={viewType === PLATFORMS_VIEW}
          className={selectedType === WALLET_TYPE ? "wallet_type_selected" : ""}
          onClick={() => setSelectedType(WALLET_TYPE)}
        >
          <h6>Wallet</h6>
          {isLoading ? (
            <Skeleton height="19.5" width="80" />
          ) : (
            <p>{beautifyTokensAmount(balances[WALLET_TYPE], { prefix: "$" })}</p>
          )}
        </button>
        <button
          className={selectedType === STAKING_TYPE ? "wallet_type_selected" : ""}
          onClick={() => setSelectedType(STAKING_TYPE)}
        >
          <h6>Staking</h6>
          {isLoading ? (
            <Skeleton height="19.5" width="80" />
          ) : (
            <p>{beautifyTokensAmount(balances[STAKING_TYPE], { prefix: "$" })}</p>
          )}
        </button>
        <button
          className={selectedType === LP_TYPE ? "wallet_type_selected" : ""}
          onClick={() => setSelectedType(LP_TYPE)}
        >
          <h6>LP</h6>
          {isLoading ? (
            <Skeleton height="19.5" width="80" />
          ) : (
            <p>{beautifyTokensAmount(balances[LP_TYPE], { prefix: "$" })}</p>
          )}
        </button>
        <div className="wallet_data_view_options">
          <p>View options</p>
          <ViewDropdown showStack={showStack} selectedView={viewType} setSelectedView={setViewType} />
        </div>
      </div>
      <NetworkLabels
        walletData
        network={network}
        setNetwork={setNetwork}
        walletValues={networkValues}
        selectedType={selectedType}
      />
      <div className="wallet_data_blacklisted">
        <button onClick={() => setOpenRemoveTokenModal(true)}>Removed tokens</button>
      </div>
      {/* If show user's stacked wallets is true AND View Type is Wallet View */}
      {showStack && viewType === WALLET_VIEW && (
        <div className="wallet_data_stack_main">
          <div className="wallet_data_stack_main_scrollable" ref={stackWrapperRef} onScroll={onScroll}>
            {stackAddressBalances.map((item) => (
              <WalletDataStackItem
                selectedType={selectedType}
                key={item._id}
                item={item}
                prices={prices}
                lp={selectedType === LP_TYPE}
              />
            ))}
          </div>
          <button className="wallet_data_expand" onClick={() => setOpenModal(true)}>
            <ExpandIcon />
          </button>
          <div ref={shadowRef} className="wallet_data_shadow" />
        </div>
      )}
      {/* If show user's stacked wallets is true */}
      {showStack && viewType === ASSETS_VIEW && (
        <div className="wallet_data_wallet">
          <div className="wallet_data_wallet_scrollable" ref={stackWrapperRef} onScroll={onScroll}>
            <WalletTable secondary showStack={showStack} lp={selectedType === "lp"}>
              <thead>
                {selectedType === "lp" ? (
                  <tr>
                    <th>{t("common.asset")}</th>
                    <th style={{ textAlign: "left" }}>{t("common.value")}</th>
                  </tr>
                ) : (
                  <tr>
                    <th>{t("common.asset")}</th>
                    <th>{t("common.balance")}</th>
                    <th className="wallet_no_mobile">{t("common.price")}</th>
                    <th>{t("common.value")}</th>
                    {showStack && <th />}
                  </tr>
                )}
              </thead>
              <tbody>
                {!app.isLoading && wallet && assetData && (
                  <TokenRows tokens={assetData} type={selectedType} network={network} />
                )}
              </tbody>{" "}
            </WalletTable>
          </div>
          <button className="wallet_data_expand" onClick={() => setOpenModal(true)}>
            <ExpandIcon />
          </button>
          <div ref={shadowRef} className="wallet_data_shadow" />
        </div>
      )}
      {/* If show user's stacked wallets is false && Selected Type IS NOT LP Tokens */}
      {!showStack && selectedType !== LP_TYPE && viewType === ASSETS_VIEW && (
        <div className="wallet_data_wallet">
          <div ref={stackWrapperRef} className="wallet_data_wallet_scrollable" onScroll={onScroll}>
            <table className="wallet_data_table wallet_data_single">
              <thead>
                <tr>
                  <th>Asset</th>
                  <th>Balance</th>
                  <th>Price</th>
                  <th>Value</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {walletTokensData.length === 0 && (
                  <tr>
                    <td colSpan={4} style={{ textAlign: "left" }}>
                      No tokens
                    </td>
                  </tr>
                )}
                {walletTokensData.length !== 0 &&
                  walletTokensData.map((token) => (
                    <tr key={`${token.network}_${token.address || Math.random()}`}>
                      <td>
                        <div className="wallet_data_row_asset">
                          <AssetImage
                            fallbackLogo={tokensLogos[token.symbol]}
                            className="wallet_data_table_icon"
                            src={`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${
                              assetImageNetwork[token.network]
                            }/assets/${
                              token.address === "0x0000000000000000000000000000000000000000"
                                ? getAddressSum(wrapperNetworkToken[token.network])
                                : getAddressSum(token.address)
                            }/logo.png`}
                            alt={token.symbol}
                          />
                          {token.symbol.toUpperCase()}
                        </div>
                      </td>
                      <td>{beautifyTokensAmount(token.amount)}</td>
                      <td>{beautifyTokensAmount(prices[token.symbol.toUpperCase()] || 0, { prefix: "$" })}</td>
                      <td>
                        {beautifyTokensAmount(token.value, {
                          prefix: "$",
                        })}
                      </td>
                      <td>
                        {token.address && selectedType === WALLET_TYPE && (
                          <div className="wallet_data_table_hide">
                            <button onClick={() => dispatch(userDataOperations.addToTokenBlacklist(token))}>
                              <HideEyeIcon />
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <button className="wallet_data_expand" onClick={() => setOpenModal(true)}>
            <ExpandIcon />
          </button>
          <div ref={shadowRef} className="wallet_data_shadow" />
        </div>
      )}

      {/* If show user's stacked wallets is false && Selected Type IS AND ONLY IS LP Tokens */}
      {!showStack && selectedType === LP_TYPE && viewType === ASSETS_VIEW && (
        <div className="wallet_data_wallet">
          <div ref={stackWrapperRef} className="wallet_data_wallet_scrollable" onScroll={onScroll}>
            <div className="wallet_data_wallet_lp">
              <div className="wallet_data_wallet_lp_header">
                <h4>Assets</h4>
                <h4>Platform</h4>
                <h4>Value</h4>
              </div>

              {walletTokensData.length === 0 && (
                <div className="wallet_lp_item">
                  <div className="wallet_lp_item_header">No tokens</div>
                </div>
              )}
              {walletTokensData.length !== 0 &&
                walletTokensData.map((token) => <LPItem onScroll={onScroll} token={token} key={token.address} />)}
            </div>
          </div>
          <div ref={shadowRef} className="wallet_data_shadow" />
        </div>
      )}
      {viewType === PLATFORMS_VIEW && (
        <div className="wallet_data_wallet">
          <div ref={stackWrapperRef} className="wallet_data_wallet_scrollable" onScroll={onScroll}>
            {platforms
              .filter(
                (item) =>
                  item.network === network && (selectedType === STAKING_TYPE ? !item.category : item?.category === "lp")
              )
              .sort((a, b) => {
                const operations = showStack ? stackBalanceOperations : walletOperations;
                const valueA = dispatch(
                  operations.getNetworkPlatformValue(a.network, a.name.toLowerCase(), a.category && a.category === "lp")
                );
                const valueB = dispatch(
                  operations.getNetworkPlatformValue(b.network, b.name.toLowerCase(), b.category && b.category === "lp")
                );

                return BigNumber(valueB).minus(BigNumber(valueA));
              })
              .map((item) => (
                <PlatformCard key={`${item.name}_${item.network}`} network={item.network} item={item} />
              ))}
            {platforms
              .filter(
                (item) =>
                  item.network === network && (selectedType === STAKING_TYPE ? !item.category : item?.category === "lp")
              )
              .filter((item) => {
                const operations = showStack ? stackBalanceOperations : walletOperations;
                const value = dispatch(
                  operations.getNetworkPlatformValue(
                    item.network,
                    item.name.toLowerCase(),
                    item.category && item.category === "lp"
                  )
                );
                return value > 0;
              }).length === 0 && <div>No platforms</div>}
          </div>
          <button className="wallet_data_expand" onClick={() => setOpenModal(true)}>
            <ExpandIcon />
          </button>
          <div ref={shadowRef} className="wallet_data_shadow" />
        </div>
      )}
    </WalletDataWrapper>
  );
};

export default WalletData;
