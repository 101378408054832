export const showTransactionManager = () => ({
  type: "SHOW_TRANSACTION_MANAGER",
});

export const closeTransactionManager = () => ({
  type: "CLOSE_TRANSACTION_MANAGER",
});

export const addTx = (tx) => {
  return {
    type: "ADD_TX",
    payload: tx,
  };
};

export const removeTx = (txHashs) => ({
  type: "REMOVE_TX",
  payload: txHashs,
});
