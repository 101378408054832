import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { userDataOperations } from "./userDataSlice";

const API = process.env.REACT_APP_PRO_API;

const initialState = {
  // For Discovery page
  tokensStats: {
    tokenCount: null,
    totalMarketCap: null,
    dailyVolume: null,
  },
  allTokens: [],
  // For Dashboard widget
  dashboardTokens: [],
  // For Single Token Discovery Page
  tokenData: {},
  isFetching: false,
  error: null,
};

const discovery = createSlice({
  name: "discovery",
  initialState,
  reducers: {
    setTokensStats(state, action) {
      return { ...state, tokensStats: action.payload };
    },
    setAllTokens(state, action) {
      return { ...state, allTokens: action.payload };
    },
    setDashboardTokens(state, action) {
      return { ...state, dashboardTokens: action.payload };
    },
    setTokenData(state, action) {
      return { ...state, tokenData: action.payload };
    },
    setIsFetching(state, action) {
      return { ...state, isFetching: action.payload };
    },
    setError(state, action) {
      return { ...state, error: action.payload };
    },
  },
});

export const discoveryActions = discovery.actions;

const getRoot = (state) => state.discovery;

export const discoverySelectors = {
  getAllTokens: (state) => getRoot(state).allTokens,
};

// If users visits same page after short period of time
// while first page is still getting fetched
// - Stops first pages fetching
const CancelToken = axios.CancelToken;
let singleToken_cancel;

export const discoveryOperations = {
  getTokenList: (page, pageSize) => async (dispatch, getState) => {
    dispatch(discoveryActions.setIsFetching(true));
    dispatch(discoveryActions.setError(null));
    try {
      const state = getState();
      const token = state.userData.tokens.access.token;

      const config = {
        headers: {
          Authorization: token,
        },
      };
      const {
        data: { tokens, ...data },
      } = await axios.get(`${API}/v1/research/tokens-dashboard?page=${page}&pageSize=${pageSize}`, config);

      dispatch(discoveryActions.setAllTokens(tokens));
      dispatch(discoveryActions.setTokensStats(data));
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch(userDataOperations.logout());
      }
      dispatch(discoveryActions.setError(err.response.data.msg));
    }
    dispatch(discoveryActions.setIsFetching(false));
  },
  getDiscoveryTokenList: (page, pageSize) => async (dispatch, getState) => {
    const state = getState();
    const isFetching = state.discovery.isFetching;
    if (isFetching) {
      return;
    }
    dispatch(discoveryActions.setIsFetching(true));
    dispatch(discoveryActions.setError(null));
    try {
      const token = state.userData.tokens.access.token;

      const config = {
        headers: {
          Authorization: token,
        },
      };
      const {
        data: { tokens, ...data },
      } = await axios.get(`${API}/v1/research/tokens-dashboard?page=${page}&pageSize=${pageSize}`, config);

      dispatch(discoveryActions.setDashboardTokens(tokens));
      dispatch(discoveryActions.setTokensStats(data));
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch(userDataOperations.logout());
      }
      dispatch(discoveryActions.setError(err.response.data.msg));
    }
    dispatch(discoveryActions.setIsFetching(false));
  },
  getSingleToken: (tokenID, ignoreCancel) => async (dispatch, getState) => {
    singleToken_cancel && singleToken_cancel("Cancel");

    dispatch(discoveryActions.setIsFetching(true));
    dispatch(discoveryActions.setError(null));
    try {
      const state = getState();
      const token = state.userData.tokens.access.token;

      const config = {
        headers: {
          Authorization: token,
        },
      };

      const { data } = await axios.get(`${API}/v1/research/token-data?token=${tokenID}`, {
        ...config,
        cancelToken:
          ignoreCancel &&
          new CancelToken(function executor(c) {
            singleToken_cancel = c;
          }),
      });

      dispatch(discoveryActions.setTokenData(data));
    } catch (err) {
      if (err.response && err.response.status === 401) {
        return dispatch(userDataOperations.logout());
      }
      if (err.message !== "Cancel") {
        dispatch(discoveryActions.setIsFetching(false));
        dispatch(discoveryActions.setError(err.response ? err.response.data : err.message));
      }
    }
    dispatch(discoveryActions.setIsFetching(false));
  },
};

export const discoveryReducer = discovery.reducer;

export function setCoinId(set_coin) {
  return function (dispatch) {
    dispatch({
      type: "FETCH_COINID_SUCCESS",
      set_coin: set_coin,
    });
  };
}

export function setPopUp(popup) {
  return function (dispatch) {
    dispatch({
      type: "FETCH_POPUP_SUCCESS",
      popup: popup,
    });
  };
}

export function getTokensAll() {
  return function (dispatch, getState) {
    const url = `${API}/v1/research/tokens-all`;
    const auth_token = getState().userData.tokens.access.token;
    const params = {};
    axios({
      url,
      params,
      headers: {
        Authorization: `${auth_token}`,
      },
    })
      .then(function (response) {
        dispatch({
          type: "FETCH_ALLTOKENS_SUCCESS",
          AllTokens: response.data.result,
        });
      })
      .catch(() => {});
  };
}
