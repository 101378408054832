import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import { ReactComponent as DownwardArrow } from "../../assets/downward-arrow.svg";
import { ReactComponent as GridViewActive } from "../../assets/grid-view-active.svg";
import { ReactComponent as GridViewInactive } from "../../assets/grid-view-inactive.svg";
import { ReactComponent as ListViewActive } from "../../assets/list-view-active.svg";
import { ReactComponent as ListViewInactive } from "../../assets/list-view-inactive.svg";
import { ReactComponent as SearchIcon } from "../../assets/search-icon.svg";
import { ReactComponent as UpwardArrow } from "../../assets/upward-arrow.svg";
import { newsOperations } from "../../redux/Slices/newsSlice";
import { ClickableButton, LineBreak, ListWrapper, MobileWrapper, SerachInput, Wrapper } from "./Tools.styles.js";

function Tools({ setView, mobileScreen, setReadingList, readingList }) {
  const dispatch = useDispatch();
  const [selectNoun, setSelectNoun] = useState(true);
  const [selectCard, setSelectCard] = useState(false);

  const [searchValue, setSearchValue] = useState("");

  const [mostVote, setMostVote] = useState(false);
  const [mostUnvote, setMostUnvote] = useState(false);

  const [showSearch, setShowSearch] = useState(false);

  const handleMostVote = () => {
    if (mostUnvote) {
      setMostUnvote(!mostUnvote);
      setMostVote(!mostVote);
    } else {
      setMostVote(!mostVote);
    }

    if (!mostVote) {
      dispatch(newsOperations.sortingNews({ sort: "vote" }));
    } else {
      dispatch(newsOperations.getNewsList());
    }
  };

  const handleMostUnvote = () => {
    if (mostVote) {
      setMostVote(!mostVote);
      setMostUnvote(!mostUnvote);
    } else {
      setMostUnvote(!mostUnvote);
    }

    if (!mostUnvote) {
      dispatch(newsOperations.sortingNews({ sort: "unvote" }));
    } else {
      dispatch(newsOperations.loadAllNews());
    }
  };

  const handleShowAllList = () => {
    setMostUnvote(false);
    setMostVote(false);
    dispatch(newsOperations.loadAllNews());
  };

  const handleSelectList = () => {
    if (selectNoun == false) {
      setSelectNoun(!selectNoun);
      setSelectCard(!selectCard);
      setView("noun");
    }
  };

  const handleSelectNoun = () => {
    if (selectCard == false) {
      setSelectCard(!selectCard);
      setSelectNoun(!selectNoun);
      setView("card");
    }
  };

  const handleShowReadingList = async () => {
    setReadingList(!readingList);
  };

  useEffect(() => {
    if (searchValue == "") {
      dispatch(newsOperations.loadAllNews());
    } else {
      dispatch(newsOperations.searchNewsList(searchValue));
    }
  }, [searchValue]);

  return (
    <>
      {mobileScreen ? (
        <MobileWrapper>
          <div className="news_mobile_upper">
            <ClickableButton icon onClick={() => setShowSearch((prev) => !prev)}>
              <SearchIcon />
            </ClickableButton>
            <ClickableButton onClick={handleShowReadingList}>{readingList ? "News" : "Reading List"}</ClickableButton>
            <ListWrapper>
              <button className={`listView clickable ${selectNoun ? `toggle` : ``}`} onClick={handleSelectList}>
                {selectNoun ? <ListViewActive className="view" /> : <ListViewInactive className="view" />}
              </button>
              <button className={`listView clickable ${selectCard ? `toggle` : ``}`} onClick={handleSelectNoun}>
                {selectCard ? <GridViewActive className="view" /> : <GridViewInactive className="view" />}
              </button>
            </ListWrapper>
          </div>
          {showSearch && (
            <SerachInput>
              <input
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                autoFocus
                type="text"
                placeholder="Search Token or keyword..."
              />
            </SerachInput>
          )}
        </MobileWrapper>
      ) : (
        <Wrapper>
          <SerachInput>
            <SearchIcon className="clickable" />
            <input
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              type="text"
              placeholder="Search Token or keyword..."
            />
          </SerachInput>

          <span className="verticleLine" />
          <ClickableButton click={"click"} onClick={handleShowAllList}>
            Recents
          </ClickableButton>
          <ClickableButton mostVote={mostVote ? "active" : ""} onClick={handleMostVote}>
            Most{" "}
            <span>
              <UpwardArrow />
            </span>
          </ClickableButton>

          <ClickableButton mostUnvote={mostUnvote ? "active" : ""} onClick={handleMostUnvote}>
            Most{" "}
            <span>
              <DownwardArrow />
            </span>
          </ClickableButton>
          {/* toggle view */}
          <ListWrapper>
            <button className={`listView clickable  ${selectNoun ? `toggle` : ``}`} onClick={handleSelectList}>
              {selectNoun ? <ListViewActive className="view" /> : <ListViewInactive className="view" />}
            </button>
            <button className={`listView clickable  ${selectCard ? `toggle` : ``}`} onClick={handleSelectNoun}>
              {selectCard ? <GridViewActive className="view grid" /> : <GridViewInactive className="view" />}
            </button>
          </ListWrapper>
        </Wrapper>
      )}

      <LineBreak />
    </>
  );
}

export default Tools;
