import styled from "styled-components";

import { breakPoints } from "../../Theme";

export const TransactionNoteWrapper = styled.div`
  padding: 1rem 0;
  .tx_note_details {
    padding-bottom: 1.5rem;
    border-bottom: 1px solid ${(props) => props.theme.researchBorder};

    h4 {
      color: ${(props) => props.theme.lightGrey};
      font-size: 14px;
      margin-bottom: 0.5rem;
    }
    .tx_note_transaction {
      background: ${(props) => props.theme.txNoteBg};
      border-radius: 6px;
      padding: 1rem;
    }
    @media (max-width: ${breakPoints.lg}px) {
      border: 0;
      padding: 0;
      h4 {
        margin-bottom: 1rem;
      }
      .tx_note_transaction {
        display: none;
      }
    }
  }
  h3 {
    font-size: 18px;
    margin: 1.5rem 0 1rem;
  }
  textarea {
    width: 100%;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 16px;
    background: ${(props) => props.theme.bg};
    padding: 1rem;
    border-radius: 6px;
    border: 0;
    outline: 0;
    color: ${(props) => props.theme.mainDarkText};
    resize: vertical;
    min-height: 52px;
    max-height: 230px;
    ::placeholder {
      color: ${(props) => props.theme.lightDarkGrey};
    }
  }
  .tx_note_buttons {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
    button {
      cursor: pointer;
      padding: 10px;
      min-width: 116px;
      font-weight: 600;
      border-radius: 6px;
      outline: 0;
      border: 1px solid transparent;
    }
    .tx_note_main_button {
      background: ${(props) => props.theme.brandBlue};
      color: ${(props) => props.theme.superLightBlue};
      :hover {
        background: ${(props) => props.theme.darkBrandBlue};
      }
      :disabled {
        pointer-events: none;
        background: ${(props) => props.theme.disabledText};
        color: ${(props) => props.theme.disabled};
      }
    }
    .tx_note_secondary_button {
      background: ${(props) => props.theme.darkGrey};
      color: ${(props) => props.theme.mainDarkText};
      border-color: ${(props) => props.theme.pagination_border};
      :hover {
        background: ${(props) => props.theme.researchBorder};
      }
    }
  }
`;
