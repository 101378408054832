import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { ReactComponent as ArrowDown } from "../assets/arrowDown.svg";

const Wrapper = styled.div`
  cursor: pointer;
  position: relative;
  color: ${(props) => (props.selected ? props.theme.mainDarkText : props.theme.researchBorder)};
  padding: 0.5rem;
  background: ${(props) => (props.selected ? "#101926" : props.theme.secondaryBg)};
  border: 1px solid ${(props) => (props.selected ? "#3A4C61" : props.theme.researchBorder)};
  border-radius: ${(props) => (props.open ? `6px 6px 0 0` : `6px`)};
  font-size: 12px;
  min-width: 130px;
  max-width: 130px;

  ${(props) =>
    props.open
      ? css`
          background: #122a4b;
          border: 1px solid #005ad3;
        `
      : ``}

  .dropdown_selected {
    display: flex;
    align-items: center;
    img {
      height: 16px;
      width: 16px;
      margin-right: 6px;
    }
    span {
      flex: 1;
      margin-right: 1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    svg {
      height: 100%;
      width: 14px;
      path {
        fill: ${(props) => props.theme.mainDarkText};
      }
    }
  }

  .dropdown_options {
    background: ${(props) => props.theme.bg};
    color: ${(props) => props.theme.mainDarkText};
    overflow: auto;
    top: 100%;
    left: -1px;
    position: absolute;
    width: 130%;
    max-height: 180px;
    border: 1px solid ${(props) => (props.selected ? props.theme.researchBorder : props.theme.researchBorder)};
    border-radius: 0 6px 6px 6px;
    z-index: 2;
    > div {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 0.5rem;
      :not(:last-of-type) {
        border-bottom: 1px solid ${(props) => props.theme.researchBorder};
      }
      img {
        height: 16px;
        width: 16px;
        margin-right: 6px;
      }
      :hover {
        background: ${(props) => props.theme.secondaryBg};
      }
    }
  }
`;

/* 
*  options structure [
    {
        id: string,
        text: string,
        img: string?
    }
]
*
*
*/
const Dropdownv2 = ({ options, setOption, selectedOption, placeholder = "select", className, inputHeight }) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const { t } = useTranslation();

  const selectedOptionObject = selectedOption && options.find((item) => item.id === selectedOption);

  const handleSelectOption = (id) => {
    setOption(id);
    setOpen(false);
  };

  const onClickOutsideListener = () => {
    setOpen(false);
    document.removeEventListener("click", onClickOutsideListener);
  };

  return (
    <Wrapper
      className={className}
      open={open}
      selected={selectedOptionObject}
      onClick={() => {
        setOpen(!open);
        setSearch("");
      }}
      onMouseLeave={() => {
        if (open) {
          document.addEventListener("click", onClickOutsideListener);
        }
      }}
    >
      <div className="dropdown_selected">
        {open ? (
          <input
            style={{ fontSize: "12px", height: inputHeight ?? "15px", flex: "1" }}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            autoFocus
          />
        ) : (
          <>
            {selectedOptionObject && selectedOptionObject.img && selectedOptionObject.img}
            <span>{selectedOptionObject ? selectedOptionObject.text : placeholder}</span>
            <ArrowDown />
          </>
        )}
      </div>
      {open && options.length !== 0 && (
        <div className="dropdown_options">
          {options.filter((item) => (search ? item.text.toLowerCase().includes(search.toLowerCase()) : true)).length !==
          0 ? (
            options
              .filter((item) => (search ? item.text.toLowerCase().includes(search.toLowerCase()) : true))
              .map((item, i) => (
                <div key={`${item.id}-${i}`} onClick={() => handleSelectOption(item.id)}>
                  {item.img && item.img} {item.text}
                </div>
              ))
          ) : (
            <div>{t("strategy.noOptions")}</div>
          )}
        </div>
      )}
    </Wrapper>
  );
};

export default Dropdownv2;
