const INITIAL_STATE = {
  rates: [],
  isFetching: false,
  error: null,
};

function ratesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "FETCH_RATES_REQUEST":
      return Object.assign({}, state, {
        isFetching: true,
      });
    case "FETCH_RATES_SUCCESS":
      return Object.assign({}, state, {
        isFetching: false,
        rates: action.rates,
      });
    case "FETCH_RATES_FAILURE":
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error,
      });
    default:
      return state;
  }
}

export default ratesReducer;
