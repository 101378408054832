import React, { useEffect, useMemo } from "react";

import BigNumber from "bignumber.js";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from "recharts";
import styled from "styled-components";

import { beautifyTokensAmount } from "../../../helpers";
import { chartWidgetOperations } from "../../../redux/Slices/chartWidgetSlice";
import Loader from "../../Loader";
import WidgetWrapper from "./WidgetWrapper";

const ChartWidgetHeader = styled.div`
  img {
    height: 24px;
    width: 24px;
    margin-right: 6px;
  }
  h4 {
    font-size: 18px;
    text-transform: uppercase;
  }
  .chart_widget_header_price {
    display: flex;
    margin-left: 1.25rem;
    align-items: center;
    gap: 4px;
    h5 {
      color: ${(props) => props.theme.lightGrey};
      font-size: 18px;
    }
    p {
      font-size: 12px;
      align-self: flex-end;
      line-height: 16px;
    }
    .chart_widget_percent_positive {
      color: ${(props) => props.theme.greenShade2};
    }
    .chart_widget_percent_negative {
      color: ${(props) => props.theme.error};
    }
  }
`;

const ChartWidgetWrapper = styled.div`
  height: 100%;

  /* Chart horizontal line color */
  .recharts-cartesian-grid-horizontal,
  .recharts-cartesian-axis-tick {
    line {
      stroke: #3a4c61;
      opacity: 0.3;
    }
  }
`;

const ChartWidget = ({ setOpenModal, item }) => {
  const dispatch = useDispatch();

  const chartWidgetData = useSelector((state) => state.chartWidget);
  const { priceData, tokenData, isLoading } = chartWidgetData;

  const selectedToken = item.options && item.options.token ? item.options.token : null;

  useEffect(() => {
    if (selectedToken && !(selectedToken.coin_id in priceData) && !isLoading) {
      dispatch(chartWidgetOperations.getPriceData(selectedToken.coin_id));
      dispatch(chartWidgetOperations.getTokenData(selectedToken.coin_id));
    }
  }, [priceData, selectedToken]);

  const chartData = useMemo(
    () =>
      selectedToken && selectedToken.coin_id in priceData
        ? priceData[selectedToken.coin_id].pricesData.map((item) => ({
            price: BigNumber(item[1]).precision(8).toNumber(),
            timestamp: item[0],
          }))
        : [],
    [priceData]
  );

  return (
    <WidgetWrapper
      item={item}
      id="chart"
      setOpenModal={setOpenModal}
      header={
        selectedToken && item.options ? (
          <ChartWidgetHeader className="widget_info">
            <img src={selectedToken.icon} alt={selectedToken.name} />
            <h4>{selectedToken.symbol}</h4>
            {selectedToken.coin_id in tokenData && (
              <div className="chart_widget_header_price">
                <h5>{beautifyTokensAmount(tokenData[selectedToken.coin_id].current_price, { prefix: "$" })}</h5>
                <p
                  className={
                    BigNumber(tokenData[selectedToken.coin_id].price_change_percentage_24h_in_currency).lt(0)
                      ? "chart_widget_percent_negative"
                      : "chart_widget_percent_positive"
                  }
                >
                  {beautifyTokensAmount(tokenData[selectedToken.coin_id].price_change_percentage_24h_in_currency, {
                    suffix: "%",
                  })}
                </p>
              </div>
            )}
          </ChartWidgetHeader>
        ) : (
          <div className="widget_info">Chart</div>
        )
      }
      body={
        <ChartWidgetWrapper>
          {item.options &&
            (chartData.length !== 0 ? (
              <ResponsiveContainer width="98%" height="100%">
                <AreaChart data={chartData} margin={{ top: 30 }}>
                  <defs>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#00A3FF" stopOpacity={0.1} />
                      <stop offset="95%" stopColor="#00A3FF" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis
                    dataKey="timestamp"
                    tickFormatter={(tick) => {
                      return moment(tick).format("DD MMM");
                    }}
                    axisLine={false}
                    tick={{ fontSize: 12, fill: "#738CA6" }}
                  />
                  <YAxis
                    dataKey="price"
                    allowDecimals
                    domain={[(dataMin) => BigNumber(dataMin).times(0.995).precision(6).toFixed(), "auto"]}
                    tickCount={5}
                    tickFormatter={(tick) => beautifyTokensAmount(tick)}
                    axisLine={false}
                    tickLine={false}
                    tick={{ fontSize: "12px", dy: -12, dx: -10, fill: "#738CA6" }}
                    mirror
                  />
                  <CartesianGrid vertical={false} />
                  <Area
                    type="monotone"
                    dataKey="price"
                    stroke="#00A3FF"
                    fillOpacity={1}
                    strokeWidth={3}
                    fill="url(#colorPv)"
                  />
                </AreaChart>
              </ResponsiveContainer>
            ) : (
              <Loader />
            ))}
          {!item.options && <div>Select token in Widget options</div>}
        </ChartWidgetWrapper>
      }
    />
  );
};

export default ChartWidget;
