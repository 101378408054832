import styled from "styled-components";

import { breakPoints } from "../../Theme";

export const NewsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 180px);
  max-width: 1400px;
  max-height: 1000px;
  margin: 0 auto;
  .left-right {
    display: flex;
    justify-content: center;
    height: 100%;
  }

  .tool-dashboard {
    flex: 9 1;
    display: flex;
    flex-direction: column;
    @media (max-width: ${breakPoints.lg}px) {
      flex: 1;
    }
  }

  .liveWrapper {
    margin: 0 auto;
  }
  @media (max-width: ${breakPoints.lg}px) {
    max-height: calc(100vh - 250px);
  }
`;
