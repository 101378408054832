import React, { useMemo, useState } from "react";

import { getAddressSum } from "@keyfi/keyfi-common/src/integrations";
import BigNumber from "bignumber.js";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as ArrowDown } from "../../assets/arrowDown.svg";
import { assetImageNetwork, beautifyTokensAmount, tokensLogos, trimAddress } from "../../helpers";
import { stringToColour } from "../../Pages/ManageWallets";
import { stacksOperations } from "../../redux/wallets/stacksSlice";
import { breakPoints } from "../../Theme";
import AssetImage from "../Borrow/AssetImage";
import Modal from "../Modal";

const PlatformModalTitle = styled.div`
  display: flex;
  align-items: center;
  .title_name {
    display: flex;
    align-items: center;
    img {
      margin-right: 0.5rem;
      height: 32px;
      width: 32px;
    }
    span {
      margin-right: 1.5rem;
      font-size: 24px;
    }
  }

  .title_values {
    display: flex;
    .value_box {
      background: ${(props) => props.theme.bg};
      font-size: 16px;
      line-height: 19.5px;
      border-radius: 4px;
      margin-right: 1.5rem;
      padding: 0.5rem 1rem;
    }
  }

  @media (max-width: ${breakPoints.lg}px) {
    .title_name {
      margin: 1rem 0;
    }
  }
`;

const MoreDetailsModalWrapper = styled.div`
  padding: 2rem 0 1rem;
`;

const AddressSectionWrapper = styled.div`
  cursor: ${(props) => (props.zeroValue ? "default" : "pointer")};
  margin-bottom: 2.5rem;
  :last-of-type {
    margin: 0;
  }
  .address_section_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .address_section_title {
      display: flex;
      align-items: flex-end;
      gap: 6px;
      .address_section_icon {
        height: 16px;
        width: 16px;
        border-radius: 50%;
      }
      .address_title {
        display: flex;
        align-items: flex-end;
        gap: 6px;
        h3 {
          font-size: 16px;
          line-height: 16px;
        }
        h5 {
          font-size: 12px;
          color: ${(props) => props.theme.lightGrey};
          line-height: 12px;
        }
        @media (max-width: ${breakPoints.lg}px) {
          flex-direction: column;
          align-items: flex-start;
        }
      }
      @media (max-width: ${breakPoints.lg}px) {
        align-items: flex-start;
      }
    }
    .address_section_header_end {
      display: flex;
      align-items: center;
      gap: 1rem;
      .address_section_total {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        span {
          color: ${(props) => props.theme.lightGrey};
        }
        h3 {
          font-size: 16px;
          color: ${(props) => props.theme.form_text};
        }
        @media (max-width: ${breakPoints.lg}px) {
          flex-direction: column;
          align-items: flex-end;
          gap: 2px;
          span {
            font-size: 12px;
          }
        }
      }
      svg {
        cursor: pointer;
        height: 18px;
        width: 18px;
      }
    }
  }
  .address_section_main {
    table {
      margin-top: 0.75rem;
      width: 100%;
      border-collapse: collapse;
      th,
      td {
        width: 25%;
        text-align: left;
        :first-of-type {
          padding-left: 1rem;
        }
        :last-of-type {
          text-align: right;
          padding-right: 1rem;
        }
      }
      th {
        font-size: 12px;
        color: ${(props) => props.theme.lightGrey};
        padding-bottom: 6px;
        border-bottom: 1px solid ${(props) => props.theme.researchBorder};
      }
      tr {
        :nth-child(even) {
          background: ${(props) => props.theme.bg};
        }
      }
      td {
        padding-top: 1rem;
        padding-bottom: 1rem;
        .address_token {
          display: flex;
          gap: 0.5rem;
          align-items: center;
          .table_image,
          img {
            height: 32px;
            width: 32px;
          }
        }
      }
    }
    .mobile_address_token {
      display: none;
    }
    @media (max-width: ${breakPoints.lg}px) {
      margin-top: 1.5rem;
      table {
        display: none;
      }
      .mobile_address_token {
        display: flex;
        gap: 1.5rem;
        padding: 1rem;
        background: ${(props) => props.theme.bg};
        border-radius: 6px;
        flex-direction: column;
        margin-bottom: 1.5rem;
        > div {
          display: flex;
          width: 100%;
          > div {
            flex: 1;
            display: flex;
            align-items: center;
            :first-of-type {
              color: ${(props) => props.theme.lightGrey};
            }
            .address_token_image,
            img {
              height: 24px;
              width: 24px;
              margin-right: 0.5rem;
            }
          }
        }
      }
    }
  }
`;

const totalValue = (token, prices) => {
  if (token.type && token.type === "lp") {
    const [assetA, assetB] = [token.symbol.split(":")[0], token.symbol.split(":")[1].split(" ")[0]];

    const assetAValue = BigNumber(token[assetA]).times(prices[assetA] ?? 0);
    const assetBValue = BigNumber(token[assetB]).times(prices[assetB] ?? 0);

    return assetAValue.plus(assetBValue).toPrecision(6);
  }
  return (prices[token.symbol.toUpperCase()] ?? 0) * token.amount;
};

const getAddressValue = (filteredTokens, item, prices) => {
  return filteredTokens.reduce((acc, token) => {
    if (token.userId.toLowerCase() === item.address.toLowerCase()) {
      return BigNumber(acc).plus(totalValue(token, prices)).toPrecision(6);
    }
    return acc;
  }, 0);
};

export const AddressSection = ({ item, filteredTokens, prices }) => {
  const [open, setOpen] = useState(false);

  const total = getAddressValue(filteredTokens, item, prices);

  const reduceFunction = (acc, token) => {
    const tokenIndex = acc.findIndex((accToken) => accToken.symbol === token.symbol);
    if (tokenIndex > -1) {
      acc[tokenIndex] = {
        ...acc[tokenIndex],
        amount: BigNumber(acc[tokenIndex].amount).plus(token.amount).toPrecision(8),
      };
      return acc;
    }
    return [...acc, token];
  };

  const sortFunction = (a, b) => {
    const aValue = totalValue(a, prices);
    const bValue = totalValue(b, prices);
    return bValue - aValue;
  };

  const price = (token) => {
    if (token.type && token.type === "lp") {
      return totalValue(token, prices) / token.amount;
    }
    return prices[token.symbol.toUpperCase()];
  };

  return (
    <AddressSectionWrapper zeroValue={total === 0} onClick={() => total > 0 && setOpen((prev) => !prev)}>
      <div className="address_section_header">
        <div className="address_section_title">
          <div className="address_section_icon" style={{ background: stringToColour(item.address) }} />
          <div className="address_title">
            <h3>{item.title}</h3>
            <h5>{trimAddress(item.address)}</h5>
          </div>
        </div>
        <div className="address_section_header_end">
          <div className="address_section_total">
            <span>Total:</span>
            <h3>{beautifyTokensAmount(getAddressValue(filteredTokens, item, prices), { prefix: "$" })}</h3>
          </div>
          <ArrowDown style={{ transform: open ? "rotate(180deg)" : "", opacity: total > 0 ? "1" : "0" }} />
        </div>
      </div>
      <div style={{ display: open ? "block" : "none" }} className="address_section_main">
        <table>
          <thead>
            <tr>
              <th>Asset</th>
              <th>Balance</th>
              <th>Price</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {filteredTokens
              .reduce(reduceFunction, [])
              .sort(sortFunction)
              .map((token) => (
                <tr key={token.symbol + token.userId}>
                  <td>
                    <div className="address_token">
                      <AssetImage
                        className="table_image"
                        src={`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${
                          assetImageNetwork[token.network]
                        }/assets/${getAddressSum(token.address)}/logo.png`}
                        fallbackLogo={tokensLogos[token.symbol]}
                        alt={token.token}
                      />
                      <div>{token.symbol}</div>
                    </div>
                  </td>
                  <td>{beautifyTokensAmount(token.amount)}</td>
                  <td>{beautifyTokensAmount(price(token), { prefix: "$", suffix: " USD" })}</td>
                  <td>
                    {beautifyTokensAmount(totalValue(token, prices), {
                      prefix: "$",
                      suffix: " USD",
                    })}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {filteredTokens
          .reduce(reduceFunction, [])
          .sort(sortFunction)
          .map((token) => (
            <div key={token.symbol + token.userId + "_mobile"} className="mobile_address_token">
              <div>
                <div>Asset</div>
                <div>
                  <AssetImage
                    className="address_token_image"
                    src={`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${
                      assetImageNetwork[token.network]
                    }/assets/${getAddressSum(token.address)}/logo.png`}
                    fallbackLogo={tokensLogos[token.symbol]}
                    alt={token.token}
                  />
                  {token.symbol}
                </div>
              </div>
              <div>
                <div>Balance</div>
                <div>{beautifyTokensAmount(token.amount)}</div>
              </div>
              <div>
                <div>Price</div>
                <div>{beautifyTokensAmount(price(token), { prefix: "$", suffix: " USD" })}</div>
              </div>
              <div>
                <div>Value</div>
                <div>
                  {beautifyTokensAmount(totalValue(token, prices), {
                    prefix: "$",
                    suffix: " USD",
                  })}
                </div>
              </div>
            </div>
          ))}
      </div>
    </AddressSectionWrapper>
  );
};

const MoreDetailsModal = ({ walletValues, open, handleClose, activeTab, network }) => {
  const dispatch = useDispatch();
  const totalValue = useMemo(() => {
    if (network && activeTab) {
      return walletValues[network][activeTab];
    }
    return 0;
  }, [network, activeTab, walletValues]);

  const stackBalance = useSelector((state) => state.stackBalance);
  const { balance } = stackBalance;

  const prices = useSelector((state) => state.user.usdPrices);

  const activeStack = dispatch(stacksOperations.getActiveStack(stackBalance.activeStackId));

  const stackData = {
    wallet: balance.tokens,
    staking: balance.staking,
    lp: balance.lp,
  };

  const filteredTokens = activeTab && network ? stackData[activeTab].filter((item) => item.network === network) : [];

  return (
    <Modal
      open={open}
      handleCloseModal={handleClose}
      maxwidth="925px"
      width="100%"
      title={
        <PlatformModalTitle>
          <div className="title_name">
            <span>Wallet</span>
          </div>
          <div className="title_values">
            <div className="value_box">{beautifyTokensAmount(totalValue, { prefix: "$" })}</div>
          </div>
        </PlatformModalTitle>
      }
    >
      <MoreDetailsModalWrapper>
        {activeStack &&
          activeStack.addresses &&
          activeStack.addresses
            .slice()
            .sort((a, b) => {
              const aValue = getAddressValue(filteredTokens, a, prices);
              const bValue = getAddressValue(filteredTokens, b, prices);
              return parseFloat(bValue) - parseFloat(aValue);
            })
            .map((item) => (
              <AddressSection
                key={item._id}
                item={item}
                prices={prices}
                filteredTokens={filteredTokens.filter(
                  (token) => token.userId.toLowerCase() === item.address.toLowerCase()
                )}
              />
            ))}
      </MoreDetailsModalWrapper>
    </Modal>
  );
};

export default MoreDetailsModal;
