import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

import { beautifyTokensAmount } from "../../../helpers";
import { discoveryOperations } from "../../../redux/discoverySlice";
import { breakPoints } from "../../../Theme";
import Loader from "../../Loader";
import { TableMain } from "../dashboard.style";
import WidgetWrapper from "./WidgetWrapper";

const ResearchWidget = ({ setOpenModal, item }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const discovery = useSelector((state) => state.discovery);
  const { t } = useTranslation();
  const { dashboardTokens, isFetching } = discovery;

  useEffect(() => {
    if (dashboardTokens.length === 0) {
      dispatch(discoveryOperations.getDiscoveryTokenList(1, item?.options?.size?.row === "2" ? 20 : 10));
    }
  }, [dashboardTokens, dispatch]);

  return (
    <WidgetWrapper
      item={item}
      setOpenModal={setOpenModal}
      id="research"
      header={
        <div className="widget_info">
          <Link to="/research">{t("dashboard.widget.research")}</Link>
        </div>
      }
      body={
        isFetching ? (
          <Loader style={{ marginTop: "3rem" }} />
        ) : (
          <div className="widget_main" style={{ display: "flex", flexDirection: "column", overflowY: "auto" }}>
            <TableMain>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{t("common.token")}</th>
                  <th>{window.innerWidth > breakPoints.lg && t("common.symbol")}</th>
                  <th>{t("common.price")}</th>
                  <th>24h</th>
                  {/* <th className="research_no_mobile" /> */}
                </tr>
              </thead>
              <tbody className={isFetching ? "load_opacity" : ""}>
                {dashboardTokens.length !== 0 &&
                  dashboardTokens.map((token, index) => {
                    return (
                      <tr key={token.name} onClick={() => history.push(`/research/token/${token.coin_id}`)}>
                        <td>{index + 1}</td>
                        <td>
                          <div className="research_widget_token">
                            <img src={token.icon} alt="token"></img> {window.innerWidth > breakPoints.lg && token.name}
                          </div>
                        </td>
                        <td>{token.symbol.toUpperCase()}</td>
                        <td>{beautifyTokensAmount(token.current_price, { prefix: "$" })}</td>
                        <td
                          className={
                            token.price_change_percentage_24h_in_currency < 0
                              ? "research_widget_minus"
                              : "research_widget_diff"
                          }
                        >
                          {beautifyTokensAmount(token.price_change_percentage_24h_in_currency, { suffix: "%" })}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </TableMain>
          </div>
        )
      }
    />
  );
};

export default ResearchWidget;
