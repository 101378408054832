import React, { useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch , useSelector } from "react-redux";
import styled from "styled-components";

import { alertsOperations } from "../../../redux/alertsSlice";
import { createAlertOperations } from "../../../redux/createAlertSlice";
import AlertDropdown from "../AlertDropdown";
import { sortFunction } from "./alertTypeHelpers";

const Wrapper = styled.div`
  h4 {
    font-size: 12px;
    margin-bottom: 0.5rem;
    color: ${(props) => props.theme.lightGrey};
  }
  .volume_dropdown {
    max-width: 200px;
    width: 100%;
    margin-bottom: 1.5rem;
  }
  .volume_conditions {
    margin-bottom: 1.5rem;
    button {
      z-index: 1;
      width: 140px;
      padding: 0.5rem 0;
      background: none;
      border: 1px solid ${(props) => props.theme.researchBorder};
      color: ${(props) => props.theme.mainDarkText};
      :hover {
        background: ${(props) => props.theme.secondaryBtnHover};
      }
      :first-of-type {
        border-radius: 6px 0 0 6px;
      }
      :last-of-type {
        border-radius: 0 6px 6px 0;
      }
    }
    .volume_condition_selected {
      z-index: 4;
      background: ${(props) => props.theme.brandBlue};
      color: white;
      border-color: ${(props) => props.theme.brandBlue};
      :hover {
        border-color: ${(props) => props.theme.darkBrandBlue};
        background: ${(props) => props.theme.darkBrandBlue};
      }
    }
  }
  .volume_percentage {
    display: flex;
    align-items: center;
    padding: 0.75rem 0.5rem;
    border-radius: 6px;
    border: 1px solid ${(props) => props.theme.researchBorder};
    width: 100%;
    max-width: 200px;
    line-height: 16px;
    input {
      height: 16px;

      width: 100%;
    }
  }
`;

const VolumeAlert = ({ editModal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");

  const editAlert = useSelector((state) => state.alerts.editAlert);
  const createAlert = useSelector((state) => state.createAlert);
  const { volume } = createAlert;

  const watchlistList = useSelector((state) => state.watchlist.watchlistList);

  const data = {
    asset: editModal ? editAlert.asset : volume.asset,
    conditional: editModal ? editAlert.conditional : volume.conditional,
    value: editModal ? editAlert.value : volume.value,
  };

  const handleInputChange = (name, value) => {
    if (editModal) {
      return dispatch(alertsOperations.handleEditAlert(name, value));
    }
    return dispatch(createAlertOperations.setVolume(name, value));
  };

  const filteredList = useMemo(() => {
    return watchlistList
      .filter(
        ({ name, symbol }) =>
          name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          symbol.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .map((item) => ({
        id: item.coin_id,
        text: item.symbol.toUpperCase(),
        name: item.name,
        img: <img src={item.icon} alt={item.name} />,
      }))
      .slice(0, 20)
      .sort((a, b) => sortFunction(a, b, searchQuery));
  }, [watchlistList, searchQuery]);

  return (
    <Wrapper>
      <h4>{t("alert.chooseAnAsset")}</h4>
      <AlertDropdown
        name="asset"
        selectedOption={data.asset}
        placeholder={t("alert.chooseAnAsset")}
        options={filteredList}
        className="volume_dropdown"
        setOption={handleInputChange}
        searchBar
        onChange={(e) => setSearchQuery(e.target.value)}
        searchQuery={searchQuery}
      />
      <h4>{t("alert.condition")}</h4>
      <div className="volume_conditions">
        <button
          onClick={() => handleInputChange("conditional", ">")}
          className={data.conditional === ">" ? "volume_condition_selected" : ""}
        >
          {t("alert.increase")}
        </button>
        <button
          onClick={() => handleInputChange("conditional", "<")}
          className={data.conditional === "<" ? "volume_condition_selected" : ""}
        >
          {t("alert.decrease")}
        </button>
      </div>
      <h4>{t("alert.percentage")}</h4>
      <div className="volume_percentage">
        <input name="value" value={data.value} onChange={(e) => handleInputChange(e.target.name, e.target.value)} />
        <div>%</div>
      </div>
    </Wrapper>
  );
};

export default VolumeAlert;
