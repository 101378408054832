import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled, { css } from "styled-components";

// Assets and helpers
import { ReactComponent as ArrowDown } from "../../../assets/arrowDown.svg";
import { beautifyTokensAmount, compareFunction, poolsLogos } from "../../../helpers";
import { Skeleton } from "../../../Shared";
import AssetImage from "../AssetImage";
import { tableHeadItems } from "../BorrowConstants";
import { BorrowTableWrapper } from "./BorrowTable.styles";

const ArrowDownSVG = styled(ArrowDown)`
  transform: ${(props) => (props.rotate ? "rotate(180deg)" : undefined)};
  path {
    fill: ${(props) => props.theme.researchBorder} !important;
  }
  ${(props) =>
    props.selected &&
    css`
      path {
        fill: ${props.theme.form_text} !important;
      }
    `}
`;

const BorrowTableDesktop = ({ data, isFetching }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [sort, setSort] = useState({
    field: tableHeadItems(t)[3].id, // Sets Variable APY as default
    direction: "asc",
  });

  const changeSort = (field) => {
    setSort((prevSort) => {
      if (prevSort.field === field) {
        return {
          ...prevSort,
          direction: prevSort.direction === "asc" ? "desc" : "asc",
        };
      }
      return {
        field,
        direction: "desc",
      };
    });
  };

  return (
    <BorrowTableWrapper>
      <thead>
        <tr>
          {tableHeadItems(t).map((item) => (
            <th key={item.id}>
              <div onClick={() => changeSort(item.id)}>
                {item.label}
                <div>
                  <ArrowDownSVG rotate="true" selected={item.id === sort.field && sort.direction === "asc"} />
                  <ArrowDownSVG selected={item.id === sort.field && sort.direction === "desc"} />
                </div>
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {!isFetching ? (
          data.sort(compareFunction(sort)).map((item) => (
            <tr
              key={item.address + item.platform}
              onClick={() =>
                history.push(`/borrow/${item.platform.split(" ")[0].toLowerCase()}/${item.version}/${item.symbol}`)
              }
            >
              <td>
                <div>
                  <AssetImage
                    className="borrow_table_desktop_image"
                    src={`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${item.address}/logo.png`}
                    alt={item.symbol}
                  />
                  {item.symbol}
                </div>
              </td>
              <td>
                <div>
                  {poolsLogos[item.platform.split(" ")[0]]} {item.platform}
                </div>
              </td>
              <td>
                {beautifyTokensAmount(item.availableToBorrow, {
                  suffix: ` ${item.symbol}`,
                })}
              </td>
              <td>
                {beautifyTokensAmount(item.variableAPY * 100, {
                  suffix: "%",
                  percent: true,
                })}
              </td>
              <td>
                {item.stableAPY !== "0"
                  ? beautifyTokensAmount(item.stableAPY * 100, {
                      suffix: "%",
                      percent: true,
                    })
                  : "-"}
              </td>
              {/* <td>
                <Link to={`/borrow/${item.platform.split(" ")[0].toLowerCase()}/${item.version}/${item.symbol}`}>
                  <Button primary>{t("borrow.title")}</Button>
                </Link>
              </td> */}
            </tr>
          ))
        ) : (
          <tr>
            {[...Array(6)].map((e, i) => (
              <td key={Math.random()}>
                <Skeleton width={i === 5 ? "100" : undefined} />
              </td>
            ))}
          </tr>
        )}
      </tbody>
    </BorrowTableWrapper>
  );
};

export default BorrowTableDesktop;
