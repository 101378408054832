import React, { useEffect, useState } from "react";

import styled from "styled-components";

import { compareFunction, poolsLogos, tokensLogos } from "../../helpers";
import { PoolsCardWrapper } from "../../Shared";
import { breakPoints } from "../../Theme";
import { ReactComponent as SortArrowIcon } from "./../../assets/sortArrow.svg";

const StyledTable = styled.table`
  border-collapse: collapse;
  min-width: 400px;
  width: 100%;

  th {
    border-bottom: 1px solid ${(props) => props.theme.form_text};
    padding-left: 17px;
    color: ${(props) => props.theme.form_text};
    font-size: 12px;
    text-align: left;
    font-weight: 500;
  }

  thead tr {
    background: ${(props) => props.theme.gradientBg};
    height: 32px;
    th > * {
      color: ${(props) => props.theme.form_text};
      font-size: 12px;
      line-height: 16px;
      text-align: left;
      display: flex;
    }
  }

  tbody {
    border-spacing: 0px;
    svg,
    img {
      width: 24px;
      height: 24px;
    }
    tr {
      border-spacing: 0px;
      height: 56px;

      :nth-of-type(even) {
        background: ${(props) => props.theme.bg};
      }
      /* background: ${(props) => props.theme.gradientBg}; */
    }

    td {
      color: ${(props) => props.theme.default_color};
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      position: relative;
      text-align: left;
    }

    tr:hover {
      // background: rgb(0, 70, 162, 0.2);
    }
  }
`;

const TableWrapper = styled(PoolsCardWrapper)`
  border: 0;
  overflow-x: auto;
  padding: 0;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 0;
  display: table;
  background: none;
  @media (max-width: ${breakPoints.lg}px) {
    display: none;
  }
`;

export const StyledLogoWrapper = styled.div`
  color: ${(props) => props.theme.text_greyDark};
  font-size: 12px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: none;
  white-space: nowrap;
  cursor: pointer;
  img,
  svg {
    margin-left: 8px;
  }

  .sort-arrow {
    margin-left: 8px;
    transform: ${(props) => (props.state === "asc" ? `scale(-1)` : `none`)};
    rect {
      fill: none;
    }
  }
`;
const RowsIconsWrapper = styled.div`
  position: absolute;
  align-items: center;
  color: ${(props) => props.theme.default_color};
  display: flex;
  left: -15px;
  overflow: hidden;
  text-overflow: ellipsis;
  top: 15px;
  white-space: nowrap;

  img,
  svg {
    margin-right: 8px;
  }
`;

const StyledTd = styled.td`
  padding-left: 17px;
`;

const RebalancerMobileTableWrap = styled.div`
  display: none;

  .table {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  tbody {
    width: 100% !important;
  }

  @media (max-width: ${breakPoints.lg}px) {
    display: flex;
    margin-top: 2rem;

    .mobileTable {
      font-size: 14px;
      width: 100%;
    }
  }
`;

const MobileTransactionWidget = styled.tr`
  background: ${(props) => props.theme.bg};
  border: 1px solid ${(props) => props.theme.researchBorder};
  color: ${(props) => props.theme.mainDarkText};
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  margin-bottom: 1.5em;
  padding: 6px 16px;
  height: initial !important;
  width: 100%;

  .tableTitle {
    color: ${(props) => props.theme.form_text};
    margin-right: 5px;
    min-width: 100px;
  }

  .tableLink {
    color: ${(props) => props.theme.mainDarkText};
    text-decoration: underline;

    a,
    a:hover,
    a:focus,
    a:visited {
      color: ${(props) => props.theme.mainDarkText};
    }
  }

  div {
    font-weight: 500;
  }
`;

const RebalancerMobileCell = styled.td`
  align-items: center;
  display: flex;
  height: 40px;
  margin: 0;
  max-width: 100%;

  img,
  svg {
    height: 20px;
    width: 20px;
    margin-right: 8px;
  }

  :not(.last) {
    border-bottom: 1px solid ${(props) => props.theme.researchBorder};
  }
`;

export default function Table(props) {
  const { defaultSorted } = props;
  const [sort, setSort] = useState({
    field: props.tableHeadItems[defaultSorted || 2].id,
    direction: "desc",
  });

  const [rowsData, setRowsData] = useState([...props.rows]);

  useEffect(() => {
    rowsData.sort(compareFunction(sort));
    setRowsData([...rowsData]);
  }, [sort, props.rows]);

  const changeSort = (field) => {
    setSort((sort) => {
      if (sort.field === field) {
        return {
          ...sort,
          direction: sort.direction === "asc" ? "desc" : "asc",
        };
      } else {
        return {
          field,
          direction: "desc",
        };
      }
    });
  };

  const createRows = (rows) => {
    return rows.map((row, i) => {
      return (
        <tr key={i}>
          {Object.keys(row).map((cell) => (
            <StyledTd key={cell}>
              {cell === "asset" ? (
                <RowsIconsWrapper>
                  {tokensLogos[row[cell]]}
                  {row[cell]}
                </RowsIconsWrapper>
              ) : cell === "platform" ? (
                <RowsIconsWrapper>
                  {poolsLogos[row[cell] === "Aave V1" || row[cell] === "Aave V2" ? "Aave" : row[cell]]}
                  {row[cell] === "Aave" ? "AAVE" : row[cell]}
                </RowsIconsWrapper>
              ) : (
                row[cell]
              )}
            </StyledTd>
          ))}
        </tr>
      );
    });
  };

  const RebalancerMobileTable = ({ txHistory, tableHeadItems }) => {
    return (
      <RebalancerMobileTableWrap>
        <table className="mobileTable">
          <tbody>
            {txHistory.map((row, indx) => (
              <MobileTransactionWidget key={indx}>
                <RebalancerMobileCell>
                  <div className="tableTitle">{tableHeadItems[0].label}</div>
                  <div>{row.rank}</div>
                </RebalancerMobileCell>
                <RebalancerMobileCell>
                  <div className="tableTitle">{tableHeadItems[1].label}</div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {tokensLogos[row.asset]} {row.asset}
                  </div>
                </RebalancerMobileCell>
                <RebalancerMobileCell>
                  <div className="tableTitle">{tableHeadItems[2].label}</div>
                  <div style={{ display: "flex", alignItems: "center" }}>{row.rate}</div>
                </RebalancerMobileCell>
                <RebalancerMobileCell>
                  <div className="tableTitle">{tableHeadItems[3].label}</div>
                  <div>${row.assetPrice}</div>
                </RebalancerMobileCell>
                <RebalancerMobileCell>
                  <div className="tableTitle">{tableHeadItems[4].label}</div>
                  <div>{row.borrowRate}</div>
                </RebalancerMobileCell>
                <RebalancerMobileCell>
                  <div className="tableTitle">{tableHeadItems[5].label}</div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {poolsLogos[row.platform]}
                    {row.platform === "Aave" ? "AAVE" : row.platform}
                  </div>
                </RebalancerMobileCell>
                <RebalancerMobileCell className="last">
                  <div className="tableTitle">{tableHeadItems[6].label}</div>
                  <div className="tableLink">
                    <a href={row.action.props.to}>{row.action.props.children}</a>
                  </div>
                </RebalancerMobileCell>
              </MobileTransactionWidget>
            ))}
          </tbody>
        </table>
      </RebalancerMobileTableWrap>
    );
  };

  return (
    <>
      <TableWrapper>
        <StyledTable {...props}>
          <thead>
            <tr>
              {props.tableHeadItems.map(({ label, sortable, id }) => (
                <th key={label}>
                  {sortable ? (
                    <StyledLogoWrapper
                      onClick={() => changeSort(id)}
                      state={sort.field === id ? sort.direction : "asc"}
                    >
                      {label}
                      <SortArrowIcon className="sort-arrow" />
                    </StyledLogoWrapper>
                  ) : (
                    label
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{createRows(rowsData)}</tbody>
        </StyledTable>
      </TableWrapper>
      <RebalancerMobileTable txHistory={rowsData} tableHeadItems={props.tableHeadItems} />
    </>
  );
}
