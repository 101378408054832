import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import Livedata from "../../Components/News/Livedata";
import Newsdashboard from "../../Components/News/Newsdashboard";
import Readinglist from "../../Components/News/Readinglist";
import Tools from "../../Components/News/Tools";
import { newsOperations } from "../../redux/Slices/newsSlice";
import { breakPoints } from "../../Theme";
import { NewsWrapper } from "./News.styles";

function News() {
  const dispatch = useDispatch();

  const [view, setView] = useState("noun");
  const [newsOpen, setNewsOpen] = useState(false);
  const [readingList, setReadingList] = useState(false);

  const mobileScreen = window.innerWidth < breakPoints.md;

  useEffect(() => {
    dispatch(newsOperations.loadAllNews());
    dispatch(newsOperations.getReadingList());
    dispatch(newsOperations.getVote());
    dispatch(newsOperations.getUnvote());
  }, [dispatch]);

  return (
    <NewsWrapper>
      <div className="left-right">
        <div className="tool-dashboard">
          <Tools
            setView={setView}
            mobileScreen={mobileScreen}
            newsOpen={newsOpen}
            readingList={readingList}
            setReadingList={setReadingList}
          />
          <Newsdashboard
            view={view}
            mobileScreen={mobileScreen}
            setNewsOpen={setNewsOpen}
            newsOpen={newsOpen}
            readingList={readingList}
            setReadingList={setReadingList}
          />
        </div>
        <Readinglist />
      </div>
      <Livedata className="live" mobileScreen={mobileScreen} />
    </NewsWrapper>
  );
}

export default News;
