import styled, { css } from "styled-components";

import { breakPoints } from "../../Theme";

export const AlertsWrapper = styled.div`
  max-width: 2560px;
  width: 100%;
  margin: 0 auto;
  .alerts_title {
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    h1 {
      font-size: 24px;
      @media (max-width: ${breakPoints.md}px) {
        display: none;
      }
    }
    button {
      color: ${(props) => props.theme.mainDarkText};
      padding: 0.5rem 2rem;
    }
  }
`;

export const NoAlertsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 380px;
  width: 100%;
  margin: 4rem auto 0;
  text-align: center;
  @media (max-width: ${breakPoints.md}px) {
    margin-top: 2rem;
  }
  .alerts_alert_icon {
    height: 44px;
    width: 40px;
    margin-bottom: 1rem;
    path {
      fill: ${(props) => props.theme.curveYellow};
    }
  }
  .alerts_noalerts_image {
    margin-bottom: 2rem;
    @media (max-width: ${breakPoints.md}px) {
      display: none;
    }
  }
  h2 {
    font-size: 24px;
    margin-bottom: 0.5rem;
  }
  h3 {
    font-size: 16px;
    color: ${(props) => props.theme.lightGrey};
    margin-bottom: 2rem;
  }
  button {
    padding: 0.75rem 2.5rem;
    background: ${(props) => props.theme.brandBlue};
    border: 0;
    border-radius: 6px;
    color: white;
    font-size: 16px;
    :hover {
      background: ${(props) => props.theme.darkBrandBlue};
    }
  }
`;

export const AlertLabels = styled.div`
  display: flex;
  padding: 0 1rem;
  margin-bottom: 6px;
  font-size: 12px;
  color: ${(props) => props.theme.lightGrey};
  @media (max-width: ${breakPoints.md}px) {
    display: none;
  }
`;

export const AlertsListWrapper = styled.div`
  margin-bottom: 2rem;
  ${(props) =>
    props.deleteOpen &&
    css`
      > div {
        opacity: 0.2;
      }
    `}
`;

export const AlertListItem = styled.div`
  display: flex;
  align-items: center;
  margin-top: -1px;
  padding: 1rem;
  background: ${(props) => props.theme.secondaryBg};
  border: 1px solid ${(props) => props.theme.researchBorder};
  width: 100%;
  ${(props) =>
    props.selectedDeleteItem &&
    css`
      opacity: 1 !important;
    `}
  @media (max-width: ${breakPoints.md}px) {
    max-width: calc(100vw - 40px);
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
    > div:not(.alert_list_title) {
      margin-left: 1.7rem;
    }
  }
  :first-of-type {
    border-radius: 6px 6px 0 0;
  }
  :last-of-type {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .alert_list_title {
    display: flex;
    align-items: center;
    flex: 1.5;
    @media (max-width: ${breakPoints.md}px) {
      margin: 0;
    }
    svg {
      margin-right: 0.5rem;
    }
    h4 {
      font-size: 14px;
    }
    h6 {
      font-size: 11px;
      color: ${(props) => props.theme.alertSubtitle};
    }
  }
  .alert_list_date {
    flex: 0.5;
    font-size: 14px;
    margin-right: 1.5rem;
    div:last-of-type {
      font-size: 12px;
      color: ${(props) => props.theme.lightGrey};
    }
  }
  .alert_list_buttons {
    flex: 0.5;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1.25rem;
    svg {
      cursor: pointer;
      max-height: 20px;
      max-width: 20px;
      width: 100%;
      height: 100%;
      :focus {
        outline: 0;
      }
    }
  }
  .alert_expired_text {
    font-size: 14px;
    color: ${(props) => props.theme.lightGrey};
  }
  .alert_triggered_text {
    font-size: 14px;
    color: ${(props) => props.theme.lightBrandBlue};
  }
  .alert_reactivate_icon {
    margin-left: 1.5rem;
    cursor: pointer;
  }
  ${(props) =>
    props.deleteBlock &&
    css`
      opacity: 1 !important;
      display: block;
      transition: 0.3s all ease;
      .alert_list_delete_title {
        font-size: 16px;
        margin-bottom: 2px;
      }
      .alert_list_delete_description {
        font-size: 14px;
        color: ${(props) => props.theme.alertSubtitle};
        max-width: 340px;
        width: 100%;
        font-weight: 300;
        margin-bottom: 1.5rem;
        @media (max-width: ${breakPoints.md}px) {
          max-width: 100%;
        }
      }
      .alert_list_buttons {
        margin-bottom: 1rem;
        justify-content: flex-start;
        margin-left: 0 !important;
        button {
          padding: 0.5rem;
          background: ${(props) => props.theme.error};
          color: white;
          gap: 0.5rem;
          border: 0;
          border-radius: 6px;
          width: 160px;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            path {
              fill: white;
            }
          }
          :disabled {
            background: ${(props) => props.theme.disabledText};
          }
          :hover {
            background: ${(props) => props.theme.darkError};
          }
          :last-of-type {
            background: 0;
            :disabled {
              background: 0;
            }
          }
        }
      }
    `}
`;

export const ToggleButtonWrapper = styled.label`
  position: relative;
  display: inline-block;
  width: 34px;
  min-width: 34px;
  height: 20px;
  min-height: 20px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
    :checked + .slider {
      transition: 0.4s ease;
      background: ${(props) => props.theme.greenShade2};
      :before {
        transform: translateX(14px);
      }
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.disabledText};
    transition: 0.4s ease;
    :before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      left: 2px;
      bottom: 2px;
      background: white;
      transition: 0.4s ease;
    }
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
    :before {
      border-radius: 50%;
    }
  }
`;

export const AlertsGrid = styled.div`
  display: grid;
  gap: 3.5rem;
  row-gap: 1rem;
  @media (min-width: ${breakPoints.xxl}px) {
    grid-template-columns: 1fr 1fr;
  }
  .alert_type_header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
    h2 {
      font-size: 20px;
    }
    .alert_type_circle {
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: green;
      border-radius: 50%;
      div {
        border-radius: 50%;
        height: 10px;
        width: 10px;
        background: white;
      }
    }
    .alert_active_circle {
      background: ${(props) => props.theme.borrowGreen};
    }
    .alert_history_circle {
      background: ${(props) => props.theme.binanceYellow};
    }
    .alert_inactive_circle {
      background: ${(props) => props.theme.lightGrey};
    }
  }
`;

export const SuccessMessage = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 0.75rem 1rem;
  border-radius: 6px;
  top: -6rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 500px;
  background: ${(props) => props.theme.userUpdateSuccess};
  color: black;
  box-shadow: 0px 0px 110px 0px #000000d6;
  z-index: 10;
  animation: moveMessage 0.5s forwards, moveMessageAway 0.5s forwards 3s;
  img {
    margin-right: 10px;
  }
  div {
    flex: 1;
  }
  svg {
    cursor: pointer;
    margin-right: 10px;
    path {
      stroke: black;
    }
  }
  @keyframes moveMessage {
    0% {
      top: -6rem;
    }
    50% {
      top: 3rem;
    }
    100% {
      top: 2rem;
    }
  }
  @keyframes moveMessageAway {
    0% {
      top: 2rem;
    }
    50% {
      top: 3rem;
    }
    100% {
      top: -6rem;
    }
  }
`;
