import React, { useEffect } from "react";

import BigNumber from "bignumber.js";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import SmallDown from "../../../assets/alerts/smallDown.svg";
import SmallUp from "../../../assets/alerts/smallUp.svg";
import { beautifyTokensAmount, poolsLogos, tokensLogos } from "../../../helpers";
import { alertsOperations } from "../../../redux/alertsSlice";
import { createAlertOperations } from "../../../redux/createAlertSlice";
import AlertDropdown from "../AlertDropdown";

// Assets


const Wrapper = styled.div`
  h4 {
    font-size: 12px;
    color: ${(props) => props.theme.lightGrey};
    margin-bottom: 0.5rem;
  }
  .interest_rate_dropdown {
    width: 100%;
    max-width: 200px;
    margin-bottom: 0.75rem;
  }

  .interest_rate_inputs {
    display: flex;
    align-items: flex-end;
    gap: 1.25rem;
    .interest_rate_value {
      display: flex;
      width: 100%;
      max-width: 200px;
      padding: 0.5rem;
      margin-bottom: 0.5rem;
      line-height: 24px;
      font-size: 14px;
      border: 1px solid ${(props) => props.theme.researchBorder};
      border-radius: 6px;

      input {
        width: 100%;
      }
    }
    .interest_rate_dropdown {
      width: 100%;
      max-width: 200px;
      margin-bottom: 0.5rem;
    }
    > div {
      flex-basis: 200px;
    }
  }

  .interest_rate_apy {
    font-size: 10px;
    color: ${(props) => props.theme.lightGrey};
    span {
      color: ${(props) => props.theme.form_text};
    }
  }
`;

const InterestRateAlert = ({ editModal }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const conditionalOptions = [
    {
      id: ">",
      text: t("alert.upTo"),
      img: <img style={{ height: "16px", width: "16px" }} src={SmallUp} alt="Up" />,
    },
    {
      id: "<",
      text: t("alert.downTo"),
      img: <img style={{ height: "16px", width: "16px" }} src={SmallDown} alt="Up" />,
    },
  ];
  const rates = useSelector((state) => state.rates.rates);
  const editAlert = useSelector((state) => state.alerts.editAlert);
  const createAlert = useSelector((state) => state.createAlert);
  const { interestRate } = createAlert;

  const data = {
    platform: editModal ? editAlert.platform : interestRate.platform,
    asset: editModal ? editAlert.asset : interestRate.asset,
    value: editModal ? editAlert.value : interestRate.value,
    conditional: editModal ? editAlert.conditional : interestRate.conditional,
  };

  // Platform options
  const platforms = rates
    .reduce((prev, curr) => {
      if (!prev.some((item) => item.id === curr.platform)) {
        return [...prev, { id: curr.platform, text: curr.platform, img: poolsLogos[curr.platform] }];
      }
      return prev;
    }, [])
    .sort((a, b) => a.text.localeCompare(b.text));

  // Platform assets options
  const platformAssets = data.platform
    ? rates.reduce((prev, curr) => {
        if (curr.platform === data.platform) {
          return [...prev, { id: curr.asset, text: curr.asset, img: tokensLogos[curr.asset] }];
        }
        return prev;
      }, [])
    : [];

  const selectedAssetData =
    data.platform && data.asset
      ? rates.find((item) => item.asset === data.asset && item.platform === data.platform)
      : {};

  useEffect(() => {
    if (selectedAssetData.rate && data.value) {
      if (!editModal) {
        dispatch(
          createAlertOperations.setInterest("conditional", BigNumber(data.value).gt(selectedAssetData.rate) ? ">" : "<")
        );
      } else {
        dispatch(
          alertsOperations.handleEditAlert("conditional", BigNumber(data.value).gt(selectedAssetData.rate) ? ">" : "<")
        );
      }
    }
  }, [selectedAssetData.rate, data.value, dispatch, editModal]);

  const handleInputChange = (name, value, platformChange) => {
    if (editModal) {
      if (platformChange) {
        dispatch(alertsOperations.handleEditAlert("asset", ""));
      }
      return dispatch(alertsOperations.handleEditAlert(name, value));
    }
    if (platformChange) {
      dispatch(createAlertOperations.setInterest("asset", ""));
    }
    return dispatch(createAlertOperations.setInterest(name, value));
  };

  return (
    <Wrapper>
      <h4>{t("alert.chooseAplatform")}</h4>
      <AlertDropdown
        name="platform"
        selectedOption={data.platform}
        options={platforms}
        placeholder={t("alert.chooseAplatform")}
        className="interest_rate_dropdown"
        setOption={(name, value) => handleInputChange(name, value, true)}
      />
      <h4>{t("alert.chooseAnAsset")}</h4>
      <AlertDropdown
        name="asset"
        selectedOption={data.asset}
        placeholder={t("alert.chooseAnAsset")}
        options={platformAssets}
        className="interest_rate_dropdown"
        setOption={handleInputChange}
      />
      <div className="interest_rate_inputs">
        <div>
          <h4>{t("alert.apyRate")}</h4>
          <div className="interest_rate_value">
            <input name="value" value={data.value} onChange={(e) => handleInputChange(e.target.name, e.target.value)} />
            %
          </div>
        </div>
        <div>
          <AlertDropdown
            name="conditional"
            selectedOption={data.conditional}
            placeholder="Choose an asset"
            options={conditionalOptions}
            className="interest_rate_dropdown"
            setOption={handleInputChange}
          />{" "}
        </div>
      </div>
      {data.asset && data.platform && selectedAssetData.asset && (
        <div className="interest_rate_apy">
          {t("alert.apyRateFor")} <span>{data.asset}</span> {t("alert.on")} <span>{data.platform}</span> {t("alert.in")}{" "}
          <span>{beautifyTokensAmount(selectedAssetData.rate, { suffix: "%" })}</span>
        </div>
      )}
    </Wrapper>
  );
};

export default InterestRateAlert;
