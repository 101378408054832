import React from "react";

import { BEP20Tokens, ERC20Tokens, PolygonTokens } from "@keyfi/keyfi-common";
import BigNumber from "bignumber.js";

// wallets icons
import KeyfiLogo from "../assets/Blue-filled-logo.svg";
// tokens
import InchLogo from "../assets/platforms/1inch.svg";
import AaveLogo from "../assets/platforms/aave.svg";
import Apeswap from "../assets/platforms/Apeswap.svg";
import AVAX from "../assets/platforms/avalanche.svg";
import BalancerLogo from "../assets/platforms/balancer.svg";
import CompoundLogo from "../assets/platforms/compound.svg";
import CreamLogo from "../assets/platforms/cream.svg";
import CurveLogo from "../assets/platforms/curve.svg";
import DeFinerIcon from "../assets/platforms/DeFiner.png";
import DydxLogo from "../assets/platforms/dydx.svg";
import FTM from "../assets/platforms/fantom.svg";
import FulcrumLogo from "../assets/platforms/fulcrum.svg";
import MakerLogo from "../assets/platforms/maker.svg";
import Metis from "../assets/platforms/Metis.svg";
import QuickSwap from "../assets/platforms/quickswap.png";
import Trisolaris from "../assets/platforms/Trisolaris.png";
import UniswapLogo from "../assets/platforms/uniswap.svg";
import ActiveCalendar from "../assets/popicon/activeCalendar.svg";
import ActiveConnections from "../assets/popicon/activeConnections.svg";
import BNBlogo from "../assets/popicon/BNB.svg";
import CalendarIcon from "../assets/popicon/Calendar.svg";
import CheckedIcon from "../assets/popicon/checked.svg";
import CloseIcon from "../assets/popicon/Closeicon.svg";
import CompleteIcon from "../assets/popicon/Complete.svg";
import ConnectionIcon from "../assets/popicon/Connections.svg";
import DeleteIcon from "../assets/popicon/Delete.svg";
import DeleteIcon2 from "../assets/popicon/Delete2.svg";
import DownIcon from "../assets/popicon/Down.svg";
import EditIcon from "../assets/popicon/Edit.svg";
import EthIcon from "../assets/popicon/ethlogo.svg";
import NewIcon from "../assets/popicon/New.svg";
import NotificationIcon from "../assets/popicon/Notification.svg";
import PencakeswapLogo from "../assets/popicon/pencakeswap-logo.svg";
import PolygonMatic from "../assets/popicon/polygon-matic-logo1.svg";
import SettingIcon from "../assets/popicon/Settingsicon.svg";
import UpIcon from "../assets/popicon/UP.svg";
import Decrement from "../assets/popicon/Vector 5.svg";
import Increment from "../assets/popicon/Vector 6.svg";
import Alert from "../assets/sidebar/Alert.svg";
import INCHE from "../assets/tokens/1INCHE.png";
import AMPL from "../assets/tokens/AMPL.png";
import AXS from "../assets/tokens/AXS.png";
import BalLogo from "../assets/tokens/BAL.svg";
import BAND from "../assets/tokens/BAND.png";
import BatLogo from "../assets/tokens/BAT.svg";
import BUSDLogo from "../assets/tokens/BUSD.svg";
import CdaiLogo from "../assets/tokens/cDAI.png";
import CEL from "../assets/tokens/CEL.png";
import CHAI from "../assets/tokens/CHAI.png";
import CREAM from "../assets/tokens/CREAM.png";
import CRO from "../assets/tokens/CRO.svg";
import CusdcLogo from "../assets/tokens/cUSDC.png";
import DaiLogo from "../assets/tokens/DAI.svg";
import DIA from "../assets/tokens/DIA.png";
import DPI from "../assets/tokens/DPI.png";
import DYT from "../assets/tokens/DYT.png";
import EnjLogo from "../assets/tokens/ENJ.svg";
import EthLogo from "../assets/tokens/ETH.svg";
import FtxLogo from "../assets/tokens/FTX.svg";
import FYZ from "../assets/tokens/FYZ.png";
import HBTC from "../assets/tokens/HBTC.png";
import HEGIC from "../assets/tokens/HEGIC.png";
import HEX from "../assets/tokens/HEX.png";
import KP3R from "../assets/tokens/KP3R.png";
import LendLogo from "../assets/tokens/LEND.svg";
import LinkLogo from "../assets/tokens/LINK.svg";
import ManaLogo from "../assets/tokens/MANA.svg";
import MkrLogo from "../assets/tokens/MKR.svg";
import MtaLogo from "../assets/tokens/MTA.svg";
import OCEAN from "../assets/tokens/OCEAN.png";
import OMG from "../assets/tokens/OMG.png";
import PAX from "../assets/tokens/PAX.png";
import RenLogo from "../assets/tokens/REN.svg";
import RenBTCLogo from "../assets/tokens/renBTC.svg";
import RepLogo from "../assets/tokens/REP.svg";
import RSR from "../assets/tokens/RSR.png";
import SaiLogo from "../assets/tokens/SAI.svg";
import sBTC from "../assets/tokens/SBTC.png";
import SLP from "../assets/tokens/SLP.png";
import SnxLogo from "../assets/tokens/SNX.png";
import SrmLogo from "../assets/tokens/SRM.svg";
import STAKE from "../assets/tokens/STAKE.jpg";
import SUSD from "../assets/tokens/SUSD.png";
import SUSHI from "../assets/tokens/SUSHI.png";
import SXP from "../assets/tokens/SXP.png";
import TusdLogo from "../assets/tokens/TUSD.svg";
import UBT from "../assets/tokens/UBT.png";
import UniLogo from "../assets/tokens/UNI.svg";
import UsdcLogo from "../assets/tokens/USDC.svg";
import UsdtLogo from "../assets/tokens/USDT.svg";
import WbtcLogo from "../assets/tokens/WBTC.svg";
import WETH from "../assets/tokens/WETH.png";
import XOR from "../assets/tokens/XOR.png";
import YfiLogo from "../assets/tokens/YFI.svg";
import ZrxLogo from "../assets/tokens/ZRX.svg";
// platforms
//popUp Icons
import CoinbaseLogo from "../assets/wallets/coinbase.svg";
import MetaMaskLogo from "../assets/wallets/metaMask.svg";
import SelfKeyLogo from "../assets/wallets/selfkey.svg";
import WalletConnectLogo from "../assets/wallets/walletConnect.svg";

function isIsoDate(str) {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
  var d = new Date(str);
  return d.toISOString() === str;
}

export const compareFunction = ({ field, direction }) => {
  return (a, b) => {
    if (isIsoDate(a[field]) && isIsoDate(b[field])) {
      if (direction === "desc") {
        if (new Date(a[field]) > new Date(b[field])) return -1;
        if (new Date(a[field]) === new Date(b[field])) return 0;
        if (new Date(a[field]) < new Date(b[field])) return 1;
      }
      if (direction === "asc") {
        if (new Date(a[field]) > new Date(b[field])) return 1;
        if (new Date(a[field]) === new Date(b[field])) return 0;
        if (new Date(a[field]) < new Date(b[field])) return -1;
      }
    }
    let parsedA = isNaN(parseFloat(a[field])) ? a[field] : parseFloat(a[field]);
    let parsedB = isNaN(parseFloat(b[field])) ? b[field] : parseFloat(b[field]);
    if (direction === "desc") {
      if (parsedA > parsedB) return -1;
      if (parsedA === parsedB) return 0;
      if (parsedA < parsedB) return 1;
    }
    if (direction === "asc") {
      if (parsedA > parsedB) return 1;
      if (parsedA === parsedB) return 0;
      if (parsedA < parsedB) return -1;
    }
  };
};

export const trimAddress = (str, number) => {
  if (str) {
    const firstPart = str.slice(0, number || 6);
    const secondPart = str.slice(-4);

    return `${firstPart}...${secondPart}`;
  }
};

const numberFormat = {
  decimalSeparator: ".",
  groupSeparator: ",",
  groupSize: 3,
  secondaryGroupSize: 3,
};

export const beautifyTokensAmount = (amount, format = {}) => {
  const n = new BigNumber(amount);
  if (n.isNaN()) {
    return BigNumber("0.00").toFormat(2, { ...numberFormat, ...format });
  }
  if (n.eq(0) || format.suffix === "%") {
    return n.precision(4).toFormat(2, { ...numberFormat, ...format });
  }
  if (amount < 1 && amount > 0 && format.suffix !== "%") {
    return n.precision(4).toFormat({ ...numberFormat, ...format });
  }
  if (format.noDecimals) {
    return n.toFormat(0, { ...numberFormat, ...format });
  }

  return n.precision(6).toFormat(2, { ...numberFormat, ...format });
};

export const beautifyTokensAmountSmall = (amount, format = {}) => {
  const n = new BigNumber(amount);
  if (n.gte(0.01) || n.isNaN()) {
    return beautifyTokensAmount(amount);
  }
  return n.toFormat({ ...numberFormat, ...format });
};

export const walletsLogos = {
  Metamask: <img src={MetaMaskLogo} alt="MetaMaskLogo" />,
  Coinbase: <img src={CoinbaseLogo} alt="CoinbaseLogo" />,
  WalletConnect: <img src={WalletConnectLogo} alt="WalletConnectLogo" />,
  SelfKey: <img src={SelfKeyLogo} alt="SelfKeyLogo" />,
};

// map for pools logos
export const poolsLogos = {
  Compound: <img src={CompoundLogo} alt="compound" />,
  Aave: <img src={AaveLogo} alt="AaveLogo" />,
  "Aave V1": <img src={AaveLogo} alt="AaveLogo" />,
  "Aave V2": <img src={AaveLogo} alt="AaveLogo" />,
  Uniswap: <img src={UniLogo} alt="Uniswap" />,
  "Uniswap V2": <img src={UniLogo} alt="Uniswap" />,
  dYdX: <img src={DydxLogo} alt="DYdX" />,
  dydx: <img src={DydxLogo} alt="DYdX" />,
  Fulcrum: <img src={FulcrumLogo} alt="Fulcrum" />,
  Maker: <img src={MakerLogo} alt="MakerLogo" />,
  "C.R.E.A.M. Finance": <img src={CreamLogo} alt="CreamLogo" />,
  "1Inch": <img src={InchLogo} alt="InchLogo" />,
  "1inch": <img src={InchLogo} alt="InchLogo" />,
  Curve: <img src={CurveLogo} alt="CurveLogo" />,
  Balancer: <img src={BalancerLogo} alt="BalancerLogo" />,
  KeyFi: <img src={KeyfiLogo} alt="keyfi" />,
  Pancakeswap: <img src={PencakeswapLogo} alt="CAKE" />,
  PancakeSwap: <img src={PencakeswapLogo} alt="CAKE" />,
  ApeSwap: <img src={Apeswap} alt="ApeSwap" />,
  DeFiner: <img src={DeFinerIcon} alt="DeFiner Icon" />,
  Synthetix: <img src={SnxLogo} alt="Synthetix Icon" />,
  QuickSwap: <img src={QuickSwap} alt="QuickSwap Icon" />,
  Trisolaris: <img src={Trisolaris} alt="Trisolaris Icon" />,
};

// map for tokens logos
export const tokensLogos = {
  BNB: <img src={BNBlogo} alt="BNBLogo" />,
  AVAX: <img src={AVAX} alt="AVAX" />,
  FTM: <img src={FTM} alt="FTM" />,
  CRO: <img src={CRO} alt="CRO" />,
  wBNB: <img src={BNBlogo} alt="wBNBLogo" />,
  ETH: <img src={EthLogo} alt="EthLogo" />,
  DAI: <img src={DaiLogo} alt="DaiLogo" />,
  USDT: <img src={UsdtLogo} alt="UsdtLogo" />,
  LINK: <img src={LinkLogo} alt="LinkLogo" />,
  BAL: <img src={BalLogo} alt="BalLogo" />,
  BAT: <img src={BatLogo} alt="BatLogo" />,
  BUSD: <img src={BUSDLogo} alt="BUSDLogo" />,
  FTX: <img src={FtxLogo} alt="FtxLogo" />,
  LEND: <img src={LendLogo} alt="LendLogo" />,
  MTA: <img src={MtaLogo} alt="MtaLogo" />,
  RenBTC: <img src={RenBTCLogo} alt="RenBTCLogo" />,
  renBTC: <img src={RenBTCLogo} alt="RenBTCLogo" />,
  REP: <img src={RepLogo} alt="RepLogo" />,
  SRM: <img src={SrmLogo} alt="SrmLogo" />,
  UNI: <img src={UniLogo} alt="UniLogo" />,
  USDC: <img src={UsdcLogo} alt="UsdcLogo" />,
  WBTC: <img src={WbtcLogo} alt="WbtcLogo" />,
  YFI: <img src={YfiLogo} alt="YfiLogo" />,
  ZRX: <img src={ZrxLogo} alt="ZrxLogo" />,
  TUSD: <img src={TusdLogo} alt="TusdLogo" />,
  SAI: <img src={SaiLogo} alt="SaiLogo" />,
  ENJ: <img src={EnjLogo} alt="EnjLogo" />,
  MKR: <img src={MkrLogo} alt="MkrLogo" />,
  REN: <img src={RenLogo} alt="RenLogo" />,
  SNX: <img src={SnxLogo} alt="SnxLogo" />,
  MANA: <img src={ManaLogo} alt="ManaLogo" />,
  cUSDC: <img src={CusdcLogo} alt="CusdcLogo" />,
  cDAI: <img src={CdaiLogo} alt="CdaiLogo" />,
  KEY: <img src={SelfKeyLogo} alt="selfkey logo" />,
  KEYFIUSDCLP: <img src={KeyfiLogo} alt="keyfi" />,
  KEYFI: <img src={KeyfiLogo} alt="keyfi" />,
  Uniswap: <img src={UniswapLogo} alt="UniswapLogo" />,
  AAVE: <img src={AaveLogo} alt="AaveLogo" />,
  COMP: <img src={CompoundLogo} alt="compound" />,
  CRV: <img src={CurveLogo} alt="CurveLogo" />,
  "1INCH": <img src={INCHE} alt="InchLogo Token" />,
  AMPL: <img src={AMPL} alt="AMPL Token" />,
  AXS: <img src={AXS} alt="AXS Token" />,
  BAND: <img src={BAND} alt="BAND Token" />,
  CEL: <img src={CEL} alt="CEL Token" />,
  DIA: <img src={DIA} alt="DIA Token" />,
  DYT: <img src={DYT} alt="DYT Token" />,
  FYZ: <img src={FYZ} alt="FYZ Token" />,
  HEGIC: <img src={HEGIC} alt="HEGIC Token" />,
  HEX: <img src={HEX} alt="HEX Token" />,
  KP3R: <img src={KP3R} alt="KP3R Token" />,
  OCEAN: <img src={OCEAN} alt="OCEAN Token" />,
  OMG: <img src={OMG} alt="OMG Token" />,
  RLC: <img src={OCEAN} alt="RLC Token" />,
  RSR: <img src={RSR} alt="RSR Token" />,
  SLP: <img src={SLP} alt="SLP Token" />,
  STAKE: <img src={STAKE} alt="STAKE Token" />,
  XOR: <img src={XOR} alt="XOR Token" />,
  SUSHI: <img src={SUSHI} alt="SUSHI Token" />,
  UBT: <img src={UBT} alt="UBT Token" />,
  SXP: <img src={SXP} alt="SXP Token" />,
  CREAM: <img src={CREAM} alt="CREAM Token" />,
  WETH: <img src={WETH} alt="WETH Token" />,
  sUSD: <img src={SUSD} alt="SUSD Token" />,
  DPI: <img src={DPI} alt="DPI Token" />,
  CHAI: <img src={CHAI} alt="CHAI Token" />,
  PAX: <img src={PAX} alt="PAX Token" />,
  HBTC: <img src={HBTC} alt="HBTC Token" />,
  sBTC: <img src={sBTC} alt="sBTC Token" />,
  BTC: <img src={WbtcLogo} alt="Bitcoin" />,
  MATIC: <img src={PolygonMatic} alt="PolygonMatic" />,
  METIS: <img src={Metis} alt="Metis" />,
};

// map for popup icons
export const PopUpIcons = {
  SettingIcon: <img src={SettingIcon} alt="SettingIcon" />,
  CloseIcon: <img src={CloseIcon} alt="CloseIcon" />,
  ConnectionIcon: <img src={ConnectionIcon} alt="ConnectionIcon" />,
  CalendarIcon: <img src={CalendarIcon} alt="CalendarIcon" />,
  EthIcon: <img src={EthIcon} alt="EthIcon" />,
  CompoundLogo: <img src={CompoundLogo} alt="CompoundLogo" />,
  BNB: <img src={BNBlogo} alt="BNBlogo" />,
  PolygonMatic: <img src={PolygonMatic} alt="PolygonMatic" />,
  PencakeswapLogo: <img src={PencakeswapLogo} alt="PencakeswapLogo" />,
  Increment: <img src={Increment} alt="Increment" />,
  Decrement: <img src={Decrement} alt="Decrement" />,
  ActiveCalendar: <img src={ActiveCalendar} alt="ActiveCalendar" />,
  ActiveConnections: <img src={ActiveConnections} alt="ActiveConnections" />,
  CheckedIcon: <img src={CheckedIcon} alt="CheckedIcon" />,
  CompleteIcon: <img src={CompleteIcon} alt="CompleteIcon" />,
  Alert: <img src={Alert} alt="AlertIcon" />,
  DeleteIcon: <img src={DeleteIcon} alt="DeleteIcon" />,
  EditIcon: <img src={EditIcon} alt="EditIcon" />,
  UpIcon: <img src={UpIcon} alt="UpIcon" />,
  DownIcon: <img src={DownIcon} alt="DownIcon" />,
  NewIcon: <img src={NewIcon} alt="NewIcon" />,
  DeleteIcon2: <img src={DeleteIcon2} alt="DeleteIcon2" />,
  NotificationIcon: <img src={NotificationIcon} alt="NotificationIcon" />,
};

export const calculateTotal = (tokens, tokensPrices, stakedObject) => {
  let total = 0;

  for (let token in tokensPrices) {
    const tokenBalance = stakedObject ? (tokens[token] ? tokens[token].amount : 0) : tokens[token];
    if (tokenBalance) {
      const tokenPrice = tokensPrices[token];
      total += Number(tokenBalance) * Number(tokenPrice);
    }
  }

  return total;
};

/**
 * Convert paired balance structure like in Uniswap:
 * [{ USDC: 100, DAI: 101, assetA: "USDC", ... } ...]
 * To Compound and others-like flat structure with tokens summary:
 * { USDC: 100, DAI: 100, ... }
 */
export const getTokensTotalFromPairs = (pairs) => {
  const total = {};

  for (let pair of pairs) {
    for (const tokenSymbol of [pair.assetA, pair.assetB]) {
      if (!total[tokenSymbol]) {
        total[tokenSymbol] = new BigNumber(0);
      }

      total[tokenSymbol] = total[tokenSymbol].plus(pair[tokenSymbol]);
    }
  }

  return Object.entries(total).reduce((newTotal, [symbol, amount]) => {
    newTotal[symbol] = amount.toNumber();
    return newTotal;
  }, {});
};

/**
 * Convert network from liquidity state
 * { uniswap: { v2: [] }, curve: { v1: [] } }
 * Into one single array of network liquidities
 * [{ assetA: "USDC", assetB: "KEYFI"}, {...} ...]
 */
export const getLiquditiesFromNetwork = (networkObject) => {
  return Object.values(networkObject).reduce((acc, platform) => {
    return [
      ...acc,
      ...Object.values(platform).reduce((acc2, version) => {
        return [...acc2, ...version];
      }, []),
    ];
  }, []);
};

/**
 * Convert platform staking state
 * { v1: {}, v2: {}... }
 * Into one single array of network liquidities
 * { BUSD: 12, USDC: 123 ...}
 */
export const getTokensFromStakingPlatform = (platformObject) => {
  const versionArray = Object.values(platformObject);

  return versionArray.reduce((acc, tokenList) => {
    const tokens = Object.keys(tokenList).reduce((acc2, key) => {
      const token = tokenList[key];
      if (token.type === "lp") {
        const [assetA, assetB] = [key.split(":")[0], key.split(":")[1].split(" ")[0]];

        return merge(acc2, {
          [assetA]: token[assetA],
          [assetB]: token[assetB],
        });
      }
      return merge(acc2, { [key]: token.amount });
    }, {});
    return merge(acc, tokens);
  }, {});
};

/**
 * Convert network staking state
 * { aave: { v1: {} }, compound: { v1: {} }... }
 * Into one single array of network liquidities
 * { BUSD: 12, USDC: 123 ...}
 */
export const getTokensFromStakingNetwork = (networkObject) => {
  return Object.values(networkObject).reduce((acc, platform) => {
    const data = getTokensFromStakingPlatform(platform);

    return merge(acc, data);
  }, {});
};

export const chunkArray = (arr, size) =>
  arr.reduce(
    (acc, e, i) => (
      // eslint-disable-next-line no-sequences
      i % size ? acc[acc.length - 1].push(e) : acc.push([e]), acc
    ),
    []
  );

export const getAPYRatesOnPlatform = (rates, platformName) => {
  return rates
    .filter((rate) => rate.platform.toLowerCase().includes(platformName.toLowerCase()))
    .reduce((acc, rate) => {
      if (rate.asset === "ETH") acc["WETH"] = rate.rate;
      acc[rate.asset] = rate.rate;
      return acc;
    }, {});
};

export const calculateAPY = (tokens, tokenPrices, platformRates) => {
  return Object.entries(tokens)
    .filter((val) => val[1].amount > 0)
    .reduce(
      ([sum, rate], [symbol, value]) => {
        const tokenPrice = tokenPrices[symbol];
        const tokenRate = platformRates[symbol] || 0;

        if (typeof tokenPrice === "number") {
          const currencyEquivalent = Number(value.amount) * Number(tokenPrice);
          const newSum = sum + currencyEquivalent;
          return [newSum, (sum * rate + currencyEquivalent * tokenRate) / newSum];
        }

        return [sum, rate];
      },
      [0, 0]
    )[1];
};

export const sumBigNumbers = (amounts) => amounts.reduce((s, curr) => s.plus(curr), new BigNumber(0));

export const filterZeroBalance = (userTokens, tokens) => {
  const filteredBalance = tokens.filter((token) => Number(userTokens[token]) > 0);

  return filteredBalance;
};

export const calculateLPValue = (lpName, lpObject, prices) => {
  const [assetA, assetB] = [lpName.split(":")[0], lpName.split(":")[1].split(" ")[0]];

  const assetAValue = prices[assetA] * lpObject[assetA];
  const assetBValue = prices[assetB] * lpObject[assetB];

  return assetAValue + assetBValue;
};

export const SwitchToBsc = () => {
  const ethereum = window.ethereum;
  ethereum
    .request({
      id: 1,
      jsonrpc: "2.0",
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: "0x38",
          chainName: "Binance Smart Chain Mainnet",
          rpcUrls: ["https://bsc-dataseed1.ninicoin.io"],
          iconUrls: ["https://s2.coinmarketcap.com/static/img/coins/200x200/1839.png"],
          nativeCurrency: {
            name: "bnb",
            symbol: "bnb",
            decimals: 18,
          },
        },
      ],
    })
    .then(() => {})
    .catch((error) => {
      if (error.code === 4001) {
        // EIP-1193 userRejectedRequest error
        console.log(error);
      } else {
        console.error(error);
      }
    });
};

export const switchToETH = async () => {
  const { ethereum } = window;
  try {
    await ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x1" }],
    });
  } catch (err) {
    console.log(err);
  }
};

export const validateEmail = (value) => {
  if (
    value.match(
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
    ) &&
    value.length !== 0
  ) {
    return true;
  } else {
    return false;
  }
};

export const merge = (...objects) => {
  const merged = objects.reduce((a, obj) => {
    Object.entries(obj).forEach(([key, val]) => {
      a[key] = ((parseFloat(a[key]) || 0) + parseFloat(val)).toString();
    });
    return a;
  }, {});
  return Object.fromEntries(Object.entries(merged).sort((a, b) => b[1] - a[1]));
};

export const mergeAmount = (version, ...objects) => {
  const merged = objects.reduce((a, obj) => {
    Object.entries(obj).forEach(([key, val]) => {
      a[key] = {
        ...val,
        version,
        amount: (parseFloat(a[key]?.amount || 0) + parseFloat(val.amount)).toString(),
        type: val.type,
      };
    });
    return a;
  }, {});

  return Object.fromEntries(Object.entries(merged).sort((a, b) => b.amount - a.amount));
};

export const assetImageNetwork = {
  mainnet: "ethereum",
  "bsc-mainnet": "smartchain",
  polygon: "polygon",
  fantom: "fantom",
  avalanche: "avalanchec",
};

export const assetImageChainID = {
  1: "ethereum",
  56: "smartchain",
  137: "polygon",
  250: "fantom",
  43114: "avalanchec",
};

export const wrapperNetworkToken = {
  1: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2", // WETH,
  56: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c", // WBNB
  137: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270", // WMATIC
};

export const networkTokenList = {
  1: ERC20Tokens,
  56: BEP20Tokens,
  137: PolygonTokens,
};

export const networkMainToken = {
  mainnet: "ETH",
  "bsc-mainnet": "BNB",
  polygon: "MATIC",
  avalanche: "AVAX",
  arbitrum: "ETH",
  fantom: "FTM",
  optimism: "ETH",
  cronos: "CRO",
  metis: "METIS",
  moonriver: "MOVR",
  telos: "TLOS",
  kcc: "KCS",
  okex: "OKT",
  aurora: "ETH",
};
