import React, { useState } from "react";

import update from "immutability-helper";
import { useDrag, useDrop } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as Grid11 } from "../../../assets/dashboard/11Grid.svg";
import { ReactComponent as Grid12 } from "../../../assets/dashboard/12Grid.svg";
import { ReactComponent as Grid21 } from "../../../assets/dashboard/21Grid.svg";
import { ReactComponent as Grid22 } from "../../../assets/dashboard/22Grid.svg";
import { ReactComponent as ExpandIcon } from "../../../assets/dashboard/ExpandIcon.svg";
import { userDataOperations } from "../../../redux/userDataSlice";
import { breakPoints } from "../../../Theme";
import { Widget } from "../dashboard.style";

// 3. Gas price - 1:1

const gridIcon = {
  11: Grid11,
  12: Grid12,
  21: Grid21,
  22: Grid22,
};

const widgetOptions = {
  chart: ["21", "22", "11"],
  interest: ["12", "11"],
  research: ["12", "11"],
  watchlist: ["12", "11"],
  alerts: ["12", "11"],
  news: ["12", "22", "11"],
  sentiment: ["11"],
};

export const dropWidget = (dispatch, widgets, item, position) => {
  const droppedWidgetPosition = widgets.findIndex((widget) => widget._id === item._id);
  const data = update(widgets, {
    $splice: [
      [droppedWidgetPosition, 1],
      [position, 0, widgets[droppedWidgetPosition]],
    ],
  });

  dispatch(userDataOperations.updateDashboardWidgets(data));
};

const WidgetWrapper = ({ id, header, body, item }) => {
  const [showOptions, setShowOptions] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData);
  const { user } = userData;
  const dashboardWidgets = user.dashboardWidgets || [];

  const position = dashboardWidgets.findIndex((wid) => wid._id === item._id);

  const [{ opactiy }, drag] = useDrag(() => ({
    type: "card",
    item,
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  }));
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: "card",
      drop: (item) => dropWidget(dispatch, dashboardWidgets, item, position),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [position]
  );
  const sizeOptions = { row: item?.options?.size?.row || "1", column: item?.options?.size?.column || "1" };

  const widgetStyling = {
    gridColumn: `span ${window.innerWidth > breakPoints.md ? sizeOptions.column : "1"}`,
    gridRow: `span ${window.innerWidth > breakPoints.md ? sizeOptions.row : "1"}`,
  };

  const handleChangeSize = (size) => {
    const columnSize = size[0];
    const rowSize = size[1];

    const newWidget = { ...item, options: { ...item.options, size: { row: rowSize, column: columnSize } } };

    dispatch(userDataOperations.updateWidgetSize(newWidget));
  };

  return (
    <div style={widgetStyling} ref={drop}>
      <Widget ref={drag} style={{ ...opactiy, opacity: isOver ? "0.2" : "1" }}>
        <div className="widget_header">
          {header}
          <div className={"widget_grid_options " + (showOptions ? "widget_grid_options_show" : "")}>
            {showOptions &&
              widgetOptions[id] &&
              widgetOptions[id].map((item) => {
                const Component = gridIcon[item];
                return (
                  <button
                    key={item}
                    className={
                      `widget_grid_option ` +
                      (sizeOptions.column + sizeOptions.row === item ? "widget_grid_selected" : "")
                    }
                    onClick={() => handleChangeSize(item)}
                  >
                    <Component />
                  </button>
                );
              })}
            <button className="widget_grid_option" onClick={() => setShowOptions((prev) => !prev)}>
              <ExpandIcon />
            </button>
          </div>
        </div>
        {body}
      </Widget>
    </div>
  );
};

export default WidgetWrapper;
