import styled from "styled-components";

export const CreateGroupModalWrapper = styled.div`
  padding: 1.5rem 0;
  display: grid;
  grid-template-columns: 1fr 32px 1fr;
  column-gap: 1rem;
  h3 {
    font-size: 16px;
    margin-bottom: 1rem;
  }
  .create_group_arrow_field {
    align-self: center;
  }
`;

export const CreateGroupActions = styled.div`
  .create_group_actions_searchbar {
    padding-bottom: 1.5rem;
    border-bottom: 1px solid ${(props) => props.theme.researchBorder};
    label {
      display: block;
      font-size: 12px;
      color: ${(props) => props.theme.lightGrey};
      margin-bottom: 0.5rem;
    }
    .create_group_actions_search {
      width: 100%;
      background: ${(props) => props.theme.bg};
      display: flex;
      padding: 0.75rem 1rem;
      border-radius: 6px;
      border: 1px solid ${(props) => props.theme.researchBorder};

      input {
        color: ${(props) => props.theme.mainDarkText};
        flex: 1;
        gap: 1rem;
        font-size: 16px;
      }
      :focus-within {
        box-shadow: 0px 0px 10px 0px #4984d340;
        border-color: ${(props) => props.theme.border_color};
      }
    }
  }
  .create_group_actions {
    max-height: 450px;
    overflow: auto;
    padding: 1.5rem 0;
    .create_group_action {
      display: flex;
      align-items: center;
      padding: 1rem;
      background: ${(props) => props.theme.bg};
      border: 1px solid ${(props) => props.theme.researchBorder};
      border-radius: 6px;
      :not(:last-of-type) {
        margin-bottom: 1rem;
      }
      > img,
      > svg {
        height: 32px;
        width: 32px;
        margin-right: 10px;
      }
      .create_group_action_name {
        flex: 1;
        h4 {
          font-size: 16px;
        }
        h5 {
          font-size: 12px;
        }
      }
      button {
        display: flex;
        align-items: center;
        padding: 0.25rem 1rem;
        background: ${(props) => props.theme.secondaryBg};
        border: 1px solid ${(props) => props.theme.researchBorder};
        border-radius: 6px;
        color: ${(props) => props.theme.mainDarkText};
        svg {
          margin-left: 0.5rem;
          height: 18px;
          width: 12px;
        }
        :hover {
          background: ${(props) => props.theme.secondaryBtnHover};
        }
      }
    }
  }
  .create_group_action_custom {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    width: 100%;
    padding: 1rem 0;
    border: 1px dashed ${(props) => props.theme.researchBorder};
    border-radius: 6px;
    background: ${(props) => props.theme.widgetCardBg};
    font-size: 16px;
    color: ${(props) => props.theme.mainDarkText};
  }
`;

export const NewActionGroup = styled.div`
  background: ${(props) => props.theme.bg};
  padding: 1.5rem;
  border: 1px solid ${(props) => props.theme.researchBorder};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  label {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 12px;
    color: ${(props) => props.theme.lightGrey};
  }
  input {
    font-size: 16px;
    width: 100%;
    margin-bottom: 1rem;
    padding: 0.75rem 1rem;
    border: 1px solid ${(props) => props.theme.researchBorder};
    border-radius: 6px;
    color: ${(props) => props.theme.mainDarkText};
    ::placeholder {
      color: ${(props) => props.theme.disabled};
    }
    :focus {
      box-shadow: 0px 0px 10px 0px #4984d340;
      border-color: ${(props) => props.theme.border_color};
    }
  }
  .new_actions_section {
    flex: 1 1 auto;
    overflow-y: auto;
    .new_action_item {
      display: flex;
      align-items: center;
      padding: 1rem;
      margin-bottom: 1rem;
      background: ${(props) => props.theme.secondaryBg};
      border: 1px solid ${(props) => props.theme.researchBorder};
      border-radius: 6px;
      > img,
      > svg {
        height: 32px;
        width: 32px;
        margin-right: 10px;
      }
      .new_action_item_name {
        flex: 1;
        h4 {
          font-size: 16px;
        }
        h5 {
          font-size: 12px;
        }
      }
      .new_action_item_buttons {
        display: flex;
        gap: 0.75rem;
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0.5rem 9px;
          background: ${(props) => props.theme.bg};
          border: 1px solid ${(props) => props.theme.researchBorder};
          border-radius: 6px;
          svg {
            height: 16px;
            width: 16px;
          }
          :hover {
            background: ${(props) => props.theme.secondaryBg};
          }
        }
      }
    }
  }
  .new_actions_buttons {
    display: flex;

    button {
      font-size: 16px;
      flex: 1;
      padding: 0.75rem 0;
      background: 0;
      border: 0;
      border-radius: 6px;
      color: ${(props) => props.theme.mainDarkText};
    }
    .new_actions_button_primary {
      color: white;
      background: ${(props) => props.theme.brandBlue};
      :hover {
        background: ${(props) => props.theme.darkBrandBlue};
      }
      :disabled {
        background: ${(props) => props.theme.disabledText};
        color: ${(props) => props.theme.disabled};
        pointer-events: none;
      }
    }
  }
`;
