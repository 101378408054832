import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components";

import { poolsLogos } from "../../helpers";
import { getRates } from "../../redux/rates/actions";
import { walletOperations } from "../../redux/walletSlice";
import { Divider } from "../../Shared";
import { StyledLink, breakPoints } from "../../Theme";
import Card from "./Card";
import Table from "./Table";

const platforms = ["Compound", "Aave", "dYdX"];

const ExtendedLink = styled(StyledLink)`
  color: ${(props) => props.theme.default_color};
`;

export const Container = styled.div`
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: ${(props) => props.theme.default_color};

  svg,
  img {
    margin-right: 10px;
    margin-left: 5px;
  }
  span {
    font-size: 16px;
    line-height: 16px;
    color: ${(props) => props.theme.form_text};
  }

  @media (max-width: ${breakPoints.lg}px) {
    font-size: 12px;
  }
`;
const Title = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => props.theme.mainDarkText};
  background: ${(props) => props.theme.bg};
  padding: 1.5rem 1rem;
  margin-bottom: 1.5em;
  width: max-content;
  min-width: 400px;

  .topInterestRate {
    color: ${(props) => props.theme.form_text};
    font-size: 14px;
  }

  @media (max-width: ${breakPoints.md}px) {
    display: flex;
    align-items: baseline;
    min-width: 0;
    width: 100%;
    span {
      font-size: 14px;
    }
  }

  @media (max-width: ${breakPoints.md}px) {
    flex-direction: column;
    padding: 16px;
  }

  svg,
  img {
    width: 24px;
    height: 24px;
  }
`;

export const RebalancerScreenWrapper = styled.div`
  margin-bottom: 2em;
  background: ${(props) => props.theme.sidebar_bg};
  .rebalancer_inside_wrapper {
    border: 1px solid ${(props) => props.theme.borderBg};
    padding: 1.5em;
    border-radius: 0 6px 6px 6px;
  }
  .PlatformsCards {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));

    @media (max-width: ${breakPoints.sm}px) {
      grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    }
  }
`;

export const renderPlatformLink = (title, text, asset) => {
  let str = title.toLowerCase();
  if (title.includes("C.")) str = "cream";
  if (title.includes("Aave")) str = "aave";
  return <ExtendedLink to={`/deposit/${str}?asset=${asset}`}>{text}</ExtendedLink>;
};

const stableCoins = ["DAI", "USDC", "USDT", "TUSD", "BUSD", "sUSD"];

export const TotalInvestRate = ({ t, localRates }) => {
  return (
    <Title>
      <span className="topInterestRate">{t("rebalancer.topInterestRate")}: </span>
      {localRates.length ? (
        <Container>
          <span style={{ marginLeft: "0.5rem" }}>{localRates[0].rate}</span>
          &nbsp;
          <span style={{ marginRight: "10px", fontWeight: 600, color: "#CED2D6" }}> @ </span>
          {poolsLogos[localRates[0].platform]}
          {localRates[0].platform}
        </Container>
      ) : null}
    </Title>
  );
};

function StableCoins({ rates }) {
  const dispatch = useDispatch();
  const [localRates, setLocalRates] = useState([]);

  const { t } = useTranslation();
  const tableHeadItems = [
    { id: "rank", label: t("common.rank"), sortable: true },
    { id: "asset", label: t("common.asset"), sortable: true },
    { id: "rate", label: t("common.supplyRate"), sortable: true },
    { id: "assetPrice", label: t("common.price"), sortable: true },
    { id: "borrowRate", label: t("common.borrowRate"), sortable: true },
    { id: "platform", label: t("common.platform"), sortable: true },
    { id: "action", label: t("common.action"), sortable: false },
  ];

  const filteredStableCoinsTokensList = (platform) => {
    let balance = dispatch(walletOperations.getNetworkStakedTokens("mainnet", platform, true));
    return Object.entries(balance)
      .filter(([symbol]) => {
        return stableCoins.includes(symbol);
      })
      .slice(0, 6);
  };

  useEffect(() => {
    if (rates.length) {
      // TODO: remove that excessive filtering
      let filterStableCoins = rates.filter(
        (entry, index) =>
          stableCoins.includes(entry.asset) &&
          rates.findIndex((obj) => obj.asset === entry.asset && obj.platform === entry.platform) === index
      );

      filterStableCoins.sort((a, b) => b.rate - a.rate);
      filterStableCoins.forEach((rate, index) => {
        filterStableCoins[index].rank = index + 1;
        filterStableCoins[index].action = renderPlatformLink(
          filterStableCoins[index].platform,
          t("common.manageAssets"),
          filterStableCoins[index].asset
        );
      });
      filterStableCoins = filterStableCoins.map((entry) => ({
        rank: entry.rank,
        asset: entry.asset,
        rate: String(entry.rate).slice(0, 6) + " %",
        assetPrice: `$${String(entry.asset === "sUSD" ? 1 : entry.assetPrice).slice(0, 7)} USD`,
        borrowRate: String(entry.borrowRate).slice(0, 4) + " %",
        platform:
          entry.platform === "C.R.E.A.M. Finance"
            ? "C.R.E.A.M."
            : entry.platform === "dYdX"
            ? "dydx"
            : entry.platform.charAt(0).toUpperCase() + entry.platform.slice(1),
        action: entry.action,
      }));
      setLocalRates(filterStableCoins);
    }
  }, [rates, t]);
  return (
    <RebalancerScreenWrapper>
      <div className="rebalancer_inside_wrapper">
        <TotalInvestRate localRates={localRates} t={t} />
        <div className="PlatformsCards">
          {platforms.map((p) => (
            <Card
              key={p}
              title={p}
              platform={p.toLowerCase()}
              tokenList={filteredStableCoinsTokensList(p.toLowerCase())}
            />
          ))}
        </div>
        <Divider />
        {localRates.length ? (
          <Table rows={localRates} tableHeadItems={tableHeadItems} title={t("common.stableCoin")} />
        ) : null}
      </div>
    </RebalancerScreenWrapper>
  );
}

const mapStateToProps = (state) => ({
  rates: state.rates.rates,
  user: state.user,
});

const mapDispatchtoProps = (dispatch) => ({
  getRates: () => dispatch(getRates()),
});
export default connect(mapStateToProps, mapDispatchtoProps)(StableCoins);
