import React, { useEffect, useMemo, useState } from "react";

import { ERC20Tokens } from "@keyfi/keyfi-common/src/constants";
import { getAddressSum } from "@keyfi/keyfi-common/src/integrations/common";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { strategyActionOperations } from "../../../redux/strategyActionsSlice";
import { strategyGroupOperations } from "../../../redux/strategyGroupSlice";
import AssetImage from "../../Borrow/AssetImage";
import StrategyDropdown from "../StrategyDropdown";

const Wrapper = styled.div`
  .action_input_container {
    display: flex;
    gap: 2rem;
    > div {
      flex: 1;
      .dropdown_image {
        height: 24px;
        width: 24px;
      }
    }
  }
`;
const AddLiquidityAction = ({ method, handleCloseModal, editModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const strategyAction = useSelector((state) => state.strategyAction);
  const { activeAction } = strategyAction;

  const strategyGroup = useSelector((state) => state.strategyGroup);
  const { group } = strategyGroup;

  const [searchQuery, setSearchQuery] = useState("");

  const tokens = useMemo(
    () =>
      ERC20Tokens.map((item) => ({
        id: item.id,
        text: item.symbol,
        img: (
          <AssetImage
            className="dropdown_image"
            alt={item.symbol}
            src={`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${
              item.symbol === "ETH" ? "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2" : getAddressSum(item.id)
            }/logo.png`}
          />
        ),
      })),
    []
  );

  useEffect(() => {
    if (method === "addLiquidityETH") {
      dispatch(strategyActionOperations.handleActiveActionArgs("1", "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"));
    }
  }, [method, dispatch]);

  const filteredList = useMemo(() => {
    return tokens.filter(({ text }) => text.toLowerCase().includes(searchQuery.toLowerCase())).slice(0, 20);
  }, [searchQuery, tokens]);

  const handleSaveAction = () => {
    const newArray = group.actions.filter((item) => activeAction.templateId !== item.templateId);
    dispatch(strategyGroupOperations.handleGroupInput("actions", [...newArray, activeAction]));
    handleCloseModal();
  };

  const handleUpdateAction = () => {
    dispatch(strategyActionOperations.updateAction());
    handleCloseModal();
  };

  const handleSelectOption = (name, value) => {
    dispatch(strategyActionOperations.handleActiveActionArgs(name, value));
    setSearchQuery("");
  };

  return (
    <Wrapper>
      <div className="action_input_container">
        <div>
          <h5>{t("common.asset")} 1</h5>
          <StrategyDropdown
            name="0"
            placeholder={t("strategy.chooseAnAsset")}
            setOption={handleSelectOption}
            options={filteredList}
            selectedOption={activeAction.args[0]}
            fullOptions={tokens}
            // className="edit_action_input"
            searchBar
            onChange={(e) => setSearchQuery(e.target.value)}
            searchQuery={searchQuery}
          />
        </div>
        <div>
          <h5>{t("common.asset")} 2</h5>
          <StrategyDropdown
            name="1"
            placeholder={t("strategy.chooseAnAsset")}
            setOption={(name, value) => dispatch(strategyActionOperations.handleActiveActionArgs(name, value))}
            options={filteredList}
            fullOptions={tokens}
            selectedOption={activeAction.args[1]}
            // className="edit_action_input"
            searchBar
            onChange={(e) => setSearchQuery(e.target.value)}
            searchQuery={searchQuery}
          />{" "}
        </div>
      </div>
      <div className="action_input_container">
        <div>
          <h5>
            {t("common.asset")} 1 {t("common.value")}
          </h5>
          <input
            name="2"
            value={activeAction.args[2]}
            onChange={(e) => dispatch(strategyActionOperations.handleActiveActionArgs(e.target.name, e.target.value))}
            className="edit_action_input"
          />
        </div>
        <div>
          <h5>
            {t("common.asset")} 2 {t("common.value")}
          </h5>
          <input
            name="3"
            value={activeAction.args[3]}
            onChange={(e) => dispatch(strategyActionOperations.handleActiveActionArgs(e.target.name, e.target.value))}
            className="edit_action_input"
          />
        </div>
      </div>
      <button className="primary" onClick={editModal ? handleUpdateAction : handleSaveAction}>
        {t("strategy.saveAction")}
      </button>
    </Wrapper>
  );
};

export default AddLiquidityAction;
