import styled, { css } from "styled-components";

import { breakPoints } from "../../../Theme";

export const MainSwapSection = styled.div`
  max-width: ${(props) => (props.dashboardSwap ? "100%" : "350px")};
  width: 100%;
  @media (min-width: ${breakPoints.lg}px) and (max-width: 1300px) {
    max-width: 370px;
  }
  h1 {
    text-align: center;
    font-size: 24px;
    margin-bottom: 1.5rem;
    @media (max-width: ${breakPoints.md}px) {
      display: none;
    }
  }
  .swap_main {
    padding: 1.5rem;
    background: ${(props) => props.theme.secondaryBg};
    border: 1px solid ${(props) => props.theme.researchBorder};
    border-radius: 6px;
    .swap_main_platforms {
      padding-bottom: 1.5rem;
      border-bottom: 1px solid ${(props) => props.theme.researchBorder};
      h5 {
        color: ${(props) => props.theme.lightGrey};
        margin-bottom: 6px;
        font-size: 12px;
      }
    }
    .swap_main_section {
      display: flex;
      flex-direction: column;
      padding: 1.5rem 0 2rem;
      .swap_input_label {
        display: flex;
        justify-content: space-between;
        color: ${(props) => props.theme.lightGrey};
        font-size: 12px;
        margin-bottom: 6px;
      }
      .swap_input_error {
        margin-top: 6px;
        font-size: 12px;
        color: ${(props) => props.theme.error};
        text-align: center;
      }
      .swap_arrow {
        margin: 0.5rem;
        align-self: center;
        cursor: pointer;
      }
    }
    .swap_button {
      width: 100%;
      background: ${(props) => props.theme.brandBlue};
      border-radius: 6px;
      border: 0;
      padding: 0.75rem 0;
      font-size: 16px;
      color: white;
      :hover {
        color: ${(props) => props.theme.mainDarkText};
        background: ${(props) => props.theme.darkBrandBlue};
      }
      :disabled {
        background: ${(props) => props.theme.disabledText};
        color: ${(props) => props.theme.disabled};
      }
    }
  }
`;

export const SwapInfo = styled.div`
  margin-top: 1.5rem;
  background: ${(props) => props.theme.secondaryBg};
  padding: 1.5rem;
  border: 1px solid ${(props) => props.theme.researchBorder};
  border-radius: 6px;
  ${(props) =>
    props.dashboardSwap &&
    css`
      border: 0px;
      padding: 0;
    `}
  h3 {
    font-size: 12px;
    color: ${(props) => props.theme.lightGrey};
    margin-bottom: 6px;
  }
  .swap_info_line {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.75rem;
    > div {
      font-size: 12px;
      .swap_info_value {
        margin-bottom: 2px;
      }
      :last-of-type {
        text-align: right;
      }
      .swap_info_label {
        color: ${(props) => props.theme.lightGrey};
      }
    }
    :last-of-type {
      margin: 0;
    }
  }
  ${(props) =>
    props.input1.length === 0 &&
    css`
      .swap_info_value {
        color: ${(props) => props.theme.disabled};
      }
    `}
`;

export const SwapInput = styled.div`
  display: flex;
  position: relative;
  .swap_input {
    display: block;
    text-align: left;
    width: 100%;
    color: ${(props) => props.theme.form_text};
    margin: 1rem 0;
    padding: 4px 0;
    border-bottom: 1px solid ${(props) => props.theme.researchBorder};
    font-size: 20px;
    ${(props) =>
      props.error &&
      css`
        color: ${(props) => props.theme.error};
      `}
    ::placeholder {
      color: ${(props) => props.theme.disabled};
    }
  }
  .swap_read_only {
    color: ${(props) => props.theme.mainDarkText};
  }
  .swap_max {
    position: absolute;
    right: 0;
    font-size: 12px;
    top: 25%;
    padding: 4px 0.5rem;
  }
`;
