import { integrations } from "@keyfi/keyfi-common";
import { createSlice } from "@reduxjs/toolkit";

import { walletSelectors } from "./walletSlice";

const initialState = {
  isFetching: true,
  mainnet: {
    aave: {
      v1: {
        data: [],
        balance: {},
        user: {},
      },
      v2: { data: [], balance: {}, user: {} },
    },
    compound: {
      v1: { data: [], balance: {}, user: {} },
    },
  },
};

const borrow = createSlice({
  name: "borrow",
  initialState,
  reducers: {
    setMainnet(state, action) {
      state.mainnet = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
  },
});

export const borrowActions = borrow.actions;

const getRoot = (state) => state.borrow;

export const borrowSelectors = {
  getMainnet: (state) => getRoot(state).mainnet,
};

export const borrowOperations = {
  getBorrowBalance: (selectedAddress) => async (dispatch, getState) => {
    if (!selectedAddress) {
      selectedAddress = walletSelectors.getSelectedAddress(getState());
    }

    // [aavev1, aavev2, compoundv1, aavev1Data, aavev2Data, compoundv1Data = []]

    try {
      const [aavev1, aavev2, compoundv1, aavev1Data, aavev2Data, compoundv1Data = []] = await Promise.all([
        integrations.aave.getBorrowedBalance(selectedAddress),
        integrations.aave.v2.getBorrowedBalance(selectedAddress),
        integrations.compound.getBorrowedBalance(selectedAddress),
        integrations.aave.getBorrowAssets(),
        integrations.aave.v2.getBorrowAssets(),
        integrations.compound.getBorrowAssets(),
      ]);

      const [userDataAaveV1, userDataAaveV2, userDataCompound = {}] = await Promise.all([
        integrations.aave.getUserAccountData(selectedAddress),
        integrations.aave.v2.getUserAccountData(selectedAddress),
        integrations.compound.getUserAccountData(selectedAddress),
      ]);

      dispatch(
        borrowActions.setMainnet({
          aave: {
            v1: {
              user: userDataAaveV1,
              data: aavev1Data,
              balance: aavev1,
            },
            v2: {
              user: userDataAaveV2,
              data: aavev2Data,
              balance: aavev2,
            },
          },
          compound: {
            v1: {
              data: compoundv1Data,
              balance: compoundv1,
              user: userDataCompound,
            },
          },
        })
      );
    } catch (err) {
      console.log(err);
    }
    dispatch(borrowActions.setIsFetching(false));
  },
  getBorrowedAmounts: () => (dispatch, getState) => {
    const state = getState();

    // Parses all borrowed values in one array
    const data = Object.entries(state.borrow).reduce((acc, [network, networkData]) => {
      const networkDataArray = Object.entries(networkData).reduce((acc, [platform, platformData]) => {
        const platformDataArray = Object.entries(platformData).reduce((acc, [version, versionData]) => {
          const versionDataArray = Object.entries(versionData.balance).reduce((acc, [symbol, symbolData]) => {
            if (symbolData.totalDebt !== "0") {
              const itemData = state.borrow[network][platform][version].data.find((item) => item.symbol === symbol);

              const data = [];

              if (symbolData.currentVariableDebt !== "0" && symbolData.currentVariableDebt) {
                data.push({
                  debtAmount: parseFloat(symbolData.currentVariableDebt),
                  borrowMode: "2",
                  network,
                  platform,
                  version,
                  address: itemData.address,
                  symbol,
                });
              }

              if (symbolData.currentStableDebt !== "0" && symbolData.currentStableDebt) {
                data.push({
                  debtAmount: parseFloat(symbolData.currentStableDebt),
                  borrowMode: "1",
                  network,
                  platform,
                  version,
                  address: itemData.address,
                  symbol,
                });
              }

              return [...acc, ...data];
            }
            return acc;
          }, []);
          return [...acc, ...versionDataArray];
        }, []);
        return [...acc, ...platformDataArray];
      }, []);
      return [...acc, ...networkDataArray];
    }, []);

    return data;
  },
  /**
   * Reduces all collateral data in one array
   * @return {Array} Array with objects
   */
  getCollateralData: () => (dispatch, getState) => {
    const { borrow } = getState();

    return Object.entries(borrow.mainnet).reduce((acc, [platform, platformValue]) => {
      const versionData = Object.entries(platformValue).reduce(
        (acc2, [version, versionValue]) => [
          ...acc2,
          {
            platform,
            version,
            ...versionValue.user,
          },
        ],
        []
      );
      return [...acc, ...versionData];
    }, []);
  },
  /**
   * Checks if platform has multiple versions
   * @param  {string} platform Borrow platform
   * @return {boolean}
   */
  doesPlatformHaveVersions: (platform) => (_, getState) => {
    const { borrow } = getState();

    return Object.keys(borrow.mainnet[platform.toLowerCase()]).length > 1;
  },
};

export const borrowReducer = borrow.reducer;
