import React, { useEffect, useState } from "react";

import { getSymbolFromAddress } from "@keyfi/keyfi-common/src/integrations";
import { availablePairs } from "@keyfi/keyfi-common/src/integrations/uniswap/constants/constants";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

// Assets
import { ReactComponent as ArrowRight } from "../../assets/actions/arrowRight.svg";
import { ReactComponent as NoTransactions } from "../../assets/actions/no_tx.svg";
import { ReactComponent as Plus } from "../../assets/actions/plus.svg";
import { ReactComponent as ArrowDown } from "../../assets/arrowDown.svg";
import { ReactComponent as Edit } from "../../assets/strategy/edit.svg";
import { ReactComponent as Trash } from "../../assets/strategy/trash.svg";
import AddActionModal from "../../Components/Actions/AddActionModal";
import Meta from "../../Components/Meta";
import { handleLink } from "../../Components/Strategy/StrategyCard";
import { poolsLogos } from "../../helpers";
import { txQueueOperations } from "../../redux/txQueueSlice";
import {
  ActionsContainer,
  ActionsTemplateList,
  ActionsTransactionQueue,
  ActionsWrapper,
  TxQueueItem,
} from "./Actions.styles";

export const handleShowArgs = (tx) => {
  if (
    tx.action === "Deposit" ||
    tx.action === "Withdraw" ||
    tx.name === "KeyFi" ||
    tx.action === "Borrow" ||
    tx.action === "Repay"
  ) {
    return (
      <div>
        {tx.args[1]} {tx.args[0]}
      </div>
    );
  }
  if (
    tx.platform === "uniswapv2" &&
    (tx.method === "swapExactETHForTokens" ||
      tx.method === "swapExactTokensForETH" ||
      tx.method === "swapExactTokensForTokens")
  ) {
    return (
      <div>
        {tx.args[2]} {getSymbolFromAddress(tx.args[0])}
      </div>
    );
  }
  if (tx.platform === "uniswapv2" && (tx.method === "addLiquidityETH" || tx.method === "addLiquidity")) {
    return (
      <div>
        {getSymbolFromAddress(tx.args[0])}/{getSymbolFromAddress(tx.args[1])}
      </div>
    );
  }
  if (tx.platform === "uniswapv2" && (tx.method === "removeLiquidityETH" || tx.method === "removeLiquidity")) {
    const pair = tx.args[0];
    const input = availablePairs.mainnet.find((item) => item.address === pair).key.split(":");

    return (
      <div>
        {input[0]}/{input[1]}
      </div>
    );
  }
};

const ActionTemplate = ({ item, actionList, openModal }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div onClick={() => setOpen((prev) => !prev)} className={`action_template_platform ${open ? "action_open" : ""}`}>
        {poolsLogos[item]}
        <span>{item}</span>
        <ArrowDown className="action_arrow_down" />
      </div>
      {open &&
        actionList
          .filter((action) => action.name === item)
          .map((action) => (
            <div
              key={action.action}
              className="action_template_platform action_platform_action"
              onClick={() => openModal(action._id)}
            >
              {poolsLogos[action.name]}
              <span>
                <h4>{action.action}</h4>
                <h5>{action.name}</h5>
              </span>
              <Plus />
            </div>
          ))}
    </>
  );
};

const Actions = ({ history }) => {
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const txQueue = useSelector((state) => state.txQueue);
  const { txQueueList, actionList } = txQueue;

  useEffect(() => {
    dispatch(txQueueOperations.getActionTemplateList());
  }, [dispatch]);

  const reducedPlatformList = actionList.reduce((acc, item) => {
    if (!acc.some((accItem) => accItem === item.name)) {
      return [...acc, item.name];
    }
    return acc;
  }, []);

  const handleOpenModal = (item) => {
    setShowModal(true);
    dispatch(txQueueOperations.handleActiveAction("templateId", item));
  };

  const handleEditOpenModal = (item) => {
    setShowEditModal(true);
    dispatch(txQueueOperations.handleActiveAction("templateId", item._id));
    dispatch(txQueueOperations.handleActiveAction("args", item.args));
  };

  return (
    <ActionsWrapper>
      <Meta title="Actions" />
      <h1>{t("action.title")}</h1>
      {showModal && <AddActionModal handleCloseModal={() => setShowModal(false)} />}
      {showEditModal && <AddActionModal editModal handleCloseModal={() => setShowEditModal(false)} />}
      <ActionsContainer>
        <ActionsTemplateList>
          {reducedPlatformList.map((item, i) => (
            <ActionTemplate key={`${item}-${i}`} item={item} actionList={actionList} openModal={handleOpenModal} />
          ))}
        </ActionsTemplateList>
        <div style={{ alignSelf: "center" }}>
          <ArrowRight />
        </div>
        <ActionsTransactionQueue>
          <h3>
            {t("action.txQueue")} ({txQueueList.length})
          </h3>
          {txQueueList.length !== 0 ? (
            txQueueList.map((tx, i) => (
              <TxQueueItem key={`${tx.name}-${i}`}>
                <div className="tx_item_main" onClick={() => history.push(handleLink(tx, tx.args))}>
                  {poolsLogos[tx.name]}
                  <span>
                    <h4>{tx.action}</h4>
                    <h5>{tx.name}</h5>
                  </span>
                  {handleShowArgs(tx)}
                </div>
                <div className="tx_item_buttons">
                  <button onClick={() => handleEditOpenModal(tx)}>
                    <Edit />
                  </button>
                  <button onClick={() => dispatch(txQueueOperations.removeFromTxQueue(tx._id))}>
                    <Trash />
                  </button>
                </div>
              </TxQueueItem>
            ))
          ) : (
            <div className="actions_no_tx_container">
              <NoTransactions className="action_no_tx" />
              <p>{t("action.txQueueIsEmpty")}</p>
            </div>
          )}
        </ActionsTransactionQueue>
      </ActionsContainer>
    </ActionsWrapper>
  );
};

export default Actions;
