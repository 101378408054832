import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import BigNumber from "bignumber.js";

import { Storage } from "../../helpers/Storage";
import { appActions } from "../appSlice";
import { stacksOperations } from "./stacksSlice";

const BALANCE_API = process.env.REACT_APP_BALANCE_API;

const initialState = {
  isLoading: false,
  activeStackId: "",
  showStack: false,
  balance: {
    tokens: [],
    lp: [],
    staking: [],
  },
};

const stackBalance = createSlice({
  name: "stackBalance",
  initialState,
  reducers: {
    setLoading(state, action) {
      return { ...state, isLoading: action.payload };
    },
    setActiveStackId(state, action) {
      return { ...state, activeStackId: action.payload };
    },
    setShowStack(state, action) {
      return { ...state, showStack: action.payload };
    },
    setBalance(state, action) {
      return { ...state, balance: action.payload };
    },
  },
});

export const stackBalanceActions = stackBalance.actions;

const getRoot = (state) => state.stackBalance;

export const stackBalanceSelectors = {
  getLoading: (state) => getRoot(state).isLoading,
  getActiveStackId: (state) => getRoot(state).activeStackId,
  getShowStack: (state) => getRoot(state).showStack,
  getBalance: (state) => getRoot(state).balance,
};

export const stackBalanceOperations = {
  fetchBalance: (ignoreCache) => async (dispatch, getState) => {
    try {
      dispatch(appActions.setLoading(true));
      const activeStackId = stackBalanceSelectors.getActiveStackId(getState());
      const activeStack = dispatch(stacksOperations.getActiveStack(activeStackId));
      const addresses = activeStack.addresses.map((item) => item.address);
      const [tokens, lp, staking] = await Promise.all([
        axios.get(`${BALANCE_API}/tokens?address=${addresses.join()}&ignoreCache=${ignoreCache}`),
        axios.get(`${BALANCE_API}/liquidity?address=${addresses.join()}&ignoreCache=${ignoreCache}`),
        axios.get(`${BALANCE_API}/staking?address=${addresses.join()}&ignoreCache=${ignoreCache}`),
      ]);

      dispatch(stackBalanceActions.setBalance({ tokens: tokens.data, lp: lp.data, staking: staking.data }));
    } catch (err) {
      console.log(err);
    }
    dispatch(appActions.setLoading(false));
  },
  initFetch: () => (dispatch) => {
    const id = Storage.getItem("activeStack");
    const showStack = Storage.getItem("showStack");
    if (id) dispatch(stackBalanceOperations.setActiveStackId(id));
    if (showStack === "true") dispatch(stackBalanceOperations.setShowStack(true));
    if (id && showStack === "true") dispatch(stackBalanceOperations.fetchBalance());
  },
  setActiveStackId: (id) => (dispatch) => {
    Storage.setItem("activeStack", id);
    dispatch(stackBalanceActions.setActiveStackId(id));
  },
  setShowStack: (value) => (dispatch) => {
    Storage.setItem("showStack", value);
    dispatch(stackBalanceActions.setShowStack(value));
  },
  // Returns network balance
  getNetworkValue: (network) => (dispatch, getState) => {
    const prices = getState().user.usdPrices;
    const balance = stackBalanceSelectors.getBalance(getState());
    const tokenBlacklist = getState().userData.user.tokenBlacklist;

    const tokenBlacklistFilter = (item) =>
      !tokenBlacklist.some((token) => token.network === item.network && token.address === item.address);

    const tokensBalance = balance.tokens.filter(tokenBlacklistFilter).reduce((acc, token) => {
      if (token.network === network) {
        return BigNumber(token.amount)
          .times(prices[token.symbol] ?? 0)
          .plus(acc)
          .toPrecision(6);
      }
      return acc;
    }, 0);

    const stakingBalance = balance.staking.filter(tokenBlacklistFilter).reduce((acc, token) => {
      if (token.network === network) {
        if (token.type === "token") {
          return BigNumber(token.amount)
            .times(prices[token.token.toUpperCase()] ?? 0)
            .plus(acc)
            .toPrecision(6);
        } else {
          const [assetA, assetB] = [token.token.split(":")[0], token.token.split(":")[1].split(" ")[0]];

          const assetAValue = prices[assetA] * token[assetA];
          const assetBValue = prices[assetB] * token[assetB];

          return BigNumber(acc).plus(assetAValue).plus(assetBValue).toPrecision(6);
        }
      }
      return acc;
    }, 0);

    const lpBalance = balance.lp.filter(tokenBlacklistFilter).reduce((acc, token) => {
      if (token.network === network) {
        const assetAValue = prices[token.assetA] * token[token.assetA];
        const assetBValue = prices[token.assetB] * token[token.assetB];

        return BigNumber(acc).plus(assetAValue).plus(assetBValue).toPrecision(6);
      }
      return acc;
    }, 0);

    const totalBalance = BigNumber(tokensBalance).plus(stakingBalance).plus(lpBalance).toPrecision(6);
    return { tokens: tokensBalance, staking: stakingBalance, lp: lpBalance, total: totalBalance };
  },

  // Returns network tokens
  getNetworkTokens: (network) => (dispatch, getState) => {
    const balance = stackBalanceSelectors.getBalance(getState());
    return balance.tokens
      .filter((token) => token.network === network)
      .reduce((acc, token) => {
        const index = acc.findIndex((item) => item.symbol === token.symbol);
        if (index !== -1) {
          acc[index] = {
            ...acc[index],
            amount: BigNumber(acc[index].amount).plus(token.amount).toPrecision(6),
          };
          return acc;
        }
        return [...acc, token];
      }, []);
  },
  getNetworkStakedTokens: (network, platform, notReduce) => (dispatch, getState) => {
    const balance = stackBalanceSelectors.getBalance(getState());
    return balance.staking
      .filter((token) => {
        if (platform) {
          return token.network === network && token.platform === platform;
        }
        return token.network === network;
      })
      .reduce((acc, token) => {
        if (!notReduce) {
          const index = acc.findIndex((item) => item.token === token.token);
          if (index !== -1) {
            acc[index] = {
              ...acc[index],
              amount: BigNumber(acc[index].amount).plus(token.amount).toPrecision(6),
            };
            return acc;
          }
        }
        return [...acc, token];
      }, []);
  },
  getNetworkLP: (network, platform) => (dispatch, getState) => {
    const balance = stackBalanceSelectors.getBalance(getState());
    return balance.lp.filter((token) => {
      if (platform) {
        return token.network === network && token.platform === platform;
      }
      return token.network === network;
    });
  },
  getNetworkPlatformValue: (network, platform, lp) => (dispatch, getState) => {
    const balance = stackBalanceSelectors.getBalance(getState());
    const prices = getState().user.usdPrices;

    if (lp) {
      const lpBalance = balance.lp.reduce((acc, token) => {
        if (token.network === network && token.platform === platform) {
          const assetAValue = prices[token.assetA] * token[token.assetA];
          const assetBValue = prices[token.assetB] * token[token.assetB];

          return BigNumber(acc).plus(assetAValue).plus(assetBValue).toPrecision(6);
        }
        return acc;
      }, 0);
      return lpBalance;
    }

    const stakingBalance = balance.staking.reduce((acc, token) => {
      if (token.network === network && token.platform === platform) {
        if (token.type === "token") {
          return BigNumber(token.amount)
            .times(prices[token.token.toUpperCase()] ?? 0)
            .plus(acc)
            .toPrecision(6);
        } else {
          const [assetA, assetB] = [token.token.split(":")[0], token.token.split(":")[1].split(" ")[0]];

          const assetAValue = prices[assetA] * token[assetA];
          const assetBValue = prices[assetB] * token[assetB];

          return BigNumber(acc).plus(assetAValue).plus(assetBValue).toPrecision(6);
        }
      }
      return acc;
    }, 0);

    return stakingBalance;
  },
  getSpecificToken: (symbol, network, staking) => (dispatch, getState) => {
    const balance = stackBalanceSelectors.getBalance(getState());

    if (staking) {
      return balance.staking.filter((token) => token.token === symbol && token.network === network);
    }
    return balance.tokens.filter((token) => token.symbol === symbol && token.network === network);
  },
  // Get total stack value
  getTotalBalance: () => (dispatch, getState) => {
    const prices = getState().user.usdPrices;
    const balance = stackBalanceSelectors.getBalance(getState());
    const tokenBlacklist = getState().userData.user.tokenBlacklist;

    const tokenBlacklistFilter = (item) =>
      !tokenBlacklist.some((token) => token.network === item.network && token.address === item.address);

    const tokensBalance = balance.tokens.filter(tokenBlacklistFilter).reduce((acc, token) => {
      return BigNumber(token.amount)
        .times(prices[token.symbol] ?? 0)
        .plus(acc)
        .toPrecision(6);
    }, 0);

    const stakingBalance = balance.staking.filter(tokenBlacklistFilter).reduce((acc, token) => {
      if (token.type === "token") {
        return BigNumber(token.amount)
          .times(prices[token.token] ?? 0)
          .plus(acc)
          .toPrecision(6);
      } else {
        const [assetA, assetB] = [token.token.split(":")[0], token.token.split(":")[1].split(" ")[0]];

        const assetAValue = prices[assetA] * token[assetA];
        const assetBValue = prices[assetB] * token[assetB];

        return BigNumber(acc).plus(assetAValue).plus(assetBValue).toPrecision(6);
      }
    }, 0);

    const lpBalance = balance.lp.filter(tokenBlacklistFilter).reduce((acc, token) => {
      const assetAValue = prices[token.assetA] * token[token.assetA];
      const assetBValue = prices[token.assetB] * token[token.assetB];

      return BigNumber(acc).plus(assetAValue).plus(assetBValue).toPrecision(6);
    }, 0);

    const totalBalance = BigNumber(tokensBalance).plus(stakingBalance).plus(lpBalance).toPrecision(6);
    return totalBalance;
  },
};

export const stackBalanceReducer = stackBalance.reducer;
