import React, { useMemo, useState } from "react";

import { availablePairs } from "@keyfi/keyfi-common/src/integrations/uniswap/constants/constants";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { txQueueOperations } from "../../../redux/txQueueSlice";
import StrategyDropdown from "../../Strategy/StrategyDropdown";



const Wrapper = styled.div`
  .action_input_container {
    display: flex;
    gap: 2rem;
    > div {
      flex: 1;
      .dropdown_image {
        height: 24px;
        width: 24px;
      }
    }
  }
`;
const RemoveLiquidityAction = ({ handleCloseModal, editModal }) => {
  const dispatch = useDispatch();
  const txQueue = useSelector((state) => state.txQueue);
  const { activeAction } = txQueue;

  const [searchQuery, setSearchQuery] = useState("");

  const tokenPairs = useMemo(
    () =>
      availablePairs.mainnet.map((item) => ({
        id: item.address,
        text: `${item.key}`,
      })),
    []
  );

  const filteredList = useMemo(() => {
    return tokenPairs.filter(({ text }) => text.toLowerCase().includes(searchQuery.toLowerCase())).slice(0, 20);
  }, [searchQuery, tokenPairs]);

  const handleSaveAction = () => {
    dispatch(txQueueOperations.addToTxQueueList());
    handleCloseModal();
  };

  const handleUpdateAction = () => {
    dispatch(txQueueOperations.updateTxQueueItem());
    handleCloseModal();
  };

  const handleSelectOption = (name, value) => {
    dispatch(txQueueOperations.handleActiveActionArgs(name, value));
    setSearchQuery("");
  };
  const { t } = useTranslation();
  return (
    <Wrapper>
      <h5>{t("common.asset")} 2</h5>
      <StrategyDropdown
        name="0"
        placeholder={t("strategy.chooseAnAsset")}
        setOption={handleSelectOption}
        options={filteredList}
        fullOptions={tokenPairs}
        selectedOption={activeAction.args[0]}
        // className="edit_action_input"
        searchBar
        onChange={(e) => setSearchQuery(e.target.value)}
        searchQuery={searchQuery}
      />{" "}
      <h5>{t("common.lpValue")}</h5>
      <input
        name="1"
        value={activeAction.args[1]}
        onChange={(e) => dispatch(txQueueOperations.handleActiveActionArgs(e.target.name, e.target.value))}
        className="edit_action_input"
      />
      <button className="primary" onClick={editModal ? handleUpdateAction : handleSaveAction}>
        {t("strategy.saveAction")}
      </button>
    </Wrapper>
  );
};

export default RemoveLiquidityAction;
