import React from "react";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import store from "./redux/store";
import { StyledThemeProvider } from "./Theme";
import "./index.css";

import "./i18n";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <StyledThemeProvider>
          <DndProvider backend={HTML5Backend}>
            <App />
          </DndProvider>
        </StyledThemeProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,

  document.getElementById("root")
);
