import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationDE from "./locales/de/translation.json";
import translationEN from "./locales/en/translation.json";
import translationFR from "./locales/fr/translation.json";
import translationIT from "./locales/it/translation.json";
import translationJP from "./locales/jp/translation.json";
import translationKR from "./locales/kr/translation.json";
import translationPL from "./locales/pl/translation.json";
import translationPT from "./locales/pt/translation.json";
import translationRU from "./locales/ru/translation.json";
import translationSP from "./locales/sp/translation.json";
import translationVI from "./locales/vi/translation.json";
import translationZH from "./locales/zh/translation.json";

const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
  fr: {
    translation: translationFR,
  },
  it: {
    translation: translationIT,
  },
  jp: {
    translation: translationJP,
  },
  kr: {
    translation: translationKR,
  },
  ru: {
    translation: translationRU,
  },
  sp: {
    translation: translationSP,
  },
  zh: {
    translation: translationZH,
  },
  pl: {
    translation: translationPL,
  },
  pt: {
    translation: translationPT,
  },
  vi: {
    translation: translationVI,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    supportedLngs: ["en", "en", "fr", "it", "jp", "kr", "ru", "sp", "zh", "pl", "pt", "vi"],
    debug: true,
    detection: {
      order: ["localStorage", "navigator"],
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
