import React from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as BSCIcon } from "../../../assets/header/BSC.svg";
import { ReactComponent as ETHIcon } from "../../../assets/header/ETH.svg";
import { ReactComponent as PolygonIcon } from "../../../assets/header/Polygon.svg";
import { beautifyTokensAmount } from "../../../helpers";
import { alertsOperations } from "../../../redux/alertsSlice";
import { createAlertOperations } from "../../../redux/createAlertSlice";
import AlertDropdown from "../AlertDropdown";

const Wrapper = styled.div`
  padding-bottom: 1rem;
  .gas_buttons {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1.25rem;
    button {
      display: flex;
      align-items: center;
      padding: 0.5rem;
      border-radius: 6px;
      line-height: 19px;
      background: 0;
      border: 0;
      color: ${(props) => props.theme.mainDarkText};
      :hover {
        background: ${(props) => props.theme.disabled};
      }
      svg {
        height: 19px;
        width: 19px;
        margin-right: 6px;
      }
    }
    .gas_button_selected {
      color: white;
      background: ${(props) => props.theme.brandBlue};
      :hover {
        background: ${(props) => props.theme.darkBrandBlue};
      }
    }
  }
  h4 {
    font-size: 12px;
    color: ${(props) => props.theme.lightGrey};
    margin-bottom: 0.5rem;
  }
  .gas_main {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 4px;
    .gas_dropdown {
      width: 100%;
      max-width: 200px;
    }
    > span {
      font-size: 12px;
      color: ${(props) => props.theme.disabledText};
    }
    input {
      padding: 11px 0.5rem;
      border: 1px solid ${(props) => props.theme.researchBorder};
      border-radius: 6px;
      line-height: 18px;
      :focus {
        border-color: ${(props) => props.theme.darkBrandBlue};
      }
    }
  }
  .gas_info {
    font-size: 12px;
    span {
      color: ${(props) => props.theme.form_text};
    }
  }
`;

const GasAlert = ({ editModal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const createAlert = useSelector((state) => state.createAlert);
  const { gas } = createAlert;

  const gasTypePlatforms = [
    {
      id: "safeLow",
      text: t("alert.Safe Low gas price"),
    },
    {
      id: "average",
      text: t("alert.Average gas price"),
    },
    {
      id: "fastest",
      text: t("alert.High gas price"),
    },
  ];

  const editAlert = useSelector((state) => state.alerts.editAlert);
  const gasPrices = useSelector((state) => state.gas.gasPrices);

  const handleInputChange = (name, value) => {
    if (editModal) {
      return dispatch(alertsOperations.handleEditAlert(name, value));
    }
    return dispatch(createAlertOperations.setGas(name, value));
  };
  const data = {
    network: editModal ? editAlert.network : gas.network,
    gasRate: editModal ? editAlert.gasRate : gas.gasRate,
    value: editModal ? editAlert.value : gas.value,
  };

  const selectedGasType = gasTypePlatforms.find((item) => item.id === data.gasRate);
  return (
    <Wrapper>
      <div className="gas_buttons">
        <button
          className={data.network === "ethereum" ? "gas_button_selected" : ""}
          onClick={() => handleInputChange("network", "ethereum")}
        >
          <ETHIcon />
          Ethereum
        </button>
        <button
          className={data.network === "bsc" ? "gas_button_selected" : ""}
          onClick={() => handleInputChange("network", "bsc")}
        >
          <BSCIcon />
          BSC
        </button>
        <button
          className={data.network === "polygon" ? "gas_button_selected" : ""}
          onClick={() => handleInputChange("network", "polygon")}
        >
          <PolygonIcon />
          Polygon
        </button>
      </div>
      <h4>{t("alert.gasPrice")}</h4>
      <div className="gas_main">
        <AlertDropdown
          name="gasRate"
          selectedOption={data.gasRate}
          options={gasTypePlatforms}
          placeholder={t("alert.chooseType")}
          className="gas_dropdown"
          setOption={handleInputChange}
        />
        <span>{t("alert.lessThan")}</span>
        <input name="value" value={data.value} onChange={(e) => handleInputChange(e.target.name, e.target.value)} />
      </div>
      {selectedGasType && (
        <div className="gas_info">
          {selectedGasType ? selectedGasType.text : ""} {t("alert.is")}{" "}
          <span>{beautifyTokensAmount(gasPrices[data.gasRate] / 10)}</span> Gwei
        </div>
      )}
    </Wrapper>
  );
};

export default GasAlert;
