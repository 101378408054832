import React, { useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { ReactComponent as ArrowDown } from "../../assets/arrowDown.svg";
import { trimAddress } from "../../helpers";
import { useWallet } from "../../helpers/useWallet";
import { stringToColour } from "../../Pages/ManageWallets";
import { addressesOperations } from "../../redux/wallets/addressesSlice";
import { stackBalanceOperations } from "../../redux/wallets/stackBalanceSlice";
import { stacksOperations } from "../../redux/wallets/stacksSlice";
import { breakPoints } from "../../Theme";
import { StackIcon, StacksButtonsWrapper } from "../Layout/Header.styles";
import WalletCard from "./WalletCard";

export function colorIsLight(color) {
  const hex = color.replace("#", "");
  const c_r = parseInt(hex.substr(0, 2), 16);
  const c_g = parseInt(hex.substr(2, 2), 16);
  const c_b = parseInt(hex.substr(4, 2), 16);
  const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
  return brightness > 155;
}

const StacksButtons = ({ handleShowModal, handleDisconnect, languageData, handleLanguage }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const wallet = useWallet();
  const { showStack, balance, activeStackId } = useSelector((state) => state.stackBalance);

  const ref = useRef(null); // Stack ref
  const addressRef = useRef(null); // Address ref

  const connectedWalletData = wallet.address
    ? dispatch(addressesOperations.getAddressFromAddress(wallet.address))
    : null;
  const stacks = useSelector((state) => state.stacks);

  const activeStack = dispatch(stacksOperations.getActiveStack(activeStackId)) ?? stacks.stacks[0] ?? undefined;

  const setStack = (fetchStack, id) => {
    if (fetchStack) {
      dispatch(stackBalanceOperations.setActiveStackId(id));
      if (balance.tokens.length === 0 || activeStackId !== id) {
        dispatch(stackBalanceOperations.fetchBalance());
      }
    }
    dispatch(stackBalanceOperations.setShowStack(fetchStack));
  };

  return (
    <StacksButtonsWrapper>
      {/* If wallet is connected */}
      {wallet.address && (
        <button
          onMouseLeave={() => (addressRef.current.style.display = "")}
          onMouseOver={() => {
            if (window.innerWidth > breakPoints.lg && stacks.stacks.length !== 0 && addressRef.current) {
              addressRef.current.style.display = "block";
            }
          }}
          className={!showStack ? "stack_button_active" : ""}
          onClick={() => setStack(false)}
        >
          <div className={"stack_button_icon"} style={{ background: "#1AD6B4" }} />
          <div className="stack_button_title">
            {connectedWalletData
              ? connectedWalletData.title || trimAddress(connectedWalletData.address)
              : trimAddress(wallet.address)}
          </div>
          <ArrowDown
            className="stack_button_arrow_mobile"
            onClick={() => {
              if (window.innerWidth < breakPoints.lg) {
                addressRef.current.style.display = "block";
              }
            }}
          />

          <WalletCard
            ref={addressRef}
            handleShowModal={handleShowModal}
            handleDisconnect={handleDisconnect}
            languageData={languageData}
            openLanguage={handleLanguage}
          />
        </button>
      )}
      {!wallet.address && (
        <button className={!showStack ? "stack_button_active" : ""} onClick={handleShowModal}>
          Connect Wallet
        </button>
      )}

      <button
        onMouseLeave={() => (ref.current.style.display = "")}
        onMouseOver={() => {
          if (window.innerWidth > breakPoints.lg && stacks.stacks.length !== 0) {
            ref.current.style.display = "block";
          }
        }}
        className={`stack_button_list ${showStack ? "stack_button_active" : ""}`}
        onClick={() => {
          if (window.innerWidth < breakPoints.lg) return;
          return stacks.stacks.length !== 0 ? setStack(true, activeStack._id) : history.push("/wallets");
        }}
      >
        {activeStack ? (
          <>
            <StackIcon
              height="24px"
              fontSize="14px"
              onClick={() => window.innerWidth < breakPoints.lg && setStack(true, activeStack._id)}
              bg={stringToColour(activeStack._id)}
              bgIsLight={colorIsLight(stringToColour(activeStack._id))}
            >
              {activeStack.addresses.length}
            </StackIcon>
            <div
              className="stack_button_title"
              onClick={() => window.innerWidth < breakPoints.lg && setStack(true, activeStack._id)}
            >
              {activeStack.title}
            </div>
            <ArrowDown
              className="stack_button_arrow_mobile"
              onClick={() => {
                if (window.innerWidth < breakPoints.lg) {
                  ref.current.style.display = "block";
                }
              }}
            />
          </>
        ) : (
          "Manage Stacks"
        )}
      </button>
      <div
        ref={ref}
        className="stack_list"
        onMouseLeave={() => (ref.current.style.display = "")}
        onMouseOver={() => (ref.current.style.display = "block")}
      >
        <div className="stack_list_section">
          {stacks.stacks.map((stack) => (
            <button
              key={stack._id}
              onClick={() => {
                setStack(true, stack._id);
                ref.current.style.display = "none";
              }}
            >
              <StackIcon bg={stringToColour(stack._id)} bgIsLight={colorIsLight(stringToColour(stack._id))}>
                {stack.addresses.length}
              </StackIcon>
              <div className="stack_info">
                <h4>{stack.title}</h4>
                <h6>{stack.addresses.length} addresses</h6>
              </div>
            </button>
          ))}
        </div>
        <div className="stack_list_line" />
        <Link to="/wallets" onClick={() => (ref.current.style.display = "none")}>
          Manage stacks
        </Link>
      </div>
    </StacksButtonsWrapper>
  );
};

export default StacksButtons;
