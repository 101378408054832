import React, { useState } from "react";

import { getTimeFromNow } from "./news.helpers";
import PopUpNews from "./PopUpNews";
import { Wrap } from "./Readinglist.styles";

const ReadingFeed = ({ item }) => {
  const [open, setOpen] = useState(false);
  const [itemData, setItemData] = useState(item);
  const handleOpen = () => {
    setOpen(!open);
  };

  const mydate = new Date(item.date);
  const postedTime = getTimeFromNow(mydate.getTime());

  return (
    <Wrap onClick={handleOpen}>
      {open && <PopUpNews handleOpen={handleOpen} itemData={itemData} setItemData={setItemData} />}
      <img src={item.image_url} />
      <div className="info">
        <h5>{item.title}</h5>
        <p>{postedTime}</p>
      </div>
    </Wrap>
  );
};

export default ReadingFeed;
