import { poolsLogos } from "../../helpers";
import Token from "../../helpers/token";

const platforms = [
  {
    id: "uniswap",
    icon: poolsLogos["Uniswap"],
    text: "Uniswap",
    network: 1,
    networkName: "mainnet",
    type: "univ2",
    defaultSelected: [
      new Token(1, "0x0000000000000000000000000000000000000000", "ETH", 18),
      new Token(1, "0xb8647e90c0645152fccf4d9abb6b59eb4aa99052", "KEYFI", 18),
    ],
  },
  {
    id: "apeswap",
    icon: poolsLogos["ApeSwap"],
    text: "ApeSwap BSC",
    network: 56,
    networkName: "bsc-mainnet",
    type: "univ2",
    defaultSelected: [
      new Token(56, "0x0000000000000000000000000000000000000000", "BNB", 18),
      new Token(56, "0x4b6000f9163de2e3f0a01ec37e06e1469dbbce9d", "KEYFI", 18),
    ],
  },
  {
    id: "pancakeswap",
    icon: poolsLogos["Pancakeswap"],
    text: "PancakeSwap",
    versions: ["v1", "v2"],
    network: 56,
    networkName: "bsc-mainnet",
    type: "univ2",
    defaultSelected: [
      new Token(56, "0xe9e7cea3dedca5984780bafc599bd69add087d56", "BUSD", 18),
      new Token(56, "0x4b6000f9163de2e3f0a01ec37e06e1469dbbce9d", "KEYFI", 18),
    ],
  },
  {
    id: "quickswap",
    icon: poolsLogos.QuickSwap,
    text: "QuickSwap",
    network: 137,
    networkName: "polygon",
    type: "univ2",
    defaultSelected: [
      new Token(137, "0x831753dd7087cac61ab5644b308642cc1c33dc13", "QUICK", 18),
      new Token(137, "0xd1a5f2a049343fc4d5f8d478f734eba51b22375e", "KEYFI", 18),
    ],
  },
  // { id: "curve", icon: poolsLogos["Curve"], text: "Curve", networkName: "mainnet", network: 1 },
];

export default platforms;
