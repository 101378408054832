import React, { useEffect, useState } from "react";

import Tippy from "@tippyjs/react";
import moment from "moment";
import "tippy.js/dist/tippy.css"; // optional
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

// Assets
import { ReactComponent as DeleteIcon } from "../../assets/alerts/delete.svg";
import { ReactComponent as EditIcon } from "../../assets/alerts/edit.svg";
import { ReactComponent as ReactivateIcon } from "../../assets/alerts/Reactivate.svg";
import Checked from "../../assets/checkedCircle.svg";
import { ReactComponent as Close } from "../../assets/closeModal.svg";
import CreateAlertModal from "../../Components/Alerts/CreateAlertModal";
import Meta from "../../Components/Meta";
import { alertsActions, alertsOperations } from "../../redux/alertsSlice";
import { createAlertOperations } from "../../redux/createAlertSlice";
import {
  AlertLabels,
  AlertListItem,
  AlertsGrid,
  AlertsListWrapper,
  AlertsWrapper,
  SuccessMessage,
  ToggleButtonWrapper,
} from "./Alerts.styles";
import { iconHandler, subtitleHandler, titleHandler } from "./alertsHelpers";

const Alerts = ({ location }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { search } = location;

  const selectedAsset = new URLSearchParams(search).get("asset") ?? "";

  const [showModal, setShowModal] = useState(selectedAsset.length !== 0);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDelete, setShowDelete] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const alerts = useSelector((state) => state.alerts);
  const { editIsLoading, alertsList } = alerts;

  useEffect(() => {
    dispatch(alertsOperations.getAlerts());
  }, [dispatch]);

  useEffect(() => {
    if (selectedAsset) {
      dispatch(createAlertOperations.setPrice("asset", selectedAsset));
    }
  }, [selectedAsset, dispatch]);

  useEffect(() => {
    if (showMessage) {
      let timer1 = setTimeout(() => setShowMessage(false), 3500);
      return () => {
        clearTimeout(timer1);
      };
    }
  }, [showMessage]);

  const handleShowDelete = (id) => {
    if (id === showDelete) {
      return setShowDelete("");
    }
    return setShowDelete(id);
  };

  const handleDelete = async (id) => {
    await dispatch(alertsOperations.deleteAlert(id));
    setShowDelete("");
  };

  const handleEditModal = (data) => {
    dispatch(alertsActions.setEditAlert(data));
    setShowEditModal(true);
  };

  const handleReactivateAlert = (id) => {
    setShowMessage(true);
    dispatch(alertsOperations.updateAlert(id, { status: "active", endDate: null }));
  };
  const activeList = alertsList.filter(
    (item) => item.status === "active" && (item.endDate ? new Date() < new Date(item.endDate) : true)
  );

  const historyList = alertsList.filter(
    (item) => item.status === "triggered" || (item.endDate ? new Date() > new Date(item.endDate) : false)
  );

  const inactiveList = alertsList.filter((item) => item.status === "disabled");

  const ToggleButton = ({ name, checked, onClick }) => (
    <Tippy content={<span style={{ fontSize: "10px" }}>{checked ? t("common.active") : t("common.disabled")}</span>}>
      <ToggleButtonWrapper>
        <input name={name} type="checkbox" checked={checked} onChange={onClick} />
        <span className="slider round"></span>
      </ToggleButtonWrapper>
    </Tippy>
  );

  return (
    <AlertsWrapper>
      <Meta title="Alerts" />
      {showModal && <CreateAlertModal handleCloseModal={() => setShowModal(false)} />}
      {showEditModal && (
        <CreateAlertModal editModal item={showEditModal} handleCloseModal={() => setShowEditModal(false)} />
      )}
      {showMessage && (
        <SuccessMessage>
          <img src={Checked} alt="Update successful" />
          <div>{t("alert.Your alerts has been reactivated")}</div>
          <Close onClick={() => setShowMessage(false)} />
        </SuccessMessage>
      )}

      <div className="alerts_title">
        <h1>{t("alert.myAlerts")}</h1>
        <button className="secondary" onClick={() => setShowModal(true)}>
          {t("alert.createAlert")}
        </button>
      </div>

      <AlertsGrid>
        <div>
          <div className="alert_type_header">
            <div className="alert_type_circle alert_active_circle">
              <div />
            </div>
            <h2>{t("alert.activeAlert")}</h2>
          </div>
          <AlertLabels>
            <div style={{ flex: "1.5" }}>
              <span style={{ paddingLeft: "28px" }}>{t("alert.type")}</span>
            </div>
            <div style={{ flex: "0.5", marginRight: "1.5rem" }}>{t("alert.created")}</div>
            <div style={{ flex: "0.5", marginRight: "1.5rem" }}>{t("alert.dueTime")}</div>
            <div style={{ flex: "0.5", textAlign: "right" }}>
              {t("alert.status")} / {t("alert.edit")} / {t("alert.delete")}
            </div>
          </AlertLabels>
          <AlertsListWrapper deleteOpen={showDelete.length !== 0}>
            {activeList.length !== 0 ? (
              activeList.map((item) => (
                <>
                  <AlertListItem key={item._id} selectedDeleteItem={showDelete === item._id}>
                    <div className="alert_list_title">
                      {iconHandler(item)}
                      <div>
                        {" "}
                        <h4>{titleHandler(item, t)}</h4>
                        <h6>{subtitleHandler(item, t)}</h6>{" "}
                      </div>
                    </div>
                    <div className="alert_list_date">
                      <div>{moment(item.createdAt).format("DD MMM, YYYY")}</div>
                      <div>{moment(item.createdAt).format("HH:mm")}</div>
                    </div>
                    <div className="alert_list_date">
                      {item.endDate ? (
                        <>
                          {" "}
                          <div>{moment(item.endDate).format("DD MMM, YYYY")}</div>
                          <div>{moment(item.endDate).format("HH:mm")}</div>
                        </>
                      ) : (
                        "-"
                      )}
                    </div>
                    <div className="alert_list_buttons">
                      <ToggleButton
                        checked={item.status === "active"}
                        onClick={(e) =>
                          dispatch(
                            alertsOperations.updateAlert(item._id, { status: e.target.checked ? "active" : "disabled" })
                          )
                        }
                      />
                      <Tippy content={<span style={{ fontSize: "10px" }}>{t("alert.edit")}</span>}>
                        <EditIcon onClick={() => handleEditModal(item)} style={{ marginBottom: "-1px" }} />
                      </Tippy>
                      <Tippy content={<span style={{ fontSize: "10px" }}>{t("alert.delete")}</span>}>
                        <DeleteIcon onClick={() => handleShowDelete(item._id)} />
                      </Tippy>
                    </div>
                  </AlertListItem>
                  {showDelete === item._id && (
                    <AlertListItem key={item._id + "12"} deleteBlock>
                      <h3 className="alert_list_delete_title">{t("alert.aboutToDelete")}</h3>
                      <p className="alert_list_delete_description">{t("alert.irreversibleDelete")}</p>
                      <div className="alert_list_buttons">
                        <button disabled={editIsLoading} onClick={() => handleDelete(item._id)}>
                          <DeleteIcon /> {t("alert.delete")}
                        </button>
                        <button onClick={() => setShowDelete("")}>{t("alert.cancel")}</button>
                      </div>
                    </AlertListItem>
                  )}
                </>
              ))
            ) : (
              <AlertListItem>{t("alert.noActiveAlert")}</AlertListItem>
            )}
          </AlertsListWrapper>
          <div className="alert_type_header">
            <div className="alert_type_circle alert_inactive_circle">
              <div />
            </div>
            <h2>{t("alert.inactiveAlert")}</h2>
          </div>
          <AlertLabels>
            <div style={{ flex: "1.5" }}>
              <span style={{ paddingLeft: "28px" }}>{t("alert.type")}</span>
            </div>
            <div style={{ flex: "0.5", marginRight: "1.5rem" }}>{t("alert.created")}</div>
            <div style={{ flex: "0.5", marginRight: "1.5rem" }}>{t("alert.dueTime")}</div>
            <div style={{ flex: "0.5", textAlign: "right" }}>
              {t("alert.status")} / {t("alert.edit")} / {t("alert.delete")}
            </div>
          </AlertLabels>

          <AlertsListWrapper deleteOpen={showDelete.length !== 0}>
            {inactiveList.length !== 0 ? (
              inactiveList.map((item) => (
                <>
                  <AlertListItem key={item._id} selectedDeleteItem={showDelete === item._id}>
                    <div className="alert_list_title">
                      {iconHandler(item)}
                      <div>
                        {" "}
                        <h4>{titleHandler(item, t)}</h4>
                        <h6>{subtitleHandler(item, t)}</h6>{" "}
                      </div>
                    </div>
                    <div className="alert_list_date">
                      <div>{moment(item.createdAt).format("DD MMM, YYYY")}</div>
                      <div>{moment(item.createdAt).format("HH:mm")}</div>
                    </div>
                    <div className="alert_list_date">
                      {item.endDate ? (
                        <>
                          {" "}
                          <div>{moment(item.endDate).format("DD MMM, YYYY")}</div>
                          <div>{moment(item.endDate).format("HH:mm")}</div>
                        </>
                      ) : (
                        "-"
                      )}
                    </div>
                    <div className="alert_list_buttons">
                      <ToggleButton
                        checked={item.status === "active"}
                        onClick={(e) =>
                          dispatch(
                            alertsOperations.updateAlert(item._id, { status: e.target.checked ? "active" : "disabled" })
                          )
                        }
                      />
                      <Tippy content={<span style={{ fontSize: "10px" }}>{t("alert.edit")}</span>}>
                        <EditIcon onClick={() => handleEditModal(item)} style={{ marginBottom: "-1px" }} />
                      </Tippy>
                      <Tippy content={<span style={{ fontSize: "10px" }}>{t("alert.delete")}</span>}>
                        <DeleteIcon onClick={() => handleShowDelete(item._id)} />
                      </Tippy>
                    </div>
                  </AlertListItem>
                  {showDelete === item._id && (
                    <AlertListItem key={item._id + "12"} deleteBlock>
                      <h3 className="alert_list_delete_title">{t("alert.aboutToDelete")}</h3>
                      <p className="alert_list_delete_description">{t("alert.irreversibleDelete")}</p>
                      <div className="alert_list_buttons">
                        <button disabled={editIsLoading} onClick={() => handleDelete(item._id)}>
                          <DeleteIcon /> {t("alert.delete")}
                        </button>
                        <button onClick={() => setShowDelete("")}>{t("alert.cancel")}</button>
                      </div>
                    </AlertListItem>
                  )}
                </>
              ))
            ) : (
              <AlertListItem>{t("alert.noInavtiveAlert")}</AlertListItem>
            )}
          </AlertsListWrapper>
        </div>
        <div>
          <div className="alert_type_header">
            <div className="alert_type_circle alert_history_circle">
              <div />
            </div>
            <h2>{t("alert.history")}</h2>
          </div>
          <AlertLabels>
            <div style={{ flex: "1.5" }}>
              <span style={{ paddingLeft: "28px" }}>{t("alert.type")}</span>
            </div>
            <div style={{ flex: "0.5", marginRight: "1.5rem" }}>{t("alert.created")}</div>
            <div style={{ flex: "0.5", marginRight: "1.5rem" }}>{t("alert.dueTime")}</div>
            <div style={{ flex: "0.5", textAlign: "right" }}>
              <span style={{ paddingRight: "6.5rem" }}>{t("alert.status")} </span>
            </div>
          </AlertLabels>
          <AlertsListWrapper deleteOpen={showDelete.length !== 0}>
            {historyList.length !== 0 ? (
              historyList.map((item) => (
                <>
                  <AlertListItem key={item._id} selectedDeleteItem={showDelete === item._id}>
                    <div className="alert_list_title">
                      {iconHandler(item)}
                      <div>
                        {" "}
                        <h4>{titleHandler(item, t)}</h4>
                        <h6>{subtitleHandler(item, t)}</h6>{" "}
                      </div>
                    </div>
                    <div className="alert_list_date">
                      <div>{moment(item.createdAt).format("DD MMM, YYYY")}</div>
                      <div>{moment(item.createdAt).format("HH:mm")}</div>
                    </div>
                    <div className="alert_list_date">
                      {item.triggeredAt ? (
                        <>
                          {" "}
                          <div>{moment(item.triggeredAt).format("DD MMM, YYYY")}</div>
                          <div>{moment(item.triggeredAt).format("HH:mm")}</div>
                        </>
                      ) : (
                        "-"
                      )}
                    </div>
                    <div className="alert_list_buttons">
                      <div className={item.status === "triggered" ? "alert_triggered_text" : "alert_expired_text"}>
                        {item.status === "triggered" ? t("common.triggered") : t("common.expired")}
                      </div>
                      <ReactivateIcon
                        onClick={() => handleReactivateAlert(item._id)}
                        className="alert_reactivate_icon"
                      />
                      <Tippy content={<span style={{ fontSize: "10px" }}>{t("alert.delete")}</span>}>
                        <DeleteIcon onClick={() => handleShowDelete(item._id)} />
                      </Tippy>
                    </div>
                  </AlertListItem>
                  {showDelete === item._id && (
                    <AlertListItem key={item._id + "12"} deleteBlock>
                      <h3 className="alert_list_delete_title">{t("alert.aboutToDelete")}</h3>
                      <p className="alert_list_delete_description">{t("alert.irreversibleDelete")}</p>
                      <div className="alert_list_buttons">
                        <button disabled={editIsLoading} onClick={() => handleDelete(item._id)}>
                          <DeleteIcon /> {t("alert.delete")}
                        </button>
                        <button onClick={() => setShowDelete("")}>{t("alert.cancel")}</button>
                      </div>
                    </AlertListItem>
                  )}
                </>
              ))
            ) : (
              <AlertListItem>{t("alert.noTriggeredOrExpiredAlert")}</AlertListItem>
            )}
          </AlertsListWrapper>
        </div>
      </AlertsGrid>
    </AlertsWrapper>
  );
};

export default Alerts;
