import React, { useState } from "react";

import styled from "styled-components";

// Assets
import { ReactComponent as ArrowDown } from "../../assets/arrowDown.svg";

const Wrapper = styled.div`
  position: relative;
  width: ${(props) => (props.maxWidth ? "100%" : "max-content")};
  font-size: 14px;

  .dropdown_selected {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0.75rem;
    background: ${(props) => props.theme.bg};
    border-radius: ${(props) => (props.open ? "6px 6px 0 0" : "6px")};
    border: 1px solid ${(props) => (props.open ? props.theme.brandBlue : props.theme.researchBorder)};
    cursor: pointer;
    svg {
      width: 14px;
      margin-left: 9px;
    }
  }
  .dropdown_list {
    position: absolute;
    width: max-content;
    min-width: ${(props) => (props.maxWidth ? "100%" : "110%")};
    top: 100%;
    left: 0;
    z-index: 10;
    .dropdown_option {
      background: ${(props) => props.theme.bg};
      overflow: hidden;
      cursor: pointer;
      padding: 0.5rem 0.75rem;
      border: 1px solid ${(props) => props.theme.researchBorder};
      border-top: 0;
      :hover {
        background: ${(props) => props.theme.brandBlue};
        border-color: ${(props) => props.theme.darkBrandBlue};
        color: white;
      }
      :first-of-type {
        border: 1px solid ${(props) => props.theme.researchBorder};
        border-radius: 0 6px 0 0;
      }
      :last-of-type {
        border-radius: 0 0 6px 6px;
      }
    }
  }
`;

const DropdownList = ({ className, list, placeholder, selected, setOption, maxWidth }) => {
  const [open, setOpen] = useState(false);

  const onClickOutsideListener = () => {
    setOpen(false);
    document.removeEventListener("click", onClickOutsideListener);
  };

  const handleSelectOption = (id) => {
    setOption(id);
    setOpen(false);
  };
  return (
    <Wrapper
      className={className}
      maxWidth={maxWidth}
      open={open}
      onMouseLeave={() => {
        if (open) {
          document.addEventListener("click", onClickOutsideListener);
        }
      }}
    >
      <div className="dropdown_selected" onClick={() => setOpen(!open)}>
        {selected ? selected : placeholder} <ArrowDown />
      </div>
      {open && (
        <div className="dropdown_list">
          {list.map((option) => (
            <div key={option.id} className="dropdown_option" onClick={() => handleSelectOption(option.id)}>
              {option.text}
            </div>
          ))}
        </div>
      )}
    </Wrapper>
  );
};

export default DropdownList;
