import styled from "styled-components";
export const PopUpWrapper = styled.div`
  width: 100%;
  min-height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: ${(props) => props.position};
  bottom: ${(props) => props.position};
  z-index: 999999;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.7);
  .change-color {
    color: #56c2ff;
  }
`;
export const PopUpContent = styled.div`
  height: 100%;
  padding-bottom: 5rem;
  padding-top: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const PopUpContainer = styled.div`
  background: #182331;
  border-radius: 6px;
  width: 50%;
  z-index: 99999900;
`;
export const PopUpContantWrapper = styled.div`
  padding: 35px 30px;
`;
export const PopUpHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #3a4c61;
  padding-bottom: 20px;
  span {
    font-size: 24px;
    color: #fff;
  }
  .close-icon {
    cursor: pointer;
    padding: 7px 5px;
    border-radius: 6px;
    transition: all 0.3s linear;
    &:hover {
      background-color: #2a3540;
    }
  }
`;
export const PopUpBodyWrapper = styled.div`
  border-radius: 6px 6px 0px 0px;
  margin-top: 30px;
  border: 1px solid #2a3540;
`;
export const PopUpBody = styled.div`
  background: linear-gradient(90deg, #101926 0%, rgba(74, 91, 114, 0) 100%);
  padding: 10px;
  border: 1px solid #2a3540;
  display: flex;
  align-items: center;
  span {
    color: #56c2ff;
  }
  .popup-parameters {
    color: #56c2ff;
    font-size: 16px;
    margin-left: 10px;
  }
`;
export const PopUpBodyHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 15px;
  .header-active {
    background-color: #005ad3;
  }
`;
export const PopUpBodyHeader = styled.div`
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  border-radius: 6px;
  padding: 10px 15px;
  transition: all 0.2s linear;
  cursor: pointer;
  &:hover {
    background-color: #2a3540;
  }
`;

export const PopUpBodyContainer = styled.div`
  background: ${(props) => props.bgColor};
`;
export const ActiveTime = styled.div`
  background: ${(props) => props.bgColor};
  border: 1px solid #2a3540;
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  span img {
    margin-right: 15px;
  }
  span {
    color: ${(props) => props.color};
    font-size: 16px;
  }
`;
export const PopUpButtonContainer = styled.div`
  display: flex;
  margin-top: 30px;
`;
export const PopUpButton = styled.button`
  background-color: ${(props) => props.bgColor};
  border-radius: 6px;
  border: none;
  outline: none;
  padding: 10px 20px;
  color: ${(props) => props.color};
  cursor: pointer;
  margin-right: 2rem;
`;
export const ParametersContentWrapper = styled.div`
  padding: 0 30px;
`;
export const ParametersContent = styled.div`
  border-top: 1px solid #2a3540;
  padding-top: 15px;
  padding-bottom: 15px;
`;
export const ShowContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const SingleContentWrapper = styled.div`
  position: relative;
  margin-bottom: 12px;
  width: 100%;
  span {
    font-size: 12px;
    color: #ced2d6;
  }
  .bottom-text {
    font-size: 12px;
  }
  .apy-rate {
    border: 1px solid #4984d3;
    box-shadow: 0px 0px 10px rgba(73, 132, 211, 0.25);
  }
  .dropdown-item {
    position: absolute;
    top: 100%;
    left: 0;
    width: calc(38% - 6px);
    height: 200px;
    overflow: auto;
    /* border: 1px solid #3A4C61; */
    z-index: 122;
    background-color: #101926;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #2a3540;
    }

    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }

    ul {
      list-style: none;
      padding: 0px;
      text-align: center;
      margin: 0px;
      .show {
        display: block;
      }
      .hide {
        display: none;
      }
      li {
        border: 1px solid #3a4c61;
        background-color: #101926;
        color: #fff;
        text-align: left;
        padding: 5px 10px;
        cursor: pointer;
        transition: all 0.2s linear;

        span {
          vertical-align: super;
          margin-left: 10px;
          font-size: 14px;
        }
        &:first-child {
          border-radius: 6px 6px 0px 0px;
          background: none;
          padding: 0;
        }
        &:last-child {
          border-radius: 0px 0px 6px 6px;
        }
        &:hover {
          background-color: #005ad3;
        }
        input {
          margin: 0;
          width: 100%;
          font-size: 15px;
          padding: 8px 15px;
          border: none;
          outline: none;
        }
        img {
          width: 25px;
          height: 25px;
        }
      }
    }
  }
  .hide {
    display: none;
  }
`;
export const ShowContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #465b6f;
  border-radius: 6px;
  padding: 7px 10px;
  width: 37%;
  margin-top: 10px;
  cursor: pointer;
  .wrapper-icon-name {
    img {
      width: 25px;
      height: 25px;
    }
    span {
      margin-left: 10px;
      font-size: 16px;
      vertical-align: super;
    }
  }
  span:nth-child(1) {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #ced2d6;
    img {
      margin-right: 10px;
      width: 28px;
      height: 28px;
    }
  }
`;

export const PriceInputWrapper = styled.div`
  width: 38%;

  input {
    width: 100%;
  }
`;

export const GasWrapper = styled.div`
  margin-top: 3px;
`;

export const GasHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;
export const GasHeader = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.bgColor};
  padding: 7px 10px;
  border-radius: 6px;
  margin-right: 1rem;
  cursor: pointer;
  span {
    color: #fff;
    font-size: 16px;
    img {
      width: 19px;
      height: 19px;
      margin-right: 8px;
      margin-top: 1px;
    }
  }
`;
export const GasPriceWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const GasPrice = styled.div`
  margin-right: 20px;

  .gas-title {
    color: #fff;
    font-size: 12px;
  }
  .price-container {
    width: 200px;
  }
  .dropdown-header {
    margin-top: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #465b6f;
    border-radius: 6px;
    color: #fff;
    padding: 10px;
    cursor: pointer;
  }
  .dropdown-list {
    position: relative;
  }
  .dropdown-item {
    position: absolute;
    top: 100%;
    left: 0;
    width: 200px;
    z-index: 122;
    background-color: #101926;
    ul {
      list-style: none;
      padding: 0px;
      text-align: center;
      margin: 0px;
      li {
        border: 1px solid #3a4c61;
        background-color: #101926;
        color: #fff;
        padding: 5px;
        cursor: pointer;
        transition: all 0.2s linear;
        &:first-child {
          border-radius: 6px 6px 0px 0px;
        }
        &:last-child {
          border-radius: 0px 0px 6px 6px;
        }
        &:hover {
          background-color: #005ad3;
        }
      }
    }
  }
  .show {
    display: block;
  }
  .hide {
    display: none;
  }
`;

export const GasInputWrapper = styled.div`
  margin-top: 15px;
  width: 38%;

  input {
    width: 100%;
    height: 40px;
  }
`;

export const ChooseAssets = styled.div`
  .assets-title {
    color: #fff;
    font-size: 12px;
  }
  .assets-container {
    width: 38%;
  }
  .dropdown-header {
    margin-top: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #465b6f;
    border-radius: 6px;
    color: #fff;
    padding: 8px;
    cursor: pointer;

    .asset-avatar {
      vertical-align: middle;
    }
    .asset-name {
      vertical-align: middle;
    }
    span {
      vertical-align: super;
      margin-left: 10px;
      font-size: 14px;
    }
  }
  .dropdown-list {
    position: relative;
  }
  .dropdown-item {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 200px;
    overflow: auto;
    /* border: 1px solid #3A4C61; */
    z-index: 122;
    background-color: #101926;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #2a3540;
    }

    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }

    ul {
      list-style: none;
      padding: 0px;
      text-align: center;
      margin: 0px;
      .show {
        display: block;
      }
      .hide {
        display: none;
      }
      li {
        border: 1px solid #3a4c61;
        background-color: #101926;
        color: #fff;
        text-align: left;
        padding: 5px 10px;
        cursor: pointer;
        transition: all 0.2s linear;

        span {
          vertical-align: super;
          margin-left: 10px;
          font-size: 14px;
        }
        &:first-child {
          border-radius: 6px 6px 0px 0px;
          background: none;
          padding: 0;
        }
        &:last-child {
          border-radius: 0px 0px 6px 6px;
        }
        &:hover {
          background-color: #005ad3;
        }
        input {
          margin: 0;
          width: 100%;
          font-size: 15px;
          padding: 8px 15px;
          border: none;
          outline: none;
        }
      }
    }
  }
  .show {
    display: block;
  }
  .hide {
    display: none;
  }
`;

export const LessThanWrapper = styled.div`
  border: 1px solid #4984d3;
  box-shadow: 0px 0px 10px rgba(73, 132, 211, 0.25);
  width: 200px;
  padding: 10px;
  border-radius: 6px;
  color: #fff;
`;
export const NewListingWrapper = styled.div`
  display: flex;
`;
export const Listing = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 75%;
  span {
    color: #fff;
  }
  .listing-token {
    display: flex;
    align-items: center;
    border: 1px solid #2a3540;
    justify-content: space-between;
    padding: 10px;
    border-radius: 6px;
    width: 200px;
    margin: 9px 10px 12px 12px;
  }
`;
export const VolumeContainer = styled.div`
  margin-top: 2px;
`;
export const VolumeContentWrapper = styled.div`
  margin-bottom: 12px;
  span {
    color: #fff;
    font-size: 12px;
  }
`;
export const VolumeButtonWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  &:nth-child(1) {
    border: none;
  }
`;
export const VolumeButton = styled.div`
  background-color: ${(props) => props.bgColor};
  padding: 8px 25px;
  color: #fff;
  border-radius: ${(props) => props.radius};
  cursor: pointer;
  font-size: 16px;
  border: 1px solid #465b6f;
`;
export const Percentage = styled.div`
  display: flex;
  flex-direction: column;
  span {
    cursor: pointer;
    &:nth-child(1) {
      margin-bottom: -10px;
      margin-top: -5px;
    }
    img {
      width: 11px !important;
    }
  }
`;
export const StyledDatePickerWrapper = styled.div`
  padding: 10px 0 20px 10px;
  background: #101926;
`;

export const DatePickersContainer = styled.div`
  display: flex;
`;

export const DateContainer = styled.div`
  margin-right: 10px;
  .date-custom-input {
    border: 1px solid #465b6f;
    border-radius: 6px;
    padding: 10px;
    background-color: transparent;
  }
`;
export const DateLable = styled.div`
  margin-bottom: 10px;
  span {
    color: #fff;
    font-size: 12px;
  }
`;
export const DatePickerInput = styled.div`
  position: relative;
  .DateInput {
    border: 1px solid #465b6f;
    border-radius: 6px;
    padding: 10px;
    background-color: transparent;
    cursor: pointer;
    color: #fff;
    /* border: 1px solid red; */
    &:focus {
      border: 1px solid #005ad3 !important;
      outline: none;
    }
  }
  .date-picker-icon {
    position: absolute;
    top: 21%;
    right: 10px;
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__day--weekend,
  .react-datepicker__day {
    background-color: #101926;
    box-shadow: 0px 0.646465px 0.646465px rgba(0, 14, 51, 0.05);
    border-radius: 3.87879px;
    color: #fff;
  }
  .react-datepicker {
    border: none;
    border-radius: 10.3434px;
    color: #fff;
  }
  .react-datepicker__day--selected {
    background: #005ad3;
  }
  .react-datepicker__month-container {
    background: #232e3a;
    border: none;
    .react-datepicker__header {
      background: #232e3a;
      color: #fff;
      border: 0px;
    }
    .react-datepicker__day-name {
      color: #fff;
    }
  }
  .react-datepicker__day--outside-month {
    color: #3a4c61;
    background: transparent;
  }
  .react-datepicker__current-month {
    color: #fff;
  }
`;

export const ChannelsContainer = styled.div`
  padding: 10px;
  background-color: #101926;
  .custom-checkbox {
    background: #2a3540;
    border-radius: 3px;
    border: 1px solid #465b6f;
  }
`;
export const SingleChannelWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
  .channel-name {
    cursor: pointer;
    color: #ced2d6;
    font-size: 18px;
  }
`;
export const HidenCheckBox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  cursor: pointer;
`;
export const CheckBox = styled.div`
  display: inline-block;
  width: 18px;
  height: 18px;
  background: ${(props) => props.bgColor};
  border-radius: 3px;
  border: 1px solid ${(props) => props.borderColor};
  transition: all 150ms;
  position: relative;
  cursor: pointer;
  z-index: 1;
  margin-right: 7px;
  z-index: 0;
`;
export const CheckBoxIcon = styled.div`
  position: absolute;
  top: -5px;
  left: 4px;
  cursor: pointer;
  z-index: 0;
`;
export const Label = styled.label`
  cursor: pointer;
  color: #ced2d6;
  font-size: 18px;
  display: flex;
  align-items: center;
`;
export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const InputFieldLabel = styled.label`
  color: white;
  font-weight: 400;
  margin-bottom: 0.5rem;
`;
export const InputField = styled.input`
  font-family: Montserrat;
  background: ${(props) => props.theme.bg};
  color: white;
  width: 50%;
  border: 1px solid #3a4c61;
  border-radius: 6px;
  padding: 5px 1rem;
  font-size: 20px;
  transition: all 150ms;
  :last-of-type {
    margin-bottom: 1rem;
  }
  :first-of-type {
    margin-bottom: 1rem;
  }
  ::placeholder {
    color: ${(props) => props.theme.input_placefolder};
    font-weight: 500;
  }
  :focus {
    outline: none;
    box-shadow: 0px 0px 10px 0px rgba(73, 132, 211, 0.25);
    border-color: #4984d3;
  }
`;
