import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { beautifyTokensAmount } from "../../helpers";
import { discoveryOperations } from "../../redux/discoverySlice";
import { Wrapper } from "./Livedata.styles";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

function Livedata() {
  SwiperCore.use([Autoplay]);

  const dispatch = useDispatch();
  const discovery = useSelector((state) => state.discovery);
  const { allTokens } = discovery;

  useEffect(() => {
    dispatch(discoveryOperations.getTokenList(1, 20));
  }, [dispatch]);

  return (
    <Wrapper>
      <Swiper
        className="live"
        autoplay={{
          delay: 3000,
        }}
        loop={true}
        slidesPerView="auto"
        initialSlide={4}
        loopedSlides={5}
        spaceBetween={24}
      >
        {allTokens.map((item) => (
          <SwiperSlide key={item.market_cap_rank} className="slides">
            <div className="token">
              <div className="slide_rank">{item.market_cap_rank}</div>
              <img src={item.icon} />
              <div className="symbol">{item.symbol.toUpperCase()}</div>
              <div className="price">{beautifyTokensAmount(item.current_price, { prefix: "$" })}</div>
              <div
                className={`token_price_change ${
                  item.price_change_percentage_24h_in_currency < 0 ? "token_price_negative" : ""
                }`}
              >
                {beautifyTokensAmount(item.price_change_percentage_24h_in_currency, { suffix: "%" })}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </Wrapper>
  );
}

export default Livedata;
