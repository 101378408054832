import React, { useMemo } from "react";

import { useSelector } from "react-redux";

import NegativeTopic from "../../assets/bearish-topic.svg";
import PositiveTopic from "../../assets/bullish-topic.svg";
import NeutralTopic from "../../assets/neutral-topic.svg";
import Feed from "./Feed";
import { FeedWrap, Wrapper } from "./Newsdashboard.styles";

const NEUTRAL = "Neutral";
const POSITIVE = "Positive";
const NEGATIVE = "Negative";

function Newsdashboard({ view, mobileScreen, setNewsOpen, newsOpen, readingList }) {
  const news = useSelector((state) => state.news);
  const { newsData, readingList: readingListData } = news;

  const topic = {
    [NEUTRAL]: NeutralTopic,
    [POSITIVE]: PositiveTopic,
    [NEGATIVE]: NegativeTopic,
  };

  const newsDataMemo = useMemo(() => {
    if (readingList && mobileScreen) return readingListData;
    return newsData;
  }, [newsData, mobileScreen, readingList]);

  return (
    <Wrapper view={view} newsOpen={newsOpen}>
      <FeedWrap view={view}>
        {newsDataMemo.length > 0 ? (
          newsDataMemo.map((item) => (
            <Feed
              key={item._id}
              view={view}
              setNewsOpen={setNewsOpen}
              item={item}
              mobileScreen={mobileScreen}
              icon={topic[item.sentiment]}
            />
          ))
        ) : (
          <>
            <p style={{ textAlign: "center", color: "#738ca6", margin: "0 auto" }}>Your list is empty</p>
          </>
        )}
      </FeedWrap>
    </Wrapper>
  );
}

export default Newsdashboard;
