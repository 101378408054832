import React from "react";

import BigNumber from "bignumber.js";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { beautifyTokensAmount, networkMainToken } from "../../helpers";

const Wrapper = styled.div`
  border-radius: 6px;
  width: 550px;
  z-index: 90;
  border: 1px solid ${(props) => props.theme.researchBorder};
  background: ${(props) => props.theme.secondaryBg};
  display: flex;
  position: absolute;
  top: 120%;
  right: 0;
  transform: translateX(50%);
  background-clip: padding-box;

  ::after {
    z-index: 9;
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 100%;
    height: 9px;
    width: 100%;
  }
  .item {
    width: 100%;
    position: relative;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    border-right: 1px solid ${(props) => props.theme.researchBorder};
    :last-child {
      border-right: 0;
    }
    .title {
      color: ${(props) => props.theme.mainDarkText};
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 4px;
    }

    .units {
      font-size: 14px;
      color: ${(props) => props.theme.lightGrey};
      margin-bottom: 3px;
    }

    .value {
      font-size: 14px;
      color: ${(props) => props.theme.form_text};
    }
    .color {
      position: absolute;
      top: 5px;
      left: 5px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }

    .green {
      background: ${(props) => props.theme.greenShade3};
    }
    .orange {
      background: #ffd700;
    }

    .red {
      background: #ff6400;
    }
  }
`;

const getGwei = (number) => BigNumber(number).shiftedBy(-9).toPrecision(6);

function GasCard() {
  const requiredGas = 152809;
  const wallet = useSelector((state) => state.wallet);
  const { network } = wallet;
  const user = useSelector((state) => state.user);
  const { gasData } = useSelector((state) => state.gas);

  const { t } = useTranslation();
  const getGasUSDPrice = (type) => {
    const priceOf1GasInETH = BigNumber(gasData[type]).shiftedBy(-18);
    const priceOfGasForETHInUsd = BigNumber(priceOf1GasInETH)
      .multipliedBy(requiredGas)
      .multipliedBy(user.usdPrices[networkMainToken[network.name]])
      .toFixed(2);

    if (isNaN(priceOfGasForETHInUsd)) return null;

    return priceOfGasForETHInUsd;
  };

  const USDPriceFastest = getGasUSDPrice("fast");
  const USDPriceAverage = getGasUSDPrice("average");
  const USDPriceSlow = getGasUSDPrice("slow");

  if (!("slow" in gasData)) {
    return <></>;
  }
  return (
    <Wrapper>
      <div className="item">
        <span className="color green"></span>
        <span className="title">{t("common.fastest")}</span>
        <span className="units">{!!gasData && `${getGwei(gasData.fast)} gwei`}</span>
        <span className="value">
          {!!USDPriceFastest && beautifyTokensAmount(USDPriceFastest, { prefix: "$", suffix: " USD" })}
        </span>
      </div>
      <div className="item">
        <span className="color orange"></span>
        <span className="title">{t("common.average")}</span>
        <span className="units">{!!gasData && `${getGwei(gasData.average)} gwei`}</span>
        <span className="value">
          {!!USDPriceAverage && beautifyTokensAmount(USDPriceAverage, { prefix: "$", suffix: " USD" })}
        </span>
      </div>

      <div className="item">
        <span className="color red"></span>
        <span className="title">{t("common.safeLow")}</span>
        <span className="units">{!!gasData && `${getGwei(gasData.slow)} gwei`}</span>
        <span className="value">
          {!!USDPriceSlow && beautifyTokensAmount(USDPriceSlow, { prefix: "$", suffix: " USD" })}
        </span>
      </div>
    </Wrapper>
  );
}

export default GasCard;
