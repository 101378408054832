import React, { Suspense, useEffect, useState } from "react";

import "tippy.js/dist/tippy.css"; // optional
import BigNumber from "bignumber.js";
import { useTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

import ArIcon from "../../assets/arabicFlag-big.svg";
import KeyfiLogo from "../../assets/Blue-only-logo.svg";
import KeyfiLogoText from "../../assets/Blue-only-text.svg";
import ChIcon from "../../assets/chinaFlag-big.svg";
import UkIcon from "../../assets/enFlag-big.svg";
import BSCIcon from "../../assets/header/BSC.svg";
import { ReactComponent as ChevronDownIcon } from "../../assets/header/chevron-down.svg";
import ETHIcon from "../../assets/header/ETH.svg";
import { ReactComponent as GasStationIcon } from "../../assets/header/gasStation.svg";
import { ReactComponent as GearIcon } from "../../assets/header/gear.svg";
import { ReactComponent as NewHamburgerMenuLogo } from "../../assets/header/hamburgerNew.svg";
import PolygonIcon from "../../assets/header/PolygonCircle.svg";
import { ReactComponent as TxQueueIcon } from "../../assets/header/txQueue.svg";
import { ReactComponent as UserIcon } from "../../assets/header/user.svg";
import JpIcon from "../../assets/japanFlag-big.svg";
import KrIcon from "../../assets/koreaFlag-big.svg";
import Arbitrum from "../../assets/platforms/arbitrum.svg";
import Aurora from "../../assets/platforms/Aurora.svg";
import Avalanche from "../../assets/platforms/avalanche.svg";
import Cronos from "../../assets/platforms/cronos.svg";
import Fantom from "../../assets/platforms/fantom.svg";
import Metis from "../../assets/platforms/Metis.svg";
import Moonbeam from "../../assets/platforms/Moonbeam.png";
import Moonriver from "../../assets/platforms/moonriver.svg";
import OKEx from "../../assets/platforms/OEC.svg";
import Optimism from "../../assets/platforms/optimism.svg";
import Telos from "../../assets/platforms/telos.svg";
import RuIcon from "../../assets/russianFlag-big.svg";
import Pro from "../../assets/sidebar/PRO.svg";
import TrIcon from "../../assets/turkishFlag-big.svg";
import { useWallet } from "../../helpers/useWallet";
import { getGasPrices } from "../../redux/gasPrices/actions";
import { getRates } from "../../redux/rates/actions";
import { gasOperations } from "../../redux/Slices/gasSlice";
import { closeTransactionManager, showTransactionManager } from "../../redux/transactionManager/actions";
import { setCurrentUser } from "../../redux/user/actions";
import { stackBalanceActions } from "../../redux/wallets/stackBalanceSlice";
import { walletOperations } from "../../redux/walletSlice";
import ConnectedWallet from "../ConnectedWallet";
import GasCard from "../Header/GasCard";
import NetworkCard from "../Header/NetworkCard";
import SettingsCard from "../Header/SettingsCard";
import StacksButtons from "../Header/StacksButtons";
import TXQueueCard from "../Header/TXQueueCard";
import UserCard from "../Header/UserCard";
import Modal from "../Modal";
import WalletModalContent from "../WalletModalContent";
import { DesktopHeader, StyledLogoWrapper, StyledMobileMenu } from "./Header.styles";

const NetworkStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  background: ${(props) => props.bg};
  color: ${(props) => props.color};
  border-radius: 6px;
  font-size: 12px;
  width: fit-content;

  img {
    height: 15px;
    width: 15px;
  }
`;

const languageData = {
  en: {
    title: "English",
    logo: UkIcon,
  },
  zh: {
    title: "中文",
    logo: ChIcon,
  },
  jp: {
    title: "日本語",
    logo: JpIcon,
  },
  kr: {
    title: "한국인",
    logo: KrIcon,
  },
  ru: {
    title: "русский",
    logo: RuIcon,
  },
  tr: {
    title: "Arapça",
    logo: TrIcon,
  },
  ar: {
    title: "عربي",
    logo: ArIcon,
  },
  vi: {
    title: "Việt Nam",
    logo: ArIcon,
  },
  pt: {
    title: "Portuguesa",
    logo: ArIcon,
  },
  pl: {
    title: "język polski",
    logo: ArIcon,
  },
};

const networkData = {
  mainnet: {
    name: "Ethereum",
    color: "#3A4C61",
    img: ETHIcon,
  },
  "bsc-mainnet": {
    name: "BSC",
    color: "#FFC700",
    img: BSCIcon,
  },
  polygon: {
    name: "Polygon",
    color: "#8247E5",
    img: PolygonIcon,
  },
  cronos: {
    name: "Cronos",
    img: Cronos,
  },
  fantom: {
    name: "Fantom",
    img: Fantom,
  },
  avalanche: {
    name: "Avalanche",
    img: Avalanche,
  },
  optimism: {
    name: "Optimism",
    img: Optimism,
  },
  arbitrum: {
    name: "Arbitrum",
    img: Arbitrum,
  },
  metis: {
    name: "Metis",
    img: Metis,
  },
  moonriver: {
    name: "Moonriver",
    img: Moonriver,
  },
  moonbeam: {
    name: "Moonbeam",
    img: Moonbeam,
  },
  okex: {
    name: "OKC",
    img: OKEx,
  },
  telos: {
    name: "Telos",
    img: Telos,
  },
  aurora: {
    name: "Aurora",
    img: Aurora,
  },
};

const NetworkStatus = ({ networkName, mobile }) => {
  const { t } = useTranslation();
  if (networkData[networkName]) {
    return (
      <NetworkStatusWrapper
        mobile={mobile}
        bg={networkData[networkName].color}
        color={networkData[networkName].textColor}
      >
        <Suspense fallback={<div></div>}>
          {typeof networkData[networkName].img === "string" ? (
            <img src={networkData[networkName].img} alt={networkName} />
          ) : (
            networkData[networkName].img
          )}
        </Suspense>
      </NetworkStatusWrapper>
    );
  }
  return (
    <NetworkStatusWrapper mobile bg="#EB5757" color="white">
      {t("common.unsupportNetwork")}
    </NetworkStatusWrapper>
  );
};

const Header = ({
  handleToggleSidebar,
  isMobile,
  handleLanguage,
  handleShowModal,
  handleCloseModal,
  showModal,
  getRates,
  getGasPrices,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const wallet = useWallet();

  const [requestChangeWallet, setRequestChangeWallet] = useState(false);
  const [appOpen, setAppOpen] = useState(true);

  const [showUserTooltip, setShowUserTooltip] = useState(false);
  const [showSettingsTooltip, setShowSettingsTooltip] = useState(false);
  const [showTxQ, setShowTxQ] = useState(false);
  const [showGasFee, setShowGasFee] = useState(false);
  const [showNetworkList, setShowNetworkList] = useState(false);

  const addresses = useSelector((state) => state.addresses);

  const walletRedux = useSelector((state) => state.wallet);
  const { network: networkRedux, web3 } = walletRedux;

  const user = useSelector((state) => state.user);
  const { readOnly } = user;

  const gas = useSelector((state) => state.gas);
  const { gasData } = gas;

  useEffect(() => {
    if (web3) {
      dispatch(gasOperations.getGasData());
    }
  }, [web3]);

  // Every 30 sec refresh gas data
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(gasOperations.getGasData());
    }, 1000 * 30);

    return () => clearInterval(interval);
  }, [dispatch]);

  // Checks if the website is active in the browser
  useEffect(() => {
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        setAppOpen(true);
      } else {
        setAppOpen(false);
      }
    });
  }, []);

  useEffect(() => {
    try {
      const interval = setInterval(() => {
        if (appOpen) {
          // getRates();
          // getGasPrices();
          dispatch(walletOperations.loadUserData());
        }
      }, 1000 * 60 * 5);

      return () => clearInterval(interval);
    } catch (error) {
      console.log(error);
    }
  }, [appOpen, getRates, getGasPrices, dispatch]);

  const handleRequestChangeWallet = () => {
    setRequestChangeWallet(true);
  };

  const onConnectWallet = async (providerId) => {
    try {
      setRequestChangeWallet(false);
      dispatch(stackBalanceActions.setShowStack(false));
      dispatch(walletOperations.connect(providerId));
      handleCloseModal();
    } catch (err) {
      console.log(err);
    }
  };
  const onReadOnlyWallet = async (address) => {
    try {
      setRequestChangeWallet(false);
      handleCloseModal();
      dispatch(walletOperations.connectReadOnly(address));
    } catch (err) {
      console.log(err);
    }
  };

  const handleDisconnect = () => {
    setRequestChangeWallet(false);
    dispatch(walletOperations.disconnect());
    setRequestChangeWallet(true);
  };

  const onClickOutsideUserListener = () => {
    setShowUserTooltip(false);
    document.removeEventListener("click", onClickOutsideUserListener);
  };

  const onClickOutsideGasListener = () => {
    setShowGasFee(false);
    document.removeEventListener("click", onClickOutsideGasListener);
  };

  const onClickOutsideSettingsListener = () => {
    setShowSettingsTooltip(false);
    document.removeEventListener("click", onClickOutsideSettingsListener);
  };
  const onClickOutsideTXQListener = () => {
    setShowTxQ(false);
    document.removeEventListener("click", onClickOutsideTXQListener);
  };

  const onClickOutsideNetwork = () => {
    setShowNetworkList(false);
    document.removeEventListener("click", onClickOutsideNetwork);
  };

  return (
    <>
      {isMobile ? (
        <>
          <StyledMobileMenu>
            {networkRedux && networkRedux.name ? <NetworkStatus mobile networkName={networkRedux.name} /> : <div />}

            <Link to="/dashboard">
              <StyledLogoWrapper>
                <img src={KeyfiLogo} alt="KeyFi" height="45px" width="45px" />
                <img src={KeyfiLogoText} alt="KeyFi Text" height="32px" width="100px" />
                <img src={Pro} alt="KeyFi PRO" height="17px" width="44px" />
              </StyledLogoWrapper>
            </Link>
            <NewHamburgerMenuLogo onClick={handleToggleSidebar} />
          </StyledMobileMenu>
          {addresses.addresses.length !== 0 && (
            <StacksButtons
              handleShowModal={handleShowModal}
              handleDisconnect={handleDisconnect}
              languageData={languageData}
              openLanguage={handleLanguage}
            />
          )}
        </>
      ) : (
        <DesktopHeader>
          {readOnly && (
            <div className="desktopheader_readonly">
              <div className="readonly_circle">
                <div className="readonly_insidecircle" />
              </div>
              Read-only mode
            </div>
          )}

          <div className="desktop_header_end">
            {networkRedux.name && (
              <div
                className={`icon-wrapper ${showNetworkList ? "icon-open" : ""}`}
                onMouseEnter={() => setShowNetworkList(true)}
                onMouseLeave={() => {
                  if (showNetworkList) {
                    setShowNetworkList(false);
                    document.addEventListener("click", onClickOutsideNetwork);
                  }
                }}
              >
                {networkRedux.name !== "unknown" &&
                  networkData[networkRedux.name] &&
                  (typeof networkData[networkRedux.name].img === "string" ? (
                    <img height="24px" width="24px" src={networkData[networkRedux.name].img} alt={networkRedux.name} />
                  ) : (
                    networkData[networkRedux.name].img
                  ))}
                {networkRedux.name === "unknown" && "Unsupported Network"}
                {networkRedux.name !== "unknown" && networkData[networkRedux.name].name}
                <ChevronDownIcon className="chev-down" />
                {showNetworkList && (
                  <NetworkCard
                    handleShowModal={handleShowModal}
                    handleDisconnect={handleDisconnect}
                    languageData={languageData}
                    openLanguage={handleLanguage}
                  />
                )}
              </div>
            )}

            <div
              className={`icon-wrapper ${showTxQ ? "icon-open" : ""}`}
              onMouseEnter={() => setShowTxQ(true)}
              onMouseLeave={() => {
                if (showTxQ) {
                  setShowTxQ(false);
                  document.addEventListener("click", onClickOutsideTXQListener);
                }
              }}
            >
              <TxQueueIcon />
              {showTxQ && <TXQueueCard />}
            </div>
            <div
              className={`icon-wrapper ${showGasFee ? "icon-open" : ""}`}
              onMouseEnter={() => setShowGasFee(true)}
              onMouseLeave={() => {
                if (showGasFee) {
                  setShowGasFee(false);
                  document.addEventListener("click", onClickOutsideGasListener);
                }
              }}
            >
              <Suspense fallback={""}>
                <GasStationIcon />
              </Suspense>
              {!!gasData.average && (
                <span className="gas-price"> {BigNumber(gasData.average).shiftedBy(-9).toPrecision(2)}</span>
              )}
              {showGasFee && <GasCard />}
              <ChevronDownIcon className="chev-down" />
            </div>
            <StacksButtons
              handleShowModal={handleShowModal}
              handleDisconnect={handleDisconnect}
              languageData={languageData}
              openLanguage={handleLanguage}
            />
            <div
              className={`icon-wrapper ${showSettingsTooltip ? "icon-open" : ""}`}
              onMouseEnter={() => setShowSettingsTooltip(true)}
              onMouseLeave={() => {
                if (showSettingsTooltip) {
                  setShowSettingsTooltip(false);
                  document.addEventListener("click", onClickOutsideSettingsListener);
                }
              }}
            >
              <GearIcon />
              <ChevronDownIcon className="chev-down" />
              {showSettingsTooltip && <SettingsCard languageData={languageData} openLanguage={handleLanguage} />}
            </div>
            <div
              className={`icon-wrapper ${showUserTooltip ? "icon-open" : ""}`}
              onMouseEnter={() => setShowUserTooltip(true)}
              onMouseLeave={() => {
                if (showUserTooltip) {
                  setShowUserTooltip(false);
                  document.addEventListener("click", onClickOutsideUserListener);
                }
              }}
            >
              <UserIcon />
              <ChevronDownIcon className="chev-down" />
              {showUserTooltip && <UserCard />}
            </div>
          </div>
        </DesktopHeader>
      )}

      <Modal
        title={wallet.address ? t("common.youAreConnectWith") : t("dashboard.connectWallet")}
        open={showModal}
        HandleShowModal={handleShowModal}
        handleCloseModal={handleCloseModal}
        width="100%"
        maxwidth="740px"
      >
        {wallet.address && !requestChangeWallet ? (
          <ConnectedWallet
            wallet={wallet.providerId}
            userAddress={wallet.address}
            handleChangeWallet={handleRequestChangeWallet}
            onDisconnect={handleDisconnect}
          />
        ) : (
          <WalletModalContent handleReadOnly={onReadOnlyWallet} handleWalletSelect={onConnectWallet} />
        )}
      </Modal>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  showTransactionManager: () => dispatch(showTransactionManager()),
  closeTransactionManager: () => dispatch(closeTransactionManager()),
  getRates: () => dispatch(getRates()),
  getGasPrices: () => dispatch(getGasPrices()),
});

export default connect(mapDispatchToProps)(Header);
