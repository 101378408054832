import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import Checked from "../assets/checkedCircle.svg";
import { ReactComponent as Close } from "../assets/closeModal.svg";
import GoogleAuth from "../assets/Google_Authenticator.svg";
import Button from "../Components/Button";
import Modal from "../Components/Modal";
import GoogleAuthModal from "../Components/User/GoogleAuthModal";
import PasswordChecklist from "../Components/User/PasswordChecklist";
import UserInputField from "../Components/User/UserInputField";
import { userDataOperations } from "../redux/userDataSlice";

const Wrapper = styled.div`
  max-width: 1250px;
  width: 100%;
  padding: 0 1.25rem 3rem;
  margin: 0 auto;

  .user_container {
    position: relative;
    max-width: 650px;
    width: 100%;
    h1 {
      color: ${(props) => props.theme.default_color};
      margin-bottom: 2rem;
    }
    .user_info_container {
      display: flex;
      margin-bottom: 2.5rem;
    }
    .user_input_field {
      max-width: 250px;
    }
  }
  .user_label {
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 0.5rem;
    color: ${(props) => props.theme.lightDarkGrey};
  }
`;

const UserImageSection = styled.div`
  margin-right: 2rem;
  .user_image {
    height: 80px;
    width: 80px;
    background: #c4c4c4;
    border-radius: 50%;
    margin-bottom: 1rem;
  }
  .user_image_button {
    cursor: pointer;
    width: 100%;
    border: 1px solid ${(props) => props.theme.form_text};
    border-radius: 6px;
    background: none;
    padding: 7px 0;
    font-size: 14px;
    color: ${(props) => props.theme.default_color};
    font-weight: 500;
  }
`;

const UserNameSection = styled.div`
  margin-right: 1.5rem;
  flex: 1;
`;

const UserAboutSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  textarea {
    color: ${(props) => props.theme.default_color};
    font-family: Montserrat;
    font-weight: 500;
    font-size: 16px;
    padding: 0.75rem;
    background: none;
    border: 1px solid ${(props) => props.theme.input_placefolder};
    border-radius: 6px;
    min-height: 80px;
    width: 100%;
    resize: none;
    outline: none;
    transition: all 0.15s;
    ::placeholder {
      color: ${(props) => props.theme.lightDarkGrey};
    }
    :focus {
      border: 1px solid ${(props) => props.theme.userInputFocus};
      box-shadow: ${(props) => props.theme.userInputFocusShadow};
    }
  }
`;

const UserInfoPasswords = styled.div`
  margin-bottom: 2.5rem;
  h2 {
    color: ${(props) => props.theme.default_color};
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0.75rem;
  }
  .user_passwords_inputs {
    display: flex;
    flex-direction: column;
  }
`;

const TwoFactorAuth = styled.div`
  padding: 2rem 0;
  border-top: 1px solid ${(props) => props.theme.researchBorder};
  border-bottom: 1px solid ${(props) => props.theme.researchBorder};
  h2 {
    color: ${(props) => props.theme.default_color};
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0.5rem;
    span {
      font-size: 14px;
      color: ${(props) => props.theme.error};
    }
  }
  p {
    font-weight: 500;
    color: ${(props) => props.theme.lightDarkGrey};
    margin-bottom: 1.5rem;
    font-size: 12px;
  }
  .twofactor_auth_option {
    color: ${(props) => props.theme.default_color};
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 341px;
    margin-left: 0.75rem;
    img {
      margin-right: 0.5rem;
    }
    div {
      flex: 1;
      font-weight: 500;
    }
    button {
      cursor: pointer;
      border: 1px solid ${(props) => props.theme.form_text};
      border-radius: 6px;
      background: none;
      padding: 7px 19px;
      font-size: 14px;
      color: ${(props) => props.theme.default_color};
      font-weight: 500;
      ${(props) =>
        props.approved &&
        css`
          background: ${(props) => props.theme.form_content};
          border-color: ${(props) => props.theme.pagination_border};
        `}
    }
  }
`;

const AccountSection = styled.div`
  h2 {
    margin: 2rem 0 0.75rem;
    font-weight: 500;
    font-size: 20px;
    color: ${(props) => props.theme.default_color};
  }
  div {
    font-weight: 500;
    color: ${(props) => props.theme.lightDarkGrey};
  }
`;

const ImageUploadModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  .image_upload_picture {
    height: 140px;
    width: 140px;
    border-radius: 50%;
    background: #c4c4c4;
  }
  .image_upload_button {
    margin-top: 1rem;
    cursor: pointer;
    border: 1px solid ${(props) => props.theme.form_text};
    border-radius: 6px;
    background: none;
    padding: 7px 0.75rem;
    font-size: 14px;
    color: ${(props) => props.theme.lightDarkGrey};
  }
  .image_upload_save {
    display: flex;
    margin-top: 2rem;
    width: 100%;
    ${Button} {
      width: 100%;
      height: max-content;
      font-size: 16px;
      padding: 10px 0;
      line-height: normal;
    }
    .image_upload_cancel {
      background: none;
      color: ${(props) => props.theme.lightDarkGrey};
    }
  }
`;

const SaveButtonSection = styled.div`
  margin-top: 2.5rem;
  display: flex;
  border-top: 1px solid ${(props) => props.theme.input_placefolder};
  padding-top: 2.5rem;
  ${Button} {
    width: 100%;
    max-width: 200px;
    height: max-content;
    font-size: 16px;
  }
  .save_buttonNoBg {
    background: none;
    color: ${(props) => props.theme.default_color};
  }
`;

const SuccessMessage = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 0.75rem 1rem;
  border-radius: 6px;
  top: -6rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 500px;
  background: ${(props) => props.theme.userUpdateSuccess};
  color: black;
  box-shadow: 0px 0px 110px 0px #000000d6;
  z-index: 10;
  animation: moveMessage 0.5s forwards, moveMessageAway 0.5s forwards 3s;
  img {
    margin-right: 10px;
  }
  div {
    flex: 1;
  }
  svg {
    cursor: pointer;
    margin-right: 10px;
    path {
      stroke: black;
    }
  }
  @keyframes moveMessage {
    0% {
      top: -6rem;
    }
    50% {
      top: 3rem;
    }
    100% {
      top: 2rem;
    }
  }
  @keyframes moveMessageAway {
    0% {
      top: 2rem;
    }
    50% {
      top: 3rem;
    }
    100% {
      top: -6rem;
    }
  }
`;

const User = ({ history }) => {
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.userData);
  const { user, updateSuccess, error } = userData;

  const [showModal, setShowModal] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [inputData, setInputData] = useState({
    displayName: user.displayName,
    userName: user.userName,
    about: user.about,
    inputsChanged: false,
  });
  const [pwData, setPwData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [newPWFocus, setNewPWFocus] = useState(false);

  const handleInputChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value, inputsChanged: true });
  };

  const handlePWChange = (e) => {
    setPwData({ ...pwData, [e.target.name]: e.target.value });
  };

  const handleUserUpdate = () => {
    if (inputData.inputsChanged) {
      dispatch(userDataOperations.updateProfile(inputData));
    }
  };

  const handlePasswordReset = () => {
    dispatch(userDataOperations.resetPasswordProfile(pwData));
    setShowMessage(true);
    setPwData({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  const handleAuthDisable = () => {
    dispatch(userDataOperations.disableAuth());
  };
  const { t } = useTranslation();
  return (
    <Wrapper>
      <div className="user_container">
        {updateSuccess && !error && (
          <SuccessMessage show={showMessage}>
            <img src={Checked} alt="Update successful" />
            <div>{t("auth.profileUpdated")}</div>
            <Close onClick={() => setShowMessage(false)} />
          </SuccessMessage>
        )}

        <h1>{t("auth.myProAccount")}</h1>
        <div className="user_info_container">
          <UserImageSection>
            <div className="user_image" />
            <button onClick={() => setShowModal(true)} className="user_image_button">
              {t("auth.upload")}
            </button>
          </UserImageSection>
          <UserNameSection>
            <div className="user_label">{t("auth.displayName")}</div>
            <UserInputField
              value={inputData.displayName}
              name="displayName"
              onChange={handleInputChange}
              onBlur={handleUserUpdate}
            />
            <div className="user_label">{t("auth.username")}</div>
            <UserInputField
              value={inputData.userName}
              name="userName"
              onChange={handleInputChange}
              onBlur={handleUserUpdate}
            />
            <UserAboutSection>
              <div className="user_label">{t("auth.about")}</div>
              <textarea
                autoCorrect="off"
                placeholder="About"
                name="about"
                value={inputData.about}
                onChange={handleInputChange}
                onBlur={handleUserUpdate}
              />
            </UserAboutSection>
          </UserNameSection>
        </div>
        <UserInfoPasswords>
          <h2>{t("auth.resetPassword")}</h2>
          <div className="user_passwords_inputs">
            <div className="user_label">{t("auth.oldPassword")}</div>
            <UserInputField type="password" value={pwData.oldPassword} name="oldPassword" onChange={handlePWChange} />
            <div className="user_label">{t("auth.newPassword")}</div>
            <UserInputField
              showEye
              onFocus={() => setNewPWFocus(true)}
              onBlur={() => setNewPWFocus(false)}
              type="password"
              value={pwData.newPassword}
              name="newPassword"
              onChange={handlePWChange}
            />
            <div className="user_label">{t("auth.confirmPassword")}</div>
            <UserInputField
              type="password"
              value={pwData.confirmPassword}
              name="confirmPassword"
              onChange={handlePWChange}
            />
          </div>
          {(newPWFocus || pwData.newPassword) && <PasswordChecklist password={pwData.newPassword} />}
        </UserInfoPasswords>
        <TwoFactorAuth approved={user.authentication.approved}>
          <h2>
            {t("auth.twoFactorAuth")} {!user.authentication.approved && <span>({t("auth.disable")})</span>}
          </h2>
          <p>{t("auth.twoFactorAuthText")}</p>
          <div className="twofactor_auth_option">
            <img src={GoogleAuth} alt="Google Authenticator" />
            <div>{t("auth.googleAuth")}</div>
            <button onClick={user.authentication.approved ? handleAuthDisable : () => setShowAuthModal(true)}>
              {user.authentication.approved ? "Disable" : "Setup"}
            </button>
          </div>
        </TwoFactorAuth>
        <AccountSection>
          <h2>{t("auth.account")}</h2>
          <div>{t("auth.trialPro")}</div>
        </AccountSection>
        <SaveButtonSection>
          <Button primary disabled={!pwData.oldPassword} onClick={handlePasswordReset}>
            {t("dashboard.widget.save")}
          </Button>
          <Button className="save_buttonNoBg" primary onClick={() => history.push("/dashboard")}>
            {t("dashboard.widget.cancel")}
          </Button>
        </SaveButtonSection>
      </div>
      {showModal && (
        <Modal title="Profile picture" handleCloseModal={() => setShowModal(false)}>
          <ImageUploadModal>
            <div className="image_upload_picture"></div>
            <button className="image_upload_button">{t("auth.change")}</button>
            <div className="image_upload_save">
              <Button primary>{t("dashboard.widget.save")}</Button>
              <Button className="image_upload_cancel" onClick={() => setShowModal(false)}>
                {t("dashboard.widget.cancel")}
              </Button>
            </div>
          </ImageUploadModal>
        </Modal>
      )}
      {showAuthModal && <GoogleAuthModal openModal={setShowAuthModal} />}
    </Wrapper>
  );
};

export default User;
