import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { alertsOperations } from "./alertsSlice";

const API = process.env.REACT_APP_PRO_API;

const initialState = {
  isLoading: false,
  notificationsList: [],
};

const notifications = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setIsLoading(state, action) {
      return { ...state, isLoading: action.payload };
    },
    setNotificationsList(state, action) {
      return { ...state, notificationsList: action.payload };
    },
  },
});

export const notificationsActions = notifications.actions;

const getRoot = (state) => state.notifications;

export const notificationsSelectors = {
  getLoading: (state) => getRoot(state).isLoading,
  getNotificationsList: (state) => getRoot(state).notificationsList,
};

export const notificationsOperations = {
  fetchNotifications: () => async (dispatch, getState) => {
    try {
      const state = getState();
      const token = state.userData.tokens.access.token;

      const config = {
        headers: {
          Authorization: token,
        },
      };

      const { data } = await axios.get(`${API}/v1/alert/triggered`, config);

      const notificationList = notificationsSelectors.getNotificationsList(getState());
      if (data.length !== 0) {
        dispatch(alertsOperations.getAlerts());
      }
      if (notificationList.length !== 0) {
        const ids = new Set(data.map((d) => d._id));
        const merged = [...notificationList, ...data.filter((d) => !ids.has(d._id))];
        return dispatch(notificationsActions.setNotificationsList(merged));
      }
      return dispatch(notificationsActions.setNotificationsList(data));
    } catch (err) {
      console.log(err);
    }
  },
  removeNotification: (id) => async (dispatch, getState) => {
    try {
      const state = getState();
      const token = state.userData.tokens.access.token;

      const config = {
        headers: {
          Authorization: token,
        },
      };

      await axios.patch(`${API}/v1/alert/seen/${id}`, {}, config);

      const notificationsList = [...notificationsSelectors.getNotificationsList(getState())];
      const newList = notificationsList.filter((item) => item._id !== id);
      dispatch(notificationsActions.setNotificationsList(newList));
    } catch (err) {
      console.log(err);
    }
  },
};

export const notificationsReducer = notifications.reducer;
