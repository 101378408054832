import styled, { css } from "styled-components";

import { breakPoints } from "../../Theme";

export const DepositWrapper = styled.div`
  max-width: 620px;
  width: 100%;
  margin: 0 auto;
  h1 {
    font-size: 24px;
    margin-bottom: 1.5rem;
    @media (max-width: ${breakPoints.md}px) {
      display: none;
    }
  }
`;

export const DepositContainer = styled.div`
  background: ${(props) => props.theme.secondaryBg};
  padding: 1.5rem;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.researchBorder};
  .deposit_header {
    padding-bottom: 1.5rem;
    border-bottom: 1px solid ${(props) => props.theme.researchBorder};
    .deposit_description {
      cursor: pointer;
      display: flex;
      margin-bottom: 1.5rem;
      padding: 1rem;
      background: ${(props) => props.theme.disabled};
      border-radius: 6px;
      p {
        flex: 1;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      svg {
        height: 18px;
      }
    }
    h4 {
      font-size: 12px;
      color: ${(props) => props.theme.lightGrey};
      margin-bottom: 0.5rem;
    }
    .deposit_platform_dropdown {
      width: 100%;
      max-width: 200px;
      font-size: 16px;
      padding: 7px 0.75rem;
      .dropdown_selected {
        input {
          line-height: 28px;
        }
      }

      img {
        height: 28px;
        width: 28px;
      }
    }
  }
`;

export const DepositMain = styled.div`
  padding: 1.5rem 0;
  .deposit_data {
    margin-bottom: 1.25rem;
    font-size: 12px;
    span {
      margin-left: 0.5rem;
      font-size: 16px;
      color: ${(props) => props.theme.form_text};
    }
  }
  .deposit_tokens {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.25rem;
    .deposit_token {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 0.5rem;
      border: 1px solid ${(props) => props.theme.researchBorder};
      border-radius: 6px;

      img,
      svg {
        height: 16px;
        width: 16px;
        margin-right: 6px;
      }
      span {
        font-size: 12px;
      }
      .deposit_token_title {
        margin-right: 1rem;
      }
      .deposit_token_amount {
        color: ${(props) => props.theme.form_text};
      }
      :hover {
        background: ${(props) => props.theme.placeholderText};
        .deposit_token_amount {
          color: white;
        }
      }
    }
  }
`;

export const DepositInputContainer = styled.div`
  .deposit_input {
    display: grid;
    grid-template-columns: 6fr 3fr;
    margin-bottom: 1.5rem;
    column-gap: 0.75rem;
    .deposit_input_data {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      color: ${(props) => props.theme.lightGrey};
      margin-bottom: 6px;
    }
    .deposit_input_error {
      color: ${(props) => props.theme.error};
      font-size: 12px;
    }
    > button {
      background: ${(props) => props.theme.brandBlue};
      padding: 0.75rem 0;
      border: 1px solid ${(props) => props.theme.brandBlue};
      border-radius: 6px;
      font-size: 16px;
      color: white;
      :hover {
        background: ${(props) => props.theme.darkBrandBlue};
        border-color: ${(props) => props.theme.darkBrandBlue};
      }
      :disabled {
        pointer-events: none;
        background: ${(props) => props.theme.disabledText};
        color: ${(props) => props.theme.disabled};
        border-color: ${(props) => props.theme.disabledText};
      }
    }
    @media (max-width: ${breakPoints.lg}px) {
      display: flex;
      flex-direction: column;
      .deposit_input_error {
        display: none;
      }
      > button {
        padding: 0.5rem 0;
      }
    }
  }
`;

export const DepositInput = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: ${(props) => props.theme.bg};
  border: 1px solid ${(props) => props.theme.researchBorder};
  border-radius: 6px;
  padding: 0.5rem 0.75rem;
  :focus-within {
    border-color: ${(props) => props.theme.brandBlue};
    box-shadow: 0px 0px 6px 0px #005ad340;
  }
  ${(props) =>
    props.error &&
    css`
      border-color: ${props.theme.error} !important;
      input {
        color: ${props.theme.error};
      }
    `}
  input {
    width: 100%;
  }
  button {
    padding: 0.25rem 1rem;
    font-size: 14px;
    line-height: 18px;
    border: 1px solid ${(props) => props.theme.researchBorder};
    border-radius: 6px;
    background: ${(props) => props.theme.secondaryBg};
    color: ${(props) => props.theme.mainDarkText};
  }
  @media (max-width: ${breakPoints.lg}px) {
    margin-bottom: 0.75rem;
  }
`;

export const VersionButtons = styled.div`
  display: flex;
  margin-top: 1.5rem;
  > button {
    background: 0;
    color: ${(props) => props.theme.mainDarkText};
    padding: 0.5rem 2rem;
    margin-right: -1px;
    font-size: 12px;
    border: 1px solid ${(props) => props.theme.researchBorder};
    :first-of-type {
      border-radius: 6px 0 0 6px;
    }
    :last-of-type {
      border-radius: 0 6px 6px 0;
    }
  }
  .deposit_version_active {
    border-color: ${(props) => props.theme.brandBlue};
    background: ${(props) => props.theme.blueTransparent};
    color: white;
    z-index: 1;
  }
`;
