import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as ActiveUnvote } from "../../assets/active-unvote-arrow.svg";
import { ReactComponent as ActiveVote } from "../../assets/active-vote-arrow.svg";
import { ReactComponent as Externallink } from "../../assets/external-link.svg";
import { ReactComponent as InactiveUnvote } from "../../assets/inactive-unvote-arrow.svg";
import { ReactComponent as InactiveVote } from "../../assets/inactive-vote-arrow.svg";
import { newsOperations } from "../../redux/Slices/newsSlice";
import Modal from "../Modal";
import { CommentFeed } from "./Comment";
import { ContentWrap, PopupWrap } from "./Feed.styles";
import { getTimeFromNow } from "./news.helpers";

const PopUpNews = ({ handleOpen, itemData, setItemData, setNumOfComment }) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData);
  const { user } = userData;

  const news = useSelector((state) => state.news);
  const { readingList } = news;

  const mydate = new Date(itemData.date);
  const postedTime = getTimeFromNow(mydate.getTime());

  const inReadingList = readingList.findIndex((item) => itemData._id === item._id) > -1;
  const numVote = itemData.vote.length;
  const numUnvote = itemData.downvote.length;
  const voted = itemData.vote.includes(user.id);
  const unvoted = itemData.downvote.includes(user.id);

  const handleAddReadingList = async () => {
    const inReadingList = readingList.findIndex((item) => itemData._id === item._id) > -1;
    if (!inReadingList) {
      dispatch(newsOperations.addReadingList(itemData));
      return;
    }
    dispatch(newsOperations.removeReadingList(itemData));
  };

  const handleVoteNews = async () => {
    try {
      dispatch(newsOperations.vote(itemData.title));

      // If user has voted - remove vote
      if (voted) {
        const filteredVote = itemData.vote.filter((vote) => vote !== user.id);
        setItemData((prev) => ({ ...prev, vote: filteredVote }));
        return;
      }

      setItemData((prev) => ({ ...prev, vote: [...prev.vote, user.id] }));

      // If user has unvoted - remove unvoted
      if (unvoted) {
        const filteredUnvote = itemData.downvote.filter((vote) => vote !== user.id);
        setItemData((prev) => ({ ...prev, downvote: filteredUnvote }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleUnvoteNews = async () => {
    try {
      dispatch(newsOperations.unvote(itemData.title));

      // If user has unvoted - remove unvoted
      if (unvoted) {
        const filteredUnvote = itemData.downvote.filter((vote) => vote !== user.id);
        setItemData((prev) => ({ ...prev, downvote: filteredUnvote }));
        return;
      }
      setItemData((prev) => ({ ...prev, downvote: [...prev.downvote, user.id] }));

      // If user has voted - remove vote
      if (voted) {
        const filteredVote = itemData.vote.filter((vote) => vote !== user.id);
        setItemData((prev) => ({ ...prev, vote: filteredVote }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal handleCloseModal={handleOpen} maxwidth="64em" noBorder>
      <PopupWrap>
        <div className="left">
          <div className="cover">
            <p className="coverText">{itemData.title}</p>
            <img src={itemData.image_url} />
          </div>

          <ContentWrap>
            <div className="btn">
              <a href={itemData.news_url} target="_blank" rel="noreferrer">
                Read Now <Externallink className="svg" />
              </a>
              <button onClick={handleAddReadingList}>{inReadingList ? "Reading list -" : "Reading list +"}</button>
            </div>
            <div className="vote">
              {voted ? (
                <div className="up" onClick={handleVoteNews}>
                  <ActiveVote className="voteIcon" />
                  <p className="active">{numVote}</p>
                </div>
              ) : (
                <div className="up" onClick={handleVoteNews}>
                  <InactiveVote className="voteIcon" />
                  <p className="inactive">{numVote}</p>
                </div>
              )}

              {unvoted ? (
                <div className="down" onClick={handleUnvoteNews}>
                  <ActiveUnvote className="voteIcon" />
                  <p className="activeUnvote">{numUnvote}</p>
                </div>
              ) : (
                <div className="down" onClick={handleUnvoteNews}>
                  <InactiveUnvote className="voteIcon" />
                  <p className="inactive">{numUnvote}</p>
                </div>
              )}
            </div>

            <div className="info">
              <p className="down">
                {postedTime} <span> | </span> {itemData.source_name}
              </p>
            </div>

            <p className="content">{itemData.text}</p>

            <div className="tickets">
              {itemData.tickers.map((tickets) => (
                <>
                  <p key={tickets} className="symbol">
                    {tickets}
                  </p>
                  <span></span>
                </>
              ))}
            </div>
          </ContentWrap>
        </div>
        <CommentFeed item={itemData} setNumOfComment={setNumOfComment} />
      </PopupWrap>
    </Modal>
  );
};

export default PopUpNews;
