import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const API = process.env.REACT_APP_PRO_API;

const initialState = {
  isLoading: false,
  newsData: [],
  readingList: [],
  vote: [],
  unvote: [],
};

const news = createSlice({
  name: "news",
  initialState,
  reducers: {
    setLoading(state, action) {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
    setNews(state, action) {
      return {
        ...state,
        newsData: action.payload,
      };
    },
    setReadingList(state, action) {
      return {
        ...state,
        readingList: action.payload,
      };
    },
    setVote(state, action) {
      return {
        ...state,
        vote: action.payload,
      };
    },
    setUnvote(state, action) {
      return {
        ...state,
        unvote: action.payload,
      };
    },
    setComment(state, action) {
      return {
        ...state,
        comment: action.payload,
      };
    },
  },
});

export const newsActions = news.actions;

const getRoot = (state) => state.wallet;

export const newsSelectors = {
  getLoading: (state) => getRoot(state).isLoading,
  getNews: (state) => getRoot(state).news,
};

export const newsOperations = {
  loadAllNews: () => async (dispatch, getState) => {
    const isLoading = newsSelectors.getLoading(getState());
    if (isLoading) {
      return;
    }
    dispatch(newsActions.setLoading(true));
    try {
      const config = {
        headers: {
          Authorization: getState().userData.tokens.access.token,
        },
      };
      const { data } = await axios.get(`${API}/v1/news`, config);
      dispatch(newsActions.setNews(data));
      dispatch(newsActions.setLoading(false));
      return data;
    } catch (err) {
      console.log(err);
      dispatch(newsActions.setLoading(false));
      return;
    }
  },

  addNewsList: (newsData) => async (dispatch, getState) => {
    dispatch(newsActions.setLoading(true));
    try {
      const url = `${API}/v1/news/`;
      const config = {
        headers: {
          Authorization: getState().userData.tokens.access.token,
        },
      };
      await axios.post(url, newsData, config);
      dispatch(newsActions.setLoading(false));
      return;
    } catch (err) {
      console.log(err);
      dispatch(newsActions.setLoading(false));
      return;
    }
  },

  getNewsList: () => async (dispatch, getState) => {
    dispatch(newsActions.setLoading(true));
    try {
      const url = `${API}/v1/news/newList`;
      const config = {
        headers: {
          Authorization: getState().userData.tokens.access.token,
        },
      };
      const { data } = await axios.get(url, config);
      dispatch(newsActions.setNews(data));
      dispatch(newsActions.setLoading(false));
      return;
    } catch (err) {
      console.log(err);
      dispatch(newsActions.setLoading(false));
      return;
    }
  },

  searchNewsList: (filter) => async (dispatch, getState) => {
    dispatch(newsActions.setLoading(true));
    try {
      const url = `${API}/v1/news/search-news`;
      const config = {
        headers: {
          Authorization: getState().userData.tokens.access.token,
        },
      };
      const { data } = await axios.post(url, { filter: filter }, config);
      dispatch(newsActions.setNews(data));
      dispatch(newsActions.setLoading(false));
      return;
    } catch (err) {
      console.log(err);
      dispatch(newsActions.setLoading(false));
      return;
    }
  },

  sortingNews: (filter) => async (dispatch, getState) => {
    dispatch(newsActions.setLoading(true));
    try {
      const url = `${API}/v1/news/sort-news`;
      const config = {
        headers: {
          Authorization: getState().userData.tokens.access.token,
        },
      };
      const { data } = await axios.post(url, { filter: filter }, config);
      dispatch(newsActions.setNews(data));
      dispatch(newsActions.setLoading(false));
      return;
    } catch (err) {
      console.log(err);
      dispatch(newsActions.setLoading(false));
      return;
    }
  },

  addReadingList: (newsData) => async (dispatch, getState) => {
    dispatch(newsActions.setLoading(true));
    try {
      const url = `${API}/v1/news/add-reading`;
      const config = {
        headers: {
          Authorization: getState().userData.tokens.access.token,
        },
      };
      const { data } = await axios.post(url, newsData, config);
      dispatch(newsOperations.getReadingList());
      dispatch(newsActions.setLoading(false));
      return data;
    } catch (err) {
      console.log(err);
      dispatch(newsActions.setLoading(false));
      return;
    }
  },

  removeReadingList: (newsData) => async (dispatch, getState) => {
    dispatch(newsActions.setLoading(true));
    try {
      const url = `${API}/v1/news/delete-reading`;
      const config = {
        headers: {
          Authorization: getState().userData.tokens.access.token,
        },
      };

      const filteredList = [...getState().news.readingList].filter((item) => item._id !== newsData._id);
      dispatch(newsActions.setReadingList(filteredList));

      const { data } = await axios.post(url, newsData, config);
      dispatch(newsActions.setLoading(false));
      return data;
    } catch (err) {
      console.log(err);
      dispatch(newsActions.setLoading(false));
      return;
    }
  },

  getReadingList: () => async (dispatch, getState) => {
    dispatch(newsActions.setLoading(true));
    try {
      const url = `${API}/v1/news/all-reading`;

      const config = {
        headers: {
          Authorization: getState().userData.tokens.access.token,
        },
      };

      const { data } = await axios.get(url, config);
      dispatch(newsActions.setReadingList(data));
      dispatch(newsActions.setLoading(false));
      return data;
    } catch (err) {
      console.log(err);
      dispatch(newsActions.setLoading(false));
      return;
    }
  },

  vote: (newsData) => async (dispatch, getState) => {
    dispatch(newsActions.setLoading(true));
    try {
      const url = `${API}/v1/news/vote`;
      const config = {
        headers: {
          Authorization: getState().userData.tokens.access.token,
        },
      };
      await axios.post(url, { title: newsData }, config);
      dispatch(newsActions.setLoading(false));
      return;
    } catch (err) {
      console.log(err);
      dispatch(newsActions.setLoading(false));
      return;
    }
  },

  getVote: () => async (dispatch, getState) => {
    dispatch(newsActions.setLoading(true));
    try {
      const url = `${API}/v1/news/get-vote`;
      const config = {
        headers: {
          Authorization: getState().userData.tokens.access.token,
        },
      };
      const { data } = await axios.get(url, config);
      dispatch(newsActions.setVote(data));
      dispatch(newsActions.setLoading(false));
      return data;
    } catch (err) {
      console.log(err);
      dispatch(newsActions.setLoading(false));
      return;
    }
  },

  unvote: (newsData) => async (dispatch, getState) => {
    dispatch(newsActions.setLoading(true));
    try {
      const url = `${API}/v1/news/unvote`;
      const config = {
        headers: {
          Authorization: getState().userData.tokens.access.token,
        },
      };
      await axios.post(url, { title: newsData }, config);
      dispatch(newsActions.setLoading(false));
      return;
    } catch (err) {
      console.log(err);
      dispatch(newsActions.setLoading(false));
      return;
    }
  },

  getUnvote: () => async (dispatch, getState) => {
    dispatch(newsActions.setLoading(true));
    try {
      const url = `${API}/v1/news/unvote`;
      const config = {
        headers: {
          Authorization: getState().userData.tokens.access.token,
        },
      };
      const { data } = await axios.get(url, config);
      dispatch(newsActions.setUnvote(data));
      dispatch(newsActions.setLoading(false));
      return data;
    } catch (err) {
      console.log(err);
      dispatch(newsActions.setLoading(false));
      return;
    }
  },

  addComment: (newsId, comment) => async (dispatch, getState) => {
    dispatch(newsActions.setLoading(true));
    try {
      const url = `${API}/v1/news/add-comment`;
      const config = {
        headers: {
          Authorization: getState().userData.tokens.access.token,
        },
      };
      if (!comment.replyTo) comment = { text: comment.text };
      const { data } = await axios.post(url, { newsId, comment }, config);

      let newsList = [...getState().news.newsData];
      newsList = newsList.map((x) => (x._id === data._id ? data : x));

      let readingList = [...getState().news.readingList];
      readingList = readingList.map((x) => (x._id === data._id ? data : x));
      console.log(readingList);

      dispatch(newsActions.setNews(newsList));
      dispatch(newsActions.setReadingList(readingList));
      dispatch(newsActions.setLoading(false));
      return;
    } catch (err) {
      console.log(err);
      dispatch(newsActions.setLoading(false));
      return;
    }
  },

  getAllComment: (id) => async (dispatch, getState) => {
    dispatch(newsActions.setLoading(true));
    try {
      const url = `${API}/v1/news/get-all-comments`;
      const config = {
        headers: {
          Authorization: getState().userData.tokens.access.token,
        },
      };
      const { data } = await axios.post(url, { id: id }, config);
      dispatch(newsActions.setComment(data));
      dispatch(newsActions.setLoading(false));
      return data;
    } catch (err) {
      console.log(err);
      dispatch(newsActions.setLoading(false));
      return;
    }
  },

  voteComment: (id) => async (dispatch, getState) => {
    dispatch(newsActions.setLoading(true));
    try {
      const url = `${API}/v1/news/vote-comment`;
      const config = {
        headers: {
          Authorization: getState().userData.tokens.access.token,
        },
      };
      const { data } = await axios.post(url, { id }, config);

      const newsList = [...getState().news.newsData].map((x) => (x._id === data._id ? data : x));
      dispatch(newsActions.setNews(newsList));
      dispatch(newsActions.setLoading(false));
      return;
    } catch (err) {
      console.log(err);
      dispatch(newsActions.setLoading(false));
      return;
    }
  },

  downvoteComment: (id) => async (dispatch, getState) => {
    dispatch(newsActions.setLoading(true));
    try {
      const url = `${API}/v1/news/downvote-comment`;
      const config = {
        headers: {
          Authorization: getState().userData.tokens.access.token,
        },
      };
      const { data } = await axios.post(url, { id }, config);
      const newsList = [...getState().news.newsData].map((x) => (x._id === data._id ? data : x));
      dispatch(newsActions.setNews(newsList));
      dispatch(newsActions.setLoading(false));
      return;
    } catch (err) {
      console.log(err);
      dispatch(newsActions.setLoading(false));
      return;
    }
  },
};

export const newsReducer = news.reducer;
