import React, { useState } from "react";

import { WalletProviderId } from "@keyfi/keyfi-common";
import styled from "styled-components";

import { walletsLogos } from "../helpers";
import { breakPoints } from "../Theme";

const Wrapper = styled.div`
  h3 {
    font-weight: 500;
    margin: 1.5rem 0 1rem 0;
    color: ${(props) => props.theme.mainDarkText};
  }
  .walletlist_divider {
    height: 1px;
    width: 100%;
    background: ${(props) => props.theme.researchBorder};
  }
  .walletlist_track {
    padding-bottom: 2rem;
    .walletlist_track_label {
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 0.5rem;
      color: ${(props) => props.theme.lightGrey};
    }
    .walletlist_track_container {
      display: flex;
      input {
        font-family: Montserrat;
        width: 100%;
        background: ${(props) => props.theme.bg};
        margin-right: 1rem;
        padding: 0.75rem 10px;
        font-size: 16px;
        border-radius: 6px;
        outline: none;
        border: 1px solid ${(props) => props.theme.researchBorder};
        ::placeholder {
          font-family: Montserrat;
          color: ${(props) => props.theme.placeholderText};
        }
      }
      @media (max-width: ${breakPoints.lg}px) {
        flex-direction: column;
        input {
          margin-bottom: 1rem;
          font-size: 12px;
        }
      }
    }
  }
`;

export const ListItemsWrapper = styled.div`
  margin: 1rem 0 1rem 0;

  .listItem {
    color: ${(props) => props.theme.default_color};
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    height: 72px;
    padding: 1rem;
    display: flex;
    align-items: center;
    border-radius: 4px;
    svg,
    img {
      margin-right: 8px;
    }
    cursor: pointer;
  }
  .listItem:hover {
    background: ${(props) => props.theme.bg};
  }
`;

const StyledButton = styled.button`
  padding: 0.75rem 2.5rem;
  background: ${(props) => props.theme.brandBlue};
  border-radius: 6px;
  border: 0;
  color: white;
  :hover {
    background: ${(props) => props.theme.darkBrandBlue};
  }
`;

export default function WalletsList({ handleWalletSelect, handleReadOnly }) {
  const [input, setInput] = useState("");
  return (
    <Wrapper>
      <ListItemsWrapper noMarginTop>
        <div className="listItem" onClick={() => handleWalletSelect(WalletProviderId.Metamask)}>
          {walletsLogos.Metamask}
          MetaMask
        </div>

        <div className="listItem" onClick={() => handleWalletSelect(WalletProviderId.WalletConnect)}>
          {walletsLogos.WalletConnect}
          WalletConnect
        </div>

        <div className="listItem" onClick={() => handleWalletSelect(WalletProviderId.SelfKey)}>
          {walletsLogos.SelfKey}
          SelfKey
        </div>
      </ListItemsWrapper>
      <div className="walletlist_divider" />
      <h3>Track Portfolio</h3>
      <div className="walletlist_track">
        <div className="walletlist_track_label">Read-only portfolio tracking</div>
        <div className="walletlist_track_container">
          <input
            placeholder="eg. 0x640Fdf0FF52Ee61E8bebcaED718572B1385f4052"
            maxLength="42"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            autoCorrect="false"
            spellCheck="false"
          />
          <StyledButton onClick={() => handleReadOnly(input)}>Connect</StyledButton>
        </div>
      </div>
    </Wrapper>
  );
}
