import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { stackBalanceOperations } from "./stackBalanceSlice";

const API = process.env.REACT_APP_PRO_API;

const initialState = {
  isLoading: false,
  stacks: [],
};

const stacks = createSlice({
  name: "stacks",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setStacks(state, action) {
      state.stacks = action.payload;
    },
  },
});

export const stacksActions = stacks.actions;

const getRoot = (state) => state.stacks;

export const stacksSelectors = {
  getLoading: (state) => getRoot(state).isLoading,
  getStacks: (state) => getRoot(state).stacks,
};

export const stacksOperations = {
  fetchUserStacks: (init) => async (dispatch, getState) => {
    try {
      dispatch(stacksActions.setLoading(true));
      const token = getState().userData.tokens.access.token;

      const config = {
        headers: {
          Authorization: token,
        },
      };
      const { data } = await axios.get(`${API}/v1/wallet/stack`, config);
      dispatch(stacksActions.setStacks(data));
      dispatch(stacksActions.setLoading(false));
      if (init) dispatch(stackBalanceOperations.initFetch());
    } catch (err) {
      console.log(err);
      dispatch(stacksActions.setLoading(false));
    }
  },
  getActiveStack: (id) => (dispatch, getState) => {
    const stacks = stacksSelectors.getStacks(getState());
    const activeStack = stacks.find((item) => item._id === id);
    return activeStack ?? undefined;
  },
};

export const stacksReducer = stacks.reducer;
