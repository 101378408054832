import React from "react";

import { integrations } from "@keyfi/keyfi-common";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import BorrowTable from "../../Components/Borrow/BorrowTable";
import MyBorrows from "../../Components/Borrow/MyBorrows";
import Button from "../../Components/Button";
import Meta from "../../Components/Meta";
import { switchToETH } from "../../helpers";
import { BorrowWrapper, ChangeNetworkMessage } from "./Borrow.styles";

const Borrow = () => {
  const { t } = useTranslation();

  const wallet = useSelector((state) => state.wallet);
  const { network } = wallet;

  const handleNetworkChange = async () => {
    switchToETH();
    integrations.resetNetwork();
  };

  return (
    <BorrowWrapper>
      <Meta title="Borrow" />
      <h1>{t("borrow.title")}</h1>
      {network && network.chainId === 1 ? (
        <>
          <MyBorrows />
          <BorrowTable />
        </>
      ) : (
        <ChangeNetworkMessage>
          {t("borrow.shopportOnEthOnly")}
          <Button primary onClick={handleNetworkChange}>
            {t("borrow.switchToEth")}
          </Button>
        </ChangeNetworkMessage>
      )}
    </BorrowWrapper>
  );
};

export default Borrow;
