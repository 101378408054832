import { combineReducers } from "redux";

import { alertsReducer } from "./alertsSlice";
import { appReducer } from "./appSlice";
import billingReducer from "./billing/reducers";
import { borrowReducer } from "./borrowSlice";
import { createAlertReducer } from "./createAlertSlice";
import { discoveryReducer } from "./discoverySlice";
import { notificationsReducer } from "./notificationsSlice";
import ratesReducer from "./rates/reducers";
import { chartWidgetReducer } from "./Slices/chartWidgetSlice";
import { gasReducer } from "./Slices/gasSlice";
import { marketAnalysisReducer } from "./Slices/marketAnalysisSlice";
import { newsReducer } from "./Slices/newsSlice";
import { recommendationReducer } from "./Slices/recommendationSlice";
import { sentimentReducer } from "./Slices/sentimentSlice";
import { strategyActionReducer } from "./strategyActionsSlice";
import { strategyGroupReducer } from "./strategyGroupSlice";
import { strategyReducer } from "./strategySlice";
import transactionReducer from "./transactionManager/reducers";
import { txQueueReducer } from "./txQueueSlice";
import userReducer from "./user/reducers";
import { userDataReducer } from "./userDataSlice";
import { addAddressesReducer } from "./wallets/addAddressesSlice";
import { addressesReducer } from "./wallets/addressesSlice";
import { createStackReducer } from "./wallets/createStackSlice";
import { editAddressReducer } from "./wallets/editAddressSlice";
import { editStackReducer } from "./wallets/editStackSlice";
import { stackBalanceReducer } from "./wallets/stackBalanceSlice";
import { stacksReducer } from "./wallets/stacksSlice";
import { walletReducer } from "./walletSlice";
import { dashboardWatchlistReducer } from "./watchlistDashboardSlice";
import { watchlistReducer } from "./watchlistSlice";

export default combineReducers({
  user: userReducer, // web3 user
  userData: userDataReducer, // User login info
  strategy: strategyReducer,
  strategyGroup: strategyGroupReducer,
  strategyAction: strategyActionReducer,
  txQueue: txQueueReducer,
  alerts: alertsReducer,
  createAlert: createAlertReducer,
  billing: billingReducer,
  discovery: discoveryReducer,
  watchlist: watchlistReducer,
  dashboardWatchlist: dashboardWatchlistReducer,
  borrow: borrowReducer,
  rates: ratesReducer,
  wallet: walletReducer,
  app: appReducer,
  transactions: transactionReducer,
  gas: gasReducer,
  news: newsReducer,
  marketAnalysis: marketAnalysisReducer,
  notifications: notificationsReducer,
  addAddresses: addAddressesReducer, // Add addresses for multiwallet
  addresses: addressesReducer, // User addresses
  editAddress: editAddressReducer, // Edit one of the addresses and update
  stacks: stacksReducer, // User stacks
  createStack: createStackReducer, // Create stack
  editStack: editStackReducer, // Edit one of the stacks and update
  stackBalance: stackBalanceReducer, // User's stack balance
  recommendation: recommendationReducer, // User recommendation tokens
  sentiment: sentimentReducer, // Sentiment dashboard widget data
  chartWidget: chartWidgetReducer, // Chart dashboard widget data
});
