import styled from "styled-components";

import { breakPoints } from "../../../Theme";
import Button from "../../Button";

export const MainBorrowTableWrapper = styled.div`
  background: ${(props) => props.theme.secondaryBg};
  border: 1px solid ${(props) => props.theme.researchBorder};
  border-radius: 6px;
  padding: 1.5rem;
  h2 {
    margin: 0 0 1rem;
    font-size: 20px;
  }
  .borrow_table_filter_container {
    display: flex;
    flex-wrap: wrap;
    .borrow_table_filter {
      margin-bottom: 1.5rem;
      margin-right: 1.5rem;
      .borrow_table_filter_label {
        font-size: 12px;
        color: ${(props) => props.theme.form_text};
        margin-bottom: 0.5rem;
      }
    }
    .borrow_table_filter_clear {
      cursor: pointer;
      display: flex;
      align-items: center;
      height: fit-content;
      margin-top: 23px;
      font-size: 12px;
      padding: 0.5rem;
      border-radius: 6px;
      border: 1px solid ${(props) => props.theme.researchBorder};
      background: 0;
      color: ${(props) => props.theme.mainDarkText};
      svg {
        height: 100%;
        width: 12px;
        margin-right: 0.75rem;
        path {
          stroke: ${(props) => props.theme.mainDarkText};
        }
      }
      :hover {
        background: ${(props) => props.theme.bg};
      }
    }
  }
  @media (max-width: ${breakPoints.md}px) {
    padding: 1.25rem 0.75rem;
  }
`;

export const BorrowTableWrapper = styled.table`
  border-collapse: collapse;
  display: table;
  width: 100%;
  @media (max-width: ${breakPoints.md}px) {
    display: none;
  }
  thead {
    text-align: left;
    tr {
      border-bottom: 1px solid ${(props) => props.theme.researchBorder};
      th {
        padding-bottom: 0.75rem;
        font-size: 12px;
        color: ${(props) => props.theme.form_text};
        cursor: pointer;
        > div {
          display: flex;
          align-items: center;
          > div {
            display: flex;
            flex-direction: column;
            margin-left: 0.4rem;
            svg {
              width: 10px;
              height: 8px;
              path {
                fill: ${(props) => props.theme.lightBlue3};
              }
            }
          }
        }
        :first-of-type {
          padding-left: 1rem;
        }
      }
    }
  }
  tbody {
    width: 100%;
    div {
      display: flex;
      align-items: center;
      .borrow_table_desktop_image,
      img {
        height: 20px;
        width: 20px;
        margin-right: 6px;
        border-radius: 50%;
      }
    }
    tr {
      cursor: pointer;
      :nth-child(2n) {
        background: ${(props) => props.theme.darkGrey};
      }
      td {
        :first-of-type {
          padding-left: 1rem;
        }
        :last-of-type {
          padding-right: 1rem;
        }
        height: 20px;
        padding: 14px 0;
        ${Button} {
          text-transform: capitalize;
          height: max-content;
          width: 100%;
          line-height: normal;
          padding: 6px 2rem;
          font-size: 16px;
        }
      }
      :hover {
        z-index: 10;
        background: ${(props) => props.theme.blueTransparent};
        box-shadow: inset 0px 0px 0px 1px ${(props) => props.theme.brandBlue};
      }
    }
  }
`;
