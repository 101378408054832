import React from "react";

import { Switch } from "./billing.style";

const ToggleButton = ({ onChange, className, onDisable, checked, name, width = 72, height = 36 }) => {
  return (
    <Switch className={className} disable={onDisable} width={width} height={height}>
      <input name={name} onChange={onChange} type="checkbox" checked={checked}></input>
      <span className="slider"></span>
    </Switch>
  );
};

export default ToggleButton;
