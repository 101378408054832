import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import { ReactComponent as InactiveUnvote } from "../../assets/inactive-unvote-arrow.svg";
import { ReactComponent as InactiveVote } from "../../assets/inactive-vote-arrow.svg";
import { ReactComponent as Upvote } from "../../assets/upvote.svg";
import { newsOperations } from "../../redux/Slices/newsSlice";
import { getTimeFromNow } from "./news.helpers";
import { CardWrap, VerticalLineBreak, Wrap } from "./Newsdashboard.styles";
import PopUpNews from "./PopUpNews";

const Feed = ({ setNewsOpen, mobileScreen, view, item, icon, setNumOfComment }) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [itemData, setItemData] = useState(item);

  useEffect(() => {
    setItemData(item);
  }, [item.comment]);

  const numVote = itemData.vote.length;
  const numUnvote = itemData.downvote.length;

  const mydate = new Date(itemData.date);
  const postedTime = getTimeFromNow(mydate.getTime());

  const handleOpen = async () => {
    dispatch(newsOperations.getAllComment(itemData._id));
    setOpen(!open);
    setNewsOpen(true);
  };

  const ListView = () => (
    <>
      {mobileScreen ? (
        <>
          <Wrap>
            <img src={icon} />
            <div className="info">
              <h5 onClick={handleOpen}>{itemData.title}</h5>
              <div className="sub-text">
  {/* <p>{postedTime}</p> */}
                <VerticalLineBreak margin="12px" />
                <p>{itemData.source_name}</p>
                <VerticalLineBreak margin="12px" />
                <p className="increase">
                  <Upvote />
                  {numVote}
                </p>
                <p className="decrease">
                  <Upvote style={{ transform: "rotate(180deg)" }} />
                  {numUnvote}
                </p>
              </div>
              <div className="tickets">
                {itemData.tickers.map((tickets) => (
                  <>
                    <p key={tickets} className="symbol">
                      {tickets}
                    </p>
                    <span></span>
                  </>
                ))}
              </div>
            </div>
          </Wrap>
        </>
      ) : (
        <>
          <Wrap>
            <img src={icon} />
            <div className="info">
              <h5 onClick={handleOpen}>{itemData.title}</h5>
              <div className="sub-text">
        {/* <p>{postedTime}</p> */}
                <VerticalLineBreak margin="12px" />
                <p>{itemData.source_name}</p>
                <VerticalLineBreak margin="12px" />
                <p className="increase">
                  <Upvote />
                  {numVote}
                </p>
                <p className="decrease">
                  <Upvote style={{ transform: "rotate(180deg)" }} />
                  {numUnvote}
                </p>
              </div>
            </div>
            <div className="tickets">
              {itemData.tickers.map((tickets) => (
                <>
                  <p key={tickets} className="symbol">
                    {tickets}
                  </p>
                  <span></span>
                </>
              ))}
            </div>
          </Wrap>
        </>
      )}
    </>
  );

  const CardView = () => (
    <CardWrap onClick={handleOpen}>
      <div className="cover">
        <div className="textWrap">
          <p className="coverText">{itemData.source_name}</p>
        </div>
        <img src={itemData.image_url} />
      </div>
      <div className="infoWrap">
        <div className="subText">
          <img className="topic" src={icon} />
    {/* <p>{postedTime}</p> */}
          <VerticalLineBreak margin="8px" height="15px" />

          <div className="vote">
            <p>
              <InactiveVote className="voteIcon" />
              {numVote}
            </p>
            <p className="decrease">
              <InactiveUnvote className="voteIcon" />
              {numUnvote}
            </p>
          </div>
        </div>
        <p className="title">{itemData.title}</p>
        <p className="text">{itemData.text}</p>
      </div>
    </CardWrap>
  );

  return (
    <>
      {open && (
        <PopUpNews
          key={item._id}
          handleOpen={handleOpen}
          itemData={itemData}
          setItemData={setItemData}
          setNumOfComment={setNumOfComment}
        />
      )}
      {!open && <>{view == "noun" ? <ListView /> : <CardView />}</>}
    </>
  );
};

export default Feed;
