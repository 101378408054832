import React from "react";

import Tippy from "@tippyjs/react/headless";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Tooltip = styled.div`
  background: #f4bb27;
  color: black;
  padding: 0.5rem 0.75rem;
  border-radius: 6px;
  font-size: 11px;
`;

const Arrow = styled.div`
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid #f4bb27;
`;

const WatchlistTooltip = ({ children, inWatchlist }) => {
  const { t } = useTranslation();
  return (
    <Tippy
      offset={[30, 10]}
      render={(attrs) => (
        <Tooltip tabIndex="-1" {...attrs}>
          {inWatchlist ? t("watchlist.removeFromWatchlist") : t("watchlist.addToWatchlist")}

          <Arrow data-popper-arrow="" />
        </Tooltip>
      )}
    >
      {children}
    </Tippy>
  );
};

export default WatchlistTooltip;
