import { createSlice } from "@reduxjs/toolkit";

// import { Storage, StorageKey } from '../helpers/Storage';
import { notificationsOperations } from "./notificationsSlice";
import { addressesOperations } from "./wallets/addressesSlice";
import { stacksOperations } from "./wallets/stacksSlice";
import { walletOperations } from "./walletSlice";

const initialState = {
  isLoading: true,
};

const app = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
});

export const appActions = app.actions;

const getRoot = (state) => state.app;

export const appSelectors = {
  getLoading: (state) => getRoot(state).isLoading,
};

export const appOperations = {
  initApplication: () => async (dispatch) => {
    dispatch(appActions.setLoading(true));
    // dispatch(getRates());
    // dispatch(getGasPrices());
    dispatch(stacksOperations.fetchUserStacks(true));
    dispatch(notificationsOperations.fetchNotifications());
    dispatch(addressesOperations.fetchUserAddresses());
    await dispatch(walletOperations.initialize());
  },
};

export const appReducer = app.reducer;
