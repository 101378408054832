import * as styled from "styled-components";

import { breakPoints } from "./index";

export const GlobalStyles = styled.createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    position: relative;
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 100vh;
    background: ${({ theme }) => theme.bg};
    color: ${({ theme }) => theme.mainDarkText};
    font-family: "Montserrat", sans-serif;
  }

  a {
    text-decoration: none;
  }

  svg {
    overflow: visible;
  }

  .tippy-box {
    outline: none !important;
    max-width: 400px !important;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  input,
  textarea {
    color: ${(props) => props.theme.mainDarkText};
    font-size: 16px;
    ::placeholder {
      color: ${(props) => props.theme.lightGrey};
    }
  }

  button {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 16px;

    &.primary {
      padding: 0.75rem 2.5rem;
      color: white;
      border-radius: 6px;

      background: ${(props) => props.theme.brandBlue};
      border: 1px solid ${(props) => props.theme.brandBlue};
      :hover {
        background: ${(props) => props.theme.darkBrandBlue};
        border-color: ${(props) => props.theme.darkBrandBlue};
      }
      :disabled {
        background: ${(props) => props.theme.disabledText};
        border-color: ${(props) => props.theme.disabledText};
        color: ${(props) => props.theme.disabled};
      }
    }
    &.secondary {
      padding: 0.75rem 2.5rem;
      color: white;
      border-radius: 6px;

      background: ${(props) => props.theme.secondaryBg};
      border: 1px solid ${(props) => props.theme.researchBorder};
      :hover {
        background: ${(props) => props.theme.secondaryBtnHover};
      }
    }
    &.error {
      padding: 0.75rem 2.5rem;
      color: white;
      border-radius: 6px;

      background: ${(props) => props.theme.error};
      border: 1px solid ${(props) => props.theme.error};
      :hover {
        background: ${(props) => props.theme.txError};
      }
    }
  }

  .keyfi_input {
    padding: 0.75rem;
    background: ${(props) => props.theme.bg};
    border: 1px solid ${(props) => props.theme.researchBorder};
    border-radius: 6px;
  }

  [data-reach-dialog-content] {
    @media (max-width: ${breakPoints.sm}px) {
      padding: 1rem;
    }
  }
`;
