import React, { useState } from "react";

import { getAddress, getAddressSum } from "@keyfi/keyfi-common/src/integrations";
import BigNumber from "bignumber.js";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { ReactComponent as ArrowDown } from "../../assets/arrowDown.svg";
import { ReactComponent as HideEyeIcon } from "../../assets/dashboard/hideEye.svg";
import { assetImageNetwork, beautifyTokensAmount, tokensLogos, trimAddress } from "../../helpers";
import { stringToColour } from "../../Pages/ManageWallets";
import { userDataOperations } from "../../redux/userDataSlice";
import { stackBalanceOperations } from "../../redux/wallets/stackBalanceSlice";
import { breakPoints } from "../../Theme";
import AssetImage from "../Borrow/AssetImage";
import { totalValue } from "./dashboard.helpers";

export const Wrapper = styled.div`
  max-height: 420px;
  height: 100%;
  max-width: 600px;
  .wallet_table_bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: right;
    margin-top: 10px;
    color: ${(props) => props.theme.lightDarkGrey};
    .wallet_table_modal {
      background: 0;
      border: 0;
      outline: 0;
      color: ${(props) => props.theme.form_text};
      text-decoration: underline;
      font-size: 14px;
    }
    .wallet_table_total {
      margin-left: auto;
      display: flex;
      align-items: center;
      span {
        margin-left: 0.25rem;
        font-weight: 600;
        color: ${(props) => props.theme.form_text};
        font-size: 20px;
      }
    }
  }
`;

export const NetworkLabelsWrapper = styled.div`
  position: relative;
  width: 0;
  min-width: 100%;
  margin-bottom: ${(props) => (props.walletData ? "1rem" : "2.25rem")};
  .network_label_scroll {
    position: absolute;
    color: white;
    transform: translateY(100%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .scroll_left {
    display: none;
    right: calc(100% + 6px);
    top: 0;
  }
  .scroll_right {
    left: calc(100% + 6px);
    top: 0;
  }
  .network_labels_scroll {
    display: flex;
    scroll-behavior: smooth;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    gap: 1rem;
    margin: 0 auto;
    min-height: 54px;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  .network_label {
    scroll-snap-align: start;
    cursor: pointer;
    display: flex;
    display: flex;
    padding: 0.5rem;
    width: 100%;
    max-width: max-content;
    border: 1px solid transparent;
    gap: 0.4rem;
    img {
      margin-top: -3px;
      height: 20px;
      width: 20px;
    }
    .network_label_info {
      .network_label_title {
        font-weight: 500;
        font-size: 12px;
        color: ${(props) => props.theme.lightDarkGrey};
        margin-bottom: 0.125rem;
      }
      .network_label_total {
        display: flex;
        align-items: flex-end;
        font-weight: 500;
        span {
          margin-left: 0.25rem;
          color: ${(props) => props.theme.greenShade};
          font-size: 11px;
          line-height: 15px;
        }
      }
    }
  }
  .network_active {
    background: ${(props) => props.theme.blueTransparent};
    border-color: ${(props) => props.theme.brandBlue};
    color: white;
    border-radius: 6px;
  }
  @media (max-width: ${breakPoints.lg}px) {
    .network_labels_scroll {
      gap: 1rem;
    }
    .network_label {
      padding: 5px 0.5rem;
      font-size: 13px;
      img {
        margin: 0;
      }
    }
  }
`;

export const WalletOptions = styled.div`
  margin: 1.75rem 0 1.25rem;
  display: flex;
  gap: 10px;
  .wallet_option {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 2rem;
    border: 1px solid ${(props) => props.theme.researchBorder};
    border-radius: 6px;
    font-size: 16px;
    background-color: ${(props) => props.theme.secondaryBg};
    color: ${(props) => props.theme.mainDarkText};
    @media (max-width: ${breakPoints.md}px) {
      padding: 0.5rem 0;
      width: 100%;
      text-align: center;
    }
  }

  .wallet_option:hover {
    background: #101926;
  }
  .wallet_active {
    color: white;
    background: ${(props) => props.theme.blueTransparent};
    border-color: ${(props) => props.theme.brandBlue};
  }
`;

export const WalletTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  display: table;
  th,
  td {
    text-align: left;
    :last-of-type {
      text-align: right;
    }
    ${(props) =>
      props.hideEye &&
      css`
        :nth-last-of-type(2) {
          text-align: right;
        }
      `}
    ${(props) =>
      props.showStack &&
      css`
        :nth-of-type(4n) {
          text-align: right;
        }
      `}
    ${(props) =>
      props.lp &&
      css`
        :last-of-type {
          width: 50%;
        }
        :nth-last-of-type(2) {
          text-align: left;
        }
      `}
  }
  thead {
    margin-bottom: 0.25rem;
    th {
      color: ${(props) => props.theme.lightDarkGrey};
      font-size: 12px;
      :first-of-type {
        padding-left: 0.5rem;
      }
      :last-of-type {
        padding-right: 0.5rem;
      }
      @media (max-width: ${breakPoints.lg}px) {
        :first-of-type {
          padding-left: 8px;
          width: 40%;
        }
      }
    }
  }

  > tbody {
    > tr {
      width: 100%;
      /* table-layout: fixed; */
      ${(props) =>
        !props.showStack &&
        css`
          :nth-of-type(2n) {
            background: ${(props) => props.theme.bg};
          }
        `}

      border-radius: 6px;
      td {
        padding: 0.75rem 0;

        :first-of-type,
        :last-of-type {
          padding: 0.75rem 0.5rem;
        }
        :first-of-type {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }
        :last-of-type {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
        .wallet_table_asset {
          display: flex;
          align-items: center;
          img,
          .table_image {
            margin-right: 0.5rem;
            height: 20px;
            width: 20px;
            border-radius: 50%;
          }
        }
        .stack_arrow {
          background: 0;
          padding: 0;
          border: 0;
          outline: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 15px;
          margin-left: auto;
          svg {
            height: 15px;
            width: 15px;
          }
        }
        .wallet_data_table_hide {
          display: flex;
          justify-content: flex-end;
          visibility: hidden;
          button {
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              cursor: pointer;
              height: 18px;
              width: 18px;
            }
          }
        }

        @media (max-width: ${breakPoints.lg}px) {
          padding: 0.5rem 0;
          font-size: 14px;
          :first-of-type,
          :last-of-type {
            padding: 0.5rem 0.5rem;
          }
          :first-of-type {
            width: 40%;
          }
          .wallet_table_asset {
            img,
            .table_image {
              height: 16px;
              width: 16px;
            }
          }
        }
      }
      :hover {
        .wallet_data_table_hide {
          visibility: visible;
        }
      }
    }
    .lp_table_row_odd {
      cursor: pointer;
    }
    .lp_table_row_even {
      cursor: pointer;
      background: ${(props) => (props.secondary ? props.theme.secondaryBg : props.theme.bg)} !important;
    }
    .lp_table_row_open {
      border-radius: 6px 6px 0 0;
    }
    .lp_row_details {
      padding: 0 9px;
      border-radius: 0 0 6px 6px;
    }
  }
  .wallet_no_mobile {
    @media (max-width: ${breakPoints.sm}px) {
      display: none;
    }
  }
`;

export const StyledLPTableCell = styled.td`
  font-size: 14px;
  > div {
    display: flex;
    justify-content: ${(props) => (props.spaceBetween ? "space-between" : "flex-start")};
    align-items: center;
    .lp_platform_table_icons {
      display: flex;
      img {
        height: 24px;
        width: 24px;

        z-index: 2;
        :last-of-type {
          z-index: 1;
          transform: translateX(-12px);
        }
      }
      margin-right: 4px;
    }
    .lp_platform_table_title {
      .lp_platform_table_amount {
        color: ${(props) => props.theme.form_text};
      }
    }
    .lp_platform_table_button {
      display: flex;
      align-items: center;
      color: ${(props) => props.theme.form_text};
      svg {
        height: 15px;
        path {
          fill: ${(props) => props.theme.form_text};
        }
      }
    }
  }
`;

export const LPDetails = styled.td`
  padding: 0 !important;
  * {
    text-align: start;
  }
  > div {
    margin: 0 9px;
    padding: 10px 0 1rem;
    border-top: 1px solid ${(props) => props.theme.researchBorder};
    .lp_details_token {
      display: flex;
      justify-content: space-between;
      :not(:last-of-type) {
        margin-bottom: 10px;
      }
      .lp_details_title {
        display: flex;
        align-items: center;
        img {
          height: 24px;
          width: 24px;
          margin-right: 6px;
        }
      }
    }
  }
`;

export const StackDataTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  .stack_address_line {
    :first-of-type {
      padding-top: 1rem;
      > td {
        border-top: 1px solid ${(props) => props.theme.researchBorder};
        padding-top: 1rem !important;
      }
    }
    .stack_address {
      > div {
        display: flex;
        align-items: center;
        gap: 6px;
        font-size: 12px;
        color: ${(props) => props.theme.lightGrey};
        .stack_address_icon {
          height: 12px;
          width: 12px;
          background: lightgrey;
          border-radius: 50%;
        }
        h4 {
          width: 100px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    > td {
      padding: 0 !important;
      padding-bottom: 1rem !important;
      :first-of-type,
      :last-of-type {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
      }

      :nth-of-type(3) {
        text-align: right;
      }
    }
  }
`;

export const LPPlatformRow = ({ item, usdPrices, index }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const value = () => {
    const assetA = BigNumber(item[item.assetA]).times(usdPrices[item.assetA]);
    const assetB = BigNumber(item[item.assetB]).times(usdPrices[item.assetB]);

    return beautifyTokensAmount(assetA.plus(assetB), { prefix: "$" });
  };

  return (
    <>
      <tr
        onClick={() => setOpen(!open)}
        className={`lp_table_row_${(index + 1) % 2 === 0 ? "even" : "odd"} ${open ? "lp_table_row_open" : ""}`}
      >
        <StyledLPTableCell>
          <div>
            <div className="lp_platform_table_icons">
              {tokensLogos[item.assetA]}
              {tokensLogos[item.assetB]}
            </div>
            <div className="lp_platform_table_title">
              <div className="lp_platform_table_token">
                {item.assetA}:{item.assetB}
              </div>
              <div className="lp_platform_table_amount">{beautifyTokensAmount(item.liquidity)}</div>
            </div>
          </div>
        </StyledLPTableCell>
        <StyledLPTableCell style={{ paddingLeft: "0" }} spaceBetween>
          <div>
            {value()}
            <div className="lp_platform_table_button">
              {t("common.moreDetails")} <ArrowDown />
            </div>
          </div>
        </StyledLPTableCell>
      </tr>
      {open && (
        <tr className={`lp_table_row_${(index + 1) % 2 === 0 ? "even" : "odd"} lp_row_details`}>
          <LPDetails colSpan="2">
            <div>
              <div className="lp_details_token">
                <div className="lp_details_title">
                  {tokensLogos[item.assetA]} {t("common.pooled")} {item.assetA}
                </div>
                <div>{beautifyTokensAmount(item[item.assetA])}</div>
              </div>
              <div className="lp_details_token">
                <div className="lp_details_title">
                  {tokensLogos[item.assetB]} {t("common.pooled")} {item.assetB}
                </div>
                <div>{beautifyTokensAmount(item[item.assetB])}</div>
              </div>
              <div className="lp_details_token">
                <div>{t("common.yourPoolShare")} </div>
                <div>
                  {beautifyTokensAmount(item.liquidityPercent * 100, {
                    suffix: "%",
                  })}
                </div>
              </div>
            </div>
          </LPDetails>
        </tr>
      )}
    </>
  );
};

const value = (item, user) => {
  const [assetA, assetB] = [item.token.split(":")[0], item.token.split(":")[1].split(" ")[0]];

  const assetAValue = BigNumber(item[assetA]).times(user.usdPrices[assetA]);
  const assetBValue = BigNumber(item[assetB]).times(user.usdPrices[assetB]);

  return assetAValue.plus(assetBValue);
};

const StackTokenRow = ({ token, network, user, index, staking, type }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const addresses = useSelector((state) => state.addresses.addresses);

  const getAddressObject = (address) => {
    const addressObject = addresses.find((item) => item.address.toLowerCase() === address.toLowerCase());
    if (addressObject) return addressObject;
    return undefined;
  };

  const extraData = dispatch(
    stackBalanceOperations.getSpecificToken(staking ? token.token : token.symbol, network, staking)
  );

  if (!staking) {
    return (
      <>
        <tr
          key={token.address}
          className={`lp_table_row_${(index + 1) % 2 === 0 ? "even" : "odd"} ${open ? "lp_table_row_open" : ""}`}
        >
          <td onClick={() => extraData.length > 1 && setOpen((prev) => !prev)}>
            <div className="wallet_table_asset">
              <AssetImage
                className="table_image"
                src={`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${
                  assetImageNetwork[network]
                }/assets/${getAddressSum(token.address)}/logo.png`}
                fallbackLogo={tokensLogos[token.symbol]}
                alt={token.symbol}
              />
              {token.symbol}
            </div>
          </td>
          <td onClick={() => extraData.length > 1 && setOpen((prev) => !prev)}>{beautifyTokensAmount(token.amount)}</td>
          <td onClick={() => extraData.length > 1 && setOpen((prev) => !prev)} className="wallet_no_mobile">
            {beautifyTokensAmount(user.usdPrices[token.symbol.toUpperCase()], { prefix: "$" })}
          </td>
          <td onClick={() => extraData.length > 1 && setOpen((prev) => !prev)} style={{ whiteSpace: "nowrap" }}>
            {beautifyTokensAmount(user.usdPrices[token.symbol.toUpperCase()] * token.amount, { prefix: "$" })}
          </td>
          <td onClick={() => extraData.length > 1 && setOpen((prev) => !prev)}>
            <div style={{ display: "flex" }}>
              <button style={{ display: extraData.length > 1 ? "block" : "none" }} className="stack_arrow">
                <ArrowDown style={{ transform: open ? "rotate(180deg)" : "" }} />
              </button>
            </div>
          </td>
          <td>
            {token.address && type === "tokens" && (
              <div className="wallet_data_table_hide">
                <button onClick={() => dispatch(userDataOperations.addToTokenBlacklist(token))}>
                  <HideEyeIcon />
                </button>
              </div>
            )}
          </td>
        </tr>
        {open && (
          <tr className={`lp_table_row_${(index + 1) % 2 === 0 ? "even" : "odd"} lp_row_details`}>
            <td style={{ borderRadius: "0" }} colSpan={6}>
              <StackDataTable>
                {extraData.map((item) => (
                  <tr key={`${item.address}_${item.userId}`} className="stack_address_line">
                    <td className="stack_address">
                      <div>
                        <div
                          className="stack_address_icon"
                          style={{ background: stringToColour(getAddressSum(item.userId)) }}
                        />
                        <h4 className="stack_address_title">
                          {getAddressObject(item.userId)
                            ? getAddressObject(item.userId).title ?? trimAddress(getAddressObject(item.userId).address)
                            : trimAddress(item.userId)}
                        </h4>
                      </div>
                    </td>
                    <td colSpan="2">{beautifyTokensAmount(item.amount)}</td>
                    <td>{beautifyTokensAmount(user.usdPrices[item.symbol] * item.amount, { prefix: "$" })}</td>
                    <td />
                  </tr>
                ))}
              </StackDataTable>
            </td>
          </tr>
        )}
      </>
    );
  }
  return (
    <>
      {token.type === "token" ? (
        <tr
          onClick={() => extraData.length > 1 && setOpen((prev) => !prev)}
          key={`${token.token}_${token.platform}`}
          className={`lp_table_row_${(index + 1) % 2 === 0 ? "even" : "odd"} ${open ? "lp_table_row_open" : ""}`}
        >
          <td>
            <div className="wallet_table_asset">
              <AssetImage
                className="table_image"
                src={`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${
                  network === "bsc-mainnet" ? "smartchain" : "ethereum"
                }/assets/${getAddress(token.token ?? "", network)}/logo.png`}
                fallbackLogo={tokensLogos[token.token]}
                alt={token.token}
              />
              {token.token}
            </div>
          </td>
          <td>{beautifyTokensAmount(token.amount)}</td>
          <td className="wallet_no_mobile">
            {beautifyTokensAmount(user.usdPrices[token.token.toUpperCase()], { prefix: "$" })}
          </td>
          <td style={{ whiteSpace: "nowrap" }}>
            {beautifyTokensAmount(user.usdPrices[token.token.toUpperCase()] * token.amount, { prefix: "$" })}
          </td>
          <td>
            <button
              style={{ display: extraData.length > 1 ? "block" : "none" }}
              className="stack_arrow"
              onClick={() => setOpen((prev) => !prev)}
            >
              <ArrowDown style={{ transform: open ? "rotate(180deg)" : "" }} />
            </button>
          </td>
        </tr>
      ) : (
        <tr
          onClick={() => extraData.length > 1 && setOpen((prev) => !prev)}
          key={`${token.token}_${token.platform}`}
          className={`lp_table_row_${(index + 1) % 2 === 0 ? "even" : "odd"} ${open ? "lp_table_row_open" : ""}`}
        >
          <td>
            <div className="wallet_table_asset">
              <AssetImage
                className="table_image"
                src={`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${
                  network === "bsc-mainnet" ? "smartchain" : "ethereum"
                }/assets/${getAddress(token.token ?? "", network)}/logo.png`}
                fallbackLogo={tokensLogos[token.token]}
                alt={token.token}
              />
              {token.token}
            </div>
          </td>
          <td>{beautifyTokensAmount(token.amount)}</td>
          <td className="wallet_no_mobile">
            {beautifyTokensAmount(value(token, user) / token.amount, { prefix: "$" })}
          </td>
          <td style={{ whiteSpace: "nowrap" }}>{beautifyTokensAmount(value(token, user), { prefix: "$" })}</td>
          <td>
            <button style={{ display: extraData.length > 1 ? "block" : "none" }} className="stack_arrow">
              <ArrowDown style={{ transform: open ? "rotate(180deg)" : "" }} />
            </button>
          </td>
        </tr>
      )}
      {open && (
        <tr className={`lp_table_row_${(index + 1) % 2 === 0 ? "even" : "odd"} lp_row_details`}>
          <td colSpan={6}>
            <StackDataTable>
              {extraData.map((item) => (
                <tr key={`${item.address}_${item.userId}`} className="stack_address_line">
                  <td className="stack_address">
                    <div>
                      <div
                        className="stack_address_icon"
                        style={{ background: stringToColour(getAddressSum(item.userId)) }}
                      />
                      {getAddressObject(item.userId)
                        ? getAddressObject(item.userId).title ?? trimAddress(getAddressObject(item.userId).address)
                        : trimAddress(item.userId)}
                    </div>
                  </td>
                  <td colSpan="2">{beautifyTokensAmount(item.amount)}</td>
                  {item.type === "lp" ? (
                    <td>{beautifyTokensAmount(value(item, user), { prefix: "$" })}</td>
                  ) : (
                    <td>{beautifyTokensAmount(user.usdPrices[item.token] * item.amount, { prefix: "$" })}</td>
                  )}
                  <td />
                </tr>
              ))}
            </StackDataTable>
          </td>
        </tr>
      )}
    </>
  );
};

export const TokenRows = ({ tokens, type, network }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const stackBalance = useSelector((state) => state.stackBalance);
  const { showStack } = stackBalance;

  if (tokens.length === 0) {
    return (
      <tr>
        <td style={{ textAlign: "left" }} colSpan="4">
          {t("common.noAsset")}
        </td>
      </tr>
    );
  }
  if (type === "lp") {
    const sortedTokens = tokens.sort((a, b) => {
      const aValue = totalValue(a, user.usdPrices, true);
      const bValue = totalValue(b, user.usdPrices, true);

      return bValue - aValue;
    });
    return sortedTokens.map((item, i) => (
      <LPPlatformRow key={item.liquidity} index={i} item={item} usdPrices={user.usdPrices} />
    ));
  }

  const sorted = tokens.sort((a, b) => {
    let aValue;
    let bValue;
    if (a.type && a.type === "lp") {
      aValue = totalValue(a, user.usdPrices, true);
    } else {
      aValue = totalValue(a, user.usdPrices);
    }

    if (b.type && b.type === "lp") {
      bValue = totalValue(b, user.usdPrices, true);
    } else {
      bValue = totalValue(b, user.usdPrices);
    }

    return bValue - aValue;
  });

  if (type === "tokens") {
    return sorted.map((token, i) =>
      showStack ? (
        <StackTokenRow key={token.address} token={token} network={network} type={type} user={user} index={i} />
      ) : (
        <tr key={token.address}>
          <td>
            <div className="wallet_table_asset">
              <AssetImage
                className="table_image"
                src={`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${
                  assetImageNetwork[network]
                }/assets/${getAddressSum(token.address)}/logo.png`}
                fallbackLogo={tokensLogos[token.symbol]}
                alt={token.symbol}
              />
              {token.symbol}
            </div>
          </td>
          <td>{beautifyTokensAmount(token.amount)}</td>
          <td className="wallet_no_mobile">
            {beautifyTokensAmount(user.usdPrices[token.symbol.toUpperCase()], { prefix: "$" })}
          </td>
          <td style={{ whiteSpace: "nowrap" }}>
            {beautifyTokensAmount(user.usdPrices[token.symbol.toUpperCase()] * token.amount, { prefix: "$" })}
          </td>
          <td>
            {token.address && type === "tokens" && (
              <div className="wallet_data_table_hide">
                <button onClick={() => dispatch(userDataOperations.addToTokenBlacklist(token))}>
                  <HideEyeIcon />
                </button>
              </div>
            )}
          </td>
        </tr>
      )
    );
  }

  if (type === "staking") {
    return sorted.map((token, i) =>
      showStack ? (
        <StackTokenRow key={token.address} token={token} network={network} user={user} index={i} staking={true} />
      ) : token.type === "token" ? (
        <tr key={`${token.token}_${token.platform}`}>
          <td>
            <div className="wallet_table_asset">
              <AssetImage
                className="table_image"
                src={`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${
                  network === "bsc-mainnet" ? "smartchain" : "ethereum"
                }/assets/${getAddress(token.token ?? "", network)}/logo.png`}
                fallbackLogo={tokensLogos[token.token]}
                alt={token.token}
              />
              {token.token}
            </div>
          </td>
          <td>{beautifyTokensAmount(token.amount)}</td>
          <td className="wallet_no_mobile">
            {beautifyTokensAmount(user.usdPrices[token.token.toUpperCase()], { prefix: "$" })}
          </td>
          <td style={{ whiteSpace: "nowrap" }}>
            {beautifyTokensAmount(user.usdPrices[token.token.toUpperCase()] * token.amount, { prefix: "$" })}
          </td>
        </tr>
      ) : (
        <tr key={`${token.token}_${token.platform}`}>
          <td>
            <div className="wallet_table_asset">
              <AssetImage
                className="table_image"
                src={`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${
                  network === "bsc-mainnet" ? "smartchain" : "ethereum"
                }/assets/${getAddress(token.token ?? "", network)}/logo.png`}
                fallbackLogo={tokensLogos[token.token]}
                alt={token.token}
              />
              {token.token}
            </div>
          </td>
          <td>{beautifyTokensAmount(token.amount)}</td>
          <td className="wallet_no_mobile">
            {beautifyTokensAmount(value(token, user) / token.amount, { prefix: "$" })}
          </td>
          <td style={{ whiteSpace: "nowrap" }}>{beautifyTokensAmount(value(token, user), { prefix: "$" })}</td>
        </tr>
      )
    );
  }
  return <></>;
};
