import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const API = process.env.REACT_APP_PRO_API;

const initialState = {
  isLoading: false,
  value: null,
};

const sentiment = createSlice({
  name: "sentiment",
  initialState,
  reducers: {
    setLoading(state, action) {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
    setValue(state, action) {
      return {
        ...state,
        value: action.payload,
      };
    },
  },
});

export const sentimentActions = sentiment.actions;

const getRoot = (state) => state.sentiment;

export const sentimentSelectors = {
  getLoading: (state) => getRoot(state).isLoading,
  getNews: (state) => getRoot(state).news,
};

export const sentimentOperations = {
  getSentiment: () => async (dispatch, getState) => {
    try {
      const config = {
        headers: {
          Authorization: getState().userData.tokens.access.token,
        },
      };
      const { data } = await axios.get(`${API}/v1/research/market-sentiment`, config);
      dispatch(sentimentActions.setValue(data));
    } catch (err) {
      console.log(err);
    }
  },
};

export const sentimentReducer = sentiment.reducer;
