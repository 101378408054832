import React from "react";

import { useSelector } from "react-redux";

import arrow from "../../assets/right-arrow.svg";
import ReadingFeed from "./ReadingFeed";
import { FeedWrap, Wrapper } from "./Readinglist.styles";

function Readinglist() {
  const news = useSelector((state) => state.news);
  const { readingList } = news;

  return (
    <Wrapper>
      <FeedWrap>
        <img src={arrow} />
        <h2>Reading List</h2>
        {readingList.length != 0 ? (
          readingList.map((item) => <ReadingFeed key={item._id} item={item} />)
        ) : (
          <p className="noList">Your list is empty</p>
        )}
      </FeedWrap>
    </Wrapper>
  );
}

export default Readinglist;
