import React from "react";

import { Dialog, DialogOverlay } from "@reach/dialog";
import styled from "styled-components";

import "@reach/dialog/styles.css";
import { ReactComponent as CloseIcon } from "../assets/closeModal.svg";
import { breakPoints } from "../Theme";

const ModalWrapper = styled(Dialog)`
  border-radius: 6px;
  background: ${(props) => (props.bg ? props.bg : props.theme.sidebar_bg)};
  max-width: ${(props) => (props.maxwidth ? props.maxwidth : `400px`)};
  margin: ${(props) => props.margin};
  width: 100%; /* overflow: hidden; */
  @media (max-width: ${breakPoints.lg}px) {
    width: calc(100% - 32px);
    max-width: 640px;
    padding: 1.25rem;
  }
`;

const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 16px;
  border-bottom: ${(props) => !props.noBorder && `1px solid ${props.theme.researchBorder}`};
  align-items: center;
  .close-icon {
    margin-left: auto;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 6px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    path {
      stroke: ${(props) => props.theme.default_color};
    }
    &:hover {
      background: ${(props) => props.theme.form_text3};
    }
  }

  .title {
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    color: ${(props) => props.theme.default_color};

    @media (max-width: ${breakPoints.sm}px) {
      line-height: 28px;
    }
  }

  @media (max-width: ${breakPoints.sm}px) {
    .title {
      font-size: 22px;
    }
  }

  @media (max-width: ${breakPoints.sm}px) {
    padding-bottom: 8px;
  }
`;
const ModalContent = styled.div``;
export default function Modal(props) {
  const { children, title, open, handleCloseModal, txModal, width, padding, margin, tokensModal, maxwidth, noBorder } =
    props;
  return (
    <DialogOverlay style={{ background: "black", opacity: "0.8" }} onDismiss={handleCloseModal} isOpen={open}>
      <ModalWrapper
        padding={padding}
        margin={margin}
        width={width}
        isOpen={open}
        onDismiss={handleCloseModal}
        aria-label="modal"
        maxwidth={maxwidth}
      >
        {txModal ? (
          children
        ) : (
          <>
            <ModalHeader noBorder={noBorder}>
              <span className="title">{title}</span>
              <span className="close-icon" onClick={handleCloseModal}>
                <CloseIcon />
              </span>
            </ModalHeader>
            <ModalContent tokensModal={tokensModal}>{children}</ModalContent>
          </>
        )}
      </ModalWrapper>
    </DialogOverlay>
  );
}
