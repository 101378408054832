import styled from "styled-components";

import { breakPoints } from "../../Theme";

export const Wrapper = styled.div`
  /* max-width: 1920px; */
  width: 100%;
  margin: 0 auto 3rem;
  @media (max-width: ${breakPoints.md}px) {
    padding: 0;
  }
  .discovery_grid {
    margin: 2.5rem 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-gap: 3rem;
    @media (min-width: ${breakPoints.xxl}px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  .discovery_pagination {
    display: flex;
    align-items: flex-end;
    > div {
      margin-left: auto;
    }
  }
`;

export const DiscoveryFiltersWrapper = styled.div`
  margin-top: 1.5rem;
  .discovery_upper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .discovery_filters {
      display: flex;
      align-items: center;
      .discovery_filter_currency {
        margin-right: 1rem;
      }
      .discovery_category_options {
        display: flex;
        padding-left: 1rem;
        border-left: 1px solid ${(props) => props.theme.researchBorder};
        gap: 1rem;
        .discovery_category_button {
          cursor: pointer;
          background: 0;
          color: ${(props) => props.theme.mainDarkText};
          padding: 0.5rem 0.75rem;
          border: 1px solid ${(props) => props.theme.researchBorder};
          border-radius: 6px;
        }
        .discovery_category_selected {
          background: ${(props) => props.theme.brandBlue};
          border-color: ${(props) => props.theme.border_color};
        }
      }
      .discovery_filter_advanced {
        cursor: pointer;
        margin-left: 1rem;
        text-decoration: underline;
        display: flex;
        align-items: center;
        svg {
          width: 15px;
          margin-left: 6px;
        }
      }
    }
  }
`;

export const ResearchTokenMainTabs = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${(props) => props.theme.researchBorder};
  padding: 1rem 0;

  .research_token_tabs {
    display: flex;
    gap: 0.75rem;
    > button {
      padding: 0.75rem 0;
      width: 140px;
    }
    .tab_active {
      border-color: ${(props) => props.theme.brandBlue};
      background: ${(props) => props.theme.blueTransparent};
    }
  }
  @media (max-width: ${breakPoints.lg}px) {
    flex-direction: column-reverse;
    gap: 0.75rem;
    align-items: flex-start;
    .research_token_tabs {
      width: 100%;
      justify-content: space-between;
      > button {
        padding: 6px 0;
        font-size: 12px;
        width: 100px;
      }
    }
  }
`;
