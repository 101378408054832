import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as GridView } from "../../assets/discovery/gridView.svg";
import { ReactComponent as TableViewIcon } from "../../assets/discovery/tableView.svg";
import { beautifyTokensAmount } from "../../helpers";
import { watchlistOperations } from "../../redux/watchlistSlice";
import { Skeleton } from "../../Shared";
import { breakPoints } from "../../Theme";
import { DiscoveryPageHeaderWrapper } from "./ResearchPageHeader.styles";
import SearchBar from "./SearchBar";

const ResearchPageHeader = ({ dataView, setDataView }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [mobileDetails, setMobileDetails] = useState(false);

  const discovery = useSelector((state) => state.discovery);
  const { tokensStats, isFetching } = discovery;

  const watchlist = useSelector((state) => state.watchlist);
  const { watchlistList } = watchlist;

  useEffect(() => {
    if (watchlistList.length === 0) {
      dispatch(watchlistOperations.getWatchlistList());
    }
  }, [watchlistList, dispatch]);

  return (
    <DiscoveryPageHeaderWrapper>
      <h1>{t("research.researchDashboard")}</h1>
      <button className="discovery_toggle_mobile secondary" onClick={() => setMobileDetails((prev) => !prev)}>
        {mobileDetails ? "Hide details" : "Show details"}
      </button>
      {(mobileDetails || window.innerWidth > breakPoints.md) && (
        <div className="discovery_header_info">
          <div>
            {t("common.tokens")}:
            <span>
              {!isFetching || tokensStats.tokenCount ? tokensStats.tokenCount : <Skeleton height="14" width="50" />}
            </span>
          </div>
          <div>
            {t("common.marketCap")}:
            <span>
              {!isFetching || tokensStats.totalMarketCap ? (
                beautifyTokensAmount(tokensStats.totalMarketCap, { prefix: "$", noDecimals: true })
              ) : (
                <Skeleton height="14" width="120" />
              )}
            </span>
          </div>
          <div>
            {t("common.24hVolume")}:
            <span>
              {!isFetching || tokensStats.dailyVolume ? (
                beautifyTokensAmount(tokensStats.dailyVolume, { prefix: "$", noDecimals: true })
              ) : (
                <Skeleton height="14" width="120" />
              )}
            </span>
          </div>
        </div>
      )}
      <div className="discovery_table_options">
        <div className="discovery_header_toggle_links">
          <SearchBar />
        </div>
        <div className="discovery_grid_options">
          <GridView
            className={dataView === "grid" ? "discovery_table_selected" : ""}
            onClick={() => setDataView("grid")}
          />
          <TableViewIcon
            className={dataView === "table" ? "discovery_table_selected" : ""}
            onClick={() => setDataView("table")}
          />
        </div>
      </div>
    </DiscoveryPageHeaderWrapper>
  );
};

export default ResearchPageHeader;
