import styled from "styled-components";

import { breakPoints } from "../../Theme";

export const WalletDataWrapper = styled.div`
  /* Wallet/Staking/Lp buttons and Dropdown container */

  display: flex;
  flex-direction: column;
  position: relative;
  max-height: 455px;
  @media (min-width: ${breakPoints.xxl}px) {
    max-height: calc(50vh - 86px);
  }
  .wallet_data_header {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
    > button,
    > div {
      flex: 1;
    }
    button {
      padding: 0.75rem;
      border-radius: 6px;
      text-align: left;
      background: ${(props) => props.theme.secondaryBg};
      border: 1px solid ${(props) => props.theme.secondaryBg};
      h6 {
        color: ${(props) => props.theme.lightGrey};
        margin-bottom: 2px;
      }
      p {
        color: ${(props) => props.theme.mainDarkText};
        font-size: 16px;
        font-weight: 500;
      }
      :hover {
        background: ${(props) => props.theme.blueTransparentHover};
        border-color: ${(props) => props.theme.brandBlue};
        p {
          color: white;
        }
      }
      :disabled {
        pointer-events: none;
        * {
          color: ${(props) => props.theme.placeholderText};
        }
      }
    }
    .wallet_type_selected {
      background: ${(props) => props.theme.blueTransparent};
      border-color: ${(props) => props.theme.brandBlue};
      p {
        color: white;
      }
    }
    .wallet_data_view_options {
      align-self: flex-end;
      p {
        font-size: 12px;
        color: ${(props) => props.theme.lightGrey};
        margin-bottom: 6px;
      }
    }
  }
  /* END OF Wallet/Staking/Lp buttons and Dropdown container */
  /* Removed Tokens CSS */
  .wallet_data_blacklisted {
    height: 16px;
    margin-bottom: 6px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    button {
      color: ${(props) => props.theme.lightGrey};
      font-size: 12px;
      text-decoration: underline;
    }
  }
  /* Stack Data */
  .wallet_data_stack_main {
    overflow: hidden;
    flex: 1;
    :hover {
      .wallet_data_expand {
        opacity: 1;
        visibility: visible;
      }
    }
    .wallet_data_stack_main_scrollable {
      position: relative;
      max-height: 100%;
      overflow-y: auto;
      border-radius: 10px;
      background: ${(props) => props.theme.secondaryBg};
      padding: 1rem;
      ::-webkit-scrollbar {
        display: none;
      }
      :hover {
        + .wallet_data_expand {
          opacity: 1;
          visibility: visible;
        }
      }
      /* Stack data single item */
      .wallet_data_stack_item {
        :not(:last-of-type) {
          margin-bottom: 1.5rem;
        }
        .wallet_data_stack_item_header {
          display: flex;
          align-items: center;
          gap: 6px;
          cursor: pointer;

          .wallet_data_stack_item_icon {
            height: 16px;
            width: 16px;
            border-radius: 50%;
          }
          h3 {
            font-size: 16px;
            color: ${(props) => props.theme.mainDarkText};
          }
          h5 {
            flex: 1;
            font-size: 12px;
            color: ${(props) => props.theme.lightGrey};
            align-self: flex-end;
          }
          .wallet_data_stack_item_total {
            display: flex;
            align-items: center;
            font-size: 16px;
            color: ${(props) => props.theme.lightGrey};
            b {
              margin-left: 0.5rem;
              margin-right: 1rem;
              color: ${(props) => props.theme.form_text};
            }
          }
        }
      }
    }
  }
  /* END OF Stack Data */
  /* Wallet data table */
  .wallet_data_table {
    width: 100%;
    margin-top: 0.75rem;
    border-collapse: collapse;
    th {
      text-align: left;
      padding-bottom: 6px;
      font-size: 12px;
      color: ${(props) => props.theme.lightGrey};
      border-bottom: 1px solid ${(props) => props.theme.researchBorder};
      :first-of-type {
        padding-left: 1rem;
      }
      :nth-last-of-type(2) {
        text-align: right;
      }
      :last-of-type {
        padding-right: 1rem;
      }
    }
    tr {
      :nth-child(even) {
        background: ${(props) => props.theme.bg};
        td {
          :first-of-type {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
          }
          :last-of-type {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
          }
        }
      }
      :hover {
        .wallet_data_table_hide {
          visibility: visible;
        }
      }
    }
    td {
      padding: 0.75rem 0;
      :first-of-type {
        padding-left: 1rem;
      }
      :nth-last-of-type(2) {
        text-align: right;
      }
      :last-of-type {
        padding-right: 1rem;
      }
    }
    .wallet_data_row_asset {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      .wallet_data_table_icon,
      img,
      svg {
        height: 32px;
        width: 32px;
        border-radius: 50%;
      }
    }
    .wallet_data_table_hide {
      display: flex;
      justify-content: flex-end;
      visibility: hidden;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          cursor: pointer;
          height: 18px;
          width: 18px;
        }
      }
    }
  }
  /* End of table */
  /* Single wallet table styling */
  .wallet_data_wallet {
    overflow: hidden;
    position: relative;
    height: 100%;
    :hover {
      .wallet_data_expand {
        opacity: 1;
        visibility: visible;
      }
    }
    .wallet_data_wallet_scrollable {
      height: 100%;
      overflow: auto;
      :hover {
        + .wallet_data_expand {
          opacity: 1;
          visibility: visible;
        }
      }
      ::-webkit-scrollbar {
        display: none;
      }
      .wallet_data_single {
        display: table;
        margin: 0;
        max-height: 100%;
        flex: 1;
        overflow-y: auto;

        tr {
          :nth-child(even) {
            background: ${(props) => props.theme.secondaryBg};
            td {
              :first-of-type {
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px;
              }
              :last-of-type {
                border-top-right-radius: 6px;
                border-bottom-right-radius: 6px;
              }
            }
          }
        }
      }
    }
  }
  /* Shadow on scroll */
  .wallet_data_shadow {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 44px;
    width: 100%;
    background: linear-gradient(180deg, rgba(16, 25, 38, 0) 0%, #101926 128.41%);
  }
  /* Open detailed view button */
  .wallet_data_expand {
    position: absolute;
    bottom: 0.75rem;
    right: 0.75rem;
    z-index: 4;
    display: flex;
    opacity: 0;
    padding: 0.75rem;
    border-radius: 6px;
    border: 1px solid ${(props) => props.theme.brandBlue};
    background: ${(props) => props.theme.blueTransparent};
    visibility: hidden;
    transition: all 0.15s ease-out;
  }

  /* Single wallet LP grid CSS */
  .wallet_data_wallet_lp {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    ::-webkit-scrollbar {
      display: none;
    }
    .wallet_data_wallet_lp_header {
      grid-column: 1/4;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      padding: 0 0.5rem 3px;
      h4 {
        font-size: 12px;
        color: ${(props) => props.theme.lightGrey};
      }
    }
    .wallet_lp_item {
      grid-column: 1/4;
      border-radius: 6px;
      :nth-of-type(odd) {
        background: ${(props) => props.theme.secondaryBg};
      }
      .wallet_lp_item_header {
        cursor: pointer;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: center;
        margin: 10px 0.5rem;

        /* Image, name and amount */
        .wallet_lp_item_info {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          .wallet_lp_item_icon {
            height: 24px;
            width: 24px;
            border-radius: 50%;
          }
          .wallet_lp_last_icon {
            margin-left: -5px;
          }
          .wallet_lp_item_title {
            h3 {
              font-size: 14px;
            }
            h5 {
              font-size: 14px;
              color: ${(props) => props.theme.form_text};
            }
          }
        }
        /* Platform */
        .wallet_lp_item_platform {
          display: flex;
          align-items: center;
          gap: 6px;
          img,
          svg {
            height: 24px;
            width: 24px;
          }
        }
        /* Value and arrow */
        .wallet_lp_item_value {
          display: flex;
          justify-content: space-between;
          align-items: center;

          path {
            fill: ${(props) => props.theme.form_text};
          }
        }
      }
      .wallet_lp_item_body {
        margin: 10px 0.5rem;
        padding-top: 10px;
        border-top: 1px solid ${(props) => props.theme.researchBorder};
        flex-direction: column;
        gap: 10px;
        .wallet_lp_body_line {
          display: flex;
          justify-content: space-between;
          > div {
            display: flex;
            align-items: center;
            gap: 6px;
          }
          img,
          svg {
            height: 24px;
            width: 24px;
            border-radius: 50%;
          }
        }
      }
    }
  }
  /* END OF Single Wallet LP grid */
  /* Stack LP Grid additional CSS */
  .wallet_data_stack_lp {
    background: ${(props) => props.theme.bg};
    padding: 0.5rem;
    margin-top: 1rem;
    border-radius: 6px;
  }
`;

export const ViewDropdownWrapper = styled.div`
  font-size: 12px;
  position: relative;
  .view_dropdown_header {
    cursor: pointer;
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    border-radius: ${(props) => (props.isOpen ? "6px 6px 0 0" : "6px")};
    border: 1px solid ${(props) => props.theme.researchBorder};
  }
  .view_dropdown_list {
    position: absolute;
    top: calc(100% - 1px);
    right: 0;
    width: 120%;
    background: ${(props) => props.theme.bg};
    z-index: 3;
    border-radius: 6px 0 6px 6px;
    border: 1px solid ${(props) => props.theme.researchBorder};
    overflow: hidden;
    > div {
      padding: 0.5rem;
      cursor: pointer;
      :not(:last-of-type) {
        border-bottom: 1px solid ${(props) => props.theme.researchBorder};
      }
      :hover {
        background: ${(props) => props.theme.secondaryBg};
      }
    }
  }
`;
