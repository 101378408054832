import React, { useCallback, useEffect, useState } from "react";

import { integrations } from "@keyfi/keyfi-common";
import BigNumber from "bignumber.js";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { beautifyTokensAmount, poolsLogos } from "../../../helpers";
import { borrowOperations } from "../../../redux/borrowSlice";
import { Skeleton } from "../../../Shared";

const Wrapper = styled.table`
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
  thead {
    th {
      font-size: 10px;
      text-align: left;
      padding-bottom: 6px;
    }
  }
  tr {
    :last-of-type {
      td {
        border: 0;
      }
    }
  }
`;

const StyledTableCell = styled.td`
  padding: 6px 0;
  border-bottom: 1px solid ${(props) => props.theme.researchBorder};
  font-size: 14px;
  > div {
    display: flex;
    align-items: center;
    img {
      height: 20px;
      margin-right: 6px;
    }
  }
`;

function capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string.slice(1);
}

const MyCollateral = ({ data }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const wallet = useSelector((state) => state.wallet);

  const [modifiedData, setModifiedData] = useState([]);

  const getETHPrice = useCallback(async () => {
    if (user.usdPrices && user.usdPrices.ETH) {
      return user.usdPrices.ETH;
    }
    if (wallet.network && wallet.network.name) {
      const ETHPrice = await integrations.compound.getETHPrice();
      return ETHPrice;
    }
    return 0;
  }, [user, wallet]);

  useEffect(() => {
    const modifyData = async () => {
      try {
        const ETHPrice = await getETHPrice();

        const newData = data.map((item) => ({
          ...item,
          totalCollateralUSD: BigNumber(item.totalCollateralETH).times(ETHPrice).toFixed(),
        }));
        setModifiedData(newData);
      } catch (err) {
        setModifiedData([]);
      }
    };

    if (data.length !== 0) {
      modifyData();
    }
  }, [data, getETHPrice]);

  return (
    <Wrapper>
      <thead>
        <tr>
          <th>{t("common.platform")}</th>
          <th>{t("common.collateral")}</th>
        </tr>
      </thead>
      <tbody>
        {modifiedData.length !== 0 ? (
          modifiedData.map((item) => (
            <tr key={item.platform + item.version}>
              <StyledTableCell>
                <div>
                  {poolsLogos[capitalizeFirstLetter(item.platform)]}
                  {capitalizeFirstLetter(item.platform)}{" "}
                  {dispatch(borrowOperations.doesPlatformHaveVersions(item.platform)) && item.version}
                </div>
              </StyledTableCell>
              <StyledTableCell>
                {beautifyTokensAmount(item.totalCollateralUSD, {
                  prefix: "$",
                })}
              </StyledTableCell>
            </tr>
          ))
        ) : (
          <tr>
            <td>
              <Skeleton width="70" height="18" />
            </td>
            <td>
              <Skeleton width="70" height="18" />
            </td>
          </tr>
        )}
      </tbody>
    </Wrapper>
  );
};

export default MyCollateral;
