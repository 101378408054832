import styled from "styled-components";

import { breakPoints } from "../../Theme";

export const DashboardWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  color: ${(props) => props.theme.default_color};
  .dashboard_total {
    margin-bottom: 1rem;
    font-weight: 500;
    .dashboard_total_title {
      color: ${(props) => props.theme.lightDarkGrey};
    }
    .dashboard_total_value {
      font-size: 27px;
    }
  }
`;

export const TopDashboard = styled.div`
  display: grid;
  grid-template-columns: 4fr 6fr;
  gap: 3rem;
  margin-bottom: 2rem;
  max-width: 2100px;
  /* max-height: 455px; */
  @media (max-width: ${breakPoints.xl}px) {
    max-height: 100%;
    grid-template-columns: 1fr;
  }
  @media (min-width: ${breakPoints.xxl}px) {
    grid-template-columns: 1fr 2fr 1fr;
    max-width: 100%;
    /* max-height: 100%;
    height: calc(50vh - 86px); */
  }
`;

export const PlatformGrid = styled.div`
  h2 {
    font-size: 24px;
    margin-bottom: 0.75rem;
  }
  .platform_grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 1.25rem;
    margin-bottom: 2.5rem;
    @media (max-width: ${breakPoints.md}px) {
      grid-template-columns: 1fr;
    }
  }
`;

export const WidgetGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  grid-auto-rows: 350px;
  gap: 3.5rem;
  @media (max-width: ${breakPoints.xl}px) {
    grid-template-columns: 1fr;
    grid-auto-rows: 350px;
    gap: 2rem;
  }
`;
