import React, { useState } from "react";

import styled from "styled-components";

// Assets
import PW from "../../assets/HidePassword.svg";
import PWOpen from "../../assets/HidePasswordOpenEye.svg";

const UserInput = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  border: 1px solid ${(props) => (props.error ? props.theme.error : props.theme.input_placefolder)};
  border-radius: 6px;
  margin-bottom: ${(props) => (props.noMargin ? "0 !important" : "1rem")};
  transition: all 0.15s;
  background: ${(props) => props.theme.bg};
  overflow: hidden;
  input {
    padding: 0.75rem;
    padding-right: 0;
    width: 100%;
    font-weight: 500;
    color: ${(props) => (props.error ? props.theme.error : props.theme.default_color)};
    font-size: 16px;
    ::placeholder {
      color: ${(props) => props.theme.input_placefolder};
      font-weight: 500;
    }
  }
  img {
    padding-right: 0.75rem;
    cursor: pointer;
    min-width: 20px;
  }
  :focus-within {
    border: 1px solid ${(props) => props.theme.userInputFocus};
    box-shadow: ${(props) => props.theme.userInputFocusShadow};
  }
`;

const UserInputField = (props) => {
  const { showEye, noMargin, error, type = "text", ...inputProps } = props;
  const [showPW, setShowPW] = useState(false);

  return (
    <UserInput noMargin={noMargin} error={error} className="user_input_field">
      <input type={showPW ? "text" : type} {...inputProps} />
      {showEye && <img src={showPW ? PWOpen : PW} alt="Show Password" onClick={() => setShowPW(!showPW)} />}
    </UserInput>
  );
};

export default UserInputField;
