import styled from "styled-components";

import Button from "../../Components/Button";
import { breakPoints } from "../../Theme";

export const BorrowWrapper = styled.div`
  width: 100%;
  max-width: 870px;
  margin: 0 auto 3rem;

  h1 {
    font-size: 24px;
    margin-bottom: 2rem;
    @media (max-width: ${breakPoints.md}px) {
      display: none;
    }
  }
  h2 {
    font-size: 16px;
    margin-bottom: 0.5rem;
  }
`;

export const ChangeNetworkMessage = styled.div`
  width: 100%;
  max-width: 505px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props) => props.theme.secondaryBg};
  border: 1px solid ${(props) => props.theme.researchBorder};
  border-radius: 6px;
  padding: 2rem 1.5rem;
  text-align: center;
  ${Button} {
    margin-top: 1.5rem;
    padding: 10px 1.6rem;
    font-size: 16px;
    text-transform: none;
    width: max-content;
    height: max-content;
    line-height: unset;
  }
`;
