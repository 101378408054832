import React, { useState } from "react";

import styled from "styled-components";

import { breakPoints } from "../../../Theme";
import DashboardDepositContainer from "./DashboardDepositContainer";
import PoolsContainer from "./PoolsContainer";
import SwapContainer from "./SwapContainer";

const WideScreenWidgetWrapper = styled.div`
  display: none;
  @media (min-width: ${breakPoints.xxl}px) {
    display: block;
  }
  .dashboard_swap_buttons {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
    > button {
      flex: 1;
      text-align: left;
      padding: 0.75rem;
      border-radius: 6px;
      background: ${(props) => props.theme.secondaryBg};
      color: ${(props) => props.theme.mainDarkText};
    }
    .dashboard_swap_button_selected {
      background: ${(props) => props.theme.blueTransparent};
      color: white;
      box-shadow: inset 0px 0px 0px 1px ${(props) => props.theme.brandBlue};
    }
  }
`;

const TYPE_SWAP = "swap";
const TYPE_DEPOSIT = "deposit";
const TYPE_POOLS = "pools";

const widgetType = {
  [TYPE_SWAP]: <SwapContainer />,
  [TYPE_DEPOSIT]: <DashboardDepositContainer />,
  [TYPE_POOLS]: <PoolsContainer />,
};

const WideScreenWidget = () => {
  const [type, setType] = useState(TYPE_SWAP);

  return (
    <WideScreenWidgetWrapper>
      <div className="dashboard_swap_buttons">
        <button
          className={type === TYPE_SWAP ? "dashboard_swap_button_selected" : ""}
          onClick={() => setType(TYPE_SWAP)}
        >
          Swap
        </button>
        <button
          className={type === TYPE_DEPOSIT ? "dashboard_swap_button_selected" : ""}
          onClick={() => setType(TYPE_DEPOSIT)}
        >
          Deposit
        </button>
        <button
          className={type === TYPE_POOLS ? "dashboard_swap_button_selected" : ""}
          onClick={() => setType(TYPE_POOLS)}
        >
          Pool
        </button>
      </div>
      {widgetType[type]}
    </WideScreenWidgetWrapper>
  );
};

export default WideScreenWidget;
