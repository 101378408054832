import styled from "styled-components";

import { breakPoints } from "../../Theme";

export const StyledConnectedButton = styled.button`
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.bg};
  padding: 8px 5px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  cursor: pointer;
  span {
    font-family: Montserrat;
    color: ${(props) => props.theme.default_color};
    font-size: 1rem;
    font-weight: 400;
    line-height: 20px;
  }
  svg {
    margin-right: 8px;
    path {
      /* stroke: ${(props) => props.theme.default_color}; */
      fill: ${(props) => props.theme.default_color};
    }
  }
  .chev-down {
    margin-left: 9px;
  }
`;

export const DesktopHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  padding: 0 2.5rem 0 0;
  width: 100%;
  margin: 1rem 0;
  .desktopheader_readonly {
    position: relative;
    background: ${(props) => props.theme.secondaryBg};
    color: white;
    margin-left: 2.5rem;
    padding: 0.5rem;
    border-radius: 6px;
    display: flex;
    align-items: center;
    font-size: 12px;
    .readonly_circle {
      height: 13px;
      width: 13px;
      background: #f2c94c;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.25rem;
      .readonly_insidecircle {
        height: 7px;
        width: 7px;
        border-radius: 50%;
        background: #ffe600;
      }
    }
    &:hover {
      .tooltip {
        display: flex;
      }
    }
  }
  .desktop_header_end {
    display: flex;
    margin-left: auto;
    align-items: center;
    gap: 1.5rem;
    .icon-wrapper {
      height: max-content;
      position: relative;
      display: flex;
      gap: 0.5rem;
      align-items: center;
      cursor: pointer;
      padding: 6px 9px;
      border: 1px solid transparent;
      svg {
        path {
          fill: ${(props) => props.theme.default_color};
        }
      }

      .gas-price {
        color: ${(props) => props.theme.default_color};
        font-family: Montserrat;
        font-size: 1rem;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .icon-open {
      background: ${(props) => props.theme.secondaryBg};
      border-radius: 6px;
      border-color: ${(props) => props.theme.researchBorder};
    }
    .header__connectButton {
      width: 220px;
      height: 45px;
    }
  }
`;

export const StyledMobileMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.theme.sidebar_bg};
  border-bottom: 1px solid transparent;
  height: 64px;
  padding: 0 1.25rem;
  position: relative;
  width: 100%;
  svg {
    rect {
      fill: ${(props) => props.theme.default_color};
    }
  }
`;

export const StyledLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-right: 1rem;
  }
`;

export const NetworkHeaderWidget = styled.div`
  display: flex;
  margin-left: 2.5rem;
  padding: 0.5rem;
  background: ${(props) => props.color};
  color: ${(props) => props.textColor};
  border-radius: 6px;
  font-size: 12px;
  gap: 0.5rem;
`;

export const StacksButtonsWrapper = styled.div`
  /* margin-left: 2.5rem; */
  position: relative;
  display: flex;
  > button {
    position: relative;
    background: ${(props) => props.theme.secondaryBg};
    border: 1px solid ${(props) => props.theme.researchBorder};
    padding: 0.5rem 1rem;
    color: ${(props) => props.theme.mainDarkText};
    display: flex;
    align-items: center;
    gap: 6px;
    max-width: 200px;
    :first-of-type {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    :last-of-type {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
    :hover {
      border-color: ${(props) => props.theme.brandBlue};
      background: ${(props) => props.theme.blueTransparent};
    }
    .stack_button_icon {
      height: 24px;
      width: 24px;
      border-radius: 50%;
    }
    .stack_button_title {
      flex: 1;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .stack_button_arrow_mobile {
      display: block;
      cursor: pointer;
      height: 16px;
      width: 16px;
    }
  }
  .stack_button_active {
    background: ${(props) => props.theme.blueTransparentDark};
    border-color: ${(props) => props.theme.brandBlue};
  }
  .stack_list {
    z-index: 10;
    margin-top: 8px;
    position: absolute;
    display: none;
    top: 100%;
    right: 0;

    padding: 0.5rem;
    width: 220px;
    border-radius: 6px;
    border: 1px solid ${(props) => props.theme.researchBorder};
    background: ${(props) => props.theme.secondaryBg};
    ::after {
      z-index: 9;
      content: "";
      display: block;
      position: absolute;
      bottom: 100%;
      height: 9px;
      width: 100%;
    }
    .stack_list_section {
      padding-bottom: 0.5rem;
      button {
        display: flex;
        padding: 10px 1rem;
        gap: 0.5rem;
        color: ${(props) => props.theme.mainDarkText};
        margin: 0;
        width: 100%;
        border-radius: 6px;
        text-align: left;
        .stack_icon {
          width: auto;
          position: relative;
          min-width: 36px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .stack_info {
          flex: 1;
          h4 {
            margin-bottom: 4px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
          h6 {
            font-size: 12px;
            color: ${(props) => props.theme.lightGrey};
          }
        }
        :hover {
          background: ${(props) => props.theme.bg};
        }
      }
    }
    .stack_list_line {
      height: 1px;
      background: ${(props) => props.theme.researchBorder};
      width: 100%;
    }
    a {
      margin-top: 0.5rem;
      display: block;
      padding: 0.5rem 1.5rem;
      border-radius: 6px;
      width: 100%;
      text-align: left;
      font-size: 16px;
      color: ${(props) => props.theme.mainDarkText};

      :hover {
        background: ${(props) => props.theme.bg};
      }
    }
  }
  @media (min-width: ${breakPoints.lg}px) {
  }

  @media (max-width: ${breakPoints.lg}px) {
    margin: 2rem 1rem 0;
    > button {
      flex: 1;
      font-size: 14px;
      max-width: 100%;

      .stack_button_title {
        width: 30px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;

export const StackIcon = styled.div`
  height: ${(props) => props.height ?? "36px"};
  width: ${(props) => props.height ?? "36px"};
  font-size: ${(props) => props.fontSize ?? "16px"};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.bg};
  color: ${(props) => (props.bgIsLight ? "black" : props.theme.mainDarkText)};
`;

export const LanguageOptions = styled.div`
  color: ${(props) => props.theme.default_color};
  margin-top: 1rem;
  .language_option {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    padding: 1rem;
    img {
      margin-right: 10px;
    }
    :hover {
      background: ${(props) => props.theme.bg};
      border-radius: 6px;
    }
  }
`;
