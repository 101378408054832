import React from "react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { beautifyTokensAmount, poolsLogos } from "../../../helpers";
import { breakPoints } from "../../../Theme";
import Button from "../../Button";
import AssetImage from "../AssetImage";

const Wrapper = styled.div`
  display: none;
  @media (max-width: ${breakPoints.md}px) {
    display: block;
  }
`;

const MobileBorrowCard = styled.div`
  background: ${(props) => props.theme.bg};
  border: 1px solid ${(props) => props.theme.researchBorder};
  padding: 1rem;
  margin-bottom: 1.5rem;
  border-radius: 6px;
  font-size: 14px;
  .mobile_borrow_card_line {
    display: flex;
    padding: 10px 0;
    :not(:last-of-type) {
      border-bottom: 1px solid ${(props) => props.theme.researchBorder};
    }
    :first-of-type {
      padding-top: 0;
    }
    :last-of-type {
      padding-bottom: 0;
    }
    > div {
      flex: 1;
      display: flex;
      align-items: center;

      .mobile_borrow_card_image,
      img {
        height: 20px;
        width: 20px;
        margin-right: 0.25rem;
      }
      :first-of-type {
        color: ${(props) => props.theme.form_text};
      }
    }
  }
  a {
    ${Button} {
      margin-top: 1rem;
      width: 100%;
      text-transform: capitalize;
      padding: 7px 6px;
      line-height: normal;
      height: auto;
      font-size: 16px;
    }
  }
`;

const BorrowTableMobile = ({ data, isFetching }) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      {!isFetching &&
        data.map((item) => (
          <MobileBorrowCard key={item.address + item.platform}>
            <div className="mobile_borrow_card_line">
              <div>{t("borrow.token")}</div>
              <div>
                <AssetImage
                  className="mobile_borrow_card_image"
                  src={`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${item.address}/logo.png`}
                  alt={item.symbol}
                />
                {item.symbol}
              </div>
            </div>
            <div className="mobile_borrow_card_line">
              <div>{t("borrow.platforms")}</div>
              <div>
                {poolsLogos[item.platform.split(" ")[0]]} {item.platform}
              </div>
            </div>
            <div className="mobile_borrow_card_line">
              <div>{t("borrow.Available to borrow")}</div>
              <div>
                {beautifyTokensAmount(item.availableToBorrow, {
                  suffix: ` ${item.symbol}`,
                })}
              </div>
            </div>
            <div className="mobile_borrow_card_line">
              <div>{t("borrow.variableAPY")}</div>
              <div>
                {beautifyTokensAmount(item.variableAPY * 100, {
                  suffix: "%",
                  percent: true,
                })}
              </div>
            </div>
            <div className="mobile_borrow_card_line">
              <div>{t("borrow.stableAPY")}</div>
              <div>
                {item.stableAPY !== "0"
                  ? beautifyTokensAmount(item.stableAPY * 100, {
                      suffix: "%",
                      percent: true,
                    })
                  : "-"}
              </div>
            </div>
            <Link to={`/borrow/${item.platform.split(" ")[0].toLowerCase()}/${item.version}/${item.symbol}`}>
              <Button primary>{t("borrow.title")}</Button>
            </Link>
          </MobileBorrowCard>
        ))}
    </Wrapper>
  );
};

export default BorrowTableMobile;
