import React from "react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Bar, BarChart, CartesianGrid, Cell, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import styled from "styled-components";

import { beautifyTokensAmount, poolsLogos } from "../../../helpers";
import { DesktopMyBorrowsWrapper } from "./MyBorrows.styles";

const CustomTooltipWrapper = styled.div`
  background: ${(props) => props.theme.bg};
  min-width: 200px;
  padding: 0.5rem;
  border: 0.5px solid ${(props) => props.theme.researchBorder};
  border-radius: 6px;
  > div {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    :not(:last-of-type) {
      margin-bottom: 6px;
    }
    span {
      font-weight: 600;
    }
    .custom_tooltip_stable {
      color: ${(props) => props.theme.brandBlue};
    }
    .custom_tooltip_variable {
      color: ${(props) => props.theme.uniswapPink};
    }
  }
`;

const CustomLabel = (props) => {
  const { x, y, width, value } = props;
  const { t } = useTranslation();
  return (
    <g>
      <foreignObject x={width + x + 10} y={y - 2} height="20" width="300">
        <div style={{ display: "flex", alignItems: "center", fontSize: "14px" }}>
          <div style={{ marginRight: "1.25rem" }}>{beautifyTokensAmount(value.debtAmountInUSD, { prefix: "$" })}</div>
          <Link className="my_borrows_manage_link" to={`/borrow/${value.platform}/${value.version}/${value.asset}`}>
            {t("common.manage")}
          </Link>
        </div>
      </foreignObject>
    </g>
  );
};

const CustomYTick = (props) => {
  const { y, payload, data } = props;

  const platform = data.find((item) => item.symbol === payload.value);

  function capitalizeFirstLetter(string) {
    return string[0].toUpperCase() + string.slice(1);
  }
  return (
    <>
      <g transform={`translate(${0},${y})`}>
        <image
          style={{ border: "1px solid black" }}
          xlinkHref={`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${platform.address}/logo.png`}
          x={-10}
          y={-8.5}
          height="16"
          width="16"
        />

        <foreignObject x="-2" y="-18" width="15" height="15">
          <style>
            {`.desktop_my_borrows_image img {
        height: 10px;
      }`}
          </style>
          <div className="desktop_my_borrows_image">{poolsLogos[capitalizeFirstLetter(platform.platform)]}</div>
        </foreignObject>
        <text x={10} y={5} fontSize="14" textAnchor="start" fill="#CED2D6">
          {payload.value}
        </text>
      </g>
    </>
  );
};

const CustomXTick = (props) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} fontSize="10" textAnchor="middle" fill="#CED2D6">
        ${payload.value}
      </text>
    </g>
  );
};

const CustomTooltip = ({ active, payload, label, t }) => {
  if (active && payload && payload.length) {
    return (
      <CustomTooltipWrapper>
        <div>
          <div>{t("common.amount")}</div>
          <span>
            {beautifyTokensAmount(payload[0].payload.debtAmount, {
              suffix: ` ${label}`,
            })}
          </span>
        </div>
        <div>
          <div>{t("common.value")}</div>
          <span>
            {beautifyTokensAmount(payload[0].payload.debtAmountInUSD, {
              prefix: "$",
            })}
          </span>
        </div>
        <div>
          <div>{t("common.APYtype")}</div>

          {payload[0].payload.borrowMode === "1" ? (
            <span className="custom_tooltip_stable">{t("common.stable")}</span>
          ) : (
            <span className="custom_tooltip_variable">{t("common.varible")}</span>
          )}
        </div>
      </CustomTooltipWrapper>
    );
  }
  return null;
};

const platformColors = {
  compound: "#00D395",
  aave: "#CA5CBE",
};

const DesktopMyBorrows = ({ borrowData, t }) => (
  <DesktopMyBorrowsWrapper>
    <ResponsiveContainer width="100%" height={10 + borrowData.length * 40} debounce={1}>
      <BarChart barSize={16} layout="vertical" data={borrowData} margin={{ left: 10, right: 100 }}>
        <CartesianGrid horizontal={false} opacity={0.6} />
        <XAxis type="number" orientation="top" axisLine={false} tick={<CustomXTick />} />
        <YAxis type="category" dataKey="symbol" tick={<CustomYTick data={borrowData} />} />
        <Tooltip allowEscapeViewBox={{ y: true }} content={<CustomTooltip t={t} />} cursor={{ fill: "transparent" }} />

        <Bar dataKey="debtAmountInUSD" radius={[0, 6, 6, 0]}>
          {borrowData.map((entry) => {
            const color = platformColors[entry.platform];
            return <Cell key={entry.platform} fill={color} />;
          })}
          <LabelList dataKey="assetData" position="right" content={<CustomLabel />} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  </DesktopMyBorrowsWrapper>
);

export default DesktopMyBorrows;
