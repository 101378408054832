import React from "react";

import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { editAddressOperations } from "../../redux/wallets/editAddressSlice";
import { breakPoints } from "../../Theme";
import Modal from "../Modal";

const Wrapper = styled.div`
  padding: 2rem 0 2rem;
  input,
  label {
    display: block;
  }
  label {
    font-size: 12px;
    color: ${(props) => props.theme.lightGrey};
    margin-bottom: 6px;
  }
  input {
    width: 100%;
    padding: 0.75rem;
    background: ${(props) => props.theme.bg};
    border: 1px solid ${(props) => props.theme.researchBorder};
    border-radius: 6px;
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 2rem;
  }
  .edit_address_buttons {
    display: flex;
    gap: 2rem;
    button {
      background: 0;
      border: 0;
      border-radius: 6px;
      padding: 0.75rem 2.5rem;
      color: white;
    }
    .edit_rename {
      background: ${(props) => props.theme.brandBlue};
      border: 1px solid ${(props) => props.theme.brandBlue};
      :hover {
        background: ${(props) => props.theme.darkBrandBlue};
        border-color: ${(props) => props.theme.darkBrandBlue};
      }
    }
    .edit_cancel {
      border: 1px solid ${(props) => props.theme.researchBorder};
      :hover {
        background: ${(props) => props.theme.bg};
      }
    }
    @media (max-width: ${breakPoints.lg}px) {
      button {
        flex: 1;
        padding: 0.75rem 0;
      }
    }
  }
`;

const EditAddressModal = ({ handleClose, open }) => {
  const dispatch = useDispatch();
  const { address } = useSelector((state) => state.editAddress);

  return (
    <Modal open={open} handleCloseModal={handleClose} title="Edit address" maxwidth="700px" width="100%">
      <Wrapper>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            dispatch(editAddressOperations.updateAddress());
            handleClose();
          }}
        >
          <label>Label</label>
          <input
            value={address.title}
            onChange={(e) => dispatch(editAddressOperations.handleTitleChange(e.target.value))}
          />
          <p>{address.address}</p>
          <div className="edit_address_buttons">
            <button className="edit_rename" type="submit">
              Rename Wallet
            </button>
            <button
              className="edit_cancel"
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </Wrapper>
    </Modal>
  );
};

export default EditAddressModal;
