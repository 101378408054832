import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { ReactComponent as ArrowDown } from "../../assets/arrowDown.svg";
import { beautifyTokensAmount, tokensLogos } from "../../helpers";
import { breakPoints } from "../../Theme";

const Wrapper = styled.div`
  margin-top: 1rem;
  .lp_platform_title_container {
    display: flex;
    align-items: flex-end;
    h3 {
      color: ${(props) => props.theme.mainDarkText};
      background: ${(props) => props.theme.researchBorder};
      margin: 0 0.75rem 0 0;
      padding: 0.5rem 1rem;
      font-size: 16px;
      width: max-content;
      border-radius: 6px;
    }
    span {
      padding: 6px 9px;
      color: white;
      background: ${(props) => props.theme.disabled};
      border-radius: 6px;
    }
    .lp_platform_version_first {
      background: ${(props) => props.theme.green3};
    }
  }
`;

const LPPlatformTableWrapper = styled.table`
  margin: 1rem 0 2rem;
  width: 100%;
  border-collapse: collapse;
  @media (max-width: ${breakPoints.md}px) {
    display: none;
  }
  :last-of-type {
    margin-bottom: 0;
  }
  thead {
    color: ${(props) => props.theme.form_text};
    text-align: left;
    tr {
      th {
        font-size: 16px;
        border-bottom: 1px solid ${(props) => props.theme.researchBorder};
        padding-bottom: 10px;
      }
    }
  }
`;

// const LPPlatformMobileTableWrapper = styled.div`
//   margin-top: 1rem;
//   display: none;
//   @media (max-width: ${breakPoints.md}px) {
//     display: block;
//   }
// `;

const StyledTableCell = styled.td`
  cursor: ${(props) => (props.noAssets ? "normal" : "pointer")};
  border-bottom: 1px solid ${(props) => props.theme.researchBorder};
  :last-of-type {
    > div {
      padding-left: 0;
    }
  }
  > div {
    padding: 0.75rem;
    display: flex;
    align-items: center;
    ${(props) =>
      props.spaceBetween &&
      css`
        justify-content: space-between;
      `}
    .lp_platform_table_icons {
      display: flex;
      img {
        height: 32px;
        z-index: 2;
        :last-of-type {
          z-index: 1;
          transform: translateX(-10px);
        }
      }
    }
    .lp_platform_table_title {
      .lp_platform_table_token {
        font-size: 16px;
      }
      .lp_platform_table_amount {
        font-size: 14px;
        color: ${(props) => props.theme.form_text};
      }
    }
  }
  .lp_platform_table_manage {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: ${(props) => props.theme.form_text};
    span {
      margin-right: 0.5rem;
    }
    svg {
      height: 14px;
      width: 15px;
      path {
        fill: ${(props) => props.theme.form_text};
      }
    }
  }
`;

const LPDetails = styled.td`
  padding: 0.75rem;
  width: 100%;

  .lp_details_token {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    width: 100%;
    .lp_details_title {
      display: flex;
      align-items: center;
      img {
        height: 24px;
        width: 24px;
        object-fit: contain;
        margin-right: 0.5rem;
      }
    }
  }
  .lp_details_buttons {
    display: flex;
    margin-top: 1rem;
    a {
      flex: 1;
      text-align: center;
      padding: 10px 0;
      background: ${(props) => props.theme.brandBlue};
      color: white;
      border-radius: 6px;
      :first-of-type {
        margin-right: 2rem;
      }
      :hover {
        background: ${(props) => props.theme.primaryButtonHover};
      }
    }
    .user-liquidity-button-disabled {
      cursor: default;
      background: ${(props) => props.theme.disabled};
      pointer-events: none;
    }
  }
`;

// const LPMobileCardWrapper = styled.div`
//   background: white;
//   border-radius: 6px;
//   border: 1px solid ${(props) => props.theme.border2};
//   padding: 1rem;
//   margin-bottom: 1rem;
//   .lp_platform_mobile_row {
//     display: flex;
//     padding: 10px 0;
//     border-bottom: 1px solid ${(props) => props.theme.border2};
//     :first-of-type {
//       padding-top: 0;
//     }
//     > span {
//       flex: 1;
//       display: flex;
//     }
//     > button {
//       display: flex;
//       align-items: center;
//       margin: 0 auto;
//       background: none;
//       border: none;
//       font-size: 16px;
//       color: ${(props) => props.theme.brandBlue};
//       text-decoration: underline;
//       svg {
//         width: 20px;
//         height: 14px;
//         path {
//           fill: ${(props) => props.theme.brandBlue};
//         }
//       }
//     }

//     .lp_platform_mobile_row_title {
//       color: ${(props) => props.theme.brandBlue};
//     }
//     .lp_platform_mobile_row_images {
//       position: relative;
//       display: flex;
//       margin-right: 6px;
//       img {
//         display: block;
//         height: 20px;
//         :first-of-type {
//           z-index: 2;
//         }
//         :last-of-type {
//           transform: translateX(-0.3rem);
//           margin-right: -0.3rem;
//         }
//       }
//     }
//   }
//   .lp_platform_open_section {
//     font-size: 14px;
//     margin-top: 0.5rem;
//     > div {
//       display: flex;
//       padding: 10px 0;
//       border-bottom: 1px solid ${(props) => props.theme.border2};
//       span {
//         flex: 1;
//         display: flex;
//         align-items: center;
//         :first-of-type {
//           color: ${(props) => props.theme.brandBlue};
//           font-size: 12px;
//         }
//         img {
//           height: 20px;
//           margin-right: 0.25rem;
//         }
//       }
//     }
//     > a {
//       ${Button} {
//         margin: 1rem 0 0;
//         width: 100%;
//         text-transform: capitalize;
//       }
//     }
//   }
// `;

const calculateLPValue = (token, prices) => {
  const { assetA, assetB, [assetA]: valueA, [assetB]: valueB } = token;

  const totalA = prices[assetA] * valueA;
  const totalB = prices[assetB] * valueB;

  return beautifyTokensAmount(totalA + totalB, {
    prefix: "$",
    suffix: " USD",
  });
};

// const LPPlatformMobileCard = ({ item, prices, t, title, version }) => {
//   const [open, setOpen] = useState(false);
//   return (
//     <LPMobileCardWrapper>
//       <div className="lp_platform_mobile_row">
//         <span className="lp_platform_mobile_row_title">Asset</span>
//         <span>
//           <div className="lp_platform_mobile_row_images">
//             {tokensLogos[item.assetA]}
//             {tokensLogos[item.assetB]}
//           </div>
//           {item.assetA}:{item.assetB}
//         </span>
//       </div>
//       <div className="lp_platform_mobile_row">
//         <span className="lp_platform_mobile_row_title">Balance</span>
//         <span>{beautifyTokensAmount(item.liquidity)}</span>
//       </div>
//       <div className="lp_platform_mobile_row">
//         <span className="lp_platform_mobile_row_title">Value</span>
//         <span>{calculateLPValue(item, prices)}</span>
//       </div>
//       <div className="lp_platform_mobile_row" style={{ border: "0", paddingBottom: "0" }}>
//         <button onClick={() => setOpen((prev) => !prev)}>
//           {t("addition.manage")} <ArrowDown style={{ transform: open ? "rotate(180deg)" : "" }} />
//         </button>
//       </div>
//       <div className="lp_platform_open_section" style={{ display: open ? "block" : "none" }}>
//         <div>
//           <span>{t("addition.pooledToken", { token: item.assetA })}</span>
//           <span>{beautifyTokensAmount(item[item.assetA])}</span>
//         </div>
//         <div>
//           <span>
//             {tokensLogos[item.assetB]} Pooled {item.assetB}
//           </span>
//           <span>{beautifyTokensAmount(item[item.assetB])}</span>
//         </div>
//         <div>
//           <span>{t("addition.yourPoolShare")}</span>
//           <span>
//             {beautifyTokensAmount(item.liquidityPercent * 100, {
//               suffix: "%",
//             })}
//           </span>
//         </div>
//         <Link to={`/pools/add-liquidity/${title}?input1=${item.assetA}&input2=${item.assetB}&v=${version}`}>
//           <Button primary>Add liquidity</Button>
//         </Link>
//         <Link to={`/pools/remove-liquidity/${title}?input1=${item.assetA}&input2=${item.assetB}&v=${version}`}>
//           <Button primary>Remove liquidity</Button>
//         </Link>
//       </div>
//     </LPMobileCardWrapper>
//   );
// };

const LPPlatformTableRow = ({ item, prices, title, network }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <tr onClick={() => setOpen(!open)}>
        <StyledTableCell>
          <div>
            <div className="lp_platform_table_icons">
              {tokensLogos[item.assetA]}
              {tokensLogos[item.assetB]}
            </div>
            <div className="lp_platform_table_title">
              <div className="lp_platform_table_token">
                {item.assetA}:{item.assetB}
              </div>
              <div className="lp_platform_table_amount">{beautifyTokensAmount(item.liquidity)}</div>
            </div>
          </div>
        </StyledTableCell>
        <StyledTableCell spaceBetween>
          <div>
            <div className="lp_platform_table_value">{calculateLPValue(item, prices)}</div>
            <div className="lp_platform_table_manage">
              <span>Manage</span>
              <ArrowDown style={{ transform: open && "rotate(180deg)" }} />
            </div>
          </div>
        </StyledTableCell>
      </tr>
      {open && (
        <tr>
          <LPDetails colSpan="2">
            <div>
              <div className="lp_details_token">
                <div className="lp_details_title">
                  {tokensLogos[item.assetA]} <div>Pooled {item.assetA}</div>
                </div>
                <div className="lp_details_token_value">{beautifyTokensAmount(item[item.assetA])}</div>
              </div>

              <div className="lp_details_token">
                <div className="lp_details_title">
                  {tokensLogos[item.assetB]} <div>Pooled {item.assetB}</div>
                </div>
                <div className="lp_details_token_value">{beautifyTokensAmount(item[item.assetB])}</div>
              </div>
              <div className="lp_details_token">
                Your pool share:{" "}
                <span>
                  {beautifyTokensAmount(item.liquidityPercent * 100, {
                    suffix: "%",
                  })}
                </span>
              </div>
              {(title !== "quickswap" || (title === "apeswap" && network !== "polygon")) && (
                <div className="lp_details_buttons">
                  <Link
                    to={`/pools/add-liquidity/${title}?input1=${item.assetA}&input2=${item.assetB}&v=${item.version}`}
                  >
                    Add liquidity
                  </Link>
                  <Link
                    to={`/pools/remove-liquidity/${title}?input1=${item.assetA}&input2=${item.assetB}&v=${item.version}`}
                  >
                    Remove liquidity
                  </Link>
                </div>
              )}
            </div>
          </LPDetails>
        </tr>
      )}
    </>
  );
};

const LPPlatformTable = ({ user, tokens, title, network }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      {/* {Object.keys(tokens)
        .reverse()
        .map((version, i) => (
          <>
            <div className="lp_platform_title_container">
              <h3>Liquidity pool</h3>
              <span className={i === 0 ? "lp_platform_version_first" : ""}>{version}</span>
            </div>

            <LPPlatformTableWrapper>
              <thead>
                <tr>
                  <th>Asset</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {tokens[version].length !== 0 ? (
                  tokens[version].map((item, i) => (
                    <LPPlatformTableRow
                      key={i}
                      item={item}
                      prices={user.usdPrices}
                      t={t}
                      title={title}
                      version={version}
                      network={network}
                    />
                  ))
                ) : (
                  <tr>
                    <StyledTableCell colSpan="2" noAssets>
                      <div>No assets</div>
                    </StyledTableCell>
                  </tr>
                )}
              </tbody>
            </LPPlatformTableWrapper>
            <LPPlatformMobileTableWrapper>
              {tokens[version].length !== 0 ? (
                tokens[version].map((item, i) => (
                  <LPPlatformMobileCard
                    key={i}
                    item={item}
                    prices={user.usdPrices}
                    t={t}
                    title={title}
                    version={version}
                  />
                ))
              ) : (
                <tr>
                  <StyledTableCell colSpan="2" noAssets>
                    <div>No assets</div>
                  </StyledTableCell>
                </tr>
              )}
            </LPPlatformMobileTableWrapper>
          </>
        ))} */}
      <LPPlatformTableWrapper>
        <thead>
          <tr>
            <th>Asset</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {tokens.length !== 0 ? (
            tokens.map((item, i) => (
              <LPPlatformTableRow key={i} item={item} prices={user.usdPrices} t={t} title={title} network={network} />
            ))
          ) : (
            <tr>
              <StyledTableCell colSpan="2" noAssets>
                <div>No assets</div>
              </StyledTableCell>
            </tr>
          )}
        </tbody>
      </LPPlatformTableWrapper>
    </Wrapper>
  );
};

export default LPPlatformTable;
