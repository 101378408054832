import React from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import styled from "styled-components";

// Assets
import { ReactComponent as EmailVerifiedIcon } from "../assets/email/email-verified.svg";
import Meta from "../Components/Meta";

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1000px;
  width: 100%;
  margin-top: 6.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => props.theme.default_color};
  h1 {
    margin: 2.5rem 0 3rem;
    font-size: 24px;
    font-weight: 500;
  }
  a {
    text-align: center;
    background: ${(props) => props.theme.brandBlue};
    border-radius: 6px;
    width: 100%;
    max-width: 280px;
    padding: 9px 0;
    font-size: 20px;
    font-weight: 500;
    color: ${(props) => props.theme.superLightBlue};
    :hover {
      background: ${(props) => props.theme.primaryHover};
    }
  }
`;

const EmailVerified = () => {
  const userData = useSelector((state) => state.userData);
  const { user, loading } = userData;
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Meta title="Email verified" />
      {!loading && user.emailApproved && user.access.allow && <Redirect to="/dashboard" />}
      {!user.id && !loading && <Redirect to="/signin" />}
      {!loading && !user.emailApproved && <Redirect to="/verify-email" />}
      <EmailVerifiedIcon />
      <h1>{t("auth.accountVerified")}</h1>
      <Link to="/access">{t("auth.accessPage")}</Link>
    </Wrapper>
  );
};

export default EmailVerified;
