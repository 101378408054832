import React from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";

import { poolsLogos } from "../../helpers";
import { TxQueueItem } from "../../Pages/Actions/Actions.styles";
import { handleLink } from "../Strategy/StrategyCard";

const Wrapper = styled.div`
  padding: 1rem;
  position: absolute;
  z-index: 10;
  top: 120%;
  left: 0;
  width: 300px;
  background: ${(props) => props.theme.secondaryBg};
  border: 1px solid ${(props) => props.theme.researchBorder};
  border-radius: 6px;
  cursor: default;
  ::after {
    z-index: 9;
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 100%;
    height: 9px;
    width: 100%;
  }
  > h4 {
    margin-bottom: 1rem;
  }
  ${TxQueueItem} {
    :last-of-type {
      margin-bottom: 0;
    }
  }
  p {
    color: ${(props) => props.theme.lightGrey};
    font-size: 14px;
  }
`;

const TXQueueCard = ({ ...attrs }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const txQueueList = useSelector((state) => state.txQueue.txQueueList);

  return (
    <Wrapper {...attrs}>
      {/* {showEditModal && <AddActionModal editModal handleCloseModal={() => setShowEditModal(false)} />} */}

      <h4>
        {t("action.txQueue")} ({txQueueList.length})
      </h4>
      {txQueueList.length !== 0 ? (
        txQueueList.map((tx) => (
          <TxQueueItem key={tx.action}>
            <div className="tx_item_main" onClick={() => history.push(handleLink(tx, tx.args))}>
              {poolsLogos[tx.name]}
              <span>
                <h4>{tx.action}</h4>
                <h5>{tx.name}</h5>
              </span>
            </div>
            {/* <div className="tx_item_buttons">
              <div className="tx_item_buttons">
                <button onClick={() => handleEditOpenModal(tx)}>
                  <Edit />
                </button>
                <button onClick={() => dispatch(txQueueOperations.removeFromTxQueue(tx._id))}>
                  <Trash />
                </button>
              </div>
            </div> */}
          </TxQueueItem>
        ))
      ) : (
        <p>{t("action.txQueueIsEmpty")}</p>
      )}
    </Wrapper>
  );
};

export default TXQueueCard;
