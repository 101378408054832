import React, { useState } from "react";

import { connect } from "react-redux";
import styled, { css } from "styled-components";

import { ReactComponent as ChevronDownIcon } from "../assets/header/chevron-down.svg";
import Modal from "./Modal";

const DropDownWrapper = styled.div``;
const StyledSelect = styled.div`
  width: 200px;
  /* height: 42px; */
  padding: 0.5rem;
  color: ${(props) => props.theme.default_color};
  border-radius: 6px;
  // background: ${(props) => props.theme.blueTransparent};
  // border: 1px solid ${(props) => props.theme.brandBlue};
  background: #101926;
  border: 1px solid ${(props) => (props.selected ? "#3A4C61" : props.theme.researchBorder)};
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;

  ${(props) =>
    props.open
      ? css`
          background: ${(props) => (props.selected ? "#101926" : props.theme.bg)};
          border: 1px solid ${(props) => (props.selected ? "#3A4C61" : props.theme.researchBorder)};
        `
      : css`
          background: #122a4b;
          border: 1px solid #005ad3;
        `}

  .selectedOption {
    display: flex;
    align-items: center;
    font-weight: 500;
    line-height: normal;

    svg,
    img {
      width: 28px;
      height: 28px;
      margin-right: 5px;
    }
  }

  .selected__arrow {
    margin-left: auto;
    svg {
      height: 100%;
      width: 14px;
    }
    path {
      fill: ${(props) => props.theme.default_color};
    }
  }
`;

const StyledOptions = styled.div`
  margin-top: 32px;
  color: ${(props) => props.theme.mainDarkText};
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  justify-content: flex-start;
  flex-direction: column;
  max-height: 350px;
  min-height: 350px;
  overflow-y: auto;
  span {
    margin-left: 5px;
  }
  svg,
  img {
    width: 32px !important;
    height: 32px !important;
  }
`;

const StyledOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  height: 56px;
  cursor: pointer;

  color: ${(props) => props.theme.default_color};
  font-size: 16px;
  line-height: 20px;
  .token-info {
    display: flex;
    align-items: center;
    font-weight: 500;
    line-height: normal;
  }

  .token-info span {
    margin-left: 0.5em;
  }
  :hover {
    background: ${(props) => props.theme.bg};
    border-radius: 6px;
  }

  border-top: 1px solid ${(props) => props.theme.form_text3};
`;

function DropDown({ options, selectedOption, setOption }) {
  const [showOptions, setShowOptions] = useState(false);

  const handleShowOptions = () => {
    setShowOptions(!showOptions);
  };

  // handle select an option from the dropdown
  const handleSelect = (value) => {
    setOption(value);
    setShowOptions(false);
  };

  return (
    <DropDownWrapper>
      <StyledSelect onClick={handleShowOptions} selectedOption={selectedOption} open={selectedOption}>
        <span className="selectedOption">
          {selectedOption.icon}
          {selectedOption.text}
        </span>
        <ChevronDownIcon className="selected__arrow" />
      </StyledSelect>
      {showOptions && (
        <Modal handleCloseModal={() => setShowOptions(false)} width="400px" title="Select platform">
          <StyledOptions>
            {options.map((option) => (
              <StyledOption key={option.text} onClick={() => handleSelect(option)}>
                <div className="token-info">
                  {option.icon}
                  <span>{option.text}</span>
                </div>
              </StyledOption>
            ))}
          </StyledOptions>
        </Modal>
      )}
    </DropDownWrapper>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(DropDown);
