import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { ReactComponent as EditIcon } from "../../../assets/strategy/edit.svg";
import { ReactComponent as CirclePlus } from "../../../assets/strategy/plus.svg";
import { ReactComponent as TrashIcon } from "../../../assets/strategy/trash.svg";
import { poolsLogos } from "../../../helpers";
import { strategyActionActions } from "../../../redux/strategyActionsSlice";
import { strategyGroupActions, strategyGroupOperations } from "../../../redux/strategyGroupSlice";
import { strategyActions } from "../../../redux/strategySlice";
import CreateGroupModal from "../CreateGroupModal";
import EditActionModal from "../EditActionModal";
import EditGroupModal from "../EditGroupModal";
import { handleLink } from "../StrategyCard";
import { CreateButton, StrategyAction, StrategyGroupItem } from "../StrategyCard.styles";

const StrategyActionGroup = ({ strategy }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [createModal, setCreateModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editActionModal, setEditActionModal] = useState(false);

  const handleEditStrategy = (item) => {
    dispatch(strategyActions.setActiveStrategy(item));
    setCreateModal(true);
  };

  const handleOpenEditGroup = (item) => {
    dispatch(strategyGroupActions.setActiveGroup(item));
    setEditModal(true);
  };

  const handleOpenEditAction = (item) => {
    dispatch(strategyActionActions.setActiveAction(item));
    setEditActionModal(true);
  };
  const { t } = useTranslation();

  return (
    <>
      <CreateGroupModal open={createModal} handleClose={() => setCreateModal(false)} />
      {editModal && <EditGroupModal open={editModal} handleClose={() => setEditModal(false)} />}
      {editActionModal && <EditActionModal editModal handleCloseModal={() => setEditActionModal(false)} />}
      <CreateButton newGroup onClick={() => handleEditStrategy(strategy)}>
        <CirclePlus />
        {t("strategy.createNewGroup")}
      </CreateButton>
      <div className="strategy_groups">
        {strategy.groups.length !== 0 &&
          strategy.groups.map((group) => (
            <StrategyGroupItem key={group.id}>
              <div className="strategy_group_title">
                <h4>{group.name}</h4>
                <button onClick={() => handleOpenEditGroup(group)}>
                  <EditIcon />
                </button>
              </div>
              <div className="strategy_group_actions">
                {group.actions.map((action, i) => (
                  <StrategyAction key={Math.random()}>
                    <div className="strategy_action_number">{i + 1}</div>
                    {poolsLogos[action.templateId.name]}
                    <div
                      className="strategy_action_name"
                      onClick={() => history.push(handleLink(action.templateId, action.args))}
                    >
                      <h5>{action.templateId.name}</h5>
                      <h6>{action.templateId.action}</h6>
                    </div>
                    <div className="strategy_action_buttons">
                      <button onClick={() => handleOpenEditAction(action)}>
                        <EditIcon />
                      </button>
                      <button
                        onClick={() =>
                          dispatch(strategyGroupOperations.deleteActionFromGroup(group.id, action._id, true))
                        }
                      >
                        <TrashIcon />
                      </button>
                    </div>
                  </StrategyAction>
                ))}
                <CreateButton onClick={() => handleOpenEditGroup(group)}>
                  <CirclePlus />
                  New Action
                </CreateButton>
              </div>
            </StrategyGroupItem>
          ))}
      </div>
    </>
  );
};

export default StrategyActionGroup;
