import { WalletProviderId, isMobile } from "@keyfi/keyfi-common";
import { whitelist } from "@keyfi/keyfi-common/src/integrations";
import { networkNames } from "@keyfi/keyfi-common/src/integrations/common/constants";
import {
  getCurrentAccountAddress,
  getNetwork,
  getWeb3,
  resetWeb3,
} from "@keyfi/keyfi-common/src/integrations/common/web3";
import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import BigNumber from "bignumber.js";

import { Storage, StorageKey } from "../helpers/Storage";
import { appActions } from "./appSlice";
import { setCurrentUser } from "./user/actions";
import { stackBalanceSelectors } from "./wallets/stackBalanceSlice";

export const supportedNetworks = [
  "mainnet",
  "bsc-mainnet",
  "polygon",
  "avalanche",
  "fantom",
  "arbitrum",
  "optimism",
  "cronos",
  "metis",
  "moonriver",
  "okex",
  "telos",
  "aurora",
  "moonbeam",
];

const initialState = {
  web3: false,
  isLoading: false,
  selectedAddress: "",
  network: {
    name: "",
    chainId: null,
  },
  tokens: [],
  staking: [],
  lp: [],
  providerId: null,
};

const wallet = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setWeb3(state, action) {
      state.web3 = action.payload;
    },
    setSelectedAddress(state, action) {
      state.selectedAddress = action.payload;
    },
    setNetwork(state, action) {
      state.network = action.payload;
    },
    setProviderId(state, action) {
      state.providerId = action.payload;
    },
    setTokens(state, action) {
      state.tokens = action.payload;
    },
    setStaking(state, action) {
      state.staking = action.payload;
    },
    setLp(state, action) {
      state.lp = action.payload;
    },
  },
});

export const walletActions = wallet.actions;

const getRoot = (state) => state.wallet;

export const walletSelectors = {
  getLoading: (state) => getRoot(state).isLoading,
  getWeb3: (state) => getRoot(state).web3,
  getSelectedAddress: (state) => getRoot(state).selectedAddress,
  getNetwork: (state) => getRoot(state).network,
  getProviderId: (state) => getRoot(state).providerId,
  getTokens: (state) => getRoot(state).tokens,
  getStaking: (state) => getRoot(state).staking,
  getLp: (state) => getRoot(state).lp,
};

export const walletOperations = {
  setProviderId: (providerId, addressId) => async (dispatch) => {
    dispatch(walletActions.setProviderId(providerId));
    if (!isMobile() && providerId === WalletProviderId.SelfKey) {
      Storage.setItem(StorageKey.selectedProvider, null);
    } else if (providerId === "Read-only mode") {
      Storage.setItem(StorageKey.selectedProvider, providerId);
      Storage.setItem(StorageKey.selectedAddress, addressId);
    } else {
      Storage.setItem(StorageKey.selectedProvider, providerId);
    }
    // set transaction in storage
    Storage.getItem("transactions") || Storage.setItem("transactions", "[]");
  },

  /**
   * Load wallet using stored preferences
   */
  loadLocalProvider: () => async (dispatch) => {
    const providerId = Storage.getItem(StorageKey.selectedProvider);

    if (!providerId) {
      console.log("Local provider not found");
      return;
    }

    if (providerId === "Read-only mode") {
      const addressId = Storage.getItem(StorageKey.selectedAddress);
      return await dispatch(walletOperations.setProviderId(providerId, addressId));
    }

    await dispatch(walletOperations.setProviderId(providerId));
  },

  initialize: () => async (dispatch, getState) => {
    let providerId = walletSelectors.getProviderId(getState());

    if (!providerId) {
      await dispatch(walletOperations.loadLocalProvider());
    }

    providerId = walletSelectors.getProviderId(getState());

    if (!providerId) {
      return;
    }

    try {
      if (providerId === "Read-only mode") {
        const addressId = Storage.getItem(StorageKey.selectedAddress);
        if (addressId) {
          return await dispatch(walletOperations.connectReadOnly(addressId));
        }
      }
      return await dispatch(walletOperations.connect(providerId, true));
    } catch (err) {
      console.log(err);
    }
  },
  connect: (providerId, init, ignoreCache) => async (dispatch, getState) => {
    const state = getState();

    if (providerId) {
      await dispatch(walletOperations.setProviderId(providerId));
    } else {
      providerId = walletSelectors.getProviderId(state);
    }

    if (providerId === WalletProviderId.Metamask && typeof window.ethereum === "undefined") {
      alert("Please install Metamask!");
      return;
    }

    const web3 = await getWeb3(providerId, init);
    if (!web3) {
      dispatch(appActions.setLoading(false));
      return;
    }
    dispatch(walletActions.setWeb3(web3));
    const selectedAddress = getCurrentAccountAddress(web3);

    dispatch(
      setCurrentUser({
        id: selectedAddress,
      })
    );

    try {
      dispatch(walletActions.setWeb3(web3));
      dispatch(walletActions.setSelectedAddress(selectedAddress));
      const network = await getNetwork(web3);
      dispatch(walletActions.setNetwork(network));
      dispatch(setCurrentUser({ readOnly: false }));
      dispatch(walletOperations.loadUserData(0, ignoreCache));
    } catch (err) {
      console.log(err);
    }
  },

  connectReadOnly: (address) => async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      dispatch(setCurrentUser({ readOnly: true, id: address }));
      dispatch(walletOperations.setProviderId("Read-only mode", address));
      dispatch(walletActions.setSelectedAddress(address));
      dispatch(walletActions.setNetwork({ chainId: 1, name: "mainnet" }));
      dispatch(walletOperations.loadUserData());
    } catch (err) {
      console.log(err);
    }
  },

  disconnect: () => async (dispatch, getState) => {
    try {
      const state = getState();
      const providerId = walletSelectors.getProviderId(state);
      const web3 = await getWeb3(providerId);

      localStorage.setItem("walletconnect", null);
      dispatch(walletActions.setSelectedAddress(null));
      await dispatch(walletOperations.setProviderId(null));

      if (web3.currentProvider.disconnect) {
        web3.currentProvider.disconnect();
      }

      dispatch(walletActions.setTokens([]));
      dispatch(walletActions.setStaking([]));
      dispatch(walletActions.setLp([]));
      resetWeb3();
    } catch (error) {
      console.error(error);
    }
  },

  // TODO: Move to userSlice
  loadUserData: (retryNumber, ignoreCache) => async (dispatch, getState) => {
    if (retryNumber >= 4) {
      throw new Error("Too many requests");
    }
    const { user } = getState();

    let selectedAddress;

    try {
      if (user.readOnly) {
        selectedAddress = user.id;
      } else {
        selectedAddress = walletSelectors.getSelectedAddress(getState());
        // "0x2a9aC1Cab57d80e5E3c4574330863d54Ae311C68";
        // "0x154a309479E3CC5B40A363a419262601b9502B40";
      }

      if (!selectedAddress) {
        return;
      }
      if (!user.readOnly) {
        const isWhitelisted = (await whitelist.isSupportedNetwork())
          ? await whitelist.isWhitelisted(selectedAddress)
          : undefined;

        // Legacy action, will need refator later
        dispatch(
          setCurrentUser({
            readOnly: false,
            id: selectedAddress,
            isWhitelisted,
          })
        );
      }

      // Fetch balances in parallel the most we can
      const [usdPrices, tokens, lp, stakingTotal] = await Promise.all([
        axios.get(`${process.env.REACT_APP_BALANCE_API}/prices`),
        axios.get(`${process.env.REACT_APP_BALANCE_API}/tokens?address=${selectedAddress}&ignoreCache=${ignoreCache}`),
        axios.get(
          `${process.env.REACT_APP_BALANCE_API}/liquidity?address=${selectedAddress}&ignoreCache=${ignoreCache}`
        ),
        axios.get(`${process.env.REACT_APP_BALANCE_API}/staking?address=${selectedAddress}&ignoreCache=${ignoreCache}`),
      ]);

      dispatch(
        setCurrentUser({
          id: selectedAddress,
          tokens,
          usdPrices: usdPrices.data,
        })
      );
      dispatch(walletActions.setTokens(tokens.data));
      dispatch(walletActions.setLp(lp.data));
      dispatch(walletActions.setStaking(stakingTotal.data));

      const showStack = stackBalanceSelectors.getShowStack(getState());
      if (!showStack) {
        dispatch(appActions.setLoading(false));
      }
    } catch (err) {
      if (retryNumber < 4) {
        setTimeout(() => dispatch(walletOperations.loadUserData(retryNumber + 1)), 18000);
      }
    }
  },
  getNetworkValue: (network) => (dispatch, getState) => {
    const prices = getState().user.usdPrices;
    const tokens = walletSelectors.getTokens(getState());
    const staking = walletSelectors.getStaking(getState());
    const lp = walletSelectors.getLp(getState());
    const tokenBlacklist = getState().userData.user.tokenBlacklist;

    const tokenBlacklistFilter = (item) =>
      !tokenBlacklist.some((token) => token.network === item.network && token.address === item.address);

    const tokensBalance = tokens.filter(tokenBlacklistFilter).reduce((acc, token) => {
      if (token.network === network) {
        return BigNumber(token.amount)
          .times(prices[token.symbol] ?? 0)
          .plus(acc)
          .toPrecision(6);
      }
      return acc;
    }, 0);

    const stakingBalance = staking.filter(tokenBlacklistFilter).reduce((acc, token) => {
      if (token.network === network) {
        if (token.type === "token") {
          return BigNumber(token.amount)
            .times(prices[token.token] ?? 0)
            .plus(acc)
            .toPrecision(6);
        } else {
          const [assetA, assetB] = [token.token.split(":")[0], token.token.split(":")[1].split(" ")[0]];

          const assetAValue = prices[assetA] * token[assetA];
          const assetBValue = prices[assetB] * token[assetB];

          return BigNumber(acc).plus(assetAValue).plus(assetBValue).toPrecision(6);
        }
      }
      return acc;
    }, 0);

    const lpBalance = lp.filter(tokenBlacklistFilter).reduce((acc, token) => {
      if (token.network === network) {
        const assetAValue = prices[token.assetA] * token[token.assetA];
        const assetBValue = prices[token.assetB] * token[token.assetB];

        return BigNumber(acc).plus(assetAValue).plus(assetBValue).toPrecision(6);
      }
      return acc;
    }, 0);

    const totalBalance = BigNumber(tokensBalance).plus(stakingBalance).plus(lpBalance).toPrecision(6);
    return { tokens: tokensBalance, staking: stakingBalance, lp: lpBalance, total: totalBalance };
  },
  getNetworkTokens: (network) => (dispatch, getState) => {
    const tokens = walletSelectors.getTokens(getState());
    return tokens.filter((token) => token.network === network);
  },
  getNetworkStakedTokens: (network, platform, object, version) => (dispatch, getState) => {
    const staking = walletSelectors.getStaking(getState());
    const data = staking
      .filter((token) => {
        if (platform) {
          return token.network === network && token.platform === platform;
        }
        return token.network === network;
      })
      .filter((token) => (version ? token.version === version : true));
    if (object) {
      return data.reduce((acc, token) => {
        return { ...acc, [token.symbol]: token.amount };
      }, {});
    }

    return data;
  },
  getNetworkLP: (network, platform) => (dispatch, getState) => {
    const lp = walletSelectors.getLp(getState());
    return lp.filter((token) => {
      if (platform) {
        return token.network === network && token.platform === platform;
      }
      return token.network === network;
    });
  },
  getNetworkPlatformValue: (network, platform, forLp, version) => (dispatch, getState) => {
    const lp = walletSelectors.getLp(getState());
    const staking = walletSelectors.getStaking(getState());
    const prices = getState().user.usdPrices;

    if (forLp) {
      const lpBalance = lp.reduce((acc, token) => {
        if (token.network === network && token.platform === platform) {
          const assetAValue = prices[token.assetA] * token[token.assetA];
          const assetBValue = prices[token.assetB] * token[token.assetB];

          return BigNumber(acc).plus(assetAValue).plus(assetBValue).toPrecision(6);
        }
        return acc;
      }, 0);
      return lpBalance;
    }

    const stakingBalance = staking.reduce((acc, token) => {
      if (token.network === network && token.platform === platform && (version ? token.version === version : true)) {
        if (token.type === "token") {
          return BigNumber(token.amount)
            .times(prices[token.token] ?? 0)
            .plus(acc)
            .toPrecision(6);
        } else {
          const [assetA, assetB] = [token.token.split(":")[0], token.token.split(":")[1].split(" ")[0]];

          const assetAValue = prices[assetA] * token[assetA];
          const assetBValue = prices[assetB] * token[assetB];

          return BigNumber(acc).plus(assetAValue).plus(assetBValue).toPrecision(6);
        }
      }
      return acc;
    }, 0);

    return stakingBalance;
  },

  // Get total stack value
  getTotalBalance: () => (dispatch, getState) => {
    const prices = getState().user.usdPrices;
    const tokens = walletSelectors.getTokens(getState());
    const staking = walletSelectors.getStaking(getState());
    const lp = walletSelectors.getLp(getState());
    const tokenBlacklist = getState().userData.user.tokenBlacklist;

    const tokenBlacklistFilter = (item) =>
      !tokenBlacklist.some((token) => token.network === item.network && token.address === item.address);

    const tokensBalance = tokens.filter(tokenBlacklistFilter).reduce((acc, token) => {
      return BigNumber(token.amount)
        .times(prices[token.symbol] ?? 0)
        .plus(acc)
        .toPrecision(6);
    }, 0);

    const stakingBalance = staking.filter(tokenBlacklistFilter).reduce((acc, token) => {
      if (token.type === "token") {
        return BigNumber(token.amount)
          .times(prices[token.token] ?? 0)
          .plus(acc)
          .toPrecision(6);
      } else {
        const [assetA, assetB] = [token.token.split(":")[0], token.token.split(":")[1].split(" ")[0]];

        const assetAValue = prices[assetA] * token[assetA];
        const assetBValue = prices[assetB] * token[assetB];

        return BigNumber(acc).plus(assetAValue).plus(assetBValue).toPrecision(6);
      }
    }, 0);

    const lpBalance = lp.filter(tokenBlacklistFilter).reduce((acc, token) => {
      const assetAValue = prices[token.assetA.toUpperCase()] * token[token.assetA];
      const assetBValue = prices[token.assetB.toUpperCase()] * token[token.assetB];

      return BigNumber(acc).plus(assetAValue).plus(assetBValue).toPrecision(6);
    }, 0);

    const totalBalance = BigNumber(tokensBalance).plus(stakingBalance).plus(lpBalance).toPrecision(6);
    return totalBalance;
  },

  getWalletNetworkTokens: (network) => (dispatch, getState) => {
    const tokens = walletSelectors.getTokens(getState());

    if (typeof network !== "string") {
      network = networkNames[network];
    }

    return tokens
      .filter((item) => item.network === network)
      .reduce((acc, token) => {
        return { ...acc, [token.symbol]: token.amount };
      }, {});
  },
};

export const walletReducer = wallet.reducer;
