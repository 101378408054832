import React from "react";

import { Helmet } from "react-helmet";

const Meta = ({
  title = "KeyFi Pro",
  description = "Intelligent tools for managing your DeFi portfolio. Create custom DeFi strategies, project your yield, and manage your assets, all from one convenient application.",
}) => {
  return (
    <Helmet>
      <title>KeyFi Pro | {title}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
};

export default Meta;
