import styled, { css } from "styled-components";

import { breakPoints } from "../../Theme";

export const PopupWrap = styled.div`
  display: flex;

  .backButton {
    margin-bottom: 12px;
  }

  .left {
    margin-right: 32px;
    flex: 1;
    .cover {
      position: relative;
      border-radius: 6px;
      overflow: hidden;
      img {
        display: block;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
      .coverText {
        width: 100%;
        position: absolute;
        bottom: 0;
        opacity: 0.93;
        background: ${(props) => props.theme.bg};
        font-size: 1.25em;
        padding: 0.75rem 16px;
        color: ${(props) => props.theme.default_color};
      }
    }
  }

  .readNow {
    margin-right: 16px;
  }

  .tickets {
    display: flex;
  }

  .symbol {
    margin-right: 6px;
  }

  .toolsKit {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background: #182331;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }

  .toolWrap {
    display: flex;
    flex-direction: column;
  }

  .toolWrap > p {
    font-size: 10px;
    text-align: center;
  }

  .tool {
    height: 50px;
    width: 50px;
    border: 1px solid #3a4c61;
    display: flex;
    justify-content: center;
    align-items: center;
    &:active {
      background: #122a4b;
    }
  }

  .toolKitLogo {
    height: 25px;
    width: 25px;
  }
  @media (max-width: ${breakPoints.lg}px) {
    flex-direction: column;
    gap: 1.5rem;
    img,
    .coverText {
      width: 100% !important;
    }
    img {
      height: auto;
    }

    .left {
      margin-right: 0 !important;
    }
    .coverText {
      font-size: 18px;
    }
  }
`;

export const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 24.25em;

  @media (max-width: ${breakPoints.sm}px) {
    width: auto;
  }

  .btn {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
    a,
    button {
      display: flex;
      padding: 0.75rem 1.5rem;
      border-radius: 6px;
      @media (max-width: ${breakPoints.lg}px) {
        font-size: 14px;
      }
    }
    button {
      border: 1px solid ${(props) => props.theme.researchBorder};
      color: ${(props) => props.theme.mainDarkText};
    }
    a {
      gap: 0.5rem;
      align-items: center;
      color: white;
      background: ${(props) => props.theme.borrowGreen};
    }
  }

  .vote {
    display: flex;
  }

  .voteIcon {
    width: 16px;
    height: 16px;
    margin-top: 2px;
    margin-right: 6px;
  }

  .up {
    font-size: 1em;
    margin-right: 1.5em;
    display: flex;
  }

  .active {
    color: #4a9c6d;
  }

  .activeUnvote {
    color: red;
  }

  .inactive {
    color: ${(props) => props.theme.lightGrey};
  }

  .down {
    font-size: 1em;
    color: ${(props) => props.theme.lightGrey};
    display: flex;
  }

  .down > span {
    margin: 0 6px;
  }

  .info > p {
    font-size: 0.75em;
    margin-top: 0.875em;
  }

  .content {
    margin: 12px 0;

    @media (max-width: ${breakPoints.sm}px) {
      width: auto;
    }
  }

  .symbol {
    font-size: 0.75em;
    color: ${(props) => props.theme.form_text};
  }

  .up,
  .down {
    cursor: pointer;
  }
`;

export const Button = styled.button`
  font-size: 1em;
  text-align: center;
  border-radius: 6px;
  display: flex;
  align-items: center;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  padding: ${(props) => props.padding};
  margin: 12px 0;
  cursor: pointer;
  text-transform: capitalize;

  .svg {
    margin-left: 8px;
  }

  ${(props) =>
    props.borrowGreen &&
    css`
      border: 0;
      background: ${(props) => props.theme.borrowGreen};

      a {
        color: ${(props) => props.theme.white};
      }
    `}

  ${(props) =>
    props.hollow &&
    css`
      background: ${(props) => props.theme.secondaryBg};
      border: 1px solid ${(props) => props.theme.researchBorder};
      color: ${(props) => props.theme.default_color};
    `}
`;

export const CommentWrap = styled.div`
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background: ${(props) => props.theme.bg};

  @media (max-width: ${breakPoints.lg}px) {
    padding: 0.5rem;
    background: ${(props) => props.theme.bg};
    width: 100%;
  }

  .commentIcon {
    width: 16px;
    height: 16px;
    margin: auto 8px;
  }

  .numOfComment {
    color: ${(props) => props.theme.lightGrey};
    font-size: 12px;
    margin: auto 0;
  }
`;
