import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const API = process.env.REACT_APP_PRO_API;

const initialState = {
  isLoading: false,
  activeAction: {
    templateId: "",
    args: [],
  },
  actionList: [],
  txQueueList: [],
};

const txQueue = createSlice({
  name: "txQueue",
  initialState,
  reducers: {
    setLoading(state, action) {
      return { ...state, isLoading: action.payload };
    },
    setActiveAction(state, action) {
      return { ...state, activeAction: action.payload };
    },
    setActionList(state, action) {
      return { ...state, actionList: action.payload };
    },
    setTxQueueList(state, action) {
      return { ...state, txQueueList: action.payload };
    },
  },
});

export const txQueueActions = txQueue.actions;

const getRoot = (state) => state.txQueue;

export const txQueueSelectors = {
  getLoading: (state) => getRoot(state).isLoading,
  getActiveAction: (state) => getRoot(state).activeAction,
  getActionList: (state) => getRoot(state).actionList,
  getTxQueueList: (state) => getRoot(state).txQueueList,
};

export const txQueueOperations = {
  getActionTemplateList: () => async (dispatch, getState) => {
    try {
      const state = getState();
      const token = state.userData.tokens.access.token;

      const config = {
        headers: {
          Authorization: token,
        },
      };

      const { data } = await axios.get(`${API}/v1/action/action-templates`, config);
      dispatch(txQueueActions.setActionList(data));
    } catch (err) {
      console.log(err);
    }
  },

  handleActiveAction: (name, value) => async (dispatch, getState) => {
    const activeAction = txQueueSelectors.getActiveAction(getState());
    dispatch(txQueueActions.setActiveAction({ ...activeAction, [name]: value }));
  },
  handleActiveActionArgs: (key, value) => async (dispatch, getState) => {
    let activeAction = txQueueSelectors.getActiveAction(getState());
    let args = [...activeAction.args];
    args[key] = value;

    dispatch(txQueueActions.setActiveAction({ ...activeAction, args }));
  },
  addToTxQueueList: () => async (dispatch, getState) => {
    const txQueueList = txQueueSelectors.getTxQueueList(getState());
    const activeAction = txQueueSelectors.getActiveAction(getState());

    const actionTemplate = txQueueSelectors
      .getActionList(getState())
      .find((item) => item._id === activeAction.templateId);
    dispatch(txQueueActions.setTxQueueList([...txQueueList, { ...actionTemplate, args: activeAction.args }]));
    dispatch(txQueueActions.setActiveAction(initialState.activeAction));
  },
  updateTxQueueItem: () => async (dispatch, getState) => {
    const txQueueList = [...txQueueSelectors.getTxQueueList(getState())];
    const activeAction = txQueueSelectors.getActiveAction(getState());

    const activeActionIndex = txQueueList.findIndex((item) => item._id === activeAction.templateId);
    txQueueList[activeActionIndex] = { ...txQueueList[activeActionIndex], args: activeAction.args };
    dispatch(txQueueActions.setTxQueueList(txQueueList));
    dispatch(txQueueActions.setActiveAction(initialState.activeAction));
  },
  removeFromTxQueue: (id) => async (dispatch, getState) => {
    const txQueueList = [...txQueueSelectors.getTxQueueList(getState())];
    const newList = txQueueList.filter((item) => item._id !== id);
    dispatch(txQueueActions.setTxQueueList(newList));
  },
};

export const txQueueReducer = txQueue.reducer;
