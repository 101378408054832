import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { newsOperations } from "../../redux/Slices/newsSlice";
import { breakPoints } from "../../Theme";
import CommentMessage from "./CommentMessage";
import { CommentWrap } from "./Feed.styles";

const CommentFeedWrap = styled.div`
  flex: 1;
  overflow: scroll;
  @media (max-width: ${breakPoints.sm}px) {
    margin-right: 20px;
    width: calc(100vw - 72px);
    overflow: visible;
    height: auto;
    max-height: 100%;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;

const CommentBox = styled.form`
  position: relative;
  height: 96px;
  border: 1px solid #3a4c61;
  border-radius: 6px;

  @media (max-width: ${breakPoints.sm}px) {
    margin-top: 1em;
    width: 100%;
  }

  input {
    width: 472px;
    margin: 12px;
  }
`;

export const Button = styled.button`
  font-size: 1em;
  border-top-left-radius: 6px;
  border-bottom-right-radius: 6px;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  padding: ${(props) => props.padding};
  cursor: pointer;
  text-transform: capitalize;
  position: absolute;
  right: 0;
  bottom: 0;
  .svg {
    margin-left: 8px;
  }

  ${(props) =>
    props.borrowGreen &&
    css`
      border: 0;
      background: ${(props) => props.theme.borrowGreen};

      a {
        color: ${(props) => props.theme.white};
      }
    `}
`;

export const CommentFeed = ({ item }) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData);

  const [comments, setComments] = useState(item.comment);

  const [inputData, setInputData] = useState({
    text: "",
    replyTo: null,
    vote: [],
    downvote: [],
  });

  const handleAddComment = async (e) => {
    e.preventDefault();
    setComments((prev) => [
      ...prev,
      { ...inputData, author: { userName: userData.user.userName }, createdAt: new Date() },
    ]);
    dispatch(newsOperations.addComment(item._id, inputData));
    setInputData({
      text: "",
      replyTo: null,
    });
  };

  useEffect(() => {
    dispatch(newsOperations.getAllComment(item._id));
  }, [dispatch]);

  return (
    <CommentWrap>
      <CommentFeedWrap>
        <CommentMessage comments={comments} newsItem={item} />
      </CommentFeedWrap>
      <CommentBox onSubmit={handleAddComment}>
        <input
          value={inputData.text}
          onChange={(e) => {
            setInputData((prev) => ({ ...prev, text: e.target.value }));
          }}
          placeholder="Your comment..."
        />

        <Button type="submit" borrowGreen width="85px" height="34px">
          <a>Send</a>
        </Button>
      </CommentBox>
    </CommentWrap>
  );
};
