import React, { useEffect, useRef } from "react";

import BigNumber from "bignumber.js";
import { useSelector } from "react-redux";

import { ReactComponent as Arrow } from "../../assets/arrowWhite.svg";
import ETH from "../../assets/eth.svg";
import Arbitrum from "../../assets/platforms/arbitrum.svg";
import Aurora from "../../assets/platforms/Aurora.svg";
import Avalanche from "../../assets/platforms/avalanche.svg";
import Cronos from "../../assets/platforms/cronos.svg";
import Fantom from "../../assets/platforms/fantom.svg";
import Metis from "../../assets/platforms/Metis.svg";
import Moonbeam from "../../assets/platforms/Moonbeam.png";
import Moonriver from "../../assets/platforms/moonriver.svg";
import OKEx from "../../assets/platforms/OEC.svg";
import Optimism from "../../assets/platforms/optimism.svg";
import Telos from "../../assets/platforms/telos.svg";
import BSC from "../../assets/tokens/BSC.svg";
import POLYGON from "../../assets/tokens/MATIC.svg";
import { beautifyTokensAmount } from "../../helpers";
import { Skeleton } from "../../Shared";
import { breakPoints } from "../../Theme";
import { NetworkLabelsWrapper } from "./WalletContainer.helpers";

export const networks = [
  {
    id: "mainnet",
    name: "Ethereum",
    mobileName: "ETH",
    img: ETH,
  },
  {
    id: "bsc-mainnet",
    name: "BSC",
    mobileName: "BSC",
    img: BSC,
  },
  {
    id: "polygon",
    name: "Polygon",
    mobileName: "Polygon",
    img: POLYGON,
  },
  {
    id: "avalanche",
    name: "Avalanche",
    mobileName: "AVAX",
    img: Avalanche,
  },
  {
    id: "fantom",
    name: "Fantom",
    mobileName: "Fantom",
    img: Fantom,
  },
  {
    id: "arbitrum",
    name: "Arbitrum",
    mobileName: "Arbitrum",
    img: Arbitrum,
  },
  {
    id: "optimism",
    name: "Optimism",
    mobileName: "Optimism",
    img: Optimism,
  },
  {
    id: "cronos",
    name: "Cronos",
    mobileName: "Cronos",
    img: Cronos,
  },
  {
    id: "metis",
    name: "Metis",
    mobileName: "Metis",
    img: Metis,
  },
  {
    id: "moonriver",
    name: "Moonriver",
    mobileName: "Moonriver",
    img: Moonriver,
  },
  {
    id: "moonbeam",
    name: "Moonbeam",
    mobileName: "Moonbeam",
    img: Moonbeam,
  },
  {
    id: "okex",
    name: "OKEx",
    mobileName: "OKEx",
    img: OKEx,
  },
  {
    id: "telos",
    name: "Telos",
    mobileName: "Telos",
    img: Telos,
  },
  {
    id: "aurora",
    name: "Aurora",
    mobileName: "Aurora",
    img: Aurora,
  },
];

const NetworkLabels = ({ network, setNetwork, walletValues, selectedType = "total", walletData }) => {
  const ref = useRef(null);
  const wrapperRef = useRef(null);
  const leftButton = useRef(null);
  const rightButton = useRef(null);

  const app = useSelector((state) => state.app);
  const wallet = useSelector((state) => state.wallet);

  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };

  const onScroll = () => {
    if (ref.current) {
      const { scrollLeft, clientWidth, scrollWidth } = ref.current;
      if (Math.floor(scrollLeft) === 0) {
        leftButton.current.style.display = "none";
      } else {
        leftButton.current.style.display = "flex";
      }
      if (Math.floor(scrollLeft) + clientWidth === scrollWidth) {
        rightButton.current.style.display = "none";
      } else {
        rightButton.current.style.display = "flex";
      }
    }
  };

  const sortFunction = (a, b) => {
    return walletValues[b.id][selectedType] - walletValues[a.id][selectedType];
  };

  useEffect(() => {
    if (ref.current && wrapperRef.current) {
      if (ref.current.scrollWidth <= wrapperRef.current.scrollWidth) {
        leftButton.current.style.display = "none";
        rightButton.current.style.display = "none";
      } else {
        rightButton.current.style.display = "flex";
      }
    }
  });
  return (
    <NetworkLabelsWrapper walletData={walletData} ref={wrapperRef}>
      <button ref={leftButton} className="network_label_scroll scroll_left" onClick={() => scroll(-100)}>
        <Arrow style={{ transform: "rotate(90deg)" }} />
      </button>
      <div ref={ref} className="network_labels_scroll" onScroll={onScroll}>
        {networks
          .filter((item) => BigNumber(walletValues[item.id][selectedType]).gt(0))
          .sort(sortFunction)
          .map((item) => (
            <div
              key={item.id}
              className={`network_label ${network === item.id && "network_active"}`}
              onClick={() => setNetwork(item.id)}
            >
              <img src={item.img} alt={item.name} />
              <div className="network_label_info">
                <div className="network_label_title">
                  {window.innerWidth > breakPoints.lg ? item.name : item.mobileName}
                </div>
                <div className="network_label_total">
                  {app.isLoading && wallet.selectedAddress ? (
                    <Skeleton
                      height={breakPoints.lg > window.innerWidth ? "15" : "19"}
                      width={breakPoints.lg > window.innerWidth ? "40" : "80"}
                    />
                  ) : (
                    beautifyTokensAmount(walletValues[item.id][selectedType], { prefix: "$" })
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>{" "}
      <button ref={rightButton} className="network_label_scroll scroll_right" onClick={() => scroll(100)}>
        <Arrow style={{ transform: "rotate(-90deg)" }} />
      </button>
    </NetworkLabelsWrapper>
  );
};

export default NetworkLabels;
