import React, { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import {
  beautifyTokensAmount,
  calculateAPY,
  calculateTotal,
  getAPYRatesOnPlatform,
  poolsLogos,
  tokensLogos,
} from "../../helpers";
import { walletOperations } from "../../redux/walletSlice";
import { HeadInfo, IconTextWrapper, PoolTokenCard } from "../../Shared";
import { breakPoints } from "../../Theme";
import Button from "../Button";
import { calculateTokenPriceInUsd } from "../TxContentCard";

const CardWrapper = styled.div`
  padding: 1em;
  border-radius: 6px;
  background: ${(props) => props.theme.bg};
  border: 1px solid ${(props) => props.theme.darkGrey};
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  @media (max-width: ${breakPoints.lg}px) {
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-top: 1px solid ${(props) => props.theme.input_placefolder};
  margin-top: 1em;
  padding-top: 1rem;
`;

const Tokens = styled.div`
  flex: 1;
  margin: 0.75em 0;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
`;

const CTAButton = styled(Button)`
  background: linear-gradient(256.02deg, #0ca7eb 2.19%, #005ad3 188.15%);
  border: none;
  color: ${(props) => props.theme.white};
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  height: 35px;
  justify-content: center;

  :hover,
  :focus {
    background: ${(props) => props.theme.primaryHover};
  }
`;

function Card({ title, user, tokenList, platform, rates }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const balance = dispatch(walletOperations.getNetworkStakedTokens("mainnet", platform, true));

  const renderPoolTokensList = useMemo(
    () =>
      tokenList.map(([symbol, value]) => (
        <PoolTokenCard key={symbol}>
          <span className="token__name">
            {tokensLogos[symbol]} {symbol}
          </span>
          <span className="token__value">
            {beautifyTokensAmount(calculateTokenPriceInUsd(user.usdPrices, symbol, value.amount), {
              prefix: "$",
              suffix: " USD",
            })}
          </span>
        </PoolTokenCard>
      )),
    [tokenList, user.usdPrices]
  );

  return (
    <CardWrapper>
      <IconTextWrapper>
        {poolsLogos[title]}
        {title}
      </IconTextWrapper>
      <ContentWrapper>
        <HeadInfo>
          <span className="title">{t("common.totalDeposit")}:</span>
          <span className="value">
            {beautifyTokensAmount(calculateTotal(balance, user.usdPrices, true), {
              prefix: "$",
            })}
          </span>
        </HeadInfo>

        <HeadInfo>
          <span className="title">{t("common.netAPY")}:</span>
          <span className="value">
            {beautifyTokensAmount(calculateAPY(balance, user.usdPrices, getAPYRatesOnPlatform(rates, platform)), {
              suffix: "%",
            })}
          </span>
        </HeadInfo>
      </ContentWrapper>

      <Tokens>{renderPoolTokensList}</Tokens>
      <CTAButton primary onClick={() => history.push(`/deposit/${platform}`)}>
        {t("common.manage")}
      </CTAButton>
    </CardWrapper>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  rates: state.rates.rates,
});

export default connect(mapStateToProps)(Card);
