import React, { useEffect, useState } from "react";

import { getAddressSum } from "@keyfi/keyfi-common/src/integrations";
import { useTranslation } from "react-i18next";

import { ReactComponent as ArrowDown } from "../../assets/arrowDown.svg";
import { ReactComponent as MagnifyingGlass } from "../../assets/discovery/magnifyingGlass.svg";
import { assetImageChainID, beautifyTokensAmount, tokensLogos, wrapperNetworkToken } from "../../helpers";
import AssetImage from "../Borrow/AssetImage";
import Modal from "../Modal";
import { OptionsWrapper, StyledOption, StyledOptions, SwapDropdownWrapper } from "./SwapDropdown.styles";

const SwapDropdown = ({
  options,
  setOption,
  selectedOption,
  token,
  network,
  placeholder,
  error,
  networkTokens = {},
}) => {
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState(options);
  const { t } = useTranslation();

  const handleSelect = (data) => {
    setOpen(false);
    setSearchQuery("");
    setOption(data);
  };

  useEffect(() => {
    if (searchQuery.length !== 0) {
      setData(
        options
          .filter((item) =>
            token
              ? item.symbol.toLowerCase().includes(searchQuery.toLowerCase())
              : item.text.toLowerCase().includes(searchQuery.toLowerCase())
          )
          .sort((a, b) => {
            const aValue = networkTokens[token ? a.symbol : a.text] ?? 0;
            const bValue = networkTokens[token ? b.symbol : b.text] ?? 0;
            return bValue - aValue;
          })
          .slice(0, 20)
      );
    } else {
      setData(
        options
          .sort((a, b) => {
            const aValue = networkTokens[token ? a.symbol : a.text] ?? 0;
            const bValue = networkTokens[token ? b.symbol : b.text] ?? 0;
            return bValue - aValue;
          })
          .slice(0, 20)
      );
    }
  }, [searchQuery, options, network]);

  return (
    <>
      <SwapDropdownWrapper error={error} selected={open} onClick={() => setOpen(true)}>
        {selectedOption ? (
          token ? (
            <>
              <AssetImage
                fallbackLogo={tokensLogos[selectedOption.symbol]}
                className="swap_token_image"
                src={`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${
                  assetImageChainID[network]
                }/assets/${
                  selectedOption.address === "0x0000000000000000000000000000000000000000"
                    ? getAddressSum(wrapperNetworkToken[network])
                    : getAddressSum(selectedOption.address)
                }/logo.png`}
                alt={selectedOption.symbol}
              />
              <span>{selectedOption.symbol}</span>
              <ArrowDown />
            </>
          ) : (
            <>
              {selectedOption.img}
              <span>{selectedOption.text}</span>
              <ArrowDown />
            </>
          )
        ) : (
          <>
            <span className="swap_placeholder">{placeholder ?? t("swap.chooseAtoken")}</span>
            <ArrowDown />
          </>
        )}
      </SwapDropdownWrapper>
      {open && (
        <Modal
          title={token ? t("swap.chooseAtoken") : t("swap.chooseAplatform")}
          handleCloseModal={() => {
            setSearchQuery("");
            setOpen(false);
          }}
        >
          <OptionsWrapper>
            <div className="input-wrapper">
              <input
                className="search"
                type="text"
                placeholder={t("common.searchTokenName")}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <MagnifyingGlass />
            </div>
            {data.length ? (
              !token ? (
                <StyledOptions>
                  {data.map((option) => (
                    <StyledOption key={option.text} onClick={() => handleSelect(option)}>
                      <div className="token-info">
                        {option.img}
                        <span>{option.text}</span>
                      </div>
                      {token && <span className="token-value">{beautifyTokensAmount(networkTokens[option.text])}</span>}
                    </StyledOption>
                  ))}
                </StyledOptions>
              ) : (
                <StyledOptions>
                  {data.map((option) => (
                    <StyledOption key={option.address} onClick={() => handleSelect(option)}>
                      <div className="token-info">
                        <AssetImage
                          fallbackLogo={tokensLogos[selectedOption.symbol]}
                          className="swap_token_image"
                          src={`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${
                            assetImageChainID[network]
                          }/assets/${
                            option.address === "0x0000000000000000000000000000000000000000"
                              ? getAddressSum(wrapperNetworkToken[network])
                              : getAddressSum(option.address)
                          }/logo.png`}
                          alt={option.symbol}
                        />
                        <span>{option.symbol}</span>
                      </div>
                      {token && (
                        <span className="token-value">{beautifyTokensAmount(networkTokens[option.symbol])}</span>
                      )}
                    </StyledOption>
                  ))}
                </StyledOptions>
              )
            ) : (
              t("common.noResultFound")
            )}
          </OptionsWrapper>
        </Modal>
      )}
    </>
  );
};

export default SwapDropdown;
