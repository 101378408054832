import React, { useState } from "react";

import { availablePairs } from "@keyfi/keyfi-common/src/integrations/uniswap/constants/constants";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { ReactComponent as ArrowDown } from "../../assets/arrowWhite.svg";
import { ReactComponent as Horse } from "../../assets/chess_horse.svg";
import { ReactComponent as ActionGroupIcon } from "../../assets/strategy/actionGroup.svg";
import { ReactComponent as EditIcon } from "../../assets/strategy/edit.svg";
import { ReactComponent as InfoIcon } from "../../assets/strategy/infoIcon.svg";
import { strategyActions } from "../../redux/strategySlice";
import EditStrategyModal from "./EditStrategyModal";
import { StrategyCardWrapper, StrategyMainSection } from "./StrategyCard.styles";
import StrategyActionGroup from "./StrategyCardSections/StrategyActionGroup";
import StrategyInfo from "./StrategyCardSections/StrategyInfo";

export const handleLink = (action, args) => {
  if (action.platform === "keyfi") {
    return `/earn/eth?asset=${args[0] ?? ""}&value=${args[1] ?? ""}&action=${action.method ?? ""}`;
  }
  if (action.action === "Deposit" || action.action === "Withdraw" || action.action === "Deposit ETH") {
    return `/deposit/${action.platform}?version=${action.platform === "aave" ? "v2" : "v1"}&asset=${
      args[0] ?? "ETH"
    }&value=${args[1] ?? "0"}&action=${
      action.action === "Deposit" || action.action === "Deposit ETH" ? "deposit" : "withdraw"
    }`;
  }
  if (
    action.platform === "uniswapv2" &&
    (action.method === "swapExactETHForTokens" ||
      action.method === "swapExactTokensForETH" ||
      action.method === "swapExactTokensForTokens")
  ) {
    return `/swap?asset1=${args[0] ?? ""}&asset2=${args[1] ?? ""}&value=${args[2] ?? ""}`;
  }
  if (action.platform === "uniswapv2" && (action.method === "addLiquidityETH" || action.method === "addLiquidity")) {
    return `/pools/add-liquidity/uniswap?asset1=${args[0] ?? ""}&asset2=${args[1] ?? ""}&value=${args[2] ?? ""}`;
  }
  if (
    action.platform === "uniswapv2" &&
    (action.method === "removeLiquidityETH" || action.method === "removeLiquidity")
  ) {
    const pair = args[0];
    const input = availablePairs.mainnet.find((item) => item.address === pair).key.split(":");
    return `/pools/remove-liquidity/uniswap?input1=${input[0] ?? ""}&input2=${input[1] ?? ""}&v=v2`;
  }
  if (action.action === "Borrow" || action.action === "Repay") {
    if (!action.playform) {
      return `/borrow`;
    }
    return `/borrow/${action.platform}/${action.platform === "aave" ? "v2" : "v1"}/${args[0] ?? "0"}?value=${
      args[1] ?? "0"
    }`;
  }
};

const StrategyCard = ({ strategy }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("actions");
  const { t } = useTranslation();

  const strategyTabs = [
    {
      id: "actions",
      title: t("strategy.actionGroup"),
      icon: <ActionGroupIcon />,
    },
    // {
    //   id: "alerts",
    //   title: t("strategy.alerts"),
    //   icon: <AlertIcon />,
    // },
    // {
    //   id: "tx",
    //   title: t("strategy.txHistory"),
    //   icon: <TransactionIcon />,
    // },
    // {
    //   id: "position",
    //   title: t("strategy.position"),
    //   icon: <PositionIcon />,
    // },
    {
      id: "info",
      title: t("strategy.info"),
      icon: <InfoIcon />,
    },
  ];
  const [showBody, setShowBody] = useState(true);

  const [editStrategyModal, setEditStrategyModal] = useState(false);

  const handleEditStrategyMeta = (item) => {
    dispatch(strategyActions.setActiveStrategy(item));
    setEditStrategyModal(true);
  };

  return (
    <StrategyCardWrapper showBody={showBody}>
      {editStrategyModal && <EditStrategyModal handleCloseModal={() => setEditStrategyModal(false)} />}

      <div className="strategy_options_section">
        <div className="strategy_builder_icon">
          <Horse />
        </div>
        {showBody && (
          <div className="strategy_options">
            {strategyTabs.map((str) => (
              <div key={str.id} className="strategy_option">
                <button
                  onClick={() => setActiveTab(str.id)}
                  className={activeTab === str.id ? "strategy_selected" : ""}
                >
                  {str.icon}
                </button>
                <h6>{str.title}</h6>
              </div>
            ))}
          </div>
        )}
      </div>
      <StrategyMainSection showBody={showBody}>
        <div className="strategy_main_header">
          <div>
            <h2>
              {strategy.name}{" "}
              <button onClick={() => handleEditStrategyMeta(strategy)}>
                <EditIcon />
              </button>
            </h2>
            <div className="strategy_tags">
              {strategy.tags.length !== 0 && strategy.tags.map((item) => <div key={Math.random()}>{item}</div>)}
            </div>
          </div>
          <button onClick={() => setShowBody((prev) => !prev)}>
            {" "}
            <ArrowDown />
          </button>
        </div>
        {showBody && (
          <div className="strategy_main_body">
            {activeTab === "actions" && <StrategyActionGroup strategy={strategy} />}
            {activeTab === "info" && <StrategyInfo strategy={strategy} />}
          </div>
        )}
      </StrategyMainSection>
    </StrategyCardWrapper>
  );
};

export default StrategyCard;
