import React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { beautifyTokensAmount, calculateLPValue, tokensLogos } from "../../helpers";
import { breakPoints } from "../../Theme";

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

const DesktopPlatformTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 2rem;
  font-weight: 500;
  thead {
    th {
      padding: 0 1rem 0.5rem;
      text-align: left;
      border-bottom: 1px solid ${(props) => props.theme.researchBorder};
      font-size: 16px;
      font-weight: 500;
      color: ${(props) => props.theme.form_text};
      :last-of-type {
        text-align: right;
      }
    }
  }
  tbody {
    tr {
      height: 54px;
      :nth-child(even) {
        background: ${(props) => props.theme.bg};
      }
      td {
        padding: 0 1rem;
        :last-of-type {
          text-align: right;
        }
      }
    }
    .table_noassets {
      background: ${(props) => props.theme.tableRow};
    }
  }

  .td_token {
    display: flex;
    align-items: center;
    svg,
    img {
      height: 32px;
      width: 32px;
      margin-right: 0.5rem;
      :first-of-type {
        z-index: 2;
      }
      :nth-child(2) {
        transform: translateX(-1rem);
        margin-right: -0.5rem;
      }
    }
  }
  @media (max-width: ${breakPoints.lg}px) {
    display: none;
  }
`;

const MobilePlatformTable = styled.div`
  display: none;
  .mobileTable {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 2rem;
    width: 100%;
    tbody {
      font-weight: 500;
      width: 100%;
    }
  }
  @media (max-width: ${breakPoints.lg}px) {
    display: block;
  }
`;

const MobileTransactionWidget = styled.tr`
  background: ${(props) => props.theme.bg};
  border: 1px solid ${(props) => props.theme.researchBorder};
  color: ${(props) => props.theme.mainDarkText};
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  margin-bottom: 1.5em;
  padding: 1rem;
  width: 100%;
  .tableTitle {
    color: ${(props) => props.theme.lightGrey};
    margin-right: 5px;
    min-width: 80px;
  }

  div {
    font-weight: 500;
  }
`;

const MobileCell = styled.td`
  align-items: center;
  display: flex;
  padding: 10px 0;
  margin: 0;
  max-width: 100%;
  :first-of-type {
    padding-top: 0;
  }
  :last-of-type {
    padding-bottom: 0;
  }
  img,
  svg {
    height: 24px;
    width: 24px;
    margin-right: 8px;
  }
  .td_token {
    display: flex;
    align-items: center;
    img {
      :first-of-type {
        z-index: 2;
      }
      :nth-child(2) {
        transform: translateX(-1rem);
        margin-right: -0.5rem;
      }
    }
  }
  :last-of-type {
    border-bottom: 0;
  }
`;

const PlatformTable = ({ tokens, user }) => {
  const { t } = useTranslation();
  const tableHead = [
    { id: "asset", label: t("common.asset") },
    { id: "balance", label: t("common.balance") },
    { id: "price", label: t("common.price") },
    { id: "value", label: t("common.value") },
  ];

  const tokenRows = () => {
    const array = tokens.map((token) => {
      if (token.type === "lp") {
        const lpValue = calculateLPValue(token.token, token, user.usdPrices);

        return {
          asset: token.token,
          token0: token.token.split(":")[0],
          token1: token.token.split(":")[1].split(" LP")[0],
          balance: beautifyTokensAmount(token.amount),
          price: beautifyTokensAmount(lpValue / token.amount, { prefix: "$" }),
          value: beautifyTokensAmount(lpValue, { prefix: "$", suffix: " USD" }),
        };
      }
      return {
        asset: token.token,
        balance: beautifyTokensAmount(token.amount),
        price: beautifyTokensAmount(user.usdPrices[token.token.toUpperCase()], {
          suffix: "",
          prefix: "$",
        }),
        value: beautifyTokensAmount(user.usdPrices[token.token.toUpperCase()] * token.amount, {
          suffix: " USD",
          prefix: "$",
        }),
      };
    });
    return array.filter((asset) => asset.balance !== "0.00");
  };

  const DesktopTable = ({ tableHead, tableBody }) => (
    <DesktopPlatformTable>
      <thead>
        <tr>
          {tableHead.map(({ label }) => (
            <th key={label}>{label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableBody.length !== 0 ? (
          tableBody.map(
            (token, i) =>
              token.balance !== "0.00" && (
                <tr key={i}>
                  <td>
                    <div className="td_token">
                      {tokensLogos[token.token0 ?? token.asset]}
                      {token.token1 && tokensLogos[token.token1]}
                      {token.asset}
                    </div>
                  </td>
                  <td>{token.balance}</td>
                  <td>{token.price}</td>
                  <td>{token.value}</td>
                </tr>
              )
          )
        ) : (
          <tr className="table_noassets">
            <td colSpan="4" style={{ textAlign: "left" }}>
              No assets
            </td>
          </tr>
        )}
      </tbody>
    </DesktopPlatformTable>
  );

  const MobileTable = ({ tableHead, tableBody }) => (
    <MobilePlatformTable>
      <table className="mobileTable">
        <tbody>
          {tableBody.length !== 0 ? (
            tableBody.map(
              (token, i) =>
                token.balance !== "0.00" && (
                  <MobileTransactionWidget key={i}>
                    <MobileCell>
                      <div className="tableTitle">{tableHead[0].label}</div>
                      <span className="td_token">
                        {tokensLogos[token.token0 ?? token.asset]}
                        {token.token1 && tokensLogos[token.token1]}
                        {token.asset}
                      </span>
                    </MobileCell>
                    <MobileCell>
                      <div className="tableTitle">{tableHead[1].label}</div>
                      {token.balance}
                    </MobileCell>
                    <MobileCell>
                      <div className="tableTitle">{tableHead[2].label}</div>
                      {token.price}
                    </MobileCell>
                    <MobileCell>
                      <div className="tableTitle">{tableHead[1].label}</div>
                      {token.value}
                    </MobileCell>
                  </MobileTransactionWidget>
                )
            )
          ) : (
            <div>{t("addition.noAssets")}</div>
          )}
        </tbody>
      </table>
    </MobilePlatformTable>
  );

  return (
    <Wrapper>
      {user.usdPrices && (
        <>
          <DesktopTable tableHead={tableHead} tableBody={tokenRows()} />
          <MobileTable tableHead={tableHead} tableBody={tokenRows()} />
        </>
      )}
    </Wrapper>
  );
};

export default PlatformTable;
