import React from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { poolsLogos } from "../../helpers";
import Modal from "../Modal";
import AddLiquidityAction from "./ActionComponents/AddLiquidityAction";
import DepositAction from "./ActionComponents/DepositAction";
import RemoveLiquidityAction from "./ActionComponents/RemoveLiquidityAction";
import SwapAction from "./ActionComponents/SwapAction";

const Wrapper = styled.div`
  padding: 1rem 0 0;
  h5 {
    font-size: 12px;
    color: ${(props) => props.theme.lightGrey};
    margin-bottom: 0.5rem;
  }
  .edit_action_input {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 0.5rem 1rem;
    line-height: 24px;
    background: ${(props) => props.theme.bg};
    margin-bottom: 1rem;
    border: 1px solid ${(props) => props.theme.researchBorder};
    border-radius: 6px;
    width: 100%;
    img {
      height: 24px;
      width: 24px;
    }
  }
`;

const handleShowOnMethod = (method, name, handleCloseModal, editModal) => {
  if (
    method === "withdraw" ||
    method === "withdrawRewards" ||
    method === "deposit" ||
    method === "borrow" ||
    method === "repay"
  ) {
    return <DepositAction method={method} handleCloseModal={handleCloseModal} name={name} editModal={editModal} />;
  }
  if (
    name === "Compound" &&
    (method === "mint" || method === "redeemUnderlying" || method === "borrow" || method === "repayBorrow")
  ) {
    return <DepositAction method={method} handleCloseModal={handleCloseModal} name={name} editModal={editModal} />;
  }
  if (
    method === "swapExactETHForTokens" ||
    method === "swapExactTokensForETH" ||
    method === "swapExactTokensForTokens"
  ) {
    return <SwapAction method={method} handleCloseModal={handleCloseModal} editModal={editModal} />;
  }
  if (method === "addLiquidityETH" || method === "addLiquidity") {
    return <AddLiquidityAction method={method} handleCloseModal={handleCloseModal} editModal={editModal} />;
  }
  if (method === "removeLiquidityETH" || method === "removeLiquidity") {
    return <RemoveLiquidityAction method={method} handleCloseModal={handleCloseModal} editModal={editModal} />;
  }
};

const EditActionModal = ({ editModal, handleCloseModal }) => {
  const strategyAction = useSelector((state) => state.strategyAction);
  const { activeAction, actionTemplates } = strategyAction;
  const { t } = useTranslation();

  if (editModal) {
    return (
      <Modal title={t("strategy.editAction")} handleCloseModal={handleCloseModal} width="100%" maxwidth="600px">
        <Wrapper>
          <h5>{t("common.platform")}</h5>
          <div className="edit_action_input">
            {poolsLogos[activeAction.templateId.name]}
            {activeAction.templateId.name}
          </div>
          <h5>{t("common.method")}</h5>
          <div className="edit_action_input">{activeAction.templateId.action}</div>
          {handleShowOnMethod(activeAction.templateId.method, activeAction.templateId.name, handleCloseModal, true)}
        </Wrapper>
      </Modal>
    );
  }
  const actionTemplate = actionTemplates.find((item) => item._id === activeAction.templateId);

  return (
    <Modal title={t("strategy.editAction")} handleCloseModal={handleCloseModal} width="100%" maxwidth="600px">
      <Wrapper>
        <h5>{t("common.platform")}</h5>
        <div className="edit_action_input">
          {poolsLogos[actionTemplate.name]}
          {actionTemplate.name}
        </div>
        <h5>{t("common.method")}</h5>
        <div className="edit_action_input">{actionTemplate.action}</div>
        {handleShowOnMethod(actionTemplate.method, actionTemplate.name, handleCloseModal)}
      </Wrapper>
    </Modal>
  );
};

export default EditActionModal;
