import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const API = process.env.REACT_APP_PRO_API;

const initialState = {
  isLoading: false,
  priceData: {},
  tokenData: {},
  searchList: null,
};

const chartWidget = createSlice({
  name: "chartWidget",
  initialState,
  reducers: {
    setLoading(state, action) {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
    setTokenData(state, action) {
      return {
        ...state,
        tokenData: action.payload,
      };
    },
    setPriceData(state, action) {
      return {
        ...state,
        priceData: action.payload,
      };
    },
    setSearchList(state, action) {
      return { ...state, searchList: action.payload };
    },
  },
});

export const chartWidgetActions = chartWidget.actions;

const getRoot = (state) => state.chartWidget;

export const chartWidgetSelectors = {
  getLoading: (state) => getRoot(state).isLoading,
  getPriceData: (state) => getRoot(state).priceData,
};

export const chartWidgetOperations = {
  getPriceData: (tokenId) => async (dispatch, getState) => {
    try {
      const isLoading = getState().chartWidget.isLoading;
      if (isLoading) {
        return;
      }
      dispatch(chartWidgetActions.setLoading(true));
      const config = {
        headers: {
          Authorization: getState().userData.tokens.access.token,
        },
      };
      const { data } = await axios.get(`${API}/v1/research/price-data?token=${tokenId}`, config);
      const priceData = getState().chartWidget.priceData;

      dispatch(chartWidgetActions.setPriceData({ ...priceData, [tokenId]: data }));
      dispatch(chartWidgetActions.setLoading(false));
    } catch (err) {
      console.log(err);
      dispatch(chartWidgetActions.setLoading(false));
    }
  },
  getTokenData: (tokenSymbol) => async (dispatch, getState) => {
    try {
      const config = {
        headers: {
          Authorization: getState().userData.tokens.access.token,
        },
      };
      const { data } = await axios.get(`${API}/v1/research/minimal-token-data?tokenId=${tokenSymbol}`, config);
      const tokenData = getState().chartWidget.tokenData;

      dispatch(chartWidgetActions.setTokenData({ ...tokenData, [data.coin_id]: data }));
      dispatch(chartWidgetActions.setLoading(false));
    } catch (err) {
      console.log(err);
      dispatch(chartWidgetActions.setLoading(false));
    }
  },
  getSearchList: () => async (dispatch, getState) => {
    try {
      dispatch(chartWidgetActions.setLoading(true));

      const config = {
        headers: {
          Authorization: getState().userData.tokens.access.token,
        },
      };
      const { data } = await axios.get(`${API}/v1/research/search-list`, config);
      dispatch(chartWidgetActions.setSearchList(data));
      dispatch(chartWidgetActions.setLoading(false));
    } catch (err) {
      console.log(err);
      dispatch(chartWidgetActions.setLoading(false));
    }
  },
};

export const chartWidgetReducer = chartWidget.reducer;
