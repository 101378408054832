import React, { useEffect, useMemo, useState } from "react";

import axios from "axios";
import BigNumber from "bignumber.js";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

// Assets
import SmallDown from "../../../assets/alerts/smallDown.svg";
import SmallUp from "../../../assets/alerts/smallUp.svg";
import { beautifyTokensAmount } from "../../../helpers";
import { alertsOperations } from "../../../redux/alertsSlice";
import { createAlertOperations } from "../../../redux/createAlertSlice";
import { Skeleton } from "../../../Shared";
import AlertDropdown from "../AlertDropdown";

const Wrapper = styled.div`
  h4 {
    font-size: 12px;
    margin-bottom: 0.5rem;
    color: ${(props) => props.theme.lightGrey};
  }
  .price_change_dropdown {
    width: 100%;
    max-width: 200px;
    margin-bottom: 1.5rem;
  }
  .price_change_inputs {
    display: flex;
    align-items: flex-end;
    gap: 1.25rem;
    margin-bottom: 4px;

    .price_change_amount {
      display: flex;
      gap: 0.25rem;
      padding: 11px 0.5rem;
      border-radius: 6px;
      border: 1px solid ${(props) => props.theme.researchBorder};
      width: 100%;
      font-size: 14px;
      max-width: 200px;
      input {
        font-size: 14px;
        width: 100%;
        ::-webkit-outer-spin-button,
        ::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
    .price_change_conditional {
      flex-basis: 200px;
      width: 100%;
      max-width: 200px;
    }
    > div:last-of-type {
      flex-basis: 200px;
    }
  }
  .price_change_info {
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 1.5rem;
    /* display: flex; */
    span {
      color: ${(props) => props.theme.form_text};
    }
    .price_change_skeleton {
      transform: translateY(2px);
      display: inline-block;
    }
  }
`;

const PriceChangeAlert = ({ editModal }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [tokenPrice, setTokenPrice] = useState({
    fetching: false,
    tokenPrice: null,
  });
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const conditionalOptions = [
    {
      id: ">",
      text: t("alert.upTo"),
      img: <img style={{ height: "16px", width: "16px" }} src={SmallUp} alt="Up" />,
    },
    {
      id: "<",
      text: t("alert.downTo"),
      img: <img style={{ height: "16px", width: "16px" }} src={SmallDown} alt="Up" />,
    },
  ];

  const editAlert = useSelector((state) => state.alerts.editAlert);
  const createAlert = useSelector((state) => state.createAlert);
  const { price } = createAlert;

  const userData = useSelector((state) => state.userData);
  const { tokens } = userData;

  const watchlistList = useSelector((state) => state.watchlist.watchlistList);

  const handleInputChange = (name, value) => {
    if (editModal) {
      return dispatch(alertsOperations.handleEditAlert(name, value));
    }
    return dispatch(createAlertOperations.setPrice(name, value));
  };

  const data = {
    asset: editModal ? editAlert.asset : price.asset,
    conditional: editModal ? editAlert.conditional : price.conditional,
    value: editModal ? editAlert.value : price.value,
  };

  const selectedAsset = watchlistList.find((item) => item.coin_id === data.asset);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          headers: {
            Authorization: tokens.access.token,
          },
        };

        setTokenPrice({ tokenPrice: null, fetching: true });
        const { data } = await axios.get(
          `${process.env.REACT_APP_PRO_API}/v1/research/minimal-token-data?token=${selectedAsset.symbol}`,
          config
        );

        setTokenPrice({
          tokenPrice: data.current_price,
          fetching: false,
        });
      } catch (err) {
        console.log(err);
      }
      setTokenPrice((prev) => ({ ...prev, fetching: false }));
    };
    if (selectedAsset && selectedAsset.symbol) {
      fetchData();
    }
  }, [selectedAsset, tokens.access.token]);

  useEffect(() => {
    if (tokenPrice.tokenPrice && data.value) {
      if (!editModal) {
        dispatch(
          createAlertOperations.setPrice("conditional", BigNumber(data.value).gt(tokenPrice.tokenPrice) ? ">" : "<")
        );
      } else {
        dispatch(
          alertsOperations.handleEditAlert("conditional", BigNumber(data.value).gt(tokenPrice.tokenPrice) ? ">" : "<")
        );
      }
    }
  }, [tokenPrice.tokenPrice, data.value, dispatch, editModal]);

  const filteredList = useMemo(() => {
    const sortedList = watchlistList
      .filter(({ name, symbol }) => {
        const regex = new RegExp(`^(${searchQuery})`, "gi");
        return regex.test(name) || regex.test(symbol);
      })
      .sort((a, b) => {
        if ((a.market_cap_rank ?? 999999) < (b.market_cap_rank ?? 898989)) return -1;
        return 1;
      })
      .sort((a, b) => {
        if (a.name.toLowerCase() === searchQuery.toLowerCase()) return -1;
        if (b.name.toLowerCase() === searchQuery.toLowerCase()) return 1;
        return 0;
      })
      .slice(0, 20);

    return sortedList
      .filter((ele, ind) => ind === sortedList.findIndex((elem) => elem.coin_id === ele.coin_id))
      .map((item) => ({
        id: item.coin_id,
        text: item.symbol.toUpperCase(),
        name: item.name,
        img: <img src={item.icon} alt={item.name} />,
      }));
  }, [watchlistList, searchQuery]);

  return (
    <Wrapper>
      <h4>{t("alert.chooseAnAsset")}</h4>
      {watchlistList.length !== 0 ? (
        <AlertDropdown
          name="asset"
          selectedOption={data.asset}
          placeholder={t("alert.chooseAnAsset")}
          options={filteredList}
          fullOptions={watchlistList.map((item) => ({
            id: item.coin_id,
            text: item.symbol.toUpperCase(),
            name: item.name,
            img: <img src={item.icon} alt={item.name} />,
          }))}
          className="price_change_dropdown"
          setOption={handleInputChange}
          searchBar
          onChange={(e) => setSearchQuery(e.target.value)}
          searchQuery={searchQuery}
        />
      ) : (
        <Skeleton className="price_change_dropdown" height="42" width="200" />
      )}

      <div className="price_change_inputs">
        <div>
          <h4>{t("alert.price")}</h4>
          <div className="price_change_amount">
            $
            <input
              name="value"
              type="number"
              min="0"
              value={data.value && Math.max(0, data.value)}
              onChange={(e) => {
                if (e.target.value) {
                  return handleInputChange(e.target.name, e.target.value);
                }
                return handleInputChange(e.target.name, "");
              }}
            />
          </div>
        </div>
        <div>
          <AlertDropdown
            name="conditional"
            className="price_change_conditional"
            selectedOption={data.conditional}
            placeholder={t("alert.chooseAnAsset")}
            options={conditionalOptions}
            setOption={handleInputChange}
          />
        </div>
      </div>
      {data.asset && selectedAsset && (
        <div className="price_change_info">
          <span>{selectedAsset.symbol.toUpperCase()}</span> {t("alert.price")} {t("alert.is")}
          {!tokenPrice.fetching ? (
            <span>{beautifyTokensAmount(tokenPrice.tokenPrice, { prefix: "$" })}</span>
          ) : (
            <Skeleton className="price_change_skeleton" height="12" width="60" />
          )}
        </div>
      )}
    </Wrapper>
  );
};

export default PriceChangeAlert;
