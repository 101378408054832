import React from "react";

import { ReactComponent as Down } from "../../assets/alerts/Down.svg";
import { ReactComponent as Up } from "../../assets/alerts/Up.svg";
import { beautifyTokensAmount } from "../../helpers";

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const iconHandler = (alert) => {
  if (alert.type === "gas") {
    return <Down />;
  }
  if (alert.type === "price" || alert.type === "volume" || alert.type === "interestRate") {
    if (alert.conditional === ">") {
      return <Up />;
    } else {
      return <Down />;
    }
  }
};

export const titleHandler = (alert, t) => {
  if (alert.type === "gas") {
    return `${
      alert.gasRate === "safeLow"
        ? t("common.safeLow")
        : alert.gasRate === "average"
        ? t("common.average")
        : t("common.fastest")
    } ${t("alert.gasInDownTo")} ${alert.value} Gwei`;
  }
  if (alert.type === "price") {
    return `${capitalizeFirstLetter(alert.asset)} ${t("alert.is")} ${
      alert.conditional === ">" ? t("alert.upTo") : t("alert.downTo")
    } ${beautifyTokensAmount(alert.value, {
      prefix: "$",
    })}`;
  }
  if (alert.type === "volume") {
    return `${capitalizeFirstLetter(alert.asset)} ${t("alert.valumeIs")} ${
      alert.conditional === ">" ? t("alert.upBy") : t("alert.downBy")
    } ${beautifyTokensAmount(alert.value, { suffix: "%" })}`;
  }
  if (alert.type === "interestRate") {
    return `${capitalizeFirstLetter(alert.asset)} ${t("common.apyRateIs")} ${
      alert.conditional === ">" ? t("alert.up") : t("alert.down")
    } to ${beautifyTokensAmount(alert.value, { suffix: "%" })} `;
  }
};

export const subtitleHandler = (alert, t) => {
  switch (alert.type) {
    case "gas": {
      switch (alert.network) {
        case "ethereum":
          return t("alert.onETH");
        case "bsc":
          return t("alert.onBSC");
        case "polygon":
          return t("alert.onPolygon");
        default:
          break;
      }
      break;
    }
    case "interestRate":
      return `${t("alert.on")} ${alert.platform}`;
    default:
      break;
  }
};
