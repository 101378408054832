import React from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { beautifyTokensAmount, poolsLogos, tokensLogos } from "../../../helpers";
import { breakPoints } from "../../../Theme";
import WidgetWrapper from "./WidgetWrapper";

const InterestRatesTable = styled.table`
  border-collapse: collapse;
  font-size: 14px;
  thead {
    width: 100%;
    padding-right: 4px;
    tr {
      width: 100%;
      th {
        text-align: left;
        border-bottom: 1px solid ${(props) => props.theme.researchBorder};
        padding: 9px 0;
        color: ${(props) => props.theme.form_text};
        :first-of-type {
          padding-left: 0.75rem;
          width: 10%;
        }
        :nth-child(2) {
          width: 20%;
        }
        :nth-child(3) {
          width: 20%;
        }
        :last-of-type {
          text-align: right;
          padding-right: 1rem;
        }
        @media (max-width: ${breakPoints.lg}px) {
          padding: 9px 0 3px;
          :first-of-type {
            padding-left: 5px;
            width: 8%;
          }
          :nth-child(2) {
            width: 30%;
          }
          :nth-child(3) {
            width: 25%;
          }
          :nth-child(4) {
            width: 10%;
          }
        }
      }
    }
  }
  tbody {
    ::-webkit-scrollbar {
      width: 4px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.scroleThumb};
      border-radius: 10px;
    }
    tr {
      display: table;
      width: 100%;
      table-layout: fixed;
      :nth-child(2n) {
        background: ${(props) => props.theme.darkGrey};
      }
      td {
        padding: 9px 0;
        :first-of-type {
          padding-left: 0.75rem;
          width: 10%;
        }
        :nth-child(2) {
          width: 20%;
        }
        :nth-child(3) {
          width: 20%;
        }

        :last-of-type {
          text-align: right;
          padding-right: 1rem;
        }
        @media (max-width: ${breakPoints.lg}px) {
          padding: 7px 0;
          :first-of-type {
            padding-left: 5px;
            width: 8%;
          }
          :nth-child(2) {
            width: 30%;
          }
          :nth-child(3) {
            width: 25%;
          }
          :nth-child(4) {
            width: 10%;
          }
        }

        .watchlist_token_title {
          display: flex;
          align-items: center;
          gap: 6px;
          img {
            height: 20px;
            width: 20px;
          }
        }
        a {
          color: ${(props) => props.theme.form_text};
          text-decoration: underline;
        }
      }
    }
    @media (max-width: ${breakPoints.lg}px) {
      height: 130px;
      tr {
        :hover {
          z-index: 4;
          background: ${(props) => props.theme.blueTransparent};
          /* Border inside */
          box-shadow: inset 0px 0px 0px 1px ${(props) => props.theme.brandBlue};
          border-radius: 6px;
        }
      }
    }
  }
  .research_widget_diff {
    color: ${(props) => props.theme.greenShade};
  }
  .research_widget_minus {
    color: ${(props) => props.theme.redMinus};
  }
  .watchlist_no_mobile {
    @media (max-width: ${breakPoints.lg}px) {
      display: none;
    }
  }
`;

const InterestRatesWidget = ({ setOpenModal, item }) => {
  const history = useHistory();
  const rates = useSelector((state) => state.rates.rates);
  const { t } = useTranslation();
  const createLink = (platform, asset) => {
    if (platform.includes("Aave") || platform === "Compound" || platform === "dYdX") {
      const platformName = platform.split(" ")[0];
      const platformVersion = platform.split(" ")[1];
      return `/deposit/${platformName.toLowerCase()}?asset=${asset}&version=${
        platformVersion ? platformVersion.toLowerCase() : "v1"
      }`;
    }
    return "/deposit";
  };

  return (
    <WidgetWrapper
      item={item}
      setOpenModal={setOpenModal}
      id="interest"
      header={
        <div className="widget_info">
          <Link to="/rebalancer">{t("dashboard.widget.interestRate")}</Link>
        </div>
      }
      body={
        <div className="widget_main" style={{ display: "flex", flexDirection: "column", overflowY: "auto" }}>
          <InterestRatesTable>
            <thead>
              <tr>
                <th>#</th>
                <th>{t("common.token")}</th>
                <th>{t("dashboard.widget.supplyRate")}</th>
                <th>{window.innerWidth > breakPoints.lg && "Platform"}</th>
                <th className="watchlist_no_mobile" />
              </tr>
            </thead>
            <tbody>
              {rates.length !== 0 &&
                rates
                  .sort((a, b) => b.rate - a.rate)
                  .map((item, i) => (
                    <tr
                      key={`${item.token}-${i}`}
                      onClick={() =>
                        window.innerWidth < breakPoints.lg && history.push(createLink(item.platform, item.asset))
                      }
                    >
                      <td>{i + 1}</td>
                      <td>
                        <div className="watchlist_token_title">
                          {tokensLogos[item.asset] || (
                            <div style={{ height: "20px", width: "20px", textAlign: "center" }}>?</div>
                          )}
                          {item.asset}
                        </div>
                      </td>
                      <td>{beautifyTokensAmount(item.rate)}%</td>
                      <td>
                        <div className="watchlist_token_title">
                          {poolsLogos[item.platform]}
                          {window.innerWidth > breakPoints.lg &&
                            (item.platform === "C.R.E.A.M. Finance"
                              ? "C.R.E.A.M."
                              : item.platform === "dYdX"
                              ? "dYdX"
                              : item.platform.charAt(0).toUpperCase() + item.platform.slice(1))}
                        </div>
                      </td>
                      <td className="watchlist_no_mobile">
                        <Link to={createLink(item.platform, item.asset)}>Manage</Link>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </InterestRatesTable>
        </div>
      }
    />
  );
};

export default InterestRatesWidget;
