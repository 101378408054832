const INITIAL_STATE = {
  isTransactionManagerOpen: false,
  txData: [],
};

const transactionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SHOW_TRANSACTION_MANAGER":
      return {
        ...state,
        isTransactionManagerOpen: true,
      };

    case "CLOSE_TRANSACTION_MANAGER":
      return {
        ...state,
        isTransactionManagerOpen: false,
      };

    case "ADD_TX":
      return {
        ...state,
        txData: [...state.txData, action.payload],
      };

    case "REMOVE_TX": {
      let newArr = [...state.txData];
      action.payload.forEach((txHash) => {
        newArr = newArr.filter((tx) => tx.txHash !== txHash);
      });
      return {
        ...state,
        txData: newArr,
      };
    }
    default:
      return state;
  }
};

export default transactionReducer;
