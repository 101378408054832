import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import ArIcon from "../../assets/arabicFlag-big.svg";
import ChIcon from "../../assets/chinaFlag-big.svg";
import UkIcon from "../../assets/enFlag-big.svg";
import JpIcon from "../../assets/japanFlag-big.svg";
import KrIcon from "../../assets/koreaFlag-big.svg";
import PlIcon from "../../assets/polandFlag-big.svg";
import PtIcon from "../../assets/portugalFlag-big.svg";
import RuIcon from "../../assets/russianFlag-big.svg";
import TrIcon from "../../assets/turkishFlag-big.svg";
import ViIcon from "../../assets/vietnamFlag-big.svg";
import useAccountChanged from "../../hooks/useAccountChanged";
import useAddAddress from "../../hooks/useAddAddress";
import useNetworkChange from "../../hooks/useNetworkChange";
import { appOperations } from "../../redux/appSlice";
import { breakPoints } from "../../Theme";
import Modal from "../Modal";
import Notifications from "../Notifications/Notifications";
import TransactionManager, { getTxHistory } from "../TransactionManager";
import Header from "./Header";
import { LanguageOptions } from "./Header.styles";
import SidebarV2 from "./SidebarV2";

const LayoutWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  .layout_main {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: ${(props) => (props.sidebarMinimized ? "60px" : "200px")};
    .layout_body {
      height: 100%;
      padding: 0 2.5rem 3rem;
    }
    @media (max-width: ${breakPoints.md}px) {
      margin-left: 0;
      .layout_body {
        padding: 2rem 1.25rem 3rem;
      }
    }
  }
  .token_price_change {
    color: ${(props) => props.theme.greenShade2};
  }
  .token_price_negative {
    color: ${(props) => props.theme.redMinus};
  }
`;

const languageData = {
  en: {
    title: "English",
    logo: UkIcon,
  },
  zh: {
    title: "中文",
    logo: ChIcon,
  },
  jp: {
    title: "日本語",
    logo: JpIcon,
  },
  kr: {
    title: "한국인",
    logo: KrIcon,
  },
  ru: {
    title: "русский",
    logo: RuIcon,
  },
  tr: {
    title: "Arapça",
    logo: TrIcon,
  },
  ar: {
    title: "عربي",
    logo: ArIcon,
  },
  vi: {
    title: "Việt Nam",
    logo: ViIcon,
  },
  pt: {
    title: "Portuguesa",
    logo: PtIcon,
  },
  pl: {
    title: "język polski",
    logo: PlIcon,
  },
};

const Layout = ({ children, ...props }) => {
  const dispatch = useDispatch();
  useNetworkChange(); // Network change listener
  useAccountChanged(); // On account change fetch balance
  useAddAddress(); // Hook to add address to user's list

  const { i18n } = useTranslation();
  const [languagePopup, setLanguagePopup] = useState(false);
  const transactions = useSelector((state) => state.transactions);
  const { txData, isTransactionManagerOpen } = transactions;

  // Initial fetch
  useEffect(() => {
    dispatch(appOperations.initApplication());
  }, [dispatch]);

  const handleLangChange = (lang) => {
    i18n.changeLanguage(lang);
    setLanguagePopup(false);
  };

  const handleToggleLanguage = () => {
    setLanguagePopup((prev) => !prev);
  };

  return (
    <LayoutWrapper {...props}>
      <Notifications />
      <SidebarV2 languageData={languageData} handleLanguage={handleToggleLanguage} {...props} />
      {languagePopup && (
        <Modal title="Change language" handleCloseModal={() => setLanguagePopup(false)}>
          <LanguageOptions>
            {Object.keys(languageData).map((key) => {
              return (
                <div key={key} className="language_option" onClick={() => handleLangChange(key)}>
                  <img src={languageData[key].logo} alt="" />
                  <span>{languageData[key].title}</span>
                </div>
              );
            })}
          </LanguageOptions>
        </Modal>
      )}
      {isTransactionManagerOpen && (
        <TransactionManager open={isTransactionManagerOpen} txData={txData} txHistory={getTxHistory()} />
      )}
      <div className="layout_main">
        <Header handleLanguage={handleToggleLanguage} {...props} />
        <div className="layout_body">{children}</div>
      </div>
    </LayoutWrapper>
  );
};

export default Layout;
