import React from "react";

import { useTranslation } from "react-i18next";

import { PopUpIcons } from "../../helpers/index";
import { PopUpBody, PopUpContainer } from "./billing.style";
import { PopUpButton, PopUpButtonContainer, PopUpContent, PopUpWrapper } from "./popup.style";

const PopUp = ({ endDate, popupCallback }) => {
  const { t } = useTranslation();
  return (
    <>
      <PopUpWrapper position="0">
        <PopUpContent>
          <PopUpContainer>
            <PopUpBody>
              <div>{PopUpIcons.CompleteIcon}</div>
              <div className="result-content">
                <p>{t("billing.paymentAddress")}</p>
                <p className="result-end-date">
                  {t("billing.currentPeriodEnd")}: {endDate}
                </p>
              </div>
              <PopUpButtonContainer>
                <PopUpButton bgColor="#005AD3" color="#FFFFFF" onClick={() => popupCallback(false)}>
                  {t("billing.goToDashboard")}
                </PopUpButton>
                <PopUpButton bgColor="transparent" color="#fff" onClick={() => popupCallback(false)}>
                  {t("billing.cancel")}
                </PopUpButton>
              </PopUpButtonContainer>
            </PopUpBody>
          </PopUpContainer>
        </PopUpContent>
      </PopUpWrapper>
    </>
  );
};

export default PopUp;
