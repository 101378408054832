import React, { useState } from "react";

import axios from "axios";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import styled from "styled-components";

import PasswordChecklist from "../Components/User/PasswordChecklist";
import UserInputField from "../Components/User/UserInputField";
import { userDataOperations } from "../redux/userDataSlice";
import { ErrorMessage, FormButton, LoginForm } from "../Shared";

const Button = styled(FormButton)`
  margin-top: 2.5rem;
`;

const ResetPassword = () => {
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.userData);
  const { user, loading } = userData;

  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");
  const [inputData, setInputData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [statusMessage, setStatusMessage] = useState({
    status: "",
    message: "",
  });
  const [pwChecklist, setPwChecklist] = useState(false);

  const handleChange = (e) => {
    setInputData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const { t } = useTranslation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatusMessage({
      status: "",
      message: "",
    });
    try {
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const res = await axios.post(
        `${process.env.REACT_APP_PRO_API}/v1/auth/reset-password?token=${token}`,
        inputData,
        config
      );
      setStatusMessage({
        status: "success",
        message: t("auth.passwordChanged"),
      });

      dispatch(userDataOperations.setUser(res.data.user, res.data.tokens));
    } catch (err) {
      setStatusMessage({
        status: "error",
        message: err.response.data.message ?? err.response.data,
      });
    }
  };

  return (
    <LoginForm onSubmit={handleSubmit}>
      {user.id && user.access.allow && user.emailApproved ? (
        <Redirect to="/dashboard" />
      ) : user.id && user.emailApproved ? (
        <Redirect to="/access" />
      ) : (
        user.id && <Redirect to="/verify-email" />
      )}

      <h2>{t("auth.resetYourPassword")}</h2>
      <div className="inputContainer">
        <h4>{t("auth.passwordDifferentFromPervious")}</h4>
        <label htmlFor="loginPassword">{t("auth.newPassword")}</label>
        <UserInputField
          placeholder="Enter your password"
          value={inputData.password}
          onChange={handleChange}
          type="password"
          name="password"
          required
          showEye
        />
        <label htmlFor="loginPassword">{t("auth.confirmPassword")}</label>
        <UserInputField
          placeholder="Enter your password again"
          value={inputData.confirmPassword}
          onChange={handleChange}
          type="password"
          name="confirmPassword"
          noMargin
          required
          showEye
        />
        {statusMessage.status && (
          <ErrorMessage success={statusMessage.status === "success"}>{statusMessage.message}</ErrorMessage>
        )}
        <PasswordChecklist setApprove={(value) => setPwChecklist(value)} margin password={inputData.password} />

        <Button
          type="submit"
          disabled={
            !inputData.password ||
            !inputData.confirmPassword ||
            inputData.password !== inputData.confirmPassword ||
            !pwChecklist ||
            loading
          }
        >
          {t("auth.createPassword")}
        </Button>
      </div>
    </LoginForm>
  );
};

export default ResetPassword;
