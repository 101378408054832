import React from "react";

import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { initialState, strategyActions, strategyOperations } from "../../redux/strategySlice";
import Modal from "../Modal";

const Wrapper = styled.div`
  .watchlist_delete_text {
    margin: 1.25rem 0 2rem;
  }
  .watchlist_delete_buttons {
    display: flex;
    gap: 1.5rem;
  }
`;

const DeleteStrategyModal = ({ handleCloseModal, callback }) => {
  const dispatch = useDispatch();

  const strategy = useSelector((state) => state.strategy);
  const { activeStrategy } = strategy;

  const handleDelete = async () => {
    dispatch(strategyOperations.deleteStrategy(activeStrategy.id));
    handleCloseModal();
    dispatch(strategyActions.setActiveStrategy(initialState.activeStrategy));
    if (callback) {
      callback();
    }
  };
  return (
    <Modal title="Delete strategy" width="100%" maxwidth="455px" handleCloseModal={handleCloseModal}>
      <Wrapper>
        <div className="watchlist_delete_text">Are you sure you want to delete this strategy?</div>
        <div className="watchlist_delete_buttons">
          {" "}
          <button className="error" onClick={() => handleDelete()}>
            Delete Strategy
          </button>
          <button className="secondary" onClick={handleCloseModal}>
            Cancel
          </button>
        </div>
      </Wrapper>
    </Modal>
  );
};

export default DeleteStrategyModal;
