// /v1/research/token-analysis
import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const API = process.env.REACT_APP_PRO_API;

const initialState = {
  isLoading: false,
  tokenAnalysis: {},
  globalAnalysis: {},
};

const marketAnalysis = createSlice({
  name: "marketAnalysis",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setTokenAnalysis(state, action) {
      state.tokenAnalysis = action.payload;
    },
    setGlobalAnalysis(state, action) {
      state.globalAnalysis = action.payload;
    },
  },
});

export const marketAnalysisActions = marketAnalysis.actions;

const getRoot = (state) => state.gas;

export const gasSelectors = {
  getLoading: (state) => getRoot(state).isLoading,
  getMarketAnalysis: (state) => getRoot(state).marketAnalysis,
  getGlobalAnalysis: (state) => getRoot(state).marketAnalysis,
};

export const marketAnalysisOperations = {
  getMarketAnalysis: () => async (dispatch, getState) => {
    try {
      dispatch(marketAnalysisActions.setLoading(true));
      const config = {
        headers: {
          Authorization: getState().userData.tokens.access.token,
        },
      };
      const [{ data }, { data: globalData }] = await Promise.all([
        axios.get(`${API}/v1/research/token-analysis`, config),
        axios.get(`${API}/v1/research/global-analysis`, config),
      ]);
      dispatch(marketAnalysisActions.setLoading(false));
      dispatch(marketAnalysisActions.setTokenAnalysis(data));
      dispatch(marketAnalysisActions.setGlobalAnalysis(globalData));
    } catch (err) {
      dispatch(marketAnalysisActions.setLoading(false));
    }
  },
};

export const marketAnalysisReducer = marketAnalysis.reducer;
