import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { addressesOperations } from "./addressesSlice";
import { stacksOperations } from "./stacksSlice";

const API = process.env.REACT_APP_PRO_API;

const initialState = {
  isLoading: false,
  address: {
    _id: "",
    title: "",
    address: "",
  },
};

const editAddress = createSlice({
  name: "editAddress",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setAddress(state, action) {
      state.address = action.payload;
    },
  },
});

export const editAddressActions = editAddress.actions;

const getRoot = (state) => state.addresses;

export const editAddressSelectors = {
  getLoading: (state) => getRoot(state).isLoading,
  getAddress: (state) => getRoot(state).address,
};

export const editAddressOperations = {
  handleTitleChange: (title) => (dispatch, getState) => {
    const address = getState().editAddress.address;
    dispatch(editAddressActions.setAddress({ ...address, title }));
  },
  updateAddress: () => async (dispatch, getState) => {
    try {
      dispatch(editAddressActions.setLoading(true));

      const token = getState().userData.tokens.access.token;
      const address = getState().editAddress.address;
      const config = {
        headers: {
          Authorization: token,
        },
      };
      await axios.patch(`${API}/v1/wallet`, address, config);
      dispatch(editAddressActions.setLoading(false));
      dispatch(addressesOperations.fetchUserAddresses());
    } catch (err) {
      console.log(err);
      dispatch(editAddressActions.setLoading(false));
    }
  },
  deleteAddress: () => async (dispatch, getState) => {
    try {
      dispatch(editAddressActions.setLoading(true));

      const token = getState().userData.tokens.access.token;
      const id = getState().editAddress.address._id;
      const config = {
        headers: {
          Authorization: token,
        },
      };
      await axios.delete(`${API}/v1/wallet/${id}`, config);
      dispatch(editAddressActions.setLoading(false));
      dispatch(addressesOperations.fetchUserAddresses());
      dispatch(stacksOperations.fetchUserStacks());
    } catch (err) {
      console.log(err);
      dispatch(editAddressActions.setLoading(false));
    }
  },
};

export const editAddressReducer = editAddress.reducer;
