import React, { useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import WalletData from "../../Components/Dashboard/WalletData";
import WalletValue from "../../Components/Dashboard/WalletValue";
import WideScreenWidget from "../../Components/Dashboard/WideScreenWidget";
import AddWidgetCard from "../../Components/Dashboard/Widgets/AddWidgetCard";
import AlertsWidget from "../../Components/Dashboard/Widgets/AlertsWidget";
import ChartWidget from "../../Components/Dashboard/Widgets/ChartWidget";
import InterestRatesWidget from "../../Components/Dashboard/Widgets/InterestRatesWidget";
import NewsWidget from "../../Components/Dashboard/Widgets/NewsWidget";
import ResearchWidget from "../../Components/Dashboard/Widgets/ResearchWidget";
import SentimentWidget from "../../Components/Dashboard/Widgets/SentimentWidget";
import WatchlistWidget from "../../Components/Dashboard/Widgets/WatchlistWidget";
import Meta from "../../Components/Meta";
import { stackBalanceOperations } from "../../redux/wallets/stackBalanceSlice";
import { walletOperations } from "../../redux/walletSlice";
import { DashboardWrapper, TopDashboard, WidgetGrid } from "./Dashboard.styles";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const prices = useSelector((state) => state.user.usdPrices);
  const wallet = useSelector((state) => state.wallet);
  const stackBalance = useSelector((state) => state.stackBalance);
  const { showStack, balance } = stackBalance;
  const userData = useSelector((state) => state.userData);
  const { user } = userData;

  const [openModal, setOpenModal] = useState(false);

  const total = useMemo(() => {
    if (prices) {
      return showStack
        ? dispatch(stackBalanceOperations.getTotalBalance())
        : dispatch(walletOperations.getTotalBalance());
    }
    return 0;
  }, [prices, showStack, wallet, balance, user.tokenBlacklist]);

  const widgets = {
    interest: ({ item }) => <InterestRatesWidget item={item} setOpenModal={setOpenModal} />,
    watchlist: ({ item }) => <WatchlistWidget item={item} setOpenModal={setOpenModal} />,
    alerts: ({ item }) => <AlertsWidget item={item} setOpenModal={setOpenModal} />,
    research: ({ item }) => <ResearchWidget item={item} setOpenModal={setOpenModal} />,
    news: ({ item }) => <NewsWidget item={item} setOpenModal={setOpenModal} />,
    sentiment: ({ item }) => <SentimentWidget item={item} setOpenModal={setOpenModal} />,
    chart: ({ item }) => <ChartWidget item={item} setOpenModal={setOpenModal} />,
  };

  return (
    <DashboardWrapper>
      <Meta title={t("dashboard.title")} />
      <TopDashboard>
        <WalletValue total={total} />
        <WalletData />
        <WideScreenWidget />
      </TopDashboard>
      <WidgetGrid>
        {user.dashboardWidgets.length !== 0 &&
          user.dashboardWidgets.map((item) => {
            const FieldComponent = widgets[item.id];
            return <FieldComponent key={item._id} item={item} />;
          })}

        <AddWidgetCard widgetData={user.dashboardWidgets} openModal={openModal} setOpenModal={setOpenModal} />
      </WidgetGrid>
    </DashboardWrapper>
  );
};

export default Dashboard;
