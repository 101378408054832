import React from "react";

import BigNumber from "bignumber.js";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { beautifyTokensAmount } from "../../helpers";


const Wrapper = styled.div`
  margin-top: 2rem;
  .item {
    width: 100%;
    background: ${(props) => props.theme.bg};
    font-family: Montserrat;
    position: relative;
    margin: 0 auto 1rem;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    border: 1px solid ${(props) => props.theme.researchBorder};
    border-radius: 6px;

    .title {
      color: ${(props) => props.theme.mainDarkText};
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 4px;
    }

    .units {
      font-size: 14px;
      color: ${(props) => props.theme.lightGrey};
      margin-bottom: 3px;
    }

    .value {
      font-size: 14px;
      color: ${(props) => props.theme.form_text};
    }
    .color {
      position: absolute;
      top: 5px;
      left: 5px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }

    .green {
      background: ${(props) => props.theme.greenShade3};
    }
    .orange {
      background: #ffd700;
    }

    .red {
      background: #ff6400;
    }
  }
`;

const MobileGas = () => {
  const requiredGas = 21000;
  const { t } = useTranslation();
  const gasPrices = useSelector((state) => state.gas.gasPrices);
  const user = useSelector((state) => state.user);

  const getGasUSDPrice = (type) => {
    const priceOf1GasInETH = BigNumber(gasPrices[type]).dividedBy(BigNumber(10 ** 9));
    const priceOfGasForETHInUsd = BigNumber(priceOf1GasInETH)
      .multipliedBy(requiredGas)
      .multipliedBy(user.usdPrices && user.usdPrices.ETH ? user.usdPrices.ETH : 0)
      .toFixed(2);

    if (isNaN(priceOfGasForETHInUsd)) return null;

    return priceOfGasForETHInUsd;
  };

  const USDPriceFastest = getGasUSDPrice("fastest");
  const USDPriceAverage = getGasUSDPrice("average");
  const USDPriceSafeLow = getGasUSDPrice("safeLow");

  return (
    <Wrapper>
      <div className="item">
        <span className="color green"></span>
        <span className="title">{t("common.fastest")}</span>
        <span className="units">{!!gasPrices && `${gasPrices.fastest / 10} gwei`}</span>
        <span className="value">
          {!!USDPriceFastest && beautifyTokensAmount(USDPriceFastest, { prefix: "$", suffix: " USD" })}
        </span>

        <span className="units">~ {!!gasPrices.fastWait && `${gasPrices.fastWait} min`}</span>
      </div>
      <div className="item">
        <span className="color orange"></span>
        <span className="title">{t("common.average")}</span>
        <span className="units">{!!gasPrices && `${gasPrices.average / 10} gwei`}</span>
        <span className="value">
          {!!USDPriceAverage && beautifyTokensAmount(USDPriceAverage, { prefix: "$", suffix: " USD" })}
        </span>
        <span className="units">~ {!!gasPrices.avgWait && `${gasPrices.avgWait} min`}</span>
      </div>

      <div className="item">
        <span className="color red"></span>
        <span className="title">{t("common.safeLow")}</span>
        <span className="units">{!!gasPrices && `${gasPrices.safeLow / 10} gwei`}</span>
        <span className="value">
          {!!USDPriceSafeLow && beautifyTokensAmount(USDPriceSafeLow, { prefix: "$", suffix: " USD" })}
        </span>

        <span className="units">~ {!!gasPrices.safeLowWait && `${gasPrices.safeLowWait} min`}</span>
      </div>
    </Wrapper>
  );
};

export default MobileGas;
