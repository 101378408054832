import React, { useEffect, useMemo, useState } from "react";

import { getAddressSum } from "@keyfi/keyfi-common/src/integrations";
import BigNumber from "bignumber.js";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as ArrowDown } from "../../assets/arrowDown.svg";
import { ReactComponent as HideEyeIcon } from "../../assets/dashboard/hideEye.svg";
import { assetImageNetwork, beautifyTokensAmount, tokensLogos, trimAddress, wrapperNetworkToken } from "../../helpers";
import { stringToColour } from "../../Pages/ManageWallets";
import { userDataOperations } from "../../redux/userDataSlice";
import { stackBalanceOperations } from "../../redux/wallets/stackBalanceSlice";
import { stacksOperations } from "../../redux/wallets/stacksSlice";
import { supportedNetworks, walletOperations } from "../../redux/walletSlice";
import AssetImage from "../Borrow/AssetImage";
import Modal from "../Modal";
import { getAddressValue, totalValue } from "./dashboard.helpers";
import NetworkLabels from "./NetworkLabels";
import PlatformCard from "./PlatformCard";
import platforms from "./platforms";
import { TokenRows, WalletTable } from "./WalletContainer.helpers";
import { ASSETS_VIEW, LPItem, PLATFORMS_VIEW, WALLET_VIEW } from "./WalletData";
import {
  AddressSectionWrapper,
  WalletViewDropdown,
  WalletViewModalHeaderWrapper,
  WalletViewModalWrapper,
} from "./WalletViewModal.styles";

const AddressSection = ({ item, filteredTokens, prices, lp, selectedType }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const total = getAddressValue(filteredTokens, item, prices);

  const reduceFunction = (acc, token) => {
    const tokenIndex = acc.findIndex((accToken) => accToken.symbol === token.symbol);
    if (tokenIndex > -1) {
      acc[tokenIndex] = {
        ...acc[tokenIndex],
        amount: BigNumber(acc[tokenIndex].amount).plus(token.amount).toPrecision(8),
      };
      return acc;
    }
    return [...acc, token];
  };

  const sortFunction = (a, b) => {
    const aValue = totalValue(a, prices);
    const bValue = totalValue(b, prices);
    return bValue - aValue;
  };

  const price = (token) => {
    if ((token.type && token.type === "lp") || token.assetA) {
      return totalValue(token, prices) / token.amount;
    }
    return prices[token.symbol.toUpperCase()];
  };

  return (
    <AddressSectionWrapper zeroValue={total === 0}>
      <div className="address_section_header" onClick={() => total > 0 && setOpen((prev) => !prev)}>
        <div className="address_section_title">
          <div className="address_section_icon" style={{ background: stringToColour(item.address) }} />
          <div className="address_title">
            <h3>{item.title}</h3>
            <h5>{trimAddress(item.address)}</h5>
          </div>
        </div>
        <div className="address_section_header_end">
          <div className="address_section_total">
            <span>Total:</span>
            <h3>{beautifyTokensAmount(getAddressValue(filteredTokens, item, prices), { prefix: "$" })}</h3>
          </div>
          <ArrowDown style={{ transform: open ? "rotate(180deg)" : "", opacity: total > 0 ? "1" : "0" }} />
        </div>
      </div>
      <div style={{ display: open ? "block" : "none" }} className="address_section_main">
        {!lp ? (
          <table>
            <thead>
              <tr>
                <th>Asset</th>
                <th>Balance</th>
                <th>Price</th>
                <th>Value</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {filteredTokens
                .reduce(reduceFunction, [])
                .sort(sortFunction)
                .map((token) => (
                  <tr key={token.address}>
                    <td>
                      <div className="address_token">
                        <AssetImage
                          fallbackLogo={tokensLogos[token.symbol]}
                          className="table_image"
                          src={`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${
                            assetImageNetwork[token.network]
                          }/assets/${
                            token.address === "0x0000000000000000000000000000000000000000"
                              ? getAddressSum(wrapperNetworkToken[token.network])
                              : getAddressSum(token.address)
                          }/logo.png`}
                          alt={token.token}
                        />

                        <div>{token.symbol}</div>
                      </div>
                    </td>
                    <td>{beautifyTokensAmount(token.amount)}</td>
                    <td>{beautifyTokensAmount(price(token), { prefix: "$", suffix: " USD" })}</td>
                    <td>
                      {beautifyTokensAmount(totalValue(token, prices), {
                        prefix: "$",
                        suffix: " USD",
                      })}
                    </td>
                    <td>
                      {token.address && selectedType === "tokens" && (
                        <div className="wallet_data_table_hide">
                          <button onClick={() => dispatch(userDataOperations.addToTokenBlacklist(token))}>
                            <HideEyeIcon />
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        ) : (
          <div className="wallet_data_wallet_lp wallet_data_stack_lp">
            <div className="wallet_data_wallet_lp_header">
              <h4>Assets</h4>
              <h4>Platform</h4>
              <h4>Value</h4>
            </div>

            {filteredTokens.length === 0 && (
              <div className="wallet_lp_item">
                <div className="wallet_lp_item_header">No tokens</div>
              </div>
            )}
            {filteredTokens.length !== 0 &&
              filteredTokens
                .map((token) => ({
                  ...token,
                  value: totalValue(token, prices, true),
                }))
                .map((token) => <LPItem token={token} key={token.address} />)}
          </div>
        )}

        {filteredTokens
          .reduce(reduceFunction, [])
          .sort(sortFunction)
          .map((token) => (
            <div key={`${token.address}_mobile`} className="mobile_address_token">
              <div>
                <div>Asset</div>
                <div>
                  <AssetImage
                    fallbackLogo={tokensLogos[token.symbol]}
                    className="table_image"
                    src={`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${
                      assetImageNetwork[token.network]
                    }/assets/${
                      token.address === "0x0000000000000000000000000000000000000000"
                        ? getAddressSum(wrapperNetworkToken[token.network])
                        : getAddressSum(token.address)
                    }/logo.png`}
                    alt={token.token}
                  />
                  {token.symbol}
                </div>
              </div>
              <div>
                <div>Balance</div>
                <div>{beautifyTokensAmount(token.amount)}</div>
              </div>
              <div>
                <div>Price</div>
                <div>{beautifyTokensAmount(price(token), { prefix: "$", suffix: " USD" })}</div>
              </div>
              <div>
                <div>Value</div>
                <div>
                  {beautifyTokensAmount(totalValue(token, prices), {
                    prefix: "$",
                    suffix: " USD",
                  })}
                </div>
              </div>
            </div>
          ))}
      </div>
    </AddressSectionWrapper>
  );
};

const Dropdown = ({ viewType, setViewType }) => {
  const [open, setOpen] = useState(false);

  const dropdownData = [
    {
      id: ASSETS_VIEW,
      title: "Assets",
    },
    {
      id: WALLET_VIEW,
      title: "Wallets",
    },
    {
      id: PLATFORMS_VIEW,
      title: "Platforms",
    },
  ];

  const selectedType = dropdownData.find((item) => item.id === viewType);

  const handleClick = (id) => {
    setViewType(id);
    setOpen(false);
  };
  return (
    <WalletViewDropdown open={open}>
      <div onClick={() => setOpen((prev) => !prev)} className="wallet_view_dropdown">
        {selectedType.title}
        <ArrowDown className="wallet_view_arrow" />
      </div>
      {open && (
        <div className="wallet_view_dropdown_list">
          {dropdownData.map((item) => (
            <div onClick={() => handleClick(item.id)} key={item.id}>
              {item.title}
            </div>
          ))}
        </div>
      )}
    </WalletViewDropdown>
  );
};

const WalletViewModal = ({
  open,
  handleCloseModal,
  networkValues,
  selectedType,
  selectedNetwork,
  viewType,
  setViewType,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [hideSmallBalances, setHideSmallBalances] = useState(false);
  const [network, setNetwork] = useState(selectedNetwork);

  const app = useSelector((state) => state.app);
  const wallet = useSelector((state) => state.wallet);
  const stackBalance = useSelector((state) => state.stackBalance);
  const { balance, showStack } = stackBalance;
  const user = useSelector((state) => state.userData.user);
  const { tokenBlacklist } = user;

  const tokenBlacklistFilter = (item) =>
    !tokenBlacklist.some((token) => token.network === item.network && token.address === item.address);

  useEffect(() => {
    setNetwork(selectedNetwork);
  }, [selectedNetwork]);

  useEffect(() => {
    if (!showStack) {
      setViewType("assets");
    }
  }, [showStack]);

  const prices = useSelector((state) => state.user.usdPrices);

  const activeStack = dispatch(stacksOperations.getActiveStack(stackBalance.activeStackId));

  const filteredTokens =
    selectedType && network
      ? balance[selectedType].filter(
          (item) =>
            item.network === network &&
            (!hideSmallBalances ? true : BigNumber(totalValue(item, prices, selectedType === "lp")).gt(1)) &&
            tokenBlacklistFilter(item)
        )
      : [];

  const data = useMemo(() => {
    if (activeStack && activeStack.addresses) {
      return activeStack.addresses
        .slice()
        .filter((item) => (!hideSmallBalances ? true : BigNumber(getAddressValue(filteredTokens, item, prices)).gt(1)))
        .sort((a, b) => {
          const aValue = getAddressValue(filteredTokens, a, prices);
          const bValue = getAddressValue(filteredTokens, b, prices);
          return parseFloat(bValue) - parseFloat(aValue);
        });
    }
  }, [activeStack, filteredTokens, prices]);

  const networkData = supportedNetworks.reduce((acc, network) => {
    const operations = showStack ? stackBalanceOperations : walletOperations;
    return {
      ...acc,
      [network]: {
        tokens: dispatch(operations.getNetworkTokens(network)),
        staking: dispatch(operations.getNetworkStakedTokens(network)),
        lp: dispatch(operations.getNetworkLP(network)),
      },
    };
  }, {});

  const assetData = useMemo(() => {
    return networkData[network][selectedType].filter(
      (item) =>
        (!hideSmallBalances ? true : BigNumber(totalValue(item, prices, selectedType === "lp")).gt(1)) &&
        tokenBlacklistFilter(item)
    );
  }, [showStack, wallet, balance, hideSmallBalances, prices, selectedType, network, tokenBlacklist]);

  return (
    <Modal
      open={open}
      handleCloseModal={handleCloseModal}
      width="880px"
      maxwidth="880px"
      title={
        <WalletViewModalHeaderWrapper>
          Wallet View
          {showStack && <Dropdown viewType={viewType} setViewType={setViewType} />}
          <label className="wallet_view_checkbox">
            <input
              type="checkbox"
              name="checkbox"
              checked={hideSmallBalances}
              onChange={(e) => setHideSmallBalances(e.target.checked)}
            />
            <span className="checkmark" />
            Hide small balances
          </label>
        </WalletViewModalHeaderWrapper>
      }
    >
      <WalletViewModalWrapper>
        <NetworkLabels
          network={network}
          setNetwork={setNetwork}
          walletValues={networkValues}
          selectedType={selectedType}
        />
        {activeStack &&
          activeStack.addresses &&
          viewType === WALLET_VIEW &&
          data.map((item) => (
            <AddressSection
              key={item._id}
              item={item}
              prices={prices}
              lp={selectedType === "lp"}
              selectedType={selectedType}
              filteredTokens={filteredTokens.filter(
                (token) => token.userId.toLowerCase() === item.address.toLowerCase()
              )}
            />
          ))}
        {viewType === ASSETS_VIEW && (
          <WalletTable hideEye showStack={showStack} lp={selectedType === "lp"}>
            <thead>
              {selectedType === "lp" ? (
                <tr>
                  <th>{t("common.asset")}</th>
                  <th style={{ textAlign: "left" }}>{t("common.value")}</th>
                </tr>
              ) : (
                <tr>
                  <th>{t("common.asset")}</th>
                  <th>{t("common.balance")}</th>
                  <th className="wallet_no_mobile">{t("common.price")}</th>
                  <th>{t("common.value")}</th>
                  {showStack && <th />}
                  {selectedType === "wallet" && <th />}
                </tr>
              )}
            </thead>
            <tbody>
              {!app.isLoading && wallet && assetData && (
                <TokenRows tokens={assetData} type={selectedType} network={network} />
              )}
            </tbody>{" "}
          </WalletTable>
        )}
        {viewType === PLATFORMS_VIEW && (
          <div>
            {platforms
              .filter(
                (item) =>
                  item.network === network && (selectedType === "staking" ? !item.category : item?.category === "lp")
              )
              .sort((a, b) => {
                const operations = showStack ? stackBalanceOperations : walletOperations;
                const valueA = dispatch(
                  operations.getNetworkPlatformValue(a.network, a.name.toLowerCase(), a.category && a.category === "lp")
                );
                const valueB = dispatch(
                  operations.getNetworkPlatformValue(b.network, b.name.toLowerCase(), b.category && b.category === "lp")
                );

                return BigNumber(valueB).minus(BigNumber(valueA));
              })
              .map((item) => (
                <PlatformCard modalView key={`${item.name}_${item.network}`} network={item.network} item={item} />
              ))}
            {platforms
              .filter((item) => item.network === network)
              .filter((item) => {
                const operations = showStack ? stackBalanceOperations : walletOperations;
                const value = dispatch(
                  operations.getNetworkPlatformValue(
                    item.network,
                    item.name.toLowerCase(),
                    item.category && item.category === "lp"
                  )
                );
                return value > 0;
              }).length === 0 && <div>No platforms</div>}
          </div>
        )}
      </WalletViewModalWrapper>
    </Modal>
  );
};

export default WalletViewModal;
