import React, { useEffect, useMemo, useState } from "react";

import { useSelector } from "react-redux";

import UniV2Swap from "../../Swap/integrations/UniV2Swap";
import platforms from "../../Swap/SwapPlatforms";

const SwapContainer = () => {
  const [platform, setPlatform] = useState({ id: "", networkName: "" });
  const wallet = useSelector((state) => state.wallet);
  const [inputData, setInputData] = useState({
    input1: "",
    assetA: null,
    input2: "",
    assetB: null,
    whichInputChanged: null,
    estimation: null,
    isFetching: false,
  });

  useEffect(() => {
    if (wallet.network.chainId && !platform.id) {
      const firstPlatform = platforms.find((platform) => platform.network === wallet.network.chainId) || platforms[0];
      setPlatform({ id: firstPlatform.id, networkName: firstPlatform.networkName });
    }
  }, [wallet.network.name, history]);

  const selectedPlatform = useMemo(() => {
    const data = platforms.find((pt) => pt.id === platform.id && pt.networkName === platform.networkName);
    if (data) {
      setInputData((prev) => ({ ...prev, assetA: data.defaultSelected[0], assetB: data.defaultSelected[1] }));
    }
    return data;
  }, [platform]);

  const handleSelectPlatform = (data) => {
    setPlatform({ id: data.id, networkName: data.networkName });
    setInputData((prev) => ({ ...prev, input1: "", input2: "" }));
  };

  return (
    <>
      {selectedPlatform && selectedPlatform.type === "univ2" && (
        <UniV2Swap
          dashboardSwap
          inputData={inputData}
          setInputData={setInputData}
          selectedPlatform={selectedPlatform}
          setSelectedPlatform={handleSelectPlatform}
        />
      )}
    </>
  );
};

export default SwapContainer;
