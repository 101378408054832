import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { AddLiquidityWrapper } from "../../../Pages/Pools/pools.styles";
import platforms from "../../../Pages/Pools/PoolsPlatforms";
import UniV2AddLiquidity from "../../LiquidityPools/integrations/UniV2AddLiquidity";
import PlatformsDropdown from "../../PlatformsDropdown";

const PoolsContainer = () => {
  const { t } = useTranslation();
  const wallet = useSelector((state) => state.wallet);

  const [platform, setPlatform] = useState({});

  useEffect(() => {
    if (wallet.network.chainId && !platform.id) {
      const firstPlatform = platforms.find((platform) => platform.network === wallet.network.chainId) || platforms[0];
      setPlatform(firstPlatform);
    }
  }, [wallet.network.name, history]);

  const handlePlatformSwitch = (platform) => {
    setPlatform(platform);
  };
  return (
    <AddLiquidityWrapper dashboardContainer>
      {platform && (
        <div className="add-liquidity-platform">
          <div className="add-liquidity-platform-label">{t("common.choose a platform")}:</div>
          <PlatformsDropdown setOption={handlePlatformSwitch} selectedOption={platform} options={platforms} />
        </div>
      )}

      {platform && platform.type === "univ2" && <UniV2AddLiquidity selectedPlatform={platform} />}
    </AddLiquidityWrapper>
  );
};

export default PoolsContainer;
