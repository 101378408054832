import React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Meta from "../Components/Meta";
import MaxYield from "../Components/Rebalancer/MaxYield";
import StableCoins from "../Components/Rebalancer/StableCoins";
import Tabs from "../Components/Tabs";
import { breakPoints } from "../Theme";

const Wrapper = styled.div`
  width: 1000px;
  margin: 0 auto;
  overflow: hidden;
  @media (max-width: ${breakPoints.laptop}px) {
    width: 100%;
  }
`;

function Rebalancer() {
  const { t } = useTranslation();

  const tabs = [
    { Name: t("rebalancer.stablecoins"), Component: <StableCoins /> },
    { Name: t("rebalancer.maxYield"), Component: <MaxYield /> },
  ];

  return (
    <Wrapper>
      <Meta title={t("common.rebalancer")} />
      <Tabs tabs={tabs} />
    </Wrapper>
  );
}

export default Rebalancer;
