import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { ReactComponent as Star } from "../../assets/discovery/star.svg";
import { ReactComponent as TableArrow } from "../../assets/discovery/tableArrow.svg";
import { beautifyTokensAmount, compareFunction } from "../../helpers";
import { watchlistOperations } from "../../redux/watchlistSlice";
import { Skeleton } from "../../Shared";
import { TableHeadCell, TableViewCell, TableViewMobile, TableViewWrapper } from "./TableView.styles";
import TokenCard from "./TokenCard";
import WatchlistTooltip from "./WatchlistTooltip";

const handlePriceCheck = (price) => {
  if (price > 0) return "discovery_table_positive";
  if (price < 0) return "discovery_table_negative";
  return "discovery_table_neutral";
};

export const DesktopTableRow = ({ token, history, inWatchlist, handleWatchlistToggle }) => (
  <tr key={token.coin_id}>
    <TableViewCell width="2%" paddingLeft="0.75rem" noLink>
      <div>
        <WatchlistTooltip inWatchlist={inWatchlist(token.coin_id)}>
          <Star
            className={`token_star ${inWatchlist(token.coin_id) ? "token_star_selected" : ""}`}
            onClick={() => handleWatchlistToggle(token.coin_id)}
          />
        </WatchlistTooltip>
      </div>
    </TableViewCell>
    <TableViewCell onClick={() => history.push(`/research/token/${token.coin_id}`)}>
      <div>{token.market_cap_rank}</div>
    </TableViewCell>
    <TableViewCell width="12%" onClick={() => history.push(`/research/token/${token.coin_id}`)}>
      <div>
        <img src={token.icon} alt={token.name} />
        <span>{token.name}</span>
      </div>
    </TableViewCell>
    <TableViewCell onClick={() => history.push(`/research/token/${token.coin_id}`)}>
      <div>{token.symbol.toUpperCase()}</div>
    </TableViewCell>
    <TableViewCell onClick={() => history.push(`/research/token/${token.coin_id}`)}>
      <div>{beautifyTokensAmount(token.current_price, { prefix: "$" })}</div>
    </TableViewCell>
    <TableViewCell onClick={() => history.push(`/research/token/${token.coin_id}`)}>
      <div className={`${handlePriceCheck(token.price_change_percentage_1h_in_currency)}`}>
        {beautifyTokensAmount(token.price_change_percentage_1h_in_currency, {
          prefix: token.price_change_percentage_1h_in_currency > 0 ? "+" : "",
          suffix: "%",
        })}
      </div>
    </TableViewCell>
    <TableViewCell onClick={() => history.push(`/research/token/${token.coin_id}`)}>
      <div
        className={`${
          token.price_change_percentage_24h_in_currency > 0 ? "discovery_table_positive" : "discovery_table_negative"
        }`}
      >
        {beautifyTokensAmount(token.price_change_percentage_24h_in_currency, {
          prefix: token.price_change_percentage_24h_in_currency > 0 ? "+" : "",
          suffix: "%",
        })}
      </div>
    </TableViewCell>
    <TableViewCell onClick={() => history.push(`/research/token/${token.coin_id}`)}>
      <div
        className={`${
          token.price_change_percentage_7d_in_currency > 0 ? "discovery_table_positive" : "discovery_table_negative"
        }`}
      >
        {beautifyTokensAmount(token.price_change_percentage_7d_in_currency, {
          prefix: token.price_change_percentage_7d_in_currency > 0 ? "+" : "",
          suffix: "%",
        })}
      </div>
    </TableViewCell>
    <TableViewCell onClick={() => history.push(`/research/token/${token.coin_id}`)}>
      <div>{beautifyTokensAmount(token.total_volume, { prefix: "$", noDecimals: true })}</div>
    </TableViewCell>
    <TableViewCell onClick={() => history.push(`/research/token/${token.coin_id}`)}>
      <div>{beautifyTokensAmount(token.circulating_supply, { noDecimals: true })}</div>
    </TableViewCell>
    <TableViewCell onClick={() => history.push(`/research/token/${token.coin_id}`)}>
      <div>{token.total_supply ? beautifyTokensAmount(token.total_supply, { noDecimals: true }) : "-"}</div>
    </TableViewCell>
    <TableViewCell onClick={() => history.push(`/research/token/${token.coin_id}`)}>
      <div>{beautifyTokensAmount(token.market_cap, { prefix: "$", noDecimals: true })}</div>
    </TableViewCell>
  </tr>
);

export const MobileViewCard = ({ token }) => {
  const { t } = useTranslation();
  return (
    <div className="discovery_mobile_card">
      <div className="discovery_mobile_card_line">
        <div className="discovery_mobile_card_title">{t("common.marketCapRank")}</div>
        <div className="discovery_mobile_card_value">{token.market_cap_rank}</div>
      </div>
      <div className="discovery_mobile_card_line">
        <div className="discovery_mobile_card_title">{t("common.token")}</div>
        <div className="discovery_mobile_card_value">
          <img src={token.icon} alt="" srcSet="" />
          {token.name}
        </div>
      </div>
      <div className="discovery_mobile_card_line">
        <div className="discovery_mobile_card_title">{t("common.symbol")}</div>
        <div className="discovery_mobile_card_value">{token.symbol.toUpperCase()}</div>
      </div>
      <div className="discovery_mobile_card_line">
        <div className="discovery_mobile_card_title">{t("common.price")}</div>
        <div className="discovery_mobile_card_value">{beautifyTokensAmount(token.current_price, { prefix: "$" })}</div>
      </div>
      <div className="discovery_mobile_card_line">
        <div className="discovery_mobile_card_title">1h</div>
        <div
          className={"discovery_mobile_card_value " + handlePriceCheck(token.price_change_percentage_1h_in_currency)}
        >
          {beautifyTokensAmount(token.price_change_percentage_1h_in_currency, {
            prefix: token.price_change_percentage_1h_in_currency > 0 ? "+" : "",
            suffix: "%",
          })}
        </div>
      </div>
      <div className="discovery_mobile_card_line">
        <div className="discovery_mobile_card_title">24h</div>
        <div
          className={"discovery_mobile_card_value " + handlePriceCheck(token.price_change_percentage_24h_in_currency)}
        >
          {beautifyTokensAmount(token.price_change_percentage_24h_in_currency, {
            prefix: token.price_change_percentage_24h_in_currency > 0 ? "+" : "",
            suffix: "%",
          })}
        </div>
      </div>
      <div className="discovery_mobile_card_line">
        <div className="discovery_mobile_card_title">7d</div>
        <div
          className={"discovery_mobile_card_value " + handlePriceCheck(token.price_change_percentage_7d_in_currency)}
        >
          {beautifyTokensAmount(token.price_change_percentage_7d_in_currency, {
            prefix: token.price_change_percentage_7d_in_currency > 0 ? "+" : "",
            suffix: "%",
          })}
        </div>
      </div>
      <div className="discovery_mobile_card_line">
        <div className="discovery_mobile_card_title">{t("common.24hVolume")}</div>
        <div className="discovery_mobile_card_value">
          {beautifyTokensAmount(token.total_volume, { prefix: "$", noDecimals: true })}
        </div>
      </div>
      <div className="discovery_mobile_card_line">
        <div className="discovery_mobile_card_title">{t("common.circulatingSupply")}</div>
        <div className="discovery_mobile_card_value">
          {beautifyTokensAmount(token.circulating_supply, { noDecimals: true })}
        </div>
      </div>
      <div className="discovery_mobile_card_line">
        <div className="discovery_mobile_card_title">{t("common.totalSupply")}</div>
        <div className="discovery_mobile_card_value">
          {token.total_supply ? beautifyTokensAmount(token.total_supply, { noDecimals: true }) : "-"}
        </div>
      </div>
      <div className="discovery_mobile_card_line">
        <div className="discovery_mobile_card_title">{t("common.marketCap")}</div>
        <div className="discovery_mobile_card_value">
          {beautifyTokensAmount(token.total_volume, { prefix: "$", noDecimals: true })}
        </div>
      </div>
    </div>
  );
};

const TableView = ({ pageSize = 15 }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const tableHeadItems = [
    { id: "market_cap_rank", label: "#", sortable: true },
    { id: "name", label: t("common.token"), sortable: true },
    { id: "symbol", label: t("common.symbol"), sortable: true },
    { id: "current_price", label: t("common.price"), sortable: true },
    { id: "price_change_percentage_1h_in_currency", label: "1h", sortable: true },
    { id: "price_change_percentage_24h_in_currency", label: "24h", sortable: true },
    { id: "price_change_percentage_7d_in_currency", label: "7d", sortable: true },
    { id: "total_volume", label: t("common.24hVolume"), sortable: true },
    { id: "circulating_supply", label: t("common.circulatingSupply"), sortable: true },
    { id: "total_supply", label: t("common.totalSupply"), sortable: true },
    { id: "market_cap", label: t("common.marketCap"), sortable: true },
  ];

  const discovery = useSelector((state) => state.discovery);
  const { allTokens, isFetching } = discovery;

  const userData = useSelector((state) => state.userData);
  const { user } = userData;

  const tokens = [...allTokens];

  const [sort, setSort] = useState({
    field: tableHeadItems[0].id,
    direction: "asc",
  });
  const changeSort = (field) => {
    setSort((sort) => {
      if (sort.field === field) {
        return {
          ...sort,
          direction: sort.direction === "asc" ? "desc" : "asc",
        };
      } else {
        return {
          field,
          direction: "desc",
        };
      }
    });
  };

  const inWatchlist = (tokenId) => {
    if (user.mainWatchlist.assets) {
      return user.mainWatchlist.assets.some((id) => id === tokenId);
    }
    return false;
  };

  const handleWatchlistToggle = (id) => {
    if (inWatchlist(id, true)) {
      return dispatch(watchlistOperations.removeAssetFromWatchlist(user.mainWatchlist.id, id, true));
    }
    return dispatch(watchlistOperations.addAssetToWatchlist(user.mainWatchlist.id, id, true));
  };

  return (
    <>
      <TableViewWrapper noMobile>
        <thead>
          <tr>
            <TableHeadCell />
            {tableHeadItems.map((item) => (
              <TableHeadCell key={item.id}>
                <div onClick={() => changeSort(item.id)}>
                  {item.label}
                  <div className="discovery_table_sort">
                    <TableArrow
                      style={{ transform: "rotate(180deg)" }}
                      className={
                        sort.field === item.id && sort.direction === "asc" ? "discovery_table_sort_selected" : ""
                      }
                    />
                    <TableArrow
                      className={
                        sort.field === item.id && sort.direction === "desc" ? "discovery_table_sort_selected" : ""
                      }
                    />
                  </div>
                </div>
              </TableHeadCell>
            ))}
          </tr>
        </thead>
        <tbody>
          {!isFetching
            ? tokens
                .sort(compareFunction(sort))
                .map((token) => (
                  <DesktopTableRow
                    handleWatchlistToggle={handleWatchlistToggle}
                    token={token}
                    history={history}
                    inWatchlist={inWatchlist}
                    key={token.coin_id}
                    t={t}
                  />
                ))
            : [...Array(pageSize)].map((_, i) => (
                <tr key={i}>
                  <TableViewCell width="2%" paddingLeft="0.75rem">
                    <div>
                      <Star className="discovery_table_watchlist" />
                    </div>
                  </TableViewCell>
                  <TableViewCell>
                    <Skeleton width="17" height="17" />
                  </TableViewCell>
                  <TableViewCell width="12%">
                    <div>
                      <Skeleton style={{ marginRight: "6px" }} circle height="20" width="20" />
                      <Skeleton height="17" width="70" />
                    </div>
                  </TableViewCell>
                  <TableViewCell>
                    <Skeleton width="32" height="17" />
                  </TableViewCell>
                  <TableViewCell>
                    <Skeleton width="60" height="17" />
                  </TableViewCell>
                  <TableViewCell>
                    <Skeleton width="41" height="17" />
                  </TableViewCell>
                  <TableViewCell>
                    <Skeleton width="41" height="17" />
                  </TableViewCell>
                  <TableViewCell>
                    <Skeleton width="41" height="17" />
                  </TableViewCell>
                  <TableViewCell>
                    <Skeleton width="110" height="17" />
                  </TableViewCell>
                  <TableViewCell>
                    <Skeleton width="70" height="17" />
                  </TableViewCell>
                  <TableViewCell>
                    <Skeleton width="70" height="17" />
                  </TableViewCell>
                  <TableViewCell>
                    <Skeleton width="113" height="17" />
                  </TableViewCell>
                </tr>
              ))}
        </tbody>
      </TableViewWrapper>
      <TableViewMobile>
        {!isFetching
          ? tokens.map((token) => <TokenCard key={token.coin_id} token={token} mobile />)
          : [...Array(pageSize)].map((_, i) => <TokenCard skeleton={true} key={i} mobile />)}
      </TableViewMobile>
    </>
  );
};

export default TableView;
