import React, { useEffect, useState } from "react";

import { integrations } from "@keyfi/keyfi-common";
import { useTranslation } from "react-i18next";
import { useDispatch , useSelector } from "react-redux";
import styled from "styled-components";

import { tokensLogos } from "../../../helpers";
import { txQueueOperations } from "../../../redux/txQueueSlice";
import StrategyDropdown from "../../Strategy/StrategyDropdown";

const Wrapper = styled.div``;

const platformsList = {
  KeyFi: () => [
    { id: "KEYFI", text: "KEYFI" },
    { id: "ETH/KEYFI", text: "ETH/KEYFI" },
    { id: "USDC", text: "USDC" },
    { id: "wETH", text: "wETH" },
    { id: "wBTC", text: "wBTC" },
  ],
  Aave: async () => {
    const tokens = await integrations.aave.v2.getSupportedAssets();

    return tokens.map((option) => ({
      id: option,
      img: tokensLogos[option] || <h2 style={{ textAlign: "center", width: "32px" }}>?</h2>,
      text: option,
    }));
  },
  Compound: async () => {
    const tokens = await integrations.compound.getSupportedAssets();

    return tokens.map((option) => ({
      id: option,
      img: tokensLogos[option] || <h2 style={{ textAlign: "center", width: "32px" }}>?</h2>,
      text: option,
    }));
  },
};

const getPlatforms = async (name) => {
  if (name === "KeyFi") return platformsList.KeyFi();
  if (name === "Aave") return platformsList.Aave();
  if (name === "Compound") return platformsList.Compound();
  return [];
};

const DepositAction = ({ handleCloseModal, editModal, name }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const txQueue = useSelector((state) => state.txQueue);
  const { activeAction } = txQueue;

  const handleSaveAction = () => {
    dispatch(txQueueOperations.addToTxQueueList());
    handleCloseModal();
  };

  const handleUpdateAction = () => {
    dispatch(txQueueOperations.updateTxQueueItem());
    handleCloseModal();
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await getPlatforms(name);
      setOptions(data);
    };
    fetchData();
  }, [name]);

  return (
    <Wrapper>
      <h5>{t("common.asset")}</h5>
      <StrategyDropdown
        name="0"
        placeholder={t("strategy.chooseAnAsset")}
        setOption={(name, value) => dispatch(txQueueOperations.handleActiveActionArgs(name, value))}
        options={options}
        selectedOption={activeAction.args[0]}
      />
      <h5>{t("common.value")}</h5>
      <input
        name="1"
        value={activeAction.args[1]}
        onChange={(e) => dispatch(txQueueOperations.handleActiveActionArgs(e.target.name, e.target.value))}
        className="edit_action_input"
      />
      <button className="primary" onClick={editModal ? handleUpdateAction : handleSaveAction}>
        {t("strategy.saveAction")}
      </button>
    </Wrapper>
  );
};

export default DepositAction;
