import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const API = process.env.REACT_APP_PRO_API;

const initialState = {
  isLoading: false,
  addresses: [],
};

const addresses = createSlice({
  name: "addresses",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setAddresses(state, action) {
      state.addresses = action.payload;
    },
  },
});

export const addressesActions = addresses.actions;

const getRoot = (state) => state.addresses;

export const addressesSelectors = {
  getLoading: (state) => getRoot(state).isLoading,
  getAddresses: (state) => getRoot(state).addresses,
};

export const addressesOperations = {
  fetchUserAddresses: () => async (dispatch, getState) => {
    try {
      dispatch(addressesActions.setLoading(true));
      const token = getState().userData.tokens.access.token;

      const config = {
        headers: {
          Authorization: token,
        },
      };
      const { data } = await axios.get(`${API}/v1/wallet`, config);
      dispatch(addressesActions.setAddresses(data));
      dispatch(addressesActions.setLoading(false));
    } catch (err) {
      console.log(err);
      dispatch(addressesActions.setLoading(false));
    }
  },
  getAddressFromAddress: (address) => (dispatch, getState) => {
    const addresses = addressesSelectors.getAddresses(getState());
    const addressObject = addresses.find((item) => item.address.toLowerCase() === address.toLowerCase());

    return addressObject || undefined;
  },
};

export const addressesReducer = addresses.reducer;
