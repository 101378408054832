import React, { useState } from "react";

import axios from "axios";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { validateEmail } from "../../helpers";
import { ErrorMessage, FormButton, InputField, LoginForm } from "../../Shared";
import Meta from "../Meta";

const ForgotPWText = styled.div`
  margin: 2rem 0 1rem;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => props.theme.default_color};
  .line_to_right {
    padding-right: 1rem;
    border-right: 1px solid white;
  }
  span {
    margin-left: 1rem;
    .registerLink {
      margin-left: 0.5rem;
    }
  }
`;

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [userEmail, setUserEmail] = useState("");
  const [emailStatus, setEmailStatus] = useState({
    status: "",
    message: "",
  });

  const handleChange = (e) => {
    setUserEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEmailStatus({
      status: "",
      message: "",
    });
    try {
      await axios.post(`${process.env.REACT_APP_PRO_API}/v1/auth/forgot-password`, {
        email: userEmail,
      });
      setEmailStatus({
        status: "success",
        message: t("auth.checkYourEmail"),
      });
    } catch (err) {
      setEmailStatus({
        status: "error",
        message: err.response.data,
      });
    }
  };

  return (
    <LoginForm autoComplete="off" onSubmit={handleSubmit}>
      <Meta title="Forgot Password" />
      <h2>{t("auth.forgotPassword")}</h2>
      <div className="inputContainer">
        <label htmlFor="emailForgot">{t("auth.email")}</label>
        <InputField
          type="email"
          name="email"
          onFocus={() => setEmailStatus((prev) => ({ ...prev, status: "" }))}
          onBlur={(e) => !validateEmail(e.target.value) && setEmailStatus((prev) => ({ ...prev, status: "" }))}
          placeholder={t("auth.enterEmailPlaceholder")}
          error={emailStatus.status === "error"}
          id="emailForgot"
          onChange={handleChange}
          required
        ></InputField>
        {emailStatus.status && (
          <ErrorMessage success={emailStatus.status === "success"} style={{ margin: "0.5rem 0 0", fontSize: "12px" }}>
            {emailStatus.message}
          </ErrorMessage>
        )}
        <FormButton marginTop type="submit" disabled={!userEmail || emailStatus.status === "success"}>
          {t("auth.resetPassword")}
        </FormButton>
        <ForgotPWText>
          <Link className="line_to_right" to="/signin">
            {t("auth.login")}
          </Link>
          <span>
            {t("auth.doNotHaveAccount")}{" "}
            <Link className="registerLink" to="/signup">
              {t("auth.register")}
            </Link>
          </span>
        </ForgotPWText>
      </div>
    </LoginForm>
  );
};

export default ForgotPassword;
