import React, { useEffect } from "react";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import SentimentArc from "../../../assets/widgets/sentiment_arc.svg";
import { ReactComponent as SentimentArrow } from "../../../assets/widgets/sentiment_arrow.svg";
import { sentimentOperations } from "../../../redux/Slices/sentimentSlice";
import WidgetWrapper from "./WidgetWrapper";

const LastUpdated = styled.span`
  font-size: 12px;
  color: ${(props) => props.theme.lightGrey};
  align-self: flex-end;
  margin-left: 0.75rem;
`;

const SentimentWrapper = styled.div`
  margin-top: 2px;
  .sentiment_title {
    font-size: 14px;
  }
  .sentiment_image {
    margin: 3rem 0 2rem;
    position: relative;
    img {
      display: block;
      margin: 0 auto;
    }
    .sentiment_arrow {
      position: absolute;
      bottom: -32px;
      right: calc(50% - 20px);
      transform-origin: 137px 44px;
    }
    .sentiment_value_container {
      width: 220px;
      height: 40px;
      display: flex;
      position: absolute;
      bottom: -20px;
      right: 50%;
      transform-origin: 100% 50%;

      .sentiment_value {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        background: ${(props) => props.theme.lightGrey};
        color: white;
        font-size: 18px;
        font-weight: 500;
        border-radius: 50%;
      }
    }
  }
  .sentiment_history {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    > div {
      display: flex;
      align-items: center;
      gap: 4px;
      color: ${(props) => props.theme.lightGrey};
      font-size: 14px;
    }
  }
`;

const valueColor = {
  "Extreme Fear": "#F26D24",
  Fear: "#F7B11E",
  Neutral: "#FED137",
  Greed: "#FDEB48",
  "Extreme Greed": "#86DF2D",
};

const SentimentWidget = ({ setOpenModal, item }) => {
  const dispatch = useDispatch();
  const sentiment = useSelector((state) => state.sentiment);
  const { value } = sentiment;

  useEffect(() => {
    if (!value) {
      dispatch(sentimentOperations.getSentiment());
    }
  }, [value]);

  const rotation = value ? 180 * (Number(value.current.value) / 100) : 0;

  return (
    <WidgetWrapper
      item={item}
      id="sentiment"
      setOpenModal={setOpenModal}
      header={
        <div className="widget_info">
          Fear & Greed Index{" "}
          <LastUpdated>
            Last updated: {value && moment(Number(value.current.timestamp) * 1000).format("DD MMM, YYYY")}
          </LastUpdated>{" "}
        </div>
      }
      body={
        <SentimentWrapper>
          {value && (
            <>
              <h4 className="sentiment_title" style={{ color: valueColor[value.current.value_classification] }}>
                {value.current.value_classification}
              </h4>
              <div className="sentiment_image">
                {value && (
                  <>
                    <img src={SentimentArc} />
                    <SentimentArrow className="sentiment_arrow" style={{ transform: `rotate(${rotation}deg)` }} />
                    <div className="sentiment_value_container" style={{ transform: `rotate(${rotation}deg)` }}>
                      <div style={{ transform: `rotate(-${rotation}deg)` }} className="sentiment_value">
                        {value.current.value}
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="sentiment_history">
                <div>
                  Yesterday{" "}
                  <div style={{ color: valueColor[value.yesterday.value_classification] }}>{value.yesterday.value}</div>
                </div>
                <div>
                  Last week{" "}
                  <div style={{ color: valueColor[value.lastweek.value_classification] }}>{value.lastweek.value}</div>
                </div>
                <div>
                  Last month{" "}
                  <div style={{ color: valueColor[value.lastmonth.value_classification] }}>{value.lastmonth.value}</div>
                </div>
              </div>
            </>
          )}
        </SentimentWrapper>
      }
    />
  );
};

export default SentimentWidget;
