import React from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import styled from "styled-components";

import { ReactComponent as ArrowIcon } from "../assets/arrow-out.svg";
import { poolsLogos, tokensLogos, trimAddress } from "../helpers";
import { breakPoints } from "../Theme";

export const calculateTokenPriceInUsd = (usdPrices, token, amount) => {
  if (usdPrices && token) {
    return String(usdPrices[token] * amount).slice(0, 5);
  }
};

const MobileCell = styled.td`
  align-items: center;
  display: flex;
  height: 50px;
  margin: 0;
  max-width: 100%;

  img,
  svg {
    height: 20px;
    width: 20px;
    margin-right: 5px;
  }
  .icon-text {
    display: flex;
    align-items: center;
  }

  :not(.last) {
    border-bottom: 1px solid ${(props) => props.theme.lightBlue3};

    @media (max-width: ${breakPoints.lg}px) {
      border-bottom: 1px solid ${(props) => props.theme.brandBlue};

      img,
      svg {
        height: 16px;
        width: 16px;
      }
    }
  }
`;

const DesktopTableRow = styled.tr`
  display: table-row;

  .icon-text {
    display: flex;
    align-items: center;
    svg,
    img {
      margin-right: 8px;
      margin-left: 4px;
    }
  }
  a {
    color: ${(props) => props.theme.form_text};
  }
  @media (max-width: ${breakPoints.lg}px) {
    display: none;
  }
`;

const MobileTableRow = styled.tr`
  display: none;

  @media (max-width: ${breakPoints.lg}px) {
    background: ${(props) => props.theme.superLightBlue};
    border: 1px solid ${(props) => props.theme.lightBlue3};
    color: ${(props) => props.theme.mainDarkText};
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    margin-bottom: 1.5em;
    padding: 10px 16px;
    height: initial !important;
    width: 100%;

    .tableTitle {
      color: ${(props) => props.theme.brandBlue};
      margin-right: 5px;
      min-width: 80px;
    }

    div {
      font-weight: 500;
    }
  }
`;

const txUrl = {
  mainnet: "https://etherscan.io/tx/",
  "bsc-mainnet": "https://bscscan.com/tx/",
  polygon: "https://polygonscan.com/tx/",
};

const DesktopTableRowWrap = ({
  platform,
  txHash,
  pending,
  txType,
  txAmount,
  txToken,
  user,
  t,
  timeStamp,
  status,
  network,
}) => {
  return (
    <DesktopTableRow>
      <td>
        {txHash ? (
          <a href={`${network ? txUrl[network] : txUrl["mainnet"]}${txHash}`} target="_blank" rel="noreferrer">
            <span className="td__wrapper hash icon-text">
              {trimAddress(txHash)}
              <ArrowIcon />
            </span>
          </a>
        ) : (
          "-"
        )}
      </td>
      <td>
        <span className="icon-text">
          {poolsLogos[platform]} {platform}
        </span>
      </td>
      <td>
        {status && !pending ? (
          <span className="status-failed">{t("common.failed")}</span>
        ) : !pending ? (
          <span className="status-success">{t("common.success")}</span>
        ) : null}
      </td>
      <td>{txType}</td>
      <td>
        <span className="icon-text">
          {tokensLogos[txToken]} {txToken}
        </span>
      </td>
      <td>
        {String(txAmount).slice(0, 5)} {txToken}
      </td>
      <td>
        {isNaN(calculateTokenPriceInUsd(user.usdPrices, txToken, Number(txAmount)))
          ? null
          : "$" + calculateTokenPriceInUsd(user.usdPrices, txToken, Number(txAmount))}
      </td>
      <td style={{ textAlign: "right" }}>{timeStamp}</td>
    </DesktopTableRow>
  );
};

const MobileTableRowWrap = ({
  platform,
  txHash,
  network,
  pending,
  txType,
  txAmount,
  txToken,
  user,
  t,
  timeStamp,
  status,
  tableHeadItems,
}) => {
  return (
    <MobileTableRow>
      <MobileCell>
        <div className="tableTitle">{tableHeadItems[0]}</div>
        <div>
          {txHash ? (
            <a href={`${network ? txUrl[network] : txUrl["mainnet"]}${txHash}`} target="_blank" rel="noreferrer">
              <span className="td__wrapper hash ">
                {trimAddress(txHash)}
                <ArrowIcon />
              </span>
            </a>
          ) : (
            <span>-</span>
          )}
        </div>
      </MobileCell>
      <MobileCell>
        <div className="tableTitle">{tableHeadItems[1]}</div>
        <div className="icon-text">
          {poolsLogos[platform]} {platform}
        </div>
      </MobileCell>
      <MobileCell>
        <div className="tableTitle">{tableHeadItems[2]}</div>
        <div>
          {status && !pending ? (
            <span className="status-failed">{t("common.failed")}</span>
          ) : !pending ? (
            <span className="status-success">{t("common.success")}</span>
          ) : null}
        </div>
      </MobileCell>
      <MobileCell>
        <div className="tableTitle">{tableHeadItems[3]}</div>
        <div>{txType}</div>
      </MobileCell>
      <MobileCell>
        <div className="tableTitle">{tableHeadItems[4]}</div>
        <div className="icon-text">
          {tokensLogos[txToken]} {txToken}
        </div>
      </MobileCell>
      <MobileCell>
        <div className="tableTitle">{tableHeadItems[5]}</div>
        <div>
          {String(txAmount).slice(0, 5)} {txToken}
        </div>
      </MobileCell>
      <MobileCell>
        <div className="tableTitle">{tableHeadItems[6]}</div>
        <div>
          {isNaN(calculateTokenPriceInUsd(user.usdPrices, txToken, Number(txAmount)))
            ? null
            : "$" + calculateTokenPriceInUsd(user.usdPrices, txToken, Number(txAmount))}
        </div>
      </MobileCell>
      <MobileCell className="last">
        <div className="tableTitle">{tableHeadItems[7]}</div>
        <div>{timeStamp}</div>
      </MobileCell>
    </MobileTableRow>
  );
};

function TxContentCard({
  platform,
  txHash,
  pending,
  txType,
  txAmount,
  txToken,
  user,
  network,
  timeStamp,
  status,
  tableHeadItems,
}) {
  const { t } = useTranslation();
  return (
    <>
      <DesktopTableRowWrap
        platform={platform}
        network={network ?? null}
        txHash={txHash}
        pending={pending}
        txType={txType}
        txAmount={txAmount}
        txToken={txToken}
        user={user}
        t={t}
        timeStamp={timeStamp}
        status={status}
      />
      <MobileTableRowWrap
        platform={platform}
        network={network ?? null}
        txHash={txHash}
        pending={pending}
        txType={txType}
        txAmount={txAmount}
        txToken={txToken}
        user={user}
        t={t}
        timeStamp={timeStamp}
        status={status}
        tableHeadItems={tableHeadItems}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(TxContentCard);
