import React from "react";

import { useDispatch } from "react-redux";
import styled from "styled-components";

import { editAddressOperations } from "../../redux/wallets/editAddressSlice";
import { editStackOperations } from "../../redux/wallets/editStackSlice";
import { breakPoints } from "../../Theme";
import Modal from "../Modal";

const Wrapper = styled.div`
  padding: 1.5rem 0 0;
  p {
    margin-bottom: 2rem;
  }
  .wallet_delete_buttons {
    display: flex;
    gap: 1rem;
    button {
      padding: 0.75rem 2.5rem;
      background: 0;
      border: 1px solid ${(props) => props.theme.researchBorder};
      border-radius: 6px;
      color: ${(props) => props.theme.mainDarkText};
    }
    .wallet_main {
      border-color: ${(props) => props.theme.error};
      background: ${(props) => props.theme.error};
      color: white;
    }
    @media (max-width: ${breakPoints.md}px) {
      justify-content: space-between;
      button {
        flex: 1;
        padding: 0.75rem 0;
      }
    }
  }
`;

const DeleteStackModal = ({ stack, open, handleClose }) => {
  const dispatch = useDispatch();
  const handleDelete = () => {
    if (stack) {
      dispatch(editStackOperations.handleDelete());
    } else {
      dispatch(editAddressOperations.deleteAddress());
    }
    handleClose();
    return;
  };
  return (
    <Modal title="Delete Address" open={open} maxwidth="500px" width="100%" handleCloseModal={handleClose}>
      <Wrapper>
        {stack ? (
          <p>Are you sure you want to delete this Stack?</p>
        ) : (
          <p>Are you sure you want to delete this address from your Tracked list?</p>
        )}
        <div className="wallet_delete_buttons">
          <button className="wallet_main" onClick={handleDelete}>
            Delete
          </button>
          <button className="wallet_secondary" onClick={handleClose}>
            Cancel
          </button>
        </div>
      </Wrapper>
    </Modal>
  );
};

export default DeleteStackModal;
