import React, { useState } from "react";

import BigNumber from "bignumber.js";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { ReactComponent as ArrowDown } from "../../assets/arrowDown.svg";
import { beautifyTokensAmount } from "../../helpers";
import { stackBalanceOperations } from "../../redux/wallets/stackBalanceSlice";
import { stacksOperations } from "../../redux/wallets/stacksSlice";
import { walletOperations } from "../../redux/walletSlice";
import { Skeleton } from "../../Shared";
import { breakPoints } from "../../Theme";
import Modal from "../Modal";
import { getAddressValue } from "./dashboard.helpers";
import LPPlatformTable from "./LPPlatformTable";
import { AddressSection } from "./MoreDetailsModal";
import PlatformTable from "./PlatformTable";

const Wrapper = styled.div`
  padding: 0.75rem;
  background: ${(props) => props.theme.secondaryBg};
  border: 1px solid ${(props) => props.theme.researchBorder};
  border-radius: 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
  :not(:last-of-type) {
    margin-bottom: 0.75rem;
  }
  :hover {
    background: ${(props) => props.theme.bg};
  }
  .platform_img {
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 0.5rem;
    img {
      height: 38px;
      width: 38px;
    }
    .platform_network_img {
      position: absolute;
      top: 0;
      left: 0;
      height: 16px;
      width: 16px;
      transform: translate(-30%, -30%);
    }
  }
  .platform_title {
    flex: 1;
  }
`;

const PlatformViewWrapper = styled.div`
  :not(:last-of-type) {
    margin-bottom: 1rem;
  }
  .platform_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${(props) =>
      props.open &&
      css`
        padding-bottom: 10px;
        border-bottom: 1px solid ${props.theme.researchBorder};
      `}
    cursor: pointer;
    .platform_info {
      display: flex;
      align-items: center;
      img,
      svg {
        height: 32px;
        width: 32px;
        margin-right: 6px;
      }
      .platform_title {
        margin-right: 0.75rem;
      }
      .platform_value {
        padding: 0.5rem 0.75rem;
        background: ${(props) => props.theme.bg};
        border-radius: 6px;
      }
    }
    svg {
      height: 18px;
      width: 18px;
    }
  }
  .platform_body {
    padding-left: ${(props) => props.showStack && "2rem"};
  }
`;

const PlatformModalTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  .title_name {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    img {
      height: 32px;
      width: 32px;
    }
    span {
      font-size: 24px;
    }
  }

  .title_values {
    display: flex;
    .value_box {
      background: ${(props) => props.theme.bg};
      font-size: 16px;
      line-height: 19.5px;
      border-radius: 4px;
      margin-right: 1.5rem;
      padding: 0.5rem 1rem;
    }
  }

  @media (max-width: ${breakPoints.lg}px) {
    .title_name {
      margin: 1rem 0;
    }
    .title_values {
      .value_box {
        font-size: 16px;
        line-height: 16px;
      }
    }
  }
`;

const StackWrapper = styled.div`
  padding: 2rem 0 1rem;
  ${(props) =>
    props.modalView &&
    css`
      padding-top: 1rem;
    `}
`;

const PlatformCard = ({ network, item, modalView }) => {
  const [open, setOpen] = useState(modalView ? true : false);
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.app.isLoading);
  const wallet = useSelector((state) => state.wallet);

  const stackBalance = useSelector((state) => state.stackBalance);
  const { showStack } = stackBalance;

  const user = useSelector((state) => state.user);
  const { usdPrices } = user;

  const operations = showStack ? stackBalanceOperations : walletOperations;

  const getData = () => {
    if (item.category && item.category === "lp") {
      return dispatch(operations.getNetworkLP(network, item.name.toLowerCase()));
    }
    return dispatch(operations.getNetworkStakedTokens(item.network, item.name.toLowerCase(), showStack));
  };

  const getValue = dispatch(
    operations.getNetworkPlatformValue(item.network, item.name.toLowerCase(), item.category && item.category === "lp")
  );

  const value = usdPrices ? getValue : 0;

  const activeStack = dispatch(stacksOperations.getActiveStack(stackBalance.activeStackId));

  if (value > 0) {
    if (modalView) {
      return (
        <PlatformViewWrapper open={open} showStack={showStack}>
          <div className="platform_header" onClick={() => setOpen((prev) => !prev)}>
            <div className="platform_info">
              {item.icon}
              <div className="platform_title">{item.name}</div>
              <div className="platform_value">
                {isLoading && wallet.selectedAddress ? (
                  <Skeleton height="19" width="60" />
                ) : (
                  beautifyTokensAmount(value, { prefix: "$" })
                )}
              </div>
            </div>
            <ArrowDown style={{ transform: open ? "rotate(180deg)" : "" }} />
          </div>
          <div style={{ display: open ? "block" : "none" }} className="platform_body">
            {item && item.category === "lp" && (
              <LPPlatformTable user={user} tokens={getData()} title={item.name.toLowerCase()} network={network} />
            )}
            {!showStack && <PlatformTable user={user} tokens={getData()} />}
            {showStack && item.category !== "lp" && (
              <StackWrapper modalView>
                {activeStack &&
                  activeStack.addresses &&
                  activeStack.addresses
                    .slice()
                    .sort((a, b) => {
                      const aValue = getAddressValue(getData(), a, usdPrices);
                      const bValue = getAddressValue(getData(), b, usdPrices);

                      return BigNumber(bValue).minus(BigNumber(aValue)).toNumber();
                    })
                    .map((item) => (
                      <AddressSection
                        key={item._id}
                        item={item}
                        prices={usdPrices}
                        filteredTokens={getData().filter(
                          (token) => token.userId.toLowerCase() === item.address.toLowerCase()
                        )}
                      />
                    ))}
              </StackWrapper>
            )}
          </div>
        </PlatformViewWrapper>
      );
    }
    return (
      <Wrapper onClick={() => setOpen(true)}>
        <div className="platform_img">{item.icon}</div>
        <div className="platform_title">{item.name}</div>
        <div className="platform_value">
          {isLoading && wallet.selectedAddress ? (
            <Skeleton height="19" width="60" />
          ) : (
            beautifyTokensAmount(value, { prefix: "$" })
          )}
        </div>
        <Modal
          open={open}
          width="100%"
          maxwidth="925px"
          handleCloseModal={() => setOpen(false)}
          title={
            <PlatformModalTitle>
              <div className="title_name">
                {item.icon}
                <span>{item.name}</span>
              </div>
              <div className="title_values">
                <div className="value_box">
                  {beautifyTokensAmount(value, {
                    suffix: " USD",
                    prefix: "$",
                  })}
                </div>
              </div>
            </PlatformModalTitle>
          }
        >
          {item && item.category === "lp" && (
            <LPPlatformTable user={user} tokens={getData()} title={item.name.toLowerCase()} network={network} />
          )}
          {!showStack && <PlatformTable user={user} tokens={getData()} />}
          {showStack && item.category !== "lp" && (
            <StackWrapper>
              {activeStack &&
                activeStack.addresses &&
                activeStack.addresses
                  .slice()
                  .sort((a, b) => {
                    const aValue = getAddressValue(getData(), a, usdPrices);
                    const bValue = getAddressValue(getData(), b, usdPrices);

                    return BigNumber(bValue).minus(BigNumber(aValue)).toNumber();
                  })
                  .map((item) => (
                    <AddressSection
                      key={item._id}
                      item={item}
                      prices={usdPrices}
                      filteredTokens={getData().filter(
                        (token) => token.userId.toLowerCase() === item.address.toLowerCase()
                      )}
                    />
                  ))}
            </StackWrapper>
          )}
        </Modal>
      </Wrapper>
    );
  }
  return <></>;
};

export default PlatformCard;
