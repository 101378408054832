import React, { useState } from "react";

import styled, { css } from "styled-components";

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 18px;
  height: 18px;
  background: ${(props) => props.theme.blackish};
  border-radius: 3px;
  border: 1px solid #3a4c61;
  transition: all 150ms;
  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
  ${(props) =>
    props.focus &&
    css`
      box-shadow: ${(props) => props.theme.userInputFocusShadow};
      border-color: ${(props) => props.theme.userInputFocus};
    `}
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  max-height: 18px;
`;

const Checkbox = ({ className, checked, ...props }) => {
  const [focus, setFocus] = useState(false);

  return (
    <CheckboxContainer className={className}>
      <HiddenCheckbox checked={checked} {...props} onFocus={() => setFocus(true)} onBlur={() => setFocus(false)} />
      <StyledCheckbox checked={checked} focus={focus}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  );
};

export default Checkbox;
