import styled from "styled-components";

import { breakPoints } from "../../Theme";

export const Widget = styled.div`
  cursor: move;
  background: ${(props) => props.theme.sidebar_bg};
  position: relative;
  padding: 1rem 1rem;
  border-radius: 6px;
  color: ${(props) => props.theme.default_color};
  border: 1px solid ${(props) => props.theme.input_placefolder};
  display: flex;
  flex-direction: column;
  height: 100%;
  @media (max-width: ${breakPoints.lg}px) {
    padding: 10px;
  }
  .widget_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .widget_info {
      display: flex;
      align-items: center;
      a {
        color: ${(props) => props.theme.mainDarkText};
      }
      button {
        cursor: pointer;
        margin-left: 0.75rem;
        background: ${(props) => props.theme.sidebar_bg};
        border: 1.16px solid ${(props) => props.theme.input_placefolder};
        border-radius: 6px;
        padding: 6px 12px;
        display: flex;
        align-items: center;
        color: ${(props) => props.theme.default_color};
        font-size: 12px;
        img,
        svg {
          margin-right: 10px;
        }
        :hover {
          background: ${(props) => props.theme.bg};
        }
      }
    }
    .widget_grid_options {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 4px;
      .widget_grid_option {
        padding: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          height: 16px;
          width: 16px;
        }
      }
      .widget_grid_selected {
        background: ${(props) => props.theme.blueTransparent};
        box-shadow: inset 0px 0px 0px 1px ${(props) => props.theme.brandBlue};
        border-radius: 6px;
      }
      .widget_expand {
        cursor: pointer;
      }
    }
    .widget_grid_options_show {
      background: ${(props) => props.theme.inputPlaceholder};
      border-radius: 6px;
    }
  }
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes rotate {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  .widget_load_wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    .widget_load {
      width: 80px;
      height: 80px;
      margin: 110px auto 0;
      border: solid 7px #58afff;
      border-radius: 50%;
      border-right-color: transparent;
      border-bottom-color: transparent;
      -webkit-transition: all 0.5s ease-in;
      -webkit-animation-name: rotate;
      -webkit-animation-duration: 1s;
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-timing-function: linear;

      transition: all 0.5s ease-in;
      animation-name: rotate;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
  .widget_main {
    margin-top: 1rem;
    flex: 1;
    @media (max-width: ${breakPoints.lg}px) {
      margin: 0;
    }
    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: ${(props) => props.theme.borderBg};
    }

    ::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.scroleThumb};
      border-radius: 10px;
    }
  }
`;

export const AlertSingleBox = styled.div`
  padding: 1rem;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  opacity: ${(props) => props.opacity};
  background: linear-gradient(90deg, #101926 0%, rgba(74, 91, 114, 0.2) 100%);
  border: 1px solid ${(props) => props.theme.borderBg};
  border-bottom: 0;
  :first-of-type {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  :last-of-type {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border: 1px solid ${(props) => props.theme.borderBg};
  }
  .AlertLeftside {
    display: flex;
    align-items: center;
    span:nth-child(1) {
      margin-right: 10px;
    }
    .alertTitle {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: #ced2d6;
      margin: 0;
    }
    .alertDesc {
      font-style: normal;
      font-weight: 300;
      font-size: 11px;
      line-height: 13px;
      color: #6c8195;
      margin: 0;
    }
  }
  .AlertRightSide {
    display: flex;
    align-items: center;
    .editIcon {
      margin-left: 1.7rem;
      margin-right: 1.7rem;
      cursor: pointer;
    }
    .deleteIcon {
      cursor: pointer;
    }
  }
`;

export const DeletItemWrapper = styled.div`
  position: absolute;
  top: 66px;
  left: 0px;
  right: 0px;
  border: 2px solid #2a3540;
  background: #182331;
  z-index: 12;
`;

export const DeleteInfo = styled.div`
  padding: 10px;
  background: linear-gradient(90deg, #101926 0%, rgba(74, 91, 114, 0) 100%);
  .alrtInfoTitle {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #ced2d6;
    margin: 0px;
  }
  .aletInfoDes {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #6c8195;
    width: 85%;
    margin: 0px;
  }
`;

export const TableMain = styled.table`
  border-collapse: collapse;
  thead {
    width: 100%;
    padding-right: 4px;

    tr {
      width: 100%;
      th {
        padding: 0 0 9px;
        color: ${(props) => props.theme.lightGrey};
        border-bottom: 1px solid ${(props) => props.theme.researchBorder};
        text-align: left;
        font-size: 14px;
        :first-of-type {
          padding: 0 9px 9px;
          width: 45px;
        }

        @media (max-width: ${breakPoints.lg}px) {
          padding: 9px 0 3px;
          :first-of-type {
            width: 10%;
            padding: 9px 5px 3px;
          }
          :nth-child(2) {
            width: 7%;
          }
          :nth-child(3) {
            width: 25%;
          }
        }
      }
    }
  }
  .load_opacity {
    opacity: 0.5;
  }
  tbody {
    overflow: auto;
    ::-webkit-scrollbar {
      width: 4px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.scroleThumb};
      border-radius: 10px;
    }
    tr {
      width: 100%;
      cursor: pointer;
      :nth-child(2n) {
        background: ${(props) => props.theme.darkGrey};
      }
      :hover {
        z-index: 2;
        background: #041c3f;
        /* Border inside */
        box-shadow: inset 0px 0px 0px 1px ${(props) => props.theme.brandBlue};
        border-radius: 6px;
      }

      td {
        padding: 10px 0;
        font-weight: 500;
        font-size: 14px;
        :first-of-type {
          width: 45px;
          padding: 9px;
        }

        .research_widget_token {
          display: flex;
          align-items: center;
          img {
            margin-right: 0.4rem;
            height: 20px;
            width: 20px;
          }
        }
        a {
          color: ${(props) => props.theme.form_text};
          text-decoration: underline;
          padding-right: 4px;

          img {
            min-height: 8px;
            min-width: 8px;
          }
        }

        @media (max-width: ${breakPoints.lg}px) {
          padding: 7px 0;
          :first-of-type {
            width: 10%;
            padding: 7px;
          }
          :nth-child(2) {
            width: 7%;
          }
          :nth-child(3) {
            width: 25%;
          }
          :nth-child(4) {
            width: 28%;
          }

          .research_widget_token img {
            height: 16px;
            width: 16px;
          }
        }
      }
      .research_widget_diff {
        color: ${(props) => props.theme.greenShade};
      }
      .research_widget_minus {
        color: ${(props) => props.theme.redMinus};
      }
    }
    @media (max-width: ${breakPoints.lg}px) {
      height: 130px;
      tr {
        :hover {
          z-index: 4;
          background: ${(props) => props.theme.blueTransparent};
          /* Border inside */
          box-shadow: inset 0px 0px 0px 1px ${(props) => props.theme.brandBlue};
          border-radius: 6px;
        }
      }
    }
  }
  .research_no_mobile {
    @media (max-width: ${breakPoints.lg}px) {
      display: none;
    }
  }
`;
