import React, { useState } from "react";

import { getAddressSum } from "@keyfi/keyfi-common/src/integrations";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled, { css } from "styled-components";

import { ReactComponent as MagnifyingGlass } from "../../assets/discovery/magnifyingGlass.svg";
import { ReactComponent as ChevronDownIcon } from "../../assets/header/chevron-down.svg";
import { assetImageChainID, beautifyTokensAmount, tokensLogos, wrapperNetworkToken } from "../../helpers";
import { walletOperations } from "../../redux/walletSlice";
import { breakPoints } from "../../Theme";
import AssetImage from "../Borrow/AssetImage";
import Modal from "../Modal";

const Wrapper = styled.div`
  background: ${(props) => props.theme.bg};
  width: 100%;
  padding: 9px;
  padding-left: 0.75rem;
  display: flex;
  align-items: center;
  border: 1px solid ${(props) => props.theme.researchBorder};
  border-radius: 6px;

  :focus-within {
    border-color: ${(props) => props.theme.brandBlue};
    box-shadow: 0px 0px 6px 0px #005ad340;
  }
  ${(props) =>
    props.error &&
    css`
      border-color: ${(props) => props.theme.error} !important;
      input {
        color: ${(props) => props.theme.error};
      }
    `}
`;

const StyledInput = styled.input`
  flex: 1;
  font-family: Montserrat;
  font-size: 16px;
  border: 0;
  font-weight: 500;
  color: ${(props) => props.theme.mainDarkText};
  :focus {
    outline: 0;
  }
  ::placeholder {
    color: ${(props) => props.theme.disabled};
  }

  @media (max-width: ${breakPoints.md}px) {
    font-size: 16px;
    width: 50%;
  }
`;

const StyledMax = styled.span`
  background: ${(props) => props.theme.secondaryBg};
  color: white;
  font-size: 14px;
  line-height: 16px;
  border-radius: 6px;
  padding: 0.25rem 1rem;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 500;
  border: 1px solid ${(props) => props.theme.researchBorder};
  :hover {
    background: ${(props) => props.theme.bg};
  }

  @media (max-width: ${breakPoints.md}px) {
    font-size: 12px;
    flex: 2;
  }

  ${(props) =>
    props.readOnly &&
    css`
      background: ${props.theme.disabled};
      pointer-events: none;
    `}
`;

const SelectionWrap = styled.div`
  display: flex;
  align-items: center;
  img {
    height: 24px;
    width: 24px;
  }
`;

const StyledSelect = styled.div`
  background: ${(props) => (props.selectedOption ? props.theme.bg : props.theme.brandBlue)};
  color: ${(props) => (props.selectedOption ? props.theme.default_color : props.theme.white)};
  outline: 0;
  border: 0;
  border-radius: ${(props) => (props.selectedOption ? 0 : "6px")};
  padding: ${(props) => (props.selectedOption ? 0 : "4px 1rem")};
  font-weight: 500;
  font-size: 1em;
  line-height: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 1rem;

  img {
    width: 26px;
    height: 26px;
    margin-right: 0.5rem;
  }

  @media (max-width: ${breakPoints.md}px) {
    img,
    svg {
      width: 24px;
      height: 24px;
    }
  }

  .selected__choose {
    margin-right: 0.5rem;
    font-size: 14px;
  }
  .selected__text {
    margin-right: 0.5rem;
  }

  .selected__arrow {
    width: 12px;
    height: max-content;
    path {
      fill: ${(props) => (props.selectedOption ? props.theme.default_color : props.theme.white)};
    }
  }

  @media (max-width: ${breakPoints.md}px) {
    font-size: 12px;
    margin-left: 0.5rem;
  }
`;

const StyledOptions = styled.div`
  color: ${(props) => props.theme.mainDarkText};
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  justify-content: flex-start;
  flex-direction: column;
  max-height: 350px;
  min-height: 350px;
  overflow-y: auto;
  z-index: 9999;
  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  span {
    margin-left: 5px;
  }
  svg,
  img {
    width: 32px !important;
    height: 32px !important;
  }
`;

const StyledOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  height: 56px;
  cursor: pointer;
  color: ${(props) => props.theme.default_color};
  font-size: 16px;
  line-height: 20px;
  .token-info {
    display: flex;
    align-items: center;
    .swap_token_image {
      height: 32px;
      width: 32px;
      font-size: 32px;
      line-height: 32px;
      margin: 0;
    }
  }
  :hover {
    background: ${(props) => props.theme.bg};
  }

  border-top: 1px solid ${(props) => props.theme.form_text3};
`;

const OptionsWrapper = styled.div`
  min-height: 400px;

  .input-wrapper {
    margin-top: 32px;
    margin-bottom: 24px;
    background: ${(props) => props.theme.bg};
    display: flex;
    padding: 0.75rem;
    gap: 1rem;
    border-radius: 6px;
    border: 1px solid ${(props) => props.theme.researchBorder};
    input {
      flex: 1;
      ::placeholder {
        color: ${(props) => props.theme.placeholderText};
      }
    }
  }
`;

function DropDown({ options, network, name, updateParent, selectedToken }) {
  const dispatch = useDispatch();
  const [showOptions, setShowOptions] = useState(false);
  const { t } = useTranslation();

  const [searchQuery, setSearchQuery] = useState("");

  const walletTokens = dispatch(walletOperations.getWalletNetworkTokens(network));

  const handleShowOptions = () => {
    setShowOptions(!showOptions);
  };

  // handle select an option from the dropdown
  const handleSelect = (token) => {
    setShowOptions(false);
    updateParent(name, token);
  };

  const handleCloseModal = () => {
    setSearchQuery("");
    setShowOptions(false);
  };

  const filteredOptions =
    options.length > 0
      ? options.filter((item) => item.symbol.toLowerCase().includes(searchQuery.toLowerCase())).slice(0, 100)
      : [];

  return (
    <div>
      <StyledSelect onClick={handleShowOptions} selectedOption={selectedToken}>
        {selectedToken ? (
          <>
            <AssetImage
              style={{ borderRadius: "50%" }}
              fallbackLogo={tokensLogos[selectedToken.symbol]}
              className="swap_token_image"
              src={`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${
                assetImageChainID[network]
              }/assets/${
                selectedToken.address === "0x0000000000000000000000000000000000000000"
                  ? getAddressSum(wrapperNetworkToken[network])
                  : getAddressSum(selectedToken.address)
              }/logo.png`}
              alt={selectedToken.symbol}
            />
            <span className="selected__text">{selectedToken.symbol}</span>
          </>
        ) : (
          <span className="selected__choose">{t("common.chooseAtoken")}</span>
        )}
        <ChevronDownIcon className="selected__arrow" />
      </StyledSelect>

      {showOptions && (
        <Modal handleCloseModal={handleCloseModal} maxwidth="480px" title={t("common.chooseAtoken")}>
          <OptionsWrapper>
            <div className="input-wrapper">
              <input
                className="search"
                type="text"
                placeholder={t("common.searchTokenName")}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <MagnifyingGlass />
            </div>
            {filteredOptions.length ? (
              <StyledOptions>
                {filteredOptions
                  .sort((a, b) => {
                    const aValue = parseFloat(walletTokens[a.symbol]) || 0;
                    const bValue = parseFloat(walletTokens[b.symbol]) || 0;
                    return bValue - aValue;
                  })
                  .map((option) => (
                    <StyledOption key={option.address} onClick={() => handleSelect(option)}>
                      <div className="token-info">
                        <AssetImage
                          fallbackLogo={tokensLogos[option.symbol]}
                          className="swap_token_image"
                          src={`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${
                            assetImageChainID[network]
                          }/assets/${
                            option.address === "0x0000000000000000000000000000000000000000"
                              ? getAddressSum(wrapperNetworkToken[network])
                              : getAddressSum(option.address)
                          }/logo.png`}
                          alt={option.symbol}
                        />
                        <span>{option.symbol}</span>
                      </div>

                      <span className="token-value">{beautifyTokensAmount(walletTokens[option.symbol])}</span>
                    </StyledOption>
                  ))}
              </StyledOptions>
            ) : (
              t("common.noResultFound")
            )}
          </OptionsWrapper>
        </Modal>
      )}
    </div>
  );
}

const TokenInput = (props) => {
  const { t } = useTranslation();
  const {
    placeholder,
    updateParent,
    asset,
    name,
    value,
    options,
    invokeMaxInput,
    readOnly,
    userTokens,
    network,
    selectedToken,
  } = props;

  const onChange = (e) => {
    // filter non-numric input values
    let str = "";
    if (e.target.value.match(/\d|\./g)) {
      str = e.target.value.match(/\d|\./g).join("");
    }

    updateParent(e.target.name, str);
  };
  return (
    <Wrapper>
      <StyledInput
        onChange={onChange}
        autoComplete="off"
        placeholder={placeholder || "0.0"}
        name={name}
        value={value}
        readOnly={readOnly}
      />
      <SelectionWrap>
        {invokeMaxInput && (
          <StyledMax readOnly={readOnly} onClick={() => invokeMaxInput(name)}>
            {t("common.max")}
          </StyledMax>
        )}
        <DropDown
          name={asset}
          updateParent={updateParent}
          selectedToken={selectedToken}
          options={options}
          userTokens={userTokens}
          network={network}
        />
      </SelectionWrap>
    </Wrapper>
  );
};

export default TokenInput;
