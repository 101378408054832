import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Meta from "../../Components/Meta";
import Pagination from "../../Components/Pagination";
import ResearchPageHeader from "../../Components/Research/ResearchPageHeader";
import TableView from "../../Components/Research/TableView";
import TokenCard from "../../Components/Research/TokenCard";
import { Storage } from "../../helpers/Storage";
import { discoveryOperations } from "../../redux/discoverySlice";
import { breakPoints } from "../../Theme";
import { Wrapper } from "./Research.styles";

function Research({ history, location }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const query = new URLSearchParams(location.search);
  const page = parseFloat(query.get("page")) || 1;

  const [dataView, setDataView] = useState(Storage.getItem("dataView") || "table");

  const discovery = useSelector((state) => state.discovery);
  const { allTokens, tokensStats, isFetching } = discovery;

  const PAGE_SIZE = window.innerWidth > breakPoints.xxl ? 40 : 15;

  useEffect(() => {
    dispatch(discoveryOperations.getTokenList(page, PAGE_SIZE));
  }, [page, dispatch, PAGE_SIZE]);

  const handleDataViewChange = (view) => {
    Storage.setItem("dataView", view);
    setDataView(view);
  };

  const handlePageChange = (page) => {
    return history.push(`/research?page=${page}`);
  };

  return (
    <Wrapper>
      <Meta title={t("research.title")} />
      <ResearchPageHeader setDataView={handleDataViewChange} dataView={dataView} selected="tokenlist" />

      {dataView === "grid" && (
        <div className="discovery_grid">
          {!isFetching && allTokens.length !== 0
            ? allTokens.map((token) => <TokenCard key={token.coin_id} token={token} />)
            : [...Array(PAGE_SIZE)].map((item, i) => <TokenCard key={`${item}-${i}`} skeleton={true} />)}
        </div>
      )}
      {dataView === "table" && <TableView pageSize={PAGE_SIZE} />}
      <div className="discovery_pagination">
        <Pagination
          page={page}
          setPage={handlePageChange}
          lastPage={Math.ceil(tokensStats.tokenCount / PAGE_SIZE)}
          disabledPagination={isFetching}
        />
      </div>
    </Wrapper>
  );
}

export default Research;
