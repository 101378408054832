import { getAddressSum } from "@keyfi/keyfi-common/src/integrations";

class Token {
  constructor(network, address, symbol, decimals) {
    this.network = network;
    this.address = getAddressSum(address);
    this.symbol = symbol;
    this.decimals = decimals;
  }
}

export default Token;
