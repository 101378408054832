import styled from "styled-components";

import { Skeleton } from "../../../Shared";
import { breakPoints } from "../../../Theme";

export const MyBorrowsWrapper = styled.div`
  margin-bottom: 2.5rem;
  display: flex;
  gap: 2.5rem;
  @media (max-width: ${breakPoints.lg}px) {
    flex-direction: column;
    gap: 1.5rem;
  }
  .my_borrows {
    flex: 1.5;
    display: flex;
    flex-direction: column;
    .my_borrows_header {
      margin-bottom: 0.5rem;
      .my_borrows_total {
        display: flex;
        h3 {
          margin: 0;
          align-items: center;
          font-size: 16px;
        }
        span {
          display: flex;
          align-items: center;
          font-weight: 600;
          margin-left: 0.5rem;
          ${Skeleton} {
            margin-left: 0.25rem;
          }
        }
      }
    }
    .my_borrows_body {
      background: ${(props) => props.theme.secondaryBg};
      padding: 1rem 1.5rem;
      border-radius: 6px;
      border: 1px solid ${(props) => props.theme.researchBorder};
      @media (max-width: ${breakPoints.md}px) {
        padding: 0.5rem 0.75rem;
      }
    }
    .my_borrows_no_assets {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .my_collateral {
    flex: 1;
    .my_collateral_header {
      margin-bottom: 0.5rem;
      .my_collateral_total {
        display: flex;
        h3 {
          margin: 0;
          align-items: center;
          font-size: 16px;
        }
        span {
          display: flex;
          align-items: center;
          font-weight: 600;
          margin-left: 0.5rem;
          ${Skeleton} {
            margin-left: 0.25rem;
          }
        }
      }
    }
    .my_collateral_body {
      background: ${(props) => props.theme.secondaryBg};
      width: 100%;
      padding: 1rem;
      border-radius: 6px;
      border: 1px solid ${(props) => props.theme.researchBorder};
      @media (max-width: ${breakPoints.md}px) {
        padding: 1rem 0.75rem;
      }
    }
  }
`;

export const DesktopMyBorrowsWrapper = styled.div`
  @media (max-width: ${breakPoints.md}px) {
    display: none;
  }
  .my_borrows_manage_link {
    display: block;
    color: ${(props) => props.theme.form_text};
    text-decoration: underline;
    font-size: 14px;
    font-weight: 600;
  }
`;
