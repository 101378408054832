import styled from "styled-components";

import { breakPoints } from "../Theme";

export const ManageWalletsWrapper = styled.div`
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
  .manage_wallet_grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(440px, 1fr));

    gap: 8rem;
    @media (max-width: ${breakPoints.md}px) {
      grid-template-columns: 1fr;
      gap: 3rem;
    }
    .addresses_section {
      .manage_wallet_title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
        h3 {
          font-size: 18px;
        }
        button {
          height: 34px;
          width: 34px;
          border-radius: 6px;
          background: ${(props) => props.theme.txGreen};
          border: 0;
          :hover {
            background: ${(props) => props.theme.txGreenHover};
          }
        }
      }

      .manage_wallet_cards {
        margin-bottom: 2.5rem;
      }
    }
  }
`;

export const AddressCardWrapper = styled.div`
  padding: 1rem;
  background: ${(props) => props.theme.secondaryBg};
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.researchBorder};
  margin-bottom: 1.5rem;
  .address_card_pic {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    background: grey;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .address_card_info {
    flex: 1;
    h5 {
      font-size: 16px;
      margin-bottom: 0.25rem;
    }
    h6 {
      font-size: 12px;
      color: ${(props) => props.theme.lightGrey};
    }
  }
  .address_card_header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    position: relative;
    .address_card_connected {
      position: absolute;
      top: 0;
      left: 0;
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background: ${(props) => props.theme.notificationGreen};
      transform: translate(-0.5rem, -0.5rem);
    }

    .address_card_buttons {
      display: none;
      align-items: center;
      gap: 1.5rem;

      button {
        background: 0;
        border: 0;
        outline: 0;
        display: flex;
        gap: 6px;
        color: ${(props) => props.theme.mainDarkText};
      }
      @media (max-width: ${breakPoints.md}px) {
        display: flex;
        span {
          display: none;
        }
      }
    }
  }
  .address_card_main {
    margin: 1rem 0 0.5rem;
    border-top: 1px solid ${(props) => props.theme.researchBorder};
    padding-top: 1rem;
    flex-direction: column;
    gap: 1.25rem;
    .address_main_details {
      display: flex;
      gap: 0.5rem;
      padding-left: 2.5rem;
    }
  }
  :hover {
    background: ${(props) => props.theme.blueTransparent};
    border-color: ${(props) => props.theme.brandBlue};
    .address_card_buttons {
      display: flex;
    }
  }
`;
