import styled, { css } from "styled-components";

import { breakPoints } from "../../Theme";

export const LineBreak = styled.div`
  flex-direction: column;
  border-bottom: 1px solid ${(props) => props.theme.input_placefolder};
  margin-top: 15px;

  @media (max-width: ${breakPoints.sm}px) {
    width: calc(100vw - 40px);
  }
`;

export const Wrapper = styled.div`
  display: flex;
  .verticleLine {
    border-left: 1px solid ${(props) => props.theme.input_placefolder};
    width: 1px;
    margin-left: 15px;
  }
`;
export const SerachInput = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 12px;
  background: ${(props) => props.theme.bg};
  border: 1px solid ${(props) => props.theme.input_placefolder};
  box-sizing: border-box;
  border-radius: 6px;
  height: 1.875em;

  .clickable {
    cursor: pointer;
  }

  input {
    margin-left: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875em;
  }

  input::placeholder {
    color: ${(props) => props.theme.placeholderText};
  }
  @media (max-width: ${breakPoints.lg}px) {
    margin-top: 1rem;
    input {
      width: 100%;
    }
  }
`;

export const ClickableButton = styled.button`
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 6px 12px;
  background: ${(props) => props.theme.secondaryBg};
  border: 1px solid ${(props) => props.theme.input_placefolder};
  border-radius: 6px;
  height: 32px;
  color: ${(props) => props.theme.mainDarkText};
  margin-left: 1em;
  font-size: 14px;

  .clickable {
    cursor: pointer;
  }

  span {
    margin-left: 0.375em;
  }

  @media (max-width: ${breakPoints.sm}px) {
    margin-right: 1em;
    margin-left: 0em;
  }

  ${(props) =>
    props.click == "click" &&
    css`
      &:active {
        background: #122a4b;
      }
    `}

  ${(props) =>
    props.mostVote == "active" &&
    css`
      background: #122a4b;
    `}

    ${(props) =>
    props.mostUnvote == "active" &&
    css`
      background: #122a4b;
    `}
  ${(props) =>
    props.icon &&
    css`
      justify-content: center;
      padding: 0 6px;
      min-width: 32px;
    `}
`;

export const ListWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  align-items: center;
  button {
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .view {
    position: relative;
  }

  .toggle {
    border-radius: 6px;
    border: 1px solid ${(props) => props.theme.brandBlue};
    background: ${(props) => props.theme.blueTransparent};
  }

  .clickable {
    cursor: pointer;
  }

  .grid {
    top: -0.5px;
  }

  @media (max-width: ${breakPoints.sm}px) {
  }
`;

export const MobileWrapper = styled.div`
  .news_mobile_upper {
    display: flex;
    align-items: center;
    width: 100%;
  }
`;
