import styled from "styled-components";

import { breakPoints } from "../../Theme";

export const DiscoveryPageHeaderWrapper = styled.div`
  h1 {
    color: ${(props) => props.theme.mainDarkText};
    font-size: 24px;
    margin: 0 0 2rem;
    @media (max-width: ${breakPoints.md}px) {
      display: none;
    }
  }
  .discovery_toggle_mobile {
    display: none;
    @media (max-width: ${breakPoints.md}px) {
      display: block;
      color: ${(props) => props.theme.mainDarkText};
      font-size: 14px;
      margin-bottom: 1.5rem;
      padding: 7px 1rem;
    }
  }
  .discovery_header_info {
    display: flex;
    font-size: 14px;
    flex-wrap: wrap;
    gap: 1.5rem;
    padding-bottom: 1.5rem;
    @media (max-width: ${breakPoints.md}px) {
      row-gap: 8px;
      column-gap: 2.5rem;
    }
    > div {
      display: flex;
      align-items: center;
      span {
        margin-left: 0.25rem;
        color: ${(props) => props.theme.form_text};
      }
    }
  }

  .discovery_table_options {
    border-top: 1px solid ${(props) => props.theme.researchBorder};
    padding-top: 1.5rem;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    svg {
      cursor: pointer;
      path,
      rect {
        stroke: ${(props) => props.theme.mainDarkText};
      }
    }
    .discovery_table_selected {
      path,
      rect {
        stroke: ${(props) => props.theme.form_text};
      }
    }
  }
  .discovery_grid_options {
    display: flex;
    gap: 1rem;
  }
`;

export const SearchInput = styled.div`
  position: relative;
  padding: 0.5rem 1rem 0.5rem 0.75rem;
  border: 1px solid ${(props) => props.theme.researchBorder};
  border-radius: 6px;
  display: flex;
  align-items: center;
  max-width: 225px;
  :focus-within {
    background: ${(props) => props.theme.blueTransparent};
    border-color: ${(props) => props.theme.brandBlue};
  }

  .tokenSymbol {
    margin-left: 5px;
    color: ${(props) => props.theme.lightGrey};
  }

  input {
    font-size: 14px;
    line-height: 17px;
    width: 100%;
    ::placeholder {
      color: ${(props) => props.theme.placeholderText};
    }
  }
  svg {
    height: 16px;
    width: 16px;
  }
  .search_input_list {
    position: absolute;
    overflow-y: auto;
    top: 100%;
    left: 0;
    z-index: 4;
    transform: translateY(0.5rem);
    width: 100%;
    max-height: 300px;
    background: ${(props) => props.theme.secondaryBg};
    border: 1px solid ${(props) => props.theme.researchBorder};
    border-radius: 6px;
    padding: 0 1rem;
    ::-webkit-scrollbar {
      width: 0; /* Remove scrollbar space */
      background: transparent; /* Optional: just make scrollbar invisible */
    }

    a {
      color: ${(props) => props.theme.mainDarkText};
      font-size: 14px;
      display: flex;
      align-items: center;
      padding: 1rem 0.5rem;
      border-bottom: 1px solid ${(props) => props.theme.researchBorder};
      :last-of-type {
        border: 0;
      }
      :hover {
        background: ${(props) => props.theme.bg};
      }
      img {
        height: 16px;
        width: 16px;
        margin-right: 6px;
      }
    }
  }
`;
