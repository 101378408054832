import React from "react";

import { useTranslation } from "react-i18next";

// Assets
import { ReactComponent as WorldIcon } from "../../assets/header/world.svg";
import { ReactComponent as BSC } from "../../assets/info/bsc.svg";
import { ReactComponent as ETH } from "../../assets/info/eth.svg";
import { ReactComponent as FAQ } from "../../assets/info/faq.svg";
import { ReactComponent as NomicsBSC } from "../../assets/info/nomicss_bsc.svg";
import { ReactComponent as NomicsETH } from "../../assets/info/nomicss_erc.svg";
import { ReactComponent as UserGuide } from "../../assets/info/user_guide.svg";
import { ReactComponent as Whitepaper } from "../../assets/info/whitepaper.svg";
import APE from "../../assets/platforms/Apeswap.svg";
import CAKE from "../../assets/platforms/pancakeswap.png";
import UNI from "../../assets/platforms/uniswap.svg";
import { ReactComponent as Polygon } from "../../assets/Polygon-chain.svg";
import PolygonVector from "../../assets/polygon-vector.svg";
import QuickSwap from "../../assets/quick-swap.svg";
import { ReactComponent as Hacken } from "../../assets/sidebar/hacken.svg";
import { ReactComponent as BSCScan } from "../../assets/socialMedia/BSCScan.svg";
import { ReactComponent as CoinGecko } from "../../assets/socialMedia/CoinGecko.svg";
import { ReactComponent as CoinMarketCap } from "../../assets/socialMedia/CoinMarketCap.svg";
import CryptoPanic from "../../assets/socialMedia/cryptopanic.png";
import { ReactComponent as ETHScan } from "../../assets/socialMedia/EtherScan.svg";
import Github from "../../assets/socialMedia/Github.svg";
import Instagram from "../../assets/socialMedia/Instagram.svg";
import LinkedIn from "../../assets/socialMedia/LinkedIn.svg";
import Medium from "../../assets/socialMedia/Medium.svg";
import Reddit from "../../assets/socialMedia/Reddit.svg";
import Telegram from "../../assets/socialMedia/Telegram.svg";
import Twitter from "../../assets/socialMedia/Twitter.svg";
import YouTube from "../../assets/socialMedia/YouTube.svg";
import Meta from "../../Components/Meta";
import { InfoLinkContainer, InfoNetworkContainer, InfoWrapper } from "./Info.styles";

const networkData = {
  ETH: {
    links: [
      {
        url: "https://v2.info.uniswap.org/token/0xb8647e90c0645152fccf4d9abb6b59eb4aa99052",
        title: "KEYFI",
        image: <img src={UNI} alt="KeyFi" />,
      },
      {
        url: "https://app.uniswap.org/#/add/v2/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/0xB8647e90C0645152Fccf4d9AbB6B59Eb4AA99052",
        title: "KEYFI-USDC LP",
        image: <img src={UNI} alt="KEYFI-USDC LP" />,
      },
      {
        url: "https://app.uniswap.org/#/add/v2/ETH/0xB8647e90C0645152Fccf4d9AbB6B59Eb4AA99052",
        title: "KEYFI-ETH LP",
        image: <img src={UNI} alt="KEYFI-ETH LP" />,
      },
    ],
  },
  BSC: {
    links: [
      {
        url: "https://app.apeswap.finance/swap?outputCurrency=0x4b6000f9163de2e3f0a01ec37e06e1469dbbce9d",
        title: "KEYFI",
        image: <img src={APE} alt="KEYFI" />,
      },
      {
        url: "https://pancakeswap.finance/swap?outputCurrency=0x4b6000f9163de2e3f0a01ec37e06e1469dbbce9d",
        title: "KEYFI",
        image: <img src={CAKE} alt="KEYFI" />,
      },
      {
        url: "https://pancakeswap.finance/info/pool/0xd10321489beb6d3a83e09fa059cf6c8be5a4c542",
        title: "KEYFI-BUSD LP",
        image: <img src={CAKE} alt="KEYFI-BUSD LP" />,
      },
    ],
  },

  Polygon: {
    links: [
      {
        url: "https://quickswap.exchange/#/swap?inputCurrency=0xd1a5f2a049343fc4d5f8d478f734eba51b22375e",
        title: "KEYFI",
        image: <img src={QuickSwap} alt="KEYFI" />,
      },
      {
        url: "https://quickswap.exchange/#/quick/0xD1A5f2A049343fc4D5F8d478f734eBa51B22375E/0x831753DD7087CaC61aB5644b308642cc1c33Dc13/0x8b405c88cdACB1f54206183bC5866bdAdfa315c8",
        title: "KEYFI-QUICK LP",
        image: <img src={QuickSwap} alt="KEYFI" />,
      },
    ],
  },
};

const Info = () => {
  const { t } = useTranslation();
  return (
    <InfoWrapper>
      <Meta title="KEYFI Info" />
      <h2>{t("info.buyKEYFITokenandLP")}</h2>
      <InfoNetworkContainer>
        <div className="network">
          <div className="info_network">
            <div className="info_network_title">
              <ETH />
              <div>
                <h3>ETH</h3>
                <h6>{t("info.network")}</h6>
              </div>
            </div>
            <div className="info_network_links">
              {networkData.ETH.links.map((link) => (
                <div key={link.title}>
                  {link.image}
                  <a href={link.url} target="_blank" rel="noreferrer">
                    {link.title}
                  </a>
                </div>
              ))}
            </div>
            {/* <a
            className="info_network_buy_button"
            href="https://docs.keyfi.com/getting-started/buy-keyfi"
            target="_blank"
            rel="noreferrer"
          >
            {t("info.buyKEYFIonETH")}
          </a> */}
          </div>

          <div className="info_network">
            <div className="info_network_title">
              <BSC />
              <div>
                <h3>BSC</h3>
                <h6>{t("info.network")}</h6>
              </div>
            </div>
            <div className="info_network_links">
              {networkData.BSC.links.map((link, i) => (
                <div key={`${link.title}-${i}`}>
                  {link.image}
                  <a href={link.url} target="_blank" rel="noreferrer">
                    {link.title}
                  </a>
                </div>
              ))}
            </div>
            {/* <a
            className="info_network_buy_button"
            href="https://docs.keyfi.com/getting-started/buy-keyfi"
            target="_blank"
            rel="noreferrer"
          >
            {t("info.buyKEYFIonBSC")}
          </a> */}
          </div>

          <div className="info_network">
            <div className="info_network_title">
              <Polygon />
              <div>
                <h3>POLYGON</h3>
                <h6>{t("info.network")}</h6>
              </div>
            </div>
            <div className="info_network_links polygon-link">
              {networkData.Polygon.links.map((link, i) => (
                <div key={`${link.title}-${i}`}>
                  {link.image}
                  <a href={link.url} target="_blank" rel="noreferrer">
                    {link.title}
                  </a>
                </div>
              ))}
            </div>
            {/* <a
            className="info_network_buy_button"
            href="https://docs.keyfi.com/buy-keyfi/buy-on-quickswap"
            target="_blank"
            rel="noreferrer"
          >
            Buy KEYFI on POLYGON
          </a> */}
          </div>
        </div>
        <a
          className="info_network_buy_button"
          href="https://docs.keyfi.com/buy-keyfi/buy-on-quickswap"
          target="_blank"
          rel="noreferrer"
        >
          How to Buy KEYFI
        </a>
      </InfoNetworkContainer>

      <h2>{t("info.information")}</h2>
      <InfoLinkContainer>
        <h4>{t("info.resources")}</h4>
        <div className="info_links">
          <a href="https://keyfi.com/" target="_blank" rel="noreferrer">
            <WorldIcon /> {t("info.website")}
          </a>
          <a href="https://keyfi.com/whitepaper/" target="_blank" rel="noreferrer">
            <Whitepaper /> {t("info.whitepaper")}
          </a>
          <a href="https://docs.keyfi.com/" target="_blank" rel="noreferrer">
            <UserGuide /> {t("info.userGuide")}
          </a>
          <a href="https://keyfi.com/#faq" target="_blank" rel="noreferrer">
            <FAQ /> {t("info.FAQ")}
          </a>
        </div>
        <h4>{t("info.liveCharts")}</h4>
        <div className="info_links">
          <a
            href="https://v2.info.uniswap.org/token/0xb8647e90c0645152fccf4d9abb6b59eb4aa99052"
            target="_blank"
            rel="noreferrer"
          >
            <img src={UNI} alt="Uniswap KEYFI" /> Uniswap
          </a>
          <a
            href="https://pancakeswap.finance/info/token/0x4b6000f9163de2e3f0a01ec37e06e1469dbbce9d"
            target="_blank"
            rel="noreferrer"
          >
            <img src={CAKE} alt="ApeSwap KEYFI" /> PancakeSwap
          </a>

          <a
            href="https://info.apeswap.finance/token/0x4b6000f9163de2e3f0a01ec37e06e1469dbbce9d"
            target="_blank"
            rel="noreferrer"
          >
            <img src={APE} alt="ApeSwap KEYFI" /> ApeSwap
          </a>
          <a
            href="https://info.quickswap.exchange/#/token/0xd1a5f2a049343fc4d5f8d478f734eba51b22375e"
            target="_blank"
            rel="noreferrer"
          >
            <img src={QuickSwap} alt="QuickSwap" /> QuickSwap
          </a>
          <a href="https://nomics.com/assets/keyfi-keyfi-token-erc-20" target="_blank" rel="noreferrer">
            <NomicsETH /> KEYFI ERC-20
          </a>
          <a href="https://nomics.com/assets/keyfibep20-keyfi-token-bep-20" target="_blank" rel="noreferrer">
            <NomicsBSC /> KEYFI BEP-20
          </a>
        </div>
        <h4>{t("info.tokenDate")}</h4>
        <div className="info_links">
          <a href="https://coinmarketcap.com/currencies/keyfi" target="_blank" rel="noreferrer">
            <CoinMarketCap /> CoinMarketCap
          </a>
          <a
            href="https://info.quickswap.exchange/#/token/0xd1a5f2a049343fc4d5f8d478f734eba51b22375e"
            target="_blank"
            rel="noreferrer"
          >
            <img src={QuickSwap} alt="LunarCrush" /> QuickSwap
          </a>
          <a
            href="https://polygonscan.com/token/0xd1a5f2a049343fc4d5f8d478f734eba51b22375e"
            target="_blank"
            rel="noreferrer"
          >
            <img src={PolygonVector} alt="Polygon" /> Polygon
          </a>
          <a href="https://www.coingecko.com/en/coins/keyfi" target="_blank" rel="noreferrer">
            <CoinGecko /> Coingecko
          </a>
          {/* <a href="https://coinranking.com/coin/ZtmfWiAH0+keyfi-keyfi" target="_blank" rel="noreferrer">
            <Coinranking /> Coinranking
          </a> */}
          <a
            href="https://etherscan.io/token/0xb8647e90c0645152fccf4d9abb6b59eb4aa99052"
            target="_blank"
            rel="noreferrer"
          >
            <ETHScan />
            ERC20 Token ID
          </a>
          <a
            href="https://bscscan.com/token/0x4b6000f9163de2e3f0a01ec37e06e1469dbbce9d"
            target="_blank"
            rel="noreferrer"
          >
            <BSCScan />
            BEP20 Token ID
          </a>
        </div>
        <h4>{t("info.auditedBy")}</h4>
        <div className="info_links">
          <a
            href="https://keyfi.com/static/Keyfi_SC_Audit_Report-78d8894ee980f2a7036a7a0f7a593a4b.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <Hacken />
            Hacken
          </a>
        </div>
        <h4>News</h4>
        <div className="info_links">
          <a href="https://t.me/keyfi_official" target="_blank" rel="noreferrer">
            <img src={Telegram} alt="News" />
            News
          </a>
          <a href="https://keyfi-app.medium.com/" target="_blank" rel="noreferrer">
            <img src={Medium} alt="Medium" />
            Medium
          </a>
          <a href="https://cryptopanic.com/news/keyfi/" target="_blank" rel="noreferrer">
            <img src={CryptoPanic} alt="CryptoPanic" />
            CryptoPanic
          </a>
        </div>
        <h4>{t("info.community")}</h4>
        <div className="info_links">
          <a href="https://t.me/keyfi_ai" target="_blank" rel="noreferrer">
            <img src={Telegram} alt="Telegram" />
            Telegram
          </a>
          <a href="https://twitter.com/keyfi_app" target="_blank" rel="noreferrer">
            <img src={Twitter} alt="Twitter" />
            Twitter
          </a>
          <a href="https://www.instagram.com/keyfi.app/" target="_blank" rel="noreferrer">
            <img src={Instagram} alt="Instagram" />
            Instagram
          </a>
          <a href="https://github.com/KEYFIAI/" target="_blank" rel="noreferrer">
            <img src={Github} alt="Github" />
            Github
          </a>
          <a href="https://www.linkedin.com/company/keyfi-app" target="_blank" rel="noreferrer">
            <img src={LinkedIn} alt="LinkedIn" />
            LinkedIn
          </a>

          <a href="https://www.reddit.com/r/KeyFi_Official/" target="_blank" rel="noreferrer">
            <img src={Reddit} alt="Reddit" />
            Reddit
          </a>
          <a href="https://www.youtube.com/channel/UCt2BePcLH4aK5cOKbNDJjiA" target="_blank" rel="noreferrer">
            <img src={YouTube} alt="YouTube" />
            YouTube
          </a>
        </div>
      </InfoLinkContainer>
    </InfoWrapper>
  );
};

export default Info;
