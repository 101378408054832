import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";

// Assets
import { ReactComponent as Star } from "../../../assets/discovery/star.svg";
import { beautifyTokensAmount } from "../../../helpers";
import { dashboardWatchlistOperations } from "../../../redux/watchlistDashboardSlice";
import { watchlistOperations } from "../../../redux/watchlistSlice";
import { breakPoints } from "../../../Theme";
import Loader from "../../Loader";
import WidgetWrapper from "./WidgetWrapper";

const WatchlistMain = styled.table`
  border-collapse: collapse;
  font-size: 14px;
  thead {
    width: 100%;
    padding-right: 4px;
    tr {
      width: 100%;
      th {
        text-align: left;
        border-bottom: 1px solid ${(props) => props.theme.researchBorder};
        padding: 9px 0;
        color: ${(props) => props.theme.lightGrey};

        :first-of-type {
          padding-left: 0.75rem;
          width: 3rem;
        }
        :nth-child(2) {
          width: 8%;
          @media (max-width: ${breakPoints.lg}px) {
            width: 15%;
          }
        }
        :nth-child(3) {
          width: 30%;
          @media (max-width: ${breakPoints.lg}px) {
            width: 8%;
          }
        }
        @media (max-width: ${breakPoints.lg}px) {
          padding: 10px 0 3px;
          :first-of-type {
            padding-left: 5px;
            width: 10%;
          }
          :nth-child(2) {
            width: 15%;
          }
          :nth-child(3) {
            width: 8%;
          }
        }
      }
    }
  }
  tbody {
    overflow: auto;
    @media (max-width: ${breakPoints.lg}px) {
      height: 130px;
    }
    ::-webkit-scrollbar {
      width: 4px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.scroleThumb};
      border-radius: 10px;
    }
    tr {
      width: 100%;
      cursor: pointer;
      :nth-child(2n) {
        background: ${(props) => props.theme.darkGrey};
      }
      :hover {
        z-index: 2;
        background: #041c3f;
        /* Border inside */
        box-shadow: inset 0px 0px 0px 1px ${(props) => props.theme.brandBlue};
        border-radius: 6px;
      }

      td {
        padding: 9px 0;
        :first-of-type {
          padding-left: 0.75rem;
          width: 3rem;
        }
        :nth-child(2) {
          width: 8%;
        }
        :nth-child(3) {
          width: 30%;
        }
        .token_star {
          cursor: pointer;
        }
        .token_star_selected,
        .token_star_selected:hover {
          path {
            stroke: ${(props) => props.theme.binanceYellow};
            fill: ${(props) => props.theme.binanceYellow};
          }
        }
        .watchlist_token_title {
          display: flex;
          align-items: center;
          gap: 6px;
          img {
            height: 20px;
            width: 20px;
          }
        }
        @media (max-width: ${breakPoints.lg}px) {
          padding: 7px 0;
          :first-of-type {
            padding-left: 5px;
            width: 10%;
          }
          :nth-child(2) {
            width: 15%;
          }
          :nth-child(3) {
            width: 8%;
          }
        }
      }
      .research_widget_star {
        @media (max-width: ${breakPoints.lg}px) {
          height: 16px;
          width: 16px;
        }
      }
      .widget_star_wrapper {
        display: flex;
        align-items: center;
      }
    }
  }

  .research_widget_diff {
    color: ${(props) => props.theme.greenShade};
  }
  .research_widget_minus {
    color: ${(props) => props.theme.redMinus};
  }
`;

const WatchlistWidget = ({ setOpenModal, item }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const userData = useSelector((state) => state.userData);
  const { mainWatchlist } = userData.user;

  const dashboardWatchlist = useSelector((state) => state.dashboardWatchlist);
  const { activeWatchlist, isFetching } = dashboardWatchlist;

  useEffect(() => {
    if (!("assets" in activeWatchlist) && !isFetching) {
      dispatch(dashboardWatchlistOperations.getDashboardWatchlist(mainWatchlist.id));
    }
  }, [dispatch, mainWatchlist.id]);

  const inWatchlist = (tokenId) => {
    if (mainWatchlist.assets) {
      return mainWatchlist.assets.some((id) => id === tokenId);
    }
    return false;
  };

  const handleWatchlistToggle = (id) => {
    if (inWatchlist(id)) {
      return dispatch(watchlistOperations.removeAssetFromWatchlist(mainWatchlist.id, id, true));
    }
    return dispatch(watchlistOperations.addAssetToWatchlist(mainWatchlist.id, id, true));
  };

  return (
    <WidgetWrapper
      item={item}
      setOpenModal={setOpenModal}
      id="watchlist"
      header={
        <div className="widget_info">
          <Link to={`/watchlist/${mainWatchlist.id}`}>{mainWatchlist.title}</Link>
        </div>
      }
      body={
        !isFetching ? (
          <div className="widget_main" style={{ display: "flex", flexDirection: "column", overflowY: "auto" }}>
            <WatchlistMain>
              <thead>
                <tr>
                  <th />
                  <th>#</th>
                  <th>{t("common.token")}</th>
                  <th>{window.innerWidth > breakPoints.lg && t("common.symbol")}</th>
                  <th>{t("common.price")}</th>
                  <th>24h</th>
                </tr>
              </thead>
              <tbody>
                {!isFetching && activeWatchlist.assets?.length !== 0 ? (
                  activeWatchlist.assets?.map((item) => (
                    <tr key={item.coin_id} onClick={() => history.push(`/research/token/${item.coin_id}`)}>
                      <td>
                        <div className="widget_star_wrapper">
                          <Star
                            className={`token_star ${inWatchlist(item.coin_id) ? "token_star_selected" : ""}`}
                            onClick={() => handleWatchlistToggle(item.coin_id)}
                          />{" "}
                        </div>
                      </td>
                      <td>{item.market_cap_rank}</td>
                      <td>
                        <div className="watchlist_token_title">
                          <img src={item.icon} alt={item.name} /> {window.innerWidth > breakPoints.lg && item.name}
                        </div>
                      </td>
                      <td>{item.symbol.toUpperCase()}</td>
                      <td>{beautifyTokensAmount(item.current_price, { prefix: "$" })}</td>
                      <td
                        className={
                          item.price_change_percentage_24h_in_currency < 0
                            ? "research_widget_minus"
                            : "research_widget_diff"
                        }
                      >
                        {beautifyTokensAmount(item.price_change_percentage_24h_in_currency, { suffix: "%" })}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6">No tokens in your watchlist.</td>
                  </tr>
                )}
              </tbody>
            </WatchlistMain>
          </div>
        ) : (
          <Loader style={{ marginTop: "3rem" }} />
        )
      }
    />
  );
};

export default WatchlistWidget;
