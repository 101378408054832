import React from "react";

const NewListingAlert = () => {
  return (
    <div>
      <div>New listing</div>
    </div>
  );
};

export default NewListingAlert;
