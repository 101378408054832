import styled from "styled-components";

export const NotificationWrapper = styled.div`
  position: relative;
  overflow: hidden;
  z-index: 4;
  width: 290px;
  margin-bottom: 2rem;
  background: ${(props) => props.theme.blueTransparent};
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.brandBlue};
  transform: translateX(120%);
  animation: notificationIn 0.6s forwards ease-out;
  .notification_header {
    display: flex;
    align-items: center;
    margin-top: 0.75rem;
    margin-right: 1rem;
    gap: 0.75rem;
    .notification_logo {
      background: ${(props) => props.theme.bg};
      padding: 2px 0.75rem 2px 1rem;
      display: flex;
      align-items: center;
      border-radius: 0 6px 6px 0;
    }
    .notification_widget {
      flex: 1;
      > div {
        padding: 0.25rem 0.5rem;
        border-radius: 6px;
        margin-right: auto;
        width: max-content;
        font-size: 10px;
        text-transform: uppercase;
        color: white;
      }
      .notification_interest_rate_widget {
        background: ${(props) => props.theme.uniswapText};
      }
      .notification_gas_widget {
        background: ${(props) => props.theme.greenShade2};
      }
      .notification_volume_widget {
        background: ${(props) => props.theme.notificationPurple};
      }
      .notification_price_widget {
        background: ${(props) => props.theme.curveYellow};
        color: black;
      }
    }
    .notification_close {
      cursor: pointer;
      height: 20px;
      width: 20px;
      path {
        stroke: ${(props) => props.theme.mainDarkText};
      }
    }
  }
  .notification_main {
    padding: 1rem;
    font-size: 14px;
    .notification_main_header {
      display: flex;
      align-items: center;
      gap: 6px;
      h5 {
        font-size: 14px;
      }
      .notification_main_header_positive {
        color: ${(props) => props.theme.notificationGreen};
      }
      .notification_main_header_negative {
        color: ${(props) => props.theme.error};
      }
    }
    a {
      display: block;
      width: max-content;
      color: ${(props) => props.theme.form_text};
      font-size: 12px;
      margin: 0.5rem 0 1rem;
      text-decoration: underline;
    }
    .notification_trigger_time {
      font-size: 10px;
      color: ${(props) => props.theme.lightGrey};
    }
  }
  @keyframes notificationIn {
    0% {
      transform: translate(150%);
    }
    70% {
      transform: translate(-30px);
    }
    100% {
      transform: translate(0);
    }
  }
  @keyframes notificationOut {
    30% {
      transform: translate(-30px);
    }
    100% {
      transform: translate(150%);
    }
  }
`;
