import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const API = process.env.REACT_APP_PRO_API;

const initialState = {
  error: null,
  isLoading: false,
  editIsLoading: false,
  alertsList: [],
  editAlert: {},
};

const alerts = createSlice({
  name: "alerts",
  initialState,
  reducers: {
    setLoading(state, action) {
      return { ...state, isLoading: action.payload };
    },
    setEditIsLoading(state, action) {
      return { ...state, editIsLoading: action.payload };
    },
    setError(state, action) {
      return { ...state, error: action.payload };
    },
    setAlertsList(state, action) {
      return { ...state, alertsList: action.payload };
    },
    setEditAlert(state, action) {
      return { ...state, editAlert: action.payload };
    },
  },
});

export const alertsActions = alerts.actions;

const getRoot = (state) => state.alerts;

export const alertsSelectors = {
  getLoading: (state) => getRoot(state).isLoading,
  getError: (state) => getRoot(state).error,
  getAlertsList: (state) => getRoot(state).alertsList,
  getEditAlert: (state) => getRoot(state).editAlert,
};

export const alertsOperations = {
  getAlerts: () => async (dispatch, getState) => {
    const isLoading = alertsSelectors.getLoading(getState());
    if (isLoading) {
      return;
    }
    dispatch(alertsActions.setLoading(true));
    try {
      const url = `${API}/v1/alert/all`;

      const config = {
        headers: {
          Authorization: getState().userData.tokens.refresh.token,
        },
      };

      const { data } = await axios.get(url, config);
      dispatch(alertsActions.setAlertsList(data));
      dispatch(alertsActions.setLoading(false));
    } catch (err) {
      dispatch(alertsActions.setError(err.response.data.message || err.response.data));
      dispatch(alertsActions.setLoading(false));
    }
  },
  updateAlert: (id, alertData) => async (dispatch, getState) => {
    try {
      dispatch(alertsActions.setEditIsLoading(true));
      const token = getState().userData.tokens.access.token;
      let alertsList = [...getState().alerts.alertsList];

      const config = {
        headers: {
          Authorization: token,
        },
      };
      const { data } = await axios.patch(`${API}/v1/alert/${id}`, alertData, config);

      const foundIndex = alertsList.findIndex((x) => x._id === data._id);
      alertsList[foundIndex] = data;
      dispatch(alertsActions.setAlertsList(alertsList));
      dispatch(alertsActions.setEditIsLoading(false));
    } catch (err) {
      console.log(err);
      dispatch(alertsActions.setError(err.response.data.message || err.response.data));
      dispatch(alertsActions.setEditIsLoading(false));
    }
  },
  deleteAlert: (id) => async (dispatch, getState) => {
    try {
      dispatch(alertsActions.setEditIsLoading(true));

      const token = getState().userData.tokens.access.token;
      let alertsList = [...getState().alerts.alertsList];

      const config = {
        headers: {
          Authorization: token,
        },
      };
      await axios.delete(`${API}/v1/alert/${id}`, config);
      dispatch(alertsActions.setAlertsList(alertsList.filter((item) => item._id !== id)));
      dispatch(alertsActions.setEditIsLoading(false));
    } catch (err) {
      dispatch(alertsActions.setError(err.response.data.message || err.response.data));
      dispatch(alertsActions.setEditIsLoading(false));
    }
  },
  handleEditAlert: (name, value) => async (dispatch, getState) => {
    const editAlert = alertsSelectors.getEditAlert(getState());
    dispatch(alertsActions.setEditAlert({ ...editAlert, [name]: value }));
  },
};

export const alertsReducer = alerts.reducer;
