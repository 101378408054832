import React, { useEffect, useMemo, useState } from "react";

import { integrations } from "@keyfi/keyfi-common";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";

// Assets
import { ReactComponent as ArrowDown } from "../../assets/arrowDown.svg";
import { ReactComponent as MagnifyingGlass } from "../../assets/discovery/magnifyingGlass.svg";
import { beautifyTokensAmount, tokensLogos } from "../../helpers";
import Modal from "../Modal";

const SelectedOption = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  > img {
    height: 26px;
    width: 26px;
  }
  .selected_text {
    min-width: 35px;
  }
  .selected_arrow {
    width: 15px;
  }
`;

const StyledOptions = styled.div`
  color: ${(props) => props.theme.mainDarkText};
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  justify-content: flex-start;
  flex-direction: column;
  max-height: 350px;
  min-height: 350px;
  overflow-y: auto;
  z-index: 9999;
  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  span {
    margin-left: 5px;
  }
  svg,
  img {
    width: 32px !important;
    height: 32px !important;
  }
`;

const StyledOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  height: 56px;
  cursor: pointer;
  color: ${(props) => props.theme.default_color};
  font-size: 16px;
  line-height: 20px;

  .token-info {
    display: flex;
    align-items: center;
  }
  :hover {
    background: ${(props) => props.theme.bg};
  }

  border-top: 1px solid ${(props) => props.theme.form_text3};
`;

const OptionsWrapper = styled.div`
  min-height: 400px;

  .input-wrapper {
    margin-top: 32px;
    margin-bottom: 24px;
    background: ${(props) => props.theme.bg};
    display: flex;
    padding: 0.75rem;
    gap: 1rem;
    border-radius: 6px;
    border: 1px solid ${(props) => props.theme.researchBorder};
    input {
      flex: 1;
      ::placeholder {
        color: ${(props) => props.theme.placeholderText};
      }
    }
  }
`;

const defaultOptions = [
  { value: "ETH", icon: tokensLogos["ETH"], text: "ETH" },
  { value: "USDT", icon: tokensLogos["USDT"], text: "USDT" },
  { value: "DAI", icon: tokensLogos["DAI"], text: "DAI" },
  { value: "BUSD", icon: tokensLogos["BUSD"], text: "BUSD" },
  { value: "USDC", icon: tokensLogos["USDC"], text: "USDC" },
];

const DepositDropdown = ({ selected, network, platform, version, name, onSelect, userTokens, userPlatformData }) => {
  const [options, setOptions] = useState(defaultOptions);
  const [selectedOption, setSelectedOption] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();

  const [searchQuery, setSearchQuery] = useState("");

  const user = useSelector((state) => state.user);
  const wallet = useSelector((state) => state.wallet);
  const staking = useSelector((state) => state.staking);

  useEffect(() => {
    const getSupportedTokens = async () => {
      try {
        if (integrations[platform] && wallet.network.name) {
          const mainFunction = version !== "v1" ? integrations[platform] : integrations[platform][version];
          const tokens = await mainFunction.getSupportedAssets();
          let newOptions = tokens.map((option) => ({
            value: option,
            icon: tokensLogos[option] || <h2 style={{ textAlign: "center", width: "32px" }}>?</h2>,
            text: option,
          }));
          setOptions(newOptions);
        }
      } catch (err) {
        console.log(err);
      }
    };
    if (platform) {
      getSupportedTokens();
    }
  }, [platform, wallet.network.name, version]);

  useEffect(() => {
    if (selected) {
      let defaultSelected = options.find((option) => {
        return option.value === selected;
      });
      setSelectedOption(defaultSelected);
    }
  }, [selected, options]);

  const handleSelect = (token) => {
    const selectedToken = options.find((option) => option.value === token);
    setSelectedOption(selectedToken);
    setOpenModal(false);
    onSelect(selectedToken.value);
  };

  const filterdTokens = useMemo(() => {
    const optionsArray = options.filter((option) =>
      option.value.toLowerCase().includes(String(searchQuery).toLowerCase())
    );

    return optionsArray.sort((a, b) => {
      if (user && userPlatformData) {
        const aValue = beautifyTokensAmount(
          name === "selectwithdraw" ? userPlatformData[a.value] : userTokens[a.value]
        );
        const bValue = beautifyTokensAmount(
          name === "selectwithdraw" ? userPlatformData[b.value] : userTokens[b.value]
        );

        return parseFloat(bValue) - parseFloat(aValue);
      }
      return 0;
    });
  }, [searchQuery, options, user, name, platform, version, network, staking]);

  return (
    <>
      <SelectedOption onClick={() => setOpenModal(true)}>
        {selectedOption ? (
          <>
            {selectedOption.icon}
            <span className="selected_text"> {selectedOption.text}</span>
            <ArrowDown className="selected_arrow" />
          </>
        ) : (
          <>
            <span className="selected__choose">{t("common.choose")}</span>
            <ArrowDown className="selected_arrow" />
          </>
        )}
      </SelectedOption>
      {openModal && (
        <Modal title="Choose a token" maxwidth="480px" handleCloseModal={() => setOpenModal(false)}>
          <OptionsWrapper>
            <div className="input-wrapper">
              <input
                className="search"
                type="text"
                placeholder={t("common.searchTokenName")}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <MagnifyingGlass />
            </div>
            {filterdTokens.length ? (
              <StyledOptions>
                {filterdTokens
                  .sort((a, b) => {
                    const aValue =
                      name === "selectwithdraw" ? userPlatformData[a.value] ?? 0 : userTokens[a.value] ?? 0;
                    const bValue =
                      name === "selectwithdraw" ? userPlatformData[b.value] ?? 0 : userTokens[b.value] ?? 0;
                    return bValue - aValue;
                  })
                  .map((option) => (
                    <StyledOption key={option.text} onClick={() => handleSelect(option.value)}>
                      <div className="token-info">
                        {option.icon}
                        <span>{option.value}</span>
                      </div>

                      <span className="token-value">
                        {name === "selectwithdraw"
                          ? beautifyTokensAmount(userPlatformData[option.value] ?? 0)
                          : beautifyTokensAmount(userTokens[option.value] ?? 0)}
                      </span>
                    </StyledOption>
                  ))}
              </StyledOptions>
            ) : (
              t("common.noResultFound")
            )}
          </OptionsWrapper>
        </Modal>
      )}
    </>
  );
};

export default DepositDropdown;
