import { integrations } from "@keyfi/keyfi-common";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  gasData: {},
};

const gas = createSlice({
  name: "gas",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setGasData(state, action) {
      state.gasData = action.payload;
    },
  },
});

export const gasActions = gas.actions;

const getRoot = (state) => state.gas;

export const gasSelectors = {
  getLoading: (state) => getRoot(state).isLoading,
  getGasData: (state) => getRoot(state).gasData,
};

export const gasOperations = {
  getGasData: () => async (dispatch, getState) => {
    const web3 = getState().wallet.web3;
    if (!web3) return;
    const data = await integrations.getFees(web3, 50);

    dispatch(gasActions.setGasData(data));
  },
};

export const gasReducer = gas.reducer;
