const INITIAL_STATE = {
  Subscription: "",
  isFetching: false,
  error: null,
};

function billingReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "FETCH_TOKEN_REQUEST":
      return Object.assign({}, state, {
        isFetching: true,
      });
    case "FETCH_PRICE_SUCCESS":
      return Object.assign(state, {
        isFetching: false,
        Subscription: action.subscription,
      });
    default:
      return state;
  }
}

export default billingReducer;
