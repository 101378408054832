import React from "react";

import moment from "moment";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { ReactComponent as Down } from "../../../assets/alerts/smallDown.svg";
import { ReactComponent as Up } from "../../../assets/alerts/smallUp.svg";
import { ReactComponent as CloseIcon } from "../../../assets/closeModal.svg";
import { ReactComponent as KeyFi } from "../../../assets/notifications/keyfi_logo.svg";
import { beautifyTokensAmount } from "../../../helpers";
import { notificationsOperations } from "../../../redux/notificationsSlice";
import { NotificationWrapper } from "../Notification.styles";

const notificationIconHandler = (alert) => {
  if (alert.type === "gas") {
    return <Down />;
  }
  if (alert.type === "price" || alert.type === "volume" || alert.type === "interestRate") {
    if (alert.conditional === ">") {
      return <Up />;
    } else {
      return <Down />;
    }
  }
};

const notificationTitleHandler = (conditional, volume) => {
  if (!volume) {
    if (conditional === ">") return "Up to ";
    return "Down to ";
  }
  if (conditional === ">") return "Up by ";
  return "Down by ";
};

const notificationGasHandler = (network) => {
  if (network === "ethereum") return "ETH Gas";
  if (network === "bsc") return "BSC Gas";
  return "Polygon Gas";
};

const InterestRateNotification = ({ item }) => {
  const dispatch = useDispatch();

  const handleRemoveNotification = (id) => dispatch(notificationsOperations.removeNotification(id));

  if (item.type === "interestRate") {
    return (
      <NotificationWrapper>
        <div className="notification_header">
          <div className="notification_logo">
            <KeyFi />
          </div>
          <div className="notification_widget">
            <div className="notification_interest_rate_widget">Interest rate</div>
          </div>
          <CloseIcon onClick={() => handleRemoveNotification(item._id)} className="notification_close" />
        </div>
        <div className="notification_main">
          <div className="notification_main_header">
            {notificationIconHandler(item)}
            <h5>{item.asset} APY</h5>
            <span
              className={
                item.conditional === ">" ? "notification_main_header_positive" : "notification_main_header_negative"
              }
            >
              {notificationTitleHandler(item.conditional)} {beautifyTokensAmount(item.value, { suffix: "%" })}
            </span>
          </div>
          {item.notes && <p>{item.notes}</p>}
          <Link to="/alerts">Go to alerts</Link>
          <div className="notification_trigger_time">
            Trigger time: {moment(item.triggeredAt).format("DD MMM YYYY HH:mm")}
          </div>
        </div>
      </NotificationWrapper>
    );
  }
  if (item.type === "gas") {
    return (
      <NotificationWrapper>
        <div className="notification_header">
          <div className="notification_logo">
            <KeyFi />
          </div>
          <div className="notification_widget">
            <div className="notification_gas_widget">Gas</div>
          </div>
          <CloseIcon onClick={() => handleRemoveNotification(item._id)} className="notification_close" />
        </div>
        <div className="notification_main">
          <div className="notification_main_header">
            {notificationIconHandler(item)}
            <h5>{notificationGasHandler(item.network)}</h5>
            <span className="notification_main_header_positive">
              {notificationTitleHandler("<")} {beautifyTokensAmount(item.value, { suffix: " Gwei" })}
            </span>
          </div>
          {item.notes && <p>{item.notes}</p>}
          <Link to="/alerts">Go to alerts</Link>
          <div className="notification_trigger_time">
            Trigger time: {moment(item.triggeredAt).format("DD MMM YYYY HH:mm")}
          </div>
        </div>
      </NotificationWrapper>
    );
  }
  if (item.type === "price") {
    return (
      <NotificationWrapper>
        <div className="notification_header">
          <div className="notification_logo">
            <KeyFi />
          </div>
          <div className="notification_widget">
            <div className="notification_price_widget">Price</div>
          </div>
          <CloseIcon onClick={() => handleRemoveNotification(item._id)} className="notification_close" />
        </div>
        <div className="notification_main">
          <div className="notification_main_header">
            {notificationIconHandler(item)}
            <h5 style={{ textTransform: "capitalize" }}>{item.asset}</h5>
            <span
              className={
                item.conditional === ">" ? "notification_main_header_positive" : "notification_main_header_negative"
              }
            >
              {notificationTitleHandler(item.conditional)} {beautifyTokensAmount(item.value, { prefix: "$" })}
            </span>
          </div>
          {item.notes && <p>{item.notes}</p>}
          <Link to="/alerts">Go to alerts</Link>
          <div className="notification_trigger_time">
            Trigger time: {moment(item.triggeredAt).format("DD MMM YYYY HH:mm")}
          </div>
        </div>
      </NotificationWrapper>
    );
  }
  if (item.type === "volume") {
    return (
      <NotificationWrapper>
        <div className="notification_header">
          <div className="notification_logo">
            <KeyFi />
          </div>
          <div className="notification_widget">
            <div className="notification_volume_widget">Volume</div>
          </div>
          <CloseIcon onClick={() => handleRemoveNotification(item._id)} className="notification_close" />
        </div>
        <div className="notification_main">
          <div className="notification_main_header">
            {notificationIconHandler(item)}
            <h5 style={{ textTransform: "capitalize" }}>{item.asset}</h5>
            <span
              className={
                item.conditional === ">" ? "notification_main_header_positive" : "notification_main_header_negative"
              }
            >
              {notificationTitleHandler(item.conditional, true)} {beautifyTokensAmount(item.value, { suffix: "%" })}
            </span>
          </div>
          {item.notes && <p>{item.notes}</p>}
          <Link to="/alerts">Go to alerts</Link>
          <div className="notification_trigger_time">
            Trigger time: {moment(item.triggeredAt).format("DD MMM YYYY HH:mm")}
          </div>
        </div>
      </NotificationWrapper>
    );
  }
  return null;
};

export default InterestRateNotification;
