import styled, { css } from "styled-components";

import { breakPoints } from "../../../Theme";
import Button from "../../Button";

export const Wrapper = styled.div`
  height: 100%;
  min-height: 200px;

  cursor: pointer;
  background: ${(props) => props.theme.widgetCardBg};
  border-radius: 6px;
  border: 1px dashed ${(props) => props.theme.researchBorder};
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    background: ${(props) => props.theme.sidebar_bg};
  }
  .addwidget_inside {
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      margin: 0 0 1rem 0;
    }
    h4 {
      margin: 0 0 0.75rem 0;
    }
  }
`;

export const WidgetModal = styled.div`
  margin-top: 1.5rem;
  color: ${(props) => props.theme.default_color};
  h3 {
    margin: 0 0 1.5rem;
  }
  .widget_modal_cards {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    .widget_modal_card {
      background: ${(props) => props.theme.bg};
      border-radius: 6px;
      border: 1px solid ${(props) => props.theme.researchBorder};
      height: max-content;
      .widget_modal_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.75rem;
      }
    }
  }
  .widget_modal_buttons {
    margin: 2.5rem 0 1rem;
    display: flex;
    gap: 1rem;
  }
`;

export const WidgetSaveButton = styled(Button)`
  font-size: 16px;
  text-transform: capitalize;
  min-width: 160px;
  padding: 10px 0;
  line-height: 19.5px;
  height: max-content;
  @media (max-width: ${breakPoints.lg}px) {
    min-width: 0;
    width: 100%;
  }
  ${(props) =>
    props.noColor &&
    css`
      background: none;
      :hover,
      :active,
      :focus {
        background: none;
      }
    `}
`;

export const ToggleButtonWrapper = styled.label`
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
    :checked + .slider:before {
      background: ${(props) => props.theme.toggleOn};
      transform: translateX(20px);
      transition: 0.4s ease;
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.bg};
    border: 1px solid ${(props) => props.theme.researchBorder};
    :before {
      position: absolute;
      content: "";
      height: 18px;
      width: 18px;
      left: 2px;
      bottom: 2px;
      background: ${(props) => props.theme.lightGrey};
      transition: 0.4s ease;
    }
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
    :before {
      border-radius: 50%;
    }
  }
`;

export const ChartBodyWrapper = styled.div`
  margin: 0 0.75rem;
  border-top: 1px solid ${(props) => props.theme.researchBorder};
  padding: 0.75rem 0 0.5rem;
  .chart_body_input_container {
    display: flex;
    padding: 0.5rem;
    background: ${(props) => props.theme.blueTransparent};
    border-radius: 6px;
    border: 1px solid ${(props) => props.theme.brandBlue};
    align-items: center;
    input {
      flex: 1;
      font-size: 14px;
    }
    > svg {
      height: 16px;
      width: 16px;
    }
  }
  .chart_body_list {
    margin: 6px 0;
  }
  .chart_body_list_item {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border-radius: 6px;
    gap: 6px;
    cursor: pointer;
    :hover {
      background: ${(props) => props.theme.secondaryBg};
    }
    img {
      height: 16px;
      width: 16px;
      border-radius: 50%;
    }
    h5 {
      color: ${(props) => props.theme.lightGrey};
      align-self: flex-end;
    }
  }
  .chart_body_input_selected {
    flex: 1;
    padding: 0;
    :hover {
      background: 0;
    }
  }
`;
