import React, { useEffect, useMemo, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Plus from "../../../assets/dashboard/PlusIcon.svg";
import { ReactComponent as MagnifyingGlass } from "../../../assets/discovery/magnifyingGlass.svg";
import { ToggleButtonWrapper } from "../../../Pages/Alerts/Alerts.styles";
import { chartWidgetOperations } from "../../../redux/Slices/chartWidgetSlice";
import { userDataOperations } from "../../../redux/userDataSlice";
import Modal from "../../Modal";
import { ChartBodyWrapper, WidgetModal, WidgetSaveButton, Wrapper } from "./AddWidgetCard.styles";

const getWidgetList = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.userData.user);
  const { dashboardWidgets } = user;
  const widgets = [
    {
      id: "alerts",
      title: t("dashboard.widget.myAlerts"),
    },
    {
      id: "research",
      title: t("dashboard.widget.research"),
    },
    {
      id: "news",
      title: t("dashboard.widget.news"),
    },
    {
      id: "watchlist",
      title: t("dashboard.widget.watchlist"),
    },
    {
      id: "interest",
      title: t("dashboard.widget.interestRate"),
    },
    {
      id: "sentiment",
      title: "Sentiment Index",
    },
    {
      id: "chart",
      title: "Chart",
      multiple: true,
      body: (id) => <ChartBody id={id} />,
    },
  ];
  return widgets.reduce((acc, item) => {
    if (item.id === "chart") {
      const allChartWidgets = dashboardWidgets.filter((item) => item.id === "chart");

      if (allChartWidgets.length === 0) {
        return [...acc, item];
      }
      const array = allChartWidgets.map((wid) => ({ ...item, uid: wid._id }));
      return [...acc, ...array, item];
    }
    return [...acc, item];
  }, []);
};

const ChartBody = ({ id }) => {
  const refSearch = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.userData);
  const { user } = userData;
  const chartWidgetData = useSelector((state) => state.chartWidget);
  const { searchList } = chartWidgetData;

  const dashboardWidgets = [...user.dashboardWidgets];
  const chartWidgetIndex = dashboardWidgets.findIndex((widget) => (id ? widget._id === id : widget.id === "chart"));
  const chartData = dashboardWidgets[chartWidgetIndex];

  useEffect(() => {
    if (!searchList) {
      dispatch(chartWidgetOperations.getSearchList());
    }
  }, [searchList]);

  useEffect(() => {
    if (!chartData.options) {
      setShowSearch(true);
    }
  }, [chartData]);

  const focusSearchInput = () => {
    setShowSearch(true);
  };

  const searchListData = useMemo(
    () =>
      searchList
        ? searchList
            .slice()
            .filter((item) => {
              const sq = searchQuery.toLowerCase();
              if (sq.length === 0) return true;

              const name = item.name.toLowerCase();
              const symbol = item.symbol.toLowerCase();
              return name.includes(sq) || symbol.includes(sq);
            })
            .sort((a, b) => {
              const sq = searchQuery.toLowerCase();

              if (sq.length === 0) {
                if (a.coin_id === "keyfi") return -1;
                if (b.coin_id === "keyfi") return 1;
                return 0;
              }

              if (!a.market_cap_rank && !b.market_cap_rank) return 0;
              if (!a.market_cap_rank) return 1;
              if (!b.market_cap_rank) return -1;

              return a.market_cap_rank - b.market_cap_rank;
            })
            .slice(0, 3)
        : [],
    [searchList, searchQuery]
  );

  const handleSelect = (item) => {
    setShowSearch(false);
    dashboardWidgets[chartWidgetIndex] = { ...dashboardWidgets[chartWidgetIndex], options: { token: item } };
    dispatch(userDataOperations.updateDashboardWidgets(dashboardWidgets));
  };

  return (
    <ChartBodyWrapper>
      <div className="chart_body_input_container">
        <input
          style={{ display: !chartData.options || showSearch ? "block" : "none" }}
          ref={refSearch}
          autoFocus
          onChange={(e) => setSearchQuery(e.target.value)}
          value={searchQuery}
        />
        {chartData.options && chartData.options.token && (
          <div
            className="chart_body_list_item chart_body_input_selected"
            style={{ display: !showSearch && chartData.options ? "flex" : "none" }}
            onClick={focusSearchInput}
            key={chartData.options.token.coin_id + chartData.options.token.name + Math.random()}
          >
            <img src={chartData.options.token.icon} alt={chartData.options.token.name} />
            <h4>{chartData.options.token.name}</h4>
            <h5>{chartData.options.token.symbol.toUpperCase()}</h5>
          </div>
        )}

        <MagnifyingGlass />
      </div>
      <div className="chart_body_list">
        {showSearch &&
          searchListData.length !== 0 &&
          showSearch &&
          searchListData.map((item) => (
            <div
              className="chart_body_list_item"
              onClick={() => handleSelect(item)}
              key={item.coin_id + item.name + Math.random()}
            >
              <img src={item.icon} alt={item.name} />
              <h4>{item.name}</h4>
              <h5>{item.symbol.toUpperCase()}</h5>
            </div>
          ))}
      </div>
    </ChartBodyWrapper>
  );
};

const ToggleButton = ({ name, className, checked, onClick }) => (
  <ToggleButtonWrapper className={className}>
    <input name={name} type="checkbox" checked={checked} onChange={onClick} />
    <span className="slider round"></span>
  </ToggleButtonWrapper>
);

const AddWidgetCard = ({ widgetData, openModal, setOpenModal }) => {
  const dispatch = useDispatch();
  const checkIfEnabled = (tokenId) => {
    return widgetData.some((widget) => widget.id === tokenId || widget._id === tokenId);
  };

  const handleChange = (e) => {
    if (e.target.checked) {
      dispatch(userDataOperations.addDashboardWidget(e.target.name));
    } else {
      const data = widgetData.filter((item) => item._id !== e.target.name);
      if (data.length === widgetData.length) {
        dispatch(userDataOperations.removeDashboardWidget(e.target.name));
        return;
      }
      dispatch(userDataOperations.updateDashboardWidgets(data));
    }
  };
  const { t } = useTranslation();
  const widgets = getWidgetList();

  return (
    <Wrapper onClick={() => setOpenModal(true)}>
      <div className="addwidget_inside">
        <h3>{t("dashboard.widget.customizeYourDashboard")}</h3>
        <h4>{t("dashboard.widget.addMoreWidgets")}</h4>
        <img src={Plus} alt="Add widget" />
      </div>
      <Modal
        open={openModal}
        handleCloseModal={() => setOpenModal(false)}
        title={t("dashboard.widget.customizeYourDashboard")}
        width="100%"
        maxwidth="740px"
      >
        <WidgetModal>
          <div className="widget_modal_cards">
            {widgets.map((item) => (
              <div key={item.uid || item.id} className="widget_modal_card">
                <div className="widget_modal_header">
                  <div>{item.title}</div>
                  <ToggleButton
                    onDisable
                    name={item.uid || item.id}
                    className="widget_modal_toggle"
                    checked={checkIfEnabled(item.multiple ? item.uid : item.id)}
                    onClick={handleChange}
                  />
                </div>
                {item.body && checkIfEnabled(item.multiple ? item.uid : item.id) && item.body(item.uid)}
              </div>
            ))}
          </div>

          <div className="widget_modal_buttons">
            <WidgetSaveButton primary onClick={() => setOpenModal(false)}>
              {t("dashboard.widget.save")}
            </WidgetSaveButton>

            <WidgetSaveButton primary noColor onClick={() => setOpenModal(false)}>
              {t("dashboard.widget.cancel")}
            </WidgetSaveButton>
          </div>
        </WidgetModal>
      </Modal>
    </Wrapper>
  );
};

export default AddWidgetCard;
