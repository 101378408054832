import styled from "styled-components";

import { breakPoints } from "../../Theme";

export const TableViewWrapper = styled.table`
  margin: 1rem 0;
  border-collapse: collapse;
  width: 100%;
  font-size: 14px;
  @media (max-width: ${breakPoints.md}px) {
    display: ${(props) => props.noMobile && "none"};
  }
  thead {
    tr {
      margin-bottom: 3px;
    }
  }
  tbody {
    tr {
      :nth-child(2n) {
        background: ${(props) => props.theme.darkGrey};
      }
      :hover {
        z-index: 2;
        background: #041c3f;
        /* Border inside */
        box-shadow: inset 0px 0px 0px 1px ${(props) => props.theme.brandBlue};
        border-radius: 6px;
      }
    }
    :before {
      content: "";
      display: block;
      line-height: 2px;
      color: transparent;
    }
  }
`;

export const TableHeadCell = styled.th`
  padding: 0 0.25rem 0.5rem;
  border-bottom: 1px solid ${(props) => props.theme.researchBorder};
  color: ${(props) => props.theme.form_text};
  text-align: left;
  :last-of-type {
    > div {
      justify-content: flex-end;
      padding-right: 1rem;
    }
  }
  > div {
    cursor: pointer;
    display: flex;
    align-items: center;
    .discovery_table_sort {
      display: flex;
      flex-direction: column;
      margin-left: 6px;
      svg {
        height: 7px;
        width: 9px;
      }
      .discovery_table_sort_selected {
        path {
          fill: ${(props) => props.theme.form_text};
        }
      }
    }
  }
`;

export const TableViewCell = styled.td`
  padding: 1rem 0.25rem;
  padding-left: ${(props) => props.paddingLeft};
  padding-right: ${(props) => props.paddingLeft};
  width: ${(props) => props.width};
  max-width: ${(props) => props.maxWidth};
  cursor: ${(props) => !props.noLink && "pointer"};
  :last-of-type {
    > div {
      justify-content: flex-end;
      padding-right: 1rem;
    }
  }
  > div {
    display: flex;
    align-items: center;
    img {
      height: 20px;
      width: 20px;
      object-fit: contain;
      margin-right: 6px;
    }
    .token_star {
      cursor: pointer;
      border: 0;
      outline: 0;
      margin-right: 0.75rem;
      path {
        fill: none;
      }
      :hover {
        path {
          fill: #3a4c61;
        }
      }
    }
    .token_star_selected,
    .token_star_selected:hover {
      path {
        stroke: ${(props) => props.theme.binanceYellow};
        fill: ${(props) => props.theme.binanceYellow};
      }
    }
  }
  .discovery_table_positive {
    color: ${(props) => props.theme.greenShade2};
  }
  .discovery_table_negative {
    color: ${(props) => props.theme.redMinus};
  }
  .discovery_table_neutral {
    color: ${(props) => props.theme.mainDarkText};
  }
`;

export const TableViewMobile = styled.div`
  display: none;
  @media (max-width: ${breakPoints.md}px) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1rem 0;
  }
  .discovery_mobile_card {
    background: ${(props) => props.theme.secondaryBg};
    border: 1px solid ${(props) => props.theme.researchBorder};
    margin-bottom: 1.5rem;
    padding: 1rem;
    border-radius: 6px;
    font-size: 14px;
    .discovery_mobile_card_line {
      display: flex;
      padding: 10px 0;
      border-bottom: 1px solid ${(props) => props.theme.researchBorder};
      :first-of-type {
        padding-top: 0;
      }
      :last-of-type {
        padding-bottom: 0;
        border-bottom: 0;
      }
      .discovery_mobile_card_title {
        flex: 1;
        color: ${(props) => props.theme.form_text};
      }
      .discovery_mobile_card_value {
        flex: 1;
        display: flex;
        align-items: center;
        img {
          height: 20px;
          width: 20px;
          object-fit: contain;
          margin-right: 6px;
        }
      }
      .discovery_table_positive {
        color: ${(props) => props.theme.greenShade2};
      }
      .discovery_table_negative {
        color: ${(props) => props.theme.redMinus};
      }
      .discovery_table_neutral {
        color: ${(props) => props.theme.mainDarkText};
      }
    }
  }
`;
