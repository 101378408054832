import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

// Assets
import { ReactComponent as Check } from "../../assets/check2.svg";
import { ReactComponent as Close2 } from "../../assets/popicon/Closeicon.svg";
import { ReactComponent as Warning } from "../../assets/warning.svg";

const Wrapper = styled.div`
  background: ${(props) => props.theme.infoBackground};
  max-width: 320px;
  padding: 0.75rem 1rem;
  border-radius: 6px;
  color: ${(props) => props.theme.default_color};
  font-size: 12px;
  ${(props) =>
    props.margin &&
    css`
      margin: 1rem 0 0;
    `}
  h4 {
    display: flex;
    align-items: center;
    svg {
      margin-right: 0.5rem;
    }
  }
  .user_passwords_list_section {
    margin-top: 0.5rem;
    > div {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      svg {
        max-width: 12px;
        height: 9px;
        margin-right: 1rem;
        transform: translateX(4px);
      }
      .user_password_success {
        path {
          fill: ${(props) => props.theme.greenShade2};
        }
      }
      .user_password_error {
        path {
          stroke: ${(props) => props.theme.error};
        }
      }
    }
  }
`;

const PasswordChecklist = ({ password, margin, setApprove }) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (setApprove) {
      if (
        password &&
        password.match(/\d/) &&
        password.match(/[a-zA-Z]/) &&
        password.match(/[A-Z]/) &&
        password.match(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/)
      ) {
        setApprove(true);
      } else {
        setApprove(false);
      }
    }
  }, [password, setApprove]);

  return (
    <Wrapper margin={margin}>
      <h4>
        <Warning /> {t("auth.setUpStrogPassword")}
      </h4>
      <div className="user_passwords_list_section">
        <div>
          {password.length > 7 ? (
            <Check className="user_password_success" />
          ) : (
            <Close2 className="user_password_error" />
          )}
          <div>{t("auth.atLeast8chars")}</div>
        </div>
        <div>
          {password && password.match(/\d/) && password.match(/[a-zA-Z]/) ? (
            <Check className="user_password_success" />
          ) : (
            <Close2 className="user_password_error" />
          )}
          <div>{t("auth.atLeast1letterDigit")}</div>
        </div>
        <div>
          {password && password.match(/[A-Z]/) ? (
            <Check className="user_password_success" />
          ) : (
            <Close2 className="user_password_error" />
          )}
          <div>{t("auth.atLeast1Upper")}</div>
        </div>
        {/* eslint-disable-next-line */}
        <div>
          {password && password.match(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/) ? (
            <Check className="user_password_success" />
          ) : (
            <Close2 className="user_password_error" />
          )}
          <div>{t("auth.atLeast1Special")}</div>
        </div>
      </div>
    </Wrapper>
  );
};

export default PasswordChecklist;
