import styled, { css } from "styled-components";

import { breakPoints } from "../../Theme";
import Button from "../Button";

export const SidebarV2Wrapper = styled.div`
  z-index: 2;
  background: ${(props) => props.theme.secondaryBg};
  max-width: ${(props) => (props.sidebarMinimized ? 60 : 200)}px;
  min-width: ${(props) => (props.sidebarMinimized ? 60 : 200)}px;
  width: 100%;
  height: 100%;
  padding: 2rem 0;
  display: flex;
  position: fixed;
  align-items: stretch;
  top: 0;
  left: 0;
  bottom: 0;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: ${breakPoints.md}px) {
    height: 100vh;
    overflow: auto;
    max-width: 300px;
    padding: 1rem 0;
    right: 0;
    left: auto;
    transform: ${(props) => (props.showSidebar ? "translateX(0)" : "translateX(100%)")};
    transition: all 0.3s ease-out;
  }
  .close-icon {
    cursor: pointer;
    margin: 0 auto;
  }
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  ${(props) =>
    props.sidebarMinimized &&
    css`
      .close-icon {
        display: flex;
        justify-content: center;
        img {
          transform: scale(-1);
        }
      }
      ${SidebarHeader} {
        padding: 0;
        margin-top: 1.25rem;
        width: 100%;
        justify-content: center;
        img {
          margin: 0;
        }
      }
      ${SidebarNavItem} {
        padding: 10px;
        justify-content: center;
        svg {
          margin: 0;
        }
      }
    `}
`;

export const SidebarBackground = styled.div`
  display: ${(props) => (props.showBackground ? "block" : "none")};
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100vw;
  z-index: 1;
  background-color: black;
  animation: bgFade 0.3s forwards;
  @keyframes bgFade {
    from {
      display: none;
      opacity: 0;
    }
    to {
      opacity: 0.6;
    }
  }
`;

export const SidebarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 6px 0 1.25rem;
  .pro_logo {
    margin-left: 43%;
    /* margin-top: -1rem; */
  }
  img {
    margin-right: 8px;
  }
  .close-icon {
    cursor: pointer;
    margin-right: 0;
  }

  @media (max-width: ${breakPoints.md}px) {
    width: 100%;
    padding: 0 1.25rem;
  }
`;

export const BlockchainMobileInfo = styled.div`
  display: flex;
  width: 100%;
  margin-top: 1rem;
  > div {
    display: flex;
    flex: 1;
    padding: 0.75rem 1rem;
    margin-bottom: 0.75rem;
    background: ${(props) => props.theme.form_content};
    color: ${(props) => props.theme.default_color};
    font-size: 14px;
    align-items: center;
    svg {
      margin-right: 10px;
      path {
        fill: ${(props) => props.theme.default_color};
      }
    }
    :first-of-type {
      border-radius: 0 6px 6px 0;
      margin-right: 10px;
    }
    :last-of-type {
      border-radius: 6px 0 0 6px;
    }
  }
`;

export const ConnectWalletButton = styled(Button)`
  height: initial;
  margin: 1rem auto;
  padding: 0.5em;
  width: 90%;
`;

export const SidebarNavItem = styled.div`
  max-height: 54px;
  padding: 10px 1.25rem;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.mainDarkText};
  svg,
  img {
    margin-right: 0.75rem;
  }
  :hover {
    background: ${(props) => props.theme.bg};
  }
`;

export const SidebarNavigation = styled.nav`
  flex: 1;
  margin: 1rem 0;
  overflow: hidden;
  position: relative;
  .sidebar_nav {
    height: 100%;
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }

    .active {
      > ${SidebarNavItem} {
        position: relative;
        background: ${(props) => props.theme.focused_color} !important;
        ::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 8px;
          background: ${(props) => props.theme.border2} !important;
        }
      }
    }
  }
  .sidebar_nav_shadow {
    display: none;
    @media (max-width: ${breakPoints.md}px) {
      display: block;
      position: absolute;
      bottom: -5px;
      z-index: 10;
      left: 0;
      height: 43px;
      width: 100%;
      background: linear-gradient(180deg, rgba(24, 35, 49, 0) 0%, #182331 100%);
    }
  }
  .shadow_top {
    bottom: auto;
    top: -5px;
    background: linear-gradient(0deg, rgba(24, 35, 49, 0) 0%, #182331 100%);
  }
  .no_shadow_top {
    .shadow_top {
      display: none !important;
    }
  }
  .no_shadow_bottom {
    .shadow_bottom {
      display: none !important;
    }
  }

  @media (max-width: ${breakPoints.md}px) {
    margin-bottom: 3rem;
  }
`;

export const SidebarNavAccordionWrapper = styled.div`
  margin-bottom: 4px;
  .sidebar_acc_main {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px 1.25rem;
    :hover {
      background: ${(props) => props.theme.bg};
    }
    .sidebar_acc_main_icon {
      margin-right: 0.75rem;
    }
    .sidebar_acc_main_arrow {
      height: 16px;
      width: 16px;
      margin-left: auto;
    }
  }
  .sidebar_acc_list {
    transition: all 0.3s;
    .sidebar_acc_item {
      background: ${(props) => props.theme.sidebarAccBg};
      margin-bottom: 0px;
      :hover {
        background: ${(props) => props.theme.bg};
      }
    }
  }
  ${(props) =>
    props.sidebarMinimized &&
    css`
      .sidebar_acc_main {
        padding: 10px;
        .sidebar_acc_main_arrow {
          height: 10px;
          margin-left: -0.6rem;
        }
      }
    `}
`;

export const SidebarFooter = styled.div`
  padding: 1.5rem 1.25rem 0;
`;

export const UserSection = styled.div`
  margin: 1rem 0 1.5rem;
  width: 100%;
  padding: ${(props) => (props.sidebarMinimized ? "1rem 0.5rem" : "1rem")};
  background: ${(props) => props.theme.borderBg};
  color: ${(props) => props.theme.default_color};
  @media (max-width: ${breakPoints.md}px) {
    width: 100%;
    margin-bottom: 0;
  }
  .sidebar_user {
    display: flex;
    flex-direction: ${(props) => (props.sidebarMinimized ? "column" : "row")};
    align-items: ${(props) => props.sidebarMinimized && `center`};
    .sidebar_user_img {
      height: 20px;
      min-width: 20px;
      background: #c4c4c4;
      border-radius: 50%;
      margin-right: ${(props) => !props.sidebarMinimized && "6px"};
      margin-bottom: ${(props) => props.sidebarMinimized && "0.5rem"};
    }
    .sidebar_user_name {
      width: 100%;
      font-size: 16px;
      font-weight: 500;
    }
    a {
      height: 20px;
      width: 20px;
      img {
        height: 100%;
        width: 100%;
        min-width: 20px;
      }
    }
  }
  .sidebar_logout {
    cursor: pointer;
    font-size: ${(props) => (props.sidebarMinimized ? "10px" : "14px")};
    text-decoration: underline;
    font-weight: 500;
    color: ${(props) => props.theme.lightDarkGrey};
    width: fit-content;
    margin-top: 0.5rem;
    ${(props) =>
      props.sidebarMinimized &&
      css`
        margin: 0.5rem auto 0;
      `}
  }
`;

export const TranslationLogos = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  align-items: ${(props) => (props.sidebarMinimized ? "center" : "")};
  img {
    cursor: pointer;
  }
  @media (max-width: ${breakPoints.md}px) {
    margin-left: 1rem;
  }
`;

export const LanguageLabel = styled.span`
  color: #ced2d6;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  margin-bottom: 5px;
`;
export const LanguageSelect = styled.div`
  border: 1px solid #465b6f;
  border-radius: 6px;
  padding: 5px;
  width: 159px;
  position: relative;
  cursor: pointer;
  width: ${(props) => (props.sidebarMinimized ? 40 : 159)}px;
`;
export const CurrentLanguage = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.sidebarMinimized ? "center" : "space-between")};
  img {
    width: 30px;
    height: 30px;
  }
  .drowpDownIcon {
    width: 11px;
    height: 5.5px;
  }
  .language-name {
    font-size: 16px;
    color: #ced2d6;
  }
`;

export const Audited = styled.div`
  padding: 0 1.25rem;
  font-size: 12px;
  a {
    display: block;
    margin-top: 6px;
    width: max-content;
  }
`;
