import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";

import UserInputField from "../Components/User/UserInputField";
import { userDataOperations } from "../redux/userDataSlice";
import { FormButton, LoginForm } from "../Shared";

const VerifyAuth = () => {
  const dispatch = useDispatch();
  const [code, setCode] = useState("");

  const userData = useSelector((state) => state.userData);
  const { loading, authVerified, user } = userData;

  const handleVerifyGoogleAuth = (e) => {
    e.preventDefault();
    dispatch(userDataOperations.verifyAuthToken(code));
  };
  const { t } = useTranslation();
  return (
    <LoginForm>
      <h2>{t("auth.securityVerification")}</h2>
      {(authVerified || !user.id) && <Redirect to="/" />}
      <div className="inputContainer">
        <label>{t("auth.googleAuthenticatorCode")}</label>
        <UserInputField value={code} onChange={(e) => setCode(e.target.value)} noMargin />
        <Link className="signin_forgotpassword" to="/forgot_password">
          {t("auth.securityVerificationUnavailable")}
        </Link>
        <FormButton disabled={code.length === 0 || loading} onClick={handleVerifyGoogleAuth}>
          {t("common.submit")}
        </FormButton>
      </div>
    </LoginForm>
  );
};

export default VerifyAuth;
