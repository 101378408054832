import React from "react";

import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { trimAddress } from "../../helpers";
import { useWallet } from "../../helpers/useWallet";
import { stringToColour } from "../../Pages/ManageWallets";
import { editStackActions, editStackOperations } from "../../redux/wallets/editStackSlice";
import Modal from "../Modal";
import Checkbox from "../Signup/Checkbox";

const Wrapper = styled.form`
  padding: 1.5rem 0 1rem;
  > label,
  > input {
    display: block;
  }

  > label {
    font-size: 12px;
    color: ${(props) => props.theme.lightGrey};
    margin-bottom: 6px;
  }
  // Title input
  > input {
    width: 100%;
    margin-bottom: 2rem;
    padding: 0.75rem;
    background: ${(props) => props.theme.bg};
    border: 1px solid ${(props) => props.theme.researchBorder};
    border-radius: 6px;
  }

  // Every checkbox
  .add_stack_checkbox {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    font-size: 16px;
    color: ${(props) => props.theme.mainDarkText};
    width: max-content;
    margin-bottom: 1.25rem;
  }
  .select_all_checkbox {
    margin-bottom: 2rem;
  }
  h3 {
    font-size: 18px;
    margin-bottom: 1rem;
  }

  // Address styling
  .add_stack_address {
    display: flex;
    gap: 0.5rem;
    .address_card_pic {
      height: 36px;
      width: 36px;
      border-radius: 50%;
      background: grey;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .address_card_info {
      flex: 1;
      h5 {
        font-size: 16px;
        margin-bottom: 0.25rem;
      }
      h6 {
        font-size: 12px;
        color: ${(props) => props.theme.lightGrey};
      }
    }
  }
  .add_stack_buttons {
    display: flex;
    gap: 2rem;
    button {
      padding: 0.75rem 2.5rem;
      border-radius: 6px;
      background: 0;
      outline: 0;
      border: 0;
      color: white;
    }
    .add_stack_save {
      background: ${(props) => props.theme.brandBlue};
      border: 1px solid ${(props) => props.theme.brandBlue};
      :hover {
        background: ${(props) => props.theme.darkBrandBlue};
        border-color: ${(props) => props.theme.darkBrandBlue};
      }
    }
    .add_stack_cancel {
      border: 1px solid ${(props) => props.theme.researchBorder};
      :hover {
        background: ${(props) => props.theme.bg};
      }
    }
  }
`;

const EditStackModal = ({ handleClose, open }) => {
  const dispatch = useDispatch();

  // Create stack data
  const { data } = useSelector((state) => state.editStack);
  // User's connected wallet
  const wallet = useWallet();
  // User addresses
  const { addresses: userAddresses } = useSelector((state) => state.addresses);

  const connectedWalletData = wallet.address
    ? userAddresses.find((item) => item.address.toLowerCase() === wallet.address.toLowerCase())
    : null;

  const checkAddress = (id) => data.addresses.some((_id) => id === _id);
  return (
    <Modal open={open} handleCloseModal={handleClose} title="Edit Stack" maxwidth="700px" width="100%">
      <Wrapper
        onSubmit={async (e) => {
          e.preventDefault();
          await dispatch(editStackOperations.postEditStack());
          handleClose();
        }}
      >
        <label>Stack name</label>
        <input name="title" value={data.title} onChange={(e) => dispatch(editStackActions.setTitle(e.target.value))} />

        <label className="add_stack_checkbox select_all_checkbox">
          <Checkbox
            checked={data.addresses.length === userAddresses.length}
            onChange={(e) => dispatch(editStackOperations.handleAddAllAddresses(e.target.checked))}
          />
          <div>Select all</div>
        </label>
        <h3>Connected</h3>
        {connectedWalletData && (
          <label className="add_stack_checkbox select_all_checkbox">
            <Checkbox
              checked={checkAddress(connectedWalletData._id)}
              onChange={(e) => dispatch(editStackOperations.handleAddress(connectedWalletData._id, e.target.checked))}
            />
            <div className="add_stack_address">
              <div className="address_card_pic" style={{ background: "#1AD6B4" }} />
              <div className="address_card_info">
                <h5>{connectedWalletData.title}</h5>
                <h6>{trimAddress(connectedWalletData.address)}</h6>
              </div>
            </div>
          </label>
        )}

        <h3>Tracked ({userAddresses.length - 1})</h3>
        {userAddresses.length !== 0 &&
          userAddresses
            .filter((item) => (wallet.address ? item.address.toLowerCase() !== wallet.address.toLowerCase() : true))
            .map((item) => (
              <label key={item._id} className="add_stack_checkbox">
                <Checkbox
                  checked={checkAddress(item._id)}
                  onChange={(e) => dispatch(editStackOperations.handleAddress(item._id, e.target.checked))}
                />
                <div className="add_stack_address">
                  <div
                    className="address_card_pic"
                    style={{
                      background: stringToColour(item.address),
                    }}
                  />
                  <div className="address_card_info">
                    <h5>{item.title}</h5>
                    <h6>{trimAddress(item.address)}</h6>
                  </div>
                </div>
              </label>
            ))}
        <div className="add_stack_buttons">
          <button className="add_stack_save" type="submit">
            Save
          </button>
          <button className="add_stack_cancel">Cancel</button>
        </div>
      </Wrapper>
    </Modal>
  );
};

export default EditStackModal;
