export const sortFunction = (a, b, searchQuery) => {
  if (!searchQuery) return 0;

  // If searchQuery === symbol, puts token at the start, max priority
  if (a.text.toLowerCase() === searchQuery.toLowerCase()) return -1;
  if (b.text.toLowerCase() === searchQuery.toLowerCase()) return 1;

  // Then if symbol includes searchQuery, secondary priority
  if (a.text.toLowerCase().includes(searchQuery.toLowerCase())) return -1;
  if (b.text.toLowerCase().includes(searchQuery.toLowerCase())) return 1;

  // Then if name includes searchQuery, third priority
  if (a.name.toLowerCase().includes(searchQuery.toLowerCase())) return -1;
  if (b.name.toLowerCase().includes(searchQuery.toLowerCase())) return 1;

  return 0;
};
