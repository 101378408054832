import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { Storage } from "../helpers/Storage";
import { addressesOperations } from "./wallets/addressesSlice";
import { stacksOperations } from "./wallets/stacksSlice";

const APIUrl = process.env.REACT_APP_PRO_API;

const DONUT_CHART = "DONUT";

const getConfig = (state) => {
  return {
    headers: {
      Authorization: state.userData.tokens.refresh.token,
    },
  };
};

const initialState = {
  loading: true,
  error: null,
  updateSuccess: null,
  authVerified: false,
  user: {
    email: "",
    id: "",
    role: "",
    userName: "",
    displayName: "",
    about: "",
    telegram: "",
    discord_hook_id: "",
    discord_hook_token: "",
    sms: "",
    access: {
      allow: false,
    },
    authentication: {
      approved: false,
    },
    dashboardWidgets: [],
    mainWatchlist: "",
    tokenBlacklist: [],
    dashboardChart: DONUT_CHART,
  },
  tokens: {
    access: {
      token: "",
      expires: "",
    },
    refresh: {
      token: "",
      expires: "",
    },
  },
};

const userData = createSlice({
  name: "userData",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setAuthVerified(state, action) {
      return { ...state, authVerified: action.payload };
    },
    setUser(state, action) {
      return { ...state, user: { ...state.user, ...action.payload } };
    },
    setUpdateSuccess(state, action) {
      return { ...state, updateSuccess: action.payload };
    },
    setHasAccess(state, action) {
      state.user.access.allow = action.payload;
    },
    setTokens(state, action) {
      state.tokens = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const userDataActions = userData.actions;

const getRoot = (state) => state.userData;

export const userDataSelectors = {
  getLoading: (state) => getRoot(state).isLoading,
  getUser: (state) => getRoot(state).user,
  getTokens: (state) => getRoot(state).tokens,
};

export const userDataOperations = {
  userInit: () => async (dispatch) => {
    const user = Storage.getItem("userData");
    const googleAuth = Storage.getItem("google_auth");
    try {
      dispatch(userDataActions.setLoading(true));
      if (user) {
        const parsedUser = JSON.parse(user);
        const config = {
          headers: {
            Authorization: parsedUser.tokens.refresh.token,
          },
        };

        if (googleAuth) {
          const parsedGoogleAuth = JSON.parse(googleAuth);
          if (parsedGoogleAuth.access) {
            dispatch(userDataActions.setAuthVerified(true));
          }
        }

        const { data } = await axios.post(`${APIUrl}/v1/auth/verify-jwt`, {}, config);
        dispatch(userDataOperations.setUser(data, parsedUser.tokens));
      }
      dispatch(userDataActions.setLoading(false));
    } catch (err) {
      const parsedUser = JSON.parse(user);

      dispatch(userDataOperations.logout(parsedUser.tokens));
      dispatch(userDataActions.setLoading(false));
    }
  },

  initRequest: () => async (dispatch) => {
    dispatch(userDataActions.setLoading(true));
    dispatch(userDataActions.setError(null));
  },

  setUser: (user, tokens) => async (dispatch) => {
    dispatch(userDataActions.setUser(user));
    if (tokens) {
      dispatch(userDataActions.setTokens(tokens));
    }
    Storage.setItem("userData", JSON.stringify({ user, tokens }));
  },

  register: (signupData) => async (dispatch) => {
    dispatch(userDataOperations.initRequest());
    try {
      const { data } = await axios.post(`${APIUrl}/v1/auth/register`, signupData);

      Storage.setItem("userData", JSON.stringify(data));
      dispatch(userDataOperations.setUser(data.user, data.tokens));
    } catch (err) {
      dispatch(userDataActions.setError(err.response.data.message || err.response.data));
    }
    dispatch(userDataActions.setLoading(false));
  },

  signin: (signinData) => async (dispatch) => {
    dispatch(userDataOperations.initRequest());
    try {
      const { data } = await axios.post(`${APIUrl}/v1/auth/login`, signinData);
      Storage.setItem("userData", JSON.stringify(data));
      dispatch(userDataOperations.setUser(data.user, data.tokens));
      dispatch(addressesOperations.fetchUserAddresses());
      dispatch(stacksOperations.fetchUserStacks());
    } catch (err) {
      dispatch(userDataActions.setError(err.response.data.message));
    }
    dispatch(userDataActions.setLoading(false));
  },

  logout: (tokens) => async (dispatch, getState) => {
    const authTokens = tokens ?? getState().userData.tokens;
    try {
      Storage.removeItem("userData");
      Storage.removeItem("google_auth");

      const data = {
        refreshToken: authTokens.refresh.token,
      };

      await axios.post(`${APIUrl}/v1/auth/logout`, data);
      dispatch(userDataActions.setAuthVerified(false));
      dispatch(userDataActions.setUser(initialState.user));
      dispatch(userDataActions.setTokens(initialState.tokens));
    } catch (err) {
      dispatch(userDataActions.setError(err.response.data.message));
    }
  },

  updateProfile: (data) => async (dispatch, getState) => {
    dispatch(userDataActions.setLoading(true));

    dispatch(userDataActions.setUpdateSuccess(null));
    dispatch(userDataActions.setError(null));

    const state = getState();
    const tokens = state.userData.tokens;

    delete data.inputsChanged;

    const config = {
      headers: {
        Authorization: tokens.refresh.token,
      },
    };

    const update = {
      ...data,
    };

    try {
      const { data } = await axios.put(`${APIUrl}/v1/auth/update-profile`, update, config);
      dispatch(userDataOperations.setUser(data));
      dispatch(userDataActions.setUpdateSuccess(true));
      dispatch(userDataActions.setLoading(false));

      Storage.setItem("userData", JSON.stringify({ tokens, user: data }));
    } catch (err) {
      dispatch(userDataActions.setError(err.response.data.message));
      dispatch(userDataActions.setLoading(false));
    }
  },

  resetPasswordProfile: (data) => async (dispatch, getState) => {
    const config = {
      headers: {
        Authorization: getState().userData.tokens.refresh.token,
      },
    };

    try {
      await axios.post(`${APIUrl}/v1/auth/reset-password-profile`, data, config);
    } catch (err) {
      dispatch(userDataActions.setError(err.response.data.message));
    }
  },

  approveEmail: (token) => async (dispatch, getState) => {
    const config = {
      headers: {
        Authorization: token,
      },
    };

    try {
      const { data } = await axios.post(`${APIUrl}/v1/auth/approve-email`, {}, config);
      dispatch(userDataOperations.setUser(data));
      Storage.setItem("userData", JSON.stringify({ tokens: getState().userData.tokens, user: data }));
    } catch (err) {
      dispatch(userDataActions.setError(err.response.data.message));
    }
  },

  resendVerificationEmail: () => async (dispatch, getState) => {
    const config = {
      headers: {
        Authorization: getState().userData.tokens.refresh.token,
      },
    };

    try {
      await axios.post(`${APIUrl}/v1/auth/send-verification-email`, {}, config);
      return true;
    } catch (err) {
      dispatch(userDataActions.setError(err.response.data.message));
      return false;
    }
  },

  approveStakeKeyfi: (method) => async (dispatch, getState) => {
    const state = getState();
    const token = state.userData.tokens.refresh.token;
    const stakingAddress = state.user.id;

    const config = {
      headers: {
        Authorization: token,
      },
    };

    const methodData = {
      method,
      stakingAddress,
    };

    try {
      if (stakingAddress) {
        const { data } = await axios.post(`${APIUrl}/v1/auth/provide-access`, methodData, config);
        Storage.setItem("userData", JSON.stringify({ tokens: state.userData.tokens, user: data }));
        dispatch(userDataOperations.setUser(data));
      }
    } catch (err) {
      console.log(err);
      dispatch(userDataActions.setError(err.response.data.message || err.response.data));
    }
  },

  getAuthToken: () => async (dispatch, getState) => {
    const token = getState().userData.tokens.refresh.token;

    const config = {
      headers: {
        Authorization: token,
      },
    };

    try {
      const { data } = await axios.get(`${APIUrl}/v1/auth/get-auth-token`, config);
      return data;
    } catch (err) {
      console.log(err);
    }
  },

  verifyAuthToken: (secret) => async (dispatch, getState) => {
    dispatch(userDataOperations.initRequest());
    const state = getState();
    const tokens = state.userData.tokens;

    const config = {
      headers: {
        Authorization: tokens.refresh.token,
      },
    };

    try {
      const { data } = await axios.post(`${APIUrl}/v1/auth/verify-auth-token`, { secret }, config);

      dispatch(userDataActions.setAuthVerified(true));
      dispatch(userDataOperations.setUser(data, tokens));
      Storage.setItem("google_auth", JSON.stringify({ access: true, expires: state.userData.tokens.access.expires }));
    } catch (err) {
      dispatch(userDataActions.setError(err.response.data.message || err.response.data));
    }
    dispatch(userDataActions.setLoading(false));
  },

  disableAuth: () => async (dispatch, getState) => {
    dispatch(userDataOperations.initRequest());
    const state = getState();
    const token = state.userData.tokens.refresh.token;
    const user = state.userData.user;

    const config = {
      headers: {
        Authorization: token,
      },
    };

    try {
      await axios.post(`${APIUrl}/v1/auth/disable-auth`, {}, config);
      const userData = {
        ...user,
        authVerified: false,
        authentication: {
          approved: false,
        },
      };
      dispatch(userDataActions.setUser(userData));
      Storage.setItem("userData", JSON.stringify({ tokens: state.userData.tokens, user: userData }));
    } catch (err) {
      dispatch(userDataActions.setError(err.response.data.message || err.response.data));
    }
    dispatch(userDataActions.setLoading(false));
  },

  updateDashboardWidgets: (widgets) => async (dispatch, getState) => {
    dispatch(userDataActions.setUser({ dashboardWidgets: widgets }));
    try {
      const config = getConfig(getState());
      const { data } = await axios.post(`${APIUrl}/v1/auth/update-widgets`, { widgets }, config);
      dispatch(userDataActions.setUser(data));
    } catch (err) {
      dispatch(userDataActions.setError(err.response.data.message || err.response.data));
    }
  },
  addDashboardWidget: (widget) => async (dispatch, getState) => {
    try {
      const config = getConfig(getState());
      const { data } = await axios.post(`${APIUrl}/v1/auth/add-dashboard-widget`, { widget }, config);
      dispatch(userDataActions.setUser(data));
    } catch (err) {
      dispatch(userDataActions.setError(err.response.data.message || err.response.data));
    }
  },

  removeDashboardWidget: (widget) => async (dispatch, getState) => {
    try {
      const config = getConfig(getState());
      const { data } = await axios.post(`${APIUrl}/v1/auth/remove-dashboard-widget`, { widget }, config);
      dispatch(userDataActions.setUser(data));
    } catch (err) {
      dispatch(userDataActions.setError(err.response.data.message || err.response.data));
    }
  },
  addToTokenBlacklist: (token) => async (dispatch, getState) => {
    try {
      const user = getState().userData.user;
      const newTokenBlacklist = {
        tokenBlacklist: [
          ...user.tokenBlacklist,
          { network: token.network, address: token.address, symbol: token.symbol },
        ],
      };
      dispatch(userDataActions.setUser(newTokenBlacklist));
      const config = getConfig(getState());
      const { data } = await axios.put(`${APIUrl}/v1/auth/update-profile`, newTokenBlacklist, config);
      dispatch(userDataActions.setUser(data));
    } catch (err) {
      console.log(err);
    }
  },
  removeTokenBlacklist: (token) => async (dispatch, getState) => {
    try {
      const user = getState().userData.user;
      const newTokenBlacklist = user.tokenBlacklist.filter(
        (item) => item.address !== token.address || item.network !== token.network
      );
      dispatch(userDataActions.setUser({ tokenBlacklist: newTokenBlacklist }));
      const config = getConfig(getState());
      const { data } = await axios.put(
        `${APIUrl}/v1/auth/update-profile`,
        { tokenBlacklist: newTokenBlacklist },
        config
      );
      dispatch(userDataActions.setUser(data));
    } catch (err) {
      console.log(err);
    }
  },
  changeDashboardChart: (chart) => async (dispatch, getState) => {
    try {
      dispatch(userDataActions.setUser({ dashboardChart: chart }));
      const config = getConfig(getState());
      const { data } = await axios.put(`${APIUrl}/v1/auth/update-profile`, { dashboardChart: chart }, config);
      dispatch(userDataActions.setUser(data));
    } catch (err) {
      console.log(err);
    }
  },
  updateWidgetSize: (widget) => async (dispatch, getState) => {
    try {
      const dashboardWidgets = [...getState().userData.user.dashboardWidgets];
      const dashboardWidgetIndex = dashboardWidgets.findIndex((item) => item.id === widget.id);
      dashboardWidgets[dashboardWidgetIndex] = widget;
      dispatch(userDataActions.setUser({ dashboardWidgets }));
      const config = getConfig(getState());
      await axios.put(`${APIUrl}/v1/auth/update-profile`, { dashboardWidgets }, config);
    } catch (err) {
      console.log(err);
    }
  },
};

export const userDataReducer = userData.reducer;
