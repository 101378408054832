import React, { useMemo, useState } from "react";

import { availablePairs } from "@keyfi/keyfi-common/src/integrations/uniswap/constants/constants";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { strategyActionOperations } from "../../../redux/strategyActionsSlice";
import { strategyGroupOperations } from "../../../redux/strategyGroupSlice";
import StrategyDropdown from "../StrategyDropdown";

const Wrapper = styled.div`
  .action_input_container {
    display: flex;
    gap: 2rem;
    > div {
      flex: 1;
      .dropdown_image {
        height: 24px;
        width: 24px;
      }
    }
  }
`;
const RemoveLiquidityAction = ({ method, handleCloseModal, editModal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const strategyAction = useSelector((state) => state.strategyAction);
  const { activeAction } = strategyAction;

  const strategyGroup = useSelector((state) => state.strategyGroup);
  const { group } = strategyGroup;

  const [searchQuery, setSearchQuery] = useState("");

  const tokenPairs = useMemo(
    () =>
      availablePairs.mainnet.map((item) => ({
        id: item.address,
        text: `${item.key}`,
      })),
    []
  );

  const filteredList = useMemo(() => {
    return tokenPairs
      .filter(({ text }) => (method === "removeLiquidityETH" ? text.toLowerCase().includes("weth") : true))
      .filter(({ text }) => text.toLowerCase().includes(searchQuery.toLowerCase()))
      .slice(0, 20);
  }, [method, searchQuery, tokenPairs]);

  const handleSaveAction = () => {
    const newArray = group.actions.filter((item) => activeAction.templateId !== item.templateId);
    dispatch(strategyGroupOperations.handleGroupInput("actions", [...newArray, activeAction]));
    handleCloseModal();
  };

  const handleUpdateAction = () => {
    dispatch(strategyActionOperations.updateAction());
    handleCloseModal();
  };

  return (
    <Wrapper>
      <h5>{t("common.asset")} 2</h5>
      <StrategyDropdown
        name="0"
        placeholder={t("strategy.chooseAnAsset")}
        setOption={(name, value) => dispatch(strategyActionOperations.handleActiveActionArgs(name, value))}
        options={filteredList}
        fullOptions={tokenPairs}
        selectedOption={activeAction.args[0]}
        // className="edit_action_input"
        searchBar
        onChange={(e) => setSearchQuery(e.target.value)}
        searchQuery={searchQuery}
      />{" "}
      <h5>{t("common.lpValue")}</h5>
      <input
        name="1"
        value={activeAction.args[1]}
        onChange={(e) => dispatch(strategyActionOperations.handleActiveActionArgs(e.target.name, e.target.value))}
        className="edit_action_input"
      />
      <button className="primary" onClick={editModal ? handleUpdateAction : handleSaveAction}>
        {t("strategy.saveAction")}
      </button>
    </Wrapper>
  );
};

export default RemoveLiquidityAction;
