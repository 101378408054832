import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { strategyGroupOperations } from "./strategyGroupSlice";
import { strategyOperations } from "./strategySlice";

const API = process.env.REACT_APP_PRO_API;

const initialState = {
  isLoading: false,
  actionTemplates: [],
  activeAction: {
    templateId: "",
    args: [],
  },
};

const strategyAction = createSlice({
  name: "strategyAction",
  initialState,
  reducers: {
    setLoading(state, action) {
      return { ...state, isLoading: action.payload };
    },
    setActionsTemplates(state, action) {
      return { ...state, actionTemplates: action.payload };
    },
    setActiveAction(state, action) {
      return { ...state, activeAction: action.payload };
    },
  },
});

export const strategyActionActions = strategyAction.actions;

const getRoot = (state) => state.strategyAction;

export const strategyActionSelectors = {
  getLoading: (state) => getRoot(state).isLoading,
  getActionTemplates: (state) => getRoot(state).actionTemplates,
  getActiveAction: (state) => getRoot(state).activeAction,
};

export const strategyActionOperations = {
  handleActiveAction: (name, value) => async (dispatch, getState) => {
    const activeAction = strategyActionSelectors.getActiveAction(getState());
    dispatch(strategyActionActions.setActiveAction({ ...activeAction, [name]: value }));
  },
  handleActiveActionArgs: (key, value) => async (dispatch, getState) => {
    let activeAction = strategyActionSelectors.getActiveAction(getState());
    let args = [...activeAction.args];
    args[key] = value;

    dispatch(strategyActionActions.setActiveAction({ ...activeAction, args }));
  },
  getActionTemplates: () => async (dispatch, getState) => {
    try {
      const state = getState();
      const token = state.userData.tokens.access.token;

      const config = {
        headers: {
          Authorization: token,
        },
      };

      const { data } = await axios.get(`${API}/v1/action/action-templates`, config);
      dispatch(strategyActionActions.setActionsTemplates(data));
    } catch (err) {
      console.log(err);
    }
  },
  createAction: (groupId, templateId) => async (dispatch, getState) => {
    try {
      dispatch(strategyActionActions.setLoading([]));
      const token = getState().userData.tokens.access.token;

      const config = {
        headers: {
          Authorization: token,
        },
      };

      const body = {
        groupId,
        templateId,
        args: [],
      };

      const { data } = await axios.post(`${API}/v1/action/new`, body, config);
      const activeGroupActions = getState().strategyGroup.activeGroup.actions;

      dispatch(strategyGroupOperations.handleActiveGroupInput("actions", [...activeGroupActions, data]));
    } catch (err) {
      console.log(err);
    }
  },
  updateAction: () => async (dispatch, getState) => {
    try {
      dispatch(strategyActionActions.setLoading([]));
      const token = getState().userData.tokens.access.token;
      const action = strategyActionSelectors.getActiveAction(getState());

      const config = {
        headers: {
          Authorization: token,
        },
      };

      await axios.patch(`${API}/v1/action/${action._id}`, { args: action.args }, config);
      dispatch(strategyOperations.getStrategies());
    } catch (err) {
      console.log(err);
    }
  },
};

export const strategyActionReducer = strategyAction.reducer;
