import styled, { css } from "styled-components";

export const SwapDropdownWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.75rem 0.75rem;
  gap: 6px;
  background: ${(props) => props.theme.secondaryBg};
  border: 1px solid ${(props) => (props.selected ? props.theme.brandBlue : props.theme.researchBorder)};
  border-radius: 6px;
  img {
    height: 20px;
    width: 20px;
  }

  ${(props) =>
    props.selected
      ? css`
          background: #122a4b;
          border: 1px solid #005ad3;
        `
      : ``}

  span {
    flex: 1;
    font-size: 14px;
  }
  .swap_placeholder {
    font-size: 12px;
    color: ${(props) => props.theme.lightGrey};
  }

  .swap_token_image {
    flex: none;
    height: 26px;
    width: 26px;
  }
  svg {
    width: 20px;
    height: 18px;
  }
  ${(props) =>
    props.error &&
    css`
      border-color: ${(props) => props.theme.error};
      background: ${(props) => props.theme.errorInputBackground};
    `}
`;

export const StyledOptions = styled.div`
  color: ${(props) => props.theme.mainDarkText};
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  justify-content: flex-start;
  flex-direction: column;
  max-height: 350px;
  min-height: 350px;
  overflow-y: auto;
  z-index: 9999;
  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  span {
    margin-left: 5px;
  }
  svg,
  img {
    width: 32px !important;
    height: 32px !important;
  }
  .swap_image {
    font-size: 32px;
    line-height: 32px;
    width: 32px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  height: 56px;
  cursor: pointer;
  color: ${(props) => props.theme.default_color};
  font-size: 16px;
  line-height: 20px;
  .token-info {
    display: flex;
    align-items: center;
    .swap_token_image {
      height: 32px;
      width: 32px;
      font-size: 32px;
      line-height: 32px;
      margin-left: 0;
    }
  }
  :hover {
    background: ${(props) => props.theme.bg};
  }

  border-top: 1px solid ${(props) => props.theme.form_text3};
`;

export const OptionsWrapper = styled.div`
  min-height: 400px;

  .input-wrapper {
    margin-top: 32px;
    margin-bottom: 24px;
    background: ${(props) => props.theme.bg};
    display: flex;
    padding: 0.75rem;
    gap: 1rem;
    border-radius: 6px;
    border: 1px solid ${(props) => props.theme.researchBorder};
    input {
      flex: 1;
      ::placeholder {
        color: ${(props) => props.theme.placeholderText};
      }
    }
  }
`;
