import React, { useEffect, useState } from "react";

import styled, { css } from "styled-components";

const Wrapper = styled.div`
  .tabs {
    margin-bottom: -1px;
  }
`;

const Tab = styled.span`
  display: inline-block;
  border-radius: 6px 6px 0px 0px;
  border: 1px solid ${(props) => props.theme.input_placefolder};
  background: ${(props) => (props.active ? props.theme.brandBlue : props.theme.bg)};
  color: ${(props) => props.theme.white};
  cursor: pointer;
  height: 30px;
  padding: 8px;
  font-size: 12px;
  font-weight: 500;

  :focus,
  :hover,
  :visited {
    background: ${(props) => props.theme.input_placefolder};
  }

  ${(props) =>
    props.disabled &&
    css`
      background: ${(props) => props.theme.darkGrey};
      border-color: ${(props) => props.theme.darkGrey};
      color: ${(props) => props.theme.disabledText};
      pointer-events: none;
    `}
`;

// example on how to use Tabs
// const tabs = [
//     { Name: "tab1", Component: comp, disabled: boolean },
//     { Name: "tab2", Component: comp2, disabled: boolean },
//   ];

function Tabs({ tabs, activeTabID, handleChange }) {
  const [activeTab, setActiveTab] = useState(tabs[0]);

  useEffect(() => {
    if (activeTabID) {
      setActiveTab(activeTabID);
    }
  }, [tabs, activeTabID]);

  return (
    <Wrapper>
      <div className="tabs">
        {tabs.map((t, index) => (
          <Tab
            key={index}
            disabled={t.disabled || false}
            className="tabs__tab"
            active={t.Name === activeTab.Name}
            onClick={() => {
              if (handleChange) {
                handleChange(tabs[index]);
              } else {
                setActiveTab(tabs[index]);
              }
            }}
          >
            {t.MobileName && window.innerWidth < 767 ? t.MobileName : t.Name}
          </Tab>
        ))}
      </div>

      <div className="tabs__component">{activeTab.Component}</div>
    </Wrapper>
  );
}

export default Tabs;
