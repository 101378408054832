import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";


import { ReactComponent as Search } from "../../assets/discovery/magnifyingGlass.svg";
import { watchlistOperations } from "../../redux/watchlistSlice";
import { SearchInput } from "./ResearchPageHeader.styles";

const SearchBar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showList, setShowList] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [list, setList] = useState([]);

  const watchlist = useSelector((state) => state.watchlist);
  const { watchlistList } = watchlist;

  const onClickOutsideListener = () => {
    setShowList(false);
    document.removeEventListener("click", onClickOutsideListener);
  };

  useEffect(() => {
    if (watchlistList.length === 0) {
      dispatch(watchlistOperations.getWatchlistList());
    }
  }, [watchlistList, dispatch]);

  useEffect(() => {
    if (watchlistList.length !== 0) {
      const data = [...watchlistList]
        .filter(({ name, symbol }) => {
          const regex = new RegExp(`^(${searchQuery})`, "gi");
          return regex.test(name) || regex.test(symbol);
        })
        .sort((a, b) => {
          if ((a.market_cap_rank ?? 999999) < (b.market_cap_rank ?? 898989)) return -1;
          return 1;
        })
        .sort((a, b) => {
          if (a.name.toLowerCase() === searchQuery.toLowerCase()) return -1;
          if (b.name.toLowerCase() === searchQuery.toLowerCase()) return 1;
          return 0;
        })
        .slice(0, 20);

      setList(data.filter((ele, ind) => ind === data.findIndex((elem) => elem.coin_id === ele.coin_id)));
    }
  }, [watchlistList, searchQuery]);

  return (
    <SearchInput
      onMouseLeave={() => {
        if (showList) {
          document.addEventListener("click", onClickOutsideListener);
        }
      }}
    >
      <input
        placeholder={t("common.tokenName")}
        onFocus={() => setShowList(true)}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <Search />
      <div className="search_input_list" style={{ display: showList ? "block" : "none" }}>
        {list.length !== 0 &&
          list.map((item) => (
            <Link key={`${item.coin_id}_${item.name}`} to={`/research/token/${item.coin_id}`}>
              <img src={item.icon} alt={item.name} />
              {item.name}
              <p className="tokenSymbol"> {item.symbol.toUpperCase()} </p>
            </Link>
          ))}
      </div>
    </SearchInput>
  );
};

export default SearchBar;
