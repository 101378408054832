import React, { useEffect, useMemo, useState } from "react";

import { swap } from "@keyfi/integrations/dist/univ2/index.mjs";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { ReactComponent as SwapArrows } from "../../../assets/sidebar/swap.svg";
import { beautifyTokensAmount, networkTokenList } from "../../../helpers";
import Token from "../../../helpers/token";
import { walletOperations } from "../../../redux/walletSlice";
import SwapDropdown from "../SwapDropdown";
import platforms from "../SwapPlatforms";
import TokenInput from "../TokenInput";
import { MainSwapSection, SwapInfo } from "./integrations.styles";

const UniV2Swap = ({ selectedPlatform, inputData, setInputData, setSelectedPlatform, dashboardSwap }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { search } = location;

  const { t } = useTranslation();

  const wallet = useSelector((state) => state.wallet);
  const user = useSelector((state) => state.user);

  const { web3 } = wallet;

  const network = selectedPlatform.network;
  const networkTokens = dispatch(walletOperations.getWalletNetworkTokens(network));

  let asset1 = new URLSearchParams(search).get("asset1") ?? "";
  let asset2 = new URLSearchParams(search).get("asset2") ?? "";

  const [supportedAssets, setSupportedAssets] = useState([]);

  const { input1, assetA, input2, assetB, whichInputChanged, estimation } = inputData;

  const input1Error = useMemo(() => {
    return Number(input1) > (networkTokens[assetA.symbol] ?? 0) || input1 === "0";
  }, [user, input1, selectedPlatform, assetA]);

  useEffect(() => {
    const fetchSupportedAssets = async () => {
      let tokens = networkTokenList[network];
      tokens = tokens.map((item) => {
        return new Token(network, item.id, item.symbol, item.decimals);
      });
      setSupportedAssets(tokens);
    };
    if (selectedPlatform && wallet.network.name) {
      fetchSupportedAssets();
      if (!asset1 && !asset2) {
        setInputData((prev) => ({
          ...prev,
          asset1: selectedPlatform.defaultSelected[0],
          asset2: selectedPlatform.defaultSelected[1],
        }));
      }
    }
  }, [selectedPlatform, wallet.network.name, asset1, asset2]);

  useEffect(() => {
    if (wallet.network && wallet.network.name && web3 && whichInputChanged) {
      if (+input1 && assetA && assetB && whichInputChanged === "input1") {
        setInputData((prev) => ({ ...prev, isFetching: true }));

        swap
          .getTrade(web3, selectedPlatform.id, selectedPlatform.network, input1, assetA, assetB, false)
          .then((estimation) => {
            if (estimation && +input1) {
              setInputData((prevState) => {
                return {
                  ...prevState,
                  input2: +estimation.returnAmountHuman || "",
                  estimation,
                  isFetching: false,
                };
              });
            }
          })
          .catch((err) => console.log(err));
      } else if (+input2 && assetA && assetB && whichInputChanged === "input2") {
        setInputData((prev) => ({ ...prev, isFetching: true }));

        swap
          .getTrade(web3, selectedPlatform.id, selectedPlatform.network, input2, assetA, assetB, true)
          .then((estimation) => {
            if (estimation && +input2) {
              setInputData((prevState) => {
                return {
                  ...prevState,
                  input1: +estimation.fromAmountHuman || "",
                  estimation,
                  isFetching: false,
                };
              });
            }
          })
          .catch((err) => console.log(err));
      }
    }
  }, [input1, input2, assetA.address, assetB.address, wallet.network, selectedPlatform]);

  // Handle input change
  const handleChange = (e) => {
    const re = /^\d*\.?\d*$/;
    if (e.target.value.length === 0 || !re.test(e.target.value)) {
      setInputData((prev) => ({ ...prev, input1: "", input2: "" }));
      return;
    }
    setInputData((prev) => ({ ...prev, [e.target.name]: e.target.value, whichInputChanged: e.target.name }));
  };

  // Switch assets
  const handleSelectInputSwitch = () => {
    setInputData((prev) => ({
      ...prev,
      selectinput1: prev.selectinput2,
      input1: prev.input2,
      input2: prev.input1,
      selectinput2: prev.selectinput1,
    }));
  };

  // Handle max input
  // const handleMax = () => {
  //   setInputData((prev) => ({ ...prev, input1: networkTokens[prev.assetA.symbol] ?? 0, whichInputChanged: "input1" }));
  // };

  const handleSwap = async () => {
    if (+input1 && assetA && +input2 && assetB) {
      try {
        const res = await swap.getSwap(
          web3,
          selectedPlatform.id,
          selectedPlatform.network,
          assetA,
          input1,
          assetB,
          estimation,
          whichInputChanged === "input1" ? false : true
        );
        console.log(res);
        if (res) {
          dispatch(walletOperations.loadUserData(0, true));
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <MainSwapSection dashboardSwap={dashboardSwap}>
      {!dashboardSwap && <h1>{t("swap.title")}</h1>}
      <div className="swap_main">
        <div className="swap_main_platforms">
          <h5>{t("common.chooseAplatform")}:</h5>
          <SwapDropdown options={platforms} selectedOption={selectedPlatform} setOption={setSelectedPlatform} />
        </div>
        <div className="swap_main_section">
          <div className="swap_input_label">
            <span>{t("common.from")}</span>
            <span>
              {t("common.balance")}:{" "}
              {beautifyTokensAmount(networkTokens[inputData.assetA.symbol] ?? 0, {
                suffix: ` ${inputData.assetA.symbol}`,
              })}
            </span>
          </div>
          <TokenInput
            name="input1"
            onChange={handleChange}
            value={input1}
            networkTokens={networkTokens}
            supportedAssets={supportedAssets}
            network={selectedPlatform.network}
            selectedOption={inputData.assetA}
            setOption={(data) =>
              setInputData((prev) => ({ ...prev, assetA: data, input1: "", whichInputChanged: "input2" }))
            }
          />

          {input1Error && (
            <div className="swap_input_error">
              {t("common.insufficient")} {assetA.symbol} {t("common.balance")}
            </div>
          )}
          {assetA.address === assetB.address && <div className="swap_input_error">{t("swap.cannotEqual")}</div>}
          <SwapArrows onClick={() => handleSelectInputSwitch()} className="swap_arrow" />
          <div className="swap_input_label">
            <span>{t("common.to")}</span>
            <span>
              {assetB && (
                <>
                  {t("common.balance")}:{" "}
                  {beautifyTokensAmount(networkTokens[inputData.assetB.symbol] ?? 0, {
                    suffix: ` ${inputData.assetB.symbol}`,
                  })}
                </>
              )}
            </span>
          </div>
          <TokenInput
            name="input2"
            onChange={handleChange}
            value={input2}
            supportedAssets={supportedAssets}
            networkTokens={networkTokens}
            network={selectedPlatform.network}
            selectedOption={inputData.assetB}
            setOption={(data) =>
              setInputData((prev) => ({ ...prev, assetB: data, input1: "", whichInputChanged: "input1" }))
            }
          />
        </div>
        <button
          disabled={input1Error || assetA.address === assetB.address}
          className="swap_button"
          onClick={handleSwap}
        >
          {t("swap.title")}
        </button>
        <SwapInfo
          style={{ display: dashboardSwap ? "block" : "none" }}
          dashboardSwap={dashboardSwap}
          input1={input1 && input2}
        >
          <h3>{t("swap.estimatedSwapPrices")}</h3>
          <div className="swap_info_line">
            <div>
              <div className="swap_info_value">
                {input1 && input2 ? String(Number(input1) / Number(input2)).slice(0, 9) : "0.00"}
              </div>
              <div className="swap_info_label">
                {input1 && input2 ? assetA.symbol : "-"} per {input1 && input2 ? assetB.symbol : "-"}
              </div>
            </div>
            <div>
              <div className="swap_info_value">
                {input1 && input2 ? String(Number(input2) / Number(input1)).slice(0, 9) : "0.00"}
              </div>
              <div className="swap_info_label">
                {input1 && input2 ? assetB.symbol : "-"} per {input1 && input2 ? assetA.symbol : "-"}
              </div>
            </div>
          </div>
          <div className="swap_info_line">
            <div>
              <div className="swap_info_value">
                {input1 && input2
                  ? estimation.priceImpact >= 0.01
                    ? beautifyTokensAmount(estimation.priceImpact, { suffix: "%" })
                    : "<0.01%"
                  : "-"}
              </div>
              <div className="swap_info_label">{t("swap.priceImpact")}</div>
            </div>
            <div>
              <div className="swap_info_value">
                {input1 && input2
                  ? estimation.route &&
                    estimation.route
                      .map(
                        (addr) =>
                          supportedAssets.find((item) => item.address.toLowerCase() === addr.toLowerCase()).symbol
                      )
                      .join(" > ")
                  : "-"}
              </div>
              <div className="swap_info_label">{t("swap.route")}</div>
            </div>
          </div>
        </SwapInfo>
      </div>

      <SwapInfo style={{ display: dashboardSwap ? "none" : "block" }} input1={input1 && input2}>
        <h3>{t("swap.estimatedSwapPrices")}</h3>
        <div className="swap_info_line">
          <div>
            <div className="swap_info_value">
              {input1 && input2 ? String(Number(input1) / Number(input2)).slice(0, 9) : "0.00"}
            </div>
            <div className="swap_info_label">
              {input1 && input2 ? assetA.symbol : "-"} per {input1 && input2 ? assetB.symbol : "-"}
            </div>
          </div>
          <div>
            <div className="swap_info_value">
              {input1 && input2 ? String(Number(input2) / Number(input1)).slice(0, 9) : "0.00"}
            </div>
            <div className="swap_info_label">
              {input1 && input2 ? assetB.symbol : "-"} per {input1 && input2 ? assetA.symbol : "-"}
            </div>
          </div>
        </div>
        <div className="swap_info_line">
          <div>
            <div className="swap_info_value">
              {input1 && input2
                ? estimation.priceImpact >= 0.01
                  ? beautifyTokensAmount(estimation.priceImpact, { suffix: "%" })
                  : "<0.01%"
                : "-"}
            </div>
            <div className="swap_info_label">{t("swap.priceImpact")}</div>
          </div>
          <div>
            <div className="swap_info_value">
              {input1 && input2
                ? estimation.route &&
                  estimation.route
                    .map(
                      (addr) => supportedAssets.find((item) => item.address.toLowerCase() === addr.toLowerCase()).symbol
                    )
                    .join(" > ")
                : "-"}
            </div>
            <div className="swap_info_label">{t("swap.route")}</div>
          </div>
        </div>
      </SwapInfo>
    </MainSwapSection>
  );
};

export default UniV2Swap;
