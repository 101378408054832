import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { stacksOperations } from "./stacksSlice";

const API = process.env.REACT_APP_PRO_API;

const initialState = {
  isLoading: false,
  title: "",
  addresses: [],
};

const createStack = createSlice({
  name: "createStack",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setTitle(state, action) {
      state.title = action.payload;
    },
    setAddresses(state, action) {
      state.addresses = action.payload;
    },
  },
});

export const createStackActions = createStack.actions;

const getRoot = (state) => state.createStack;

export const createStackSelectors = {
  getLoading: (state) => getRoot(state).isLoading,
  getTitle: (state) => getRoot(state).title,
  getAddresses: (state) => getRoot(state).addresses,
};

export const createStackOperations = {
  handleAddAllAddresses: (addAll) => (dispatch, getState) => {
    if (addAll) {
      const userAddresses = getState().addresses.addresses;
      dispatch(createStackActions.setAddresses(userAddresses.map((item) => item._id)));
      return;
    }
    dispatch(createStackActions.setAddresses([]));
  },
  handleAddress: (addressId, add) => (dispatch, getState) => {
    const addresses = getState().createStack.addresses;
    if (add) {
      dispatch(createStackActions.setAddresses([...addresses, addressId]));
      return;
    }
    dispatch(createStackActions.setAddresses([...addresses].filter((id) => id !== addressId)));
  },
  postStack: () => async (dispatch, getState) => {
    dispatch(createStackActions.setLoading(true));
    const title = createStackSelectors.getTitle(getState());
    const addresses = createStackSelectors.getAddresses(getState());
    const token = getState().userData.tokens.access.token;
    const config = {
      headers: {
        Authorization: token,
      },
    };
    try {
      await axios.post(`${API}/v1/wallet/stack`, { title, addresses }, config);
      dispatch(stacksOperations.fetchUserStacks());
      dispatch(createStackActions.setTitle(""));
      dispatch(createStackActions.setAddresses([]));
    } catch (err) {
      console.log(err);
    }
    dispatch(createStackActions.setLoading(false));
  },
};

export const createStackReducer = createStack.reducer;
