import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";

import { getRates } from "../../redux/rates/actions";
import { walletOperations } from "../../redux/walletSlice";
import { Divider } from "../../Shared";
import Card from "./Card";
import { RebalancerScreenWrapper, TotalInvestRate, renderPlatformLink } from "./StableCoins";
import Table from "./Table";

const platforms = ["Compound", "Aave", "dYdX"];

function MaxYield({ rates, user }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [localRates, setLocalRates] = useState([]);

  const tableHeadItems = [
    { id: "rank", label: t("common.rank"), sortable: true },
    { id: "asset", label: t("common.asset"), sortable: true },
    { id: "rate", label: t("common.supplyRate"), sortable: true },
    { id: "assetPrice", label: t("common.price"), sortable: true },
    { id: "borrowRate", label: t("common.borrowRate"), sortable: true },
    { id: "platform", label: t("common.platform"), sortable: true },
    { id: "action", label: t("common.action"), sortable: false },
  ];

  const filteredStableCoinsTokensList = (platform) => {
    let balance = dispatch(walletOperations.getNetworkStakedTokens("mainnet", platform, true));
    return Object.entries(balance)
      .filter((val) => val[1].amount > 0)
      .slice(0, 6);
  };

  useEffect(() => {
    if (rates.length) {
      let filterStableCoins = rates.filter((entry) => entry.assetPrice != null);

      filterStableCoins.sort((a, b) => b.rate - a.rate);
      filterStableCoins.forEach((rate, index) => {
        filterStableCoins[index].rank = index + 1;
        filterStableCoins[index].action = renderPlatformLink(
          filterStableCoins[index].platform,
          t("common.manageAssets"),
          filterStableCoins[index].asset
        );
      });
      filterStableCoins = filterStableCoins.map((entry) => ({
        rank: entry.rank,
        asset: entry.asset,
        rate: String(entry.rate).slice(0, 6) + " %",
        assetPrice: `$${String(entry.assetPrice).slice(0, 7)} USD`,
        borrowRate: String(entry.borrowRate).slice(0, 4) + " %",
        platform:
          entry.platform === "C.R.E.A.M. Finance"
            ? "C.R.E.A.M."
            : entry.platform === "dYdX"
            ? "dydx"
            : entry.platform.charAt(0).toUpperCase() + entry.platform.slice(1),
        action: entry.action,
      }));
      setLocalRates(filterStableCoins);
    }
  }, [rates, t]);

  return (
    <RebalancerScreenWrapper>
      <div className="rebalancer_inside_wrapper">
        <TotalInvestRate localRates={localRates} t={t} />
        <div className="PlatformsCards">
          {platforms.map((p) => (
            <Card
              key={p}
              title={p}
              platform={p.toLowerCase()}
              tokenList={filteredStableCoinsTokensList(p.toLowerCase(), user)}
            />
          ))}
        </div>
        <Divider />
        {localRates.length ? (
          <Table rows={localRates} tableHeadItems={tableHeadItems} title={t("common.maxYieldCoin")} />
        ) : null}
      </div>
    </RebalancerScreenWrapper>
  );
}

const mapStateToProps = (state) => ({
  rates: state.rates.rates,
  user: state.user,
});

const mapDispatchtoProps = (dispatch) => ({
  getRates: () => dispatch(getRates()),
});
export default connect(mapStateToProps, mapDispatchtoProps)(MaxYield);
