import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";

import Meta from "../Components/Meta";
import UserInputField from "../Components/User/UserInputField";
import { validateEmail } from "../helpers";
import { userDataActions, userDataOperations } from "../redux/userDataSlice";
import { ErrorMessage, FormButton, LoginForm } from "../Shared";
import { RegistrationText } from "./Signup";
// Redux

function Login() {
  const dispatch = useDispatch();
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [emailError, setEmailError] = useState(false);
  const { t } = useTranslation();
  const userData = useSelector((state) => state.userData);
  const { user, loading, error } = userData;

  useEffect(() => {
    dispatch(userDataActions.setError(null));
  }, []);

  const handleChange = (e) => {
    setLoginData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(userDataOperations.signin(loginData));
  };

  return (
    <LoginForm autoComplete="off" onSubmit={handleSubmit}>
      <Meta title={t("auth.login")} />
      {user.id && user.access.allow && user.emailApproved ? (
        <Redirect to="/dashboard" />
      ) : user.id && user.emailApproved ? (
        <Redirect to="/access" />
      ) : (
        user.id && <Redirect to="/verify-email" />
      )}
      <h2>{t("auth.login")}</h2>
      <div className="inputContainer">
        <label htmlFor="loginEmail">{t("auth.email")}</label>
        <UserInputField
          placeholder={t("auth.enterEmailPlaceholder")}
          value={loginData.email}
          onChange={handleChange}
          type="email"
          name="email"
          onFocus={() => setEmailError(false)}
          onBlur={(e) => !validateEmail(e.target.value) && setEmailError(true)}
          error={emailError && loginData.email.length !== 0}
          required
        />
        {emailError && loginData.email.length !== 0 && (
          <ErrorMessage style={{ margin: "-1rem 0 1rem", fontSize: "12px" }}>{t("auth.enterEmail")}</ErrorMessage>
        )}
        <label htmlFor="loginPassword">{t("auth.password")}</label>
        <UserInputField
          placeholder={t("auth.enterEmailPlaceholder")}
          value={loginData.password}
          onChange={handleChange}
          type="password"
          name="password"
          autoComplete="off"
          required
          noMargin
          showEye
        />
        <Link className="signin_forgotpassword" to="/forgot_password">
          {t("auth.forgotPassword")}
        </Link>
        <FormButton type="submit" disabled={loading}>
          {t("auth.login")}
        </FormButton>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <RegistrationText>
          {t("auth.doNotHaveAccount")} <Link to="/signup">{t("auth.register")}</Link>
        </RegistrationText>
      </div>
    </LoginForm>
  );
}

export default Login;
