import React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Button from "../Components/Button";
import { breakPoints } from "../Theme";

const Wrapper = styled.div`
  width: 100%;
  max-width: 550px;
  color: ${(props) => props.theme.default_color};
  padding: 0 1.5rem;
  h2 {
    margin: 4rem 0 1rem;
    text-align: center;
  }
  .stakeerror_section {
    background: ${(props) => props.theme.earnLabelBg};
    border-radius: 6px;
    padding: 1.5rem 1.5rem 3rem;
    border: 1px solid ${(props) => props.theme.borderBg};
    p {
      margin: 0;
    }
    .stakeerror_buttons {
      margin-top: 2rem;
      display: flex;
      a {
        flex: 1;
        :first-of-type {
          margin-right: 2rem;
          button {
            background: ${(props) => props.theme.input_placefolder};
          }
        }
      }
      button {
        width: 100%;
        font-size: 16px;
        text-transform: capitalize;
        height: max-content;
        padding: 10px 0;
        line-height: 19.5px;
      }
      @media (max-width: ${breakPoints.md}px) {
        flex-direction: column;
        button {
          margin: 0;
          :first-of-type {
            margin: 0 0 1rem 0;
          }
        }
      }
    }
  }
`;

const StakeError = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <h2>{t("common.credentials")}</h2>
      <div className="stakeerror_section">
        <p>{t("common.stakeError")}</p>
        <div className="stakeerror_buttons">
          <a
            href="https://credentials.keyfi.com/#/auth/signup/5fa8f97dd6a5311512b54c5f"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Button primary>{t("common.applyNow")}</Button>
          </a>

          <a
            href="https://app.uniswap.org/#/swap?inputCurrency=0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48&outputCurrency=0xb8647e90c0645152fccf4d9abb6b59eb4aa99052&use=V2"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Button primary>
              {t("common.buy")} KEYFI {t("common.token")}
            </Button>
          </a>
        </div>
      </div>
    </Wrapper>
  );
};

export default StakeError;
