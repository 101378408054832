import React, { Fragment, useEffect, useState } from "react";

import Tippy from "@tippyjs/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as DeleteIcon } from "../../../assets/alerts/delete.svg";
import { AlertListItem, ToggleButtonWrapper } from "../../../Pages/Alerts/Alerts.styles";
import { iconHandler, subtitleHandler, titleHandler } from "../../../Pages/Alerts/alertsHelpers";
import { alertsOperations } from "../../../redux/alertsSlice";
import CreateAlertModal from "../../Alerts/CreateAlertModal";
import Loader from "../../Loader";
import WidgetWrapper from "./WidgetWrapper";

const Wrapper = styled.div`
  margin-top: 1.2rem !important;
  padding-top: 1px;
  height: 100%;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.scroleThumb};
    border-radius: 10px;
  }
  .alerts_widget_no_alerts {
    color: ${(props) => props.theme.lightGrey};
  }
`;

const ToggleButton = ({ name, checked, onClick }) => (
  <Tippy content={<span style={{ fontSize: "10px" }}>{checked ? "Active" : "Disabled"}</span>}>
    <ToggleButtonWrapper>
      <input name={name} type="checkbox" checked={checked} onChange={onClick} />
      <span className="slider round"></span>
    </ToggleButtonWrapper>
  </Tippy>
);

const AlertsWidget = ({ setOpenModal, item }) => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const alerts = useSelector((state) => state.alerts);
  const { editIsLoading, alertsList, isLoading } = alerts;

  const [showDelete, setShowDelete] = useState("");

  useEffect(() => {
    if (!isLoading && alertsList.length === 0) {
      dispatch(alertsOperations.getAlerts());
    }
  }, [dispatch]);

  const handleDelete = async (id) => {
    await dispatch(alertsOperations.deleteAlert(id));
    setShowDelete("");
  };
  const handleShowDelete = (id) => {
    if (id === showDelete) {
      return setShowDelete("");
    }
    return setShowDelete(id);
  };

  return (
    <WidgetWrapper
      item={item}
      id="alerts"
      setOpenModal={setOpenModal}
      header={
        <div className="widget_info">
          {showModal && <CreateAlertModal handleCloseModal={() => setShowModal(false)} />}

          <Link to="/alerts">{t("dashboard.widget.myAlerts")}</Link>
          <button onClick={() => setShowModal(true)}>Create Alert</button>
        </div>
      }
      body={
        <Wrapper className="widget_main">
          {isLoading && <Loader style={{ marginTop: "3rem" }} />}
          {alertsList.length !== 0 ? (
            alertsList.map((item) => (
              <Fragment key={item._id}>
                <AlertListItem maxWidth selectedDeleteItem={showDelete === item._id}>
                  <div className="alert_list_title">
                    {iconHandler(item)}
                    <div>
                      {" "}
                      <h4>{titleHandler(item, t)}</h4>
                      <h6>{subtitleHandler(item, t)}</h6>{" "}
                    </div>
                  </div>
                  <div className="alert_list_buttons">
                    <ToggleButton
                      checked={item.status === "active"}
                      onClick={(e) =>
                        dispatch(
                          alertsOperations.updateAlert(item._id, { status: e.target.checked ? "active" : "disabled" })
                        )
                      }
                    />

                    <Tippy content={<span style={{ fontSize: "10px" }}>{t("dashboard.widget.cancel")}</span>}>
                      <DeleteIcon onClick={() => handleShowDelete(item._id)} />
                    </Tippy>
                  </div>
                </AlertListItem>
                {showDelete === item._id && (
                  <AlertListItem deleteBlock>
                    <h3 className="alert_list_delete_title">{t("dashboard.widget.deleteAlert")}</h3>
                    <p className="alert_list_delete_description">{t("dashboard.widget.deleteAlertDescription")}</p>
                    <div className="alert_list_buttons">
                      <button disabled={editIsLoading} onClick={() => handleDelete(item._id)}>
                        <DeleteIcon /> {t("dashboard.widget.delete")}
                      </button>
                      <button onClick={() => setShowDelete("")}>{t("dashboard.widget.cancel")}</button>
                    </div>
                  </AlertListItem>
                )}
              </Fragment>
            ))
          ) : (
            <h4 className="alerts_widget_no_alerts">You have no alerts</h4>
          )}
        </Wrapper>
      }
    />
  );
};

export default AlertsWidget;
