import styled, { css } from "styled-components";

export const CreateAlertWrapper = styled.div`
  margin-top: 1.5rem;
  border: 1px solid ${(props) => props.theme.researchBorder};
  border-radius: 6px;
`;

export const CreateAlertSection = styled.div`
  h2 {
    display: flex;
    align-items: center;
    padding: 13px 1rem;
    font-size: 16px;
    border-bottom: 1px solid ${(props) => props.theme.researchBorder};
    cursor: pointer;
    :first-of-type {
      border-radius: 6px 6px 0 0;
    }
    svg {
      margin-right: 0.75rem;
      max-height: 19px;
      max-width: 19px;
    }
    ${(props) =>
      props.isOpen &&
      css`
        background: ${props.theme.bg};
        color: ${props.theme.form_text};
        svg {
          path {
            fill: ${(props) => props.theme.form_text};
          }
        }
      `}
  }
  .create_alert_section_main {
    padding: 13px 1rem;
    border-bottom: 1px solid ${(props) => props.theme.researchBorder};
    background: ${(props) => props.theme.bg};
  }
  :last-of-type {
    h2 {
      border-bottom: ${(props) => !props.isOpen && "0px"};
    }
    .create_alert_section_main {
      padding: 13px 1rem;
      border: 0;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    }
  }
`;

export const ParametersContainer = styled.div`
  .params_buttons {
    padding-bottom: 0.75rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid ${(props) => props.theme.disabled};
    display: flex;
    justify-content: space-between;
    button {
      padding: 0.5rem 1rem;
      color: ${(props) => props.theme.mainDarkText};
      background: 0;
      border-radius: 6px;
      border: 0;
      :hover {
        background: ${(props) => props.theme.disabled};
      }
    }
    .params_button_selected {
      background: ${(props) => props.theme.brandBlue};
      color: white;
      :hover {
        background: ${(props) => props.theme.darkBrandBlue};
      }
    }
  }
`;

export const DateContainer = styled.div`
  .date_container_dates {
    display: flex;
    gap: 1.5rem;
    h6 {
      font-size: 12px;
      color: ${(props) => props.theme.lightGrey};
      margin-bottom: 0.5rem;
    }
    .react-datepicker {
      border: none;
      color: ${(props) => props.theme.mainDarkText};
      background: 0;

      .react-datepicker__triangle {
        display: none;
      }

      .react-datepicker__month-container {
        background: #232e3a;
        border: 1px solid ${(props) => props.theme.researchBorder};
        border-radius: 6px;
        .react-datepicker__header {
          background: #232e3a;
          color: ${(props) => props.theme.mainDarkText};
          border: 0px;
          .react-datepicker__day-name {
            color: ${(props) => props.theme.mainDarkText};
          }
        }

        .react-datepicker__day--weekend,
        .react-datepicker__day {
          background-color: ${(props) => props.theme.bg};
          color: ${(props) => props.theme.mainDarkText};
          border-radius: 6px;
          :hover {
            background: ${(props) => props.theme.darkBrandBlue};
          }
        }
        .react-datepicker__day--selected {
          background: #005ad3;
          color: ${(props) => props.theme.mainDarkText};
        }
        .react-datepicker__day--outside-month {
          color: ${(props) => props.theme.disabledText};
          background: transparent;
        }
        .react-datepicker__current-month {
          color: ${(props) => props.theme.mainDarkText};
        }
        .react-datepicker__day--disabled {
          background: 0;
          color: ${(props) => props.theme.disabledText};
        }
      }
    }
    .datePicker {
      border: 1px solid ${(props) => props.theme.researchBorder};
      border-radius: 6px;
      padding: 0.75rem 0.5rem;
      input::placeholder {
        color: ${(props) => props.theme.disabled};
      }
    }
  }
`;

export const CreateAlertButtons = styled.div`
  margin-top: 1.5rem;
  display: flex;
  gap: 2rem;
  button {
    padding: 0.75rem 2rem;
    :disabled {
      pointer-events: none;
    }
    :first-of-type {
      :disabled {
        background: ${(props) => props.theme.disabledText};
      }
    }
  }
`;

export const CheckboxComponent = styled.div`
  margin-bottom: 0.75rem;
  span {
    color: white;
    font-size: 14px;
    margin-left: 0.5rem;
  }
`;

export const CheckboxInputContainer = styled.div`
  h4 {
    font-size: 12px;
    color: ${(props) => props.theme.lightGrey};
    margin-bottom: 0.5rem;
  }
  input {
    padding: 11px 1rem;
    border: 1px solid ${(props) => props.theme.researchBorder};
    border-radius: 6px;
    max-width: 420px;
    width: 100%;
    margin-bottom: 0.75rem;
  }
  a {
    display: block;
    width: max-content;
    margin: 0.5rem 0 1rem;
    padding: 0.5rem 2rem;
    background: ${(props) => props.theme.brandBlue};
    color: white;
    border-radius: 6px;
    :hover {
      background: ${(props) => props.theme.darkBrandBlue};
    }
  }
`;
