import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const API = process.env.REACT_APP_PRO_API;

const initialState = {
  activeWatchlist: {},
  watchlistTokens: [],
  isFetching: false,
  error: null,
};

const dashboardWatchlist = createSlice({
  name: "dashboardWatchlist",
  initialState,
  reducers: {
    setActiveWatchlist(state, action) {
      return { ...state, activeWatchlist: action.payload };
    },
    setWatchlistTokens(state, action) {
      return { ...state, watchlistTokens: action.payload };
    },
    setIsFetching(state, action) {
      return { ...state, isFetching: action.payload };
    },
    setError(state, action) {
      return { ...state, error: action.payload };
    },
  },
});

export const dashboardWatchlistActions = dashboardWatchlist.actions;

const getRoot = (state) => state.watchlist;

export const dashboardWatchlistSelectors = {
  getActiveWatchlist: (state) => getRoot(state).watchlistTokens,
  getWatchlistTokens: (state) => getRoot(state).watchlistList,
};

export const dashboardWatchlistOperations = {
  getDashboardWatchlist: (watchlistId) => async (dispatch, getState) => {
    const isFetching = getState().dashboardWatchlist.isFetching;
    if (isFetching) {
      return;
    }
    try {
      dispatch(dashboardWatchlistActions.setIsFetching(true));
      dispatch(dashboardWatchlistActions.setError(null));
      dispatch(dashboardWatchlistActions.setActiveWatchlist({}));
      dispatch(dashboardWatchlistActions.setWatchlistTokens([]));

      const token = getState().userData.tokens.access.token;

      const config = {
        headers: {
          Authorization: token,
        },
      };

      const watchlist = await axios.get(`${API}/v1/watchlist/${watchlistId}`, config);
      dispatch(dashboardWatchlistActions.setActiveWatchlist(watchlist.data));
      dispatch(dashboardWatchlistActions.setIsFetching(false));
    } catch (err) {
      dispatch(dashboardWatchlistActions.setError(err.response.data.message || err.message));
      dispatch(dashboardWatchlistActions.setIsFetching(false));
    }
  },
};

export const dashboardWatchlistReducer = dashboardWatchlist.reducer;
