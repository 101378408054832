import React, { useEffect, useState } from "react";

import moment from "moment";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";

import { ReactComponent as CalendarIcon } from "../../assets/popicon/Calendar.svg";
import { ReactComponent as ChannelsIcon } from "../../assets/popicon/Connections.svg";
import { ReactComponent as ParametersIcon } from "../../assets/sidebar/rebalancer.svg";
import { alertsOperations } from "../../redux/alertsSlice";
import { createAlertActions, createAlertOperations } from "../../redux/createAlertSlice";
import { userDataOperations } from "../../redux/userDataSlice";
import { watchlistOperations } from "../../redux/watchlistSlice";
import { breakPoints } from "../../Theme/index";
import Modal from "../Modal";
import Checkbox from "../Signup/Checkbox";
import GasAlert from "./AlertType/GasAlert";
import InterestRateAlert from "./AlertType/InterestRateAlert";
import NewListingAlert from "./AlertType/NewListingAlert";
import PriceChangeAlert from "./AlertType/PriceChangeAlert";
import VolumeAlert from "./AlertType/VolumeAlert";
import {
  CheckboxComponent,
  CheckboxInputContainer,
  CreateAlertButtons,
  CreateAlertSection,
  CreateAlertWrapper,
  DateContainer,
  ParametersContainer,
} from "./CreateAlertModal.styles";

const CreateAlertModal = ({ handleCloseModal, editModal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const alertConstants = {
    interestRate: "interestRate",
    gas: "gas",
    price: "price",
    volume: "volume",
    newListing: "newListing",
  };

  const user = useSelector((state) => state.userData.user);
  const alerts = useSelector((state) => state.alerts);
  const { editAlert, editIsLoading } = alerts;
  const createAlert = useSelector((state) => state.createAlert);
  const { startDate, endDate, channels, isLoading } = createAlert;

  const [alertType, setAlertType] = useState(alertConstants.price);
  const [showEndDate, setShowEndDate] = useState(false);
  const [userData] = useState({
    telegram: user.telegram,
    discord_hook_id: user.discord_hook_id,
    discord_hook_token: user.discord_hook_token,
    sms: user.sms,
    inputsChanged: false,
  });
  const [showSections, setShowSections] = useState({
    params: true,
    date: false,
    channels: false,
  });

  const watchlistList = useSelector((state) => state.watchlist.watchlistList);

  useEffect(() => {
    if (!showEndDate) {
      dispatch(createAlertActions.setEndDate(null));
    }
  }, [dispatch, showEndDate]);

  useEffect(() => {
    if (watchlistList.length === 0) {
      dispatch(watchlistOperations.getWatchlistList());
    }
  }, [watchlistList, dispatch]);

  useEffect(() => {
    if (editModal && editAlert.endDate) {
      setShowEndDate(true);
    }
  }, [editAlert.endDate, editModal]);

  const isMobile = window.innerWidth < breakPoints.sm;

  const handleChannelsInput = (e) => {
    if (editModal) {
      return dispatch(alertsOperations.handleEditAlert(e.target.name, e.target.checked));
    }
    return dispatch(createAlertOperations.setChannels(e.target.name, e.target.checked));
  };

  const hanldleShowEndDate = (e) => {
    if (editModal && !e.target.checked) {
      dispatch(alertsOperations.handleEditAlert("endDate", null));
    }
    setShowEndDate((prev) => !prev);
  };

  const handleInput = (name, value) => {
    if (editModal) {
      return dispatch(alertsOperations.handleEditAlert(name, value));
    }
  };

  const handleCreateAlert = async () => {
    try {
      if (userData.inputsChanged) {
        await dispatch(userDataOperations.updateProfile(userData));
      }
      await dispatch(createAlertOperations.createAlert(alertType));
      return handleCloseModal();
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateAlert = async () => {
    try {
      if (userData.inputsChanged) {
        await dispatch(userDataOperations.updateProfile(userData));
      }
      await dispatch(alertsOperations.updateAlert(editAlert._id, editAlert));
      return handleCloseModal();
    } catch (err) {
      console.log(err);
    }
  };

  const alertTypeContainers = {
    price: <PriceChangeAlert editModal={editModal} />,
    gas: <GasAlert editModal={editModal} />,
    interestRate: <InterestRateAlert editModal={editModal} />,
    newListing: <NewListingAlert editModal={editModal} />,
    volume: <VolumeAlert editModal={editModal} />,
  };

  if (!editModal) {
    return (
      <Modal title={t("alert.newAlert")} maxwidth="700px" width="100%" handleCloseModal={handleCloseModal}>
        <CreateAlertWrapper>
          <CreateAlertSection isOpen={showSections.params}>
            <h2 onClick={() => setShowSections({ ...showSections, params: !showSections.params })}>
              <ParametersIcon /> {t("alert.parameters")}
            </h2>
            <ParametersContainer
              className="create_alert_section_main"
              style={{ display: showSections.params ? "block" : "none" }}
            >
              <div className="params_buttons">
                <button
                  className={alertType === alertConstants.price ? "params_button_selected" : ""}
                  onClick={() => setAlertType(alertConstants.price)}
                >
                  {isMobile ? t("alert.price") : t("alert.priceChange")}
                </button>

                <button
                  className={alertType === alertConstants.gas ? "params_button_selected" : ""}
                  onClick={() => setAlertType(alertConstants.gas)}
                >
                  {t("alert.gas")}
                </button>
                <button
                  className={alertType === alertConstants.interestRate ? "params_button_selected" : ""}
                  onClick={() => setAlertType(alertConstants.interestRate)}
                >
                  {isMobile ? t("alert.interest") : t("alert.interestRate")}
                </button>
                {/* <button
                className={alertType === alertConstants.newListing ? "params_button_selected" : ""}
                onClick={() => setAlertType(alertConstants.newListing)}
              >
                New listing
              </button> */}
                {/* <button
                  className={alertType === alertConstants.volume ? "params_button_selected" : ""}
                  onClick={() => setAlertType(alertConstants.volume)}
                >
                  Volume
                </button> */}
              </div>
              {alertTypeContainers[alertType]}
            </ParametersContainer>
          </CreateAlertSection>
          <CreateAlertSection isOpen={showSections.date}>
            <h2 onClick={() => setShowSections({ ...showSections, date: !showSections.date })}>
              <CalendarIcon /> {t("alert.activeTime")}
            </h2>
            <DateContainer
              className="create_alert_section_main"
              style={{ display: showSections.date ? "block" : "none" }}
            >
              <CheckboxComponent>
                <div>
                  <label>
                    <Checkbox checked={showEndDate} onChange={() => setShowEndDate((prev) => !prev)}></Checkbox>
                    <span>{t("alert.addEndDate")}</span>
                  </label>
                </div>
              </CheckboxComponent>
              <div className="date_container_dates">
                <div>
                  <h6>{t("alert.start")}</h6>
                  <DatePicker
                    wrapperClassName="datePicker"
                    name="startDate"
                    selected={startDate}
                    minDate={moment().toDate()}
                    onChange={(date) => dispatch(createAlertOperations.setStartDate(date))}
                  />
                </div>
                {showEndDate && (
                  <div>
                    <h6>{t("alert.end")}</h6>
                    <DatePicker
                      wrapperClassName="datePicker"
                      name="endDate"
                      selected={endDate}
                      minDate={startDate}
                      placeholderText={t("alert.pickADate")}
                      onChange={(date) => dispatch(createAlertActions.setEndDate(date))}
                    />
                  </div>
                )}
              </div>
            </DateContainer>
          </CreateAlertSection>
          <CreateAlertSection isOpen={showSections.channels}>
            <h2 onClick={() => setShowSections({ ...showSections, channels: !showSections.channels })}>
              <ChannelsIcon /> {t("alert.getYourAlert")}
            </h2>
            <div className="create_alert_section_main" style={{ display: showSections.channels ? "block" : "none" }}>
              <CheckboxComponent>
                <div>
                  <label>
                    <Checkbox name="email" checked={channels.email} onChange={handleChannelsInput} />
                    <span>{t("alert.email")}</span>
                  </label>
                </div>
              </CheckboxComponent>
              {channels.email && (
                <CheckboxInputContainer>
                  <h4>{t("alert.email")}</h4>
                  <input readOnly value={user.email} />
                </CheckboxInputContainer>
              )}
              <CheckboxComponent>
                <div>
                  <label>
                    <Checkbox name="telegram" checked={channels.telegram} onChange={handleChannelsInput} />
                    <span>Telegram</span>
                  </label>
                </div>
              </CheckboxComponent>
              {channels.telegram && (
                <CheckboxInputContainer>
                  <h4>{t("alert.receiveTelegramAlerts")}</h4>
                  <a href="https://t.me/KeyFi_Alert_Bot" target="_blank" rel="noopener noreferrer">
                    Telegram
                  </a>
                </CheckboxInputContainer>
              )}
              {/* <CheckboxComponent>
                <div>
                  <label>
                    <Checkbox name="discord" checked={channels.discord} onChange={handleChannelsInput} />
                    <span>Discord</span>
                  </label>
                </div>
              </CheckboxComponent>
              {channels.discord && (
                <>
                  <CheckboxInputContainer>
                    <h4>Discord hook ID</h4>
                    <input name="discord_hook_id" value={userData.discord_hook_id} onChange={handleChannelsUserInput} />
                  </CheckboxInputContainer>
                  <CheckboxInputContainer>
                    <h4>Discord hook token</h4>
                    <input
                      name="discord_hook_token"
                      value={userData.discord_hook_token}
                      onChange={handleChannelsUserInput}
                    />
                  </CheckboxInputContainer>
                </>
              )}
              <CheckboxComponent>
                <div>
                  <label>
                    <Checkbox name="sms" checked={channels.sms} onChange={handleChannelsInput} />
                    <span>SMS</span>
                  </label>
                </div>
              </CheckboxComponent>
              {channels.sms && (
                <>
                  <CheckboxInputContainer>
                    <h4>Phone number</h4>
                    <input name="sms" value={userData.sms} onChange={handleChannelsUserInput} />
                  </CheckboxInputContainer>
                </>
              )} */}
            </div>
          </CreateAlertSection>
        </CreateAlertWrapper>
        <CreateAlertButtons>
          <button
            disabled={isLoading || Object.values(createAlert[alertType]).some((item) => item === "")}
            className="primary"
            onClick={handleCreateAlert}
          >
            {isLoading ? "Creating..." : isMobile ? t("alert.create") : t("alert.createAlert")}
          </button>
          <button className="secondary" onClick={handleCloseModal}>
            {t("alert.cancel")}
          </button>
        </CreateAlertButtons>
      </Modal>
    );
  }
  return (
    <Modal title="Edit alert" maxwidth="700px" width="100%" handleCloseModal={handleCloseModal}>
      <CreateAlertWrapper>
        <CreateAlertSection isOpen={showSections.params}>
          <h2 onClick={() => setShowSections({ ...showSections, params: !showSections.params })}>
            <ParametersIcon /> {t("alert.parameters")}
          </h2>
          <ParametersContainer
            className="create_alert_section_main"
            style={{ display: showSections.params ? "block" : "none" }}
          >
            {alertTypeContainers[editAlert.type]}
          </ParametersContainer>
        </CreateAlertSection>
        <CreateAlertSection isOpen={showSections.date}>
          <h2 onClick={() => setShowSections({ ...showSections, date: !showSections.date })}>
            <CalendarIcon /> {t("alert.activeTime")}
          </h2>
          <DateContainer
            className="create_alert_section_main"
            style={{ display: showSections.date ? "block" : "none" }}
          >
            <CheckboxComponent>
              <div>
                <label>
                  <Checkbox checked={showEndDate} onChange={(e) => hanldleShowEndDate(e)}></Checkbox>
                  <span>{t("alert.addEndDate")}</span>
                </label>
              </div>
            </CheckboxComponent>
            <div className="date_container_dates">
              <div>
                <h6>{t("alert.start")}</h6>
                <DatePicker
                  wrapperClassName="datePicker"
                  name="startDate"
                  selected={new Date(editAlert.startDate)}
                  minDate={moment().toDate()}
                  onChange={(date) => handleInput("startDate", date)}
                />
              </div>
              {showEndDate && (
                <div>
                  <h6>{t("alert.end")}</h6>
                  <DatePicker
                    wrapperClassName="datePicker"
                    name="endDate"
                    selected={editAlert.endDate ? new Date(editAlert.endDate) : new Date(editAlert.startDate)}
                    minDate={new Date(editAlert.startDate)}
                    placeholderText={t("alert.pickADate")}
                    onChange={(date) => handleInput("endDate", date)}
                  />
                </div>
              )}
            </div>
          </DateContainer>
        </CreateAlertSection>
        <CreateAlertSection isOpen={showSections.channels}>
          <h2 onClick={() => setShowSections({ ...showSections, channels: !showSections.channels })}>
            <ChannelsIcon /> {t("alert.getYourAlert")}
          </h2>
          <div className="create_alert_section_main" style={{ display: showSections.channels ? "block" : "none" }}>
            <CheckboxComponent>
              <div>
                <label>
                  <Checkbox name="email" checked={editAlert.email} onChange={handleChannelsInput} />
                  <span>{t("alert.email")}</span>
                </label>
              </div>
            </CheckboxComponent>
            {editAlert.email && (
              <CheckboxInputContainer>
                <h4>{t("alert.email")}</h4>
                <input readOnly value={user.email} />
              </CheckboxInputContainer>
            )}
            <CheckboxComponent>
              <div>
                <label>
                  <Checkbox name="telegram" checked={editAlert.telegram} onChange={handleChannelsInput} />
                  <span>Telegram</span>
                </label>
              </div>
            </CheckboxComponent>
            {editAlert.telegram && (
              <CheckboxInputContainer>
                <h4>{t("alert.receiveTelegramAlerts")}</h4>
                <a href="https://t.me/KeyFi_Alert_Bot" target="_blank" rel="noopener noreferrer">
                  Telegram
                </a>
              </CheckboxInputContainer>
            )}
            {/* <CheckboxComponent>
              <div>
                <label>
                  <Checkbox name="discord" checked={editAlert.discord} onChange={handleChannelsInput} />
                  <span>Discord</span>
                </label>
              </div>
            </CheckboxComponent>
            {editAlert.discord && (
              <>
                <CheckboxInputContainer>
                  <h4>Discord hook ID</h4>
                  <input name="discord_hook_id" value={userData.discord_hook_id} onChange={handleChannelsUserInput} />
                </CheckboxInputContainer>
                <CheckboxInputContainer>
                  <h4>Discord hook token</h4>
                  <input
                    name="discord_hook_token"
                    value={userData.discord_hook_token}
                    onChange={handleChannelsUserInput}
                  />
                </CheckboxInputContainer>
              </>
            )}
            <CheckboxComponent>
              <div>
                <label>
                  <Checkbox name="sms" checked={editAlert.sms} onChange={handleChannelsInput} />
                  <span>SMS</span>
                </label>
              </div>
            </CheckboxComponent>
            {editAlert.sms && (
              <>
                <CheckboxInputContainer>
                  <h4>Phone number</h4>
                  <input name="sms" value={userData.sms} onChange={handleChannelsUserInput} />
                </CheckboxInputContainer>
              </>
            )} */}
          </div>
        </CreateAlertSection>
      </CreateAlertWrapper>
      <CreateAlertButtons>
        <button className="primary" onClick={handleUpdateAlert} disabled={editIsLoading}>
          {editIsLoading ? t("alert.saving") : t("alert.saveAlert")}
        </button>
        <button className="secondary" onClick={handleCloseModal}>
          {t("alert.cancel")}
        </button>
      </CreateAlertButtons>
    </Modal>
  );
};

export default CreateAlertModal;
