import { useSelector } from "react-redux";

import { walletSelectors } from "../redux/walletSlice";

export function useWallet() {
  const address = useSelector(walletSelectors.getSelectedAddress);
  const providerId = useSelector(walletSelectors.getProviderId);
  const network = useSelector(walletSelectors.getNetwork);

  return {
    address,
    providerId,
    network,
  };
}
