import React, { useEffect, useMemo, useState } from "react";

import { liquidity } from "@keyfi/integrations/dist/univ2/index.mjs";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as PlusSign } from "../../../assets/plus.svg";
import { beautifyTokensAmount, networkTokenList } from "../../../helpers";
import Token from "../../../helpers/token";
import { walletOperations } from "../../../redux/walletSlice";
import TokenInput from "../TokenInput";
import { AddLiquidityBody, ErrorMessageLiquidity } from "./integrations.styles";

const UniV2AddLiquidity = ({ selectedPlatform }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const network = selectedPlatform.network;
  const userTokens = dispatch(walletOperations.getWalletNetworkTokens(network));

  const wallet = useSelector((state) => state.wallet);
  const { web3 } = wallet;

  const [supportedAssets, setSupportedAssets] = useState([]);
  const [inputData, setInputData] = useState({
    input1: "",
    asset1: selectedPlatform.defaultSelected[0],
    input2: "",
    asset2: selectedPlatform.defaultSelected[1],
    whichInputChanged: null,
    isFetching: false,
    shareOfPool: 0,
    tokenPrice1: 0,
    tokenPrice2: 0,
  });

  const [errorData, setErrorData] = useState({
    pairExists: true,
  });

  const { input1, asset1, input2, asset2, whichInputChanged, shareOfPool, tokenPrice1, tokenPrice2 } = inputData;
  const { pairExists } = errorData;

  const insufficientInput1 = useMemo(() => parseFloat(input1) > (userTokens[asset1.symbol] || 0), [input1, asset1]);
  const insufficientInput2 = useMemo(() => parseFloat(input2) > (userTokens[asset2.symbol] || 0), [input2, asset2]);
  const emptyInputs = useMemo(
    () => parseFloat(input1) <= 0 || parseFloat(input2) <= 0 || !input1 || !input1,
    [input1, input2]
  );

  // console.log(asset1, asset2);

  useEffect(() => {
    setInputData((prev) => ({
      ...prev,
      asset1: selectedPlatform.defaultSelected[0],
      asset2: selectedPlatform.defaultSelected[1],
    }));
  }, [selectedPlatform.id]);

  // Get supported assets once selected platform
  useEffect(() => {
    const fetchSupportedAssets = async () => {
      let tokens = networkTokenList[network];
      tokens = tokens.map((item) => {
        return new Token(network, item.id, item.symbol, item.decimals);
      });
      setSupportedAssets(tokens);
    };
    if (selectedPlatform && wallet.network.name) {
      fetchSupportedAssets();
      if (!asset1 && !asset2) {
        setInputData((prev) => ({
          ...prev,
          asset1: selectedPlatform.defaultSelected[0],
          asset2: selectedPlatform.defaultSelected[1],
        }));
      }
    }
  }, [selectedPlatform, wallet.network.name, asset1, asset2]);

  // Get liquidity price
  useEffect(() => {
    if (!asset1 || !asset2) return;
    if (+input1 && whichInputChanged === "input1") {
      setInputData((prev) => ({ ...prev, isFetching: true }));
      liquidity
        .getPrice(web3, network, selectedPlatform.id, asset1, asset2, input1)
        .then((data) => {
          setInputData((prevState) => ({
            ...prevState,
            input2: Number(input1) ? Number(data.price) * Number(input1) : "",
            shareOfPool: data.shareOfPool,
            tokenPrice1: data.tokenPrice1,
            tokenPrice2: data.tokenPrice2,
            whichInputChanged: null,
          }));
          setInputData((prev) => ({ ...prev, isFetching: false }));
          setErrorData((prev) => ({ ...prev, pairExists: true }));
        })
        .catch(() => setErrorData((prev) => ({ ...prev, pairExists: false })));
    } else if (+input2 && whichInputChanged === "input2") {
      setInputData((prev) => ({ ...prev, isFetching: true }));
      liquidity
        .getPrice(web3, network, selectedPlatform.id, asset1, asset2, input2, true)
        .then((data) => {
          setInputData((prevState) => ({
            ...prevState,
            input1: Number(input2) ? Number(data.price) * Number(input2) : "",
            shareOfPool: data.shareOfPool,
            tokenPrice1: data.tokenPrice1,
            tokenPrice2: data.tokenPrice2,
            whichInputChanged: null,
            isFetching: false,
          }));

          setErrorData((prev) => ({ ...prev, pairExists: true }));
        })
        .catch((err) => {
          console.log(err);
          setErrorData((prev) => ({ ...prev, pairExists: false }));
        });
    }
  }, [inputData.input1, inputData.input2, inputData.asset1, inputData.asset2]);

  const handleInput = (name, value) => {
    if (value === "") {
      setInputData((prevState) => ({
        ...prevState,
        whichInputChanged: name,
        input1: "",
        input2: "",
      }));
    } else {
      setInputData((prevState) => ({
        ...prevState,
        [name]: value,
        whichInputChanged: name,
      }));
    }
  };

  // Handle max input
  const handleMax = (name) => {
    setInputData((prev) => ({
      ...prev,
      [name]: userTokens[name === "input1" ? asset1.symbol : asset2.symbol] ?? 0,
      whichInputChanged: name,
    }));
  };

  const handleAddLiquidity = async () => {
    if (Number(input1) && Number(input2)) {
      try {
        const response = await liquidity.addLiquidity(
          web3,
          network,
          selectedPlatform.id,
          asset1,
          input1,
          asset2,
          input2
        );
        console.log(response);
        if (response) {
          dispatch(walletOperations.loadUserData(0, true));
        }
      } catch (err) {
        console.log(err);
      }
    }

    setInputData((prevState) => {
      return {
        ...prevState,
        input1: "",
        input2: "",
      };
    });
  };

  return (
    <AddLiquidityBody>
      <div className="add-liquidity-input-wrapper">
        <div className="add-liquidity-input-labels">
          <span>{t("common.input")}</span>
          <span>
            {t("common.balance")}: {beautifyTokensAmount(userTokens[inputData.asset1.symbol])} {inputData.asset1.symbol}
          </span>
        </div>

        <TokenInput
          name="input1"
          asset="asset1"
          network={network}
          selectedToken={inputData.asset1}
          updateParent={handleInput}
          value={inputData.input1}
          options={supportedAssets}
          removeToken={inputData.asset2}
          invokeMaxInput={handleMax}
          error={insufficientInput1 || !pairExists}
        />
        {insufficientInput1 && (
          <ErrorMessageLiquidity>
            {t("common.not enough")} {asset1.symbol}
          </ErrorMessageLiquidity>
        )}
        {!pairExists && <ErrorMessageLiquidity>{t("common.invalidPair")}</ErrorMessageLiquidity>}
      </div>
      <div className="add-liquidity-plus">
        <PlusSign />
      </div>

      <div className="add-liquidity-input-wrapper" style={{ marginBottom: "0" }}>
        <div className="add-liquidity-input-labels">
          <span>{t("common.input")}</span>
          <span>
            {t("common.balance")}: {beautifyTokensAmount(userTokens[inputData.asset2.symbol])} {inputData.asset2.symbol}
          </span>
        </div>

        <TokenInput
          name="input2"
          asset="asset2"
          network={network}
          selectedToken={inputData.asset2}
          updateParent={handleInput}
          value={inputData.input2}
          userTokens={userTokens}
          options={supportedAssets}
          removeToken={inputData.asset1}
          invokeMaxInput={handleMax}
          error={insufficientInput2 || !pairExists}
        />
        {insufficientInput2 && (
          <ErrorMessageLiquidity>
            {t("common.not enough")} {asset2.symbol}
          </ErrorMessageLiquidity>
        )}
      </div>
      {pairExists && parseFloat(inputData.input1) > 0 && parseFloat(inputData.input2) > 0 && (
        <div className="add-liquidity-data">
          <h2>{t("common.Prices and pool share")}</h2>
          <div className="add-liquidity-data-box">
            <div className="add-liquidity-data-snippet">
              <h4>{beautifyTokensAmount(tokenPrice2)}</h4>
              <h6>
                {asset1.symbol} per {asset2.symbol}
              </h6>
            </div>
            <div className="add-liquidity-data-snippet">
              <h4>{beautifyTokensAmount(tokenPrice1)}</h4>
              <h6>
                {asset2.symbol} per {asset1.symbol}
              </h6>
            </div>
            <div className="add-liquidity-data-snippet">
              <h4>{shareOfPool * 100 < 0.01 ? "<0.01" : (shareOfPool * 100).toFixed(2)}%</h4>
              <h6>{t("common.Share of pool")}</h6>
            </div>
          </div>
        </div>
      )}

      <button
        onClick={handleAddLiquidity}
        className="add-liquidity-button"
        disabled={!pairExists || insufficientInput1 || insufficientInput2 || emptyInputs}
      >
        {t("common.addLiquidity")}
      </button>
    </AddLiquidityBody>
  );
};

export default UniV2AddLiquidity;
