import React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Wrapper = styled.div`
  position: absolute;
  top: 120%;
  right: 0;
  width: 210px;
  background: ${(props) => props.theme.secondaryBg};
  border: 1px solid ${(props) => props.theme.researchBorder};
  border-radius: 6px;
  padding: 0.5rem 0;
  z-index: 2;
  ::after {
    z-index: 9;
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 100%;
    height: 9px;
    width: 100%;
  }
  > button {
    display: flex;
    align-items: center;
    padding: 0.5rem 1.5rem;
    border: 0;
    background: 0;
    width: 100%;
    text-align: left;
    font-size: 16px;
    color: ${(props) => props.theme.mainDarkText};

    :hover {
      background: ${(props) => props.theme.bg};
    }
    svg {
      margin-right: 0.5rem;
    }
    :disabled {
      color: ${(props) => props.theme.lightGrey};
      pointer-events: none;
      cursor: default;
    }
  }
`;

const SettingsCard = ({ openLanguage, languageData }) => {
  const { i18n } = useTranslation();
  return (
    <Wrapper>
      <button onClick={openLanguage}>Language - {languageData[i18n.languages[0]].title}</button>
      <button disabled>Currency - $ USD</button>
    </Wrapper>
  );
};

export default SettingsCard;
