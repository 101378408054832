import React from "react";

import { SpinningLoader } from "../Shared";

const Loader = ({ style, className }) => {
  return (
    <SpinningLoader className={className} style={style}>
      <div className="profile-main-loader">
        <div className="loader">
          <svg className="circular-loader" viewBox="25 25 50 50">
            <circle className="loader-path" cx="50" cy="50" r="20" fill="none" stroke="#005AD3" strokeWidth="2" />
          </svg>
        </div>
      </div>
    </SpinningLoader>
  );
};

export default Loader;
