import React, { useState } from "react";

import { integrations } from "@keyfi/keyfi-common";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import timestamp from "time-stamp";

import { beautifyTokensAmount, calculateAPY, getAPYRatesOnPlatform, poolsLogos, tokensLogos } from "../../../helpers";
import { Storage } from "../../../helpers/Storage";
import {
  DepositContainer,
  DepositInput,
  DepositInputContainer,
  DepositMain,
  VersionButtons,
} from "../../../Pages/Deposit/Deposit.styles";
import { addTx, removeTx, showTransactionManager } from "../../../redux/transactionManager/actions";
import { walletOperations } from "../../../redux/walletSlice";
import DepositDropdown from "../../Deposit/DepositDropdown";
import Dropdownv2 from "../../DropDownV2";
import { getTxHistory } from "../../TransactionManager";

// Assets

const platforms = [
  {
    id: "compound",
    text: "Compound",
    network: "mainnet",
    img: poolsLogos.Compound,
  },
  {
    id: "aave",
    text: "Aave",
    network: "mainnet",
    img: poolsLogos["Aave V2"],
    versions: ["v1", "v2"],
  },
  {
    id: "dydx",
    text: "dYdX",
    network: "mainnet",
    img: poolsLogos.dYdX,
  },
];

const DashboardDepositContainer = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [platform, setPlatform] = useState(platforms[0].id);
  const [version, setVersion] = useState("v1");

  const selectedPlatform = platforms.find((item) => item.id === platform);

  const [inputData, setInputData] = useState({
    deposit: "",
    selectdeposit: "ETH",
    withdraw: "",
    selectwithdraw: "ETH",
  });

  const user = useSelector((state) => state.user);
  const rates = useSelector((state) => state.rates.rates);
  const wallet = useSelector((state) => state.wallet);
  const { network, staking } = wallet;

  const userPlatformData = dispatch(
    walletOperations.getNetworkStakedTokens(
      selectedPlatform.network,
      selectedPlatform.id,
      true,
      selectedPlatform.versions ? version : undefined
    )
  );

  const stakedValue = userPlatformData[inputData.selectwithdraw] ?? 0;
  const userTokens = dispatch(walletOperations.getWalletNetworkTokens(selectedPlatform.network));

  const totalStakedValue = dispatch(
    walletOperations.getNetworkPlatformValue(
      selectedPlatform.network,
      selectedPlatform.id,
      false,
      selectedPlatform.versions ? version : undefined
    )
  );

  const handleChange = (e, name) => {
    const re = /^\d*\.?\d*$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setInputData((prev) => ({ ...prev, [name]: e.target.value }));
    }
  };

  const handleSetWithdraw = (token) => {
    setInputData((prev) => ({
      ...prev,
      selectwithdraw: token,
    }));
  };

  const handleMax = (input) => {
    if (input === "deposit") {
      setInputData((prev) => ({
        ...prev,
        deposit: userTokens[inputData.selectdeposit] ?? 0,
      }));
    } else {
      setInputData((prev) => ({
        ...prev,
        withdraw: userPlatformData[inputData.selectwithdraw] ?? "0",
      }));
    }
  };

  const handleDeposit = async () => {
    const { deposit, selectdeposit } = inputData;
    const maxBalance = userTokens[inputData.selectdeposit] ?? 0;

    const getMainFunction = () => {
      if ("versions" in selectedPlatform) {
        return integrations[selectedPlatform.id][version];
      }
      return integrations[selectedPlatform.id];
    };

    if (Number(deposit) && selectdeposit && Number(maxBalance) >= Number(deposit)) {
      let txHash = [];
      let isFirstTrxFired = false;

      try {
        const mainFunction = getMainFunction();

        const res = await mainFunction.deposit(selectdeposit, deposit, {
          pendingCallback: (trx) => {
            if (!isFirstTrxFired) {
              dispatch(showTransactionManager());
              dispatch(
                addTx({
                  platform: selectedPlatform.text,
                  txType: "Deposit",
                  txAmount: Number(deposit),
                  txToken: selectdeposit,
                  txHash: trx.hash,
                  timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
                  status: "pending",
                })
              );
              isFirstTrxFired = true;
            }

            txHash.push(trx.hash);
          },
        });
        dispatch(walletOperations.loadUserData());
        const HistoryArray = getTxHistory();
        HistoryArray.unshift({
          platform: selectedPlatform.text,
          txType: "Deposit",
          txAmount: Number(deposit),
          txToken: selectdeposit,
          txHash: res.transactionHash,
          timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
        });
        Storage.setItem("transactions", JSON.stringify(HistoryArray));
        dispatch(removeTx(txHash));
      } catch (err) {
        console.log(err);
        const HistoryArray = getTxHistory();
        dispatch(showTransactionManager());

        HistoryArray.unshift({
          platform: selectedPlatform.text,
          txType: "Deposit",
          txAmount: Number(deposit),
          txToken: selectdeposit,
          txHash: txHash[txHash.length - 1],
          timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
          status: "failed",
        });
        Storage.setItem("transactions", JSON.stringify(HistoryArray));
        dispatch(removeTx(txHash));
      }
    }

    // reset inputs
    setInputData((prevState) => {
      return {
        ...prevState,
        deposit: "",
        withdraw: "",
      };
    });
  };

  const handleWithdraw = async () => {
    const { withdraw, selectwithdraw } = inputData;

    if (Number(withdraw) && selectwithdraw && Number(stakedValue) >= Number(withdraw)) {
      const getMainFunction = () => {
        if (Object.keys(staking[network.name][selectedPlatform.id]).length === 1) {
          return integrations[selectedPlatform.id];
        }
        return integrations[selectedPlatform.id][version];
      };
      const txHash = [];
      let isFirstTrxFired = false;

      try {
        const res = await getMainFunction().withdraw(selectwithdraw, withdraw, {
          pendingCallback: (trx) => {
            if (!isFirstTrxFired) {
              dispatch(showTransactionManager());
              dispatch(
                addTx({
                  platform: selectedPlatform.text,
                  txType: "Withdraw",
                  txAmount: Number(withdraw),
                  txToken: selectwithdraw,
                  txHash: trx.hash,
                  timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
                })
              );
              isFirstTrxFired = true;
            }
            txHash.push(trx.hash);
          },
        });
        dispatch(walletOperations.loadUserData());
        const HistoryArray = getTxHistory();
        HistoryArray.unshift({
          platform: selectedPlatform.text,
          txType: "Withdraw",
          txAmount: Number(withdraw),
          txToken: selectwithdraw,
          txHash: res.transactionHash,
          timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
        });
        Storage.setItem("transactions", JSON.stringify(HistoryArray));
        dispatch(removeTx(txHash));
      } catch (err) {
        const HistoryArray = getTxHistory();
        dispatch(showTransactionManager());

        HistoryArray.unshift({
          platform: selectedPlatform.text,
          txType: "Withdraw",
          txAmount: Number(withdraw),
          txToken: selectwithdraw,
          txHash: txHash[txHash.length - 1],
          timeStamp: timestamp("YYYY/MM/DD HH:mm:ss"),
          status: "failed",
        });
        Storage.setItem("transactions", JSON.stringify(HistoryArray));
        dispatch(removeTx(txHash));
      }
    }
    // reset inputs
    setInputData((prevState) => {
      return {
        ...prevState,
        deposit: "",
        withdraw: "",
      };
    });
  };

  return (
    <DepositContainer>
      <div className="deposit_header">
        <h4>{t("common.chooseAplatform")}:</h4>
        <Dropdownv2
          inputHeight="28px"
          className="deposit_platform_dropdown"
          options={platforms}
          selectedOption={selectedPlatform.id}
          setOption={(value) => setPlatform(value)}
        />
        {network && network.name && selectedPlatform.versions && selectedPlatform.versions.length > 1 && (
          <VersionButtons>
            {selectedPlatform.versions.map((item) => (
              <button
                key={item}
                className={version === item ? "deposit_version_active" : ""}
                onClick={() => setVersion(item)}
              >
                {item.toUpperCase()}
              </button>
            ))}
          </VersionButtons>
        )}
      </div>
      <DepositMain>
        <div className="deposit_data">
          {t("common.netAPY")}:{" "}
          <span>
            {beautifyTokensAmount(
              calculateAPY(userTokens, user.usdPrices, getAPYRatesOnPlatform(rates, selectedPlatform.id)),
              {
                suffix: "%",
              }
            )}
          </span>
        </div>
        <div className="deposit_data">
          {t("common.supplyBalance")}: <span>{beautifyTokensAmount(totalStakedValue, { prefix: "$" })}</span>
        </div>
        <div className="deposit_tokens">
          {Object.entries(userPlatformData).length !== 0 &&
            Object.entries(userPlatformData).map(([key, value]) => (
              <div key={key} className="deposit_token" onClick={() => handleSetWithdraw(key)}>
                {tokensLogos[key] ?? "?"}
                <span className="deposit_token_title">{key}</span>
                <span className="deposit_token_amount">{beautifyTokensAmount(value)}</span>
              </div>
            ))}
        </div>
        <DepositInputContainer>
          <div className="deposit_input">
            <div className="deposit_input_data">
              <span>{t("common.supply")}</span>
              <span>
                {t("common.wallet")}:{" "}
                {beautifyTokensAmount(userTokens[inputData.selectdeposit] ?? 0, {
                  suffix: ` ${inputData.selectdeposit}`,
                })}
              </span>
            </div>
            <div className="deposit_input_error">
              {Number(inputData.deposit) > (userTokens[inputData.selectdeposit] ?? 0) &&
                `${t("common.insufficient")} ${inputData.selectdeposit} ${t("common.balance")}`}
            </div>
            <DepositInput error={Number(inputData.deposit) > (userTokens[inputData.selectdeposit] ?? 0)}>
              <input onChange={(e) => handleChange(e, "deposit")} value={inputData.deposit} />
              <button onClick={() => handleMax("deposit")}>{t("common.max")}</button>
              <DepositDropdown
                name="selectdeposit"
                userTokens={userTokens}
                userPlatformData={userPlatformData}
                version={version}
                selected={inputData.selectdeposit}
                platform={selectedPlatform.id}
                network={selectedPlatform.network}
                onSelect={(symbol) => setInputData((prev) => ({ ...prev, selectdeposit: symbol, deposit: "" }))}
              />
            </DepositInput>
            <button
              disabled={!inputData.deposit || (Number(inputData.deposit) > userTokens[inputData.selectdeposit] ?? 0)}
              onClick={handleDeposit}
            >
              {t("deposit.title")}
            </button>
          </div>
          <div className="deposit_input">
            <div className="deposit_input_data">
              <span>{t("deposit.withdraw")}</span>
              <span>
                {selectedPlatform.text}: {beautifyTokensAmount(stakedValue, { suffix: ` ${inputData.selectwithdraw}` })}
              </span>
            </div>
            <div className="deposit_input_error">
              {Number(inputData.withdraw) > stakedValue &&
                `${t("common.insufficient")} ${inputData.selectwithdraw} ${t("common.balance")}`}
            </div>
            <DepositInput error={Number(inputData.withdraw) > stakedValue}>
              <input onChange={(e) => handleChange(e, "withdraw")} value={inputData.withdraw} />
              <button onClick={() => handleMax("withdraw")}>{t("common.max")}</button>
              <DepositDropdown
                name="selectwithdraw"
                userTokens={userTokens}
                userPlatformData={userPlatformData}
                selected={inputData.selectwithdraw}
                network={selectedPlatform.network}
                platform={selectedPlatform.id}
                version={version}
                onSelect={(symbol) => setInputData((prev) => ({ ...prev, selectwithdraw: symbol, withdraw: "" }))}
              />
            </DepositInput>{" "}
            <button disabled={!inputData.withdraw || Number(inputData.withdraw) > stakedValue} onClick={handleWithdraw}>
              {t("deposit.withdraw")}
            </button>
          </div>
        </DepositInputContainer>
      </DepositMain>
    </DepositContainer>
  );
};

export default DashboardDepositContainer;
