import React from "react";

import styled from "styled-components";

// Assets
import { ReactComponent as Arrow } from "../assets/arrow-blue.svg";
import { ReactComponent as LastArrow } from "../assets/last-arrow.svg";

const Wrapper = styled.div`
  display: flex;
  gap: 0.25rem;
  button,
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    background: ${(props) => props.theme.bg};
    border: 1px solid ${(props) => props.theme.researchBorder};
    border-radius: 6px;
  }
  button {
    cursor: pointer;
    svg {
      height: 11px;
      path,
      rect {
        fill: ${(props) => props.theme.mainDarkText};
      }
    }
    :disabled {
      pointer-events: none;
      svg {
        path,
        rect {
          fill: ${(props) => props.theme.researchBorder};
        }
      }
    }
  }
  div,
  button:hover {
    background: ${(props) => props.theme.darkGrey};
  }
`;

// Pagination always starts from 0
const Pagination = ({ page, lastPage, setPage, disabledPagination, className }) => {
  return (
    <Wrapper className={className}>
      <button disabled={page === 1 || disabledPagination} onClick={() => setPage(1)}>
        <LastArrow style={{ transform: "rotate(180deg)" }} />
      </button>
      <button disabled={page === 1 || disabledPagination} onClick={() => setPage(page - 1)}>
        <Arrow style={{ transform: "rotate(180deg)" }} />
      </button>
      <div>{page}</div>
      <button disabled={page === lastPage || disabledPagination} onClick={() => setPage(page + 1)}>
        <Arrow />
      </button>
      <button disabled={page === lastPage || disabledPagination} onClick={() => setPage(lastPage)}>
        <LastArrow />
      </button>
    </Wrapper>
  );
};

export default Pagination;
