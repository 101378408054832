import React, { useEffect, useState } from "react";

import { integrations } from "@keyfi/keyfi-common";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { tokensLogos } from "../../../helpers";
import { strategyActionOperations } from "../../../redux/strategyActionsSlice";
import { strategyGroupOperations } from "../../../redux/strategyGroupSlice";
import StrategyDropdown from "../StrategyDropdown";

const Wrapper = styled.div``;

const platformsList = {
  KeyFi: () => [
    { id: "KEYFI", text: "KEYFI" },
    { id: "ETH/KEYFI", text: "ETH/KEYFI" },
    { id: "USDC", text: "USDC" },
    { id: "wETH", text: "wETH" },
    { id: "wBTC", text: "wBTC" },
  ],
  Aave: async () => {
    const tokens = await integrations.aave.v2.getSupportedAssets();

    return tokens.map((option) => ({
      id: option,
      img: tokensLogos[option] || <h2 style={{ textAlign: "center", width: "32px" }}>?</h2>,
      text: option,
    }));
  },
  Compound: async () => {
    const tokens = await integrations.compound.getSupportedAssets();

    return tokens.map((option) => ({
      id: option,
      img: tokensLogos[option] || <h2 style={{ textAlign: "center", width: "32px" }}>?</h2>,
      text: option,
    }));
  },
};

const getPlatforms = async (name) => {
  if (name === "KeyFi") return platformsList.KeyFi();
  if (name === "Aave") return platformsList.Aave();
  if (name === "Compound") return platformsList.Compound();
  return [];
};

const DepositAction = ({ handleCloseModal, editModal, name }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);
  const strategyAction = useSelector((state) => state.strategyAction);
  const { activeAction } = strategyAction;

  const strategyGroup = useSelector((state) => state.strategyGroup);
  const { group } = strategyGroup;

  useEffect(() => {
    if (name === "KeyFi") {
      dispatch(strategyActionOperations.handleActiveActionArgs("0", "KEYFI"));
    }
    if (activeAction.templateId.action === "Deposit ETH") {
      dispatch(strategyActionOperations.handleActiveActionArgs("0", "ETH"));
    }
  }, [name, dispatch]);

  const handleSaveAction = () => {
    const newArray = group.actions.filter((item) => activeAction.templateId !== item.templateId);
    dispatch(strategyGroupOperations.handleGroupInput("actions", [...newArray, activeAction]));
    handleCloseModal();
  };

  const handleUpdateAction = () => {
    dispatch(strategyActionOperations.updateAction());
    handleCloseModal();
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await getPlatforms(name);
      setOptions(data);
    };
    fetchData();
  }, [name]);

  return (
    <Wrapper>
      <h5>{t("common.asset")}</h5>
      <StrategyDropdown
        name="0"
        placeholder={t("strategy.chooseAnAsset")}
        setOption={(name, value) => dispatch(strategyActionOperations.handleActiveActionArgs(name, value))}
        options={options}
        selectedOption={activeAction.args[0]}
      />
      <h5>{t("common.value")}</h5>
      <input
        name="1"
        value={activeAction.args[1]}
        onChange={(e) => dispatch(strategyActionOperations.handleActiveActionArgs(e.target.name, e.target.value))}
        className="edit_action_input"
      />
      <button className="primary" onClick={editModal ? handleUpdateAction : handleSaveAction}>
        {t("strategy.saveAction")}
      </button>
    </Wrapper>
  );
};

export default DepositAction;
