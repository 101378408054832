import React, { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ReactComponent as ArrowDown } from "../../assets/arrowDown.svg";

const Wrapper = styled.div`
  cursor: pointer;
  position: relative;
  color: ${(props) => (props.selected ? props.theme.mainDarkText : props.theme.researchBorder)};
  background: ${(props) => props.theme.bg};
  padding: 0.5rem;
  border: 1px solid ${(props) => props.theme.researchBorder};
  border-radius: ${(props) => (props.open ? `6px 6px 0 0` : `6px`)};
  font-size: 14px;
  min-width: 130px;
  .dropdown_selected {
    display: flex;
    align-items: center;
    img {
      height: 24px;
      width: 24px;
      margin-right: 6px;
    }
    span {
      flex: 1;
      margin-right: 1rem;
      line-height: 24px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    svg {
      height: 100%;
      width: 14px;
      path {
        fill: ${(props) => props.theme.mainDarkText};
      }
    }
  }
  .dropdown_options {
    background: ${(props) => props.theme.bg};
    color: ${(props) => props.theme.mainDarkText};
    overflow: auto;
    top: 100%;
    left: -1px;
    position: absolute;
    width: 130%;
    max-height: 180px;
    border: 1px solid ${(props) => (props.selected ? props.theme.researchBorder : props.theme.researchBorder)};
    border-radius: 0 6px 6px 6px;
    z-index: 2;
    > div {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 0.5rem;
      :not(:last-of-type) {
        border-bottom: 1px solid ${(props) => props.theme.researchBorder};
      }
      img {
        height: 24px;
        width: 24px;
        margin-right: 6px;
      }
      :hover {
        background: ${(props) => props.theme.secondaryBg};
      }
    }
  }
`;

const AlertDropdown = ({
  name,
  options,
  fullOptions,
  setOption,
  selectedOption,
  placeholder = "select",
  className,
  onChange,
  searchBar = false,
  searchQuery,
}) => {
  const wrapperRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const selectedOptionObject =
    selectedOption && fullOptions
      ? fullOptions.find((item) => item.id === selectedOption)
      : options.find((item) => item.id === selectedOption);

  useEffect(() => {
    const modal = document.querySelector("[data-reach-dialog-overlay]>div");
    modal.addEventListener("click", handleClickOutside, false);
    return () => {
      modal.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const handleSelectOption = (id) => {
    setOption(name, id);
    setOpen(false);
  };

  return (
    <Wrapper
      className={className}
      open={open}
      ref={wrapperRef}
      selected={selectedOptionObject}
      onClick={() => {
        setOpen(!open);
      }}
    >
      <div className="dropdown_selected">
        {open && searchBar ? (
          <input
            style={{ fontSize: "14px", height: "24px", width: "100%" }}
            onChange={onChange}
            value={searchQuery}
            autoFocus
          />
        ) : (
          <>
            {selectedOptionObject && selectedOptionObject.img && selectedOptionObject.img}
            <span>{selectedOptionObject ? selectedOptionObject.text : placeholder}</span>
            <ArrowDown />
          </>
        )}
      </div>
      {open && options.length !== 0 && (
        <div className="dropdown_options">
          {options.length !== 0 ? (
            options.map((item) => (
              <div key={item.text} onClick={() => handleSelectOption(item.id)}>
                {item.img && item.img} {item.text}
              </div>
            ))
          ) : (
            <div>{t("alert.noOptions")}</div>
          )}
        </div>
      )}
    </Wrapper>
  );
};

export default AlertDropdown;
