import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { notificationsOperations } from "../../redux/notificationsSlice";
import InterestRateNotification from "./NotificationTypes/InterestRateNotification";

const Wrapper = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 10;
`;

const Notifications = () => {
  const dispatch = useDispatch();
  const notificationsList = useSelector((state) => state.notifications.notificationsList);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(notificationsOperations.fetchNotifications());
    }, 1000 * 60);

    return () => clearInterval(interval);
  });

  return (
    <Wrapper>
      {notificationsList.length !== 0 &&
        notificationsList.map((item, i) => <InterestRateNotification key={i} item={item} />)}
    </Wrapper>
  );
};

export default Notifications;
