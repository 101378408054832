import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { strategyOperations } from "./strategySlice";

const API = process.env.REACT_APP_PRO_API;

const initialState = {
  isLoading: false,
  group: {
    name: "",
    actions: [],
  },
  activeGroup: {
    id: "",
    name: "",
    strategyId: "",
    userId: "",
    actions: [],
  },
};

const strategyGroup = createSlice({
  name: "strategyGroup",
  initialState,
  reducers: {
    setLoading(state, action) {
      return { ...state, isLoading: action.payload };
    },
    setGroup(state, action) {
      return { ...state, group: action.payload };
    },
    setActiveGroup(state, action) {
      return { ...state, activeGroup: action.payload };
    },
  },
});

export const strategyGroupActions = strategyGroup.actions;

const getRoot = (state) => state.strategyGroup;

export const strategyGroupSelectors = {
  getLoading: (state) => getRoot(state).isLoading,
  getGroup: (state) => getRoot(state).group,
  getActiveGroup: (state) => getRoot(state).activeGroup,
};

export const strategyGroupOperations = {
  handleGroupInput: (name, value) => async (dispatch, getState) => {
    const group = strategyGroupSelectors.getGroup(getState());
    dispatch(strategyGroupActions.setGroup({ ...group, [name]: value }));
  },
  handleActiveGroupInput: (name, value) => async (dispatch, getState) => {
    const activeGroup = strategyGroupSelectors.getActiveGroup(getState());
    dispatch(strategyGroupActions.setActiveGroup({ ...activeGroup, [name]: value }));
  },
  createGroup: () => async (dispatch, getState) => {
    try {
      const group = strategyGroupSelectors.getGroup(getState());
      const token = getState().userData.tokens.access.token;
      const activeStrategy = getState().strategy.activeStrategy;

      const config = {
        headers: {
          Authorization: token,
        },
      };

      await axios.post(`${API}/v1/group`, { ...group, strategyId: activeStrategy.id }, config);
      dispatch(strategyGroupActions.setGroup(initialState.group));
      dispatch(strategyOperations.getStrategies());
    } catch (err) {
      console.log(err);
    }
  },
  editGroup: () => async (dispatch, getState) => {
    try {
      const activeGroup = strategyGroupSelectors.getActiveGroup(getState());
      const token = getState().userData.tokens.access.token;

      const config = {
        headers: {
          Authorization: token,
        },
      };

      await axios.patch(`${API}/v1/group/${activeGroup.id}`, { name: activeGroup.name }, config);
      dispatch(strategyOperations.getStrategies());
    } catch (err) {
      console.log(err);
    }
  },
  deleteActionFromGroup: (groupId, actionId, updateActiveGroup) => async (dispatch, getState) => {
    try {
      const token = getState().userData.tokens.access.token;

      const config = {
        headers: {
          Authorization: token,
        },
      };

      const { data } = await axios.patch(`${API}/v1/group/remove-action/${groupId}`, { actionId }, config);
      if (updateActiveGroup) {
        console.log(data);
        dispatch(strategyGroupActions.setActiveGroup(data));
      }
      dispatch(strategyOperations.getStrategies());
    } catch (err) {
      console.log(err);
    }
  },
};

export const strategyGroupReducer = strategyGroup.reducer;
