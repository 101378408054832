import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { ReactComponent as ArrowDown } from "../../assets/arrowDown.svg";
import { ReactComponent as Star } from "../../assets/discovery/starHover.svg";
import { beautifyTokensAmount } from "../../helpers";
import { watchlistOperations } from "../../redux/watchlistSlice";
import { Skeleton } from "../../Shared";
import { MobileTokenCardWrapper, TokenCardWrapper } from "./TokenCard.styles";
import WatchlistTooltip from "./WatchlistTooltip";

// Assets

const MobileTokenCard = ({ token, history, inWatchlist, handleWatchlistToggle, skeleton, t }) => {
  const [open, setOpen] = useState(false);
  if (skeleton) {
    return (
      <MobileTokenCardWrapper>
        <div className="mobile_token_header">
          <div className="mobile_token_main">
            <Star className={`token_star`} />
            <div className="mobile_token_rank">
              <Skeleton height="18" width="18" />
            </div>
            <div className="mobile_token_title">
              <Skeleton circle height="20" width="20" />
              <Skeleton height="18" width="29" />
            </div>
            <div className="mobile_token_price">
              <Skeleton style={{ marginLeft: "auto" }} height="18" width="58" />
            </div>
            <div className={`token_price_change`}>
              <Skeleton height="18" width="40" />
            </div>
          </div>
          <ArrowDown className="mobile_arrow_down" />
        </div>
      </MobileTokenCardWrapper>
    );
  }
  return (
    <MobileTokenCardWrapper>
      <div className="mobile_token_header">
        <div className="mobile_token_main">
          <Star
            className={`token_star ${inWatchlist(token.coin_id) ? "token_star_selected" : ""}`}
            onClick={() => handleWatchlistToggle(token.coin_id)}
          />
          <div className="mobile_token_rank" onClick={() => history.push(`/research/token/${token.coin_id}`)}>
            {token.market_cap_rank}
          </div>
          <div className="mobile_token_title" onClick={() => history.push(`/research/token/${token.coin_id}`)}>
            <img src={token.icon} alt={token.name} />
            {token.symbol.toUpperCase()}
          </div>
          <div className="mobile_token_price" onClick={() => history.push(`/research/token/${token.coin_id}`)}>
            {beautifyTokensAmount(token.current_price, { prefix: "$" })}
          </div>
          <div
            onClick={() => history.push(`/research/token/${token.coin_id}`)}
            className={`token_price_change ${
              token.price_change_percentage_24h_in_currency < 0 ? "token_price_negative" : ""
            }`}
          >
            {beautifyTokensAmount(token.price_change_percentage_24h_in_currency, { suffix: "%" })}
          </div>
        </div>
        <ArrowDown className="mobile_arrow_down" onClick={() => setOpen((prev) => !prev)} />
      </div>
      {open && (
        <div className="mobile_token_open">
          <div className="mobile_token_info">
            <div className="mobile_token_info_label">{t("swap.volume")}</div>
            <div className="mobile_token_info_value">
              {beautifyTokensAmount(token.total_volume, { prefix: "$", noDecimals: true })}
            </div>
          </div>
          <div className="mobile_token_info">
            <div className="mobile_token_info_label">{t("common.circulatingSupply")}</div>
            <div className="mobile_token_info_value">
              {beautifyTokensAmount(token.circulating_supply, { noDecimals: true })}
            </div>
          </div>
          <div className="mobile_token_info">
            <div className="mobile_token_info_label">{t("common.totalSupply")}</div>
            <div className="mobile_token_info_value">
              {token.total_supply ? beautifyTokensAmount(token.total_supply, { noDecimals: true }) : "-"}
            </div>
          </div>
          <div className="mobile_token_info">
            <div className="mobile_token_info_label">{t("common.marketCap")}</div>
            <div className="mobile_token_info_value">
              {beautifyTokensAmount(token.market_cap, { prefix: "$", noDecimals: true })}
            </div>
          </div>
        </div>
      )}
    </MobileTokenCardWrapper>
  );
};

const DesktopTokenCard = ({ token, history, inWatchlist, handleWatchlistToggle, skeleton }) => {
  if (skeleton) {
    return (
      <TokenCardWrapper>
        <div className="token_number token_element token_number_loader">
          <Skeleton height="35" width="35" />
        </div>
        <div className="token_inside">
          <div className="token_info">
            <Star className="token_star" />
            <div className="token_logo_loader">
              <Skeleton height="36" width="36" />
            </div>
            <div>
              <Skeleton height="19" width="57" />
              <Skeleton style={{ marginTop: "2px" }} height="16" width="28" />
            </div>
          </div>

          <div className="token_price">
            <Skeleton style={{ marginLeft: "auto" }} height="18" width="65" />
            <div className="token_price_change">
              <Skeleton style={{ marginLeft: "auto" }} height="15" width="30" />
            </div>
          </div>
        </div>
      </TokenCardWrapper>
    );
  }
  return (
    <TokenCardWrapper>
      <div className="token_number token_element">{token.market_cap_rank}</div>
      <div className="token_inside">
        <div className="token_info">
          <WatchlistTooltip inWatchlist={inWatchlist(token.coin_id)}>
            <Star
              className={`token_star ${inWatchlist(token.coin_id) ? "token_star_selected" : ""}`}
              onClick={() => handleWatchlistToggle(token.coin_id)}
            />
          </WatchlistTooltip>

          <img onClick={() => history.push(`/research/token/${token.coin_id}`)} src={token.icon} alt={token.name} />
          <div onClick={() => history.push(`/research/token/${token.coin_id}`)} className="token_name">
            <div>{token.name}</div>
            <div className="token_symbol">{token.symbol}</div>
          </div>
        </div>
        <div onClick={() => history.push(`/research/token/${token.coin_id}`)} className="token_price">
          {beautifyTokensAmount(token.current_price, { prefix: "$" })}
          <div
            className={`token_price_change ${
              token.price_change_percentage_24h_in_currency < 0 ? "token_price_negative" : ""
            }`}
          >
            {beautifyTokensAmount(token.price_change_percentage_24h_in_currency, { suffix: "%" })}
          </div>
        </div>

        <div className="token_symbol_background">{token.symbol}</div>
      </div>
    </TokenCardWrapper>
  );
};

const TokenCard = ({ token, skeleton, mobile }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const userData = useSelector((state) => state.userData);
  const { user } = userData;

  const inWatchlist = (tokenId) => {
    if (user.mainWatchlist.assets) {
      return user.mainWatchlist.assets.some((id) => id === tokenId);
    }
    return false;
  };

  const handleWatchlistToggle = (id) => {
    if (inWatchlist(id)) {
      return dispatch(watchlistOperations.removeAssetFromWatchlist(user.mainWatchlist.id, id, true));
    }
    return dispatch(watchlistOperations.addAssetToWatchlist(user.mainWatchlist.id, id, true));
  };

  if (mobile) {
    return (
      <MobileTokenCard
        skeleton={skeleton}
        token={token}
        history={history}
        inWatchlist={inWatchlist}
        handleWatchlistToggle={handleWatchlistToggle}
        t={t}
      />
    );
  }

  return (
    <>
      <DesktopTokenCard
        skeleton={skeleton}
        token={token}
        history={history}
        inWatchlist={inWatchlist}
        handleWatchlistToggle={handleWatchlistToggle}
        t={t}
      />
    </>
  );
};

export default TokenCard;
