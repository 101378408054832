import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { userDataActions } from "./userDataSlice";

const API = process.env.REACT_APP_PRO_API;

const initialState = {
  watchlistList: [],
  userWatchlists: [],
  activeWatchlist: {}, // Single watchlist
  popularWatchlists: {
    totalCount: null,
    list: [],
  }, // Most popular watchlists
  followedWatchlists: {
    totalCount: null,
    list: [],
  }, // Watchlists user follows
  isFetching: false,
  listIsFetching: false,
  followedIsFetching: false,
  userIsFetching: false,
  error: null,
};

const watchlist = createSlice({
  name: "watchlist",
  initialState,
  reducers: {
    setWatchlistList(state, action) {
      return { ...state, watchlistList: action.payload };
    },
    setActiveWatchlist(state, action) {
      return { ...state, activeWatchlist: action.payload };
    },
    setUserWatchlists(state, action) {
      return { ...state, userWatchlists: action.payload };
    },
    setPopularWatchlists(state, action) {
      return { ...state, popularWatchlists: action.payload };
    },
    setFollowedWatchlists(state, action) {
      return { ...state, followedWatchlists: action.payload };
    },
    setIsFetching(state, action) {
      return { ...state, isFetching: action.payload };
    },
    setListIsFetching(state, action) {
      return { ...state, listIsFetching: action.payload };
    },
    setUserIsFetching(state, action) {
      return { ...state, userIsFetching: action.payload };
    },
    setFollowedIsFetching(state, action) {
      return { ...state, followedIsFetching: action.payload };
    },
    setError(state, action) {
      return { ...state, error: action.payload };
    },
  },
});

export const watchlistActions = watchlist.actions;

const getRoot = (state) => state.watchlist;

export const watchlistSelectors = {
  getWatchlistTokens: (state) => getRoot(state).watchlistTokens,
  getWatchlistList: (state) => getRoot(state).watchlistList,
  getActiveWatchlist: (state) => getRoot(state).activeWatchlist,
};

export const watchlistOperations = {
  getWatchlistList: () => async (dispatch, getState) => {
    dispatch(watchlistActions.setError(null));
    dispatch(watchlistActions.setListIsFetching(true));
    try {
      const token = getState().userData.tokens.access.token;

      const config = {
        headers: {
          Authorization: token,
        },
      };

      const { data } = await axios.get(`${API}/v1/research/search-list`, config);
      dispatch(watchlistActions.setWatchlistList(data));
      dispatch(watchlistActions.setListIsFetching(false));
    } catch (err) {
      console.log(err);
      if (err.message !== "Cancel") {
        dispatch(watchlistActions.setError(err.response.data.message || err.message));
        dispatch(watchlistActions.setListIsFetching(false));
      }
    }
  },
  getWatchlist: (watchlistId) => async (dispatch, getState) => {
    try {
      dispatch(watchlistActions.setIsFetching(true));
      dispatch(watchlistActions.setError(null));
      dispatch(watchlistActions.setActiveWatchlist({}));

      const token = getState().userData.tokens.access.token;

      const config = {
        headers: {
          Authorization: token,
        },
      };

      const [watchlist, userWatchlists] = await Promise.all([
        axios.get(`${API}/v1/watchlist/${watchlistId}`, config),
        axios.get(`${API}/v1/watchlist/user-watchlists`, config),
      ]);
      dispatch(watchlistActions.setActiveWatchlist(watchlist.data));
      dispatch(watchlistActions.setUserWatchlists(userWatchlists.data));
      dispatch(watchlistActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(watchlistActions.setError(err.response.data.message || err.message));
      dispatch(watchlistActions.setIsFetching(false));
    }
  },
  getUserWatchlists: () => async (dispatch, getState) => {
    const token = getState().userData.tokens.access.token;
    dispatch(watchlistActions.setUserIsFetching(true));

    const config = {
      headers: {
        Authorization: token,
      },
    };
    try {
      const userWatchlists = await axios.get(`${API}/v1/watchlist/user-watchlists`, config);
      dispatch(watchlistActions.setUserWatchlists(userWatchlists.data));
    } catch (err) {
      console.log(err);
      dispatch(watchlistActions.setError(err.response.data.message || err.message));
    }
    dispatch(watchlistActions.setUserIsFetching(false));
  },
  updateWatchlist: (watchlistId, watchlistData) => async (dispatch, getState) => {
    try {
      const token = getState().userData.tokens.access.token;

      const config = {
        headers: {
          Authorization: token,
        },
      };

      const { data } = await axios.patch(`${API}/v1/watchlist/${watchlistId}`, watchlistData, config);
      dispatch(watchlistActions.setActiveWatchlist(data));
      const { data: userWatchlists } = await axios.get(`${API}/v1/watchlist/user-watchlists`, config);
      dispatch(watchlistActions.setUserWatchlists(userWatchlists));
      dispatch(watchlistOperations.getPopularWatchlists());
    } catch (err) {
      dispatch(watchlistActions.setError(err.response.data.message || err.message));
      dispatch(watchlistActions.setListIsFetching(false));
    }
  },
  deleteWatchlist: (watchlistId) => async (dispatch, getState) => {
    try {
      const token = getState().userData.tokens.access.token;

      const config = {
        headers: {
          Authorization: token,
        },
      };

      await axios.delete(`${API}/v1/watchlist/${watchlistId}`, config);
      dispatch(watchlistOperations.getUserWatchlists());
    } catch (err) {
      dispatch(watchlistActions.setError(err.response.data.message || err.message));
      dispatch(watchlistActions.setListIsFetching(false));
    }
  },
  createWatchlist: (watchlistData) => async (dispatch, getState) => {
    try {
      const token = getState().userData.tokens.access.token;

      const config = {
        headers: {
          Authorization: token,
        },
      };

      const { data } = await axios.post(`${API}/v1/watchlist`, watchlistData, config);
      dispatch(watchlistActions.setActiveWatchlist(data));
      const { data: userWatchlists } = await axios.get(`${API}/v1/watchlist/user-watchlists`, config);
      dispatch(watchlistActions.setUserWatchlists(userWatchlists));
      return data._id;
    } catch (err) {
      console.log(err);
      dispatch(watchlistActions.setError(err.response.data.message || err.message));
      dispatch(watchlistActions.setListIsFetching(false));
    }
  },
  addAssetToWatchlist: (watchlistId, assetId, isActiveWatchlist) => async (dispatch, getState) => {
    try {
      const token = getState().userData.tokens.access.token;

      const config = {
        headers: {
          Authorization: token,
        },
      };

      const { data } = await axios.patch(
        `${API}/v1/watchlist/add-asset`,
        {
          watchlistId,
          assetId,
        },
        config
      );

      if (!isActiveWatchlist) {
        const activeWatchlist = getState().watchlist.activeWatchlist;
        dispatch(watchlistActions.setActiveWatchlist({ ...activeWatchlist, ...data }));
      } else {
        console.log("here add");
        const mainWatchlist = getState().userData.user.mainWatchlist;
        const newList = [...mainWatchlist.assets, assetId];
        dispatch(userDataActions.setUser({ mainWatchlist: { ...mainWatchlist, assets: newList } }));
      }
      return data.id;
    } catch (err) {
      dispatch(watchlistActions.setError(err.response.data.message || err.message));
      dispatch(watchlistActions.setIsFetching(false));
    }
  },
  removeAssetFromWatchlist: (watchlistId, assetId, isActiveWatchlist) => async (dispatch, getState) => {
    try {
      const token = getState().userData.tokens.access.token;
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const { data } = await axios.patch(
        `${API}/v1/watchlist/remove-asset`,
        {
          watchlistId,
          assetId,
        },
        config
      );

      if (!isActiveWatchlist) {
        const activeWatchlist = getState().watchlist.activeWatchlist;
        dispatch(watchlistActions.setActiveWatchlist({ ...activeWatchlist, ...data }));
      } else {
        const mainWatchlist = getState().userData.user.mainWatchlist;
        const newAssetList = mainWatchlist.assets.filter((id) => id !== assetId);
        dispatch(userDataActions.setUser({ mainWatchlist: { ...mainWatchlist, assets: newAssetList } }));
      }
      dispatch(watchlistOperations.getUserWatchlists());
      return data.id;
    } catch (err) {
      dispatch(watchlistActions.setError(err.response.data.message || err.message));
      dispatch(watchlistActions.setListIsFetching(false));
    }
  },
  setMainWatchlist: (watchlistId) => async (dispatch, getState) => {
    try {
      const token = getState().userData.tokens.access.token;

      const config = {
        headers: {
          Authorization: token,
        },
      };

      const { data } = await axios.patch(
        `${API}/v1/watchlist/main-watchlist`,
        {
          watchlistId,
        },
        config
      );

      dispatch(userDataActions.setUser(data));
      return data.id;
    } catch (err) {
      dispatch(watchlistActions.setError(err.response.data.message || err.message));
      dispatch(watchlistActions.setListIsFetching(false));
    }
  },
  getPopularWatchlists: (page, pageSize) => async (dispatch, getState) => {
    try {
      dispatch(watchlistActions.setIsFetching(true));

      const token = getState().userData.tokens.access.token;
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const { data } = await axios.get(`${API}/v1/watchlist/popular?page=${page}&pageSize=${pageSize}`, config);
      dispatch(watchlistActions.setPopularWatchlists(data));
      dispatch(watchlistActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(watchlistActions.setError(err.response.data.message || err.message));
      dispatch(watchlistActions.setIsFetching(false));
    }
  },
  followWatchlist: (watchlistId) => async (dispatch, getState) => {
    try {
      const token = getState().userData.tokens.access.token;
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const { data } = await axios.post(`${API}/v1/watchlist/follow`, { watchlistId }, config);
      dispatch(watchlistActions.setActiveWatchlist(data));
      const popularWL = [...getState().watchlist.popularWatchlists.list];
      const popularTotalCount = getState().watchlist.popularWatchlists.totalCount;
      if (popularWL.length !== 0) {
        const foundIndex = popularWL.findIndex((x) => x.id === watchlistId);
        popularWL[foundIndex] = { ...popularWL[foundIndex], followers: data.followers };
        dispatch(watchlistActions.setPopularWatchlists({ list: popularWL, totalCount: popularTotalCount }));
      }
      const followedWL = [...getState().watchlist.followedWatchlists.list];
      const followedTotalCount = getState().watchlist.followedWatchlists.totalCount;
      dispatch(
        watchlistActions.setFollowedWatchlists({ list: [...followedWL, data], totalCount: followedTotalCount + 1 })
      );

      // if (followedWL.length !== 0) {
      //   const foundIndex = followedWL.findIndex((x) => x.id === watchlistId);
      //   followedWL[foundIndex] = { ...followedWL[foundIndex], followers: data.followers };
      //   dispatch(watchlistActions.setFollowedWatchlists({ list: followedWL, totalCount: followedTotalCount }));
      // }
    } catch (err) {
      console.log(err);
      dispatch(watchlistActions.setError(err.response.data.message || err.message));
      dispatch(watchlistActions.setListIsFetching(false));
    }
  },
  unfollowWatchlist: (watchlistId) => async (dispatch, getState) => {
    try {
      const token = getState().userData.tokens.access.token;
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const { data } = await axios.post(`${API}/v1/watchlist/unfollow`, { watchlistId }, config);
      dispatch(watchlistActions.setActiveWatchlist(data));
      const popularWL = [...getState().watchlist.popularWatchlists.list];
      const popularTotalCount = getState().watchlist.popularWatchlists.totalCount;
      if (popularWL.length !== 0) {
        const foundIndex = popularWL.findIndex((x) => x.id === watchlistId);
        popularWL[foundIndex] = { ...popularWL[foundIndex], followers: data.followers };
        dispatch(watchlistActions.setPopularWatchlists({ list: popularWL, totalCount: popularTotalCount }));
      }
      const followedWL = [...getState().watchlist.followedWatchlists.list];
      const followedTotalCount = getState().watchlist.followedWatchlists.totalCount;
      const newWL = followedWL.filter((x) => x._id !== watchlistId);
      dispatch(watchlistActions.setFollowedWatchlists({ list: newWL, totalCount: followedTotalCount - 1 }));
    } catch (err) {
      console.log(err);
      dispatch(watchlistActions.setError(err.response.data.message || err.message));
      dispatch(watchlistActions.setListIsFetching(false));
    }
  },
  getFollowedWatchlists: () => async (dispatch, getState) => {
    try {
      dispatch(watchlistActions.setFollowedIsFetching(true));
      const token = getState().userData.tokens.access.token;
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const { data } = await axios.get(`${API}/v1/watchlist/following`, config);
      dispatch(watchlistActions.setFollowedWatchlists(data));
    } catch (err) {
      console.log(err);
      dispatch(watchlistActions.setError(err.response.data.message || err.message));
    }
    dispatch(watchlistActions.setFollowedIsFetching(false));
  },
};

export const watchlistReducer = watchlist.reducer;
