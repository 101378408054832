import React, { useMemo, useState } from "react";

import { ERC20Tokens } from "@keyfi/keyfi-common/src/constants";
import { getAddressSum } from "@keyfi/keyfi-common/src/integrations/common";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { txQueueOperations } from "../../../redux/txQueueSlice";
import AssetImage from "../../Borrow/AssetImage";
import StrategyDropdown from "../../Strategy/StrategyDropdown";


const Wrapper = styled.div`
  .action_input_container {
    display: flex;
    gap: 2rem;
    > div {
      flex: 1;
      .dropdown_image {
        height: 24px;
        width: 24px;
      }
    }
  }
`;
const AddLiquidityAction = ({ handleCloseModal, editModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const txQueue = useSelector((state) => state.txQueue);
  const { activeAction } = txQueue;

  const [searchQuery, setSearchQuery] = useState("");

  const tokens = useMemo(
    () =>
      ERC20Tokens.map((item) => ({
        id: item.id,
        text: item.symbol,
        img: (
          <AssetImage
            className="dropdown_image"
            alt={item.symbol}
            src={`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${
              item.symbol === "ETH" ? "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2" : getAddressSum(item.id)
            }/logo.png`}
          />
        ),
      })),
    []
  );

  const filteredList = useMemo(() => {
    return tokens.filter(({ text }) => text.toLowerCase().includes(searchQuery.toLowerCase())).slice(0, 20);
  }, [searchQuery, tokens]);

  const handleSaveAction = () => {
    dispatch(txQueueOperations.addToTxQueueList());
    handleCloseModal();
  };

  const handleUpdateAction = () => {
    dispatch(txQueueOperations.updateTxQueueItem());
    handleCloseModal();
  };

  const handleSelectOption = (name, value) => {
    dispatch(txQueueOperations.handleActiveActionArgs(name, value));
    setSearchQuery("");
  };

  return (
    <Wrapper>
      <div className="action_input_container">
        <div>
          <h5>{t("common.asset")} 1</h5>
          <StrategyDropdown
            name="0"
            placeholder={t("strategy.chooseAnAsset")}
            setOption={handleSelectOption}
            options={filteredList}
            selectedOption={activeAction.args[0]}
            fullOptions={tokens}
            // className="edit_action_input"
            searchBar
            onChange={(e) => setSearchQuery(e.target.value)}
            searchQuery={searchQuery}
          />
        </div>
        <div>
          <h5>{t("common.asset")} 2</h5>
          <StrategyDropdown
            name="1"
            placeholder={t("strategy.chooseAnAsset")}
            setOption={handleSelectOption}
            options={filteredList}
            fullOptions={tokens}
            selectedOption={activeAction.args[1]}
            // className="edit_action_input"
            searchBar
            onChange={(e) => setSearchQuery(e.target.value)}
            searchQuery={searchQuery}
          />{" "}
        </div>
      </div>
      <div className="action_input_container">
        <div>
          <h5>
            {t("common.asset")} 1 {t("common.value")}
          </h5>
          <input
            name="2"
            value={activeAction.args[2]}
            onChange={(e) => handleSelectOption(e.target.name, e.target.value)}
            className="edit_action_input"
          />
        </div>
        <div>
          <h5>
            {t("common.asset")} 2 {t("common.value")}
          </h5>
          <input
            name="3"
            value={activeAction.args[3]}
            onChange={(e) => handleSelectOption(e.target.name, e.target.value)}
            className="edit_action_input"
          />
        </div>
      </div>
      <button className="primary" onClick={editModal ? handleUpdateAction : handleSaveAction}>
        {t("strategy.saveAction")}
      </button>
    </Wrapper>
  );
};

export default AddLiquidityAction;
