import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as ArrowIcon } from "../assets/arrowWhite.svg";
import { ReactComponent as EditIcon } from "../assets/discovery/edit.svg";
import { ReactComponent as ExitIcon } from "../assets/exit.svg";
import { ReactComponent as PlusIcon } from "../assets/plus.svg";
import { ReactComponent as TrashIcon } from "../assets/strategy/trash.svg";
import { colorIsLight } from "../Components/Header/StacksButtons";
import { StackIcon } from "../Components/Layout/Header.styles";
import AddAddressesModal from "../Components/ManageWallets/AddAddressesModal";
import AddStackModal from "../Components/ManageWallets/AddStackModal";
import DeleteStackModal from "../Components/ManageWallets/DeleteStackModal";
import EditAddressModal from "../Components/ManageWallets/EditAddressModal";
import EditStackModal from "../Components/ManageWallets/EditStackModal";
import { trimAddress } from "../helpers";
import { useWallet } from "../helpers/useWallet";
import { addressesOperations } from "../redux/wallets/addressesSlice";
import { editAddressActions } from "../redux/wallets/editAddressSlice";
import { editStackOperations } from "../redux/wallets/editStackSlice";
import { stacksOperations } from "../redux/wallets/stacksSlice";
import { AddressCardWrapper, ManageWalletsWrapper } from "./ManageWallets.styles";

export const stringToColour = function (str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
};

const AddressCard = ({ item, wallet, stack, openEditModal, openDeleteModal, connected }) => {
  // If address card (Connected and tracked)
  if (!stack) {
    const isConnectedWallet = wallet.address ? wallet.address.toLowerCase() === item.address.toLowerCase() : false;
    return (
      <AddressCardWrapper>
        <div className="address_card_header">
          {isConnectedWallet && <div className="address_card_connected" />}
          <div
            className="address_card_pic"
            style={{
              background: isConnectedWallet ? "#1AD6B4" : stringToColour(item.address),
            }}
          />
          <div className="address_card_info">
            <h5>{item.title}</h5>
            <h6>{trimAddress(item.address)}</h6>
          </div>
          <div className="address_card_buttons">
            <button onClick={() => openEditModal(item)}>
              <EditIcon />
              <span>Rename</span>
            </button>
            {!connected && (
              <button onClick={() => openDeleteModal(item)}>
                <ExitIcon /> <span>Remove</span>
              </button>
            )}
          </div>
        </div>
      </AddressCardWrapper>
    );
  }
  const [open, setOpen] = useState(false);
  // If stack card
  return (
    <AddressCardWrapper>
      <div className="address_card_header">
        <StackIcon height="36px" bg={stringToColour(item._id)} bgIsLight={colorIsLight(stringToColour(item._id))}>
          {item.addresses.length}
        </StackIcon>

        <div className="address_card_info">
          <h5>{item.title}</h5>
          <h6>{item.addresses.length} Addresses</h6>
        </div>
        <div className="address_card_buttons">
          <button onClick={openEditModal}>
            <EditIcon />
            <span>Edit</span>
          </button>
          <button onClick={openDeleteModal}>
            <TrashIcon /> <span>Delete</span>
          </button>
          <button onClick={() => setOpen((prev) => !prev)}>
            <ArrowIcon style={{ transform: open ? "rotate(180deg)" : "" }} />
          </button>
        </div>
      </div>
      <div className="address_card_main" style={{ display: open ? "flex" : "none" }}>
        {item.addresses.map((address, i) => (
          <div key={i} className="address_main_details">
            <div
              className="address_card_pic"
              style={{
                background:
                  wallet.address && wallet.address.toLowerCase() === address.address.toLowerCase()
                    ? "#1AD6B4"
                    : stringToColour(address.address),
              }}
            />{" "}
            <div className="address_card_info">
              <h5>{address.title}</h5>
              <h6>{trimAddress(address.address)}</h6>
            </div>
          </div>
        ))}
      </div>
    </AddressCardWrapper>
  );
};

const ManageWallets = () => {
  const dispatch = useDispatch();
  const wallet = useWallet();

  // Open/close modals
  const [openModal, setOpenModal] = useState({
    addAddresses: false,
    editAddress: false,
    createStack: false,
    editStack: false,
    deleteStack: false,
    deleteAddress: false,
  });

  // User addresses array
  const { addresses, isLoading } = useSelector((state) => state.addresses);
  const { stacks } = useSelector((state) => state.stacks);

  useEffect(() => {
    // Fetch user addresses once page opened
    dispatch(addressesOperations.fetchUserAddresses());
    dispatch(stacksOperations.fetchUserStacks());
  }, []);

  const handleToggleModal = (name) => setOpenModal((prev) => ({ ...prev, [name]: !prev[name] }));

  const connectedWalletData = wallet.address
    ? addresses.find((item) => item.address.toLowerCase() === wallet.address.toLowerCase())
    : null;

  return (
    <ManageWalletsWrapper>
      {/* Modals */}
      <AddStackModal open={openModal.createStack} handleClose={() => handleToggleModal("createStack")} />
      <AddAddressesModal open={openModal.addAddresses} handleClose={() => handleToggleModal("addAddresses")} />
      <EditAddressModal open={openModal.editAddress} handleClose={() => handleToggleModal("editAddress")} />
      <EditStackModal open={openModal.editStack} handleClose={() => handleToggleModal("editStack")} />
      <DeleteStackModal stack open={openModal.deleteStack} handleClose={() => handleToggleModal("deleteStack")} />
      <DeleteStackModal open={openModal.deleteAddress} handleClose={() => handleToggleModal("deleteAddress")} />
      {/* End of modals */}
      {/* Left side section */}
      <div className="manage_wallet_grid">
        <div className="addresses_section">
          <div className="manage_wallet_title">
            <h3>Connected</h3>
          </div>
          <div className="manage_wallet_cards">
            {connectedWalletData && (
              <AddressCard
                connected
                item={connectedWalletData}
                wallet={wallet}
                openEditModal={(address) => {
                  dispatch(editAddressActions.setAddress(address));
                  handleToggleModal("editAddress");
                }}
                openDeleteModal={(address) => {
                  dispatch(editAddressActions.setAddress(address));
                  handleToggleModal("deleteAddress");
                }}
              />
            )}
          </div>
          <div className="manage_wallet_title">
            <h3>
              Tracked (
              {!isLoading && wallet.addresses
                ? addresses.filter((item) => item.address.toLowerCase() !== wallet.address.toLowerCase()).length
                : addresses.length}
              )
            </h3>
            <button onClick={() => handleToggleModal("addAddresses")}>
              <PlusIcon />
            </button>
          </div>
          <div className="manage_wallet_cards">
            {addresses.lenght !== 0
              ? addresses
                  .filter((item) =>
                    wallet.address ? item.address.toLowerCase() !== wallet.address.toLowerCase() : true
                  )
                  .map((item) => (
                    <AddressCard
                      key={item._id}
                      item={item}
                      wallet={wallet}
                      openEditModal={(address) => {
                        dispatch(editAddressActions.setAddress(address));
                        handleToggleModal("editAddress");
                      }}
                      openDeleteModal={(address) => {
                        dispatch(editAddressActions.setAddress(address));
                        handleToggleModal("deleteAddress");
                      }}
                    />
                  ))
              : "No added addresses found"}
          </div>
        </div>
        {/* Right side section */}
        <div className="addresses_section">
          <div className="manage_wallet_title">
            <h3>Stacks</h3>
            <button onClick={() => handleToggleModal("createStack")}>
              <PlusIcon />
            </button>
          </div>
          <div className="manage_wallet_cards">
            {stacks.lenght !== 0 &&
              stacks.map((item, i) => (
                <AddressCard
                  key={i}
                  item={item}
                  wallet={wallet}
                  stack
                  openEditModal={() => {
                    dispatch(editStackOperations.addData(item));
                    handleToggleModal("editStack");
                  }}
                  openDeleteModal={() => {
                    dispatch(editStackOperations.addData(item));
                    handleToggleModal("deleteStack");
                  }}
                />
              ))}
          </div>
        </div>
      </div>
    </ManageWalletsWrapper>
  );
};

export default ManageWallets;
