import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import styled from "styled-components";

import Meta from "../Components/Meta";
import Checkbox from "../Components/Signup/Checkbox";
import PasswordChecklist from "../Components/User/PasswordChecklist";
import UserInputField from "../Components/User/UserInputField";
import { validateEmail } from "../helpers";
import { userDataActions, userDataOperations } from "../redux/userDataSlice";
import { ErrorMessage, FormButton, LoginForm } from "../Shared";
import { breakPoints } from "../Theme";

const CheckboxComponent = styled.div`
  margin: 2rem 0 1.5rem;
  span {
    color: white;
    font-size: 14px;
    margin-left: 0.5rem;
  }
`;

export const RegistrationText = styled.div`
  margin: 1.5rem 0 1rem;
  color: ${(props) => props.theme.default_color};
  font-size: 14px;
  span {
    margin-right: 1rem;
  }
  @media (max-width: ${breakPoints.md}px) {
    margin-top: 0.75rem;
  }
`;

function Signup() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userData = useSelector((state) => state.userData);
  const { user, error } = userData;

  const [signupData, setSignupData] = useState({
    userName: "",
    email: "",
    password: "",
    confirmPassword: "",
    isAgree: false,
  });
  const [pwChecklist, setPwChecklist] = useState(false);
  const [emailError, setEmailError] = useState(false);

  useEffect(() => {
    dispatch(userDataActions.setError(null));
  }, []);

  const handleChange = (e) => {
    setSignupData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (signupData.password !== signupData.confirmPassword) {
      return dispatch(userDataActions.setError(`Passwords don't match`));
    }

    dispatch(userDataOperations.register(signupData));
  };

  return (
    <LoginForm autoComplete="on" onSubmit={handleSubmit}>
      <Meta title="Signup" />
      {user.id && !error && <Redirect to="/verify-email" />}
      <h2>{t("auth.registration")}</h2>
      <div className="inputContainer">
        <label>{t("auth.username")}</label>
        <UserInputField
          onChange={handleChange}
          name="userName"
          id="username"
          autoComplete="username"
          placeholder={t("auth.enterUsername")}
        />
        <label>{t("email.auth")}</label>
        <UserInputField
          onChange={handleChange}
          error={emailError}
          onFocus={() => setEmailError(false)}
          onBlur={(e) => !validateEmail(e.target.value) && setEmailError(true)}
          type="email"
          name="email"
          id="email"
          autoComplete="email"
          placeholder={t("auth.enterEmailPlaceholder")}
        />
        {emailError && (
          <ErrorMessage style={{ margin: "-1rem 0 1rem", fontSize: "12px" }}>{t("auth.enterEmail")}</ErrorMessage>
        )}
        <label>{t("auth.password")}</label>
        <UserInputField
          type="password"
          showEye
          onChange={handleChange}
          name="password"
          autoComplete="new-password"
          placeholder={t("auth.enterPassword")}
        />
        <label>{t("auth.confirmpassword")}</label>
        <UserInputField
          type="password"
          showEye
          onChange={handleChange}
          name="confirmPassword"
          placeholder={t("auth.confirmYourPassword")}
          noMargin
        />
        <PasswordChecklist setApprove={(value) => setPwChecklist(value)} margin password={signupData.password} />
        <CheckboxComponent>
          <label>
            <Checkbox
              checked={signupData.isAgree}
              onChange={(e) =>
                setSignupData((prev) => ({
                  ...prev,
                  isAgree: e.target.checked,
                }))
              }
            ></Checkbox>
            <span>
              {t("auth.iAgreeWith")} <Link to="/terms">{t("auth.General Terms and Conditions")}</Link>
            </span>
          </label>
        </CheckboxComponent>
        <FormButton
          type="submit"
          disabled={!signupData.isAgree || !pwChecklist || signupData.password !== signupData.confirmPassword}
        >
          {t("auth.createAccount")}
        </FormButton>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <RegistrationText>
          <span>{t("auth.alreadyHaveAccount")}</span>
          <Link to="/signin">{t("auth.login")}</Link>
        </RegistrationText>
      </div>
    </LoginForm>
  );
}

export default Signup;
