import React from "react";

import { Link } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";

import Logo from "../assets/Blue-only-logo.svg";
import LogoText from "../assets/Blue-only-text.svg";
import Pro from "../assets/sidebar/PRO.svg";
import { useDarkMode } from "../hooks/useDarkMode";

export const lightTheme = {
  bg: "#101926",
  bg2: "#F8FCFD",
  text_grey: "#99A7C2",
  text_greyDark: "#555C68",
  text_primary: "#0046a2",
  text_primary2: "#0046a2",
  text_primary_dark: "#066F7E",
  text_secondary: "#1F347E",
  navBg: "#FFFFFF",
  navHover: "#EFF8F9",
  navActive: "#EEFDFF",
  btnPrimaryBg: "#00CAEC",
  btnPrimaryColor: "white",
  borderBg: "#CCE8EB",
  btnSecondary: "#AEF3FF",
  tabsBg: "#EEFDFF",
  grey1: "#F8F9FC",
  grey2: "#E6ECF5",
  grey3: "#F9FEFF",
  grey4: "#DEF0F2",
  grey5: "#D3DEEE",
  navy: "#1B3784",
  lightBlack: "#474D58",
  black1: "#555C67",
  white1: "#F3F7FF",
  orange: "#FF9F1C",
  red1: "#F65555",
  red2: "#FE7062",
  blue: "#192B66",
  lightBlue: "#5171d4",
  darkBlue: "#002072",
  brandBlue: "#005AD3",
  white: "#FFFFFF",
  superLightBlue: "#F6FAFF",
  lightBlue2: "#DDEAFB",
  lightBlue3: "#D4E7FF",
  lightBlue4: "#EAF3FF",
  blue2: "#74A7EB",
  mainDarkText: "#3B474E",
  brandBlack: "#1B232E",
  border: "#B3D5FF",
  border2: "#D4E7FF",
  placeholder: "#C4C8CA",
  placeholder2: "#BEC4CA",
  placeholderText: "#DCDEDF",
  error: "#EB5757",
  primaryHover: "#1A6BD7",
  green: "#27AE60",
};
export const darkTheme = {
  bg: "#101926",
  secondaryBg: "#182331",
  sidebar_bg: "#182331",
  sidebarAccBg: "#26405B",
  default_color: "#CED2D6",
  mainDarkText: "#CED2D6",
  reverseMainDarkText: "#3B474E",
  focused_color: "linear-gradient(277.85deg, #0A1727 -0.21%, #002454 91.73%)",
  form_content: "#232E3A",
  input_placefolder: "#3A4C61",
  inputPlaceholder: "#2a3540",
  form_text: "#58AFFF",
  form_text_light: "#005AD399",
  form_text2: "#62798E",
  form_text3: "#465B6F",
  pagination_border: "#465B6F",
  pagination_background: "#232E3A",
  border_color: "#4984D3",
  box_shadow: "rgb(73 132 211 / 25%)",
  text_grey: "#99A7C2",
  text_greyDark: "#555C68",
  text_primary: "#0046a2",
  text_primary2: "#0046a2",
  text_primary_dark: "#066F7E",
  text_secondary: "#1F347E",
  navBg: "#FFFFFF",
  navHover: "#EFF8F9",
  navActive: "#EEFDFF",
  btnText: "#CED2D6",
  btnPrimaryBg: "#00CAEC",
  btnPrimaryColor: "white",
  btnGradientBg: "linear-gradient(256.02deg, #0CA7EB 2.19%, #005AD3 188.15%)",
  borderBg: "#2A3540;",
  btnViewBg: "linear-gradient(90deg, #101926 0%, rgba(74, 91, 114, 0.3) 100%)",
  btnSecondary: "#AEF3FF",
  tabsBg: "#EEFDFF",
  grey1: "#F8F9FC",
  grey2: "#E6ECF5",
  grey3: "#F9FEFF",
  grey4: "#DEF0F2",
  grey5: "#D3DEEE",
  lightGrey: "#738CA6",
  darkGrey: "#232E3A",
  lightDarkGrey: "#698095",
  navy: "#1B3784",
  lightBlack: "#474D58",
  black1: "#555C67",
  white1: "#F3F7FF",
  orange: "#FF9F1C",
  red1: "#F65555",
  red2: "#FE7062",
  blue: "#192B66",
  lightBlue: "#5171d4",
  darkBlue: "#002072",
  lightBrandBlue: "#6298E1",
  brandBlue: "#005AD3",
  darkBrandBlue: "#004CB3",
  pressedBrandBlue: "#0452BB",
  white: "#FFFFFF",
  superLightBlue: "#F6FAFF",
  lightBlue2: "#DDEAFB",
  lightBlue3: "#D4E7FF",
  lightBlue4: "#EAF3FF",
  blue2: "#74A7EB",
  brandBlack: "#1B232E",
  border: "#B3D5FF",
  border2: "#D4E7FF",
  placeholder: "#C4C8CA",
  placeholder2: "#BEC4CA",
  placeholderText: "#354555",
  error: "#EB5757",
  errorInputBg: "#EB57571A",
  darkError: "#C64949",
  primaryHover: "#1A6BD7",
  green: "#27AE60",
  greenShade: "#6FC593",
  greenShade2: "#5ABD84",
  greenShade3: "#22df90",
  darkGreen: "#188261",
  darkGreenHover: "#156D52",
  gasYellow: "#FFD700",
  cardBorder: "#273038",
  earnLabelBg: "linear-gradient(90deg, #101926 0%, rgba(74, 91, 114, 0.5) 50%, #101926 100%);",
  newsTimestamp: "#75787C",
  researchBorder: "#3A4C61",
  gradientBg: "#182331",
  widgetCardBg: "#141D2B",
  widgetIconBg: "#8EA6BC",
  userInputFocus: "#4984D3",
  userInputFocusShadow: "0px 0px 10px 0px #4984D340",
  userUpdateSuccess: "linear-gradient(270deg, #5ABD84 0%, #6EE8A2 100%);",
  skeletonBg: "#283649",
  skeletonLoader: "linear-gradient(94.3deg, #3a485e 3.5%, rgba(40, 54, 73, 0.6) 96.5%)",
  scroleThumb: "#888",
  redMinus: "#FC7D7D",
  infoBackground: "#f2c94c1a",
  researchTokenBackground: "linear-gradient(90deg, #101926 0%, rgba(74, 91, 114, 0.2) 100%);",
  researchTokenBackground2: "linear-gradient(90deg, #101926 0%, rgba(74, 91, 114, 0.3) 50%, #101926 100%);",
  researchTokenCardHover: "linear-gradient(277.85deg, rgba(10, 23, 39, 1) -0.21%, rgba(0, 36, 84, 1) 91.73%);",
  uniswapPink: "#FF5AA9",
  uniswapText: "#FD017A",
  binanceYellow: "#f3ba2f",
  curveYellow: "#F4BB27",
  disabledText: "#465b6f",
  disabled: "#2A3540",
  error404: "#e0e0e0",
  borrowGreen: "#4A9C6D",
  borrowYellow: "#EBA757",
  warning: "#FCE59B",
  txGreen: "#4A9C6D",
  txGreenHover: "#4f845c",
  txError: "#C64949",
  blueTransparent: "#122A4B",
  blueTransparentHover: "#163560",
  blueTransparentDark: "#091A31",
  txNoteBg: "#1e2a38",
  secondaryBtnHover: "#0F1822",
  warningBackground: "#FFDC70",
  errorInputBackground: "#EB57571A",
  watchlistRankBg: "#7EB796",
  watchlistPublicBg: "#8247E5",
  watchlistFollowersBg: "#58AFFF",
  toggleOn: "linear-gradient(123.69deg, #46B474 -4.44%, #2CFF84 111.11%);",
  alertSubtitle: "#6C8195",
  notificationGreen: "#1AD6B4",
  notificationPurple: "#820097",
  mainDarketText: "#9fa3a7",
  nonHighlighted: "#90A0B8",
  greenish: "#1AD6B4",
  polygonish: "#8247E5",
  inputWarning: "#F3BA2F",
  inputWarningBg: "#272B2A",
  toggleBallBorder: "#0D5F45",
  toggleBall: "linear-gradient(123.69deg, #46B474 -4.44%, #2CFF84 111.11%)",
  toggleGreen: "#188261",
  toggleGreenOpacity: "#18826133",
};
// export const nexoTheme = {
//   bg: "#101926",
//   sidebar_bg: "#182331",
//   default_color: "#CED2D6",
//   focused_color: "linear-gradient(277.85deg, #0A1727 -0.21%, #002454 91.73%)",
//   form_content: "#232E3A",
//   input_placefolder: "#3A4C61",
//   form_text: "#58AFFF",
//   form_text2: "#62798E",
//   form_text3: "#465B6F",
//   pagination_border: "#465B6F",
//   pagination_background: "#232E3A",
//   border_color: "#4984D3",
//   box_shadow: "rgb(73 132 211 / 25%)",
//   text_grey: "#99A7C2",
//   text_greyDark: "#555C68",
//   text_primary: "#0046a2",
//   text_primary2: "#0046a2",
//   text_primary_dark: "#066F7E",
//   text_secondary: "#1F347E",
//   navBg: "#FFFFFF",
//   navHover: "#EFF8F9",
//   navActive: "#EEFDFF",
//   btnPrimaryBg: "#00CAEC",
//   btnPrimaryColor: "white",
//   btnGradientBg: "linear-gradient(256.02deg, #0CA7EB 2.19%, #005AD3 188.15%)",
//   borderBg: "#2A3540;",
//   btnViewBg: "linear-gradient(90deg, #101926 0%, rgba(74, 91, 114, 0.3) 100%)",
//   btnSecondary: "#AEF3FF",
//   tabsBg: "#EEFDFF",
//   grey1: "#F8F9FC",
//   grey2: "#E6ECF5",
//   grey3: "#F9FEFF",
//   grey4: "#DEF0F2",
//   grey5: "#D3DEEE",
//   darkGrey: "#232E3A",
//   lightDarkGrey: "#698095",
//   navy: "#1B3784",
//   lightBlack: "#474D58",
//   black1: "#555C67",
//   white1: "#F3F7FF",
//   orange: "#FF9F1C",
//   red1: "#F65555",
//   red2: "#FE7062",
//   blue: "#192B66",
//   lightBlue: "#5171d4",
//   darkBlue: "#002072",
//   brandBlue: "#005AD3",
//   darkBrandBlue: "#004CB3",
//   white: "#FFFFFF",
//   superLightBlue: "#F6FAFF",
//   lightBlue2: "#DDEAFB",
//   lightBlue3: "#D4E7FF",
//   lightBlue4: "#EAF3FF",
//   blue2: "#74A7EB",
//   mainDarkText: "#3B474E",
//   brandBlack: "#1B232E",
//   border: "#B3D5FF",
//   border2: "#D4E7FF",
//   placeholder: "#C4C8CA",
//   placeholder2: "#BEC4CA",
//   placeholderText: "#DCDEDF",
//   error: "#EB5757",
//   primaryHover: "#1A6BD7",
//   green: "#27AE60",
//   greenShade: "#6FC593",
//   greenShade2: "#5ABD84",
//   cardBorder: "#273038",
//   earnLabelBg: "linear-gradient(90deg, #101926 0%, rgba(74, 91, 114, 0.5) 50%, #101926 100%);",
//   newsTimestamp: "#75787C",
//   researchBorder: "#3A4C61",
//   gradientBg: "#182331",
//   widgetCardBg: "#141D2B",
//   widgetIconBg: "#8EA6BC",
//   userInputFocus: "#4984D3",
//   userInputFocusShadow: "0px 0px 10px 0px #4984D340",
//   userUpdateSuccess: "linear-gradient(270deg, #5ABD84 0%, #6EE8A2 100%);",
//   skeletonBg: "#283649",
//   skeletonLoader: "linear-gradient(94.3deg, #3a485e 3.5%, rgba(40, 54, 73, 0.6) 96.5%)",
//   scroleThumb: "#888",
//   redMinus: "#FC7D7D",
//   infoBackground: "#f2c94c1a",
//   // gradientBg: "background: linear-gradient(90deg, #101926 0%, rgba(74, 91, 114, 0) 100%);",
// };

export const breakPoints = {
  sm: 576, // 576
  md: 768, // TODO: Implement tablet layout
  lg: 992,
  xl: 1200,
  xxl: 1900,
  laptop: 1350,
};

export const StyledThemeProvider = ({ children }) => {
  const [theme] = useDarkMode();
  const themeMode = theme === "light" ? darkTheme : darkTheme;
  return <ThemeProvider theme={themeMode}>{children}</ThemeProvider>;
};

const StyledContainer = styled.div`
  width: calc(100% - 240px);
  display: flex;
  flex-direction: column;
  margin: 0 20px 0 auto;

  @media (max-width: ${breakPoints.md}px) {
    margin: 0 auto;
    width: calc(100% - 40px);
  }

  @media (max-width: ${breakPoints.sm}px) {
    width: calc(100% - 30px);
  }
`;

export const Container = ({ children, isMinimized }) => {
  return <StyledContainer isMinimized={isMinimized}>{children}</StyledContainer>;
};

const StyledSigninContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .signin_logo {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    .signin_logo_small {
      margin-right: 0.5rem;
    }
    img:not(:last-of-type) {
      margin-right: 1rem;
    }
  }
`;

export const SigninContainer = ({ children }) => (
  <StyledSigninContainer>
    <Link to="/signin">
      <div className="signin_logo">
        <img src={Logo} alt="KeyFi" height="40" />
        <img src={LogoText} alt="KeyFi Text" className="signin_logo_small" height="17" />
        <img src={Pro} alt="PRO" height="25" />
      </div>
    </Link>
    {children}
  </StyledSigninContainer>
);

export const StyledLink = styled(Link)`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-decoration-line: underline;
  color: ${(props) => props.theme.text_primary};
`;

export const BodyText = styled.p`
  color: ${(props) => (props.color ? props.color : props.theme.text_greyDark)};
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: ${(props) => (props.weight ? props.weight : 300)};
  line-height: 24px;
  letter-spacing: 0px;
  margin: ${(props) => (props.margin ? props.margin : 0)};
  text-align: ${(props) => (props.align === "center" ? `center` : `left`)};
`;
