import React from "react";

import Tippy from "@tippyjs/react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ReactComponent as CopyIcon } from "../assets/copy.svg";
import { trimAddress, walletsLogos } from "../helpers";
import { breakPoints } from "../Theme";

const StyledButton = styled.button`
  padding: 0.75rem 2.5rem;
  background: 0;
  border: 1px solid ${(props) => props.theme.researchBorder};
  border-radius: 6px;
  color: ${(props) => props.theme.mainDarkText};
  :hover {
    background: ${(props) => props.theme.secondaryBtnHover};
  }
`;
const StyledListItem = styled.div`
  margin-top: 1.5rem;
  .wallet {
    display: grid;
    grid-template-columns: 40px 1fr;
    align-items: center;
    column-gap: 0.75rem;
    margin-bottom: 2.5rem;
    img {
      height: 40px;
      width: 40px;
    }
    .wallet_title {
      grid-row: 1;
      grid-column: 2/3;

      h4 {
        font-size: 20px;
      }
    }
    .wallet_change {
      grid-row: 1;
      grid-column: 3/4;
      @media (max-width: ${breakPoints.lg}px) {
        display: none;
      }
    }
    .wallet_address {
      display: flex;
      grid-row: 2;
      grid-column: 2/3;
      gap: 0.75rem;
      cursor: pointer;
      h5 {
        font-size: 16px;
        color: ${(props) => props.theme.lightGrey};
      }
    }
  }
`;

export default function ConnectedWallet(props) {
  const { handleChangeWallet, onDisconnect, wallet, userAddress } = props;
  const { t } = useTranslation();
  return (
    <StyledListItem>
      <div className="wallet">
        {walletsLogos[wallet]}
        <div className="wallet_title">
          <h4>{wallet}</h4>
        </div>
        <div className="wallet_change">
          <StyledButton onClick={handleChangeWallet}>{t("auth.change")}</StyledButton>
        </div>
        <Tippy
          onShow={(instance) =>
            setTimeout(() => {
              instance.hide();
            }, 1000)
          }
          trigger="click"
          content="Copied!"
        >
          <div className="wallet_address" onClick={() => navigator.clipboard.writeText(userAddress)}>
            <h5>{!!userAddress && window.innerWidth > breakPoints.lg ? userAddress : trimAddress(userAddress)}</h5>
            <CopyIcon />
          </div>
        </Tippy>
      </div>
      <StyledButton onClick={onDisconnect}>Disconnect</StyledButton>
    </StyledListItem>
  );
}
