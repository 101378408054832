import styled from "styled-components";

import { breakPoints } from "../../Theme";

export const TokenCardWrapper = styled.div`
  position: relative;
  cursor: pointer;

  .token_inside {
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.25rem 1rem;
    width: 100%;
    background: ${(props) => props.theme.researchTokenBackground};
    border-radius: 6px;
    border: 1px solid ${(props) => props.theme.researchBorder};

    :hover {
      border-color: ${(props) => props.theme.brandBlue};
      background: ${(props) => props.theme.researchTokenCardHover};
      .token_element {
        border-color: ${(props) => props.theme.brandBlue};
      }
    }
  }

  .token_number {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 31px;
    width: 31px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
  .token_number_loader {
    overflow: hidden;
  }
  .token_info {
    display: flex;
    align-items: center;
    .token_star {
      border: 0;
      outline: 0;
      margin-right: 0.75rem;
      path {
        fill: none;
      }
      :hover {
        path {
          fill: #3a4c61;
        }
      }
    }
    .token_star_selected,
    .token_star_selected:hover {
      path {
        stroke: #f3ba2f;
        fill: #f3ba2f;
      }
    }
    img {
      height: 36px;
      width: 36px;
      border-radius: 50%;
      margin-right: 0.5rem;
    }
    .token_symbol {
      font-size: 14px;
      text-transform: uppercase;
      color: ${(props) => props.theme.lightGrey};
    }
    .token_logo_loader {
      height: 36px;
      width: 36px;
      overflow: hidden;
      border-radius: 50%;
      margin-right: 6px;
    }
  }
  .token_button {
    cursor: pointer;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0.5rem 0.75rem;
    font-size: 12px;
    line-height: 14px;
    border-radius: 6px;
    :hover {
      background: ${(props) => props.theme.brandBlue};
    }
  }
  .token_symbol_background {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 105px;
    text-transform: uppercase;
    font-weight: 700;
    opacity: 0.04;
    letter-spacing: -1px;
    z-index: -1;
    overflow: hidden;
  }
  .token_price {
    flex: 1;
    position: relative;
    text-align: right;
    font-size: 14px;
    .token_price_change {
      margin-top: 2px;
      font-size: 12px;
      color: ${(props) => props.theme.greenShade2};
    }
    .token_price_negative {
      color: ${(props) => props.theme.redMinus};
    }
  }
  .token_element {
    background: ${(props) => props.theme.researchTokenBackground};
    border: 1px solid ${(props) => props.theme.researchBorder};
    color: ${(props) => props.theme.mainDarkText};
  }
`;

export const MobileTokenCardWrapper = styled.div`
  background: ${(props) => props.theme.secondaryBg};
  border: 1px solid ${(props) => props.theme.researchBorder};
  border-radius: 6px;
  font-size: 14px;
  .mobile_token_header {
    display: flex;
    align-items: center;
    .mobile_token_main {
      width: 100%;
      padding: 1rem 0.5rem;
      display: flex;
      align-items: center;
      .token_star {
        border: 0;
        outline: 0;
        margin-right: 0.75rem;
        path {
          fill: none;
        }
        :hover {
          path {
            fill: #3a4c61;
          }
        }
      }
      .token_star_selected,
      .token_star_selected:hover {
        path {
          stroke: #f3ba2f;
          fill: #f3ba2f;
        }
      }
      .mobile_token_rank {
        flex: 0.15;
      }
      .mobile_token_title {
        display: flex;
        align-items: center;
        flex: 0.3;
        gap: 6px;
        img {
          height: 20px;
          width: 20px;
          border-radius: 50%;
        }
      }
      .mobile_token_price {
        flex: 0.5;
        text-align: right;
        margin-right: auto;
      }
      .token_price_change {
        font-size: 14px;
        color: ${(props) => props.theme.greenShade2};
      }
      .token_price_negative {
        color: ${(props) => props.theme.redMinus};
      }
    }
    .mobile_arrow_down {
      margin-right: 0.5rem;
      height: 18px;
      width: 15px;
    }
  }
  .mobile_token_open {
    margin: 0 10px;
    border-top: 1px solid ${(props) => props.theme.researchBorder};
    padding: 0.5rem 0;
    .mobile_token_info {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5rem;
      :last-of-type {
        margin: 0;
      }
      .mobile_token_info_value {
        color: ${(props) => props.theme.form_text};
      }
    }
  }
  @media (min-width: ${breakPoints.md}px) {
    display: none;
  }
`;
