import React, { useState } from "react";

import { useTranslation } from "react-i18next";
// Redux
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import styled from "styled-components";

// Assets
import KEYFI from "../assets/Blue-filled-logo.svg";
import { ReactComponent as Info } from "../assets/infoIcon.svg";
import StakeModal from "../Components/Access/StakeModal";
import Button from "../Components/Button";
import Meta from "../Components/Meta";
import { breakPoints } from "../Theme";

const Wrapper = styled.div`
  max-width: 950px;
  width: 100%;
  margin: 0 auto 4rem;
  padding: 0 1.25rem;
  .access_container {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    @media (max-width: ${breakPoints.lg}px) {
      margin-top: 4rem;
    }
    h2 {
      font-size: 28px;
      font-weight: 600;
      color: ${(props) => props.theme.default_color};
      margin: 0 0 2rem;
    }

    .access_cards {
      display: flex;
      width: 100%;
      justify-content: center;
      gap: 4rem;
      .access_card_section {
        max-width: 260px;
        :not(:last-of-type) {
        }
      }
      .access_card_info {
        display: flex;
        padding: 1rem 0.75rem;
        text-align: left;
        font-size: 12px;
        line-height: 16px;
        color: ${(props) => props.theme.default_color};
        background: ${(props) => props.theme.infoBackground};
        border-radius: 6px;
        margin-top: 1rem;
        svg {
          margin-right: 0.5rem;
          path {
            fill: #f4bb27;
          }
        }
      }
      @media (max-width: ${breakPoints.lg}px) {
        flex-direction: column;
        align-items: center;
        gap: 0rem;
        .access_card_section {
          min-width: 0;
          width: 100%;
          :not(:last-of-type) {
            margin: 0 0 2rem 0;
          }
        }
      }
    }
  }
`;

const AccessCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 260px;
  background: ${(props) => props.theme.gradientBg};
  padding: 2.5rem 1.9rem;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.researchBorder};

  h3 {
    text-align: center;
    max-width: 120px;
    margin-bottom: 2.25rem;
    font-size: 20px;
    color: ${(props) => props.theme.default_color};
    @media (max-width: ${breakPoints.lg}px) {
      max-width: 250px;
    }
  }
  .access_card_image {
    display: flex;
    align-items: center;
    height: 72px;
    img {
      :not(:first-of-type) {
        margin-left: 0.6rem;
      }
    }
  }
`;

const ConnectButton = styled(Button)`
  margin-top: 2rem;
  font-size: 16px;
  text-transform: capitalize;
  width: 100%;
  padding: 0.6rem 0;
  line-height: 19.5px;
  height: max-content;
`;

const Access = () => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const userData = useSelector((state) => state.userData);
  const {
    user: { access, id, emailApproved },
  } = userData;

  return (
    <Wrapper>
      <Meta title="Access" />
      {access.allow && emailApproved ? <Redirect to="/dashboard" /> : !id && <Redirect to="/signin" />}
      <div className="access_container">
        <h2>KeyFi PRO {t("access.title")}</h2>
        <div className="access_cards">
          <div className="access_card_section">
            <AccessCard>
              <h3>{t("common.stake")} KEYFI</h3>
              <div className="access_card_image">
                <img src={KEYFI} alt="KeyFi" height="72" width="72" />
              </div>
              <ConnectButton onClick={() => setShowModal(true)} primary>
                {t("dashboard.connectWallet")}
              </ConnectButton>
            </AccessCard>
            <div className="access_card_info">
              <Info />
              <div>{t("access.request1000Token")}</div>
            </div>
          </div>

          {/* <div className="access_card_section">
            <AccessCard>
              <h3>Pay with Crypto</h3>
              <div className="access_card_image">
                <img src={ETH} alt="ETH" height="40" width="40" />
                <img src={USDC} alt="USDC" height="40" width="40" />
                <img src={BSC} alt="BSC" height="40" width="40" />
                <img src={KEYFI} alt="KeyFi" height="40" width="40" />
              </div>
              <ConnectButton primary>View Options</ConnectButton>
            </AccessCard>
          </div>
          <div className="access_card_section">
            <AccessCard>
              <h3>Pay with Credit Card</h3>
              <div className="access_card_image">
                <img src={Mastercard} alt="Mastercard" height="48" width="48" />
                <img src={VISA} alt="Visa" height="48" width="48" />
              </div>
              <ConnectButton primary>Subscribe</ConnectButton>
            </AccessCard>
          </div> */}
        </div>
      </div>
      {showModal && <StakeModal handleClose={() => setShowModal(false)} showModal={showModal} />}
    </Wrapper>
  );
};

export default Access;
