import styled, { css } from "styled-components";

import { breakPoints } from "../../Theme";

export const StrategyWrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  .strategy_header {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0.75rem;
    h1 {
      font-size: 18px;
    }
    .strategy_create {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        height: 24px;
        width: 24px;
      }
    }
  }
  .strategy_main {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    .strategy_no_strategies {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 4rem;
      width: 100%;
      max-width: 575px;
      @media (max-width: ${breakPoints.md}px) {
        max-width: none;
        margin-top: 2.5rem;
      }
      p {
        text-align: center;
        width: 100%;
        max-width: 350px;
        margin-bottom: 1.5rem;
        color: ${(props) => props.theme.lightGrey};
      }
      img {
        display: block;
        width: 100%;
        margin-bottom: 1.5rem;
      }
      a {
        margin-bottom: 1.5rem;
        background: ${(props) => props.theme.brandBlue};
        color: white;
        border: 0;
        border-radius: 6px;
        padding: 0.75rem 2.5rem;
        :hover {
          background: ${(props) => props.theme.darkBrandBlue};
        }
      }
    }
  }
  @media (max-width: ${breakPoints.lg}px) {
    .strategy_icon_section {
      display: none;
    }
  }
`;

export const StrategyContainer = styled.div`
  width: 100%;
  background: ${(props) => props.theme.secondaryBg};
  padding: 1rem;
  display: flex;
  gap: 0.75rem;
  margin-bottom: 3rem;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.researchBorder};
  .strategy_builder_icon {
    height: 62px;
    width: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: repeating-linear-gradient(
      45deg,
      ${(props) => props.theme.darkGrey},
      ${(props) => props.theme.darkGrey} 16px,
      ${(props) => props.theme.bg} 16px,
      ${(props) => props.theme.bg} 32px
    );
    border-radius: 6px;
    border: 1px solid ${(props) => props.theme.researchBorder};
  }
  .strategy_section_header {
    border-bottom: 1px solid ${(props) => props.theme.researchBorder};
    width: 100%;
    padding-bottom: 1rem;
    .strategy_metadata {
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;
      row-gap: 1rem;
      margin-bottom: 0.75rem;
      h5 {
        font-size: 12px;
        color: ${(props) => props.theme.lightGrey};
        margin-bottom: 4px;
      }
      h3 {
        font-size: 16px;
        line-height: 19px;
      }
      .strategy_name {
        font-size: 18px;
      }
      .strategy_builder_date_yellow {
        color: ${(props) => props.theme.curveYellow};
      }
      .strategy_builder_info {
        margin-left: 3px;
        height: 12px;
        width: 12px;
        path {
          fill: ${(props) => props.theme.curveYellow};
        }
      }
    }

    p {
      color: ${(props) => props.theme.lightGrey};
      font-size: 14px;
      margin-bottom: 1rem;
    }

    .strategy_builder_tags {
      display: flex;
      gap: 1rem;
      > div {
        font-size: 12px;
        background: ${(props) => props.theme.inputPlaceholder};
        padding: 0.25rem 0.5rem;
        border-radius: 6px;
      }
    }
  }
  .strategy_builder_buttons {
    display: flex;
    gap: 1.5rem;
    margin-bottom: 1.5rem;
    button {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: ${(props) => props.theme.mainDarkText};
      padding: 0.75rem 2.5rem;
      background: 0;
      border-radius: 6px;
      border: 1px solid transparent;
      gap: 0.5rem;
    }
    .strategy_builder_button_primary {
      background: ${(props) => props.theme.brandBlue};
      color: white;
      :hover {
        background: ${(props) => props.theme.darkBrandBlue};
      }
    }
    .strategy_builder_button_secondary {
      border-color: ${(props) => props.theme.researchBorder};
      :hover {
        background: ${(props) => props.theme.secondaryBtnHover};
      }
    }
  }
  .strategy_builder_create {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 1rem 4rem;
    font-size: 16px;
    background: ${(props) => props.theme.bg};
    color: ${(props) => props.theme.mainDarkText};
    border-radius: 6px;
    border: 1px dashed ${(props) => props.theme.researchBorder};
  }
  .strategy_builder_edit {
    padding: 2rem 0 1rem;
    display: flex;
    gap: 1rem;
    border-top: 1px solid ${(props) => props.theme.researchBorder};
    button {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      svg {
        path {
          fill: white;
        }
      }
    }
    @media (max-width: ${breakPoints.lg}px) {
      flex-direction: column;
      button {
        justify-content: center;
      }
    }
  }
`;

export const StrategyGroups = styled.div`
  margin-top: 1rem;
  h2 {
    font-size: 16px;
    margin-bottom: 1rem;
  }
  h4 {
    font-size: 14px;
    color: ${(props) => props.theme.lightGrey};
    margin-bottom: 0.5rem;
  }
  .strategy_actions_container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
    background: ${(props) => props.theme.bg};
    padding: 0.75rem;
    border-radius: 6px;
    border: 1px solid ${(props) => props.theme.researchBorder};
    margin-bottom: 1rem;
  }
`;

export const StrategyAction = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0.75rem 1rem;
  background: ${(props) => props.theme.secondaryBg};
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.researchBorder};
  cursor: pointer;
  > img {
    height: 32px;
    width: 32px;
  }
  .strategy_action_name {
    flex: 1;
    h5 {
      font-size: 16px;
    }
    h6 {
      font-size: 14px;
      color: ${(props) => props.theme.mainDarketText};
    }
  }
  .strategy_action_buttons {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${(props) => props.theme.disabled};
      height: 34px;
      width: 34px;
      border-radius: 6px;
      border: 1px solid ${(props) => props.theme.researchBorder};
    }
  }
  ${(props) =>
    props.add &&
    css`
      height: 62px;
      line-height: 32px;
      cursor: pointer;
      background: ${(props) => props.theme.widgetCardBg};
      border: 1px dashed ${(props) => props.theme.researchBorder};
      justify-content: center;
    `}

  ${(props) =>
    props.create &&
    css`
      width: 305px;
      @media (max-width: ${breakPoints.lg}px) {
        width: 100%;
      }
    `}
`;
