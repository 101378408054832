import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { addAddressesOperations } from "../redux/wallets/addAddressesSlice";

export default function useAddAddress() {
  const dispatch = useDispatch();
  const addresses = useSelector((state) => state.addresses);
  const wallet = useSelector((state) => state.wallet);

  React.useEffect(() => {
    if (
      wallet.selectedAddress &&
      !addresses.addresses.some((item) => item.address.toLowerCase() === wallet.selectedAddress.toLowerCase())
    ) {
      dispatch(addAddressesOperations.addSingleAddress(wallet.selectedAddress));
    }
  }, [addresses.addresses, wallet.selectedAddress]);
  return;
}
