import BigNumber from "bignumber.js";

export const totalValue = (token, prices, lp) => {
  if (token.type && token.type === "lp") {
    const [assetA, assetB] = [token.symbol.split(":")[0], token.symbol.split(":")[1].split(" ")[0]];

    const assetAValue = BigNumber(token[assetA]).times(prices[assetA] ?? 0);
    const assetBValue = BigNumber(token[assetB]).times(prices[assetB] ?? 0);

    return assetAValue.plus(assetBValue).toPrecision(6);
  }
  if ("liquidity" in token || lp || "assetA" in token) {
    const assetA = token.assetA;
    const assetB = token.assetB;
    const assetAValue = BigNumber(token[assetA]).times(prices[assetA] ?? 0);
    const assetBValue = BigNumber(token[assetB]).times(prices[assetB] ?? 0);

    return assetAValue.plus(assetBValue).toPrecision(6);
  }
  if (!token.symbol) console.log(token);
  return (prices[token?.symbol.toUpperCase()] ?? 0) * token.amount;
};

export const getAddressValue = (filteredTokens, item, prices, lp) => {
  return filteredTokens.reduce((acc, token) => {
    if (token.userId.toLowerCase() === item.address.toLowerCase()) {
      return BigNumber(acc).plus(totalValue(token, prices, lp)).toPrecision(6);
    }
    return acc;
  }, 0);
};

export const getTotalValue = (tokens, prices, lp) => {
  return tokens.reduce((acc, token) => {
    return BigNumber(acc).plus(totalValue(token, prices, lp)).toPrecision(6);
  }, 0);
};
