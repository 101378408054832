import styled from "styled-components";

import { breakPoints } from "../../Theme/index";

export const SubscriptionContainer = styled.div`
  width: 100%;
  max-width: 560px;
  margin: 0 auto;
  margin-bottom: 100px;
  color: white;
  h2 {
    font-weight: 400;
    font-size: 24px;
  }
  .container {
    display: flex;
    flex-direction: column;
    background: ${(props) => props.theme.sidebar_bg};
    padding: 3rem 1.5rem;
    margin-top: 15px;
    border: 1px solid ${(props) => props.theme.researchBorder};
    border-radius: 6px;
  }
`;

export const ToggleButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 6rem 3rem;
  .small_text {
    font-size: 10px;
  }
  @media (max-width: ${breakPoints.md}px) {
    padding: 0 1rem 3rem;
  }
`;

export const ToggleButtonOptions = styled.div`
  margin-top: 0.45rem;
  color: ${(props) => (props.checked ? props.theme.default_color : props.theme.form_text2)};
  transition: 400ms all;
`;

export const MonthlyBillingHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 2rem;
  div {
    :first-of-type {
      color: ${(props) => props.theme.default_color};
    }
    :last-of-type {
      color: ${(props) => props.theme.form_text};
    }
  }
`;

export const MonthlyBillDivider = styled.div`
  height: 1px;
  background: #3a4c61;
`;

export const MonthlyBillForm = styled.form`
  width: 65%;
  margin: 0 auto;
  padding: 1.5rem 0;
  h4 {
    margin: 0 0 1rem;
    font-weight: 400;
    color: ${(props) => props.theme.default_color};
  }
  #PaymentForm {
    .wrap-card {
      cursor: pointer;
    }
    .rccs {
      width: 100%;
      .rccs__card {
        width: 100%;
        height: 208px;
      }
    }
    .mt-10 {
      margin-top: 10px;
    }
    input {
      width: 100%;
      color: #ced2d6;
      font-size: 16px;
      ::placeholder {
        color: #3a4c61;
      }
    }
    .invalid {
      border-color: red;
      box-shadow: 0px 0px 10px 0px rgb(255 37 37 / 25%);
      color: red;
    }
  }
  .mt-25 {
    margin-top: 25px;
  }
  .card_details {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
  }
  label {
    color: #738ca6;
    line-height: 15px;
    font-size: 12px;
  }
  .wrap-expiration {
    display: flex;
    justify-content: space-between;
    label {
      width: 48%;
    }
  }
  button {
    width: 100%;
    margin-top: 20px;
  }
  input,
  .StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    font-size: 1em;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 1px solid ${(props) => props.theme.researchBorder};
    outline: 0;
    border-radius: 6px;
    background: ${(props) => props.theme.bg};
  }
  input:focus,
  .StripeElement--focus {
    border-color: ${(props) => props.theme.border_color};
    box-shadow: 0px 0px 10px 0px rgb(73 132 211 / 25%);
  }
  @media (max-width: ${breakPoints.md}px) {
    width: 100%;
  }
`;

export const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height}px`};
  input {
    opacity: 0;
    width: 0;
    height: 0;
    /* :hover + .slider:before {
      transform: translateX(38px);
    } */
    :checked + .slider:before {
      transform: ${(props) => `translateX(${props.height}px)`};
      background: linear-gradient(123.69deg, #46b474 -4.44%, #2cff84 111.11%);
      margin-left: 0;
    }
    /* :checked:hover + .slider:before {
      transform: translateX(19px);
    } */
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.bg};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 26px;
    border: 1px solid #2b3849;
    :before {
      position: absolute;
      content: "";
      height: ${(props) => `${props.height * 0.75}px`};
      width: ${(props) => `${props.height * 0.75}px`};
      left: 0;
      bottom: 0;
      margin-bottom: ${(props) => `${Math.floor(props.height / 10)}px`};
      margin-left: ${(props) => `${Math.floor(props.height / 8)}px`};
      background: ${(props) =>
        props.disable ? props.theme.lightDarkGrey : `linear-gradient(123.69deg, #46b474 -4.44%, #2cff84 111.11%)`};
      transition: 0.4s;
      border-radius: 50%;
    }
  }
`;

export const YearBillingContainer = styled.div`
  .year_divider {
    height: 1px;
    background: #3a4c61;
  }
`;

export const ResultView = styled.div`
  margin-top: 20px;
  font-size: 14px;
  color: ${(props) => props.result};
`;

export const WrapCardFront = styled.div`
  position: absolute;
  width: 100%;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  .mark {
    position: absolute;
    right: 15px;
    top: 20px;
    svg {
      width: 100px;
      max-height: 60px;
    }
  }
  svg#cardfront {
    width: 100%;
    -webkit-box-shadow: 1px 5px 6px 0px black;
    box-shadow: 1px 5px 6px 0px black;
    border-radius: 22px;
    .grey {
      fill: #03a9f4;
    }
    .greydark {
      fill: #0288d1;
    }
    .st2 {
      fill: #ffffff;
    }
    .st3 {
      font-family: "Source Code Pro", monospace;
      font-weight: 600;
    }
    .st4 {
      font-size: 54.7817px;
    }
    .st5 {
      font-family: "Source Code Pro", monospace;
      font-weight: 400;
    }
    .st6 {
      font-size: 33.1112px;
    }
    .st7 {
      opacity: 0.6;
      fill: #ffffff;
    }
    .st8 {
      font-size: 24px;
    }
    .st9 {
      font-size: 36.5498px;
    }
    .st10 {
      font-family: "Source Code Pro", monospace;
      font-weight: 300;
    }
    .st11 {
      font-size: 16.1716px;
    }
  }
  .lightcolor {
    -webkit-transition: fill 0.5s;
    transition: fill 0.5s;
  }
`;

export const WrapCardBack = styled.div`
  position: absolute;
  width: 100%;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  transform: rotateY(180deg);
  svg#cardback {
    width: 100%;
    -webkit-box-shadow: 1px 5px 6px 0px black;
    box-shadow: 1px 5px 6px 0px black;
    border-radius: 22px;
    .lightbluedark {
      fill: #0288d1;
    }
    .st3 {
      fill: #f2f2f2;
    }
    .st4 {
      fill: #d8d2db;
    }
    .st5 {
      fill: #c4c4c4;
    }
    .st6 {
      font-family: "Source Code Pro", monospace;
      font-weight: 400;
    }
    .st7 {
      font-size: 27px;
    }
    .st8 {
      opacity: 0.6;
    }
    .st9 {
      fill: #ffffff;
    }
    .st10 {
      font-size: 24px;
    }
    .st11 {
      fill: #eaeaea;
    }
    .st12 {
      font-family: "Rock Salt", cursive;
    }
    .st13 {
      font-size: 37.769px;
    }
  }
`;

export const UpperContainer = styled.div`
  display: flex;
  padding-bottom: 2rem;
  .upper_container_description {
    flex: 11;
    h4 {
      font-weight: 400;
      margin: 0 0 0.5rem;
    }
    p {
      margin: 0;
      font-size: 12px;

      color: #62798e;
    }
  }
  .upper_container_price {
    flex: 9;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    h4 {
      font-weight: 400;
      margin: 0 0 0.5rem;
      color: ${(props) => props.theme.lightBlue};
    }
    .upper_container_select {
      cursor: pointer;
      display: flex;
      align-items: center;
      border: 1px solid #2a3540;
      padding: 0 8px;
      border-radius: 6px;
      height: 40px;
      span {
        width: 60px;
        color: ${(props) => props.theme.defaultDarkText};
      }
      .logo {
        margin-right: 8px;
      }
    }
  }
`;

export const LowerContainer = styled.div`
  margin-top: 2rem;
  .lower_container_coin {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    img {
      margin-right: 0.5rem;
    }
  }
  .lower_container_deposit {
    background: ${(props) => props.theme.blackish};
    border: 1px solid #3a4c61;
    border-radius: 6px;
    padding: 0.75rem;
    margin-bottom: 1rem;
    .lower_container_deposit_title {
      font-size: 12px;
      color: ${(props) => props.theme.grey1};
      margin-bottom: 0.75rem;
    }
    .lower_container_address {
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      .subscription_copy {
        cursor: pointer;
        display: flex;
        align-items: center;
        color: rgba(206, 210, 214, 0.6);
        img {
          margin-right: 0.5rem;
        }
      }
    }
  }
  .lower_container_warning {
    display: flex;
    align-items: center;
    border-radius: 6px;
    background: rgba(242, 201, 76, 0.1);
    padding: 0.5rem 0.75rem;
    font-size: 12px;
    img {
      margin-right: 0.5rem;
    }
  }
`;

export const PopUpContainer = styled.div`
  background: #182331;
  border-radius: 6px;
  width: 45%;
  z-index: 99999900;
`;

export const PopUpBody = styled.div`
  padding: 36px;
  .result-content {
    margin-top: 36px;
    p {
      font-size: 20px;
    }
    .result-end-date {
      margin-top: 30px;
      font-size: 15px;
    }
  }
`;
