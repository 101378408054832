import React from "react";

import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";

const ProtectedRoute = ({ component: Component, children, ...rest }) => {
  const userData = useSelector((state) => state.userData);
  const { user, authVerified, loading, tokens } = userData;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!loading || tokens.refresh.token) {
          if (user.id && user.access.allow && user.emailApproved) {
            if (user.authentication.approved) {
              if (authVerified) {
                if (!children) return <Component {...rest} {...props} />;
                return children;
              }
              return <Redirect to="/verify-auth" />;
            }
            if (!children) return <Component {...rest} {...props} />;
            return children;
          }
          return <Redirect to="/signin" />;
        }
      }}
    />
  );
};

export default ProtectedRoute;
