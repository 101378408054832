import React, { useEffect, useMemo, useState } from "react";

import { integrations } from "@keyfi/keyfi-common";
import BigNumber from "bignumber.js";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { beautifyTokensAmount } from "../../../helpers";
import { borrowOperations } from "../../../redux/borrowSlice";
import { Skeleton, Spinner } from "../../../Shared";
import DesktopMyBorrows from "./DesktopMyBorrows";
import MobileMyBorrows from "./MobileMyBorrows";
import { MyBorrowsWrapper } from "./MyBorrows.styles";
import MyCollateral from "./MyCollateral";

const MyBorrows = () => {
  const { t } = useTranslation();
  const [borrowData, setBorrowData] = useState([]);
  const [collateralData, setCollateralData] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const borrow = useSelector((state) => state.borrow);
  const { isFetching } = borrow;

  useEffect(() => {
    const getData = async () => {
      try {
        if (Object.keys(borrow.mainnet.aave.v2.balance).length !== 0) {
          setLoading(true);
          const data = dispatch(borrowOperations.getBorrowedAmounts());

          const ETHPrice = await integrations.compound.getETHPrice();

          const mappedData = data.map((item) => {
            const itemData = borrow[item.network][item.platform][item.version].data.find(
              (tokenData) => tokenData.symbol === item.symbol
            );

            const debtAmountInUSD = item.debtAmount * itemData.priceETH * parseFloat(ETHPrice);

            return {
              ...item,
              debtAmountInUSD,
              assetData: {
                platform: item.platform,
                version: item.version,
                asset: item.symbol,
                debtAmountInUSD,
              },
            };
          });

          const collateralData = dispatch(borrowOperations.getCollateralData());

          const collateralMappedData = collateralData.map((item) => ({
            ...item,
            totalCollateralUSD: BigNumber(item.totalCollateralETH).times(ETHPrice).toFixed(),
          }));

          setBorrowData(mappedData);
          setCollateralData(collateralMappedData);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
      }
    };
    if (!borrow.isFetching) {
      getData();
    }
  }, [borrow.isFetching, dispatch, borrow]);

  const collateralTotal = useMemo(
    () => collateralData.reduce((acc, item) => BigNumber(item.totalCollateralUSD).plus(acc), 0),
    [collateralData]
  );

  const borrowTotal = useMemo(() => borrowData.reduce((acc, item) => acc + item.debtAmountInUSD, 0), [borrowData]);
  return (
    <MyBorrowsWrapper>
      <div className="my_borrows">
        <div className="my_borrows_header">
          <div className="my_borrows_total">
            <h3>{t("borrow.Borrowed Balance")} -</h3>{" "}
            <span>
              {isFetching || loading ? (
                <Skeleton width="50" height="18" />
              ) : (
                beautifyTokensAmount(borrowTotal, { prefix: "$" })
              )}
            </span>
          </div>
        </div>
        {borrowData && borrowData.length !== 0 ? (
          <div className="my_borrows_body">
            <DesktopMyBorrows borrowData={borrowData} t={t} />
            <MobileMyBorrows borrowData={borrowData} t={t} />
          </div>
        ) : (
          <div className="my_borrows_body my_borrows_no_assets">
            {isFetching || loading ? <Spinner background="secondaryBg" /> : t("borrow.No borrowed assets")}
          </div>
        )}
      </div>
      <div className="my_collateral">
        <div className="my_collateral_header">
          <div className="my_collateral_total">
            <h3>{t("borrow.Collateral Balance")} -</h3>{" "}
            <span>
              {isFetching || loading ? (
                <Skeleton width="50" height="18" />
              ) : (
                beautifyTokensAmount(collateralTotal, { prefix: "$" })
              )}
            </span>
          </div>
        </div>
        <div className="my_collateral_body">
          <MyCollateral data={collateralData} />
        </div>
      </div>
    </MyBorrowsWrapper>
  );
};

export default MyBorrows;
