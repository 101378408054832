import React, { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

// Assets and helpers
import { ReactComponent as Close } from "../../../assets/closeModal.svg";
import { borrowOperations } from "../../../redux/borrowSlice";
import DropdownV2 from "../../DropDownV2";
import { platformOptions, stablecoins, tokenOptions, typeOptions } from "../BorrowConstants";
import { MainBorrowTableWrapper } from "./BorrowTable.styles";
import BorrowTableDesktop from "./BorrowTableDesktop";
import BorrowTableMobile from "./BorrowTableMobile";

/**
 * Checks if the item matches borrow criteria
 * @param {object} item Borrow asset object
 * @param {object} filter Filter state
 * @return {boolean}
 */
const filterBorrowAssets = (item, filter) => {
  const { type, platform, token } = filter;

  const typeCheck = () => !type || (type === "stablecoins" && stablecoins.find((coin) => coin === item.symbol));

  const platformCheck = () => !platform || item.platform.includes(platform);

  const tokenCheck = () => !token || item.symbol.toLowerCase() === token.toLowerCase();

  return typeCheck() && platformCheck() && tokenCheck() && item.variableAPY !== "0";
};

const BorrowTable = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.wallet);
  const { network } = wallet;

  const borrow = useSelector((state) => state.borrow);
  const { mainnet, isFetching } = borrow;

  const [filter, setFilter] = useState({
    type: "",
    platform: "",
    token: "",
  });

  const aaveV1 = useMemo(
    () =>
      mainnet.aave.v1.data.map((item) => ({
        ...item,
        platform: "Aave v1",
        version: "v1",
        availableToBorrow: item.priceETH !== "0" ? mainnet.aave.v1.user.availableBorrowsETH / item.priceETH : 0,
        stableAPY: "0",
      })) || [],
    [mainnet.aave.v1.data, mainnet.aave.v1.user.availableBorrowsETH]
  );
  const aaveV2 = useMemo(
    () =>
      mainnet.aave.v2.data.map((item) => ({
        ...item,
        availableToBorrow: mainnet.aave.v2.user.availableBorrowsETH / item.priceETH,
        platform: "Aave v2",
        version: "v2",
      })) || [],
    [mainnet.aave.v2.data, mainnet.aave.v2.user.availableBorrowsETH]
  );

  const compoundV1 = useMemo(
    () =>
      mainnet.compound.v1.data.map((item) => ({
        ...item,
        availableToBorrow: mainnet.compound.v1.user.availableBorrowsETH / item.priceETH,
        platform: "Compound",
        version: "v1",
      })) || [],
    [mainnet.compound.v1.data, mainnet.compound.v1.user.availableBorrowsETH]
  );

  const data = [...aaveV1, ...aaveV2, ...compoundV1];

  useEffect(() => {
    if (aaveV1.length === 0 && network && network.name) {
      dispatch(borrowOperations.getBorrowBalance());
    }
  }, [aaveV1, network, dispatch]);

  return (
    <>
      <h2>{t("borrow.Available to borrow")}</h2>
      <MainBorrowTableWrapper>
        <div className="borrow_table_filter_container">
          <div className="borrow_table_filter">
            <div className="borrow_table_filter_label">{t("borrow.type")}</div>
            <DropdownV2
              options={typeOptions(t)}
              setOption={(id) => setFilter((prev) => ({ ...prev, type: id }))}
              selectedOption={filter.type}
              placeholder={t("common.selectType")}
            />
          </div>
          <div className="borrow_table_filter">
            <div className="borrow_table_filter_label">{t("borrow.Platforms")}</div>
            <DropdownV2
              options={platformOptions(t)}
              setOption={(id) => setFilter((prev) => ({ ...prev, platform: id }))}
              selectedOption={filter.platform}
              placeholder={t("common.selectPlatform")}
            />
          </div>
          <div className="borrow_table_filter">
            <div className="borrow_table_filter_label">{t("borrow.Token")}</div>
            <DropdownV2
              options={tokenOptions(data, filter.type, t)}
              setOption={(id) => setFilter((prev) => ({ ...prev, token: id }))}
              selectedOption={filter.token}
              placeholder={t("common.selectToken")}
            />
          </div>
          {(filter.type || filter.platform || filter.token) && (
            <button
              className="borrow_table_filter_clear"
              onClick={() => setFilter({ type: "", platform: "", token: "" })}
            >
              <Close /> {t("common.clearFilters")}
            </button>
          )}
        </div>
        <BorrowTableDesktop
          data={data.filter((item) => filterBorrowAssets(item, filter))}
          filter={filter}
          isFetching={isFetching}
        />
        <BorrowTableMobile
          data={data.filter((item) => filterBorrowAssets(item, filter))}
          filter={filter}
          isFetching={isFetching}
        />
      </MainBorrowTableWrapper>
    </>
  );
};

export default BorrowTable;
