import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const API = process.env.REACT_APP_PRO_API;
const AI_API = process.env.REACT_APP_AI_API || "https://ai.keyfi.com";

const initialState = {
  data: [],
  recommendationWatchlist: {},
  isLoading: null,
  error: null,
};

const recommendation = createSlice({
  name: "recommendation",
  initialState,
  reducers: {
    setIsLoading(state, action) {
      return { ...state, isLoading: action.payload };
    },
    setData(state, action) {
      return { ...state, data: action.payload };
    },
    setRecommendationWatchlist(state, action) {
      return { ...state, recommendationWatchlist: action.payload };
    },
    setError(state, action) {
      return { ...state, error: action.payload };
    },
  },
});

export const recommendationActions = recommendation.actions;

const getRoot = (state) => state.recommendation;

export const recommendationSelectors = {
  getLoading: (state) => getRoot(state).isLoading,
  getError: (state) => getRoot(state).error,
  getData: (state) => getRoot(state).data,
  getRecommendationWatchlist: (state) => getRoot(state).data,
};

export const recommendationOperations = {
  getRecommendations: () => async (dispatch, getState) => {
    try {
      dispatch(recommendationActions.setError(null));
      dispatch(recommendationActions.setIsLoading(true));

      const userAddress = getState().wallet.selectedAddress;

      if (!userAddress) throw new Error("Please connect wallet");

      const { data } = await axios.get(`${AI_API}/recommend?address=${userAddress}`);
      const convertedData = JSON.parse(data.replace(/'/g, '"'));

      const tokenSymbols = convertedData.map((item) => item.token.toLowerCase());

      const config = {
        headers: {
          Authorization: getState().userData.tokens.access.token,
        },
      };

      const { data: tokenData } = await axios.get(
        `${API}/v1/research/minimal-token-data?token=${tokenSymbols.join()}`,
        config
      );

      const reducedData = tokenData
        .reduce((acc, item) => {
          const AIData = convertedData.find((item2) => item2.token.toLowerCase() === item.symbol.toLowerCase());

          if (AIData) {
            return [...acc, { ...item, desirability: AIData.desirability }];
          }
          return acc;
        }, [])
        .sort((a, b) => b.desirability - a.desirability);

      dispatch(recommendationActions.setData(reducedData));
    } catch (err) {
      dispatch(recommendationActions.setError(err.message || err.data.message));
    }
    dispatch(recommendationActions.setIsLoading(false));
  },
  getRecommendationWatchlist: () => async (dispatch, getState) => {
    try {
      const config = {
        headers: {
          Authorization: getState().userData.tokens.access.token,
        },
      };
      const { data } = await axios.get(`${API}/v1/watchlist/recommendation`, config);

      dispatch(recommendationActions.setRecommendationWatchlist(data));
    } catch (err) {
      console.log(err);
    }
  },
  addToRecommendationWatchlist: (coinId) => async (dispatch, getState) => {
    try {
      const config = {
        headers: {
          Authorization: getState().userData.tokens.access.token,
        },
      };
      const { data } = await axios.post(`${API}/v1/watchlist/recommendation`, { coinId }, config);
      dispatch(recommendationActions.setRecommendationWatchlist(data));
    } catch (err) {
      console.log(err);
    }
  },
  removeFromRecommendationWatchlist: (coinId) => async (dispatch, getState) => {
    try {
      const config = {
        headers: {
          Authorization: getState().userData.tokens.access.token,
        },
      };
      const { data } = await axios.post(`${API}/v1/watchlist/recommendation-remove`, { coinId }, config);
      dispatch(recommendationActions.setRecommendationWatchlist(data));
    } catch (err) {
      console.log(err);
    }
  },
};

export const recommendationReducer = recommendation.reducer;
