import React, { useState } from "react";

import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as CloseIcon } from "../../assets/closeModal.svg";
import { ReactComponent as Edit } from "../../assets/strategy/edit.svg";
import { ReactComponent as Trash } from "../../assets/strategy/trash.svg";
import {
  AddTagModal,
  CreateStrategyButton,
  CreateStrategyDate,
  CreateStrategyForm,
  CreateStrategyTab,
} from "../../Pages/Strategy/CreateStrategy.styles";
import { strategyActions, strategyOperations } from "../../redux/strategySlice";
import Modal from "../Modal";
import DropdownList from "../Research/DropdownList";
import DeleteStrategyModal from "./DeleteStrategyModal";

const EditStrategyModal = ({ handleCloseModal }) => {
  const dispatch = useDispatch();
  const strategy = useSelector((state) => state.strategy);
  const { activeStrategy } = strategy;
  const [openAddTagModal, setOpenAddTagModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [newTag, setNewTag] = useState("");
  const { t } = useTranslation();
  const dropdown = [
    {
      id: "yield generating",
      text: t("strategy.yieldGenerating"),
    },
  ];

  const handleInputChange = (e) => {
    dispatch(strategyActions.setActiveStrategy({ ...activeStrategy, [e.target.name]: e.target.value }));
  };

  const handleCategory = (id) => {
    const category = dropdown.find((item) => item.id === id);
    dispatch(strategyActions.setActiveStrategy({ ...activeStrategy, category: category.text }));
  };

  const handleAddTag = () => {
    dispatch(strategyActions.setActiveStrategy({ ...activeStrategy, tags: [...activeStrategy.tags, newTag] }));
    setNewTag("");
    setOpenAddTagModal(false);
  };

  const handleCancelTag = () => {
    setNewTag("");
    setOpenAddTagModal(false);
  };

  const handleEndDate = (date) => {
    dispatch(
      strategyActions.setActiveStrategy({
        ...activeStrategy,
        endDate: date,
        startDate: new Date(date) < new Date(activeStrategy.startDate) ? date : activeStrategy.startDate,
      })
    );
  };

  const removeTag = (tag) => {
    const newTags = activeStrategy.tags.filter((item) => item !== tag);
    dispatch(strategyActions.setActiveStrategy({ ...activeStrategy, tags: newTags }));
  };

  const handleCreateStrategy = async (e) => {
    e.preventDefault();
    dispatch(strategyOperations.updateStrategy());
    handleCloseModal();
  };

  return (
    <Modal title="Edit strategy" width="100%" maxwidth="600px" handleCloseModal={handleCloseModal}>
      {deleteModal && (
        <DeleteStrategyModal callback={handleCloseModal} handleCloseModal={() => setDeleteModal(false)} />
      )}
      {openAddTagModal && (
        <Modal handleCloseModal={() => setOpenAddTagModal(false)} title="Add new tag" maxwidth="500px">
          <AddTagModal>
            <label htmlFor="new_tag">{t("strategy.tagName")}</label>
            <input
              type="text"
              name="name"
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && newTag.length !== 0) {
                  handleAddTag();
                }
              }}
            />
            <div className="add_tag_buttons">
              <button className="add_tag_primary" disabled={newTag.length === 0} onClick={handleAddTag}>
                {t("strategy.save")}
              </button>
              <button className="add_tag_secondary" onClick={handleCancelTag}>
                {t("strategy.cancel")}
              </button>
            </div>
          </AddTagModal>
        </Modal>
      )}
      <CreateStrategyForm editStrategy onSubmit={handleCreateStrategy}>
        <label htmlFor="name">{t("strategy.name")}</label>
        <input
          type="text"
          name="name"
          placeholder={t("strategy.strategyName")}
          value={activeStrategy.name}
          onChange={handleInputChange}
        />
        <label htmlFor="description">{t("strategy.description")}</label>
        <textarea
          rows="4"
          name="description"
          placeholder={t("strategy.description")}
          value={activeStrategy.description}
          onChange={handleInputChange}
        />
        <label htmlFor="category">{t("strategy.category")}</label>
        <DropdownList
          className="strategy_select"
          list={dropdown}
          setOption={handleCategory}
          placeholder={t("strategy.selectCatergory")}
          selected={activeStrategy.category}
          maxWidth
        />
        <label htmlFor="tabs">Tabs</label>
        <div className="strategy_tabs">
          {activeStrategy.tags.length !== 0 &&
            activeStrategy.tags.map((item) => (
              <CreateStrategyTab key={item} onClick={() => removeTag(item)}>
                {item} <CloseIcon />
              </CreateStrategyTab>
            ))}
          <CreateStrategyButton onClick={() => setOpenAddTagModal(true)}>
            {t("strategy.addNewTag")}
          </CreateStrategyButton>
        </div>
        <div className="strategy_dates">
          <CreateStrategyDate>
            <label htmlFor="startDate"> {t("strategy.startDate")}</label>
            <DatePicker
              name="startDate"
              selected={new Date(activeStrategy.startDate)}
              minDate={moment().toDate()}
              maxDate={activeStrategy.endDate ?? null}
              onChange={(date) => dispatch(strategyActions.setActiveStrategy({ ...activeStrategy, startDate: date }))}
            />
          </CreateStrategyDate>
          <CreateStrategyDate>
            <label htmlFor="endDate"> {t("strategy.endDate")}</label>
            <DatePicker
              name="endDate"
              selected={activeStrategy.endDate && new Date(activeStrategy.endDate)}
              minDate={moment().toDate()}
              onChange={handleEndDate}
            />
          </CreateStrategyDate>
        </div>
        <div className="strategy_buttons">
          <button
            type="submit"
            className={`strategy_next ${
              activeStrategy.name.length === 0 || !activeStrategy.startDate ? "strategy_next_disabled" : ""
            }`}
          >
            {t("strategy.save")} <Edit />
          </button>
          <button type="button" className="error" onClick={() => setDeleteModal(true)}>
            Delete <Trash />
          </button>
        </div>
      </CreateStrategyForm>
    </Modal>
  );
};

export default EditStrategyModal;
