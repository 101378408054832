import React from "react";

import { getAddressSum, getContractPlatform } from "@keyfi/keyfi-common/src/integrations/common";
import Tippy from "@tippyjs/react/headless";
import BigNumber from "bignumber.js";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

// Styles
import { ReactComponent as ArrowIcon } from "../../assets/arrow-out.svg";
import { ReactComponent as InfoIcon } from "../../assets/infoIcon.svg";
import { ReactComponent as NoteIcon } from "../../assets/notes.svg";
import { ReactComponent as TXFailed } from "../../assets/transactions/txFailed.svg";
import { ReactComponent as TXSuccess } from "../../assets/transactions/txSuccess.svg";
import { beautifyTokensAmount, trimAddress } from "../../helpers";
import { Skeleton } from "../../Shared";
import AssetImage from "../Borrow/AssetImage";
import { DirectionSign, StyledTableCell, TXTooltipWrapper, TransactionTable } from "./transaction.styles";

// Helpers
const handleImageBaseURL = {
  mainnet: "ethereum",
  "bsc-mainnet": "smartchain",
  polygon: "polygon",
};

// Icons

export const directionSignCheck = (item, id, t) => {
  if (item.from === item.to) {
    return <DirectionSign self>{t("transactions.Self")}</DirectionSign>;
  }
  if (item.from === id.toLowerCase()) {
    return <DirectionSign out>{t("transactions.Out")}</DirectionSign>;
  }
  return <DirectionSign in>{t("transactions.In")}</DirectionSign>;
};

export const TXTooltip = ({ attrs, network }) => {
  const { t } = useTranslation();
  return (
    <TXTooltipWrapper {...attrs}>
      {t("transactions.txFeeBasedOnCurrent")} {network && network.name === "bsc-mainnet" ? "BNB" : "ETH"}{" "}
      {t("common.price")}
    </TXTooltipWrapper>
  );
};

const DesktopTransactionTable = ({
  tableHeadItems,
  tokenPrice,
  tokenSymbol,
  network,
  apiURL,
  loading,
  filter,
  tx,
  openModal,
}) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const { id } = user;

  return (
    <>
      <TransactionTable>
        <thead>
          <tr>
            {tableHeadItems.map((item, i) => (
              <th key={`${item.id}-${i}`}>{item.label}</th>
            ))}
            <th />
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              {[...Array(8).keys()].map((_, i) => (
                <td key={`${Math.random() * i}`}>
                  <Skeleton style={{ marginLeft: i === 7 ? "auto" : "0" }} />
                </td>
              ))}
            </tr>
          )}
          {/* If filters selected AND tx array length === 0 */}
          {tx.length === 0 && !loading && (filter.status || filter.direction) && (
            <tr>
              <td colSpan="6" style={{ textAlign: "left" }}>
                {t("transactions.Do not meet criteria")}
              </td>
            </tr>
          )}
          {/* If tx array length === 0 AND no filters selected */}
          {tx.length === 0 && !loading && !filter.status && !filter.direction && (
            <tr>
              <td colSpan="6" style={{ textAlign: "left" }}>
                {t("transactions.No transactions")}
              </td>
            </tr>
          )}
          {!loading &&
            tx.length !== 0 &&
            tokenPrice &&
            id &&
            tx.map((item, i) => (
              <tr
                key={`${item.id}-${i}`}
                style={{
                  border: tx[i + 1] && tx[i + 1].hash === item.hash && "0",
                }}
              >
                <StyledTableCell>
                  <div>
                    {((tx[i - 1] && tx[i - 1].hash !== item.hash) || i === 0) && (
                      <a
                        href={`https://${apiURL}/tx/${item.hash}`}
                        target="_blank"
                        rel="noreferrer"
                        className="transaction_txhash tx_blue"
                      >
                        {trimAddress(item.hash)}
                        <ArrowIcon />
                      </a>
                    )}
                  </div>
                </StyledTableCell>
                <StyledTableCell txHash>
                  <div>
                    {item.tokenName ? (
                      <div className="tx_token">
                        <AssetImage
                          className="tx_token_image"
                          src={`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${
                            handleImageBaseURL[network.name]
                          }/assets/${getAddressSum(item.contractAddress)}/logo.png`}
                          alt={item.tokenSymbol}
                        />
                        <div>
                          <div className="tx_token_name">{item.tokenName}</div>
                          <div className="tx_token_symbol">{item.tokenSymbol}</div>
                        </div>
                      </div>
                    ) : (
                      "-"
                    )}
                    {directionSignCheck(item, id, t)}
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div>
                    <a
                      href={`https://${apiURL}/address/${item.from}`}
                      target="_blank"
                      rel="noreferrer"
                      className="transaction_txhash"
                    >
                      {trimAddress(item.from)}
                      <ArrowIcon />
                    </a>
                  </div>
                  <div>
                    <a
                      href={`https://${apiURL}/address/${item.to}`}
                      target="_blank"
                      rel="noreferrer"
                      className={`transaction_txhash ${getContractPlatform(item.to) ? "tx_blue" : ""}`}
                    >
                      {getContractPlatform(item.to) ?? trimAddress(item.to)}

                      <ArrowIcon />
                    </a>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div>{item.isError === "0" || !item.isError ? <TXSuccess /> : <TXFailed />}</div>
                </StyledTableCell>
                <StyledTableCell>
                  {((tx[i - 1] && tx[i - 1].hash !== item.hash) || i === 0) && (
                    <>
                      <div>
                        {BigNumber(item.gasPrice).times(item.gasUsed).shiftedBy(-18).toFixed(9)} {tokenSymbol}
                      </div>
                      <div className="tx_blue">
                        {beautifyTokensAmount(
                          BigNumber(item.gasPrice).times(item.gasUsed).shiftedBy(-18).times(tokenPrice).toFixed(),
                          { prefix: "$", suffix: " USD" }
                        )}
                        <Tippy offset={[60, 5]} render={(attrs) => <TXTooltip attrs={attrs} network={network} />}>
                          <InfoIcon className="tx_info" />
                        </Tippy>
                      </div>
                    </>
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {((tx[i - 1] && tx[i - 1].hash !== item.hash) || i === 0) && (
                    <>
                      <div>
                        {BigNumber(item.gasPrice).shiftedBy(-18).toFixed(9)} {tokenSymbol}
                      </div>
                      <div className="tx_blue">
                        {beautifyTokensAmount(BigNumber(item.gasPrice).shiftedBy(-9).toFixed(), { suffix: " Gwei" })}
                      </div>
                    </>
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  <div>
                    {beautifyTokensAmount(
                      BigNumber(item.value)
                        .shiftedBy(item.tokenDecimal ? -item.tokenDecimal : -18)
                        .toFixed(),
                      { suffix: ` ${item.tokenSymbol ?? tokenSymbol}` }
                    )}
                  </div>
                </StyledTableCell>
                <td>
                  {((tx[i - 1] && tx[i - 1].hash !== item.hash) || i === 0) && (
                    <>
                      <div>{moment(item.timeStamp * 1000).format("YYYY/MM/DD")}</div>
                      <div>{moment(item.timeStamp * 1000).format("HH:mm:ss")}</div>
                    </>
                  )}
                </td>
                <StyledTableCell>
                  <div>
                    <NoteIcon onClick={() => openModal(item.hash)} className="transaction_notes_button" />
                  </div>
                </StyledTableCell>
              </tr>
            ))}
        </tbody>
      </TransactionTable>
    </>
  );
};

export default DesktopTransactionTable;
