export function getRates() {
  return function (dispatch) {
    // Dispatching REQUEST action, which tells our app, that we are started requesting todos.
    dispatch({
      type: "FETCH_RATES_REQUEST",
    });
    return fetch(`https://dev.api.keyfi.com/rates`)
      .then((response) => response.json().then((body) => ({ response, body })))
      .then(({ response, body }) => {
        if (!response.ok) {
          // If request was failed, dispatching FAILURE action.
          dispatch({
            type: "FETCH_RATES_FAILURE",
            error: body.error,
          });
        } else {
          // When everything is ok, dispatching SUCCESS action.
          dispatch({
            type: "FETCH_RATES_SUCCESS",
            rates: body,
          });
        }
      });
  };
}
