import styled from "styled-components";

import { breakPoints } from "../../Theme";

export const InfoWrapper = styled.div`
  max-width: 750px;
  margin: 0 auto;
  h2 {
    margin-bottom: 0.75rem;
  }
`;

export const InfoNetworkContainer = styled.div`
  background: ${(props) => props.theme.secondaryBg};
  padding: 1.5rem;
  margin-bottom: 2.5rem;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.researchBorder};

  .network {
    display: flex;
    flex-direction: row;
  }
  .info_network {
    flex: 1;
    .info_network_title {
      display: flex;
      margin-bottom: 2rem;
      h3 {
        font-size: 32px;
        font-weight: 600;
      }
      h6 {
        font-size: 18px;
      }
      svg {
        margin-right: 0.5rem;
      }
    }

    .polygon-link {
      margin-bottom: 4rem !important;

      @media (max-width: ${breakPoints.sm}px) {
        margin-bottom: 2rem !important;
      }
    }

    .info_network_links {
      margin-bottom: 2rem;
      > div {
        display: flex;
        align-items: center;
        :not(:last-of-type) {
          margin-bottom: 0.5rem;
        }
        svg,
        img {
          margin-right: 0.5rem;
          height: 24px;
          width: 24px;
        }
        a {
          color: ${(props) => props.theme.form_text};
          text-decoration: underline;
        }
      }
    }
  }

  .info_network_buy_button {
    display: block;
    padding: 0.5rem 2rem;
    margin-bottom: 1.5rem;
    background: ${(props) => props.theme.brandBlue};
    width: max-content;
    border-radius: 6px;
    color: white;
    :hover {
      background: ${(props) => props.theme.darkBrandBlue};
    }
    margin: 0 auto;
  }

  @media (max-width: ${breakPoints.lg}px) {
    flex-direction: column;
  }
`;

export const InfoLinkContainer = styled.div`
  background: ${(props) => props.theme.secondaryBg};
  padding: 1.5rem;
  margin-bottom: 2.5rem;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.researchBorder};
  h4 {
    margin-bottom: 0.75rem;
  }
  .info_links {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 1rem;
    margin-bottom: 1.5rem;
    a {
      img,
      svg {
        margin-right: 6px;
        height: 16px;
        width: 16px;
      }
      display: flex;
      align-items: center;
      color: ${(props) => props.theme.form_text};
      text-decoration: underline;
      width: max-content;
    }
    @media (max-width: ${breakPoints.lg}px) {
      display: flex;
      flex-direction: column;
    }
  }
  .info_community_links {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    a {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: ${(props) => props.theme.mainDarkText};
      svg,
      img {
        margin-right: 6px;
      }
    }
  }
`;
