import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as Upvote } from "../../assets/upvote.svg";
import { newsOperations } from "../../redux/Slices/newsSlice";
import { breakPoints } from "../../Theme";
import { getTimeFromNow } from "./news.helpers";

const Feed = styled.div`
  display: flex;
  margin-bottom: 0.75rem;
  @media (max-width: ${breakPoints.sm}px) {
    margin: 0;
    margin-right: 20px;
  }

  .image {
    width: 24px !important;
    height: 24px;
  }

  .username {
    display: inline-block;
    margin: 0 6px;
    font-size: 12px;
    color: #58afff;
  }

  .lineBreak {
    color: ${(props) => props.theme.lightGrey};
    margin: 0 6px;
  }

  .feed-text {
    color: ${(props) => props.theme.default_color};
    font-size: 14px;
  }

  .subtext {
    display: flex;
    margin-bottom: 15px;
  }

  .vote_buttons {
    display: flex;
    gap: 0.75rem;
    margin-left: 0.5rem;
    .vote,
    p {
      font-size: 12px;
      color: ${(props) => props.theme.lightGrey};
    }
    .vote {
      display: flex;
      align-items: center;
      gap: 6px;
    }
    .upvoted {
      color: ${(props) => props.theme.borrowGreen};
      path {
        fill: ${(props) => props.theme.borrowGreen};
      }
    }
    .downvoted {
      color: ${(props) => props.theme.error};
      path {
        fill: ${(props) => props.theme.error};
      }
    }
  }
  .reply {
    margin: 0 1em;
  }

  .decrease {
    margin-left: 12px;
    margin-right: 0.5rem;
  }

  .increase,
  .decrease,
  .reply {
    cursor: pointer;
  }

  .relyFeed {
    display: flex;
  }

  .commentBreak {
    border-left: 2px solid #3a4c61;
    margin-left: -14px;
    margin-right: 11px;
    margin-top: -15px;
    margin-bottom: 15px;
  }
`;

const LittlePrint = ({ comment, shiftAmount }) => {
  const dispatch = useDispatch();
  const [commentData, setCommentData] = useState(comment);

  useEffect(() => {
    setCommentData(comment);
  }, [comment.vote, comment.downvote]);

  const userData = useSelector((state) => state.userData);
  const { user } = userData;
  const hasVoted = commentData.vote.includes(user.id);
  const hasDownVoted = commentData.downvote.includes(user.id);
  const handleVote = (id) => {
    if (hasVoted) {
      const filteredVote = commentData.vote.filter((vote) => vote !== user.id);
      setCommentData((prev) => ({ ...prev, vote: filteredVote }));
      dispatch(newsOperations.voteComment(id));
      return;
    }
    setCommentData((prev) => ({ ...prev, vote: [...prev.vote, user.id] }));
    dispatch(newsOperations.voteComment(id));

    // If user has voted - remove vote
    if (hasDownVoted) {
      const filteredVote = commentData.downvote.filter((vote) => vote !== user.id);
      setCommentData((prev) => ({ ...prev, downvote: filteredVote }));
    }
  };

  const handleDownvote = (id) => {
    if (hasDownVoted) {
      const filteredVote = commentData.downvote.filter((vote) => vote !== user.id);
      setCommentData((prev) => ({ ...prev, downvote: filteredVote }));
      dispatch(newsOperations.downvoteComment(id));
      return;
    }
    setCommentData((prev) => ({ ...prev, downvote: [...prev.downvote, user.id] }));
    dispatch(newsOperations.downvoteComment(id));
    // If user has voted - remove vote
    if (hasVoted) {
      const filteredVote = commentData.vote.filter((vote) => vote !== user.id);
      setCommentData((prev) => ({ ...prev, vote: filteredVote }));
    }
  };
  return (
    <Feed shiftAmount={shiftAmount}>
      <div className="relyFeed">
        <div className="info">
          <p className="username">
            {commentData.author.userName} <span className="lineBreak">|</span>
            <span className="feed-text">{commentData.text}</span>
          </p>
          <div className="vote_buttons">
            <button onClick={() => handleVote(commentData._id)} className={`vote ${hasVoted ? "upvoted" : ""}`}>
              <Upvote /> {commentData.vote.length}
            </button>
            <button
              onClick={() => handleDownvote(commentData._id)}
              className={`vote ${hasDownVoted ? "downvoted" : ""}`}
            >
              <Upvote style={{ transform: "rotate(180deg)" }} /> {commentData.downvote.length}
            </button>
            {/* <p className="reply">Reply</p> */}
            <p>{getTimeFromNow(new Date(commentData.createdAt))} ago</p>
          </div>
        </div>
      </div>
    </Feed>
  );
};

function CommentMessage({ comments }) {
  return (
    <>
      {comments.length > 0 ? (
        comments.map((comment) => <LittlePrint key={comment._id} comment={comment} />)
      ) : (
        <p className="noComment" style={{ textAlign: "center", color: "#738CA6" }}>
          No comment
        </p>
      )}
    </>
  );
}

export default CommentMessage;
