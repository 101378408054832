import { poolsLogos } from "../../helpers";

export default [
  { name: "KeyFi", network: "mainnet", icon: poolsLogos.KeyFi },
  { name: "Aave", icon: poolsLogos.Aave, network: "mainnet" },
  {
    name: "Compound",
    icon: poolsLogos.Compound,
    network: "mainnet",
  },
  { name: "dYdX", network: "mainnet", icon: poolsLogos.dydx },
  {
    name: "Curve",
    network: "mainnet",
    icon: poolsLogos.Curve,
    category: "lp",
  },
  {
    name: "Uniswap",
    icon: poolsLogos.Uniswap,
    network: "mainnet",
    category: "lp",
  },
  { name: "KeyFi", network: "bsc-mainnet", icon: poolsLogos.KeyFi },
  {
    name: "PancakeSwap",
    network: "bsc-mainnet",
    icon: poolsLogos.PancakeSwap,
    category: "lp",
  },
  {
    name: "ApeSwap",
    network: "bsc-mainnet",
    icon: poolsLogos.ApeSwap,
    category: "lp",
  },
  { name: "KeyFi", network: "polygon", icon: poolsLogos.KeyFi },
  {
    name: "ApeSwap",
    network: "polygon",
    icon: poolsLogos.ApeSwap,
    category: "lp",
  },
  {
    name: "QuickSwap",
    network: "polygon",
    icon: poolsLogos.QuickSwap,
    category: "lp",
  },
  {
    name: "Trisolaris",
    network: "aurora",
    icon: poolsLogos.Trisolaris,
    category: "lp",
  },
];
