const INITIAL_STATE = {
  darkMode: null,
  id: null,
  isWhitelisted: false,
  readOnly: false,
  usdPrices: {},
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_CURRENT_USER":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
